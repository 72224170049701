import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

export default function QuillPreviewContainer({
  children,
  title,
  value,
  className,
  dashedBorder,
  qlEditorClassName
}) {
  const Translate = useAxisproTranslate();
  return (
    <div
      className={classNames('w-100 custom-text-editor', {
        'border border-dashed pt-2': dashedBorder
      })}
    >
      <div className="ql-snow">
        <div className={`${qlEditorClassName} ql-editor`}>
          {title ? (
            <p className="fw-bold m-0 pb-3 text-dark">
              {typeof title === 'string' ? Translate(title) : title}
            </p>
          ) : null}
          {value ? (
            <div
              className={className}
              dangerouslySetInnerHTML={{ __html: value }}
            ></div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
}

QuillPreviewContainer.propTypes = {
  children: PropTypes.any,
  value: PropTypes.string,
  className: PropTypes.string,
  qlEditorClassName: PropTypes.string,
  dashedBorder: PropTypes.bool,
  title: PropTypes.string
};
