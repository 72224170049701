import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaBookmark } from 'react-icons/fa';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

function Details({ label, value, icon, className, iconColor }) {
  const Translate = useAxisproTranslate();

  return (
    <Row>
      <Col xs={4} className="d-flex">
        {icon ?? <FaBookmark className={`${iconColor} me-1`} size={14} />}
        <h6 className={className}>{Translate(label)}</h6>
      </Col>
      <Col xs={8}>
        <h6 className={className}>{value}</h6>
      </Col>
    </Row>
  );
}

Details.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  icon: PropTypes.any,
  className: PropTypes.any,
  iconColor: PropTypes.any
};

export default Details;
