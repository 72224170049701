import axios from 'axios';

export const apiCall = ({
  url,
  addIitemToStore,
  storeKey,
  onSessionOut,
  params = {},
  paramsSerializer,
  includeCustomFieldsData,
  inCludeApprovalStageCount
}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        ...(paramsSerializer ? { paramsSerializer: paramsSerializer } : null)
      })
      .then(response => {
        if (response.data.success === true) {
          addIitemToStore && addIitemToStore(storeKey, response.data.data);
          const result = response?.data?.data ?? {};
          if (includeCustomFieldsData) {
            result.customFields = response?.data?.custom_field_object;
          }
          if (inCludeApprovalStageCount) {
            result.pending_count = response?.data?.pending_count;
            result.rejected_count = response?.data?.rejected_count;
          }
          resolve(result);
        }
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          onSessionOut
        ) {
          onSessionOut();
        } else {
          console.log(error);
        }

        reject(error);
      });
  });
};

export const apiCallForGettingCustomFields = ({
  url,
  addIitemToStore,
  storeKey,
  onSessionOut,
  params = {},
  paramsSerializer
}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        ...(paramsSerializer ? { paramsSerializer: paramsSerializer } : null)
      })
      .then(response => {
        if (response.data.success === true) {
          addIitemToStore && addIitemToStore(storeKey, response.data);
          resolve(response.data);
        }
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          onSessionOut
        ) {
          onSessionOut();
        } else {
          console.log(error);
        }

        reject(error);
      });
  });
};
