import { React, useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import PurchaseOrderTabView from 'module/Common/ViewPages/PurchaseOrder/PurchaseOrderTabView';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
function PurchaseOrderInfo({
  purchaseOrderId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData,
  interBranch
}) {
  const [queryParams] = useSearchParams();
  const transType = queryParams.get('type');
  const status = queryParams.get('status');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const Translate = useAxisproTranslate();
  const apiCallDelayTime = location?.state?.apiCallDelay ?? 0;

  const fetchData = async () => {
    let url = voidTransactionReport
      ? 'settings/void-transaction-details'
      : transType &&
        transType === 'PD' &&
        status &&
        (status === 'pending' || status === 'rejected')
      ? `purchase/purchase-delivery/${purchaseOrderId}`
      : `purchase/purchase-order/${purchaseOrderId}`;
    await axios
      .get(url, {
        params: {
          trans_type: voidTransactionReport ? 'PO' : '',
          trans_no: voidTransactionReport ? purchaseOrderId : ''
        }
      })
      .then(res => {
        if (res.data.success) {
          setData(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        if (error?.response?.status === 404) {
          axios
            .get(`purchase/purchase-delivery/${purchaseOrderId}`, {
              params: {
                trans_type: 'PD',
                trans_no: ''
              }
            })
            .then(res => {
              if (res?.data?.success) {
                setData(res.data.data);
                setLoading(false);
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    const apiCallTime = setTimeout(() => {
      fetchData();
    }, apiCallDelayTime);
    return () => clearTimeout(apiCallTime);
  }, [purchaseOrderId, apiCallDelayTime]);

  return (
    <>
      {loading ? (
        <LoadingScreenCover>
          <LoadingScreen
            message={`Looking for ${
              interBranch ? 'inter branch po' : 'purchase order'
            } info`}
          />
        </LoadingScreenCover>
      ) : data?.id ? (
        <PurchaseOrderTabView
          purchaseOrderData={data}
          loading={loading}
          fetchData={fetchData}
          getData={getData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          interBranch={interBranch}
        />
      ) : (
        <Card className="flex-fill view-page-content-wrapper d-flex align-items-center justify-content-center h-100 ">
          <div>{Translate('No Records')}</div>
        </Card>
      )}
    </>
  );
}

PurchaseOrderInfo.propTypes = {
  purchaseOrderId: PropTypes.string,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func,
  interBranch: PropTypes.bool
};

export default PurchaseOrderInfo;
