import { React } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsCoin } from 'react-icons/bs';
import { FaCentercode, FaWarehouse } from 'react-icons/fa';
import LineItemTable from '../Common/Tables/LineItemTable';
import Details from '../Common/ViewPageDetails/Details';
import Badges from '../Common/ViewPageDetails/Badges';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';

function MaterialRequestView({ materialRequestData }) {
  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {materialRequestData?.reference && (
            <Badges
              label={materialRequestData?.reference}
              bg={'danger'}
              className={''}
            />
          )}
          <div className="mt-sm-2 mt-md-2">
            <Details
              label={
                materialRequestData?.tax_included &&
                materialRequestData?.tax_included === '1'
                  ? 'Amount (Inc.Tax)'
                  : 'Amount'
              }
              value={materialRequestData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Tax'}
              value={materialRequestData?.total_tax_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Discount'}
              value={
                materialRequestData?.total_discount_amount_formatted ?? '0.00'
              }
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-md-5">
            {materialRequestData?.cost_center_name && (
              <Details
                label={'Cost Center'}
                value={materialRequestData?.cost_center_name}
                className={'mb-2 fs--1'}
                icon={<FaCentercode className="me-1 text-warning" size={15} />}
              />
            )}
            {materialRequestData?.warehouse_name && (
              <Details
                label={'Delivery Location'}
                value={materialRequestData?.warehouse_name}
                className={'mb-2 fs--1'}
                icon={<FaWarehouse className="me-1 text-dark" size={15} />}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <LineItemTable data={materialRequestData} />
        </Col>
      </Row>
      {materialRequestData?.memo && (
        <ShowMemo memo={materialRequestData?.memo} />
      )}
    </Container>
  );
}

MaterialRequestView.propTypes = {
  materialRequestData: PropTypes.any
};

export default MaterialRequestView;
