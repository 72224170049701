import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useEffect, useState } from 'react';
import {
  Button,
  CloseButton,
  Dropdown,
  DropdownButton,
  Modal
} from 'react-bootstrap';
import { FaCloudUploadAlt, FaFileAlt, FaSyncAlt } from 'react-icons/fa';
import {
  FaArrowLeft,
  FaEye,
  FaFileContract,
  FaFilePdf,
  FaPrint
} from 'react-icons/fa6';
import ProposalPrintLayouts from './components/ProposalPrintLayouts';
import FormLayer from 'module/Common/Layer/FormLayer';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import ProposalBuilder from './components/ProposalBuilder';
import generateProposalPDFHelper from 'helpers/generateProposalPDFHelper';
import PropTypes from 'prop-types';
import setNewFormData from 'helpers/setNewFormData';
import ProposalSaveWindowModal from './components/ProposalSaveWindowModal';

export default function ProposalPdfGeneratorDropDownItem({ data }) {
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const [showLayouts, setShowLayouts] = useState(false);
  const [editorValue, setEditorValue] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeLayout, setActiveLayout] = useState(undefined);
  const [saveWindowModalMethod, setSaveWindowModalMethod] = useState(undefined);
  const defaultFileName = 'Proposal' + Date.now();
  const [fileName, setFileName] = useState(defaultFileName);
  const [pages, setPages] = useState([
    {
      name: defaultFileName + '.pdf',
      type: 'default'
    }
  ]);
  const [editorPadding, setEditorPadding] = useState({
    Left: 10,
    Top: 10,
    Right: 10,
    Bottom: 10,
    target: 'all'
  });

  const handleModal = () => {
    setShow(!show);
  };

  const handleShowLayouts = () => {
    setShowLayouts(!showLayouts);
  };

  const handlePrintButton = event => {
    event?.preventDefault();
    setIsProcessing(true);
    generatePDF();
  };

  const handleSavePDFButton = event => {
    event?.preventDefault();
    setIsProcessing(true);
    generatePDF(true);
  };

  const handleSaveLayoutButton = async (event, create, layoutName) => {
    event?.preventDefault();

    if (!saveWindowModalMethod) {
      setSaveWindowModalMethod(create || !activeLayout?.id ? 'save' : 'update');
      return;
    }

    try {
      if (editorValue === '<p><br></p>') return;
      setIsProcessing(true);
      let apiPath = 'proposals';
      let formData = {
        body: editorValue,
        name: layoutName,
        configs: {
          padding: editorPadding,
          pages: pages?.reduce((result, item) => {
            result.push(
              item?.type === 'default'
                ? { ...item, name: layoutName || item?.name }
                : {
                    id: item?.id,
                    name: item?.name
                  }
            );
            return result;
          }, [])
        }
      };

      if (!create && activeLayout?.id) {
        apiPath = apiPath + '/' + activeLayout.id;
        formData._method = 'PUT';
      } else if (activeLayout?.id && activeLayout?.name === layoutName) {
        showToast(
          'The file name already exists. Please change the file name.',
          'error'
        );
        return;
      }

      setSaveWindowModalMethod(undefined);

      let newFormData = setNewFormData(formData);
      const apiResponse = await axios.postForm(apiPath, newFormData);
      const layoutDetails = apiResponse?.data?.data;

      if (layoutDetails?.id) {
        setActiveLayout(layoutDetails);
        showToast(apiResponse?.data?.message, 'success');
      }
    } catch (error) {
      showToast(
        error?.response?.data?.message ||
          error?.message ||
          'Something went wrong, please refresh the page and try again.',
        'error'
      );
    } finally {
      setIsProcessing(false);
    }
  };

  const generatePDF = generateProposalPDFHelper({
    setIsProcessing: setIsProcessing,
    pages: pages,
    fileName: fileName
  });

  useEffect(() => {
    return () => {
      if (show) return;
      const imageToRemove = document.querySelectorAll(
        '.blot-formatter__proxy-image'
      );
      imageToRemove?.forEach(image => image?.remove());
    };
  }, [show]);

  return (
    <>
      <Dropdown.Item
        className="d-flex align-items-center"
        onClick={handleModal}
      >
        <FaFileAlt className="me-1 text-dark" size={14} />
        {Translate('Create Proposal')}
      </Dropdown.Item>

      <Modal
        show={show}
        onHide={handleModal}
        className="form-layer-container"
        fullscreen
      >
        {isProcessing ? <FormLayer /> : null}
        <Modal.Header
          className="d-flex flex-wrap align-items-center gap-2 w-100"
          style={{
            fontSize: '1.1rem'
          }}
        >
          <div className="d-flex flex-row align-items-center gap-2 me-auto">
            <FaFileAlt className="text-success" />
            <p className="m-0 text-dark fw-bold text-uppercase">
              {Translate('Create Proposal')}
            </p>
          </div>

          <Button
            variant="secondary"
            size="sm"
            className="d-flex flex-row align-items-center gap-1 rounded shadow-sm"
            onClick={handleShowLayouts}
          >
            {showLayouts ? (
              <>
                <FaArrowLeft />
                <span>{Translate('Back To Editor')}</span>
              </>
            ) : (
              <>
                <FaFileContract />
                <span>{Translate('Choose Layout')}</span>
              </>
            )}
          </Button>
          <Button
            variant="success"
            size="sm"
            className="d-flex flex-row align-items-center gap-1 rounded shadow-sm"
            onClick={handlePrintButton}
          >
            <FaEye />
            <span>{Translate('Preview')}</span>
          </Button>
          <DropdownButton
            id="dropdown-basic-button"
            title="More"
            size="sm"
            className="rounded shadow-sm border-light"
            variant="light"
          >
            <Dropdown.Item
              className="d-flex flex-row align-items-center gap-2"
              onClick={handlePrintButton}
            >
              <FaPrint />
              <span>{Translate('Print Proposal')}</span>
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex flex-row align-items-center gap-2"
              onClick={handleSavePDFButton}
            >
              <FaFilePdf />
              <span>{Translate('Download PDF')}</span>
            </Dropdown.Item>
            {activeLayout?.id ? (
              <Dropdown.Item
                className="d-flex flex-row align-items-center gap-2"
                onClick={handleSaveLayoutButton}
              >
                <FaSyncAlt />
                <span>{Translate('Update Layout')}</span>
              </Dropdown.Item>
            ) : null}

            <Dropdown.Item
              className="d-flex flex-row align-items-center gap-2"
              onClick={event => handleSaveLayoutButton(event, true)}
            >
              <FaCloudUploadAlt />
              <span>
                {Translate(`Save ${activeLayout?.id ? 'As New' : ''} Layout`)}
              </span>
            </Dropdown.Item>
          </DropdownButton>
          <CloseButton
            className="ms-0 border btn-sm rounded shadow-sm"
            onClick={handleModal}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="dynamic-proposal-cover">
            {showLayouts ? (
              <ProposalPrintLayouts
                onHide={handleShowLayouts}
                setEditorValue={setEditorValue}
                setActiveLayout={setActiveLayout}
                setFileName={setFileName}
                setPages={setPages}
                setEditorPadding={setEditorPadding}
                data={data}
              />
            ) : (
              <ProposalBuilder
                editorValue={editorValue}
                setEditorValue={setEditorValue}
                pages={pages}
                setPages={setPages}
                fileName={fileName}
                setFileName={setFileName}
                editorPadding={editorPadding}
                setEditorPadding={setEditorPadding}
                data={data}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>

      {saveWindowModalMethod ? (
        <ProposalSaveWindowModal
          show
          onHide={() => setSaveWindowModalMethod(undefined)}
          method={saveWindowModalMethod}
          activeLayout={activeLayout}
          fileName={fileName}
          setFileName={setFileName}
          setPages={setPages}
          handleSaveLayoutButton={handleSaveLayoutButton}
        />
      ) : null}
    </>
  );
}

ProposalPdfGeneratorDropDownItem.propTypes = {
  data: PropTypes.object
};
