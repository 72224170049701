import React from 'react';
import './SettingsItem.css';
import PropTypes from 'prop-types';

const SettingsItem = ({ item, active }) => {
  return (
    <div
      className={`settings-item d-flex flex-row align-items-center p-2 cursor-pointer ${
        active ? 'active-settings-item ' : ''
      }`}
    >
      <div className="me-2 icon text-dark">{item.icon}</div>
      <div className="name">{item.name}</div>
    </div>
  );
};

SettingsItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool
};

export default SettingsItem;
