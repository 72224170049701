import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { AiFillFileAdd } from 'react-icons/ai';
import PropTypes from 'prop-types';
import axios from 'axios';
import setNewFormData from 'helpers/setNewFormData';
import { showToast } from 'module/Common/Toast/toast';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import './WorkOrderAdditionalCostsModal.scss';
import ItemCodeAutoComplete from 'components/form/ItemCodeAutoComplete';
import SelectItem from 'components/form/SelectItem';
import useAxisproPermission from 'hooks/useAxisproPermission';
import Total from 'module/Common/Total';
import WorkOrderAdditionalCostsModalTableForm from './components/WorkOrderAdditionalCostsModalTableForm';
import removeRefData from 'helpers/removeRefData';

export default function WorkOrderAdditionalCostsModal({
  onHide,
  workOrderDetailId,
  setTabApiCallCompleted,
  WorkOrderDetailsArray
}) {
  const Translate = useAxisproTranslate();
  const axisProPermission = useAxisproPermission();
  const isMultiple = !!Array.isArray(WorkOrderDetailsArray);
  const formKeys = {
    trans_date: '',
    stock_id: '',
    item_display_name: '',
    item_display_name_ref: null,
    quantity: '',
    unit_price: '',
    note: ''
  };

  const handleFieldChange = (e, action) => {
    let newFormData = generateFormDataHelper(e, action, formData);
    if (action?.name === 'item_display_name') {
      if (isNaN(parseFloat(newFormData.quantity)) || newFormData.quantity < 1) {
        newFormData.quantity = 1;
      }
      newFormData.unit_price = !isNaN(parseFloat(e?.item?.sale_rate))
        ? e.item.sale_rate
        : 0;
      newFormData.stock_id = e?.stock_id ?? '';
    }
    setFormData(newFormData);
  };

  const [formData, setFormData] = useState(formKeys);

  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const netTotal = !isMultiple
    ? !isNaN(parseFloat(formData.unit_price)) &&
      !isNaN(parseFloat(formData.quantity))
      ? parseFloat(formData.unit_price) * parseFloat(formData.quantity)
      : 0
    : Array.isArray(formData?.costs)
    ? formData.costs.reduce((total, item) => {
        if (
          !isNaN(parseFloat(item.unit_price)) &&
          !isNaN(parseFloat(item.quantity))
        ) {
          total += item.unit_price * item.quantity;
        }
        return total;
      }, 0)
    : 0;

  function handleSubmit(event) {
    event.preventDefault();
    setFormError({});
    setIsSaving(true);

    let newFormData = setNewFormData({
      trans_date: formData?.trans_date ?? '',
      note: formData?.note ?? '',
      costs: !isMultiple
        ? [
            {
              work_order_detail_id: workOrderDetailId,
              stock_id: formData?.stock_id ?? '',
              item_display_name: formData?.item_display_name ?? '',
              unit_price: formData?.unit_price ?? '',
              quantity: formData?.quantity ?? ''
            }
          ]
        : Array.isArray(formData?.costs)
        ? formData.costs.reduce((newResult, item) => {
            if (parseFloat(item?.quantity) > 0) {
              newResult.push(
                removeRefData({
                  ...item,
                  stock_id: formData?.stock_id ?? '',
                  item_display_name: formData?.item_display_name ?? ''
                })
              );
            }
            return newResult;
          }, [])
        : []
    });

    axios
      .postForm('production/save-work-order-additional-costs', newFormData)
      .then(response => {
        if (response.data.success !== true) {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
          return;
        }
        showToast(response?.data?.message, 'success');
        setFormData(formKeys);
        typeof onHide === 'function' && onHide();
        typeof setTabApiCallCompleted === 'function' &&
          setTabApiCallCompleted(prev => ({
            ...prev,
            additionalCosts: false
          }));
      })
      .catch(error => {
        setFormError(error?.response?.data?.data?.errors ?? {});
        showToast(
          error?.response?.data?.message ??
            'Something went wrong, please refresh the page and try again.',
          'error'
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  return (
    <Modal size="lg" show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="text-uppercase d-flex flex-row align-items-center gap-1 fw-bold">
          <AiFillFileAdd size={18} className="text-success" />
          Add Additional Costs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof formError === 'object' && Object.keys(formError).length > 0 && (
          <ErrorAlert formError={formError} setFormError={setFormError} />
        )}
        <Form onSubmit={handleSubmit} className="wo-ad-cost-form">
          <Row>
            <Form.Group
              className="mb-4 d-flex flex-row gap-4"
              controlId="form.date"
              as={Col}
              md={6}
            >
              <Form.Label className="require-data">Date</Form.Label>
              <div className="flex-fill d-flex flex-row align-items-center">
                <div className="flex-fill">
                  <AppDatePicker
                    name="trans_date"
                    value={formData.trans_date}
                    yearPlaceholder="yyyy"
                    monthPlaceholder="mm"
                    dayPlaceholder="dd"
                    onChange={handleFieldChange}
                    showDefaultDate
                  />
                </div>
                <FormErrorPopover
                  id="trans_date"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError?.trans_date}
                  addSpace
                />
              </div>
            </Form.Group>

            <Form.Group
              className="mb-4 d-flex flex-row gap-4"
              controlId="form.stock_id"
              as={Col}
              md={6}
            >
              <Form.Label className="require-data">Stock ID</Form.Label>
              <div className="flex-fill d-flex flex-row align-items-center">
                <div className="flex-fill">
                  <ItemCodeAutoComplete
                    type="text"
                    name="stock_id"
                    keyName="item_name"
                    SelectItemKeyName="item_display_name"
                    value={formData.stock_id}
                    onChange={handleFieldChange}
                    filter_keys={{
                      exclude_kits: true,
                      product_types: 'service'
                    }}
                  />
                </div>
                <FormErrorPopover
                  id="stock_id"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError?.stock_id}
                  addSpace
                />
              </div>
            </Form.Group>

            <Form.Group
              className="mb-4 d-flex flex-row gap-4"
              controlId="form.item_display_name"
              as={Col}
              md={6}
            >
              <Form.Label className="require-data">Item Name</Form.Label>
              <div className="flex-fill d-flex flex-row align-items-center">
                <div className="flex-fill">
                  <SelectItem
                    value={formData.item_display_name_ref}
                    handleFieldChange={handleFieldChange}
                    name="item_display_name"
                    keyName="item_name"
                    advanceSearch
                    advanceSearchType="purchase"
                    placeholder={Translate('Search...')}
                    style={{ borderRadius: 'none' }}
                    withAddButton={axisProPermission('create-item')}
                    filter_keys={{
                      exclude_kits: true,
                      product_types: 'service'
                    }}
                  />
                </div>
                <FormErrorPopover
                  id="item_display_name"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError?.item_display_name}
                  addSpace
                />
              </div>
            </Form.Group>

            <Form.Group
              className="mb-4 d-flex flex-row gap-4"
              controlId="form.quantity"
              as={Col}
              md={6}
            >
              <Form.Label className="require-data">Quantity</Form.Label>
              <div className="flex-fill d-flex flex-row align-items-center">
                <div className="flex-fill">
                  <Form.Control
                    type="number"
                    name="quantity"
                    onChange={handleFieldChange}
                    min={1}
                    value={formData.quantity}
                  />
                </div>
                <FormErrorPopover
                  id="amount"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError?.amount}
                  addSpace
                />
              </div>
            </Form.Group>

            <Form.Group
              className="mb-4 d-flex flex-row gap-4"
              controlId="form.unit_price"
              as={Col}
              md={6}
            >
              <Form.Label className="require-data">Amount</Form.Label>
              <div className="flex-fill d-flex flex-row align-items-center">
                <div className="flex-fill">
                  <Form.Control
                    type="number"
                    step="0.1"
                    min={0}
                    name="unit_price"
                    onChange={handleFieldChange}
                    value={formData.unit_price}
                  />
                </div>
                <FormErrorPopover
                  id="unit_price"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError?.unit_price}
                  addSpace
                />
              </div>
            </Form.Group>
          </Row>

          {isMultiple ? (
            <WorkOrderAdditionalCostsModalTableForm
              WorkOrderDetailsArray={WorkOrderDetailsArray}
              formData={formData}
              setFormData={setFormData}
            />
          ) : null}

          <Total subTotal={netTotal} />

          <Form.Group className="mb-3" controlId="note">
            <Form.Label>{Translate('Notes')}</Form.Label>
            <div className="d-flex flex-row align-items-center">
              <div className="flex-fill">
                <Form.Control
                  type="text"
                  as="textarea"
                  name="note"
                  rows={5}
                  onChange={handleFieldChange}
                  value={formData.note}
                />
              </div>
              <FormErrorPopover
                id="note"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={formError.note}
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {!isSaving ? (
          <Button variant="danger" size="sm" onClick={onHide}>
            Cancel
          </Button>
        ) : null}

        <Button
          variant="success"
          size="sm"
          onClick={handleSubmit}
          disabled={isSaving}
          className="d-flex flex-row gap-1 align-items-center justify-content-center"
        >
          {isSaving ? (
            <>
              <Spinner
                style={{
                  width: 15,
                  height: 15
                }}
              />{' '}
              Saving
            </>
          ) : (
            'Save'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

WorkOrderAdditionalCostsModal.propTypes = {
  onHide: PropTypes.func,
  workOrderDetailId: PropTypes.string,
  setTabApiCallCompleted: PropTypes.func,
  WorkOrderDetailsArray: PropTypes.array
};
