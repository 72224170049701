import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { showToast } from '../Toast/toast';
import axios from 'axios';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';

const UserDetailModal = ({ show, setShowUserDetail, item }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item) {
      setLoading(true);
      axios
        .get(`user/${item.id}`)
        .then(response => {
          if (response.data.success) {
            setData(response.data.data);
            setLoading(false);
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              'error'
            );
            setLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
          setLoading(false);
        });
    }
  }, [item]);
  return (
    <>
      <Modal
        show={show ? true : false}
        onHide={() => setShowUserDetail(false)}
        size={'md'}
        style={{
          top: '15rem'
        }}
        className="modal-with-overlay"
      >
        {loading ? (
          <Modal.Header className="d-flex flex-column justify-content-center align-items-center">
            <LoadingScreen />
          </Modal.Header>
        ) : (
          <Modal.Header className="d-flex flex-column justify-content-center align-items-center">
            <img
              src={data.image}
              width={100}
              height={100}
              className="rounded-circle"
            />
            <span style={{ fontSize: '17px', fontWeight: 'bold' }}>
              {data.name}
            </span>
            <p className={'p-0 m-0'}>Phone: {data.phone}</p>
            <p className={'p-0 m-0'}>Email: {data.email}</p>
            <p className={'p-0 m-0'}>
              Role:{' '}
              {data &&
                data.roles &&
                data.roles.length > 0 &&
                data.roles[0].name}
            </p>
          </Modal.Header>
        )}

        {/* <Modal.Body>
          <Card.Body className="p-0 pb-2 d-flex "></Card.Body>
        </Modal.Body> */}
      </Modal>
    </>
  );
};

UserDetailModal.propTypes = {
  show: PropTypes.bool,
  setShowUserDetail: PropTypes.func,
  item: PropTypes.any
};

export default UserDetailModal;
