export default function paymentStatusChecking(paymentStatus) {
  const result = {
    status: paymentStatus,
    statusInLowerCase: paymentStatus?.toLowerCase()
  };

  switch (result.statusInLowerCase) {
    case 'fully paid':
      result.bg = 'success';
      break;

    case 'partially paid':
      result.bg = 'warning';
      break;

    default:
      result.bg = 'danger';
      break;
  }

  return paymentStatus ? result : null;
}
