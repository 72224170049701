import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Image,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasTitle
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import AssetDetailsCollapse from './AssetDetailsCollapse';
import { Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const AssetDetails = ({ show, onHide, value, showAssetDetailsArea }) => {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const lessThanOneImage = data?.images?.length < 1;

  const fetchData = async () => {
    await axios
      .get(`/fixedasset/fa-items/${value.value}`)
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (showAssetDetailsArea) {
      fetchData();
    }
  }, [showAssetDetailsArea, value.value]);

  return (
    <Offcanvas
      show={show}
      placement="end"
      onHide={onHide}
      style={{ zIndex: 50000 }}
    >
      <OffcanvasHeader closeButton style={{ boxShadow: 'none' }}>
        <OffcanvasTitle className="fs-1 text-dark fw-bold m-0 text-uppercase ">
          {Translate('Asset Details')}
        </OffcanvasTitle>
      </OffcanvasHeader>
      {!loading && data !== '' ? (
        <Offcanvas.Body className="customer-details-offcanvas p-0">
          <div>
            {data?.images?.length > 0 && (
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false
                }}
                pagination={{
                  clickable: true
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="app-swiper cursor-pointer"
              >
                {data?.images.map((image, key) => (
                  <SwiperSlide key={key}>
                    <Image
                      src={image?.file_url}
                      width={'100%'}
                      height={'180px'}
                      objectFit="cover"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          <div className="p-3 pt-0">
            <div
              className={`customer-header d-flex flex-row align-items-center ${
                !lessThanOneImage && 'p-1 px-2'
              }`}
            >
              {data && lessThanOneImage && (
                <div className="profile-icon d-flex justify-content-center align-items-center me-2 shadow-sm ">
                  <h5 className="letter text-uppercase m-0 text-white">
                    {data && data.images && data.images[0] ? (
                      <img
                        className="rounded-circle"
                        style={{ height: '70px', width: '70px' }}
                        src={data.images[0].file_url}
                      ></img>
                    ) : (
                      ''
                    )}
                  </h5>
                </div>
              )}
              <div className="details ms-1">
                <div className="d-flex pt-2">
                  <span
                    className="mt-1 me-2 text-uppercase text-dark"
                    style={
                      !lessThanOneImage
                        ? { fontSize: '16px', fontWeight: 'bold' }
                        : {}
                    }
                  >
                    {data.item_name}
                  </span>
                  <Link
                    className="m-0"
                    to={`/fixed-assets/assets/info/${value.value}`}
                  >
                    <FontAwesomeIcon icon="external-link-alt" size="xs" />
                  </Link>
                </div>
                <p className="m-0">{data.stock_id}</p>
                <div className="d-flex mt-2">
                  <h6 className="mb-0 text-muted">
                    {data.email}
                    {data.email ? <span className="me-1 mx-1">|</span> : ''}
                    {data.phone}
                  </h6>
                </div>
              </div>
            </div>
            <AssetDetailsCollapse data={data} fetchData={fetchData} />
          </div>
        </Offcanvas.Body>
      ) : (
        <LoadingScreen />
      )}
    </Offcanvas>
  );
};

AssetDetails.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  value: PropTypes.any,
  showAssetDetailsArea: PropTypes.bool
};

export default AssetDetails;
