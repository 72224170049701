import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { AuthWizardContext } from 'context/Context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { showToast } from 'module/Common/Toast/toast';

const LockScreenForm = ({ type, ...rest }) => {
  const { user, dispatch } = useContext(AuthWizardContext);
  const [checking, setchecking] = useState(false);
  const navigate = useNavigate();

  // State
  const [formData, setFormData] = useState({
    password: ''
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    setchecking(true);
    await axios.get('csrf-cookie');
    axios
      .post('auth/login', { username: user.username, ...formData })
      .then(res => {
        if (res.data.success === true) {
          const userData = {
            ...res.data.data,
            username: formData.username,
            lockScreen: false
          };

          setFormData({
            username: '',
            password: ''
          });

          // update auth context
          dispatch({ type: 'UPDATE', payload: { ...userData } });

          // save user data with token to localStorage
          localStorage.setItem('AXIS_PRO_USER', JSON.stringify(userData));

          navigate(0);

          showToast(`Successfully logged in as ${userData.name}`, 'success');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }

        setchecking(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }

        setchecking(false);
      });
  };

  return (
    <Row
      {...rest}
      as={Form}
      className={classNames('gx-2 mt-4', {
        'mx-sm-4 mb-2': type === 'simple'
      })}
      onSubmit={handleSubmit}
    >
      <Col>
        <Form.Control
          placeholder="Enter your password"
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
          style={{ minHeight: '29px' }}
          autoFocus
        />
      </Col>
      <Col xs={type === 'simple' ? 'auto' : 4}>
        <Button
          variant="primary"
          type="submit"
          disabled={!formData.password || checking}
          className={classNames({ 'w-100': type !== 'simple' })}
          size="sm"
        >
          {checking ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Login'
          )}
        </Button>
      </Col>
    </Row>
  );
};

LockScreenForm.propTypes = {
  type: PropTypes.oneOf(['simple', 'split', 'card'])
};

export default LockScreenForm;
