import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

function PersonName({
  label,
  value,
  view,
  permission,
  setCustomerDetailsArea
}) {
  const Translate = useAxisproTranslate();

  return (
    <div className="me-auto gap-3 d-flex flex-wrap align-items-center">
      {label && <h6 className="mb-2 fw-bold">{Translate(label)}:&nbsp;</h6>}
      <div className="d-flex gap-2">
        <h6 className="mb-2 text-uppercase">{value}</h6>
        {permission && view && (
          <FontAwesomeIcon
            icon="external-link-alt"
            className="mb-1 text-primary"
            size="xs"
            cursor={'pointer'}
            onClick={() => setCustomerDetailsArea(true)}
          />
        )}
      </div>
    </div>
  );
}

PersonName.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  view: PropTypes.bool,
  permission: PropTypes.bool,
  setCustomerDetailsArea: PropTypes.func
};

export default PersonName;
