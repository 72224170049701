const setNewFormData = data => {
  let formData = new FormData();

  function setData(key, data) {
    if (Array.isArray(data)) {
      data.map((item, index) => {
        if (item && typeof item === 'object') {
          if (Object.keys(item).length > 0) {
            Object.keys(item).map(keyName => {
              let keyName_ref = keyName;
              if (keyName_ref.startsWith('[')) {
                keyName_ref = keyName_ref.substring(1, keyName_ref.length);
              }
              if (keyName_ref.endsWith(']')) {
                keyName_ref = keyName_ref.substring(0, keyName_ref.length - 1);
              }

              if (Array.isArray(item?.[keyName])) {
                setData(`${key}[${index}][${keyName_ref}]`, item[keyName]);
              } else {
                formData.append(
                  `${key}[${index}][${keyName_ref}]`,
                  item[keyName]
                );
              }
            });
          } else if (!Array.isArray(item)) {
            formData.append(`${key}[${index}]`, item);
          }
        } else {
          formData.append(`${key}[${index}]`, item);
        }
      });
    } else if (
      ![undefined, null].includes(data) &&
      typeof data === 'object' &&
      Object.keys(data).length > 0
    ) {
      Object.keys(data).map(objKey => {
        setData(`${key}[${objKey}]`, data[objKey]);
      });
    } else {
      formData.append(key, data);
    }
  }

  Object.keys(data).map(key => {
    setData(key, data[key]);
  });
  return formData;
};

export default setNewFormData;
