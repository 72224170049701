import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AfterSaveViewButton({ title, url, ...rest }) {
  return (
    <Button {...rest} size="sm" title={title} as={Link} to={url}>
      <FontAwesomeIcon icon="eye" size="sm" /> View
    </Button>
  );
}

AfterSaveViewButton.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
};

export default AfterSaveViewButton;
