export const CheckZeroOrLessThanZeroValidator = (input, name, setFormError) => {
  if (input === '0') {
    setFormError({ input: [`${name} Field cannot be zero!`] });
    return false;
  } else if (input < 0) {
    setFormError({ input: [`${name} Field cannot be less than zero!`] });
    return false;
  } else {
    setFormError({});
    return true;
  }
};
