import React from 'react';
import { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
const CarouselImage = ({ activeItemIndex, imageArray, show, onHide }) => {
  const [index, setIndex] = useState(0);
  const handleNext = () => {
    let nextIndex = index < imageArray.length - 1 ? index + 1 : 0;
    setIndex(nextIndex);
  };

  const handlePrev = () => {
    let nextIndex = index < 1 ? imageArray.length - 1 : index - 1;
    setIndex(nextIndex);
  };

  const handleCloseWindow = () => {
    if (onHide) onHide();
  };

  useEffect(() => {
    setIndex(activeItemIndex);

    return () => {
      setIndex(0);
    };
  }, [activeItemIndex]);

  return (
    <Modal show={show} className="image-carousel" centered onHide={onHide}>
      <div
        className="image-preview"
        style={{ backgroundImage: `url(${imageArray[index]})` }}
      >
        {onHide ? (
          <Button
            variant="transparent"
            className="close-button"
            onClick={handleCloseWindow}
          >
            <FaTimes size={20} color="white" />
          </Button>
        ) : null}
        <Button
          className="outline-0 border-0 left-button"
          variant="transparent"
          onClick={handlePrev}
        >
          <FaChevronLeft />
        </Button>

        <Button
          className="outline-0 border-0 right-button"
          variant="transparent "
          onClick={handleNext}
        >
          <FaChevronRight />
        </Button>
      </div>
    </Modal>
  );
};
CarouselImage.propTypes = {
  activeItemIndex: PropTypes.string,
  imageArray: PropTypes.array,
  show: PropTypes.bool,
  onHide: PropTypes.func
};
export default CarouselImage;
