import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Card, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'components/customer-details/CustomerDetails.css';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import ActivityFetching from 'module/Common/Loading/ActivityFetching';

function ActivityLog({
  id,
  type,
  tabApiCallCompleted,
  setTabApiCallCompleted,
  activeTabKey
}) {
  const Translate = useAxisproTranslate();
  const [activity, setActivity] = useState([]);
  const [data, setData] = useState([]);
  const [activityLoading, setActivityLaoding] = useState(false);

  const loadNextPage = async next_page_url => {
    await axios
      .get(next_page_url)
      .then(res => {
        setActivity(res.data.data);
        setData([...data, ...res.data.data.data]);
        setActivityLaoding(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  let parameters = {};
  if (type === 'CUSTOMER') {
    parameters = {
      customer_id: id
    };
  } else if (type === 'SUPPLIER') {
    parameters = {
      supplier_id: id
    };
  } else if (type === 'SALESMAN') {
    parameters = {
      salesman_id: id
    };
  }

  const fetchData = () => {
    setActivityLaoding(true);
    axios
      .get(`activity-log`, {
        params: parameters
      })
      .then(res => {
        if (res?.data?.success) {
          setActivity(res.data.data);
          setData(res.data.data.data);
        } else {
          showToast('Something went wrong! Please contact admin', 'error');
        }
      })
      .catch(error => {
        if (error) {
          showToast('Something went wrong! Please contact admin', 'error');
        }
      })
      .finally(() => {
        setActivityLaoding(false);
        setTabApiCallCompleted(prev => ({
          ...prev,
          activity_log: true
        }));
      });
  };

  useEffect(() => {
    if (id) {
      if (activeTabKey !== 'activity' || tabApiCallCompleted) return;
      fetchData();
    }
  }, [activeTabKey, tabApiCallCompleted]);

  return activityLoading ? (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ height: '65vh' }}
    >
      <LoadingScreen message="Looking for customer activities" />
    </Container>
  ) : data && data.length > 0 ? (
    <Container className="activity-log-style">
      <ul className="pt-4 px-0 m-0 customer-info-tab-content activity-tree">
        {data.map((item, key) => (
          <li className="d-flex flex-row" key={key}>
            <div className="icon-cover d-flex justify-content-center align-items-center shadow border">
              <FontAwesomeIcon icon="comment-dots" size="sm" color="#344050" />
            </div>
            <Card className="w-50">
              <Card.Body>
                <p className="text-muted small m-0">{item.created_at}</p>
                <div className="d-flex gap-2 text-uppercase">
                  <h6 className="m-0">{item.description}</h6>
                  <h6 className="m-0">-</h6>
                  <h6 className="m-0">{item.event}</h6>
                </div>
                <p className="text-muted m-0 d-flex gap-2">
                  {item.reference ? item.reference : ''} {item.event}
                  <b>
                    - {Translate('By')}
                    {item.causer_name}
                  </b>
                </p>
              </Card.Body>
            </Card>
          </li>
        ))}
      </ul>
      {activity.current_page != activity.last_page && (
        <>
          <ActivityFetching
            links={activity}
            loadNextPage={loadNextPage}
            info={true}
          />
        </>
      )}
    </Container>
  ) : (
    <Container>
      <Alert variant="danger" className="mt-2 text-center fs--1">
        {Translate('Nothing to show')}
      </Alert>
    </Container>
  );
}

ActivityLog.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  activeTabKey: PropTypes.string,
  tabApiCallCompleted: PropTypes.bool,
  setTabApiCallCompleted: PropTypes.func
};

export default ActivityLog;
