import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';

const GLEntryItemsTable = ({ glEntries }) => {
  const Translate = useAxisproTranslate();
  const acodaxNumberFormat = useAcodaxNumberFormat();
  return Array.isArray(glEntries) ? (
    <Table
      striped
      bordered
      responsive
      hover={false}
      className="simple-table-animation"
    >
      <thead style={{ backgroundColor: 'rgb(237,243,248)' }}>
        <tr>
          <th
            colSpan={8}
            className="border-bottom text-center text-dark text-uppercase"
          >
            {Translate('GL Items for this Invoice')}
          </th>
        </tr>
        <tr className="bg-dark text-light text-uppercase">
          <th className="fs--2 p-2 text-center" style={{ width: '40px' }}>
            #
          </th>
          <th className="fs--2 p-2 text-center" style={{ width: '150px' }}>
            {Translate('Account Code')}
          </th>
          <th style={{ minWidth: '180px' }} className="fs--2 p-2 text-center">
            {Translate('Account Name')}
          </th>
          <th className="fs--2 p-2 text-center" style={{ minWidth: '130px' }}>
            {Translate('Cost center')}
          </th>
          <th className="fs--2 p-2 text-center">{Translate('Amount')}</th>
          <th className="fs--2 p-2 text-center">{Translate('Tax')}</th>
          <th className="fs--2 p-2 text-center">{Translate('Total')}</th>
          <th className="fs--2 p-2 text-center">{Translate('Memo')}</th>
        </tr>
      </thead>
      <tbody className="text-dark">
        {glEntries.map((entry, index) => (
          <tr key={index}>
            <td className="p-2 text-center">{index + 1}</td>
            <td className="p-2 text-center">
              <span>{entry?.account_code ?? '-'}</span>
            </td>
            <td className="p-2 text-center">
              <span>{entry?.account_name ?? '-'}</span>
            </td>
            <td className="p-2 text-center">
              <span>
                {entry && entry.cost_center_name ? entry.cost_center_name : '-'}
              </span>
            </td>
            <td className="p-2 text-center">
              <span>{entry?.amount_formatted ?? '-'}</span>
            </td>
            <td className="p-2 text-center">
              <span>{entry?.applied_tax_amount_formatted ?? '-'}</span>
            </td>
            <td className="p-2 text-center">
              <span>
                {entry?.amount || entry?.applied_tax_amount
                  ? acodaxNumberFormat(
                      parseFloat(entry.amount) +
                        parseFloat(entry.applied_tax_amount)
                    )
                  : ''}
              </span>
            </td>
            <td className="p-2 text-center">
              <span>{entry?.description ?? '-'}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : null;
};

GLEntryItemsTable.propTypes = {
  glEntries: PropTypes.array
};

export default GLEntryItemsTable;
