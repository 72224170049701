import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaFilter } from 'react-icons/fa';
import { MdFilterAltOff } from 'react-icons/md';

const FilterButton = ({ className, moduleName }) => {
  const hideFilterObject =
    JSON.parse(localStorage.getItem('HIDE_ACODAX_ERP_FILTER_MODULE')) || [];
  const handleFilter = () => {
    if (typeof moduleName === 'string') {
      let updatedFilterObject;
      if (hideFilterObject.includes(moduleName)) {
        updatedFilterObject = hideFilterObject.filter(
          items => items !== moduleName
        );
      } else {
        updatedFilterObject = [...hideFilterObject, moduleName];
      }
      localStorage.setItem(
        'HIDE_ACODAX_ERP_FILTER_MODULE',
        JSON.stringify(updatedFilterObject)
      );
    }
  };
  return (
    <Button
      as={Link}
      size={'sm'}
      onClick={handleFilter}
      disabled={!moduleName}
      variant={'secondary'}
      className={` ms-1 ${className}`}
    >
      {hideFilterObject.includes(moduleName) ? (
        <MdFilterAltOff size={14} className="p-0 m-0" />
      ) : (
        <FaFilter size={10} />
      )}
    </Button>
  );
};

FilterButton.propTypes = {
  moduleName: PropTypes.string,
  className: PropTypes.string
};

export default FilterButton;
