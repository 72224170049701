import React from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';

const LogoPreview = ({ logo, onRemove, imageOnly }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      {!imageOnly ? (
        <div className="d-flex flex-row align-items-center justify-content-between w-100">
          <Form.Label className="m-0">Company Logo</Form.Label>
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Remove logo</Tooltip>}
          >
            <Button
              size="sm"
              className="rounded px-2 ms-2"
              variant="danger"
              type="button"
              onClick={onRemove}
            >
              <FaTimes size={10} />
            </Button>
          </OverlayTrigger>
        </div>
      ) : null}
      <div>
        <img
          src={URL.createObjectURL(logo)}
          alt="company logo"
          className="img-fluid mt-2"
        />
      </div>
    </div>
  );
};

LogoPreview.propTypes = {
  logo: PropTypes.object,
  onRemove: PropTypes.func,
  imageOnly: PropTypes.bool
};

export default LogoPreview;
