import axios from 'axios';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Dropdown, Spinner } from 'react-bootstrap';
import { RxDotFilled } from 'react-icons/rx';
const ContractStatusDropDownButton = ({ data, rentalContract }) => {
  const [status, setStatus] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const Translate = useAxisproTranslate();
  const [statusLoading, setStatusLoading] = useState(false);
  const dropdownRef = useRef();
  const handleOpenStatus = () => {
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
    setShow(true);
  };

  const handleStatusChange = value => {
    if (data.status == value) {
      return showToast(
        'Please choose a different status. You have already selected this status.',
        'error'
      );
    } else {
      setIsLoading(true);
      dropdownRef.current.click();
      axios({
        method: 'post',
        url: `${
          rentalContract
            ? '/sales/update-rental-contracts-status/'
            : 'sales/contracts/update-status/'
        }${data.id}`,
        ...(rentalContract && {
          params: {
            status: value,
            _method: 'PUT'
          }
        }),
        ...(!rentalContract && { data: { status: value } })
      })
        .then(res => {
          if (res?.data?.success) {
            showToast(res.data?.message, 'success');
            data['status'] = value;
            setIsLoading(false);
          }
        })
        .catch(error => {
          if (error?.response?.data?.message) {
            showToast(error.response.data.message, 'error');
            setIsLoading(false);
          }
        });
    }
  };
  useEffect(() => {
    if (show) {
      (async function () {
        try {
          setStatusLoading(true);
          const { data } = await axios.get('sales/contract-status');
          setStatus(data?.data);
          setStatusLoading(false);
        } catch (error) {
          setStatusLoading(false);
          console.log(error);
        }
      })();
    }
  }, [show]);
  //   useEffect(() => {
  //     const source = axios.CancelToken.source();
  //     axios
  //       .get('sales/contract-status', { cancelToken: source.token })
  //       .then(res => {
  //         if (res?.data?.success) {
  //           setStatus(res?.data?.data);
  //         }
  //       })
  //       .catch(error => {
  //         if (!axios.isCancel(error)) {
  //           console.log(error);
  //         }
  //       });
  //     return () => {
  //       source.cancel('Request cancelled due to component unmount');
  //     };
  //   }, []);
  return (
    <Badge
      bg={
        data?.status === 'active'
          ? 'success'
          : data?.status === 'hold'
          ? 'warning'
          : data?.status === 'renewed'
          ? 'info'
          : 'danger'
      }
      className="ms-2 p-0 text-uppercase fw-medium d-flex align-items-center justify-content-center cursor-pointer ps-2 pe-2   py-0 rental-contract-status-badge"
      onClick={handleOpenStatus}
    >
      {isLoading ? (
        <div className="d-flex align-items-center  justify-content-center">
          <Spinner
            animation="border"
            size="sm"
            className="me-1 spinner-for-rental-contract"
          />{' '}
          {Translate('Updating')}...{' '}
        </div>
      ) : (
        <>
          <RxDotFilled size={18} className="me-0 pe-0" />{' '}
          {Translate(data?.status)}
        </>
      )}
      <div
        className="ms-2 cursor-pointer"
        title={Translate('Click here to change rental contract status!')}
      >
        <Dropdown className="rental-status-dropdown ">
          <Dropdown.Toggle
            className="p-0 "
            variant="transparent"
            style={{ boxShadow: 'none' }}
            ref={dropdownRef}
          />
          <Dropdown.Menu>
            {!statusLoading ? (
              status.length > 0 &&
              status.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleStatusChange(item)}
                >
                  {Translate(item.toUpperCase())}
                </Dropdown.Item>
              ))
            ) : (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: '100px' }}
              >
                <Spinner animation="border" variant="info" />
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Badge>
  );
};
ContractStatusDropDownButton.propTypes = {
  data: PropTypes.object,
  rentalContract: PropTypes.bool
};
export default ContractStatusDropDownButton;
