import { React, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { useLocation } from 'react-router-dom';
import EstimationTabView from 'module/Common/ViewPages/Estimation/EstimationTabView';

function EstimationEntryInfo({
  estimationEntryId,
  setEstimationEntries,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  onHide
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const apiCallDelayTime = location?.state?.apiCallDelay ?? 0;

  const fetchData = async () => {
    let url = voidTransactionReport
      ? 'settings/void-transaction-details'
      : `crm/estimation/${estimationEntryId}`;

    let params = voidTransactionReport
      ? {
          trans_type: 'ES',
          trans_no: estimationEntryId
        }
      : null;
    await axios
      .get(url, {
        params: params
      })
      .then(res => {
        if (res.data.success) {
          setData(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    const apiCallTime = setTimeout(() => {
      fetchData();
    }, apiCallDelayTime);
    return () => clearTimeout(apiCallTime);
  }, [estimationEntryId, apiCallDelayTime]);

  return (
    <>
      {loading ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for estimation details" />
        </LoadingScreenCover>
      ) : (
        <EstimationTabView
          estimationEntryData={data}
          setEstimationEntryData={setData}
          setEstimationEntries={setEstimationEntries}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          onHide={onHide}
        />
      )}
    </>
  );
}

EstimationEntryInfo.propTypes = {
  estimationEntryId: PropTypes.string,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  setEstimationEntries: PropTypes.func,
  onHide: PropTypes.func
};

export default EstimationEntryInfo;
