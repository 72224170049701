import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import { FaCheckCircle, FaFilter, FaPlusCircle } from 'react-icons/fa';
import WorkOrderDetailItemSelect from './WorkOrderDetailItemSelect';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import WorkOrderEntryActionButton from 'components/work-order-entry-action-button/WorkOrderEntryActionButton';
export default function WorkOrderTabViewFilterAndActionSection({
  workOrderId,
  WorkOrderDetailsArray,
  fetchData,
  setTabApiCallCompleted,
  keyName,
  TabkeyName,
  setWorkOrderVoucherData,
  setActiveWorkOrderDetailId
}) {
  const Translate = useAxisproTranslate();
  const [workOrderItem, setWorkOrderItem] = useState(null);
  let disableActionButton,
    variant,
    buttonText,
    releaselimit,
    deliverylimit,
    fullyReleased,
    fullyDelivered,
    validAdditionalCostsWorkOrderDetailsArray;

  const activeWorkOrderItemProducingQty = !isNaN(
    parseFloat(workOrderItem?.item?.producing_quantity)
  )
    ? parseFloat(workOrderItem?.item?.producing_quantity)
    : 0;

  if (['materialRequest', 'additionalCosts', 'release'].includes(keyName)) {
    disableActionButton = !!(activeWorkOrderItemProducingQty < 1);

    if (keyName === 'materialRequest') {
      buttonText = 'Material requestes';
      disableActionButton = false;
    } else if (keyName === 'additionalCosts') {
      variant = 'danger';
      buttonText = 'Additional Costs';
      if (!workOrderItem && Array.isArray(WorkOrderDetailsArray)) {
        validAdditionalCostsWorkOrderDetailsArray =
          WorkOrderDetailsArray.filter(
            item => parseFloat(item?.producing_quantity) > 0
          );

        if (validAdditionalCostsWorkOrderDetailsArray.length > 0) {
          disableActionButton = false;
        }
      }
    } else {
      variant = 'warning';
      releaselimit = activeWorkOrderItemProducingQty;
      fullyReleased = !!(workOrderItem && disableActionButton);
      buttonText = fullyReleased ? 'Fully Released' : 'Release';
    }
  } else if (keyName === 'delivery') {
    deliverylimit =
      (!isNaN(parseFloat(workOrderItem?.item?.released_quantity))
        ? parseFloat(workOrderItem.item.released_quantity)
        : 0) -
      (!isNaN(parseFloat(workOrderItem?.item?.delivered_quantity))
        ? parseFloat(workOrderItem.item.delivered_quantity)
        : 0);

    fullyDelivered = !!(
      workOrderItem &&
      workOrderItem.item?.released_quantity ===
        workOrderItem.item?.delivered_quantity &&
      activeWorkOrderItemProducingQty <= 0
    );

    disableActionButton = !!(deliverylimit <= 0 || fullyDelivered);
    variant = 'success';
    buttonText = fullyDelivered ? 'Fully Delivered' : 'Delivery';
  }

  function handleFilter(event) {
    setWorkOrderItem(event);
    if (typeof setActiveWorkOrderDetailId === 'function') {
      setActiveWorkOrderDetailId(event?.value);
    }

    if (TabkeyName?.length > 0 && setTabApiCallCompleted === 'function') {
      setTabApiCallCompleted(prev => ({
        ...prev,
        [TabkeyName]: false
      }));

      return;
    }

    typeof fetchData === 'function' && fetchData(1, event?.value);
  }

  return Array.isArray(WorkOrderDetailsArray) ? (
    <div className="d-flex flex-wrap align-items-end">
      <div
        className="mb-2 me-auto w-100"
        style={{
          maxWidth: 300
        }}
      >
        <p className="mb-1 small fw-bold">
          {Translate('Choose Work order item to filter')}
        </p>
        <InputGroup>
          <InputGroup.Text className="text-uppercase">
            <FaFilter size={10} />
          </InputGroup.Text>
          <div className="flex-fill">
            <WorkOrderDetailItemSelect
              optionsArray={WorkOrderDetailsArray}
              placeholder="Select..."
              value={workOrderItem}
              handleFieldChange={handleFilter}
            />
          </div>
        </InputGroup>
      </div>

      <div className="mb-2">
        <WorkOrderEntryActionButton
          variant={variant}
          keyName={keyName}
          workOrderId={workOrderId}
          workOrderDetailId={workOrderItem?.value}
          setTabApiCallCompleted={setTabApiCallCompleted}
          setWorkOrderVoucherData={setWorkOrderVoucherData}
          disabled={disableActionButton}
          releaselimit={releaselimit}
          deliverylimit={deliverylimit}
          WorkOrderDetailsArray={
            keyName === 'additionalCosts'
              ? validAdditionalCostsWorkOrderDetailsArray
              : WorkOrderDetailsArray
          }
        >
          {fullyReleased || fullyDelivered ? (
            <FaCheckCircle />
          ) : (
            <FaPlusCircle />
          )}
          <small>{Translate(buttonText)}</small>
        </WorkOrderEntryActionButton>
      </div>
    </div>
  ) : null;
}

WorkOrderTabViewFilterAndActionSection.propTypes = {
  workOrderId: PropTypes.string,
  WorkOrderDetailsArray: PropTypes.array,
  fetchData: PropTypes.func,
  setTabApiCallCompleted: PropTypes.func,
  keyName: PropTypes.string.isRequired,
  TabkeyName: PropTypes.string,
  setWorkOrderVoucherData: PropTypes.func,
  setActiveWorkOrderDetailId: PropTypes.func
};
