import { React, useContext, useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { AuthWizardContext } from 'context/Context';
import ItemDetails from 'components/item-details/ItemDetails';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';

function InventoryAdjustmentView({ inventoryAdjustmentVoucherData }) {
  const { user } = useContext(AuthWizardContext);
  const enableBatchSection = !!+user?.branch?.inventory_settings?.enable_batch;
  const Translate = useAxisproTranslate();
  const [lineTotal, setLineTotal] = useState([]);
  const [value, setValue] = useState({ value: 0 });
  const [showItemDetailsArea, setShowItemDetailsArea] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const permission = {
    show: getAcodaxPermissionSlug('IT', 'show')
  };

  const clickOnItem = id => {
    setValue({ value: id });
    setShowItemDetailsArea(true);
  };

  const handleItemDetailsArea = () => {
    setShowItemDetailsArea(!showItemDetailsArea);
    setValue({ value: 0 });
  };

  useEffect(() => {
    if (
      inventoryAdjustmentVoucherData.length !== 0 &&
      inventoryAdjustmentVoucherData.trans_object
    ) {
      let quantity = inventoryAdjustmentVoucherData.trans_object.map(
        item => item.quantity
      );
      let price = inventoryAdjustmentVoucherData.trans_object.map(
        item => item.price
      );
      let total = inventoryAdjustmentVoucherData.trans_object.map(
        item => item.price * item.quantity
      );
      if (quantity.length !== 0) {
        let totalQuantitySum = quantity.reduce((a, b) => a + b);
        setTotalQuantity(totalQuantitySum);
      }
      if (price.length !== 0) {
        let totalPriceSum = price.reduce((a, b) => a + b);
        setTotalPrice(totalPriceSum);
      }
      if (price.length !== 0) {
        let lineTotalSum = total.reduce((a, b) => a + b);
        setLineTotal(lineTotalSum);
      }
    }
  }, [inventoryAdjustmentVoucherData]);

  return (
    <>
      <Table
        striped
        bordered
        hover
        responsive
        className="mt-2 simple-table-animation"
      >
        <thead className="bg-dark text-light text-uppercase">
          <tr>
            <th className="fs--2 p-2 text-center">#</th>
            <th className="fs--2 p-2 text-center">{Translate('Item Code')}</th>
            <th className="fs--2 p-2 text-center">{Translate('Item Name')}</th>
            {enableBatchSection ? (
              <th className="fs--2 p-2 text-center">{Translate('Batch')}</th>
            ) : null}

            <th className="fs--2 p-2 text-center">
              {Translate('Costing Account')}
            </th>
            <th className="fs--2 p-2 text-center">{Translate('Unit')}</th>
            <th className="fs--2 p-2 text-center">{Translate('Reason')}</th>
            <th className="fs--2 p-2 text-center">{Translate('Quantity')}</th>
            <th className="fs--2 p-2 text-center">{Translate('Price')}</th>
            <th className="fs--2 p-2 text-center">{Translate('Total')}</th>
          </tr>
        </thead>
        {inventoryAdjustmentVoucherData &&
          inventoryAdjustmentVoucherData.trans_object &&
          inventoryAdjustmentVoucherData.trans_object.map((item, key) => (
            <tbody key={key} className="text-dark">
              <tr>
                <td className="p-2 text-center">{key + 1}</td>
                <th className="p-2 text-center">
                  {permission?.show ? (
                    <a href="#" onClick={() => clickOnItem(item.item_id)}>
                      {item.stock_id}
                    </a>
                  ) : (
                    item.stock_id
                  )}
                </th>
                <th className="p-2 text-center">{item.item_name}</th>
                {enableBatchSection ? (
                  <th className="p-2 text-center">
                    {item?.batch_number_display &&
                    item?.batch_number_display?.length > 0
                      ? item.batch_number_display
                      : item?.batch_number && item?.batch_number?.length > 0
                      ? item.batch_number
                      : '-'}
                  </th>
                ) : null}

                <th className="p-2 text-center">
                  {item && item.account_name ? item.account_name : '-'}
                </th>
                <th className="p-2 text-center">{item.unit_name}</th>
                <th className="p-2 text-center">{item.reason_name}</th>
                <th className="p-2 text-center">{item.quantity}</th>
                <th className="p-2 text-center">{item.price_formatted}</th>
                <th className="p-2 text-center">
                  {Math.abs(item.price * item.quantity).toLocaleString(
                    navigator.language,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  )}
                </th>
              </tr>
            </tbody>
          ))}
        <tfoot>
          <tr className="text-dark">
            <td></td>
            <td></td>
            {enableBatchSection && <td></td>}
            <td></td>
            <td></td>
            <td></td>
            <td className="p-2 fw-bold text-end">{Translate('Total')}</td>
            <td className="p-2 fw-bold text-center">{totalQuantity}</td>
            <td className="p-2 fw-bold text-center">
              {Math.abs(totalPrice).toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </td>
            <td className="p-2 fw-bold text-center">
              {Math.abs(lineTotal).toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </td>
          </tr>
        </tfoot>
      </Table>
      {inventoryAdjustmentVoucherData && inventoryAdjustmentVoucherData.memo ? (
        <Row
          style={{ border: '1px dashed grey' }}
          className="pt-2 pb-2 pe-1 ps-1 ms-1 me-1"
        >
          <Col xs={12} className="p-0">
            <h6 className="mb-2 fw-bold">{Translate('Notes')}:</h6>
          </Col>
          <Col xs={12} className="ms-3">
            <h6 className="mb-2">{inventoryAdjustmentVoucherData.memo}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      <ItemDetails
        show={showItemDetailsArea}
        onHide={handleItemDetailsArea}
        value={value}
        showItemDetailsArea={showItemDetailsArea}
      />
    </>
  );
}

InventoryAdjustmentView.propTypes = {
  inventoryAdjustmentVoucherData: PropTypes.object
};

export default InventoryAdjustmentView;
