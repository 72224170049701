import axios from 'axios';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import PopupFormButton from 'components/popup-form-button/PopupFormButton';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaUserPlus } from 'react-icons/fa';
import SelectTax from '../SelectTax';
import SelectCurrencyCode from '../SelectCurrencyCode';
import PhoneNumberInput from '../PhoneNumberInput';
import SelectSalesType from '../SelectSalesType';
import SelectCreditStatus from '../SelectCreditStatus';
import SelectPaymentTerms from '../SelectPaymentTerms';
import { AuthWizardContext } from 'context/Context';

const CustomerPopupForm = ({
  callBack,
  setData,
  labelName,
  keyName,
  name,
  showAddButton,
  onHide,
  show,
  includeDetailsKey
}) => {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [showWindow, setShowWindow] = useState(show ?? false);
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [showCollapse, setshowCollapse] = useState(false);
  let formDataKeys = {
    name: '',
    address: '',
    email: '',
    phone: '',
    trn_no: '',
    tax_id: user?.branch?.sales_settings?.tax_id ?? '',
    tax_id_ref: user?.branch?.sales_settings?.tax_id
      ? {
          label: user?.branch?.sales_settings?.tax_name,
          value: user?.branch?.sales_settings?.tax_id
        }
      : '',
    currency_code: user?.branch?.currency_name ?? '',
    currency_code_ref: user?.branch?.currency_name
      ? {
          label: user?.branch?.currency_name,
          value: user?.branch?.currency_name
        }
      : '',
    sales_type_id: user?.branch?.sales_settings?.sales_type_id ?? '',
    sales_type_id_ref: {
      label: user?.branch?.sales_settings?.sales_type_name ?? '',
      value: user?.branch?.sales_settings?.sales_type_id ?? ''
    },
    credit_status_id: user?.branch?.sales_settings?.credit_status_id ?? '',
    credit_status_id_ref: {
      label: user?.branch?.sales_settings?.credit_status_name ?? '',
      value: user?.branch?.sales_settings?.credit_status_id ?? ''
    },
    payment_term_id: user?.branch?.sales_settings?.payment_term_id ?? '',
    payment_term_id_ref: {
      label: user?.branch?.sales_settings?.payment_term_name ?? '',
      value: user?.branch?.sales_settings?.payment_term_id ?? ''
    }
  };
  const [formData, setFormData] = useState(formDataKeys);
  const handleShow = () => {
    setShowWindow(!showWindow);
    if (show) {
      setFormData(formDataKeys);
      setFormError({});
      setIsSaving(false);
      setshowCollapse(false);
      if (onHide && typeof onHide === 'function') onHide();
    }
  };
  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };
  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setIsSaving(true);

    axios({
      method: 'post',
      url: 'sales/customers',
      data: formData
    })
      .then(response => {
        if (response?.data?.success !== true || !response?.data?.data?.id) {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
          return;
        }

        const customerDetails = response.data.data;
        showToast(response.data.message, 'success');
        if (callBack) {
          callBack(customerDetails);
        }

        if (customerDetails && setData) {
          setData(
            {
              label:
                labelName === 'default'
                  ? customerDetails?.customer_id + ' - ' + customerDetails.name
                  : customerDetails[labelName ?? 'name'],
              value: customerDetails[keyName ?? 'id'],
              ...(includeDetailsKey
                ? { customer_details: customerDetails }
                : null)
            },
            {
              action: 'select-option',
              option: undefined,
              name: name ?? 'customer'
            }
          );
        }

        handleShow();
      })
      .catch(error => {
        showToast(
          error?.response?.data?.message ??
            error?.message ??
            'Something went wrong, please refresh the page and try again.',
          'error'
        );
        setFormError({ ...(error?.response?.data?.data?.errors ?? null) });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    setShowWindow(show ?? false);
    return () => {
      setFormData(formDataKeys);
      setFormError({});
      setShowWindow(false);
      setIsSaving(false);
      setshowCollapse(false);
    };
  }, [show]);

  return (
    <>
      {showAddButton ? (
        <PopupFormButton onClick={handleShow}>
          {Translate('Add Customer')}
        </PopupFormButton>
      ) : null}

      {showWindow ? (
        <Modal
          show={showWindow}
          onHide={handleShow}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="d-flex flex-row align-items-center gap-1">
                <FaUserPlus size={20} className="text-success" />
                <span>{Translate('ADD CUSTOMER')}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(formError).length > 0 && (
                <ErrorAlert formError={formError} setFormError={setFormError} />
              )}

              <Row sm={1} md={1} lg={2}>
                <Form.Group className="mb-3" controlId="form.name" as={Col}>
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Name')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <Form.Control
                        type="text"
                        name="name"
                        onChange={handleFieldChange}
                        value={formData.name}
                      />
                      <FormErrorPopover
                        id="name"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.name}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3" controlId="form.phone" as={Col}>
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Phone')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <PhoneNumberInput
                        name="phone"
                        onChange={handleFieldChange}
                        value={formData.phone}
                      />
                      <FormErrorPopover
                        id="phone"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.phone}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3" controlId="form.email" as={Col}>
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Email')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <Form.Control
                        type="email"
                        name="email"
                        onChange={handleFieldChange}
                        value={formData.email}
                      />
                      <FormErrorPopover
                        id="email"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.email}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3" controlId="form.tax_id" as={Col}>
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Tax')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <div className="w-100">
                        <SelectTax
                          name="tax_id"
                          value={formData.tax_id_ref}
                          handleFieldChange={handleFieldChange}
                        />
                      </div>
                      <FormErrorPopover
                        id="tax_id"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.tax_id}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="form.currency_code"
                  as={Col}
                >
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Currency Code')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <div className="w-100">
                        <SelectCurrencyCode
                          name="currency_code"
                          keyName="code"
                          value={formData.currency_code_ref}
                          handleFieldChange={handleFieldChange}
                          isClearable={false}
                        />
                      </div>
                      <FormErrorPopover
                        id="currency_code"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.currency_code}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3" controlId="form.trn_no" as={Col}>
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('GSTIN/UIN Number')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <Form.Control
                        type="text"
                        name="trn_no"
                        onChange={handleFieldChange}
                        value={formData.trn_no}
                      />
                      <FormErrorPopover
                        id="trn_no"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.trn_no}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Row>

              <Button
                className="p-0 m-0 outline-none shadow-none text-info"
                onClick={() => setshowCollapse(!showCollapse)}
                aria-controls="customer-popup-form-collapse-area"
                aria-expanded={showCollapse}
                variant="transparent"
                size="sm"
              >
                {Translate(`Show ${showCollapse ? 'Less' : 'More'}`)}
              </Button>
              <Collapse in={showCollapse}>
                <Row sm={1} md={1} lg={2} className="mt-3">
                  <Form.Group
                    className="mb-3"
                    controlId="form.sales_type_id"
                    as={Col}
                  >
                    <Row>
                      <Col md={5} sm={12}>
                        <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                          {Translate('Sales Type')}
                        </Form.Label>
                      </Col>
                      <Col
                        md={7}
                        sm={12}
                        className="d-flex flex-row align-items-center"
                      >
                        <div className="w-100">
                          <SelectSalesType
                            name="sales_type_id"
                            value={formData.sales_type_id_ref}
                            handleFieldChange={handleFieldChange}
                          />
                        </div>
                        <FormErrorPopover
                          id="sales_type_id"
                          totalErrorCount={Object.keys(formError).length}
                          errorMessage={formError.sales_type_id}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="form.credit_status_id"
                    as={Col}
                  >
                    <Row>
                      <Col md={5} sm={12}>
                        <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                          {Translate('Credit Status')}
                        </Form.Label>
                      </Col>
                      <Col
                        md={7}
                        sm={12}
                        className="d-flex flex-row align-items-center"
                      >
                        <div className="w-100">
                          <SelectCreditStatus
                            name="credit_status_id"
                            value={formData?.credit_status_id_ref}
                            handleFieldChange={handleFieldChange}
                          />
                        </div>
                        <FormErrorPopover
                          id="credit_status_id"
                          totalErrorCount={Object.keys(formError).length}
                          errorMessage={formError.credit_status_id}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="form.payment_term_id"
                    as={Col}
                  >
                    <Row>
                      <Col md={5} sm={12}>
                        <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                          {Translate('Payment Terms')}
                        </Form.Label>
                      </Col>
                      <Col
                        md={7}
                        sm={12}
                        className="d-flex flex-row align-items-center"
                      >
                        <div className="w-100">
                          <SelectPaymentTerms
                            name="payment_term_id"
                            value={formData.payment_term_id_ref}
                            handleFieldChange={handleFieldChange}
                          />
                        </div>
                        <FormErrorPopover
                          id="payment_term_id"
                          totalErrorCount={Object.keys(formError).length}
                          errorMessage={formError.payment_term_id}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="form.address"
                    as={Col}
                  >
                    <Row>
                      <Col md={5} sm={12}>
                        <Form.Label className="m-0 d-flex justify-content-start h-100">
                          {Translate('Address')}
                        </Form.Label>
                      </Col>
                      <Col md={7} sm={12} className="d-flex flex-row">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="address"
                          onChange={handleFieldChange}
                          value={formData.address}
                        />
                        <FormErrorPopover
                          id="address"
                          totalErrorCount={Object.keys(formError).length}
                          errorMessage={formError.address}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
              </Collapse>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-row justify-content-start">
              <Button
                variant="outline-success"
                size="sm"
                className="d-flex align-items-center justify-content-center"
                onClick={handleSubmit}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="success"
                      className="me-1"
                    />
                    <span className="fs--1">{Translate('Saving...')}</span>
                  </>
                ) : (
                  <span>{Translate('Add')}</span>
                )}
              </Button>
              <Button
                variant="outline-danger"
                onClick={handleShow}
                size="sm"
                disabled={isSaving}
              >
                {Translate('Cancel')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

CustomerPopupForm.propTypes = {
  callBack: PropTypes.func,
  setData: PropTypes.func,
  name: PropTypes.string,
  labelName: PropTypes.string,
  keyName: PropTypes.string,
  show: PropTypes.bool,
  showAddButton: PropTypes.bool,
  onHide: PropTypes.func,
  includeDetailsKey: PropTypes.bool
};

export default CustomerPopupForm;
