import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import handlePrint from 'helpers/printHelper';

function ViewPagePrintButton({ url, pageTitle, title, onHide, ...rest }) {
  const handleButton = () => {
    if (typeof onHide === 'function') {
      onHide();
    }
    handlePrint(url, pageTitle ?? '');
  };
  return (
    <Button {...rest} onClick={handleButton} size="sm">
      <FontAwesomeIcon icon="file-pdf" size="sm" /> {title ?? ''}
    </Button>
  );
}

ViewPagePrintButton.propTypes = {
  url: PropTypes.string,
  pageTitle: PropTypes.string,
  title: PropTypes.string,
  iconSize: PropTypes.number,
  onHide: PropTypes.func
};

export default ViewPagePrintButton;
