import React from 'react';
import PropTypes from 'prop-types';
import BackButton from 'components/common/BackButton';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const PurchaseFormCancelButton = ({ onSave, draftIsSaving, ...rest }) => {
  const Translate = useAxisproTranslate();
  return !onSave && !draftIsSaving ? (
    <BackButton size="sm" {...rest}>
      {Translate('Cancel')}
    </BackButton>
  ) : null;
};

PurchaseFormCancelButton.propTypes = {
  onSave: PropTypes.bool.isRequired,
  draftIsSaving: PropTypes.bool
};

export default PurchaseFormCancelButton;
