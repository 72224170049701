import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { FaArrowRightArrowLeft } from 'react-icons/fa6';

function BranchToBranch({ badgeColor, size, className }) {
  return (
    <Badge
      bg={badgeColor}
      className={`${className} d-flex gap-1 align-items-center justify-content-center`}
      title="Branch To Branch Transaction"
    >
      <FaHome size={size} />
      <FaArrowRightArrowLeft size={size} />
      <FaHome size={size} />
    </Badge>
  );
}

BranchToBranch.propTypes = {
  badgeColor: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.any
};

export default BranchToBranch;
