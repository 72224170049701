import React from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BsHandbagFill } from 'react-icons/bs';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useState } from 'react';
import { useEffect } from 'react';
import { showToast } from 'module/Common/Toast/toast';
import TableLoading from 'module/Report/Common/TableLoading';
import { propTypes } from 'react-bootstrap/esm/Image';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

function PurchaseTableFormKitLineItem({
  entry,
  index,
  onTableItemChange,
  isLoading,
  readOnly = false
}) {
  const Translate = useAxisproTranslate();
  const [kitQuantity, setKitQuantity] = useState(0);

  let disableItemEditButton = false;
  let deleteOverlayMessage = undefined;

  if (!entry?.edit_ref) {
    if (entry?.qty_sent_ref > 0) {
      disableItemEditButton = true;
      deleteOverlayMessage =
        'The item has already been sent, so this item cannot be deleted';
    }

    if (entry?.qty_invoiced_ref > 0) {
      disableItemEditButton = true;
      deleteOverlayMessage =
        'The item has already been invoiced, so this item cannot be deleted';
    }
  }

  const handleFocus = e => e.target.select();

  const handleQuantity = e => {
    let value = e.target.value;
    if (value < 1) {
      return showToast(Translate('Invalid quantity'), 'error');
    }

    setKitQuantity(value);
  };

  useEffect(() => {
    setKitQuantity(entry.base_quantity_ref);
    return () => {
      setKitQuantity(0);
    };
  }, [entry.edit_ref]);

  return isLoading ? (
    <TableLoading row={1} column={10} />
  ) : (
    <tr>
      <td className="fs--1 text-dark p-1 text-center">{index + 1}</td>
      <td className="fs--1 text-dark p-1">{entry.stock_id}</td>
      <td className="fs--1 text-dark p-1">
        <div className="d-flex flex-row justify-content-between align-items-center">
          {entry.item_display_name}
          {parseInt(entry.is_kit) && !entry.kit_id ? (
            <BsHandbagFill style={{ fill: 'red' }} size={11} className="mx-2" />
          ) : null}
        </div>
      </td>
      <td className="fs--1 text-dark p-1">
        {entry.unit_ref ?? entry.unit_name}
      </td>
      <td className="fs--1 text-dark p-1">
        {entry.edit_ref ? (
          <Form.Control
            type="number"
            min={1}
            value={kitQuantity}
            onChange={handleQuantity}
            onFocus={handleFocus}
          />
        ) : (
          entry.base_quantity_ref
        )}
      </td>
      <td className="fs--1 text-dark p-1">{entry.quantity}</td>
      <td className="fs--1 text-dark p-1">
        {entry.unit_price_formatted ?? '-'}
      </td>
      <td className="fs--1 text-dark p-1">{entry.unit_tax_formatted ?? '-'}</td>
      <td className="fs--1 text-dark p-1">
        {entry.discount_amount < 1 ? (
          '-'
        ) : entry.discount_unit_ref === '%' ? (
          <>{entry.discount_percent}%</>
        ) : (
          <>{entry.discount_amount_formatted ?? '-'}</>
        )}
      </td>
      <td className="fs--1 text-dark p-1 fw-bolder">
        {entry.line_total_formatted ?? '-'}
      </td>
      {!readOnly && (
        <td>
          <div className="d-flex flex-row align-items-center justify-content-center p-1">
            {onTableItemChange && (
              <Button
                size="sm"
                variant="transparent"
                className={`p-0 mx-1 text-${
                  !entry.edit_ref ? 'info' : 'success'
                }`}
                onClick={() =>
                  onTableItemChange(
                    { ...entry, base_quantity_ref: kitQuantity },
                    !entry.edit_ref
                      ? 'EDIT'
                      : parseFloat(kitQuantity) !==
                        parseFloat(entry.base_quantity_ref)
                      ? 'UPDATE'
                      : 'CANCEL'
                  )
                }
                style={{ boxShadow: 'none' }}
              >
                {!entry.edit_ref ? (
                  <FontAwesomeIcon icon="edit" size="sm" />
                ) : (
                  <IoMdCheckmarkCircle size={20} />
                )}
              </Button>
            )}
            {onTableItemChange && (
              <OverlayTrigger
                overlay={
                  deleteOverlayMessage ? (
                    <Tooltip>
                      <small>{Translate(deleteOverlayMessage)}</small>
                    </Tooltip>
                  ) : (
                    <div></div>
                  )
                }
              >
                <span>
                  <Button
                    size="sm"
                    variant="transparent"
                    className="p-0 mx-1 text-danger"
                    onClick={() =>
                      !disableItemEditButton
                        ? onTableItemChange(
                            entry,
                            !entry.edit_ref ? 'REMOVE' : 'CANCEL'
                          )
                        : undefined
                    }
                    style={{ boxShadow: 'none' }}
                    disabled={disableItemEditButton}
                  >
                    {!entry.edit_ref ? (
                      <FontAwesomeIcon icon="trash" size="sm" />
                    ) : (
                      <AiFillCloseCircle size={19} />
                    )}
                  </Button>
                </span>
              </OverlayTrigger>
            )}
          </div>
        </td>
      )}
    </tr>
  );
}

PurchaseTableFormKitLineItem.propTypes = {
  index: PropTypes.number,
  entry: PropTypes.object,
  setFormData: PropTypes.func,
  onTableItemChange: PropTypes.func,
  isLoading: propTypes.bool,
  readOnly: PropTypes.bool
};

export default PurchaseTableFormKitLineItem;
