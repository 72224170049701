import { React } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import AfterSaveViewButton from './components/AfterSaveViewButton';
import ViewPagePrintButton from 'module/Common/ViewPages/Common/ViewPagePrintButton';

function AfterSavePaymentModal({
  show,
  onHide,
  data,
  dataProcessing,
  afterSave
}) {
  const Translate = useAxisproTranslate();

  const viewPageUrl = `${
    afterSave?.viewButton?.url
  }${`?entry=${data?.id}&layout=column`}`;

  return (
    <Modal
      show={!dataProcessing && show}
      onHide={onHide}
      size="md"
      centered
      backdrop={'static'}
      className="custom-modal-position"
    >
      <Card>
        <Card.Header className="after-save-modal-header pb-0">
          <Button variant="transparent" onClick={onHide}>
            <MdClose size={20} />
          </Button>
        </Card.Header>
        <Card.Body className="pt-0">
          <h1 className="fs--1">{Translate(afterSave?.mainData) ?? ''}</h1>
          <h1 className="fs-1">{data?.reference ?? ''}</h1>
        </Card.Body>
        <Card.Footer className="d-flex gap-1 align-items-center justify-content-center pt-0">
          <ViewPagePrintButton
            title={'Print'}
            variant="danger"
            className="text-white"
            style={{ boxShadow: 'none' }}
            url={`${afterSave?.printButton?.url}/${data?.id}`}
            pageTitle={`${afterSave?.printButton?.pageTitle}`}
            onHide={onHide}
          />
          <AfterSaveViewButton
            variant="info"
            className="text-white"
            title={afterSave?.viewButton?.title}
            url={viewPageUrl}
          />
        </Card.Footer>
      </Card>
    </Modal>
  );
}

AfterSavePaymentModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  dataProcessing: PropTypes.bool,
  data: PropTypes.any,
  afterSave: PropTypes.any
};

export default AfterSavePaymentModal;
