import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';

export default function EstimateEntryCostLineItem({
  type,
  data,
  setFormData,
  ItemIndex,
  handleSelectItems,
  showActionButtons,
  isPreview
}) {
  let itemKeyName = type === 'AdditionalCost' ? 'additional_costs' : 'costs';
  function handleEdit(event) {
    event.preventDefault();
    setFormData(prev => ({
      ...prev,
      [itemKeyName]: Array.isArray(prev?.[itemKeyName])
        ? prev[itemKeyName].map((entry, index) => {
            delete entry?.edit;
            if (index === ItemIndex) {
              entry.edit = true;
            }
            return entry;
          })
        : prev?.[itemKeyName]
    }));
  }

  function removeSelectedItem(dataArray, index) {
    if (Array.isArray(dataArray) && dataArray?.[index]) {
      dataArray.splice(index, 1);
    }
    return dataArray;
  }

  function handleRemove(event) {
    event.preventDefault();
    setFormData(prev => ({
      ...prev,
      [itemKeyName]: Array.isArray(prev?.[itemKeyName])
        ? removeSelectedItem(prev[itemKeyName], ItemIndex)
        : prev?.[itemKeyName]
    }));
  }

  return (
    <>
      <tr className="result-items">
        {handleSelectItems ? (
          <td className="result-item">
            <Form.Check
              checked={!!data?.selected_ref}
              onChange={event => handleSelectItems(event, ItemIndex)}
            />
          </td>
        ) : null}
        <td>{ItemIndex + 1}</td>
        <td>
          <div className="result-item">{data?.stock_id ?? '-'}</div>
        </td>
        <td>
          <div className="result-item">{data?.title_ref?.label ?? '-'}</div>
        </td>
        <td>
          <div className="result-item">
            {data?.quantity ?? '-'} {data?.unit_name_ref ?? ''}
          </div>
        </td>
        <td>
          <div className="result-item">
            {data?.amount_formatted ?? data?.amount ?? '-'}
          </div>
        </td>
        <td>
          <div className="result-item">
            {data?.total_amount_formatted ?? data?.line_total_ref ?? '-'}
          </div>
        </td>
        {!isPreview ? (
          <td>
            {showActionButtons ? (
              <div className="action-buttons d-flex align-items-center justify-content-center gap-1">
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      <small>Edit line item details</small>
                    </Tooltip>
                  }
                >
                  <Button
                    variant="transparent"
                    type="button"
                    className="p-0 d-flex align-items-center justify-content-center text-info outline-none shadow-none"
                    onClick={handleEdit}
                  >
                    <FaEdit size={12} />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      <small>Remove line item</small>
                    </Tooltip>
                  }
                >
                  <Button
                    variant="transparent"
                    type="button"
                    className="p-0 d-flex align-items-center justify-content-center text-danger outline-none shadow-none"
                    onClick={handleRemove}
                  >
                    <FaTrash size={12} />
                  </Button>
                </OverlayTrigger>
              </div>
            ) : null}
          </td>
        ) : null}
      </tr>
    </>
  );
}

EstimateEntryCostLineItem.propTypes = {
  data: PropTypes.object,
  setFormData: PropTypes.func,
  ItemIndex: PropTypes.number,
  showActionButtons: PropTypes.bool,
  handleSelectItems: PropTypes.func,
  type: PropTypes.oneOf(['AdditionalCost']),
  isPreview: PropTypes.bool
};
