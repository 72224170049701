import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';

import { FcNoIdea } from 'react-icons/fc';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import { BarChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { tooltipFormatter } from 'helpers/echart-utils';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Loader from '../Common/Loader';
import { dailyBasedTurnOver } from '../Common/LoaderData';

echarts.use([
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  CanvasRenderer,
  LegendComponent
]);

function DailyTurnOver({ draggable }) {
  const Translate = useAxisproTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [mapData, setMapData] = useState({
    totalIncome: [0, 0, 0, 0, 0, 0, 0],
    totalExpense: [0, 0, 0, 0, 0, 0, 0],
    totalCost: [0, 0, 0, 0, 0, 0, 0],
    turnOver: [0, 0, 0, 0, 0, 0, 0]
  });

  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  const getOption = mapData => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    legend: {
      left: 'right',
      top: -5,
      data: [
        Translate('Total Income'),
        Translate('Total Expense'),
        Translate('Total Cost'),
        Translate('Turn Over')
      ],
      textStyle: {
        color: getColor('700')
      }
    },
    xAxis: {
      type: 'category',
      data: days,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'solid'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('600'),
        formatter: value => value.substring(0, 3),
        margin: 15
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        formatter: value => value / 1000 + 'k',
        color: getColor('600'),
        margin: 15
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 0
    },
    series: [
      {
        type: 'bar',
        name: Translate('Total Income'),
        data: mapData.totalIncome,
        lineStyle: { color: getColor('info') },
        itemStyle: {
          color: getColor('info'),
          borderRadius: [3, 3, 0, 0]
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      },
      {
        type: 'bar',
        name: Translate('Total Expense'),
        data: mapData.totalExpense,
        lineStyle: { color: getColor('danger') },
        itemStyle: {
          color: getColor('danger'),
          borderRadius: [3, 3, 0, 0]
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      },
      {
        type: 'bar',
        name: Translate('Total Cost'),
        data: mapData.totalCost,
        lineStyle: { color: getColor('warning') },
        itemStyle: {
          color: getColor('warning'),
          borderRadius: [3, 3, 0, 0]
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      },
      {
        type: 'bar',
        name: Translate('Turn Over'),
        data: mapData.turnOver,
        lineStyle: { color: getColor('success') },
        itemStyle: {
          color: getColor('success'),
          borderRadius: [3, 3, 0, 0]
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      }
    ],
    grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
  });

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get('sales/get-daily-based-turn-over')
      .then(res => {
        if (res.data.success) {
          res.data.data.map((items, index) => {
            setMapData(prev => ({
              ...prev,
              totalIncome: [
                ...prev.totalIncome.slice(0, index),
                items.total_income,
                ...prev.totalIncome.slice(index + 1)
              ],
              totalExpense: [
                ...prev.totalExpense.slice(0, index),
                items.total_expense,
                ...prev.totalExpense.slice(index + 1)
              ],
              totalCost: [
                ...prev.totalCost.slice(0, index),
                items.total_cost,
                ...prev.totalCost.slice(index + 1)
              ],
              turnOver: [
                ...prev.turnOver.slice(0, index),
                items.turn_over,
                ...prev.turnOver.slice(index + 1)
              ]
            }));
          });
        }
        setIsLoading(false);
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card className={`h-100 ${draggable && 'dashboard_shake'}`}>
      <Card.Header>
        <h6 className="m-0">{Translate('Daily Based Turn Over')}</h6>
      </Card.Header>
      {!isLoading ? (
        mapData ? (
          <>
            <Card.Body className="pt-0">
              <ReactEChartsCore
                echarts={echarts}
                option={getOption(mapData)}
                style={{ height: '19rem' }}
              />
            </Card.Body>
          </>
        ) : (
          <Card.Body className="d-flex flex-column justify-content-center align-items-center">
            <FcNoIdea size={30} />
            {Translate('No Data Available')}
          </Card.Body>
        )
      ) : (
        <Loader loader={dailyBasedTurnOver} className={'ps-4'} />
      )}
    </Card>
  );
}

DailyTurnOver.propTypes = {
  draggable: PropTypes.bool
};
export default DailyTurnOver;
