import { React, useState } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import VoucherDetail from 'components/voucher-details/VoucherDetail';

function BatchTable({ data }) {
  const Translate = useAxisproTranslate();
  const [voucherId, setVoucherId] = useState();
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);

  const clickOnVoucher = voucher_id => {
    setVoucherId(voucher_id);
    setShowVoucherDetailArea(true);
  };

  const handleVoucherDetailsArea = () => {
    setShowVoucherDetailArea(!showVoucherDetailArea);
  };

  return (
    <>
      <Table
        striped
        bordered
        hover
        responsive
        className="p-0 simple-table-animation"
      >
        <thead className="bg-dark text-light text-uppercase">
          <tr>
            <th className="fs--2 p-2 text-center">#</th>
            <th className="fs--2 p-2 text-center">{Translate('Date')}</th>
            <th className="fs--2 p-2 text-center">{Translate('Reference')}</th>
            <th className="fs--2 p-2 text-center">{Translate('Trans Type')}</th>
            <th className="fs--2 p-2 text-center">{Translate('Stock In')}</th>
            <th className="fs--2 p-2 text-center">{Translate('Stock Out')}</th>
          </tr>
        </thead>
        {data &&
          data.length > 0 &&
          data.map((item, key) => (
            <tbody key={key} className="text-dark">
              <tr>
                <th className="p-2 text-center">{key + 1}</th>
                <th className="p-2 text-center">{item.trans_date_formatted}</th>
                <th className="p-2 text-center">
                  {getAcodaxPermissionSlug(item.trans_type, 'show') ? (
                    <a href="#" onClick={() => clickOnVoucher(item.trans_no)}>
                      {item.reference}
                    </a>
                  ) : (
                    item.reference
                  )}
                </th>
                <th className="p-2 text-center text-uppercase">
                  {Translate(item.trans_type_name)}
                </th>
                <th className="p-2 text-center">
                  {item.stock_in ? item.stock_in : '-'}
                </th>
                <th className="p-2 text-center">
                  {item.stock_out ? item.stock_out : '-'}
                </th>
              </tr>
            </tbody>
          ))}
      </Table>
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={handleVoucherDetailsArea}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        voucherId={voucherId}
        infoPage={true}
        offCanvas={true}
      />
    </>
  );
}

BatchTable.propTypes = {
  data: PropTypes.object
};

export default BatchTable;
