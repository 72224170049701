import { React, useContext, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import { Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AiFillQuestionCircle } from 'react-icons/ai';
import { BiRepeat } from 'react-icons/bi';

import { StoreContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import { CheckZeroOrLessThanZeroValidator } from 'module/Common/Validators/CheckZeroOrLessThanZero';
import { CancelButton, SaveButton } from 'module/Common/Buttons/CommonButton';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import ErrorAlert from 'module/Common/Error/ErrorAlert';

function RecurringRules({ show, type, id, onHide, fetchData }) {
  const { store } = useContext(StoreContext);
  const Translate = useAxisproTranslate();

  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);

  useLayoutEffect(() => {
    setFormData({
      transaction_type: type,
      transaction_id: id,
      is_unlimited: 0,
      repeat_every: 1,
      repeat_type: 'days',
      start_date: store.currentDate
    });
  }, [id]);

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleUnlimitedField = e => {
    let newOldData = {
      ...formData,
      [e.target.name]: !e.target.checked ? 0 : 1,
      end_date: e.target.checked ? '' : formData.end_date
    };
    setFormData(newOldData);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (
      CheckZeroOrLessThanZeroValidator(
        formData.repeat_every,
        'repeat every',
        setFormError
      )
    ) {
      setOnSave(true);
      axios({
        method: 'post',
        url: 'finance/recurrent-profiles',
        data: formData
      })
        .then(response => {
          if (response.data.success === true) {
            showToast(response.data.message, 'success');
            onHide();
            fetchData();
          } else {
            showToast(
              'Something went wrong, please refresh the page and try again.',
              'error'
            );
          }
          setOnSave(false);
        })
        .catch(error => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            const validation_error =
              error.response.data &&
              error.response.data.data &&
              error.response.data.data.errors
                ? error.response.data.data.errors
                : '';
            validation_error && setFormError({ ...validation_error });

            setOnSave(false);
          } else {
            showToast(
              'Something went wrong, please refresh the page and try again.',
              'error'
            );
            onHide();
            setOnSave(false);
          }
        });
    }
  };

  return (
    <Modal
      show={show}
      size="lg"
      className="modal-with-overlay"
      backdrop="static"
    >
      <Card>
        <Card.Header className="border-bottom text-uppercase fs-1 text-dark d-flex align-items-center gap-1">
          <BiRepeat className="text-dark" size={20} />
          {Translate('Make Recurring')}
        </Card.Header>
        <Card.Body>
          {!Object.keys(formError).length == 0 ? (
            <ErrorAlert setFormError={setFormError} formError={formError} />
          ) : (
            ''
          )}
          <Container>
            <Row>
              <Form.Group
                as={Col}
                xxl={6}
                xl={6}
                md={6}
                sm={12}
                className="mb-3"
              >
                <Row>
                  <Col xl={4} md={4} sm={12} className="p-0">
                    <Form.Label className="require-data text-dark m-0 d-flex align-items-center justify-content-start">
                      {Translate('Start Date')}
                    </Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12} className="ps-0">
                    <Row className="d-flex flex-row">
                      <Col xl={10} md={10} sm={11} className="pe-sm-0 flex-2">
                        <AppDatePicker
                          name="start_date"
                          value={formData.start_date}
                          yearPlaceholder="yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          onChange={handleFieldChange}
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className={
                          'text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1'
                        }
                      >
                        {formError.start_date ? (
                          <FormErrorPopover
                            id="recurring_start_date"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.start_date}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                as={Col}
                xxl={6}
                xl={6}
                md={6}
                sm={12}
                className="mb-3"
              >
                <Row>
                  <Col xl={4} md={4} sm={12} className="p-0">
                    <Form.Label className="text-dark m-0 d-flex align-items-center justify-content-start">
                      {Translate('Is Unlimited?')}
                    </Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12} className="ps-0">
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                          <Form.Check
                            className="m-0"
                            type="switch"
                            name="is_unlimited"
                            checked={formData.is_unlimited == 1 ? true : false}
                            onChange={handleUnlimitedField}
                            value={formData.is_unlimited}
                            style={{ boxShadow: 'none' }}
                          />
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              {formData.is_unlimited == 0 ? (
                <Form.Group
                  as={Col}
                  xxl={6}
                  xl={6}
                  md={6}
                  sm={12}
                  className="mb-3"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12} className="p-0">
                      <Form.Label className="text-dark m-0 d-flex align-items-center justify-content-start">
                        {Translate('End Date')}
                      </Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12} className="ps-0">
                      <Row className="d-flex flex-row">
                        <Col xl={10} md={10} sm={10} className="pe-sm-0 flex-2">
                          <AppDatePicker
                            name="end_date"
                            value={formData.end_date}
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            onChange={handleFieldChange}
                          />
                        </Col>
                        <Col
                          xl={1}
                          md={1}
                          sm={1}
                          className={
                            'text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1'
                          }
                        >
                          {formError.end_date ? (
                            <FormErrorPopover
                              id="recurring_start_date"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.end_date}
                            />
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
              ) : (
                ''
              )}
              <Form.Group
                as={Col}
                xxl={6}
                xl={6}
                md={6}
                sm={12}
                className="mb-3 pe-0"
              >
                <Row>
                  <Col xl={4} md={4} sm={12} className="p-0">
                    <Form.Label className="require-data text-dark m-0 d-flex align-items-center justify-content-start">
                      {Translate('Repeat Every')}
                      <AiFillQuestionCircle
                        className={'ms-2'}
                        title={Translate(
                          'ex: repeat every 1 days | reapeat every 1 month'
                        )}
                      />
                    </Form.Label>
                  </Col>
                  <Col className="d-flex">
                    <Row>
                      <Col xl={5} md={5} sm={12} className="p-0 pe-1 ps-1">
                        <Form.Control
                          type="number"
                          name="repeat_every"
                          onChange={handleFieldChange}
                          value={formData.repeat_every}
                        />
                      </Col>
                      <Col xl={5} md={5} sm={12} className="p-0">
                        <Form.Select
                          onChange={handleFieldChange}
                          aria-label="Select Repeat Type"
                          defaultValue="days"
                          name="repeat_type"
                        >
                          <option value="days">{Translate('Days')}</option>
                          <option value="week">{Translate('Week')}</option>
                          <option value="month">{Translate('Month')}</option>
                          <option value="year">{Translate('Year')}</option>
                        </Form.Select>
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className={
                          'text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1'
                        }
                      >
                        {formError.repeat_every ? (
                          <FormErrorPopover
                            id="repeat_every"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.repeat_every}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                as={Col}
                xxl={12}
                xl={12}
                md={12}
                sm={12}
                className="mb-3"
              >
                <Row>
                  <Col xl={2} md={4} sm={12} className="p-0">
                    <Form.Label className="text-dark m-0 d-flex align-items-center justify-content-start">
                      {Translate('Description')}
                    </Form.Label>
                  </Col>
                  <Col xl={6} md={6} sm={12} className="ps-0">
                    <Form.Control
                      type="text"
                      as="textarea"
                      name="description"
                      rows={3}
                      onChange={handleFieldChange}
                      value={formData.description}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Row>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex border-top gap-1">
          <SaveButton
            onSave={onSave}
            handleSubmit={handleSubmit}
            createPermission={true}
            editPermission={true}
          />
          <CancelButton onSave={onSave} handleCancel={onHide} />
        </Card.Footer>
      </Card>
    </Modal>
  );
}

RecurringRules.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  id: PropTypes.any,
  type: PropTypes.string,
  fetchData: PropTypes.func
};

export default RecurringRules;
