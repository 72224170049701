import { React, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { ImInfo } from 'react-icons/im';
import { BiFullscreen } from 'react-icons/bi';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import GroupAvatarImage from 'assets/img/group-avatar.jpg';
import UserDetailModal from 'module/Common/Info/UserDetailModal';
import GroupInfoModal from '../MessengerGroups/GroupInfoModal';
import { RxCross1 } from 'react-icons/rx';

const ChatWindowHeader = ({
  item,
  type,
  smallScreen,
  setShowChatWindow,
  hideDetailOffCanvas
}) => {
  const navigate = useNavigate();
  const individualType = 'INDIVIDUAL';
  const groupType = 'GROUP';
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [showGroupDetails, setShowGroupDetails] = useState(false);
  const [groupDetail, setGroupDetail] = useState(false);
  const [newMember, setNewMember] = useState(true);
  const [showUserDetail, setShowUserDetail] = useState(false);

  const FullScreen = () => {
    setShowChatWindow(false);
    hideDetailOffCanvas();
    navigate('/chat', { state: { item: item, type: type } });
  };

  useEffect(() => {
    setNewMember(false);
    if (type == groupType) {
      axios
        .get(`/user/chat-groups/${item.id}`)
        .then(response => {
          setGroupDetail(response.data.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [type, item, newMember]);

  return (
    <>
      <Offcanvas.Header className="d-flex align-items-center justify-content-between border-bottom bg-dark position-relative">
        <div className="d-flex  align-items-center ">
          <div className="position-relative me-2">
            <img
              src={item.image ?? GroupAvatarImage}
              width={50}
              height={50}
              className="rounded-circle"
            />
          </div>
          {item.is_user_active && (
            <div
              className={
                user.language == 'AR'
                  ? 'translate-middle badge rounded-circle bg-success text-success header-active-green-symbol-style-rtl'
                  : 'translate-middle badge rounded-circle bg-success text-success header-active-green-symbol-style'
              }
            >
              .
            </div>
          )}
          <div className="d-flex flex-column">
            {type == groupType ? (
              <div className="d-flex flex-row align-items-center">
                <span
                  className="m-0 chat-name pt-1 text-white text-capitalize"
                  style={{ fontSize: !smallScreen ? 18 : 13 }}
                >
                  {groupDetail.name}
                </span>
                {!smallScreen && (
                  <ImInfo
                    className="ms-2 cursor-pointer text-primary mt-1"
                    size={17}
                    onClick={() => setShowGroupDetails(!showGroupDetails)}
                  />
                )}
              </div>
            ) : (
              <span
                className="m-0 cursor-pointer chat-name pt-1 text-white  text-capitalize"
                style={{ fontSize: !smallScreen ? 17 : 13 }}
                onClick={() => setShowUserDetail(true)}
              >
                {item.name}
              </span>
            )}
            {type == individualType ? (
              <h4 className="text-muted chat-role pt-1 mb-0">
                {item.roles && item.roles.length > 0 && item.roles[0].name}
              </h4>
            ) : groupType ? (
              <>
                <p
                  className="d-flex flex-row mb-0"
                  style={{
                    width: smallScreen ? '90%' : '90%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                  }}
                >
                  {groupDetail &&
                    groupDetail.users &&
                    groupDetail.users.length > 0 &&
                    groupDetail.users.map((el, index) => {
                      return (
                        <span key={index} style={{ marginRight: '5px' }}>
                          {el.id == user.id ? Translate('You') : el.name},
                        </span>
                      );
                    })}
                </p>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="d-flex flex-column position-relative">
          <RxCross1
            size={18}
            className="cursor-pointer"
            color="white"
            onClick={() =>
              smallScreen ? setShowChatWindow(false) : navigate(-1)
            }
          />
        </div>
        {smallScreen && (
          <BiFullscreen
            className="text-dark position-fixed"
            size={25}
            onClick={FullScreen}
            style={{ cursor: 'pointer', bottom: '50px' }}
          />
        )}
      </Offcanvas.Header>
      <GroupInfoModal
        groupDetail={groupDetail}
        showGroupDetails={showGroupDetails}
        setShowGroupDetails={setShowGroupDetails}
        setNewMember={setNewMember}
        GroupAvatarImage={GroupAvatarImage}
      />
      {type == 'INDIVIDUAL' && (
        <UserDetailModal
          show={showUserDetail}
          setShowUserDetail={setShowUserDetail}
          item={item}
        />
      )}
    </>
  );
};

ChatWindowHeader.propTypes = {
  item: PropTypes.any,
  type: PropTypes.any,
  smallScreen: PropTypes.bool,
  setShowChatWindow: PropTypes.func,
  hideDetailOffCanvas: PropTypes.func
};

export default ChatWindowHeader;
