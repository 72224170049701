import { driver } from 'driver.js';

const driverStepsData = {
  welcome: () => driverStepsData.profile(),
  profile: () => {
    const driverObj = driver({
      allowClose: false,
      showButtons: ['next', 'close'],
      doneBtnText: 'Next',
      steps: [
        {
          element: '.user-profile-driver-element',
          popover: {
            title: 'Before we start',
            description: 'You can check your profile details from here.'
          }
        }
      ],
      onPopoverRender: popover => addSkipButton(driverObj, popover),
      onDestroyStarted: () => {
        driverObj.destroy();
        let profileElement = document.querySelector(
          '.user-profile-driver-element'
        );

        if (profileElement) {
          profileElement.click();
          setTimeout(() => {
            driverStepsData.logout();
          }, 500);
        }
      }
    });
    driverObj.drive();
  },

  logout: () => {
    const driverObj = driver({
      allowClose: false,
      showButtons: ['next', 'close'],
      doneBtnText: 'Next',
      steps: [
        {
          element: '.logout-button-driver-element',
          popover: {
            title: 'Logout',
            description: 'You can logout your acoount from here.'
          }
        }
      ],
      onPopoverRender: popover => addSkipButton(driverObj, popover),
      onDestroyStarted: () => {
        driverObj.destroy();
        let profileElement = document.querySelector(
          '.user-profile-driver-element'
        );

        if (profileElement) {
          profileElement.click();
          driverStepsData.settings();
        }
      }
    });
    driverObj.drive();
  },

  settings: () => {
    const driverObj = driver({
      allowClose: false,
      showButtons: ['next', 'close'],
      doneBtnText: 'Next',
      steps: [
        {
          element: '.settings-button-driver-element',
          popover: {
            title: 'Settings',
            description: 'You can access settings menu from here.'
          }
        }
      ],
      onPopoverRender: popover => addSkipButton(driverObj, popover),
      onDestroyStarted: () => {
        driverObj.destroy();
        driverStepsData.aiChatBot();
      }
    });
    driverObj.drive();
  },

  aiChatBot: () => {
    const driverObj = driver({
      allowClose: false,
      showButtons: ['next', 'close'],
      doneBtnText: 'Next',
      steps: [
        {
          element: '.ai-chatbot-driver-element',
          popover: {
            title: 'AI chatboat',
            description: 'You can access AI chatbot from here.'
          }
        }
      ],
      onPopoverRender: popover => addSkipButton(driverObj, popover),
      onDestroyStarted: () => {
        driverObj.destroy();
        driverStepsData.quickLinks();
      }
    });
    driverObj.drive();
  },

  quickLinks: () => {
    const driverObj = driver({
      allowClose: false,
      showButtons: ['next', 'close'],
      doneBtnText: 'Next',
      steps: [
        {
          element: '.quick-links-driver-element',
          popover: {
            title: 'Quick links',
            description: 'You can create and manage quick links from here.'
          }
        }
      ],
      onPopoverRender: popover => addSkipButton(driverObj, popover),
      onDestroyStarted: () => {
        driverObj.destroy();
        let quickLinkElement = document.querySelector(
          '.quick-links-driver-element'
        );

        if (quickLinkElement) {
          quickLinkElement.click();
          driverStepsData.quickLinksDropDown();
        }
      }
    });
    driverObj.drive();
  },

  quickLinksDropDown: () => {
    const driverObj = driver({
      allowClose: false,
      showButtons: ['next', 'close'],
      nextBtnText: 'Next',
      doneBtnText: 'Next',
      steps: [
        {
          element: '.quick-links-result-driver-element',
          popover: {
            title: 'Available Qucik links',
            description: 'This are the current available quick links area.'
          }
        },
        {
          element: '.quick-links-add-button-driver-element',
          popover: {
            title: 'Add new quick link',
            description: 'Click here to create new quick links.'
          }
        }
      ],
      onPopoverRender: popover => addSkipButton(driverObj, popover),
      onDestroyStarted: () => {
        driverObj.destroy();
        let quickLinkElement = document.querySelector(
          '.quick-links-driver-element'
        );

        if (quickLinkElement) {
          quickLinkElement.click();
          driverStepsData.navLinks();
        }
      }
    });
    driverObj.drive();
  },

  navLinks: () => {
    const driverObj = driver({
      allowClose: false,
      showButtons: ['next', 'close'],
      nextBtnText: 'Next',
      steps: [
        {
          element: '.view-finance',
          popover: {
            title: 'Finance Module',
            description: 'Finance module description here.'
          }
        },
        {
          element: '.view-purchase',
          popover: {
            title: 'Purchase Module',
            description: 'Purchase module description here.'
          }
        },
        {
          element: '.view-reports',
          popover: {
            title: 'Report Module',
            description: 'Report module description here.'
          }
        }
      ],
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          driverObj.destroy();
          if (typeof driverStepsData.updateUserEnableUserGuide === 'function') {
            driverStepsData.updateUserEnableUserGuide();
          }
        }
      }
    });
    driverObj.drive();
  }
};

function addSkipButton(driver, popover) {
  let configs = driver.getConfig();
  if (configs?.doneBtnText === 'Next' || configs?.nextBtnText === 'Next') {
    const firstButton = document.createElement('button');
    firstButton.innerText = 'Skip';
    popover.footerButtons.prepend(firstButton);

    firstButton.addEventListener('click', () => {
      driver.destroy();
      if (typeof driverStepsData.updateUserEnableUserGuide === 'function') {
        driverStepsData.updateUserEnableUserGuide();
      }
    });
  }
}

export default driverStepsData;
