import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  createSearchParams,
  useLocation,
  useSearchParams
} from 'react-router-dom';

export default function LedgerInquiryTableLineItem({ lineNumber, ledgerData }) {
  const location = useLocation();
  const [queryParams] = useSearchParams();
  function createPath({ type, params }) {
    let paramsList = Object.fromEntries([...queryParams]);
    if (type === 'reference') {
      delete paramsList.person_view_id;
      delete paramsList.person_view_type;
    } else if (type === 'person') {
      delete paramsList.reference_view_id;
      delete paramsList.reference_view_type;
    }

    return (
      location.pathname + '?' + createSearchParams({ ...paramsList, ...params })
    );
  }

  const referenceViewPath = createPath({
    type: 'reference',
    params: {
      reference_view_id: ledgerData.voucher_id,
      reference_view_type: ['SI', 'SD', 'CN', 'PI', 'PD', 'DN'].includes(
        ledgerData.trans_type
      )
        ? null
        : ledgerData.trans_type
      // For certain transaction types ('SI', 'SD', 'CN', 'PI', 'PD', 'DN'), only the voucher ID is needed without including the transaction type in the reference_view_type parameter. other wise its shows 404 error
    }
  });

  const personViewPath = createPath({
    type: 'person',
    params: {
      person_view_id: ledgerData.person_id,
      person_view_type: ledgerData.person_type_id
    }
  });

  const style = {
    fontSize: '.77rem'
  };
  return (
    <tr className="text-dark">
      <td className="p-2" style={style}>
        {lineNumber}
      </td>
      <td className="p-2" style={style}>
        {ledgerData.trans_date_formatted ?? '-'}
      </td>
      <td className="p-2" style={style}>
        {ledgerData.trans_type_name ?? '-'}
      </td>
      <td className="p-2" style={style}>
        <Link to={referenceViewPath}>{ledgerData.reference ?? '-'}</Link>
      </td>
      <td className="p-2" style={style}>
        <Link to={personViewPath}>{ledgerData.person_name ?? '-'}</Link>
      </td>
      <td className="p-2" style={style}>
        {ledgerData.cost_center_name ?? '-'}
      </td>
      <td className="p-2" style={style}>
        {ledgerData.description ?? '-'}
      </td>
      <td className="p-2" style={style}>
        {parseFloat(ledgerData.amount) >= 0
          ? ledgerData.absolute_amount_formatted.replace(/[^0-9.]/g, '')
          : '-'}
      </td>
      <td className="p-2" style={style}>
        {parseFloat(ledgerData.amount) < 0
          ? ledgerData.absolute_amount_formatted.replace(/[^0-9.]/g, '')
          : '-'}
      </td>
      <td className="p-2" style={style}>
        {parseFloat(ledgerData.opening_blance_amount)
          ? ledgerData.opening_blance_amount_formatted
          : '-'}
      </td>
    </tr>
  );
}

LedgerInquiryTableLineItem.propTypes = {
  lineNumber: PropTypes.number,
  ledgerData: PropTypes.object
};
