import { React } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectPaymentTerm from '../Search/SelectPaymentTerm';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function PaymentTermInputField({
  formData,
  uiDesign,
  validations,
  handlePaymentTerms,
  formError,
  setFormData,
  editDisable,
  required
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group as={Col} xxl={4} xl={6} md={6} sm={12} className="mb-2">
      <Row>
        <Col xl={4} md={4} sm={12} className="pe-0">
          <Form.Label
            className={
              required
                ? 'require-data m-0 d-flex align-items-center justify-content-start h-100'
                : 'm-0 d-flex align-items-center justify-content-start h-100'
            }
          >
            {Translate('Payment Terms')}
          </Form.Label>
        </Col>
        <Col xl={8} md={8} sm={12} className="ps-0">
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
              {(uiDesign &&
                uiDesign.convertingScreen &&
                !uiDesign.convertingScreenForSO &&
                !uiDesign.convertingScreenFromContract) ||
              (validations && validations.fromSd) ||
              (validations && validations.fromSi) ||
              (validations && validations.fromSo) ||
              editDisable ? (
                <Form.Control
                  value={
                    formData?.payment_term_id_ref?.label ??
                    formData?.payment_term_id_ref
                  }
                  disabled
                />
              ) : (
                <SelectPaymentTerm
                  value={formData.payment_term_id_ref}
                  name={formData.payment_term_id_ref}
                  handleFieldChange={handlePaymentTerms}
                  setFormData={setFormData}
                />
              )}
            </Col>
            <Col
              xl={1}
              md={1}
              sm={1}
              className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
            >
              {formError.payment_term_id ? (
                <FormErrorPopover
                  id="payment_term_id"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError.payment_term_id}
                />
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

PaymentTermInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  uiDesign: PropTypes.any,
  validations: PropTypes.any,
  setFormData: PropTypes.func,
  handlePaymentTerms: PropTypes.func,
  editDisable: PropTypes.bool,
  required: PropTypes.bool
};

export default PaymentTermInputField;
