import './KitDetailsModalPreview.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { BsHandbagFill } from 'react-icons/bs';
import PurchaseTableFormKitLineItem from '../PurchaseTableFormKitLineItem';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';

const KitDetailsModalPreview = ({
  kit,
  onTableItemChange,
  readOnly = false
}) => {
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);
  const handleKitItem = (kitItem, method) => {
    if (
      (kitItem?.qty_sent_ref > 0 || kitItem?.qty_invoiced_ref > 0) &&
      kitItem?.base_quantity_ref > 0 &&
      kitItem?.kit_quantity > 0
    ) {
      const kitQuantity = parseFloat(kitItem.kit_quantity);
      const itemQuantity = parseFloat(kitItem.base_quantity_ref);
      const quantitySentCount =
        kitItem?.qty_sent_ref > 0 ? parseFloat(kitItem?.qty_sent_ref) : 0;
      const quantityInvoicedCount =
        kitItem?.qty_invoiced_ref > 0
          ? parseFloat(kitItem?.qty_invoiced_ref)
          : 0;

      let statusChangedMaxQuantity = Math.max(
        quantityInvoicedCount,
        quantitySentCount
      );

      if (kitQuantity * itemQuantity < statusChangedMaxQuantity) {
        return showToast(
          Translate(
            `Invalid quantity, total quantity value cannot be reduced below the already ${
              statusChangedMaxQuantity === quantityInvoicedCount
                ? 'invoiced'
                : 'sent'
            } quantity of ` + statusChangedMaxQuantity
          ),
          'error'
        );
      }
    }

    kit.kit_items = kit.kit_items.reduce((newResult, item) => {
      delete item.edit_ref;
      if (item.kit_item_id === kitItem.kit_item_id) {
        if (method === 'EDIT') {
          item.edit_ref = true;
        }
        if (method === 'UPDATE') {
          item.base_quantity_ref = kitItem.base_quantity_ref;
        }
      }

      if (item.kit_item_id != kitItem.kit_item_id || method != 'REMOVE')
        newResult.push(item);
      return newResult;
    }, []);
    if (typeof onTableItemChange === 'function')
      if (method === 'UPDATE') {
        kit.add_to_cart_ref = true;
      }
    onTableItemChange(
      kit,
      kit.kit_items.length > 0 ? 'UPDATE' : 'REMOVE',
      ['REMOVE', 'UPDATE'].includes(method) || kit.kit_items.length < 1
        ? true
        : false
    );
  };
  return kit ? (
    <>
      <Button
        size="sm"
        variant="transparent"
        className="p-0 me-1 text-primary"
        title="Check kit details"
        onClick={handleShow}
        style={{ boxShadow: 'none' }}
      >
        <FontAwesomeIcon icon="eye" size="sm" />
      </Button>
      <Modal
        show={show}
        onHide={handleShow}
        className="kit-items-modal-cover"
        dialogClassName="kit-items-modal-view"
      >
        <Modal.Header closeButton>
          <div className="d-flex flex-row align-items-center">
            <BsHandbagFill style={{ fill: 'red' }} size={17} className="me-1" />
            <p className="mb-0 text-uppercase" style={{ fontSize: '1.1rem' }}>
              {kit.item_display_name}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p className="text-muted mb-1" style={{ fontSize: '0.55rem' }}>
            **TOTAL QUANTITY = KIT QUANTITY * ITEM QUANTITY
          </p>
          <Table
            striped
            bordered
            responsive
            hover={false}
            className="simple-table-animation"
          >
            <thead
              className="text-dark text-uppercase"
              style={{ backgroundColor: 'rgb(237,243,248)' }}
            >
              <tr>
                <th
                  className="table-header-size text-center"
                  style={{ width: '40px' }}
                >
                  #
                </th>
                <th className="table-header-size" style={{ width: '200px' }}>
                  Item Code
                </th>
                <th style={{ minWidth: '200px' }} className="table-header-size">
                  Item Name
                </th>
                <th className="table-header-size" style={{ width: '120px' }}>
                  Unit
                </th>
                <th className="table-header-size" style={{ width: '200px' }}>
                  Quantity
                </th>
                <th className="table-header-size" style={{ width: '200px' }}>
                  Total Quantity
                </th>
                <th className="table-header-size" style={{ width: '200px' }}>
                  Unit Price
                  {parseInt(kit.tax_included) ? (
                    <small> (Inc Tax)</small>
                  ) : null}
                </th>
                <th className="table-header-size" style={{ minWidth: '120px' }}>
                  Unit Tax
                </th>
                <th className="table-header-size" style={{ width: '200px' }}>
                  Discount / Unit
                </th>
                <th className="table-header-size" style={{ minWidth: '200px' }}>
                  Total
                </th>
                {!readOnly && <th style={{ width: '70px' }}></th>}
              </tr>
            </thead>
            <tbody>
              {kit.kit_items
                ? kit.kit_items.map((item, index) => (
                    <PurchaseTableFormKitLineItem
                      key={index}
                      entry={item}
                      index={index}
                      onTableItemChange={handleKitItem}
                      isLoading={kit.add_to_cart_ref ? true : false}
                      readOnly={readOnly}
                    />
                  ))
                : null}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  ) : null;
};

KitDetailsModalPreview.propTypes = {
  kit: PropTypes.any,
  onTableItemChange: PropTypes.func,
  readOnly: PropTypes.bool
};

export default KitDetailsModalPreview;
