import { React } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LineItemTable from '../Common/Tables/LineItemTable';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import { FaCentercode, FaPhoneAlt } from 'react-icons/fa';
import { BiLogoGmail } from 'react-icons/bi';
import { BsCoin } from 'react-icons/bs';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';

function DebitNoteView({ debitNoteInfoData }) {
  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {debitNoteInfoData?.supplier_name ? (
            <PersonName value={debitNoteInfoData?.supplier_name} />
          ) : null}
          {debitNoteInfoData?.reference && (
            <Badges label={debitNoteInfoData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {debitNoteInfoData?.cost_center_name && (
              <Details
                label={'Cost Center'}
                value={debitNoteInfoData?.cost_center_name}
                className={'mb-2 fs--1'}
                icon={<FaCentercode className="me-1 text-warning" size={15} />}
              />
            )}
            {debitNoteInfoData?.sales_type_name && (
              <Details
                label={'Sales Type'}
                value={debitNoteInfoData.sales_type_name}
                className={'mb-2 fs--1'}
                icon={<FaCentercode className="me-1 text-warning" size={15} />}
              />
            )}
            {debitNoteInfoData?.mobile && (
              <Details
                label={'Phone Number'}
                value={debitNoteInfoData?.mobile}
                icon={<FaPhoneAlt className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {debitNoteInfoData?.email && (
              <Details
                label={'Email Address'}
                value={debitNoteInfoData?.email}
                icon={<BiLogoGmail className="me-1 text-danger" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-4">
            <Details
              label={
                debitNoteInfoData?.tax_included &&
                debitNoteInfoData?.tax_included === 1
                  ? 'Amount (Inc.Tax)'
                  : 'Amount'
              }
              value={debitNoteInfoData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Allocated Amount'}
              value={debitNoteInfoData?.alloc_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Balance Amount'}
              value={debitNoteInfoData?.balance_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Tax'}
              value={debitNoteInfoData?.total_tax_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Round Off Amount'}
              value={debitNoteInfoData?.round_off_amount_formatted}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Discount'}
              value={
                debitNoteInfoData?.total_discount_amount_formatted ?? '0.00'
              }
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <LineItemTable data={debitNoteInfoData} batch />
        </Col>
      </Row>
      {debitNoteInfoData?.memo && <ShowMemo memo={debitNoteInfoData?.memo} />}
    </Container>
  );
}

DebitNoteView.propTypes = {
  debitNoteInfoData: PropTypes.any
};

export default DebitNoteView;
