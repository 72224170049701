import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from 'providers/AuthProvider';
import TabViewContentReloadHandlerContextProvider from 'providers/TabViewContentReloadHandlerContextProvider';
import ShortCutKeyController from 'module/Common/ArrowKeyController/ShortCutKeyController';

ReactDOM.render(
  <React.StrictMode>
    <Main>
      <Router basename={process.env.PUBLIC_URL}>
        <AuthProvider>
          <ShortCutKeyController>
            <TabViewContentReloadHandlerContextProvider>
              <App />
            </TabViewContentReloadHandlerContextProvider>
          </ShortCutKeyController>
        </AuthProvider>
      </Router>
    </Main>
  </React.StrictMode>,
  document.getElementById('main'),
  document.addEventListener('wheel', function () {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  })
);
