import React from 'react';
import { Placeholder } from 'react-bootstrap';

function TotalPlaceholder() {
  return (
    <>
      <Placeholder
        as="p"
        animation="glow"
        className="m-0"
        style={{ height: '10px' }}
      >
        <Placeholder
          className="w-25"
          size="sm"
          bg="secondary"
          style={{ opacity: '0.01' }}
        />
      </Placeholder>
    </>
  );
}

export default TotalPlaceholder;
