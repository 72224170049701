import { React, useRef } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import axios from 'axios';

const Search = ({
  className,
  value,
  onChange,
  placeholder,
  onSearch,
  searchUrl,
  searchParamKeyName,
  params = null,
  onSuccess,
  onError,
  onFinally,
  autofocus,
  iconClassname,
  ...rest
}) => {
  const Translate = useAxisproTranslate();
  const timeOutRef = useRef(undefined);

  const handleSearch = event => {
    if (typeof onChange === 'function') onChange(event);
    if (timeOutRef?.current) clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(async () => {
      if (typeof onSearch === 'function') onSearch(event?.target?.value, event);
      if (typeof searchUrl === 'string') {
        try {
          const apiResponse = await axios.get(searchUrl, {
            params: {
              ...params,
              [searchParamKeyName || 'searchKey']: event?.target?.value
            }
          });

          if (apiResponse?.status === 200) {
            typeof onSuccess === 'function' && onSuccess(apiResponse);
            return;
          }
          onError(apiResponse);
        } catch (error) {
          typeof onError === 'function' && onError(error);
        } finally {
          typeof onFinally === 'function' && onFinally();
        }
      }
    }, 1200);
  };

  return (
    <div
      className={`d-flex flex-row align-items-center border border-1 ${className}`}
    >
      <div className={`p-2 ${iconClassname}`}>
        <FontAwesomeIcon icon="search" />
      </div>
      <input
        autoFocus={autofocus}
        type="search"
        placeholder={placeholder ?? Translate('Search Settings')}
        className="border-0 w-100 align-self-stretch me-2"
        style={{ outline: 'none' }}
        onChange={onSearch || searchUrl ? handleSearch : onChange}
        {...(value?.length > 0
          ? {
              value: value
            }
          : null)}
        {...rest}
      />
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  searchUrl: PropTypes.string,
  searchParamKeyName: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onFinally: PropTypes.func,
  params: PropTypes.object,
  autofocus: PropTypes.bool,
  iconClassname: PropTypes.any
};

export default Search;
