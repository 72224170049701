import { React, useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Col, Figure, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AiOutlineUpload } from 'react-icons/ai';
import {
  FaBalanceScale,
  FaCreditCard,
  FaFileInvoiceDollar,
  FaList,
  FaPhoneAlt,
  FaRegAddressCard,
  FaRegIdCard,
  FaSave,
  FaUserSecret,
  FaWallet,
  FaWhatsapp
} from 'react-icons/fa';

import Loading from '../../../../../assets/gif/upload.gif';

import { AuthWizardContext, StoreContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';
import { HiOutlineReceiptTax, HiStatusOnline } from 'react-icons/hi';
import { GrCurrency } from 'react-icons/gr';
import { BsPersonExclamation } from 'react-icons/bs';
import {
  MdMailOutline,
  MdOutlineBusinessCenter,
  MdOutlineGroup,
  MdOutlineInsertPageBreak
} from 'react-icons/md';
import { TbFilePercent, TbLicense } from 'react-icons/tb';
import { IoMdPerson } from 'react-icons/io';

function BasicDetails({ data, type }) {
  const { user } = useContext(AuthWizardContext);
  const { store } = useContext(StoreContext);
  const Translate = useAxisproTranslate();
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const fileRef = useRef('');
  const [formData, setFormData] = useState({
    id: data.id,
    image: data.image
  });
  const [logoImg, setLogoImg] = useState('');
  const [isUploadImage, setIsUploadImage] = useState(false);
  const [save, setSave] = useState(false);
  const baseCurrency = user?.branch?.base_currency ?? '';

  const handleFieldChange = e => {
    let value;
    if (e.target.type === 'file' && e.target.files.length > 0) {
      value = e.target.files[0];
      setLogoImg(URL.createObjectURL(e.target.files[0]));
      setIsUploadImage(true);
    }
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSave(true);
    axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
      },
      url:
        data && data.customer_id
          ? `sales/customer-upload-profile-image`
          : type === 'salesman'
          ? `sales/salesman-upload-profile-image`
          : `purchase/supplier-upload-profile-image`,
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          setIsUploadImage(false);
          setSave(false);
          showToast(response.data.message, 'success');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setSave(false);
      });
  };

  return (
    <div className="pt-3 pb-3 pe-1 ps-0">
      <div className="d-flex align-items-center flex-column justify-content-center mb-3">
        <div className="customer-image">
          {data && data.image ? (
            <Figure
              onClick={() => fileRef.current.click()}
              className="figure m-1"
            >
              {logoImg ? (
                save ? (
                  <div className="profile-image d-flex align-items-center justify-content-center">
                    <img
                      src={Loading}
                      alt="customer-image"
                      className="profile-image-gif"
                    />
                  </div>
                ) : (
                  <img
                    src={logoImg}
                    alt="customer-image"
                    className="profile-image"
                  />
                )
              ) : (
                <img
                  src={formData.image}
                  alt="customer-image"
                  className="profile-image"
                />
              )}
              <Form.Control
                type="file"
                name="image"
                ref={fileRef}
                onChange={handleFieldChange}
                hidden
              />
            </Figure>
          ) : (
            <FaUserSecret size={60} className="text-dark" />
          )}
        </div>
        <div className="mb-3">
          {isUploadImage ? (
            <Button
              onClick={handleSubmit}
              variant="success"
              size="sm"
              className="customer-image-save-button"
            >
              {save ? Translate('Saving') : Translate('Save')}
              <FaSave />
            </Button>
          ) : (
            <div
              className="image-upload-section"
              onClick={() => fileRef.current.click()}
            >
              <p className="m-0 text-primary">
                {Translate('click here to choose image')}
                <AiOutlineUpload size={18} />
                <Form.Control
                  type="file"
                  name="image"
                  ref={fileRef}
                  onChange={handleFieldChange}
                  hidden
                />
              </p>
            </div>
          )}
        </div>
      </div>
      {data && data?.opening_balance ? (
        <BasicDetailsRow
          icon={
            <FaBalanceScale
              size={16}
              className="text-warning me-2"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Opening Balance')}
          value={
            `${baseCurrency} ` +
            ' ' +
            acodaxNumberFormat(Math.abs(data?.opening_balance) ?? 0)
          }
          openingBalance={data?.opening_balance}
        />
      ) : (
        ''
      )}

      {data && data.salesman_name && !type ? (
        <BasicDetailsRow
          icon={
            <IoMdPerson
              size={17}
              className="me-2 text-info"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Salesman')}
          value={data.salesman_name}
          salesmanId={data?.salesman_id}
        />
      ) : (
        ''
      )}
      {(data && data.phone) ||
      (data && data.contact_number) ||
      (data && data.salesman_phone) ? (
        <BasicDetailsRow
          icon={
            <FaPhoneAlt
              size={12}
              className="me-2"
              color="#222831"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Phone Number')}
          value={
            data.phone || data.contact_number || (data && data.salesman_phone)
          }
        />
      ) : (
        ''
      )}
      {data && data.emirates_id ? (
        <BasicDetailsRow
          icon={
            <FaRegIdCard
              size={16}
              className="me-2"
              color="#433878"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Emirates Id')}
          value={data.emirates_id}
        />
      ) : (
        ''
      )}
      {data && data.customer_type ? (
        <BasicDetailsRow
          icon={
            <BsPersonExclamation
              size={17}
              className="me-2"
              color="#F07B3F"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Customer Type')}
          value={data.customer_type}
        />
      ) : (
        ''
      )}
      {data && data?.customer_whatsapp_number ? (
        <BasicDetailsRow
          icon={
            <FaWhatsapp
              size={16}
              className="me-2 text-success"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Whatsapp Number')}
          value={data?.customer_whatsapp_number}
        />
      ) : (
        ''
      )}
      {data && data?.customer_group ? (
        <BasicDetailsRow
          icon={
            <MdOutlineGroup
              size={18}
              className="me-2"
              color="#87A922"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Customer Group')}
          value={
            data?.customer_group?.group_code +
            ' - ' +
            data?.customer_group?.name
          }
        />
      ) : (
        ''
      )}
      {data && data.payment_term_name ? (
        <BasicDetailsRow
          icon={
            <FaWallet
              size={13}
              className="me-2"
              color="#40A2E3"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Payment Term')}
          value={data?.payment_term_name}
        />
      ) : (
        ''
      )}
      {data && data.sales_type_name ? (
        <BasicDetailsRow
          icon={
            <FaList
              size={12}
              className="me-2"
              color="#FF0000"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Sales Type')}
          value={data?.sales_type_name}
        />
      ) : (
        ''
      )}
      {data && data.secondary_contact_number ? (
        <BasicDetailsRow
          icon={
            <FaPhoneAlt
              size={12}
              className="me-2"
              color="#537188"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Second Number')}
          value={data?.secondary_contact_number}
        />
      ) : (
        ''
      )}
      {(data && data.email) ||
      (data && data.email_address) ||
      (data && data.salesman_email) ? (
        <BasicDetailsRow
          icon={
            <MdMailOutline
              size={17}
              className="me-2"
              color="#FF8080"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Email Address')}
          value={
            data.email || data.email_address || (data && data.salesman_email)
          }
        />
      ) : (
        ''
      )}
      {/* {data && data.salesman_name && !type ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Salesman')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.salesman_name}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )} */}
      {data && data?.trn_no ? (
        <BasicDetailsRow
          icon={
            <TbFilePercent
              size={18}
              className="me-2 text-danger"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate(`${store?.globalConfiguration?.tax_label} Number`)}
          value={data?.trn_no}
        />
      ) : (
        ''
      )}
      {data && data?.trade_license_no ? (
        <BasicDetailsRow
          icon={
            <TbLicense
              size={17}
              className="me-2"
              color="#8E9775"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Trade License no')}
          value={data?.trade_license_no}
        />
      ) : (
        ''
      )}
      {data && data.credit_status_name ? (
        <BasicDetailsRow
          icon={
            <HiStatusOnline
              size={15}
              className="me-2"
              color="#024CAA"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Credit Status')}
          value={data?.credit_status_name}
        />
      ) : (
        ''
      )}
      {data && data.tax_name ? (
        <BasicDetailsRow
          icon={
            <HiOutlineReceiptTax
              size={17}
              className="me-2"
              color="#BE6283"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Tax')}
          value={data?.tax_name}
        />
      ) : (
        ''
      )}
      {data?.tax_config ? (
        <BasicDetailsRow
          icon={
            <FaFileInvoiceDollar
              size={15}
              className="me-2"
              color="#78D8D0"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Tax Configuration')}
          value={
            data?.tax_config === 'USE_ITEM_TAX'
              ? Translate('USE ITEM TAX')
              : data?.tax_config_tax_group?.name
          }
        />
      ) : (
        ''
      )}
      {data && data.currency_code ? (
        <BasicDetailsRow
          icon={
            <GrCurrency
              size={16}
              className="me-2"
              color="#8EAC50"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Currency Code')}
          value={data?.currency_code}
        />
      ) : (
        ''
      )}
      {data && data.credit_limit ? (
        <BasicDetailsRow
          icon={
            <FaCreditCard
              size={14}
              className="me-2"
              color="#4D4C7D"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Credit Limit')}
          value={data?.credit_limit}
        />
      ) : (
        ''
      )}
      {data && data.address ? (
        <BasicDetailsRow
          icon={
            <FaRegAddressCard
              size={16}
              className="me-2"
              color="#11009E"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Address')}
          value={data?.address}
        />
      ) : (
        ''
      )}
      {data && data.provision1 ? (
        <BasicDetailsRow
          icon={
            <MdOutlineBusinessCenter
              size={18}
              className="me-2"
              color="#4CC9FE"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Provision 1')}
          value={data?.provision1}
        />
      ) : (
        ''
      )}
      {data && data.break_point ? (
        <BasicDetailsRow
          icon={
            <MdOutlineInsertPageBreak
              size={17}
              className="me-2"
              color="#F7C04A"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Break Point')}
          value={data?.break_point}
        />
      ) : (
        ''
      )}
      {data && data.provision2 ? (
        <BasicDetailsRow
          icon={
            <MdOutlineBusinessCenter
              size={18}
              className="me-2"
              color="#C62E2E"
              style={{ minWidth: 'fit-content' }}
            />
          }
          label={Translate('Provision 2')}
          value={data?.provision2}
        />
      ) : (
        ''
      )}
    </div>
  );
}

BasicDetails.propTypes = {
  data: PropTypes.any,
  type: PropTypes.string
};

export default BasicDetails;

const BasicDetailsRow = ({
  icon,
  label,
  value,
  salesmanId,
  openingBalance
}) => {
  const Navigate = useNavigate();
  return (
    <Row className="mb-2 basic-details-wrapper">
      <Col xs={6} className="pe-0 basic-details-label">
        <div className="d-flex align-items-center">
          {icon}
          <h6 className={`mb-0 ${openingBalance ? 'text-warning' : ''}`}>
            {label}
          </h6>
        </div>
      </Col>
      <Col xs={6} className="basic-details">
        {salesmanId ? (
          <h6
            onClick={() => {
              Navigate(`/sales/salesman/info/${salesmanId}`);
            }}
            className="mb-2 text-primary cursor-pointer"
          >
            {value}
          </h6>
        ) : (
          <h6 className={`mb-2 ${openingBalance ? 'text-warning' : ''}`}>
            {value}
            {openingBalance && (
              <span className="text-dark text-small m-0 fs--2 ps-2">
                {openingBalance < 0
                  ? '(Credit)'
                  : openingBalance > 0
                  ? '(Debit)'
                  : ''}
              </span>
            )}
          </h6>
        )}
      </Col>
    </Row>
  );
};

BasicDetailsRow.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
  salesmanId: PropTypes.any,
  openingBalance: PropTypes.any
};
