import { toast } from 'react-toastify';

let currentWarningToastId = null;

export const showWarningToast = (message, settings) => {
  if (currentWarningToastId) {
    toast.dismiss(currentWarningToastId);
  }
  currentWarningToastId = toast.warn(
    message,
    settings
      ? settings
      : {
          theme: 'dark',
          position: 'bottom-right'
        }
  );
};
