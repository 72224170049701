class TableMethods {
  constructor(quill, options) {
    this.quill = quill;
    this.options = options;
    this.toolbar = quill.getModule('toolbar');
    this.tableModule = this.quill.getModule('table-better');

    this.handleInsertTable = this.handleInsertTable.bind(this);
    this.handleInsertTable();
  }

  handleInsertTable() {
    const tableButton = this.toolbar?.container?.querySelector('.ql-table');
    if (!tableButton) return;

    // Clone the element without its event listeners
    const newElement = tableButton.cloneNode(true);

    // Replace the original element with the cloned one
    tableButton.parentNode.replaceChild(newElement, tableButton);

    // Add the new click event
    if (!this.tableModule) return;
    newElement.addEventListener('click', event => {
      event?.preventDefault();
      this.tableModule.insertTable(2, 2);
    });
  }
}

export default TableMethods;
