import React from 'react';
import PropTypes from 'prop-types';
import SwitchController from 'components/switch/SwitchController';

export default function PermissionsTableLineItem({
  lineNumber,
  updatingPermissions,
  permission,
  onSwitch
}) {
  const tdStyle = {
    fontSize: '.77rem'
  };
  return (
    <tr className="text-dark">
      <th className="text-center">{lineNumber}</th>
      <td className="p-2 border-end-0" style={tdStyle}>
        {permission?.name ?? '-'}
      </td>
      <td className="p-2 border-start-0 text-end" style={tdStyle}>
        <SwitchController
          disabled={updatingPermissions}
          checked={
            Object.keys(permission).includes('hasPermissionRef')
              ? permission.hasPermissionRef
              : !!permission?.hasPermission
          }
          onSwitch={event => onSwitch(event, permission.id)}
        />
      </td>
    </tr>
  );
}

PermissionsTableLineItem.propTypes = {
  lineNumber: PropTypes.number,
  updatingPermissions: PropTypes.bool,
  permission: PropTypes.object,
  onSelect: PropTypes.func,
  onSwitch: PropTypes.func
};
