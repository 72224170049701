import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Flex from 'components/common/Flex';
import Background from 'components/common/Background';
import Loader from '../Common/Loader';
import { countTile } from '../Common/LoaderData';

function TotalCount({
  isLoading,
  data,
  corner,
  title,
  to,
  name,
  description,
  Icon,
  bgColor,
  permission,
  totalCount,
  draggable
}) {
  const Translate = useAxisproTranslate();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (totalCount) {
      const targetCount = totalCount;
      const duration = 2000;
      const increment = (targetCount / duration) * 10;

      const timer = setInterval(() => {
        setCount(prevCount => {
          const newCount = prevCount + increment;
          return newCount >= targetCount ? targetCount : Math.ceil(newCount);
        });
      }, 10);

      return () => {
        clearInterval(timer);
        setCount(0);
      };
    }
  }, [totalCount]);

  return (
    <Card
      className={`h-100 p-2 position-relative ${
        draggable && 'dashboard_shake'
      }`}
    >
      {!isLoading ? (
        <>
          <Background image={corner} className="bg-card" />
          <Card.Header className="pb-0">
            <h6 className="mb-0 tile-heading">{Translate(title)}</h6>
          </Card.Header>
          <Card.Body className="pb-0">
            <div className="d-flex flex-row gap-2 align-items-center">
              <h2 className="fw-bold fs-3 mb-1">{count}</h2>
              {data.customer_average_difference >= 0 ? (
                <Badge pill bg="200" className="text-success fs--2">
                  <FontAwesomeIcon icon="caret-up" className="me-1" />
                  {Math.round(data.customer_average_difference) + '%'}
                </Badge>
              ) : data.customer_average_difference ? (
                <Badge pill bg="200" className="text-danger fs--2">
                  <FontAwesomeIcon icon="caret-down" className="me-1" />
                  {Math.abs(Math.round(data.customer_average_difference)) + '%'}
                </Badge>
              ) : (
                ''
              )}
            </div>
            {permission ? (
              <div className="mt-3 mb-0">
                <Row className="g-2 h-100 align-items-end">
                  <Col sm={12} md={12}>
                    <Flex className="position-relative">
                      <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                        <Icon className={bgColor} />
                      </div>
                      <div className="flex-1">
                        <Link to={to} className="stretched-link">
                          <h6 className="fw-normal mb-0">{Translate(name)}</h6>
                        </Link>
                        <p className="mb-0 fs--2 text-500 ">
                          {Translate(description)}
                        </p>
                      </div>
                    </Flex>
                  </Col>
                </Row>
              </div>
            ) : (
              ''
            )}
          </Card.Body>
        </>
      ) : (
        <Loader loader={countTile} />
      )}
    </Card>
  );
}

TotalCount.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.any,
  corner: PropTypes.any,
  title: PropTypes.any,
  to: PropTypes.any,
  name: PropTypes.any,
  description: PropTypes.any,
  Icon: PropTypes.any,
  bgColor: PropTypes.any,
  permission: PropTypes.bool,
  totalCount: PropTypes.any,
  draggable: PropTypes.bool
};

export default TotalCount;
