import Treeview from 'components/common/Treeview';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Search from 'components/search/Search';

export default function PermissionPermissionsTreeView({
  data,
  handleShowPermissionsList,
  onSelectItems,
  defaultExpanded,
  defaultSelectedModules
}) {
  const [items] = useState(Array.isArray(data) ? data : []);
  const [selectedItems, setselectedItems] = useState(
    Array.isArray(defaultSelectedModules) ? defaultSelectedModules : []
  );
  const [openedItems, setOpenedItems] = useState(
    Array.isArray(defaultExpanded) ? defaultExpanded : []
  );
  const [searchKey, setSearchKey] = useState(undefined);
  function handleSetSelectedItems(items) {
    setselectedItems(items);
    if (typeof onSelectItems === 'function') onSelectItems(items);
  }
  function handleSearch(event) {
    let searchKey = event.target.value;
    searchKey = searchKey?.length > 0 ? searchKey.toLowerCase() : undefined;
    setSearchKey(searchKey);
    let searchResult = Array.isArray(defaultExpanded) ? defaultExpanded : [];
    let allResult = items.reduce((newResult, item) => {
      function checkChildren(children, parentId) {
        children.map(item => {
          newResult.push({
            name: (item?.name ?? '').toLowerCase(),
            id: item?.id,
            parentId: parentId
          });
          if (Array.isArray(item.children))
            checkChildren(item.children, item?.id);
        });
      }

      newResult.push({
        name: (item?.name ?? '').toLowerCase(),
        id: item?.id
      });

      if (Array.isArray(item.children)) checkChildren(item.children, item?.id);

      return newResult;
    }, []);

    if (searchKey) {
      let searchingItems = allResult.reduce((newResult, item) => {
        if (item.name.includes(searchKey)) {
          newResult.push(item);
        }
        return newResult;
      }, []);

      searchResult = searchingItems.reduce((newResult, item) => {
        function getParentItems(parentItem) {
          const parentItemDetails = allResult.find(
            item => item.id === parentItem.parentId
          );
          if (parentItemDetails?.id) {
            newResult.push(parentItemDetails.id);
            if (parentItemDetails?.parentId) {
              getParentItems(parentItemDetails);
            }
          }
        }

        newResult.push(item?.id);
        if (item?.parentId) {
          getParentItems(item);
        }
        return newResult;
      }, []);
    }
    setOpenedItems(searchResult);
  }

  useEffect(() => {
    setselectedItems(
      Array.isArray(defaultSelectedModules) ? defaultSelectedModules : []
    );

    return () => {
      setselectedItems([]);
    };
  }, [defaultSelectedModules]);

  return (
    <>
      <Search
        className="border-0 border-bottom shadow-sm"
        placeholder="Search module"
        onChange={handleSearch}
      />
      <div className="system-admin-permission-tree-view p-3 pe-5">
        <Treeview
          data={items}
          selection
          selectedItems={selectedItems}
          setSelectedItems={handleSetSelectedItems}
          onTogglePermission={handleShowPermissionsList}
          openedItems={openedItems}
          setOpenedItems={setOpenedItems}
          searchKey={searchKey}
        />
      </div>
    </>
  );
}

PermissionPermissionsTreeView.propTypes = {
  data: PropTypes.array,
  handleShowPermissionsList: PropTypes.func,
  onSelectItems: PropTypes.func,
  defaultExpanded: PropTypes.array,
  defaultSelectedModules: PropTypes.array
};
