import React from 'react';
import PropTypes from 'prop-types';

function ClickReference({
  trans_type,
  permission,
  id,
  reference,
  clickOnFunction,
  className
}) {
  return permission ? (
    <a
      href="#"
      className={`${className} report-column-content-custom-style`}
      onClick={() => clickOnFunction(id, trans_type)}
    >
      {reference}
    </a>
  ) : (
    reference
  );
}

ClickReference.propTypes = {
  trans_type: PropTypes.string,
  id: PropTypes.string,
  reference: PropTypes.string,
  clickOnFunction: PropTypes.func,
  className: PropTypes.string,
  permission: PropTypes.any
};

export default ClickReference;
