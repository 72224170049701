import { React, useLayoutEffect, useState } from 'react';
import {
  useNavigate,
  useOutletContext,
  useSearchParams
} from 'react-router-dom';
import { ButtonGroup, Card, Container, Tab, Tabs } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { AiOutlineFileText } from 'react-icons/ai';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { CloseButton, MoreButton } from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import MaterialRequestView from './MaterialRequestView';
import TransactionsData from 'module/Common/Timeline/TransactionsData';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import IssueItemPopUp from '../Common/IssueItem/IssueItemPopUp';
import PDFView from 'components/PDFViewerButton/PDFView';
import Badges from '../Common/ViewPageDetails/Badges';
import ApprovalWorkFlow from '../Common/ApprovelWorkFlow/ApprovalWorkFlow';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';
import TabViewGeneralDetailsSectionCover from 'components/tab-view-general-details-section-cover/TabViewGeneralDetailsSectionCover';
import ShowReference from 'components/show-reference/ShowReference';
import ApproveButton from 'components/approval-component/button/ApproveButton';
import RejectButton from 'components/approval-component/button/RejectButton';

function MaterialRequestTabView({
  materialRequestData,
  loading,
  // infoPage,
  voidScreen,
  fetchData,
  getData,
  offCanvas,
  setShowVoucherDetailArea,
  onHide,
  voidTransactionReport,
  setIssuedItem
}) {
  const Translate = useAxisproTranslate();
  const navigateTo = useNavigate();
  let [permissions] = useOutletContext() ?? [];
  if (!permissions) {
    permissions = {
      sendMail: getAcodaxPermissionSlug('MR', 'send-mail'),
      print: getAcodaxPermissionSlug('MR', 'print'),
      create: getAcodaxPermissionSlug('MR', 'create'),
      edit: getAcodaxPermissionSlug('MR', 'update'),
      voidTransaction: getAcodaxPermissionSlug('VOID', 'TRANSACTION'),
      createPurchaseRequest: getAcodaxPermissionSlug('PR', 'create'),
      issueItems: getAcodaxPermissionSlug('ISSUE_ITEMS', 'MR')
    };
  }
  const { breakpoints } = useBreakpoints();
  const [queryParams] = useSearchParams();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [transactions, setTransactions] = useState([]);
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [issueItem, setIssueItem] = useState(false);
  const status = queryParams.get('status');

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  useLayoutEffect(() => {
    if (materialRequestData?.connected_orders) {
      let connected = [...materialRequestData.connected_orders];
      let sorted = connected.sort((a, b) =>
        a?.timestamp?.localeCompare(b?.timestamp)
      );
      setTransactions(sorted);
    }
  }, [materialRequestData]);

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <AiOutlineFileText size={28} className="me-2 text-dark" />
              <h5
                className="mb-0 d-flex text-uppercase view-page-header"
                style={{ fontSize: '25px' }}
              >
                {Translate('Material Request')}
              </h5>
            </div>
            {voidTransactionReport && (
              <Badges label={'Voided'} className="ms-2 fs--1" bg={'danger'} />
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {(status && status !== 'approved-request') ||
              voidScreen ||
              offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  firstConversionValidation={permissions?.createPurchaseRequest}
                  firstConversionTo={
                    '/purchase/purchase-request/add?material_request_id=' +
                    materialRequestData.id
                  }
                  beforeFirstConversion={'Convert to Purchase Request'}
                  issueItems={permissions?.issueItems}
                  setIssueItem={setIssueItem}
                  emailPermission={permissions?.sendMail}
                  clonePath={
                    permissions?.create
                      ? '/production/material-request/add?clone_item=' +
                        materialRequestData?.id
                      : undefined
                  }
                  voidTransactionData={
                    permissions?.voidTransaction
                      ? {
                          id: materialRequestData?.id,
                          trans_type: 'MR',
                          callBack: () => {
                            navigateTo(
                              '/production/material-request?layout=column'
                            );
                            typeof getData === 'function' && getData(1);
                          }
                        }
                      : undefined
                  }
                />
              )}
              {/* {voidScreen || infoPage || offCanvas ? (
                ''
              ) : permissions?.edit ? (
                <EditButton
                  to={`/production/material-request/edit/${materialRequestData.id}`}
                />
              ) : (
                console.log('hji')
              )} */}
              {(status && status !== 'approved-request') ||
                (permissions?.print && !voidTransactionReport && (
                  <ViewPagePrintButton
                    variant="danger"
                    className="text-white"
                    style={{ boxShadow: 'none' }}
                    url={`purchase/material-requests/print/${materialRequestData.id}`}
                    pageTitle="Material request print view"
                  />
                ))}
              {offCanvas || onHide ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                  onHide={onHide}
                />
              ) : (
                ''
              )}
              {materialRequestData?.user_actions?.Approve && (
                <ApproveButton
                  name={materialRequestData?.user_actions?.Approve}
                  Id={materialRequestData?.approval_event_id}
                  data={materialRequestData}
                  getData={getData}
                  info={true}
                />
              )}
              {materialRequestData?.user_actions?.Reject && (
                <RejectButton
                  name={materialRequestData?.user_actions?.Reject}
                  Id={materialRequestData?.approval_event_id}
                  data={materialRequestData}
                  getData={getData}
                  info={true}
                />
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <TabViewGeneralDetailsSectionCover mainTitle="General Details">
            <TabViewGeneralDetailsSectionCover.Section position="start">
              <TabViewGeneralDetailsSectionCover.Item
                title="Transaction Date"
                value={materialRequestData?.trans_date_formatted}
              />

              <TabViewGeneralDetailsSectionCover.Item
                title="Cost Center"
                value={materialRequestData?.cost_center_name}
              />

              <TabViewGeneralDetailsSectionCover.Item
                title="Warehouse Name"
                value={materialRequestData?.warehouse_name}
              />
            </TabViewGeneralDetailsSectionCover.Section>

            <TabViewGeneralDetailsSectionCover.Section position="end">
              <TabViewGeneralDetailsSectionCover.Item
                title="Reference"
                contentSectionClassName="mb-2"
              >
                <ShowReference
                  data={materialRequestData}
                  to={`/production/material-request?entry=${materialRequestData?.id}&layout=column`}
                  offCanvas={offCanvas}
                />
              </TabViewGeneralDetailsSectionCover.Item>

              <TabViewGeneralDetailsSectionCover.Item
                title="Tax Included"
                value={
                  [true, 1, '1'].includes(materialRequestData?.tax_included)
                    ? 'Included'
                    : 'Excluded'
                }
              />
            </TabViewGeneralDetailsSectionCover.Section>

            <TabViewGeneralDetailsSectionCover.CustomColumnResultSection
              customColumnItems={materialRequestData?.custom_field_values}
            />
          </TabViewGeneralDetailsSectionCover>

          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={materialRequestData.trans_type}
                      transId={materialRequestData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <MaterialRequestView
                    materialRequestData={materialRequestData}
                  />
                </SimpleBar>
              </Tab>
              {transactions && transactions.length > 0 && (
                <Tab eventKey="transaction" title={Translate('Transactions')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <TransactionsData transaction={transactions} />
                  </SimpleBar>
                </Tab>
              )}
              {materialRequestData.media &&
              materialRequestData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={materialRequestData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}

              {status === 'approved-request' ||
              (!status &&
                !materialRequestData?.user_actions?.Approve &&
                materialRequestData?.id) ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    <PDFView
                      viewType="tab"
                      url={`purchase/material-requests/print/${materialRequestData.id}`}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
              {materialRequestData?.history_events?.length && (
                <Tab
                  eventKey="approval-flow"
                  title={Translate('Approval Flow')}
                >
                  <SimpleBar className="simplebar-view-page-style">
                    <ApprovalWorkFlow
                      data={materialRequestData?.history_events}
                      details={{
                        created_user:
                          materialRequestData?.created_user_full_name,
                        create_time: materialRequestData?.created_at,
                        trans_type_name: materialRequestData?.trans_type_name
                      }}
                    />
                  </SimpleBar>
                </Tab>
              )}
            </Tabs>
          </Container>
        </Card.Body>
        <FooterDetails
          created_at={materialRequestData?.created_at}
          created_by={materialRequestData?.created_user_full_name}
        />
      </Card>
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'MR'}
        id={materialRequestData.id}
        setKey={setKey}
      />
      <SendEmail
        url={'purchase/material-requests/send-mail/' + materialRequestData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={materialRequestData.supplier_email}
      />
      <IssueItemPopUp
        isMaterialRequest
        show={issueItem}
        data={materialRequestData}
        onHide={() => setIssueItem(false)}
        setIssueItem={setIssueItem}
        setIssuedItem={setIssuedItem}
      />
    </>
  );
}

MaterialRequestTabView.propTypes = {
  materialRequestData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  getData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  onHide: PropTypes.bool,
  voidTransactionReport: PropTypes.bool,
  setIssuedItem: PropTypes.func,
  infoPage: PropTypes.bool
};

export default MaterialRequestTabView;
