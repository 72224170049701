import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import { MdRefresh } from 'react-icons/md';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import { showWarningToast } from 'module/Common/Toast/showWarningToast';

const ExportItemRetryButton = ({ exportArrayDispatch, item }) => {
  const [loading, setLoading] = useState(false);

  const handleRetryExport = (e, item) => {
    e.preventDefault();

    setLoading(true);
    showWarningToast(item.initialMessage);

    axios
      .get(item.url, { params: { mode: item.file_type } })
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          // Update export context item
          let exportData = response.data.data;
          exportData.dbId = exportData.id;
          delete exportData.id;
          exportArrayDispatch({
            type: 'UPDATE',
            payload: {
              ...{ ...item, status: false },
              ...response.data.data
            }
          });
          setLoading(false);
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );

          setLoading(false);
        }
      })
      .catch(error => {
        console.error(error);
        showToast(
          `Something went wrong, please refresh the page and try again.`,
          'error'
        );

        setLoading(false);
      });
  };

  return (
    <Button
      variant="primary"
      size="sm"
      className="d-flex flex-row justify-content-center align-items-center mt-2 me-2"
      disabled={loading}
      onClick={e => handleRetryExport(e, item)}
    >
      {loading ? (
        <Spinner animation="border" style={{ width: 12, height: 12 }} />
      ) : (
        <>
          <MdRefresh size={12} />
          <span>Retry</span>
        </>
      )}
    </Button>
  );
};

ExportItemRetryButton.propTypes = {
  exportArrayDispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default ExportItemRetryButton;
