import { React, useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { apiCall } from 'helpers/apiCalls';
import { AuthWizardContext } from 'context/Context';
import eventEmitter from '../helpers/eventEmiter';
import authReducer from 'reducers/authReducer';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';

const AuthProvider = ({ children }) => {
  const [loading, setloading] = useState(true);
  const [invalidHost, setInvalidHost] = useState(false);
  let TranslationData = JSON.parse(localStorage.getItem('ACODAX_TRANSLATION'));
  let assignedPermission = JSON.parse(
    localStorage.getItem('ACODAX_PERMISSION')
  );
  const [user, dispatch] = useReducer(
    authReducer,
    useContext(AuthWizardContext)
  );

  const lockScreen = () => {
    dispatch({ type: 'UPDATE', payload: { ...user, lockScreen: true } });
  };
  const urlObject = new URL(window.location.href);

  const getUserInfo = async () => {
    localStorage.setItem('ACODAX_MANUEL_LOGOUT', false);
    if (
      (process.env.REACT_APP_API_DEVELOPMENT_URL &&
        process.env.REACT_APP_API_CUSTOM_DEVELOPMENT == 'DEV') ||
      process.env.REACT_APP_API_CUSTOM_REQUEST_URL == urlObject.origin
    ) {
      axios.defaults.baseURL = process.env.REACT_APP_API_DEVELOPMENT_URL;
    } else {
      try {
        const apiEndPointDetails = await axios.get(
          process.env.REACT_APP_SET_API_URL,
          {
            headers: {
              withCredentials: true,
              settingBaseUrl: true
            }
          }
        );

        if (!apiEndPointDetails?.data?.api_url) {
          console.error('Invalid Host.!');
          setInvalidHost(true);
          return;
        }

        axios.defaults.baseURL = apiEndPointDetails.data.api_url;
      } catch (error) {
        console.error('Invalid Host.!');
        setInvalidHost(true);
        return;
      }
    }

    let userData = JSON.parse(localStorage.getItem('AXIS_PRO_USER'));
    let data = null;
    if (userData) {
      try {
        // validate token
        data = await apiCall({
          url: 'user/info'
        });

        // update auth context
        userData.username = data.username;
        userData.name = data.name;
        dispatch({
          type: 'UPDATE',
          payload: data
        });
      } catch (error) {
        console.log(error);
      }
    }
    setloading(false);
  };

  const getTranslateInfo = async userData => {
    try {
      const { data } = await axios.get(
        `/translations?locale=${userData?.language}`
      );
      localStorage.setItem('ACODAX_TRANSLATION', JSON.stringify(data.data));
    } catch (error) {
      console.log(error);
    }
  };

  const getAssignedPermission = async () => {
    let userData = JSON.parse(localStorage.getItem('AXIS_PRO_USER'));
    if (userData) {
      try {
        const { data } = await axios.get(`auth/permissions`);
        localStorage.setItem('ACODAX_PERMISSION', JSON.stringify(data.data));
        setloading(false);
      } catch (error) {
        setloading(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem('ACODAX_MANUEL_LOGOUT', false);
    if (!TranslationData && user) {
      getTranslateInfo(user);
    }
    if (!assignedPermission && user) {
      getAssignedPermission();
    }
    getUserInfo();
  }, []);

  //Need to call Info API whenever an update in settings table - So listening to channel to make that
  useEffect(() => {
    console.log('');
    if (!TranslationData && user) {
      getTranslateInfo(user);
    }
    if (!assignedPermission && user) {
      setloading(true);
      getAssignedPermission();
    }
    if (user?.branch_id) {
      const fetchData = async () => {
        try {
          let userData = JSON.parse(localStorage.getItem('AXIS_PRO_USER'));
          let data = null;
          if (userData) {
            data = await apiCall({
              url: 'user/info'
            });

            userData.username = data.username;
            userData.name = data.name;
            dispatch({
              type: 'UPDATE',
              payload: data
            });
            const simulatedChannelData = {
              message: 'settings.updated',
              data: data
            };
            eventEmitter.emit('settingsEmitter', simulatedChannelData);
          }
        } catch (error) {
          console.error(error);
        }
      };

      const connectWebSocket = () => {
        window.Echo.channel(`app-broadcast-${user?.branch_id}`).listen(
          'AcodaxAppBranchBroadCast',
          settingsData => {
            if (settingsData?.type === 'settings.updated') {
              fetchData();
            }
          }
        );
        window.Echo.channel(
          `app-broadcast-${user?.branch_id}-${user?.id}`
        ).listen('AcodaxAppUserBroadCast', settingsData => {
          if (settingsData?.type === 'permissions.updated') {
            getAssignedPermission();
            fetchData();
          }
        });
      };

      // const retryInterval = setInterval(() => {
      //   if (user?.branch_id) {
      //     clearInterval(retryInterval);
      connectWebSocket();
      // }
      // }, 5000);

      return () => {
        // clearInterval(retryInterval);
        window.Echo.channel(`app-broadcast-${user?.branch_id}`).stopListening(
          'AcodaxAppBroadCast'
        );
        window.Echo.channel(
          `app-broadcast-${user?.branch_id}-${user?.id}`
        ).stopListening('AcodaxAppBroadCast');
      };
    }
  }, [user?.branch_id && user?.id]);
  return (
    <AuthWizardContext.Provider
      value={{
        user,
        dispatch,
        lockScreen
      }}
    >
      {loading ? (
        <div
          style={{ width: '100%', minHeight: '100vh' }}
          className="d-flex justify-content-center align-items-center"
        >
          {invalidHost ? (
            <Alert variant="danger">Invalid host.!</Alert>
          ) : (
            <LoadingScreen />
          )}
        </div>
      ) : (
        children
      )}
    </AuthWizardContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired
};
export default AuthProvider;
