import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Spinner, Table } from 'react-bootstrap';
import axios from 'axios';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaCalendarAlt, FaUser } from 'react-icons/fa';
import WorkOrderMaterialRequestLineItemDetailsModal from './modals/WorkOrderMaterialRequestsModal/WorkOrderMaterialRequestLineItemDetailsModal';
import WorkOrderTabDataInfiniteScrollHandler from './components/WorkOrderTabDataInfiniteScrollHandler';
import WorkOrderTabViewFilterAndActionSection from './components/WorkOrderTabViewFilterAndActionSection';

export default function WorkOrderReleasesTabView({
  activeTabKey,
  tabApiCallCompleted,
  setTabApiCallCompleted,
  workOrderId,
  WorkOrderDetailsArray,
  setWorkOrderVoucherData
}) {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [activeItemData, setActiveItemData] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [activeWorkOrderDetailId, setActiveWorkOrderDetailId] =
    useState(undefined);

  function fetchData(page, workOrderDetailId) {
    setIsLoading(true);
    axios
      .get('production/work-order/releases', {
        params: {
          work_order_id: workOrderId,
          page: page ?? 1,
          ...(workOrderDetailId
            ? {
                work_order_detail_id: workOrderDetailId
              }
            : null)
        }
      })
      .then(response => {
        setData(prev =>
          page > 1 && prev?.data && Array.isArray(response?.data?.data?.data)
            ? {
                ...(response?.data?.data ?? null),
                data: [...prev.data, ...(response?.data?.data?.data ?? null)]
              }
            : response?.data?.data
        );
      })
      .catch(error => {
        setErrorMessage(
          error?.response?.data?.message ??
            'Something went wrong, please refresh the page and try again.'
        );
      })
      .finally(() => {
        setIsLoading(false);
        setTabApiCallCompleted(prev => ({
          ...prev,
          releases: true
        }));
      });
  }

  useEffect(() => {
    if (activeTabKey !== 'releases' || tabApiCallCompleted) return;
    fetchData(1, activeWorkOrderDetailId);
  }, [activeTabKey, tabApiCallCompleted, activeWorkOrderDetailId]);

  return (
    <>
      <div className="pt-4 d-flex flex-column flex-fill align-items-between justify-content-between">
        {errorMessage ? (
          <Alert variant="danger">{Translate(errorMessage)}</Alert>
        ) : isLoading && isNaN(data?.meta?.to) ? (
          <Alert className="d-flex flex-row align-items-center gap-2">
            <span>{Translate('Looking for releases')}</span>
            <Spinner
              style={{
                width: 15,
                height: 15
              }}
            />
          </Alert>
        ) : (
          <>
            <WorkOrderTabViewFilterAndActionSection
              keyName="release"
              workOrderId={workOrderId}
              WorkOrderDetailsArray={WorkOrderDetailsArray}
              fetchData={fetchData}
              setTabApiCallCompleted={setTabApiCallCompleted}
              setWorkOrderVoucherData={setWorkOrderVoucherData}
              setActiveWorkOrderDetailId={setActiveWorkOrderDetailId}
            />

            <Table
              responsive
              striped
              bordered
              hover
              className="simple-table-animation"
            >
              <thead>
                <tr className="bg-dark text-light fs--2 text-uppercase">
                  <td>{Translate('Release Date')}</td>
                  <td>{Translate('Item Code')}</td>
                  <td>{Translate('Item Name')}</td>
                  <td className="text-center">
                    {Translate('Released Quantity')}
                  </td>
                  <td>{Translate('Warehouse Name')}</td>
                  <td>{Translate('Released User')}</td>
                  <td>{Translate('Released At')}</td>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data?.data) && data.data.length > 0 ? (
                  data.data.map((item, index) => (
                    <tr key={index} className="fs--1">
                      <td>
                        <div className="d-flex flex-row align-items-center gap-2">
                          <FaCalendarAlt size={12} className="text-muted" />
                          <span>{item?.released_date_formatted}</span>
                        </div>
                      </td>
                      <td>{item?.item?.stock_id ?? '-'}</td>
                      <td>
                        <div className="d-flex flex-row align-items-center gap-2">
                          {item?.item?.item_name ?? '-'}
                        </div>
                      </td>
                      <td className="text-center">
                        {item?.released_quantity ?? '-'}
                      </td>
                      <td className="text-center">
                        {item?.warehouse_name ?? '-'}
                      </td>
                      <td className="text-center">
                        <div className="d-flex flex-row align-items-center gap-2">
                          <FaUser size={12} className="text-muted" />
                          <span>{item?.created_user_full_name ?? '-'}</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-row align-items-center gap-2">
                          <FaCalendarAlt size={12} className="text-muted" />
                          <span>{item?.created_at}</span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="p-0" colSpan={7}>
                      <Alert className="m-0 border-0 text-center rounded-0">
                        {Translate('No Results found!')}
                      </Alert>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            <WorkOrderTabDataInfiniteScrollHandler
              isLoading={isLoading}
              current_page={data?.meta?.current_page}
              last_page={data.meta.last_page}
              nextLink={data?.meta?.current_page + 1}
              loadNextPage={() => fetchData(data?.meta?.current_page + 1)}
            />
          </>
        )}
      </div>

      {activeItemData?.id ? (
        <WorkOrderMaterialRequestLineItemDetailsModal
          targetId={activeItemData.id}
          onHide={() => setActiveItemData(undefined)}
        />
      ) : null}
    </>
  );
}

WorkOrderReleasesTabView.propTypes = {
  activeTabKey: PropTypes.string,
  tabApiCallCompleted: PropTypes.bool,
  setTabApiCallCompleted: PropTypes.func,
  workOrderId: PropTypes.string,
  WorkOrderDetailsArray: PropTypes.array,
  setWorkOrderVoucherData: PropTypes.func
};
