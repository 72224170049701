const SetLineItemsWithItemsIncludedInTheKit = items => {
  if (!items || !Array.isArray(items)) return [];
  return items.reduce((newArrayResult, item) => {
    let arrayWithLineItems =
      parseInt(item.is_kit) === 1 &&
      item.kit_items &&
      Array.isArray(item.kit_items)
        ? item.kit_items
        : [item];
    return [...newArrayResult, ...arrayWithLineItems];
  }, []);
};

export default SetLineItemsWithItemsIncludedInTheKit;
