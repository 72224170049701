import { React } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

const SelectSubLedger = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  ledger_id,
  onFocus
}) => {
  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: `finance/chart-of-accounts/${ledger_id}/sub-ledgers`,
      setParams: searchKey => ({
        name: searchKey
      }),
      setOptions: option => ({
        label: option.name,
        value: option.code
      }),
      onFocus: onFocus
    });

  return (
    <AsyncSelect
      isDisabled={ledger_id ? false : true}
      key={JSON.stringify(ledger_id)}
      cacheOptions
      loadOptions={ledger_id && fetchData}
      onFocus={ledger_id ? setDefaultResult : onFocus ? onFocus : null}
      isLoading={loading}
      defaultOptions={defaultOptions}
      isClearable
      name={name ?? 'sub_ledger'}
      value={value}
      placeholder={placeholder ?? ''}
      onChange={handleFieldChange}
      className={error && 'form-control ps-0 py-0 is-invalid'}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({
          ...provided,
          zIndex: 9999,
          textTransform: 'uppercase'
        }),
        control: provided => ({
          ...provided,
          height: 32,
          minHeight: 32,
          textTransform: 'uppercase',
          ...style
        })
      }}
    />
  );
};

SelectSubLedger.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  ledger_id: PropTypes.string,
  onFocus: PropTypes.func
};

export default SelectSubLedger;
