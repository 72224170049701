import { React } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsBank2, BsCoin } from 'react-icons/bs';

import JournalTable from '../Common/Tables/JournalTable';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';
function SupplierPaymentView({ supplierPaymentVoucherData }) {
  return (
    <Container className="mt-3 pe-3 ps-3 voucher-inquiry-view-wrapper">
      <Row className="ms-1">
        <Col lg={6} md={6} sm={12} className="p-0">
          {supplierPaymentVoucherData?.person_name ? (
            <PersonName value={supplierPaymentVoucherData.person_name} />
          ) : null}
          {supplierPaymentVoucherData?.reference && (
            <Badges
              label={supplierPaymentVoucherData?.reference}
              bg={'danger'}
            />
          )}
          <div className="mt-2">
            {supplierPaymentVoucherData?.bank_account_name && (
              <Details
                label={'Pay From'}
                value={supplierPaymentVoucherData.bank_account_name}
                icon={<BsBank2 className="me-1 text-danger" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-3">
            <Details
              label={'Amount'}
              value={supplierPaymentVoucherData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Balance Amount'}
              value={supplierPaymentVoucherData?.balance_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      {supplierPaymentVoucherData && (
        <JournalTable journalData={supplierPaymentVoucherData} />
      )}
      {supplierPaymentVoucherData?.memo && (
        <ShowMemo memo={supplierPaymentVoucherData.memo} />
      )}
    </Container>
  );
}

SupplierPaymentView.propTypes = {
  supplierPaymentVoucherData: PropTypes.any
};

export default SupplierPaymentView;
