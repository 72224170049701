import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaSliders } from 'react-icons/fa6';
import { FaPlusCircle, FaSave } from 'react-icons/fa';
import { showToast } from 'module/Common/Toast/toast';
import useFormSettingsHook from 'hooks/useFormSettingsHook';
import { formSettingsPropTypesAllowdTypeItems } from 'data/form-settings-data/formSettingsData';

export default function FormSettingsTemplatePopupForm({
  type,
  show,
  onHide,
  callBack,
  method,
  defaultName
}) {
  const Translate = useAxisproTranslate();
  const { handleRenameTemplate, handleAddNewTemplate } =
    useFormSettingsHook(type);
  const [templateName, setTemplateName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleTemplate = async event => {
    try {
      event?.preventDefault();
      if (isLoading) return;
      setIsLoading(true);
      let templateActionResult;
      if (method === 'RENAME') {
        templateActionResult = await handleRenameTemplate(event, templateName);
      } else {
        templateActionResult = await handleAddNewTemplate(event, templateName);
      }

      if (templateActionResult?.status) {
        setTemplateName('');
        templateActionResult?.message &&
          showToast(templateActionResult.message, 'success');
        if (typeof callBack === 'function') callBack(templateActionResult);
        if (typeof onHide === 'function') onHide();
      }
    } catch (error) {
      error?.message && showToast(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show && defaultName) {
      setTemplateName(defaultName);
    }

    return () => {
      setTemplateName('');
    };
  }, [defaultName, show]);

  return (
    <Modal centered show={show}>
      <Modal.Header className="border-0 p-3 pb-0">
        <Modal.Title
          as={'div'}
          className="fw-bold text-dark"
          style={{
            fontSize: '1.1rem'
          }}
        >
          {Translate(
            `Please enter${method === 'RENAME' ? ' a new' : ''} template name:`
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Form onSubmit={handleTemplate}>
          <Form.Group className="p-3">
            {method !== 'RENAME' ? (
              <div className="d-flex flex-row gap-2 mb-3">
                <div className="bg-soft-success p-3 rounded my-auto">
                  <FaSliders size={17} />
                </div>
                <p className="text-secondary m-0">
                  {Translate(
                    `We'll create a new template with default settings using this name, and 
            it will appear in the dropdown. To modify it, select the new template 
            and make your changes.`
                  )}
                </p>
              </div>
            ) : null}
            <Form.Control
              autoFocus
              className="p-2"
              value={templateName}
              onChange={event => setTemplateName(event.target.value)}
              readOnly={isLoading}
            />
          </Form.Group>
          <Form.Group className="d-flex flex-row align-items-center justify-content-end gap-2 bg-soft-secondary p-3">
            <Button
              size="sm"
              variant="success"
              type="submit"
              className="rounded d-flex flex-row align-items-center justify-content-center gap-2 shadow align-self-stretch"
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  style={{
                    width: 18,
                    height: 18
                  }}
                />
              ) : method === 'RENAME' ? (
                <>
                  <FaSave />
                  {Translate('Save Name')}
                </>
              ) : (
                <>
                  <FaPlusCircle />
                  {Translate('Add')}
                </>
              )}
            </Button>
            <Button
              size="sm"
              variant="secondary"
              onClick={
                !isLoading && typeof onHide === 'function' ? onHide : undefined
              }
              className="rounded d-flex flex-row align-items-center justify-content-center gap-2 shadow"
              disabled={isLoading}
            >
              {Translate('Cancel')}
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

FormSettingsTemplatePopupForm.propTypes = {
  show: PropTypes.bool,
  method: PropTypes.oneOf(['ADD', 'RENAME']),
  defaultName: PropTypes.string,
  onHide: PropTypes.func,
  callBack: PropTypes.func,
  type: PropTypes.oneOf(formSettingsPropTypesAllowdTypeItems).isRequired
};
