import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const AccountSection = ({ formData, errors, onChange }) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="username">
        <Form.Label>Username</Form.Label>
        <Form.Control
          placeholder="Enter user name"
          name="username"
          onChange={onChange}
          value={formData.username}
          isValid={formData.username.length > 0 && !errors.username}
          isInvalid={!!errors.username}
        />
        {errors.username ? (
          <Form.Control.Feedback type="invalid">
            {errors.username}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Enter password"
          name="password"
          onChange={onChange}
          value={formData.password}
          isValid={formData.password.length > 0 && !errors.password}
          isInvalid={!!errors.password}
        />
        {errors.password ? (
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3" controlId="cpassword">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Confirm password"
          name="c_password"
          onChange={onChange}
          value={formData.c_password}
          isValid={formData.c_password.length > 0 && !errors.c_password}
          isInvalid={!!errors.c_password}
        />
        {errors.c_password ? (
          <Form.Control.Feedback type="invalid">
            {errors.c_password}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
    </>
  );
};

AccountSection.propTypes = {
  formData: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func
};

export default AccountSection;
