import { ExportContext } from 'context/Context';
import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import exportReducer from 'reducers/exportReducer';

const ExportContextProvider = ({ children }) => {
  const [exportArray, dispatch] = useReducer(
    exportReducer,
    useContext(ExportContext)
  );
  return (
    <ExportContext.Provider value={{ exportArray, dispatch }}>
      {children}
    </ExportContext.Provider>
  );
};

ExportContextProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default ExportContextProvider;
