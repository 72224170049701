import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsPlus } from 'react-icons/bs';

function DropdownAddButton({ title, show }) {
  return (
    <div className="select-item-dropdown-styles">
      <Button
        onClick={show}
        size="sm"
        className="select-item-add-button"
        variant="transparent"
      >
        <BsPlus size={20} />
        {title}
      </Button>
    </div>
  );
}

DropdownAddButton.propTypes = {
  title: PropTypes.string,
  show: PropTypes.func
};

export default DropdownAddButton;
