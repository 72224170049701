import { React, useState } from 'react';
import axios from 'axios';
import { Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaCircle } from 'react-icons/fa';
import { fixedAssetStatus } from 'module/Common/Enum/enum';
import { showToast } from 'module/Common/Toast/toast';

function StatusChangeDropDown({
  name,
  value,
  labelColumn,
  valueColumn,
  labelClassName,
  mainColumn,
  icon,
  id,
  categoryId,
  fetchData,
  assetOffCanvas
}) {
  const [selectedStatus, setSelectedStatus] = useState(value);

  function getColorForStatus(value) {
    const item = fixedAssetStatus.find(item => item.value === value);
    return item ? item.color : 'black';
  }

  const handleStatusChange = status => {
    setSelectedStatus(status?.value);
    axios
      .put(`fixedasset/fa-items/${id}`, {
        fa_status: status?.value,
        category_id: categoryId
      })
      .then(res => {
        if (res?.data?.success) {
          fetchData();
        }
      })
      .catch(error => {
        if (error) {
          showToast('Status not changed! Please contact admin.', 'error');
          setSelectedStatus(value);
        }
      });
  };

  return (
    <Col
      lg={mainColumn ? mainColumn : 6}
      md={mainColumn ? mainColumn : 6}
      sm={12}
      className="p-0 mb-2"
    >
      <Row className={`${assetOffCanvas ? 'm-0 align-items-center' : ''}`}>
        {assetOffCanvas ? (
          <Col
            sm={12}
            lg={5}
            className="text-muted d-flex flex-row justify-content-between"
          >
            {name}
            <span className="ms-1 d-none d-lg-block">:</span>
          </Col>
        ) : (
          <Col
            xs={labelColumn ? labelColumn : 4}
            className={`${labelClassName} d-flex align-items-center`}
          >
            {icon ? icon : ''}
            <h5 className="text-dark mb-0">{name}</h5>
          </Col>
        )}

        <Col xs={valueColumn ? valueColumn : 8}>
          <DropdownButton
            id="dropdown-basic-button"
            className="custom-dropdown-button custom-transperent-common-button status-change-button-width"
            variant="light"
            title={
              <span>
                <FaCircle
                  className="me-1"
                  color={getColorForStatus(selectedStatus?.toLowerCase())}
                  size={10}
                />
                <span className="ms-1 text-capitalize">
                  {selectedStatus?.replace('_', ' ')}
                </span>
              </span>
            }
            size="sm"
          >
            {fixedAssetStatus.map((status, key) => (
              <Dropdown.Item
                key={key}
                className="text-capitalize"
                onClick={() => handleStatusChange(status)}
              >
                <FaCircle className="me-1" color={status?.color} size={10} />
                {status?.label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
      </Row>
    </Col>
  );
}

StatusChangeDropDown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  labelColumn: PropTypes.any,
  valueColumn: PropTypes.any,
  labelClassName: PropTypes.any,
  mainColumn: PropTypes.any,
  icon: PropTypes.any,
  fetchData: PropTypes.func,
  id: PropTypes.any,
  categoryId: PropTypes.any,
  assetOffCanvas: PropTypes.bool
};

export default StatusChangeDropDown;
