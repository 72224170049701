import { React, useEffect, useRef, useState } from 'react';
import { Button, Form, Spinner, InputGroup, Alert } from 'react-bootstrap';
import axios from 'axios';
import { showToast } from '../Toast/toast';
import { FaArrowRight, FaUser } from 'react-icons/fa';
import PropTypes from 'prop-types';

function SendOtpForm({
  isOtpForm,
  setIsotpVerifyForm,
  username,
  setUsername,
  setHiddenNumber,
  backToLogin
}) {
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});
  const inputRef = useRef(null);

  const handleChange = ({ target }) => {
    let value = target.value;
    setUsername(value);
  };

  const sendOtp = () => {
    setFormError({});
    setOnSave(true);
    axios
      .get('/send-otp', {
        params: {
          username: username
        }
      })
      .then(response => {
        if (response.data.success) {
          setIsotpVerifyForm(true);
          if (response.data.data.phone) {
            setHiddenNumber(response.data.data.phone);
          }
        }
        setOnSave(false);
      })
      .catch(error => {
        if (error.response.data) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          setOnSave(false);
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
          setOnSave(false);
        }
      });
  };

  useEffect(() => {
    if (isOtpForm) {
      inputRef.current.focus();
    }
  }, [isOtpForm]);

  return (
    <>
      <Form>
        {Object.keys(formError).length > 0 ? (
          <Form.Group className="mb-3">
            <Alert variant="danger">{formError.username}</Alert>
          </Form.Group>
        ) : null}
        <Form.Group className="mb-3" controlId="username">
          <Form.Label>Email or Phone</Form.Label>
          <InputGroup className={` rounded shadow py-1`}>
            <InputGroup.Text className="border-0 bg-transparent px-2">
              <FaUser size={10} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              name="username"
              placeholder="Enter email or phone number"
              ref={inputRef}
              value={username}
              required
              onChange={handleChange}
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="d-flex flex-row">
          <Button
            variant="success"
            type="button"
            onClick={sendOtp}
            disabled={onSave}
            className="rounded w-100 py-2 d-flex flex-row justify-content-center align-items-center"
          >
            {onSave ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="white"
                  className="me-1"
                  style={{ width: '12px', height: '12px' }}
                />
                <span className="fs--1">Processing...</span>
              </>
            ) : (
              <>
                <span>Continue</span>
                <FaArrowRight className="ms-2" size={15} />
              </>
            )}
          </Button>
        </Form.Group>
      </Form>

      <a
        href="#"
        onClick={backToLogin}
        className="rounded w-100 mt-2 d-flex flex-row justify-content-center align-items-center"
      >
        Back to login
      </a>
    </>
  );
}

SendOtpForm.propTypes = {
  isOtpForm: PropTypes.bool,
  setIsotpVerifyForm: PropTypes.func,
  setUsername: PropTypes.func,
  username: PropTypes.any,
  setHiddenNumber: PropTypes.func,
  backToLogin: PropTypes.func
};

export default SendOtpForm;
