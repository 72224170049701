import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export default function EllipsisOverlayText({ text, limit, emptyCaseResult }) {
  return typeof text === 'string' ? (
    text.length > (typeof limit === 'number' && limit > 0 ? limit : 30) ? (
      <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>}>
        <span>{text.substring(0, limit ?? 30) + '...'}</span>
      </OverlayTrigger>
    ) : (
      text
    )
  ) : emptyCaseResult ? (
    emptyCaseResult
  ) : null;
}

EllipsisOverlayText.propTypes = {
  text: PropTypes.string,
  limit: PropTypes.number,
  emptyCaseResult: PropTypes.string
};
