import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';

export default function ProposalSaveWindowModal({
  show,
  onHide,
  method,
  activeLayout,
  fileName,
  setFileName,
  setPages,
  handleSaveLayoutButton
}) {
  const Translate = useAxisproTranslate();
  const [newLayoutName, setNewLayoutName] = useState(fileName || '');

  const handleCancel = event => {
    event?.preventDefault();
    typeof onHide === 'function' && onHide();
  };

  const handleInputValueChange = event => {
    setNewLayoutName(event?.target?.value || '');
  };

  const handleContinue = event => {
    event?.preventDefault();
    if (newLayoutName?.length < 1) {
      showToast('Enter a valid layout name', 'error');
      return;
    }

    typeof setFileName === 'function' && setFileName(newLayoutName);
    typeof setPages === 'function' &&
      setPages(prev =>
        prev?.map(item =>
          item?.type === 'default' ? { ...item, name: newLayoutName } : item
        )
      );

    typeof handleSaveLayoutButton === 'function' &&
      handleSaveLayoutButton(null, !!(method === 'save'), newLayoutName);
  };

  return (
    <Modal show={show} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title
          as={'p'}
          className="fw-bold text-dark m-0"
          style={{
            fontSize: '1rem'
          }}
        >
          {Translate(
            method === 'update'
              ? 'Update Layout'
              : `Save ${activeLayout?.id ? 'As New' : ''} Layout`
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-1 text-warning">
          {Translate(
            'You can update the layout name by editing the given input value.'
          )}
        </p>
        <Form.Control value={newLayoutName} onChange={handleInputValueChange} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" size="sm" onClick={handleContinue}>
          {Translate('Continue')}
        </Button>
        <Button variant="danger" size="sm" onClick={handleCancel}>
          {Translate('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ProposalSaveWindowModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.bool,
  method: PropTypes.oneOf(['save', 'update']),
  activeLayout: PropTypes.object,
  fileName: PropTypes.string,
  setFileName: PropTypes.func,
  setPages: PropTypes.func,
  handleSaveLayoutButton: PropTypes.func
};
