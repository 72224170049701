const handleTableItem = (data = {}) => {
  const returnItem = item => {
    if (Array.isArray(item?.removeThisKeys)) {
      let removableKeys = ['removeThisKeys', ...item.removeThisKeys];
      let itemkeys = item && typeof item === 'object' ? Object.keys(item) : [];
      removableKeys.map(key => {
        if (itemkeys.includes(key)) {
          delete item[key];
        }
      });
    }
    if (!item?.has_batch_inventory) {
      delete item.batch_number;
      delete item.batch_number_ref;
    }
    return item;
  };

  const generateNextIdRef = details => {
    let nextIdRef = 1;
    const detailsLength = details?.length;
    if (detailsLength > 0) {
      const lastItem = details?.[detailsLength - 1];
      if (lastItem?.id_ref) {
        nextIdRef = parseInt(lastItem?.id_ref) + 1;
      }
    }
    return nextIdRef;
  };

  if (
    data?.data &&
    ['ADD', 'REMOVE', 'UPDATE', 'UPDATE-MULTIPLE', 'REPLACE'].includes(
      data.method
    ) &&
    typeof data?.setFormData === 'function'
  ) {
    data.setFormData(prev => {
      let details;
      switch (data.method) {
        case 'ADD':
          if (data?.data?.has_batch_inventory !== 1) {
            delete data.data.batch_number;
            delete data.data.batch_number_ref;
          }
          details = prev.details
            ? [
                ...prev.details,
                returnItem({
                  ...data.data,
                  id_ref: generateNextIdRef(prev?.details)
                })
              ]
            : [data.data];
          break;

        case 'REMOVE':
          details = prev.details.filter(
            item => item.id_ref !== data.data.id_ref
          );
          break;

        case 'UPDATE':
          details = prev.details.map(item => {
            return item.id_ref === data.data.id_ref
              ? returnItem({ ...item, ...data.data })
              : item;
          });
          break;

        case 'UPDATE-MULTIPLE':
          details = prev.details.map(item => {
            let newData = data.data.find(
              newItem => newItem.id_ref === item.id_ref
            );

            return newData ? returnItem({ ...item, ...newData }) : item;
          });
          break;

        case 'REPLACE':
          details = prev.details.map(item => {
            return item.id_ref === data.data.id_ref
              ? returnItem(data.data)
              : item;
          });
          break;

        default:
          details = [];
          break;
      }
      let newData = {
        ...prev,
        details: details
      };
      if (
        typeof data?.handleSaveDraftData === 'function' &&
        data.saveToDraft === true
      ) {
        data.handleSaveDraftData({ draftableData: newData });
      }
      return newData;
    });
  } else {
    console.error('invalid data');
  }
};

export default handleTableItem;
