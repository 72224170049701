import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import BasicDetails from '../Common/PersonBasedComponents/BasicDetails';
import SalesChart from '../Common/PersonBasedComponents/SalesChart';
import Contacts from '../Common/PersonBasedComponents/Contacts';

function CustomerView({ customerData }) {
  const Translate = useAxisproTranslate();

  return (
    <Container className="mt-3 ps-3 pe-3">
      <Row>
        <Col
          md={4}
          className="p-0"
          style={{ height: '64vh', backgroundColor: 'whitesmoke' }}
        >
          <BasicDetails data={customerData} />
        </Col>
        <Col md={8}>
          <Row>
            <SalesChart title="Income" customer_id={customerData.id} />
          </Row>
          <Row className="mt-1 mb-1 p-2">
            <h5 className="fs-1">{Translate('Customer Contacts')}</h5>
            <Contacts data={customerData} />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

CustomerView.propTypes = {
  customerData: PropTypes.any
};

export default CustomerView;
