import { React, useState } from 'react';
import { Badge, Button, Col, Collapse, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import StatusChangeDropDown from 'module/Common/ViewPages/FixedAsset/components/StatusChangeDropDown';

const AssetDetailsCollapse = ({ data, fetchData }) => {
  const Translate = useAxisproTranslate();
  const [open, setOpen] = useState(true);

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="customer-more-info-collapse"
        aria-expanded={open}
        variant="white"
        size="small"
        className="px-0 text-primary d-flex flex-row align-items-center"
        style={{ border: 'none', outLine: 'none', boxShadow: 'none' }}
      >
        <small>{Translate('Details')}</small>
        <FontAwesomeIcon
          icon={open ? 'caret-down' : 'caret-right'}
          className="ms-1"
        />
      </Button>
      <Collapse in={open}>
        <Row
          id="customer-more-info-collapse"
          className="mt-2"
          style={{ fontSize: '.8rem', fontWeight: '600' }}
        >
          {data?.fa_status && (
            <StatusChangeDropDown
              name={Translate('Asset Status')}
              value={data?.fa_status}
              valueColumn={6}
              mainColumn={12}
              id={data?.id}
              categoryId={data?.category_id}
              assetOffCanvas
              fetchData={fetchData}
            />
          )}
          {data?.item_status ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate('Item Status')}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark text-capitalize">
                <Badge
                  bg={`${
                    data?.item_status === 'Out of Stock'
                      ? 'danger'
                      : data?.item_status === 'in_stock'
                      ? 'success'
                      : data?.item_status === 'rented_out'
                      ? 'warning'
                      : 'danger'
                  }`}
                  className="text-capitalize"
                >
                  {data?.item_status.replace(/_/g, ' ')}
                </Badge>
              </Col>
            </>
          ) : (
            ''
          )}
          {data?.inventory_account_name ? (
            <>
              <Col
                sm={6}
                md={6}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate('Inventory Account')}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={6} md={6} lg={7} className="mb-2 text-dark">
                {data?.inventory_account_name}
              </Col>
            </>
          ) : (
            ''
          )}
          {data?.purchase_account_name ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate('Purchase Account')}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data?.purchase_account_name}
              </Col>
            </>
          ) : (
            ''
          )}
          {data?.sales_account_name ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate('Sales Account')}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data?.sales_account_name}
              </Col>
            </>
          ) : (
            ''
          )}
        </Row>
      </Collapse>
    </>
  );
};

AssetDetailsCollapse.propTypes = {
  data: PropTypes.any,
  fetchData: PropTypes.func
};

export default AssetDetailsCollapse;
