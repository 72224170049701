import { apiCall } from 'helpers/apiCalls';
import React, { useEffect, useState } from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import DebitNoteColumnView from './Page/DebitNoteColumnView';
import DebitNoteTableView from './Page/DebitNoteTableView';
import purchaseCommonFilters from '../common/helpers/purchaseCommonFilters';
import FormattedData from 'module/Common/TableFilter/FormatedData';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { Card } from 'react-bootstrap';
const DebitNoteView = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [permissions] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [entries, setEntries] = useState({});
  const reload =
    location.state && location.state.reload ? location.state.reload : null;
  const pageNumber = queryParams.get('page');
  const layoutType = queryParams.get('layout');
  const filterItems = queryParams.get('filters');
  let purchaseCommonFiltersItems = purchaseCommonFilters;
  delete purchaseCommonFiltersItems.supplier_id;

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      includeCustomFieldsData: true,
      url: 'finance/debit-notes',
      params: {
        page: page,
        ...FormattedData(JSON.parse(filterItems))
      },

      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
    setEntries(data);
    setIsLoading(false);
  };

  useEffect(() => {
    // reset route after reload
    reload &&
      navigate({
        pathname: '/purchase/debit-note',
        search: `?${pageNumber ? 'page=' + pageNumber : ''}${
          layoutType ? '&layout=' + layoutType : ''
        }`,
        replace: true
      });

    getData(pageNumber);
  }, [filterItems, reload, pageNumber]);

  return !permissions?.show_dn ? (
    <Navigate to="/dashboard" />
  ) : layoutType === 'column' && entries?.data?.length > 0 ? (
    !isLoading ? (
      <DebitNoteColumnView data={entries} getData={getData} />
    ) : (
      <Card className=" d-flex align-item-center justify-content-center flex-fill">
        <LoadingScreen message="Looking for data" />
      </Card>
    )
  ) : (
    <DebitNoteTableView data={entries} isLoading={isLoading} />
  );
};

export default DebitNoteView;
