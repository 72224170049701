import { React, useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

function SelectWorkOrder({ handleFieldChange, name, error, value }) {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const setItems = async name => {
    setLoading(true);
    try {
      let response = await axios.get('production/work-orders-auto-complete', {
        params: { reference: name }
      });

      return (
        Array.isArray(response?.data?.data) ? response.data.data : []
      ).map(item => ({
        label: item.reference,
        value: item.id
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleFocus = async () => {
    if (defaultOptions.length < 1) {
      const defaultOptionsData = await setItems('');
      setDefaultOptions(defaultOptionsData);
    }
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={setItems}
        defaultOptions={defaultOptions}
        onFocus={handleFocus}
        isLoading={loading}
        isClearable
        isSelected={true}
        isOptionSelected={item => item.value === value?.value}
        name={name ? name : 'currency_code'}
        value={value}
        placeholder=""
        onChange={handleFieldChange}
        className={`w-100  ${
          error ? ' form-control ps-0 py-0 is-invalid' : ''
        }`}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({
            ...provided,
            zIndex: 9999,
            fontFamily: 'sans-serif',
            fontSize: '12px',
            borderRadius: '0px',
            textTransform: 'capitalize',
            overflow: 'hidden'
          }),
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: '0px',
            border: '1px solid #b5b5b5',
            height: '10px'
          })
        }}
        menuPortalTarget={document.body}
      ></AsyncSelect>
    </>
  );
}

SelectWorkOrder.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.any,
  error: PropTypes.any,
  value: PropTypes.any
};

export default SelectWorkOrder;
