import AppContext, { AuthWizardContext, DeleteContext } from 'context/Context';
import getFormSettingsData, {
  contextSettingsTemplatesIsUpdatingTargetKey,
  contextSettingsTemplatesTargetKey,
  formSettingsPropTypesAllowdTypeItems
} from 'data/form-settings-data/formSettingsData';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import useAxisproTranslate from './useAxisproTranslate';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { showToast } from 'module/Common/Toast/toast';

export default function useFormSettingsHook(type) {
  const Translate = useAxisproTranslate();
  const pendingRequestToastMessage =
    'A pending request has been detected, please wait for the current request to complete.';
  let { user } = useContext(AuthWizardContext);
  const { dispatch: confirmDispatch } = useContext(DeleteContext);
  const { config, setConfig } = useContext(AppContext);
  const [templateIsSwitching, setTemplateIsSwitching] = useState(false);
  const apiCallDelayRef = useRef(null);
  const apiControllerRef = useRef(null);
  const branchId = user?.branch_id;
  const apiRoute = `settings/system/system-settings/form-settings-templates/${branchId}`;
  const settingsData = getFormSettingsData(type);
  const settingsTemplatesIsUpdating = Boolean(
    config?.[contextSettingsTemplatesIsUpdatingTargetKey]
  );
  const allTemplates = config?.[contextSettingsTemplatesTargetKey];
  const thisTemplates = allTemplates?.[type];
  let thisActiveTemplate, thisSettings;
  if (Array.isArray(thisTemplates)) {
    thisActiveTemplate = thisTemplates.find(template => template?.active);
  }

  if (thisActiveTemplate) {
    thisSettings = thisActiveTemplate?.value?.sections?.reduce(
      (result, section) => {
        section?.data?.forEach(item => {
          result[item?.uniqueKey] = item?.show;
        });
        return result;
      },
      {}
    );
  }

  // methods

  const deepClone = object => JSON.parse(JSON.stringify(object));

  const updateTemplateResult = (action, delay) => {
    if (settingsTemplatesIsUpdating) return;
    return new Promise((resolve, reject) => {
      if (typeof allTemplates !== 'object') {
        return reject(Translate('Invalid data'));
      }

      if (apiCallDelayRef?.current) clearTimeout(apiCallDelayRef.current);
      apiCallDelayRef.current = setTimeout(async () => {
        try {
          setConfig(contextSettingsTemplatesIsUpdatingTargetKey, true);

          const allTemplatesDeepClone = deepClone(allTemplates);
          if (action === 'toggle') {
            const activeTemplateDeepClone = deepClone(thisActiveTemplate);
            // update result before saving
            if (!Array.isArray(activeTemplateDeepClone?.value?.sections)) {
              return reject(Translate('Invalid request'));
            }

            activeTemplateDeepClone.value.sections =
              activeTemplateDeepClone.value.sections.map(section => {
                const dataArray = section?.data;
                if (Array.isArray(dataArray)) {
                  section.data = section.data.map(item => {
                    if ('showIsLoading' in item) {
                      delete item.showIsLoading;
                    }

                    if ('show_ref' in item) {
                      item.show = item.show_ref;
                      delete item.show_ref;
                    }
                    return item;
                  });
                }

                return section;
              });

            allTemplatesDeepClone[type] = thisTemplates?.map(template =>
              template?.active ? activeTemplateDeepClone : template
            );
          }

          apiControllerRef.current = new AbortController();
          const apiCall = await axios.put(
            apiRoute,
            {
              form_settings: JSON.stringify(allTemplatesDeepClone)
            },
            { signal: apiControllerRef.current?.signal }
          );

          if (apiCall?.status !== 200) {
            return reject(Translate('Invalid request'));
          }

          setConfig(contextSettingsTemplatesTargetKey, allTemplatesDeepClone);
          return resolve('result updated');
        } catch (error) {
          return reject(
            error instanceof Error
              ? error
              : new Error(Translate('An error occurred'))
          );
        } finally {
          apiControllerRef.current = null;
          setConfig(contextSettingsTemplatesIsUpdatingTargetKey, false);
        }
      }, delay || 0);
    });
  };

  const handleToggle = async event => {
    try {
      if (settingsTemplatesIsUpdating && !apiControllerRef.current) {
        showToast(pendingRequestToastMessage, 'error');
        return;
      }

      apiControllerRef?.current?.abort();

      const name = event.target?.name;
      if (!name || !thisActiveTemplate) return;

      const nameDetails = name.split('-');
      const sectionIndex = nameDetails?.[1];
      const itemIndex = nameDetails?.[2];
      if (!sectionIndex || !itemIndex) return;

      const targetItem =
        thisActiveTemplate?.value?.sections?.[sectionIndex]?.data?.[itemIndex];
      if (!targetItem) return;

      targetItem.showIsLoading = true;
      setConfig(contextSettingsTemplatesTargetKey, allTemplates);
      targetItem.show_ref = event.target.checked;

      await updateTemplateResult('toggle', 2000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleTemplate = async (event, templateName, type) => {
    if (settingsTemplatesIsUpdating) {
      showToast(pendingRequestToastMessage, 'error');
      return;
    }

    return new Promise((resolve, reject) => {
      event?.preventDefault();
      const response = { status: false, message: '' };
      if (typeof templateName !== 'string' || !templateName) {
        response.message = 'Enter a valid template name.';
        return reject(response);
      }

      const duplicateItem = thisTemplates?.find(
        item => item.label?.toLowerCase() === templateName?.toLowerCase()
      );

      if (duplicateItem) {
        response.message =
          'Template name already exists, please enter another name';
        return reject(response);
      }

      if (type === 'rename') {
        if (thisActiveTemplate?.label === 'Default') {
          response.message = 'You cannot rename the default template.';
          return reject(response);
        }

        thisActiveTemplate.label = templateName;
      }

      if (type === 'add') {
        if (Array.isArray(thisTemplates)) {
          thisTemplates.push({
            label: templateName,
            value: deepClone(settingsData)
          });
        }
      }

      updateTemplateResult()
        .then(() => {
          response.status = true;
          response.message =
            type === 'rename'
              ? 'Template has been successfully renamed.'
              : 'Template created successfully.';
          return resolve(response);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  const handleSelectTemplate = async value => {
    if (settingsTemplatesIsUpdating) {
      showToast(pendingRequestToastMessage, 'error');
      return;
    }
    try {
      if (!Array.isArray(thisTemplates)) return;
      setTemplateIsSwitching(true);

      const selectedItemTarget = thisTemplates.find(
        item => item?.label === value?.label
      );

      if (!selectedItemTarget || selectedItemTarget?.active) return;
      thisActiveTemplate.active = false;
      selectedItemTarget.active = true;
      await updateTemplateResult();
    } catch (error) {
      console.error(error);
    } finally {
      setTemplateIsSwitching(false);
    }
  };

  const handleRemoveTemplate = event => {
    event?.preventDefault();
    if (settingsTemplatesIsUpdating) {
      showToast(pendingRequestToastMessage, 'error');
      return;
    }

    if (!thisActiveTemplate || thisActiveTemplate?.label === 'Default') return;
    const configs = {
      type: 'CONFIG',
      payload: {
        show: true,
        title: Translate('Remove Selected Template'),
        message: Translate(
          'Are you sure you want to permanently remove the selected template?'
        ),
        onSubmit: async () => {
          try {
            confirmDispatch({
              ...configs,
              payload: {
                ...configs?.payload,
                buttonText: (
                  <div className="d-flex flex-row align-items-center justify-content-center gap-2">
                    <Spinner
                      animation="border"
                      style={{
                        width: 11,
                        height: 11
                      }}
                    />
                    <span>{Translate('Deleting')}</span>
                  </div>
                ),
                disableSubmitButton: true,
                disableCancelButton: true
              }
            });

            thisActiveTemplate.active = false;

            // set default active item
            const defaultTemplate = thisTemplates.find(
              item => item?.label === 'Default'
            );

            if (defaultTemplate) {
              defaultTemplate.active = true;
            }

            // remove selected template
            allTemplates[type] = thisTemplates?.filter(
              item => item?.label !== thisActiveTemplate?.label
            );

            await updateTemplateResult();

            confirmDispatch({ type: 'RESET' });
          } catch (error) {
            console.error(error);
          }
        }
      }
    };

    confirmDispatch(configs);
  };

  // methods end

  return {
    settings: thisSettings,
    templates: thisTemplates,
    activeTemplate: thisActiveTemplate,
    handleToggle: handleToggle,
    handleAddNewTemplate: (event, templateName) =>
      handleTemplate(event, templateName, 'add'),
    handleRenameTemplate: (event, templateName) =>
      handleTemplate(event, templateName, 'rename'),
    handleSelectTemplate: handleSelectTemplate,
    handleRemoveTemplate: handleRemoveTemplate,
    templateIsSwitching: templateIsSwitching,
    settingsTemplatesIsUpdating: settingsTemplatesIsUpdating
  };
}

useFormSettingsHook.propTypes = {
  type: PropTypes.oneOf(formSettingsPropTypesAllowdTypeItems).isRequired
};
