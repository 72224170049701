import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
const SelectMultiYear = ({
  handleFieldChange,
  name,
  value,
  style,
  placeholder,
  selectRef,
  yearData
}) => {
  return (
    <Select
      isClearable
      isMulti
      placeholder={placeholder ?? ''}
      options={yearData}
      value={value}
      onChange={handleFieldChange}
      ref={selectRef}
      name={name ? name : 'user'}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({
          ...provided,
          height: 'auto',
          borderRadius: '0px',
          minHeight: 32,
          border: '1px solid #b5b5b5',
          ...style
        })
      }}
    />
  );
};

SelectMultiYear.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.object,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  groupDetail: PropTypes.any,
  selectRef: PropTypes.any,
  yearData: PropTypes.any
};

export default SelectMultiYear;
