import React from 'react';
import { Image, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdOutlineClose } from 'react-icons/md';

import WhatsAppImage from 'assets/img/icons/whatsapp.png';

function ModalHeader({ title, onHide }) {
  return (
    <Modal.Title className="d-flex align-items-center justify-content-between modal-title w-100">
      <div className="d-flex align-items-center">
        <Image src={WhatsAppImage} height={23} width={23} />
        <span className="text-uppercase text-dark">{title}</span>
      </div>
      <div onClick={onHide} style={{ cursor: 'pointer' }}>
        <MdOutlineClose className="text-dark" size={19} />
      </div>
    </Modal.Title>
  );
}

ModalHeader.propTypes = {
  onHide: PropTypes.func,
  title: PropTypes.string
};

export default ModalHeader;
