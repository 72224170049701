import { React } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { AiOutlineFileText } from 'react-icons/ai';
import { MdOutlinePayments } from 'react-icons/md';
import { BiReceipt } from 'react-icons/bi';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';

const AddVoucherButton = ({ ...props }) => {
  const Translate = useAxisproTranslate();
  const permission = {
    create_jv: getAcodaxPermissionSlug('JV', 'create'),
    create_pv: getAcodaxPermissionSlug('PV', 'create'),
    create_rv: getAcodaxPermissionSlug('RV', 'create'),
    create_bt: getAcodaxPermissionSlug('BT', 'create')
  };

  return (
    <Dropdown {...props}>
      <Dropdown.Toggle
        variant="light"
        className="dropdown-button-style me-1 text-uppercase"
        style={{
          backgroundColor: 'whitesmoke',
          border: '1px solid rgb(212, 210, 210)'
        }}
      >
        {Translate('Add')}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {[
          {
            name: Translate('Journal Voucher'),
            icon: <AiOutlineFileText className="me-1 text-dark" />,
            route: '/finance/journal-voucher/add',
            slug: permission?.create_jv
          },
          {
            name: Translate('Payment Voucher'),
            icon: <MdOutlinePayments className="me-1 text-dark" />,
            route: '/finance/payment-voucher/add',
            slug: permission?.create_pv
          },
          {
            name: Translate('Receipt Voucher'),
            icon: <BiReceipt className="me-1 text-dark" />,
            route: '/finance/receipt-voucher/add',
            slug: permission?.create_rv
          },
          {
            name: Translate('Bank Transfer'),
            icon: <BiReceipt className="me-1 text-dark" />,
            route: '/finance/bank-transfer/add',
            slug: permission?.create_bt
          }
        ].map(({ name, route, icon, slug }, index) =>
          slug ? (
            <Dropdown.Item
              key={index}
              as={Link}
              to={route}
              className="d-flex align-items-center"
            >
              {icon}
              {name}
            </Dropdown.Item>
          ) : (
            ''
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddVoucherButton;
