import { React, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { useLocation } from 'react-router-dom';
import DebitNoteTabView from 'module/Common/ViewPages/DebitNote/DebitNoteTabView';

function DebitNoteInfo({
  debitNoteId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [issuedItem, setIssuedItem] = useState(false);
  const location = useLocation();
  const apiCallDelayTime = location?.state?.apiCallDelay ?? 0;

  const fetchData = async () => {
    let url = voidTransactionReport
      ? 'settings/void-transaction-details'
      : `finance/debit-notes/${debitNoteId}`;
    await axios
      .get(url, {
        params: {
          trans_type: voidTransactionReport ? 'DN' : '',
          trans_no: voidTransactionReport ? debitNoteId : ''
        }
      })
      .then(res => {
        if (res.data.success) {
          setData(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
    setIssuedItem(false);
  };

  useEffect(() => {
    setLoading(true);
    const apiCallTime = setTimeout(() => {
      fetchData();
    }, apiCallDelayTime);
    return () => clearTimeout(apiCallTime);
  }, [debitNoteId, apiCallDelayTime]);

  useEffect(() => {
    if (issuedItem) {
      fetchData();
    }
  }, [issuedItem]);

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : (
        <DebitNoteTabView
          debitNoteInfoData={data}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
        />
      )}
    </>
  );
}

DebitNoteInfo.propTypes = {
  debitNoteId: PropTypes.string,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func
};

export default DebitNoteInfo;
