import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import PhoneNumberInput from 'components/form/PhoneNumberInput';
import SelectCustomFieldItem from '../SelectCustomFieldItem';

export default function CustomFieldsSection({
  customFieldItems,
  layout,
  formData,
  formError,
  handleFieldChange,
  saveDraftData
}) {
  const Translate = useAxisproTranslate();

  function renderElement({ type, props }) {
    const Elements = {
      text: <Form.Control {...props} />,
      email: <Form.Control type="email" {...props} />,
      date: (
        <AppDatePicker
          yearPlaceholder="yyyy"
          monthPlaceholder="mm"
          dayPlaceholder="dd"
          showDefaultDate
          {...props}
        />
      ),
      number: <PhoneNumberInput {...props} />,
      longText: <Form.Control type="text" as="textarea" {...props} />,
      select: (
        <SelectCustomFieldItem style={{ borderRadius: 'none' }} {...props} />
      )
    };

    return Elements?.[type];
  }

  return !Array.isArray(customFieldItems) ||
    customFieldItems?.length < 0 ? null : layout === 'grid' ? (
    customFieldItems.map((item, key) => (
      <Col key={item?.id ?? key} md={4}>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId={item?.id || encodeURI(item?.label)}
        >
          <Form.Label
            column
            xl={4}
            md={4}
            sm={12}
            className={
              ['1', 1, true].includes(item?.is_required) ? 'require-data' : ''
            }
          >
            {Translate(item?.label ?? '')}
          </Form.Label>
          <Col
            xl={8}
            md={8}
            sm={12}
            className="d-flex flex-row align-items-center"
          >
            <div className="flex-fill">
              {item?.id
                ? renderElement({
                    type: item?.column_type,
                    props: {
                      name: `custom_fields[${item.id}]`,
                      onChange: handleFieldChange,
                      value: formData?.[`custom_fields[${item.id}]`] ?? '',
                      onFocus: saveDraftData,
                      placeholder: item?.place_holder,
                      ...(item?.column_type === 'select'
                        ? {
                            value:
                              formData?.[
                                `custom_fields[${item.id}]` + '_ref'
                              ] ?? '',
                            placeholder: item?.place_holder ?? 'Search...',
                            options: item?.option_custom_value
                          }
                        : null)
                    }
                  })
                : null}
            </div>
            <FormErrorPopover
              id={item?.id || encodeURI(item?.label)}
              totalErrorCount={Object.keys(formError).length}
              errorMessage={formError?.['custom_fields.' + item?.id]}
              addSpace
            />
          </Col>
        </Form.Group>
      </Col>
    ))
  ) : (
    <Alert variant="danger">Please provide a valid layout</Alert>
  );
}

CustomFieldsSection.propTypes = {
  customFieldItems: PropTypes.array.isRequired,
  layout: PropTypes.oneOf(['grid']).isRequired,
  formData: PropTypes.object.isRequired,
  formError: PropTypes.object.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  saveDraftData: PropTypes.func.isRequired
};
