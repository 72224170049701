import SelectItem from 'components/form/SelectItem';
import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
const AddItemForm = ({ itemFromData, handleFieldChange }) => {
  const Translate = useAxisproTranslate();
  return (
    <Row>
      <Col md={12}>
        <Form.Group as={Row} className="mb-3" controlId="stock_id">
          <Form.Label column md={12} className="text-dark fs--2 require-data">
            {Translate('ITEM NAME')}
          </Form.Label>
          <Col md={12} className="d-flex flex-row align-items-center">
            <div className="flex-fill">
              <SelectItem
                name="item"
                handleFieldChange={handleFieldChange}
                value={itemFromData?.item_ref}
                filter_keys={{
                  exclude_kits: '1',
                  product_type: 'service'
                }}
              />
            </div>
          </Col>
        </Form.Group>
      </Col>
      <Col md={12}>
        <Form.Group as={Row} className="mb-3" controlId="unit_price">
          <Form.Label column md={12} className="require-data text-dark fs--2">
            {Translate('AMOUNT')}
          </Form.Label>
          <Col md={12} className="d-flex flex-row align-items-center">
            <Form.Control
              name="unit_price"
              onChange={handleFieldChange}
              value={itemFromData?.unit_price}
            />
          </Col>
        </Form.Group>
      </Col>
      <Col md={12}>
        <Form.Group as={Row} className="mb-3" controlId="quantity">
          <Form.Label column md={12} className="require-data text-dark fs--2">
            {Translate('QUANTITY')}
          </Form.Label>
          <Col md={12} className="d-flex flex-row align-items-center">
            <Form.Control
              name="quantity"
              onChange={handleFieldChange}
              value={itemFromData?.quantity}
            />
          </Col>
        </Form.Group>
      </Col>
      <Col md={12} className="">
        <Form.Group as={Row} className="mb-3" controlId="description">
          <Form.Label
            column
            md={12}
            className="text-dark text-uppercase fs--2 "
          >
            {Translate('Description')}
          </Form.Label>
          <Col md={12} className="d-flex flex-row align-items-center">
            <div className="flex-fill">
              <Form.Control
                type="text"
                as="textarea"
                name="description"
                rows={5}
                value={itemFromData?.description}
                onChange={handleFieldChange}
              />
            </div>
          </Col>
        </Form.Group>
      </Col>
    </Row>
  );
};
AddItemForm.propTypes = {
  formData: PropTypes.object,
  itemFromData: PropTypes.object,
  handleFieldChange: PropTypes.func
};
export default AddItemForm;
