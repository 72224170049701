import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BiRefresh } from 'react-icons/bi';
import { MdOutlineAdd } from 'react-icons/md';
import { FaFilter, FaTimes } from 'react-icons/fa';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FilterButton from 'components/filter/FilterButton';
import AddVoucherButton from 'module/Finance/voucherInquiry/AddVoucherButton';
import FilterModal from '../Common/TableFilter/FilterModal';

function ListHeader({
  title,
  route,
  refresh,
  filter = false,
  filters,
  filterUrl,
  permission,
  add,
  customer,
  salesman,
  supplier,
  voucher,
  search,
  showFilter,
  column,
  moduleName,
  enableDropDown,
  url,
  dropDownData,
  onClick,
  modalForm,
  isRefresh
}) {
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get('filters');
  const handleModal = () => setShow(!show);
  const navigate = useNavigate();
  const resetFilters = () => {
    if (!column) {
      navigate({
        pathname: '',
        search: ''
      });
    } else {
      navigate({
        pathname: '',
        search: `layout=column`
      });
    }
  };
  return (
    <Row className="flex-between-center">
      <Col lg={12} sm={12} md={12} className="d-flex align-items-center ">
        <span className="fs--1 fw-bold text-dark text-uppercase me-auto">
          {title}
        </span>
        <div className="d-flex gap-1">
          {add ? <AddVoucherButton className="me-0" /> : ''}
          {route && permission ? (
            <Button
              title={Translate('Add')}
              variant="transaprent"
              size="sm"
              as={Link}
              to={route}
              className="three-column-layout-list-button"
            >
              <MdOutlineAdd size={20} className="text-dark" />
            </Button>
          ) : (
            ''
          )}
          {modalForm && permission ? (
            <Button
              title={Translate('Add')}
              variant="transaprent"
              size="sm"
              onClick={onClick}
              className="three-column-layout-list-button"
            >
              <MdOutlineAdd size={20} className="text-dark" />
            </Button>
          ) : (
            ''
          )}
          {refresh ? (
            <Button
              title={Translate('Refresh')}
              variant="transaprent"
              size="sm"
              className="three-column-layout-list-button"
              onClick={refresh}
            >
              <BiRefresh
                size={20}
                className={`text-dark ${
                  isRefresh && 'spinning-list-header-icon'
                }`}
              />
            </Button>
          ) : (
            ''
          )}
          {filter ? (
            <FilterButton
              filters={filters}
              url={filterUrl}
              className="three-column-layout-list-button"
              variant="transparent"
              size="md"
              customer={customer}
              salesman={salesman}
              supplier={supplier}
              search={search}
              voucher={voucher}
            />
          ) : (
            ''
          )}
          {showFilter && (
            <ButtonGroup
              aria-label="filter-button-group"
              className="three-column-layout-list-button"
            >
              <Button
                size={'md'}
                variant="transparent"
                className={`d-flex flex-row align-items-center`}
                onClick={handleModal}
              >
                <>
                  <FaFilter size={10} />
                </>
              </Button>
              {filterItems && (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="clear-filter-toolptip" className="small">
                      {Translate('Clear all filters')}
                    </Tooltip>
                  }
                >
                  <Button
                    size="sm"
                    variant={'danger'}
                    className={`d-flex flex-row align-items-center px-1 position-absolute rounded-circle`}
                    style={{
                      top: '-10px',
                      right: '-5px',
                      width: '17px',
                      height: '17px',
                      zIndex: '1'
                    }}
                    onClick={resetFilters}
                  >
                    <>
                      <FaTimes size={10} />
                    </>
                  </Button>
                </OverlayTrigger>
              )}
            </ButtonGroup>
          )}
        </div>
      </Col>
      <FilterModal
        show={show}
        handleModal={handleModal}
        setShow={setShow}
        moduleName={moduleName}
        column={column}
        enableDropDown={enableDropDown}
        url={url}
        dropDownData={dropDownData}
      />
    </Row>
  );
}

ListHeader.propTypes = {
  title: PropTypes.string,
  route: PropTypes.string,
  refresh: PropTypes.func,
  filter: PropTypes.bool,
  filters: PropTypes.object,
  filterUrl: PropTypes.string,
  permission: PropTypes.bool,
  add: PropTypes.bool,
  customer: PropTypes.bool,
  salesman: PropTypes.bool,
  supplier: PropTypes.bool,
  search: PropTypes.any,
  voucher: PropTypes.bool,
  showFilter: PropTypes.bool,
  column: PropTypes.bool,
  moduleName: PropTypes.string,
  enableDropDown: PropTypes.any,
  url: PropTypes.any,
  dropDownData: PropTypes.any,
  onClick: PropTypes.func,
  modalForm: PropTypes.bool,
  isRefresh: PropTypes.bool
};

export default ListHeader;
