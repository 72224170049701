import { React } from 'react';

import { Card, Col, Modal, Row } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import CommonComments from './Comments/CommonComments';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RiMessageFill } from 'react-icons/ri';
import { useState } from 'react';
import { apiCall } from 'helpers/apiCalls';
import { useEffect } from 'react';
import { ImCross } from 'react-icons/im';
import PropTypes from 'prop-types';
function FollowupComments({ show, invoiceData }) {
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const [followData, setFollowData] = useState({});
  const Follow_id = queryParams.get('show_id');
  const getData = async () => {
    const data = await apiCall({
      url: `sales/followups/${Follow_id}`,
      params: {
        filters: [{ column: 'followup_id', operator: '=', value: Follow_id }]
      }
    });
    setFollowData(data);
  };
  useEffect(() => {
    if (Follow_id) {
      getData(Follow_id);
    }
  }, [Follow_id]);
  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: 9998 }}
        className="modal-with-overlay"
      >
        <Card>
          <Card.Header className="fs-1 text-dark d-flex align-items-center border-bottom justify-content-between">
            <div>
              <RiMessageFill size={20} className={'me-2'} />
              {Translate('Comments')}&nbsp;
              <span style={{ cursor: 'pointer' }}></span>
            </div>
            <div>
              <ImCross
                size={15}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={6} sm={12} className={'m-0'}>
                <div className="p-2 ps-0 d-flex align-items-center">
                  {Translate('Customer Name')}
                  &nbsp;: &nbsp;
                  <span className="fw-bold text-warning">
                    {invoiceData.customer_name}
                  </span>
                </div>
              </Col>
              <Col md={6} sm={12} className={'m-0'}>
                <div className="p-2 ps-0 d-flex align-items-center">
                  {Translate('Reference')}
                  &nbsp;: &nbsp;
                  <span className="fw-bold ">{invoiceData.reference}</span>
                </div>
              </Col>
              <Col md={6} sm={12} className={'m-0'}>
                <div className="p-2 ps-0 d-flex align-items-center">
                  {Translate('Follow Up Date')}
                  &nbsp;: &nbsp;
                  <span className="fw-bold ">
                    {followData.followup_date_formatted}
                  </span>
                </div>
              </Col>
              <Col md={6} sm={12} className={'m-0'}>
                <div className="p-2 ps-0 d-flex align-items-center">
                  {Translate('Next Follow Up Date')}
                  &nbsp;: &nbsp;
                  <span className="fw-bold ">
                    {followData.next_followup_date_formatted}
                  </span>
                </div>
              </Col>
              <Col md={12} sm={12} className={'m-0'}>
                <div className="p-2 ps-0 d-flex align-items-center">
                  {Translate('Notes')}
                  &nbsp;: &nbsp;
                  <span className="fw-bold ">{followData.memo}</span>
                </div>
              </Col>
            </Row>
            <CommonComments id={Follow_id} commentType={'SI'} />
          </Card.Body>
        </Card>
      </Modal>
    </>
  );
}
FollowupComments.propTypes = {
  show: PropTypes.bool,
  invoiceData: PropTypes.any
};

export default FollowupComments;
