import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import ChatFileUpload from 'components/file-controller/ChatFileUpload';
import setNewFormData from 'helpers/setNewFormData';
import { BsFillSendFill } from 'react-icons/bs';

const ChatWindowFooter = ({
  formData,
  setData,
  setFormData,
  data,
  setAttachments,
  attachments,
  messagesEndRef,
  pageNumber
}) => {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const [isFailed, setIsFailed] = useState(false);

  const onKeyDown = e => {
    e.preventDefault;
    if (e.key === 'Tab') {
      sendMessage();
    }
  };

  const handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;

    let newData = {
      ...formData,
      [name]: value
    };

    setFormData(newData);
  };

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleFiles = files => {
    handleFieldChange({ target: { name: 'files', value: files } });
  };

  let newData = setNewFormData(formData);

  const sendMessage = () => {
    setIsFailed(false);
    if (
      formData.message == '' &&
      formData.files &&
      formData.files.length <= 0
    ) {
      showToast('Message or Attachments required');
    } else {
      const newObj = {
        message: formData.message,
        sender_id: user.id,
        media: attachments,
        success: true
      };
      setAttachments([]);
      setData([...data, newObj]);

      setFormData({ ...formData, message: '', files: [] });
      axios({
        method: 'post',
        url: `user/chats`,
        data: newData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          if (pageNumber == 1) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          if (response.data.success) {
            console.log(response);
          } else {
            setIsFailed(true);
          }
        })
        .catch(() => {
          if (pageNumber == 1) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          setIsFailed(true);
        });
    }
  };

  useEffect(() => {
    if (isFailed) {
      if (data.length > 0) {
        const lastIndex = data.length - 1;
        const updatedItem = {
          ...data[lastIndex],
          success: false
        };

        const updatedData = [...data];
        updatedData[lastIndex] = updatedItem;
        setData(updatedData);
      }
    }
  }, [isFailed]);

  return (
    <div className="d-flex w-100 p-0">
      <Form.Control
        as="textarea"
        rows="1"
        type="text"
        name="message"
        onChange={handleChange}
        onKeyDown={onKeyDown}
        value={formData.message}
        className=" bg-light"
        placeholder={Translate('Type something...')}
      />
      <div className="d-flex justify-content-end align-items-center">
        <ChatFileUpload
          limit={30}
          limitFeature={true}
          limitFileSizeInMB={5}
          limitFileType={[
            'jpg',
            'png',
            'jpeg',
            'pdf',
            'excel',
            'xlsx',
            'docx',
            'doc'
          ]}
          onChange={handleFiles}
          setAttachments={setAttachments}
          className="ms-1 me-1"
        />
        <Button
          style={{ backgroundColor: '#0A80FE' }}
          className="rounded-1"
          size={'sm'}
          onClick={sendMessage}
        >
          <BsFillSendFill />
        </Button>
      </div>
    </div>
  );
};

ChatWindowFooter.propTypes = {
  formData: PropTypes.any,
  setFormData: PropTypes.func,
  data: PropTypes.any,
  setData: PropTypes.func,
  setAttachments: PropTypes.func,
  attachments: PropTypes.array,
  messagesEndRef: PropTypes.any,
  pageNumber: PropTypes.any
};

export default ChatWindowFooter;
