import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

function EntryItem({
  item,
  active,
  pageNumber,
  route,
  defaultRoute,
  focusRef,
  index
}) {
  return (
    <ListGroup.Item
      tabIndex={index}
      as={Link}
      to={defaultRoute ? defaultRoute : route + item.id + '/' + pageNumber}
      className={`d-flex active-list-item text-decoration-none pt-3 pb-3 ${
        active && 'active-tab'
      }`}
      style={{
        backgroundColor: active ? '#edf2f9' : '#fff'
      }}
      ref={focusRef}
    >
      <div className="ms-2 w-100 d-flex flex-row align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <div className="list-item-letters">
            {item.customer_name
              ? item.customer_name.charAt(0)
              : item.person_name
              ? item.person_name.charAt(0)
              : 'z'}
          </div>
          <div>
            <h6
              className="text-dark text-uppercase"
              style={{ fontSize: '.84rem' }}
            >
              {item.customer_name
                ? item.customer_name
                : item.person_name
                ? item.person_name
                : ''}
            </h6>
            <h6 className="text-muted m-0" style={{ fontSize: '.74rem' }}>
              {item.trans_date_formatted}
            </h6>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start">
          <div className="d-flex flex-column ms-auto">
            <h6 className="fw-normal" style={{ fontSize: '.74rem' }}>
              {item.reference}
            </h6>
            <h6 className="fw-normal m-0" style={{ fontSize: '.74rem' }}>
              {item.amount_formatted}
            </h6>
          </div>
        </div>
      </div>
    </ListGroup.Item>
  );
}

EntryItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool,
  pageNumber: PropTypes.string,
  route: PropTypes.any,
  defaultRoute: PropTypes.string,
  focusRef: PropTypes.any,
  index: PropTypes.any
};

export default EntryItem;
