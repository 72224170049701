import SelectFormSettingsTemplate from 'components/form/SelectFormSettingsTemplate';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
import FormSettingsTemplatePopupForm from 'components/form/popup-forms/FormSettingsTemplatePopupForm';
import FormLayer from 'module/Common/Layer/FormLayer';
import AppContext from 'context/Context';
import useFormSettingsHook from 'hooks/useFormSettingsHook';
import {
  contextSettingsTemplatesIsUpdatingTargetKey,
  formSettingsPropTypesAllowdTypeItems
} from 'data/form-settings-data/formSettingsData';

export default function FormSettingsOffcanvasAddSection({ type }) {
  const Translate = useAxisproTranslate();
  const { config } = useContext(AppContext);
  const settingsTemplatesIsUpdating = Boolean(
    config?.[contextSettingsTemplatesIsUpdatingTargetKey]
  );

  const {
    activeTemplate,
    handleSelectTemplate,
    handleRemoveTemplate,
    templateIsSwitching
  } = useFormSettingsHook(type);

  const [showModal, setShowModal] = useState(false);
  const [showModalType, setShowModalType] = useState(undefined);

  const handleModal = (event, type) => {
    event?.preventDefault();
    setShowModal(!showModal);
    if (type) {
      setShowModalType(type);
    }
  };

  return (
    <>
      {templateIsSwitching ? (
        <FormLayer style={{ zIndex: 1016, position: 'fixed' }} />
      ) : null}
      <SelectFormSettingsTemplate
        withAddButton
        type={type}
        placeholder={Translate('Select settings template')}
        handleFieldChange={handleSelectTemplate}
        value={activeTemplate || null}
        isDisabled={settingsTemplatesIsUpdating}
      />

      <div className="border-top border-bottom d-flex flex-row">
        <ActionButton
          variant="transparent"
          onClick={event => handleModal(event, 'ADD')}
          disabled={settingsTemplatesIsUpdating}
        >
          <FaPlusCircle size={10} />
          <small>{Translate('Add')}</small>
        </ActionButton>
        <ActionButton
          variant="transparent"
          className="border-start border-end"
          onClick={event => handleModal(event, 'RENAME')}
          disabled={
            settingsTemplatesIsUpdating ||
            Boolean(activeTemplate?.label === 'Default')
          }
        >
          <FaEdit size={10} />
          <small>{Translate('Rename')}</small>
        </ActionButton>

        <ActionButton
          variant="transparent"
          onClick={handleRemoveTemplate}
          disabled={
            settingsTemplatesIsUpdating ||
            Boolean(activeTemplate?.label === 'Default')
          }
        >
          <FaTrash size={10} />
          <small>{Translate('Remove')}</small>
        </ActionButton>
      </div>

      <FormSettingsTemplatePopupForm
        type={type}
        show={showModal}
        method={showModalType}
        defaultName={
          showModalType === 'RENAME' ? activeTemplate?.label : undefined
        }
        onHide={handleModal}
      />
    </>
  );
}

FormSettingsOffcanvasAddSection.propTypes = {
  type: PropTypes.oneOf(formSettingsPropTypesAllowdTypeItems).isRequired
};

const ActionButton = ({ children, className, ...props }) => {
  return (
    <Button
      type="button"
      variant="transparent"
      className={`py-2 flex-fill shadow-none d-flex flex-row align-items-center justify-content-center gap-2 m-0 border-0 outline-none ${className}`}
      size="sm"
      {...props}
    >
      {children}
    </Button>
  );
};

ActionButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};
