const generateFilterFormData = (e, action, oldData) => {
  let newOldData = {};
  if (action) {
    newOldData = {
      ...oldData,
      [action.name]:
        e && Array.isArray(e)
          ? e.map(items => items.value).join(',')
          : e && e.value
          ? e.value
          : null,
      [action.name + '_ref']: e
    };
  } else {
    newOldData = {
      ...oldData,
      [e.target.name]:
        e.target.type === 'checkbox'
          ? !e.target.checked
            ? 0
            : 1
          : e.target.value
    };
  }

  return newOldData;
};

export default generateFilterFormData;
