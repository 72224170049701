import axios from 'axios';
import isObject from 'helpers/isObject';
import { showToast } from 'module/Common/Toast/toast';

const handleDraft = (
  dataObjectItems = {
    data: null,
    draftId: null,
    setDraftId: null,
    draftIsSaving: null,
    setDraftIsSaving: null,
    setDraftData: null,
    setShowDraftWindow: null,
    trans_type: null,
    withoutItemsArray: [],
    conversionData: {}
  }
) =>
  new Promise((resolve, reject) => {
    {
      if (
        !dataObjectItems ||
        !isObject(dataObjectItems) ||
        !dataObjectItems.trans_type
      ) {
        console.error('invalid data provided pls check');
        reject({ error: 'invalid data provided pls check' });
      } else {
        let dataObject = {
          data: null,
          draftId: null,
          setDraftId: null,
          draftIsSaving: null,
          setDraftIsSaving: null,
          setDraftData: null,
          setShowDraftWindow: null,
          trans_type: null,
          withoutItemsArray: [],
          conversionData: {},
          ...dataObjectItems
        };

        let keysWithData = [];
        let dataWithoutNull = {};
        dataObject.data &&
          Object.keys(dataObject.data).map(key => {
            if (!dataObject.withoutItemsArray.includes(key)) {
              if (
                key === 'tax_included' &&
                [0, 1].includes(dataObject.data[key])
              ) {
                keysWithData.push(key);
                dataWithoutNull[key] = dataObject.data[key];
              } else if (
                dataObject.data[key] &&
                dataObject.data[key].length > 0
              ) {
                keysWithData.push(key);
                dataWithoutNull[key] = dataObject.data[key];
              } else if (
                dataObject.data[key] &&
                typeof dataObject.data[key] === 'object' &&
                Object.keys(dataObject.data[key]).length > 0
              ) {
                keysWithData.push(key);
                dataWithoutNull[key] = dataObject.data[key];
              }
            }
          });

        if (!dataObject.data || dataObject.draftId || keysWithData.length > 1) {
          axios({
            method: !dataObject.data
              ? 'get'
              : dataObject.draftId && keysWithData.length < 2
              ? 'delete'
              : 'post',
            url: `draft/${
              !dataObject.data
                ? 'find'
                : dataObject.draftId && keysWithData.length < 2
                ? `discard/${dataObject.draftId}`
                : 'save'
            }`,
            data: dataObject.data && {
              ...dataWithoutNull,
              trans_type: dataObject.trans_type,
              draft_id: dataObject.draftId,
              ...dataObject.conversionData
            },
            params: !dataObject.data && {
              trans_type: dataObject.trans_type,
              ...dataObject.conversionData
            }
          })
            .then(response => {
              if (response.data.success === true && response.data.data) {
                let draftId =
                  !dataObject.data && response.data.data.id
                    ? response.data.data.id
                    : response.data.data.draft_id
                    ? response.data.data.draft_id
                    : null;
                response.draftId = draftId;
                if (
                  dataObject.setDraftId &&
                  typeof dataObject.setDraftId === 'function'
                ) {
                  dataObject.setDraftId(draftId);
                }
                if (!dataObject.data && !Array.isArray(response.data.data)) {
                  if (
                    dataObject.setDraftData &&
                    typeof dataObject.setDraftData === 'function'
                  ) {
                    dataObject.setDraftData(response.data);
                  }

                  if (
                    dataObject.setShowDraftWindow &&
                    typeof dataObject.setShowDraftWindow === 'function'
                  ) {
                    dataObject.setShowDraftWindow(true);
                  }
                }
              } else {
                showToast(
                  `something went wrong while ${
                    !dataObject.data ? 'getting' : 'saving'
                  } draft data, please refresh the page and try again.`,
                  'error'
                );
              }
              resolve(response);
            })
            .catch(error => {
              console.log(error);
              showToast(
                `something went wrong while ${
                  !dataObject.data ? 'getting' : 'saving'
                } draft data, please refresh the page and try again.`,
                'error'
              );

              resolve(error);
            });
        } else {
          resolve(null);
        }
      }
    }
  });

export default handleDraft;
