import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default function ShowDimensionsResult({ entry }) {
  return ['dim_length', 'dim_width', 'dim_height'].map((keyName, index) => (
    <Fragment key={keyName}>
      {index > 0 ? <b> / </b> : null}
      {entry?.[keyName]
        ? `${entry?.[keyName]} ${entry?.dimension_unit || ''}`
        : '-'}
    </Fragment>
  ));
}

ShowDimensionsResult.propTypes = {
  entry: PropTypes.object
};
