import { React } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
function CustomerRef({
  formData,
  formError,
  handleFieldChange,
  handleInputBoxOutofFocus,
  handleInputBoxFocus,
  enableDraft,
  required,
  readOnly
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group as={Col} xxl={4} xl={6} md={6} sm={12} className="mb-2">
      <Row>
        <Col xl={4} md={4} sm={12} className="pe-0">
          <Form.Label
            className={
              required
                ? 'require-data m-0 d-flex align-items-center justify-content-start h-100'
                : 'm-0 d-flex align-items-center justify-content-start h-100'
            }
          >
            {Translate('Enquiry No')}
          </Form.Label>
        </Col>
        <Col xl={8} md={8} sm={12} className="ps-0">
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
              {readOnly ? (
                <Form.Control
                  type="text"
                  value={formData.customer_reference}
                  readOnly
                />
              ) : (
                <Form.Control
                  type="text"
                  name="customer_reference"
                  className="form-tab-switch-control"
                  onChange={e => handleFieldChange(e)}
                  value={formData.customer_reference}
                  onBlur={() => {
                    enableDraft ? handleInputBoxOutofFocus() : '';
                  }}
                  onFocus={() => {
                    enableDraft ? handleInputBoxFocus() : '';
                  }}
                />
              )}
            </Col>
            <Col
              xl={1}
              md={1}
              sm={1}
              className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
            >
              {formError.customer_reference ? (
                <FormErrorPopover
                  id="customer_reference"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError.customer_reference}
                />
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

CustomerRef.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func,
  handleInputBoxFocus: PropTypes.func,
  handleInputBoxOutofFocus: PropTypes.func,
  enableDraft: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default CustomerRef;
