import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ConvertModelInfo from 'module/Common/Info/ConvertModelInfo';

const ReferencePreview = ({ reference, type, url, interBranch }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);
  return (
    <>
      <Button
        variant="danger"
        className="ms-2 px-1 rounded"
        size="sm"
        style={{ fontSize: '10px' }}
        onClick={handleShow}
      >
        Reference : {reference}
      </Button>

      {show ? (
        <ConvertModelInfo
          show={show}
          onHide={handleShow}
          type={type}
          url={url}
          interBranch={interBranch}
        />
      ) : null}
    </>
  );
};

ReferencePreview.propTypes = {
  reference: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['PR', 'PO', 'PD', 'PI']).isRequired,
  url: PropTypes.string.isRequired,
  interBranch: PropTypes.bool
};

export default ReferencePreview;
