import axios from 'axios';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import PopupFormButton from 'components/popup-form-button/PopupFormButton';
import { paymentTermsPopupFormKeys } from 'helpers/formKeys';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { MdOutlinePayments } from 'react-icons/md';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const PaymentTermPopupForm = ({
  callBack,
  setData,
  labelName,
  keyName,
  name,
  showAddButton,
  onHide,
  show
}) => {
  const Translate = useAxisproTranslate();
  const [showWindow, setShowWindow] = useState(show ?? false);
  const [formData, setFormData] = useState(paymentTermsPopupFormKeys);
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const handleShow = () => {
    setShowWindow(!showWindow);
    if (showWindow) {
      setFormData(paymentTermsPopupFormKeys);
      setFormError({});
      setIsSaving(false);
      if (onHide && typeof onHide === 'function') onHide();
    }
  };
  const handleFieldChange = (e, action) =>
    setFormData(generateFormDataHelper(e, action, formData));
  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setIsSaving(true);

    axios({
      method: 'post',
      url: 'settings/payment-terms',
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');

          if (callBack) {
            callBack(response.data.data);
          }

          if (response.data.data && setData) {
            setData(
              {
                label: response.data.data[labelName ?? 'terms'],
                value: response.data.data[keyName ?? 'id']
              },
              {
                action: 'select-option',
                option: undefined,
                name: name ?? 'payment_term_id'
              }
            );
          }

          handleShow();
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setIsSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setIsSaving(false);
      });
  };
  useEffect(() => {
    setShowWindow(show ?? false);
    return () => {
      setFormData(paymentTermsPopupFormKeys);
      setFormError({});
      setShowWindow(false);
      setIsSaving(false);
    };
  }, [show]);

  return (
    <>
      {showAddButton ? (
        <PopupFormButton onClick={handleShow}>
          {Translate('Add Payment Term')}
        </PopupFormButton>
      ) : null}
      {showWindow ? (
        <Modal
          show={showWindow}
          onHide={handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="d-flex flex-row align-items-center">
                <MdOutlinePayments size={20} className="text-success" />
                <span className="ms-1">{Translate('ADD PAYMENT TERM')}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(formError).length > 0 && (
                <ErrorAlert formError={formError} setFormError={setFormError} />
              )}
              <Form.Group className="mb-3" controlId="paymentTermsIndicator">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Terms Indicator')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Control
                      type="number"
                      name="terms_indicator"
                      onChange={handleFieldChange}
                      value={formData.terms_indicator}
                      min={0}
                    />
                    <FormErrorPopover
                      id="terms_indicator"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.terms_indicator}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="paymentTermsTerms">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Terms')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Control
                      type="text"
                      name="terms"
                      onChange={handleFieldChange}
                      value={formData.terms}
                    />
                    <FormErrorPopover
                      id="terms"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.terms}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="paymentTermsDaysBeforeDue"
              >
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Days Before Due')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Control
                      type="number"
                      name="days_before_due"
                      onChange={handleFieldChange}
                      value={formData.days_before_due}
                      min={0}
                    />
                    <FormErrorPopover
                      id="days_before_due"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.days_before_due}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="paymentTermsDayInFollowingMonth"
              >
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Day in Following Month')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Control
                      type="number"
                      name="day_in_following_month"
                      onChange={handleFieldChange}
                      value={formData.day_in_following_month}
                      min={0}
                    />
                    <FormErrorPopover
                      id="day_in_following_month"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.day_in_following_month}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-row justify-content-start">
              <Button
                variant="outline-success"
                size="sm"
                className="d-flex align-items-center justify-content-center"
                onClick={handleSubmit}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="success"
                      className="me-1"
                    />
                    <span className="fs--1">{Translate('Saving...')}</span>
                  </>
                ) : (
                  <span>{Translate('Add')}</span>
                )}
              </Button>
              <Button
                variant="outline-danger"
                onClick={handleShow}
                size="sm"
                disabled={isSaving}
              >
                {Translate('Cancel')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

PaymentTermPopupForm.propTypes = {
  callBack: PropTypes.func,
  setData: PropTypes.func,
  name: PropTypes.string,
  labelName: PropTypes.string,
  keyName: PropTypes.string,
  show: PropTypes.bool,
  showAddButton: PropTypes.bool,
  onHide: PropTypes.func
};

export default PaymentTermPopupForm;
