import { React } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

function SelectBranch({
  handleFieldChange,
  name,
  value,
  keyName,
  companyId,
  withAllOption,
  navbar,
  error,
  onFocus
}) {
  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: 'settings/company/branches/autocomplete',
      setParams: searchKey => ({
        name: searchKey,
        company_id: companyId ?? ''
      }),
      setOptions: option => ({
        label: option.branch_name,
        value: keyName ? option[keyName] : option.id
      }),
      onFocus: onFocus,
      staticOptions: withAllOption
        ? [{ branch_name: 'All', value: 'all' }]
        : undefined,
      ...(companyId && { dependencyKeyToResetDefaultOptions: companyId })
    });
  return (
    <>
      <AsyncSelect
        key={JSON.stringify(companyId)}
        cacheOptions
        loadOptions={fetchData}
        defaultOptions={defaultOptions}
        onFocus={setDefaultResult}
        isLoading={loading}
        isClearable={navbar ? false : true}
        isSelected={true}
        name={name ? name : 'branch'}
        value={value ?? ''}
        onChange={handleFieldChange}
        classNamePrefix="app-react-select"
        className={`${error && 'is-invalid'}`}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({
            ...provided,
            zIndex: 9999,
            fontFamily: 'sans-serif',
            fontSize: '12px',
            borderRadius: '0px',
            textTransform: 'capitalize',
            overflow: 'hidden'
          }),
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: '0px',
            border: '1px solid #b5b5b5',
            height: '10px'
          })
        }}
        menuPortalTarget={document.body}
      ></AsyncSelect>
    </>
  );
}

SelectBranch.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.any,
  value: PropTypes.any,
  companyId: PropTypes.any,
  keyName: PropTypes.string,
  withAllOption: PropTypes.bool,
  navbar: PropTypes.bool,
  error: PropTypes.any,
  onFocus: PropTypes.func
};

export default SelectBranch;
