export const _validateEmail = email =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
export const _validatePassword = (password, username, policy) => {
  const errors = [];
  // Password length
  if (policy?.password_policy_password_length) {
    const isValidLength =
      password?.length >= parseInt(policy.password_policy_password_length);
    errors.push({
      message: `Password must be at least ${policy.password_policy_password_length} characters long.`,
      error: !isValidLength
    });
  }

  // Uppercase letters
  if (parseInt(policy?.password_policy_uppercases_count) > 0) {
    const upperCaseRegex = new RegExp(
      `(?=(?:.*[A-Z]){${policy.password_policy_uppercases_count}})`
    );
    const hasUpperCase = upperCaseRegex.test(password);
    errors.push({
      message: `Password must contain at least ${policy.password_policy_uppercases_count} uppercase letter(s).`,
      error: !hasUpperCase
    });
  }

  // Lowercase letters
  if (parseInt(policy?.password_policy_lowercases_count) > 0) {
    const lowerCaseRegex = new RegExp(
      `(?=(?:.*[a-z]){${policy.password_policy_lowercases_count}})`
    );
    const hasLowerCase = lowerCaseRegex.test(password);
    errors.push({
      message: `Password must contain at least ${policy.password_policy_lowercases_count} lowercase letter(s).`,
      error: !hasLowerCase
    });
  }

  // Special characters
  if (parseInt(policy?.password_policy_special_characters_count) > 0) {
    const specialCharRegex = new RegExp(
      `(?=(?:.*[!@#$&*]){${policy.password_policy_special_characters_count}})`
    );
    const hasSpecialChar = specialCharRegex.test(password);
    errors.push({
      message: `Password must contain at least ${policy.password_policy_special_characters_count} special character(s).`,
      error: !hasSpecialChar
    });
  }

  // Digits
  if (parseInt(policy?.password_policy_digits_count) > 0) {
    const digitRegex = new RegExp(
      `(?=(?:.*\\d){${policy.password_policy_digits_count}})`
    );
    const hasDigits = digitRegex.test(password);
    errors.push({
      message: `Password must contain at least ${policy.password_policy_digits_count} numeral(s).`,
      error: !hasDigits
    });
  }

  // Username inclusion
  if (
    password &&
    policy?.password_policy_prevent_including_username_on_password === '1'
  ) {
    const isSameAsUsername = password === username;
    errors.push({
      message: 'Password cannot be the same as username.',
      error: isSameAsUsername
    });
  }

  return errors;
};

export const _validateConfirmPassword = (password, c_password) =>
  password === c_password;
