import axios from 'axios';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaShip } from 'react-icons/fa';
import { SaveButton } from 'module/Common/Buttons/CommonButton';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import PhoneNumberInput from 'components/form/PhoneNumberInput';

const ShippingAddressPopupForm = ({
  customerData,
  onHide,
  show,
  fetchData,
  editItem
}) => {
  const Translate = useAxisproTranslate();
  const [showWindow, setShowWindow] = useState(show ?? false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    address1: '',
    address2: '',
    address_type: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    phone_number: ''
  });
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const permission = {
    create: getAcodaxPermissionSlug('CSA', 'create'),
    update: getAcodaxPermissionSlug('CSA', 'update')
  };

  const handleShow = () => {
    setShowWindow(!showWindow);
    if (show) {
      setFormData({});
      setFormError({});
      setIsSaving(false);
      if (onHide && typeof onHide === 'function') onHide();
    }
  };

  const handleFieldChange = (e, action) =>
    setFormData(generateFormDataHelper(e, action, formData));

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setIsSaving(true);

    axios({
      method: editItem ? 'put' : 'post',
      url:
        `sales/customer/${customerData?.id}/shipping-addresses` +
        (editItem ? `/${editItem.id}` : '/'),
      params: formData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          fetchData();
          handleShow();
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
      })
      .catch(error => {
        showToast(
          error?.response?.data?.message ??
            error?.message ??
            'Something went wrong, please refresh the page and try again.',
          'error'
        );
        setFormError({ ...(error?.response?.data?.data?.errors ?? null) });
      })
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    if (show && customerData) {
      setFormData(prev => ({
        ...prev,
        first_name: customerData?.name,
        phone_number: customerData?.contacts[0]?.contact_mobile,
        address_type: 'home'
      }));
    }
  }, [show, customerData]);

  useEffect(() => {
    if (editItem && show) {
      setFormData(prev => ({
        ...prev,
        first_name: editItem?.first_name,
        last_name: editItem?.last_name,
        address1: editItem?.address1,
        address2: editItem?.address2,
        address_type: editItem?.address_type,
        city: editItem?.city,
        state: editItem?.state,
        country: editItem?.country,
        zip_code: editItem?.zip_code,
        phone_number: editItem?.phone_number
      }));
    }
  }, [editItem, show]);

  useEffect(() => {
    setShowWindow(show ?? false);
    return () => {
      setFormData({});
      setFormError({});
      setShowWindow(false);
      setIsSaving(false);
    };
  }, [show]);

  return (
    <>
      {showWindow ? (
        <Modal
          show={showWindow}
          onHide={handleShow}
          backdrop="static"
          size="lg"
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="d-flex align-items-center">
                <FaShip size={22} className="me-1 text-dark" />
                <span className="ms-1 text-dark" style={{ fontSize: 16 }}>
                  {Translate('ADD SHIPPING ADDRESS')}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(formError).length > 0 && (
                <ErrorAlert formError={formError} setFormError={setFormError} />
              )}
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-2" controlId="form.first_name">
                    <Form.Label column sm={12} className="require-data p-0">
                      {Translate('First Name')}
                    </Form.Label>
                    <Col sm={12} className="d-flex flex-row align-items-center">
                      <Form.Control
                        type="text"
                        name="first_name"
                        value={formData?.first_name}
                        onChange={handleFieldChange}
                      />
                      <FormErrorPopover
                        id="first_name"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.first_name}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="form.phone_number">
                    <Form.Label column sm={12} className="p-0">
                      {Translate('Phone Number')}
                    </Form.Label>
                    <Col sm={12} className="d-flex flex-row align-items-center">
                      <PhoneNumberInput
                        name="phone_number"
                        onChange={handleFieldChange}
                        value={formData?.phone_number}
                      />
                      <FormErrorPopover
                        id="phone_number"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.phone_number}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="form.address1">
                    <Form.Label column sm={12} className="require-data p-0">
                      {Translate('Address 1')}
                    </Form.Label>
                    <Col sm={12} className="d-flex flex-row align-items-center">
                      <Form.Control
                        type="text"
                        name="address1"
                        value={formData?.address1}
                        onChange={handleFieldChange}
                      />
                      <FormErrorPopover
                        id="address1"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.address1}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="form.country">
                    <Form.Label column sm={12} className="p-0">
                      {Translate('Country')}
                    </Form.Label>
                    <Col sm={12} className="d-flex flex-row align-items-center">
                      <Form.Control
                        type="text"
                        name="country"
                        onChange={handleFieldChange}
                        value={formData?.country}
                      />
                      <FormErrorPopover
                        id="country"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.country}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="mb-0" controlId="form.city">
                    <Form.Label column sm={12} className="p-0">
                      {Translate('City')}
                    </Form.Label>
                    <Col sm={12} className="d-flex flex-row align-items-center">
                      <Form.Control
                        type="text"
                        name="city"
                        onChange={handleFieldChange}
                        value={formData?.city}
                      />
                      <FormErrorPopover
                        id="city"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.city}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-2" controlId="form.last_name">
                    <Form.Label column sm={12} className="p-0 pt-1">
                      {Translate('Last Name')}
                    </Form.Label>
                    <Col sm={12} className="d-flex flex-row align-items-center">
                      <Form.Control
                        type="text"
                        name="last_name"
                        value={formData?.last_name}
                        onChange={handleFieldChange}
                      />
                      <FormErrorPopover
                        id="last_name"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.last_name}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="form.address_type">
                    <Form.Label column sm={12} className="p-0">
                      {Translate('Address Type')}
                    </Form.Label>
                    <Col sm={12} className="d-flex flex-row align-items-center">
                      <Form.Select
                        type="select"
                        name="address_type"
                        value={formData?.address_type}
                        onChange={handleFieldChange}
                      >
                        <option value="home">{Translate('Home')}</option>
                        <option value="work">{Translate('Work')}</option>
                      </Form.Select>
                      <FormErrorPopover
                        id="address_type"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.address_type}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="form.address2">
                    <Form.Label column sm={12} className="p-0 pt-1">
                      {Translate('Address 2')}
                    </Form.Label>
                    <Col sm={12} className="d-flex flex-row align-items-center">
                      <Form.Control
                        type="text"
                        name="address2"
                        value={formData?.address2}
                        onChange={handleFieldChange}
                      />
                      <FormErrorPopover
                        id="address1"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.address2}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="form.state">
                    <Form.Label column sm={12} className="p-0">
                      {Translate('State')}
                    </Form.Label>
                    <Col sm={12} className="d-flex flex-row align-items-center">
                      <Form.Control
                        type="text"
                        name="state"
                        value={formData?.state}
                        onChange={handleFieldChange}
                      />
                      <FormErrorPopover
                        id="state"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.state}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="mb-0" controlId="form.zip_code">
                    <Form.Label column sm={12} className="p-0">
                      {Translate('Zip Code')}
                    </Form.Label>
                    <Col sm={12} className="d-flex flex-row align-items-center">
                      <Form.Control
                        type="number"
                        name="zip_code"
                        onChange={handleFieldChange}
                        value={formData?.zip_code}
                      />
                      <FormErrorPopover
                        id="zip_code"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.zip_code}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-row justify-content-end">
              <SaveButton
                onSave={isSaving}
                handleSubmit={handleSubmit}
                id={editItem ? true : false}
                createPermission={permission?.create}
                editPermission={permission?.update}
              />
              <Button
                variant="danger"
                onClick={handleShow}
                size="sm"
                disabled={isSaving}
              >
                {Translate('Cancel')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

ShippingAddressPopupForm.propTypes = {
  customerData: PropTypes.any,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  fetchData: PropTypes.func,
  editItem: PropTypes.any
};

export default ShippingAddressPopupForm;
