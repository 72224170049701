import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import PropTypes from 'prop-types';

export default function WorkOrderMaterialRequestsModalMaterialsEntryTableItem({
  data,
  ItemIndex,
  setFormData
}) {
  function handleEdit(event) {
    event.preventDefault();
    setFormData(prev => ({
      ...prev,
      details: Array.isArray(prev?.details)
        ? prev.details.map((entry, index) => {
            delete entry?.edit_ref;
            if (index === ItemIndex) {
              entry.edit_ref = true;
            }
            return entry;
          })
        : prev?.details
    }));
  }

  function removeSelectedItem(dataArray, index) {
    if (Array.isArray(dataArray) && dataArray?.[index]) {
      dataArray.splice(index, 1);
    }
    return dataArray;
  }

  function handleRemove(event) {
    event.preventDefault();
    setFormData(prev => ({
      ...prev,
      details: Array.isArray(prev?.details)
        ? removeSelectedItem(prev.details, ItemIndex)
        : prev?.details
    }));
  }
  return (
    <tr className="result-items" key={ItemIndex}>
      <td>{ItemIndex + 1}</td>
      <td className="p-0">
        <div className="result-item">{data?.stock_id ?? '-'}</div>
      </td>
      <td className="p-0">
        <div className="result-item">
          {data?.item_display_name_ref?.label ?? '-'}
        </div>
      </td>
      <td className="p-0">
        <div className="result-item">{data?.quantity ?? '-'}</div>
      </td>
      <td>
        <div className="action-buttons d-flex align-items-center justify-content-center">
          <OverlayTrigger
            overlay={
              <Tooltip>
                <small>Edit line item details</small>
              </Tooltip>
            }
          >
            <Button
              variant="transparent"
              type="button"
              className="p-0 me-1 d-flex align-items-center justify-content-center text-primary"
              onClick={handleEdit}
            >
              <FaEdit size={11} />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={
              <Tooltip>
                <small>Remove line item</small>
              </Tooltip>
            }
          >
            <Button
              variant="transparent"
              type="button"
              className="p-0 d-flex align-items-center justify-content-center text-danger"
              onClick={handleRemove}
            >
              <FaTrash size={11} />
            </Button>
          </OverlayTrigger>
        </div>
      </td>
    </tr>
  );
}

WorkOrderMaterialRequestsModalMaterialsEntryTableItem.propTypes = {
  data: PropTypes.object,
  ItemIndex: PropTypes.number,
  setFormData: PropTypes.func
};
