import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ConvertingScreenInfoLoading from '../Loading/ConvertingScreenInfoLoading';
import SalesOrderTabView from '../ViewPages/SalesOrder/SalesOrderTabView';
import SalesInvoiceTabView from '../ViewPages/SalesInvoice/SalesInvoiceTabView';
import SalesQuotationTabView from '../ViewPages/SalesQuotation/SalesQuotationTabView';
import SalesDeliveryTabView from '../ViewPages/SalesDelivery/SalesDeliveryTabView';
import PurchaseInvoiceTabView from '../ViewPages/PurchaseInvoice/PurchaseInvoiceTabView';
import PurchaseOrderTabView from '../ViewPages/PurchaseOrder/PurchaseOrderTabView';
import PurchaseDeliveryTabView from 'module/Common/ViewPages/PurchaseDelivery/PurchaseDeliveryTabView';
import PurchaseRequestTabView from 'module/Common/ViewPages/PurchaseRequest/PurchaseRequestTabView';
import ContractTabView from 'module/Common/ViewPages/Contract/ContractTabView';
import WorkOrderTabView from '../ViewPages/WorkOrder/WorkOrderTabView';

function ConvertModelInfo({ show, onHide, url, type, interBranch }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    if (url) {
      await axios
        .get(url)
        .then(res => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [url]);

  return (
    <Modal
      show={show}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      className="modal-with-overlay"
    >
      {loading && data !== '' ? (
        <ConvertingScreenInfoLoading />
      ) : (
        <>
          {type === 'SO' ? (
            <SalesOrderTabView
              salesOrderData={data}
              loading={loading}
              onHide={onHide}
              infoPage={true}
              fetchData={getData}
              offCanvas={true}
              interBranch={interBranch}
            />
          ) : type === 'SI' ? (
            <SalesInvoiceTabView
              salesInvoiceData={data}
              loading={loading}
              onHide={onHide}
              infoPage={true}
              creditFormatted={data.customer.unused_credits_formatted}
              credit={data.customer.unused_credits}
              fetchData={getData}
              offCanvas={true}
              interBranch={interBranch}
            />
          ) : type === 'SQ' ? (
            <SalesQuotationTabView
              salesQuotationData={data}
              loading={loading}
              onHide={onHide}
              infoPage={true}
              fetchData={getData}
              offCanvas={true}
            />
          ) : type === 'SD' ? (
            <SalesDeliveryTabView
              salesDeliveryData={data}
              loading={loading}
              onHide={onHide}
              infoPage={true}
              fetchData={getData}
              offCanvas={true}
              interBranch={interBranch}
            />
          ) : type == 'PI' ? (
            <PurchaseInvoiceTabView
              infoPage={true}
              purchaseInvoiceData={data}
              loading={loading}
              fetchData={getData}
              onHide={onHide}
              offCanvas={true}
              interBranch={interBranch}
            />
          ) : type == 'PO' ? (
            <PurchaseOrderTabView
              purchaseOrderData={data}
              loading={loading}
              fetchData={getData}
              offCanvas={true}
              onHide={onHide}
              interBranch={interBranch}
            />
          ) : type == 'PD' ? (
            <PurchaseDeliveryTabView
              purchaseDeliveryData={data}
              loading={loading}
              fetchData={getData}
              offCanvas={true}
              onHide={onHide}
              interBranch={interBranch}
            />
          ) : type == 'PR' ? (
            <PurchaseRequestTabView
              purchaseRequestData={data}
              loading={loading}
              fetchData={getData}
              offCanvas={true}
              onHide={onHide}
            />
          ) : type == 'CT' ? (
            <ContractTabView
              contractData={data}
              loading={loading}
              fetchData={getData}
              offCanvas={true}
              onHide={onHide}
            />
          ) : type === 'WO' ? (
            <WorkOrderTabView
              workOrderVoucherData={data}
              loading={loading}
              fetchData={getData}
              onHide={onHide}
              offCanvas
            />
          ) : (
            ''
          )}
        </>
      )}
    </Modal>
  );
}

ConvertModelInfo.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  url: PropTypes.string,
  type: PropTypes.string,
  interBranch: PropTypes.bool
};

export default ConvertModelInfo;
