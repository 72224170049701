import { DeleteContext } from 'context/Context';
import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaTimesCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

export default function DownloadCenterClearAllResultsButton({
  exportArrayDispatch,
  setDonwloadApiItems,
  setDonwloadItems
}) {
  const { dispatch: delteContextDispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleClear = event => {
    event.preventDefault();
    // return
    delteContextDispatch({
      type: 'CONFIG',
      payload: {
        url: '/user/delete-all-export-logs',
        buttonText: 'Clear',
        title: 'Clear Export Items',
        message: 'Are you sure you want to clear all items?',
        onDelete: () => {
          setIsLoading(true);
        },
        onSuccess: () => {
          exportArrayDispatch([]);
          setDonwloadApiItems([]);
          setDonwloadItems([]);
        },
        onError: () => {
          setIsLoading(false);
        }
      }
    });
  };
  return (
    <Button
      variant="danger"
      size="sm"
      onClick={handleClear}
      className="d-flex flex-row align-items-center justify-content-center"
      disabled={isLoading}
    >
      {isLoading ? (
        <>
          <Spinner
            animation="border"
            role="status"
            className="me-1"
            style={{
              width: '13px',
              height: '13px'
            }}
          />
          <span>Clearing all results</span>
        </>
      ) : (
        <>
          <FaTimesCircle className="me-1" /> <span>Clear all result</span>
        </>
      )}
    </Button>
  );
}

DownloadCenterClearAllResultsButton.propTypes = {
  exportArrayDispatch: PropTypes.func,
  setDonwloadApiItems: PropTypes.func,
  setDonwloadItems: PropTypes.func
};
