import PropTypes from 'prop-types';

export default function ShowInput({ show, children }) {
  return show ? children : null;
}

ShowInput.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.any
};
