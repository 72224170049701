import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Offcanvas, Tab, Tabs } from 'react-bootstrap';
import { FaQuestionCircle, FaTimes } from 'react-icons/fa';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import LoadingItem from 'components/loading-screen/LoadingItem';
import axios from 'axios';
import classNames from 'classnames';
import { EditButton, MoreButton } from 'module/Common/Buttons/ViewPageButtons';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import SalesmanOffcanvasDetails from 'components/salesman-offcanvas-details/SalesmanOffcanvasDetails';
import EnquiryOverview from 'components/EnquiryInfoOffcanvas/EnquiryOverview';
import AttachementsView from 'module/Common/ViewPages/Common/AttachementsView';
import FileUpload from 'module/Common/ViewPages/Common/FileUpload';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import SendEmail from 'module/Common/ViewPages/Common/SendEmail';

export default function EnquiryInfoOffcanvas({
  show,
  onHide,
  targetId,
  offCanvas,
  permission
}) {
  const Translate = useAxisproTranslate();
  const [enquiryDetails, setenquiryDetails] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [showDetailsArea, setshowDetailsArea] = useState(undefined);
  const [activeTabKey, setActiveTabKey] = useState('overview');
  const [activeOption, setActiveOption] = useState(undefined);
  const loadingElementCount = Array.from(Array(100).keys());

  if (!permission) {
    permission = {
      update: getAcodaxPermissionSlug('ENQUIRY', 'update'),
      sendMail: getAcodaxPermissionSlug('ENQUIRY', 'send-mail'),
      createEstimation: getAcodaxPermissionSlug('ESTIMATION', 'store'),
      createSalesQuotation: getAcodaxPermissionSlug('SQ', 'create'),
      showCustomerDetails: getAcodaxPermissionSlug('CS', 'show'),
      showSalesmandDetails: getAcodaxPermissionSlug('SM', 'show')
    };
  }

  async function fetchDetails() {
    try {
      setIsLoading(true);
      const apiResponse = await axios.get('crm/enquiries/' + targetId);
      const enquiryDetails = apiResponse?.data?.data;
      if (enquiryDetails?.id) {
        setenquiryDetails(enquiryDetails);
        return;
      }

      setErrorMessage(
        Translate('Something went wrong, please refresh and try again!')
      );
    } catch (error) {
      setErrorMessage(
        Translate(
          error?.response?.data?.message ??
            'Something went wrong, please refresh and try again!'
        )
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (targetId && show) {
      fetchDetails();
    }

    return () => {
      setenquiryDetails(undefined);
    };
  }, [targetId, show]);

  return (
    <>
      <Offcanvas show={show} placement="end" onHide={onHide}>
        <Offcanvas.Header className="border-bottom">
          <Offcanvas.Title className="d-flex flex-row gap-2 fw-bold">
            <FaQuestionCircle />
            <span>{Translate('Enquiry Details')}</span>
          </Offcanvas.Title>
          <div className="d-flex flex-row gap-2 ms-auto">
            <MoreButton
              setUpload={() => setActiveOption('fileUpload')}
              firstConversionValidation={!!permission?.createEstimation}
              disableFirstConversion={enquiryDetails?.is_fully_estimated}
              firstConversionTo={`/production/estimation/add?enquiry_id=${enquiryDetails?.id}`}
              beforeFirstConversion={Translate('Convert to Estimation')}
              afterFirstConversion={Translate('Fully Estimated!')}
              secondConversionValidation={!!permission?.createSalesQuotation}
              disableSecondConversion={enquiryDetails?.is_fully_quoted}
              secondConversionTo={`/sales/sales-quotation/add?enquiry_id=${enquiryDetails?.id}`}
              beforeSecondConversion={Translate('Convert to Sales Quotation')}
              afterSecondConversion={Translate('Fully Quoted!')}
              sendEmail={() => setActiveOption('sendMail')}
              emailPermission={permission?.sendMail}
            />
            {permission?.update ? (
              <EditButton to={`/sales/enquiry/edit/${enquiryDetails?.id}`} />
            ) : null}
            <Button
              className="border"
              variant="transparent"
              size="sm"
              onClick={onHide}
            >
              <FaTimes />
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body
          className={classNames({
            'p-0': !isLoading
          })}
        >
          {isLoading ? (
            loadingElementCount.map(key => <LoadingItem key={key} />)
          ) : enquiryDetails?.id ? (
            <Tabs
              activeKey={activeTabKey}
              onSelect={key => setActiveTabKey(key)}
              id="enquiry-info-offcanavas-tab"
              className="bg-white"
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1
              }}
            >
              <Tab eventKey="overview" title={Translate('Overview')}>
                <EnquiryOverview
                  enquiryDetails={enquiryDetails}
                  setshowDetailsArea={setshowDetailsArea}
                  Offcanvas={offCanvas}
                  permission={permission}
                />
              </Tab>

              {enquiryDetails?.files?.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <AttachementsView
                    data={{
                      ...enquiryDetails,
                      media: enquiryDetails?.files
                    }}
                    fetchData={fetchDetails}
                    setKey={setActiveTabKey}
                  />
                </Tab>
              ) : null}
            </Tabs>
          ) : errorMessage ? (
            <Alert variant="danger" className="m-3">
              {errorMessage}
            </Alert>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>

      {showDetailsArea === 'CUSTOMER' ? (
        <CustomerDetails
          show={true}
          onHide={() => setshowDetailsArea(undefined)}
          value={{
            value: enquiryDetails?.customer_id
          }}
          showCustomerDetailsArea={true}
        />
      ) : showDetailsArea === 'SALESMAN' ? (
        <SalesmanOffcanvasDetails
          show
          onHide={() => setshowDetailsArea(undefined)}
          salesmanId={enquiryDetails?.salesman_id}
        />
      ) : null}

      {activeOption === 'fileUpload' ? (
        <FileUpload
          show
          onHide={() => setActiveOption(undefined)}
          fetchData={fetchDetails}
          type={'EQ'}
          id={enquiryDetails?.id}
          setKey={setActiveTabKey}
        />
      ) : activeOption === 'sendMail' ? (
        <SendEmail
          url={'crm/enquiries-mail/' + enquiryDetails?.id}
          showEmailModal={() => setActiveOption('sendMail')}
          handleCancel={() => setActiveOption(undefined)}
          data={enquiryDetails?.email}
        />
      ) : null}
    </>
  );
}

EnquiryInfoOffcanvas.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  targetId: PropTypes.string,
  offCanvas: PropTypes.bool,
  permission: PropTypes.object
};
