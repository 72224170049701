export const useRemoveParams = (allQueryParams, removeItems) => {
  if (Object.keys(allQueryParams).length > 0) {
    let urlParams = Object.keys(allQueryParams).reduce((newResult, key) => {
      if (!(removeItems ?? []).includes(key)) {
        newResult[key] = allQueryParams[key];
      }
      return newResult;
    }, {});
    return urlParams;
  } else {
    return '';
  }
};
export default useRemoveParams;
