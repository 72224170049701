import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import InventoryMomentHistoryTable from 'components/purchase-history-modal/InventoryMomentHistoryTable';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import DataFetching from 'module/Report/LedgerReport/DataFetching';
import { FaListAlt } from 'react-icons/fa';

export default function InventoryMomentHistoryModal({ id, stockId, sales }) {
  const Translate = useAxisproTranslate();
  const [fechingResult, setFechingResult] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [historyData, setHistoryData] = useState(undefined);

  const handleShowModal = event => {
    event?.preventDefault();
    setShowModal(!showModal);
  };

  const fetchData = async page => {
    try {
      !page || (page < 2 && setFechingResult(true));
      const apiResult = await axios.get(
        sales
          ? 'inventory/customer-wise-stock-movements'
          : `inventory/supplier-wise-stock-movements`,
        {
          params: {
            page: page || 1,
            stock_id: stockId,
            ...(sales
              ? {
                  customer_id: id
                }
              : {
                  supplier_id: id
                })
          }
        }
      );
      if (apiResult?.data?.data) {
        if (page > 1) {
          setHistoryData(prev => ({
            ...apiResult.data.data,
            data: [
              ...(prev?.data || null),
              ...(apiResult.data.data?.data || null)
            ]
          }));
          return;
        }
        setHistoryData(apiResult.data.data);
      }
    } catch (error) {
      console.error(error);
      showToast(
        Translate('Something went wrong, please refresh and try again')
      );
    } finally {
      setFechingResult(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      setHistoryData(undefined);
      fetchData();
    }

    return () => {
      setFechingResult(true);
      setHistoryData(undefined);
    };
  }, [showModal, id, stockId]);

  return (
    <>
      <a
        href="#"
        role="button"
        className="d-flex justify-content-end p-0"
        onClick={handleShowModal}
      >
        <small className="p-0">
          {sales
            ? Translate('Show Sales History')
            : Translate('Show Purchase History')}
        </small>
      </a>

      <Modal show={showModal} onHide={handleShowModal} size="lg">
        <Modal.Header
          className="d-flex flex-row gap-2"
          style={{
            fontSize: '1.1rem'
          }}
          closeButton
        >
          <FaListAlt className="text-info" />
          <Modal.Title className="text-dark" as={'p'}>
            {sales ? Translate('Sales History') : Translate('Purchase History')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fechingResult ? (
            <LoadingScreen message="Looking for result" />
          ) : historyData?.meta?.total > 0 ? (
            <>
              <InventoryMomentHistoryTable data={historyData} />
              {historyData?.meta?.last_page > 0 &&
              historyData?.meta?.current_page !== historyData.meta.last_page ? (
                <DataFetching
                  nextLink={historyData.meta.current_page + 1}
                  loadNextPage={() =>
                    fetchData(historyData.meta.current_page + 1)
                  }
                />
              ) : historyData?.meta?.last_page > 0 &&
                historyData?.meta?.last_page ===
                  historyData?.meta?.current_page ? (
                <p className="text-center">{Translate('End Reached')}</p>
              ) : null}
            </>
          ) : (
            <Alert>{Translate('No result found!')}</Alert>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

InventoryMomentHistoryModal.propTypes = {
  id: PropTypes.string,
  stockId: PropTypes.string,
  sales: PropTypes.bool
};
