import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import SelectCustomer from 'components/form/SelectCustomer';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import { useEffect } from 'react';
import generateFormDataHelper from 'helpers/generateFormDataHelper';

export default function ChatRequiredParamsList({
  requiredParams,
  onSubmit,
  details
}) {
  const [formData, setFormData] = useState({});

  function handleFieldChange(e, action) {
    let newFormData = generateFormDataHelper(e, action, formData);
    setFormData(newFormData);
  }

  function handleSubmitForm(event) {
    event.preventDefault();
    if (typeof onSubmit === 'function') {
      if (
        requiredParams.filter(
          key => !formData[key] || formData[key]?.length < 1
        ).length < 1
      ) {
        onSubmit(formData);
      }
    }
  }

  useEffect(() => {
    if (Array.isArray(requiredParams)) {
      requiredParams.forEach(key => {
        if (typeof key === 'string') {
          setFormData(prev => ({
            ...prev,
            [key]: undefined
          }));
        }
      });
    }
    return () => {
      setFormData({});
    };
  }, [requiredParams]);

  return Array.isArray(requiredParams) && requiredParams.length > 0 ? (
    <>
      <p className="small-message">
        AcoBot needs this information to help you.
      </p>
      <div className="chat-initial-helpers">
        <div className="helper-item text-capitalize">
          <Form onSubmit={handleSubmitForm}>
            {requiredParams.includes('customer_id') ? (
              <Form.Group className="form-item-group">
                <p>Customer</p>
                {details?.submitted ? (
                  <p className="form-data">
                    {details?.params?.customer_id_ref?.label}
                  </p>
                ) : (
                  <p className="mt-2 form-input">
                    <SelectCustomer
                      value={formData.customer_id_ref}
                      name="customer_id"
                      handleFieldChange={handleFieldChange}
                      placeholder="Search..."
                      style={{ borderRadius: 'none' }}
                      classNamePrefix="chatbot-input-item"
                      withAddButton
                    />
                  </p>
                )}
              </Form.Group>
            ) : null}
            {requiredParams.includes('from_date') ? (
              <Form.Group className="form-item-group">
                <p>From Date</p>
                {details?.submitted ? (
                  <p className="form-data">{details?.params?.from_date}</p>
                ) : (
                  <p className="mt-2 form-input">
                    <AppDatePicker
                      name="from_date"
                      value={formData.from_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                    />
                  </p>
                )}
              </Form.Group>
            ) : null}
            {requiredParams.includes('to_date') ? (
              <Form.Group className="form-item-group">
                <p>To Date</p>
                {details?.submitted ? (
                  <p className="form-data">{details?.params?.to_date}</p>
                ) : (
                  <p className="mt-2 form-input">
                    <AppDatePicker
                      name="to_date"
                      value={formData.to_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                    />
                  </p>
                )}
              </Form.Group>
            ) : null}
            {!details?.submitted && (
              <Form.Group>
                <Button
                  size="sm"
                  variant="success"
                  className="w-100"
                  type="submit"
                >
                  Submit
                </Button>
              </Form.Group>
            )}
          </Form>
        </div>
      </div>
    </>
  ) : null;
}

ChatRequiredParamsList.propTypes = {
  requiredParams: PropTypes.array,
  onSubmit: PropTypes.func,
  details: PropTypes.object
};
