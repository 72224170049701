import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SelectTaxGroup from 'components/form/SelectTaxGroup';

const TaxSetupSection = ({ formData, errors, onChange }) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="trnNo">
        <Form.Label>TRN No.</Form.Label>
        <Form.Control
          placeholder="Enter TRN Number"
          name="trn_no"
          onChange={onChange}
          value={formData.trn_no}
          isValid={formData.trn_no.length > 0 && !errors.trn_no}
          isInvalid={!!errors.trn_no}
        />
        {errors.trn_no ? (
          <Form.Control.Feedback type="invalid">
            {errors.trn_no}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3" controlId="taxGroup">
        <Form.Label>Tax Group</Form.Label>
        <SelectTaxGroup
          name="tax_group"
          placeholder="Choose tax group"
          value={formData.tax_group_ref}
          style={{ borderRadius: 'none' }}
          handleFieldChange={onChange}
          error={!!errors.tax_group}
        />
        {errors.tax_group ? (
          <Form.Control.Feedback type="invalid">
            {errors.tax_group}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
    </>
  );
};

TaxSetupSection.propTypes = {
  formData: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func
};

export default TaxSetupSection;
