import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

export default function SuspensePageLoaderUI() {
  return (
    <Suspense
      fallback={
        <div className="bg-light flex-fill d-flex align-items-center justify-content-center">
          <LoadingScreen />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  );
}
