import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { BsExclamationCircleFill } from 'react-icons/bs';
import PropTypes from 'prop-types';

const FormErrorPopover = ({
  id,
  placement = 'bottom',
  errorMessage,
  totalErrorCount,
  addSpace = false
}) => {
  return errorMessage ? (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Popover id={id} className="rounded-0" style={{ zIndex: '500004' }}>
          <Popover.Body className="text-danger small">
            {errorMessage}
          </Popover.Body>
        </Popover>
      }
    >
      <Button
        variant="transparent"
        className="p-0 text-center"
        style={{ width: '20px', boxShadow: 'none' }}
      >
        <BsExclamationCircleFill size={13} className="text-danger" />
      </Button>
    </OverlayTrigger>
  ) : totalErrorCount > 0 || addSpace ? (
    <div style={{ width: '20px' }}></div>
  ) : null;
};

FormErrorPopover.propTypes = {
  id: PropTypes.string.isRequired,
  totalErrorCount: PropTypes.number.isRequired,
  errorMessage: PropTypes.any,
  placement: PropTypes.string,
  addSpace: PropTypes.bool
};

export default FormErrorPopover;
