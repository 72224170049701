import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function StockIdInputField({
  formData,
  formError,
  handleFieldChange,
  required
}) {
  const Translate = useAxisproTranslate();

  return (
    <Row className="mb-3">
      <Col
        xl={4}
        md={4}
        sm={12}
        className="d-flex align-items-center justify-content-start"
      >
        <Form.Label className={required ? 'require-data m-0' : 'm-0'}>
          {Translate('Stock ID')}
        </Form.Label>
      </Col>
      <Col xl={8} md={8} sm={12}>
        <Row>
          <Col xl={11} md={11} sm={11}>
            <Form.Control
              type="text"
              name="stock_id"
              onChange={handleFieldChange}
              value={formData.stock_id}
              isInvalid={!!formError?.stock_id}
            />
          </Col>
          <Col
            xl={1}
            md={1}
            sm={1}
            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
          >
            {formError.stock_id ? (
              <FormErrorPopover
                id="stock_id"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={formError.stock_id}
              />
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

StockIdInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func,
  required: PropTypes.bool
};

export default StockIdInputField;
