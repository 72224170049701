import { React } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaFilter } from 'react-icons/fa';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Filter from './Filter';

const FilterModal = ({
  show,
  handleModal,
  moduleName,
  enableDropDown,
  url,
  dropDownData
}) => {
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const layout = queryParams.get('layout');
  const navigate = useNavigate();
  const resetFilters = () => {
    handleModal();
    navigate({
      pathname: '',
      search: layout ? `layout=column` : ''
    });
  };
  return (
    <>
      <Modal show={show} onHide={handleModal} size="lg">
        <Modal.Header className="offcanvas-heading-style">
          <Modal.Title className="text-uppercase text-dark m-0">
            <FaFilter className="me-1" size={15} />
            {Translate('Filter')}
          </Modal.Title>
          <Button size="sm" onClick={resetFilters}>
            {Translate('Reset Filters')}
          </Button>
        </Modal.Header>
        <Modal.Body className="border-top p-0">
          <Filter
            moduleName={moduleName}
            modal={true}
            handleModal={handleModal}
            enableDropDown={enableDropDown}
            url={url}
            dropDownData={dropDownData}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

FilterModal.propTypes = {
  show: PropTypes.bool,
  handleModal: PropTypes.func,
  moduleName: PropTypes.string,
  enableDropDown: PropTypes.any,
  url: PropTypes.any,
  dropDownData: PropTypes.any
};

export default FilterModal;
