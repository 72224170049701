import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { FaCheckCircle, FaEdit, FaTimesCircle } from 'react-icons/fa';
import classNames from 'classnames';
import { FaBars, FaPlus, FaRegSquare, FaTrash } from 'react-icons/fa6';
import { DeleteContext } from 'context/Context';
import ProposalPageSelectionModal from './ProposalPageSelectionModal';

export default function ProposalPdfConfigSection({
  pages,
  setPages,
  fileName,
  setFileName,
  editorPadding,
  setEditorPadding
}) {
  const Translate = useAxisproTranslate();
  const [showRenameInput, setShowRenameInput] = useState(false);
  const [newFileName, setNewFileName] = useState(undefined);
  const fileNameWithExtension = fileName + '.pdf';
  const [activePlace, setActivePlace] = useState('top');
  const [showPagesLibrary, setShowPagesLibrary] = useState(false);
  const paddingItems = ['Left', 'Top', 'Right', 'Bottom'];
  const dragItemCoverRef = useRef(null);
  const dragItemRef = useRef(null);

  const handlePadding = event => {
    const target = event?.target;
    const name = target?.name;
    if (!target || !paddingItems?.includes(name)) return;
    setEditorPadding(prev => ({
      ...prev,
      ...(prev?.target === 'individual'
        ? { [name]: target?.value }
        : paddingItems?.reduce((newResult, key) => {
            newResult[key] = target?.value;
            return newResult;
          }, {}))
    }));
  };

  const handlePaddingTarget = event => {
    event?.preventDefault();
    setEditorPadding(prev => ({
      ...prev,
      target: prev?.target === 'individual' ? 'all' : 'individual'
    }));
  };

  const handleChoosePage = event => {
    event?.preventDefault();
    const button = event?.currentTarget;
    const place = button?.getAttribute('place');
    if (!showPagesLibrary && !place) return;
    setActivePlace(place);
    setShowPagesLibrary(!showPagesLibrary);
  };

  const handleDrag = event => {
    dragItemRef.current = event?.currentTarget;
    event?.dataTransfer.setData('text', null);
  };

  const handleDropItem = event => {
    event?.preventDefault();
    const target = event?.target.closest('.dm-pr-pdf-page-item');
    const dragItem = dragItemRef?.current;
    if (!dragItem || !target) return;

    if (
      target.classList.contains('dm-pr-pdf-page-item') &&
      target !== dragItem
    ) {
      const targetItemIndex = target?.getAttribute('item-index');
      const dragItemItemIndex = dragItem?.getAttribute('item-index');
      const dragItemData = pages?.[dragItemItemIndex];
      if (!dragItemData || targetItemIndex < 0 || dragItemItemIndex < 0) return;
      setPages(prev => {
        const newResult = prev?.filter(
          (_, index) => index !== +dragItemItemIndex
        );

        newResult?.splice(+targetItemIndex, 0, dragItemData);
        return newResult;
      });
    }
  };

  const handleRemoveItem = indexToRemove => {
    setPages(prev => prev?.filter((_, index) => index !== indexToRemove));
  };

  const handleShowRenameInput = event => {
    event?.preventDefault();
    setNewFileName(fileName);
    setShowRenameInput(!showRenameInput);
  };

  const handleRenameFileInput = event => {
    event?.preventDefault();
    setNewFileName(event?.target?.value);
  };

  const handleRenameFile = event => {
    event?.preventDefault();
    setPages(prev =>
      prev?.map(item =>
        item?.type === 'default'
          ? { ...item, file: { ...item.file, name: newFileName + '.pdf' } }
          : item
      )
    );
    setFileName(newFileName);
    handleShowRenameInput();
  };

  return (
    <>
      <div className="border shadow-sm mb-4">
        <div className="bg-dark text-light p-2 text-uppercase fw-bold">
          {Translate('PDF Config Options')}
        </div>
        <div className="p-2">
          <span className="text-dark fw-bold">{Translate('File Name')}</span>
          <div className="d-flex flex-row align-items-center justify-content-between gap-2 mt-1 mb-4 file-name-section pe-2">
            {showRenameInput ? (
              <Form.Control
                value={newFileName || ''}
                onChange={handleRenameFileInput}
              />
            ) : (
              <span className="text-secondary">{fileNameWithExtension}</span>
            )}
            <div className="d-flex flex-row align-items-center gap-1">
              {showRenameInput && (
                <Button
                  variant="transparent"
                  className="p-0 shadow-none text-success"
                  type="button"
                  onClick={handleRenameFile}
                >
                  <FaCheckCircle size={14} />
                </Button>
              )}
              <Button
                variant="transparent"
                className={classNames('p-0 shadow-none', {
                  'file-rename-btn': !showRenameInput
                })}
                title={Translate(showRenameInput ? 'Cancel' : 'Rename file')}
                type="button"
                onClick={handleShowRenameInput}
              >
                {showRenameInput ? (
                  <FaTimesCircle size={14} />
                ) : (
                  <FaEdit size={12} />
                )}
              </Button>
            </div>
          </div>

          <div className="d-flex flex-row align-items-center justify-content-between gap-1 pe-2 mb-2">
            <span className="text-dark fw-bold">{Translate('Padding')}</span>
            <Button
              className={classNames(
                'border px-1 py-0 bg-light shadow-none dm-pr-pdf-padding-target-btn',
                {
                  'text-primary': editorPadding?.target === 'all'
                }
              )}
              title={Translate(
                editorPadding?.target === 'all' ? 'All side' : 'Individual'
              )}
              variant="transparent"
              type="button"
              onClick={handlePaddingTarget}
            >
              <FaRegSquare size={13} />
            </Button>
          </div>
          <div className="d-flex flex-wrap gap-2 mt-1 mb-4">
            {paddingItems?.map(item => (
              <Form.Control
                type="number"
                min={0}
                key={item}
                name={item}
                value={editorPadding?.[item] || ''}
                placeholder={Translate(item)}
                className="padding-input"
                onChange={handlePadding}
              />
            ))}
          </div>

          <div>
            <ProposalPageSelectionModal
              setPages={setPages}
              activePlace={activePlace}
              show={showPagesLibrary}
              onHide={handleChoosePage}
            />
            <span className="text-dark fw-bold">{Translate('Pages')}</span>
            <div className="d-flex flex-column gap-2 py-2">
              <PageAddButton onClick={handleChoosePage} place="top" />
              <div
                className="d-flex flex-column gap-2"
                ref={dragItemCoverRef}
                onDragOver={event => event?.preventDefault()}
                onDrop={handleDropItem}
              >
                {pages?.map((page, index) => (
                  <PageItem
                    key={'pdfPage' + index}
                    item-index={index}
                    pageData={page}
                    onDragStart={handleDrag}
                    handleRemoveItem={() => handleRemoveItem(index)}
                  />
                ))}
              </div>
              <PageAddButton onClick={handleChoosePage} place="bottom" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const PageAddButton = ({ ...rest }) => {
  const Translate = useAxisproTranslate();
  return (
    <Button
      className="d-flex flex-row align-items-center justify-content-center gap-2 w-100 page-add-button text-muted p-2 shadow-none rounded"
      size="sm"
      variant="transparent"
      type="button"
      {...rest}
    >
      <span>{Translate('Include Page')}</span>
      <FaPlus />
    </Button>
  );
};

const PageItem = ({ pageData, handleRemoveItem, ...rest }) => {
  const Translate = useAxisproTranslate();
  const { dispatch: configConfirm } = useContext(DeleteContext);
  const isDefault = pageData?.type === 'default';

  const handleTrash = event => {
    event?.preventDefault();

    configConfirm({
      type: 'CONFIG',
      payload: {
        show: true,
        title: Translate('Remove selected item'),
        message: Translate(
          'Are you sure you want to remove the selected item?'
        ),
        submitButtonVariant: 'danger',
        buttonText: Translate('Delete'),
        cancelButtonVariant: 'secondary',
        onSubmit: event => {
          event?.preventDefault();
          configConfirm({
            type: 'RESET'
          });
          typeof handleRemoveItem === 'function' && handleRemoveItem();
        }
      }
    });
  };

  return (
    <div
      className={classNames(
        'dm-pr-pdf-page-item border p-2 rounded d-flex flex-row align-items-center gap-2',
        {
          'default-dm-pr-pdf-page-item bg-primary text-light': isDefault,
          'bg-light': !isDefault
        }
      )}
      draggable
      {...rest}
    >
      <FaBars size={10} className="svg-bars" />
      <span
        className={classNames('f-name', {
          'fw-bold': isDefault
        })}
      >
        {pageData?.name || Translate('Dynamic Page')}
      </span>
      {!isDefault ? (
        <Button
          variant="transparent"
          className="ms-auto p-0 shadow-none"
          title={Translate('Remove Page')}
          type="button"
          onClick={handleTrash}
        >
          <FaTrash size={11} />
        </Button>
      ) : null}
    </div>
  );
};

PageItem.propTypes = {
  pageData: PropTypes.object,
  handleRemoveItem: PropTypes.func
};

ProposalPdfConfigSection.propTypes = {
  pages: PropTypes.array,
  setPages: PropTypes.func,
  fileName: PropTypes.string,
  setFileName: PropTypes.func,
  editorPadding: PropTypes.object,
  setEditorPadding: PropTypes.func
};
