import { React, useEffect, useState, useRef } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Form, Dropdown, CloseButton } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BsQuestionCircleFill } from 'react-icons/bs';

import { linkItems } from './components/data';
import { getAcodaxPermissionSlug } from '../Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import ItemsAutoComplete from './components/MenuItems';

const SearchBox = () => {
  const inputRef = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showAutoCompletion, setShowAutoCompletion] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchBoxData, setSearchBoxData] = useState(linkItems);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchInputValue, setSearchInputValue] = useState();
  const Translate = useAxisproTranslate();
  const navigate = useNavigate();
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const performSearch = e => {
    e.preventDefault();
  };
  const handleFieldChange = e => {
    e.preventDefault();

    const filteredItems = linkItems.filter(item =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (!searchInputValue) {
      setDropdownOpen(true);
      setSearchBoxData(() => [...linkItems]);
    } else {
      setSearchBoxData(() => [...filteredItems]);
    }
    // Now, filteredItems contains the items that match the search term
    setSearchInputValue(e.target.value);
    const result = e.target.value.split(':')[1] || null;
    setSearchValue(result);
  };
  const handleSelectOption = items => {
    setSelectedItem(items);
    setSearchBoxData(() => [...linkItems]);
    setSearchInputValue(items.display_input_value);
    inputRef.current.focus();
    setShowAutoCompletion(true);
  };
  const closeSearchButton = () => {
    setSearchInputValue('');
    setSearchValue('');
    setShowAutoCompletion(false);
    setSelectedItem({});
    setSearchBoxData(() => [...linkItems]);
    setDropdownOpen(false);
  };
  const handleAutocompleteSubmit = items => {
    setDropdownOpen(false);
    const obj = {};
    const filter_name = selectedItem?.filter_name;
    if (selectedItem.trans) {
      obj[filter_name] = items.label;
    } else {
      obj[filter_name] = items.value;
      obj[filter_name + '_ref'] = items;
    }
    if (selectedItem.params) {
      navigate(
        `${selectedItem.navigate}?entry=${
          items.value
        }&layout=column&${createSearchParams({
          filters: JSON.stringify(obj)
        })}&page=1`
      );
    } else {
      navigate(
        `${selectedItem.navigate}/${items.value}?${createSearchParams({
          filters: JSON.stringify(obj)
        })}&page=1`
      );
    }
    closeSearchButton();
  };
  useEffect(() => {
    if (!searchInputValue) {
      setShowAutoCompletion(false);
      setSelectedItem({});
      setDropdownOpen(false);
    }
  }, [searchInputValue]);
  return (
    <>
      <Dropdown
        onToggle={toggle}
        show={selectedItem.navigate ? true : dropdownOpen}
        className="search-box"
      >
        <Dropdown.Toggle
          as="div"
          data-toggle="dropdown"
          aria-expanded={selectedItem.navigate ? true : dropdownOpen}
          bsPrefix="toggle"
        >
          <Form className="position-relative" onSubmit={performSearch}>
            <Form.Control
              type="search"
              placeholder="Search..."
              aria-label="Search"
              className="rounded-pill search-input"
              ref={inputRef}
              value={searchInputValue}
              onChange={handleFieldChange}
            />
            <FontAwesomeIcon
              icon="search"
              className="position-absolute text-400 search-box-icon"
            />
            {searchInputValue && (
              <div className="search-box-close-btn-container">
                <CloseButton
                  style={{ width: '0.1rem', height: '0.1rem' }}
                  onClick={closeSearchButton}
                />
              </div>
            )}
          </Form>
        </Dropdown.Toggle>
        {showAutoCompletion ? (
          <ItemsAutoComplete
            selectedItem={selectedItem}
            searchValue={searchValue}
            handleAutocompleteSubmit={handleAutocompleteSubmit}
          />
        ) : (
          searchBoxData[0] && (
            <Dropdown.Menu
              className="search-data-menu"
              style={{
                maxHeight: '20rem',
                overflow: 'hidden',
                overflowY: 'scroll'
              }}
            >
              {searchBoxData.map((items, index) => {
                if (getAcodaxPermissionSlug(items.type, 'show')) {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="fs-0 px-card py-1 search-data-dropdown"
                      onClick={() => handleSelectOption(items)}
                    >
                      <Flex alignItems="center">
                        <SoftBadge className="fw-medium w-100 text-decoration-none me-2 text-start">
                          <BsQuestionCircleFill
                            title={Translate('search for an item')}
                          />
                          {items.name}
                        </SoftBadge>
                      </Flex>
                    </Dropdown.Item>
                  );
                }
              })}
            </Dropdown.Menu>
          )
        )}
      </Dropdown>
    </>
  );
};
export default SearchBox;
