import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectAccountDescriptions from 'components/form/SelectAccountDescriptions';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function AccountInputField({
  label,
  name,
  value,
  error,
  formError,
  handleFieldChange,
  required,
  startPS
}) {
  const Translate = useAxisproTranslate();

  return (
    <Row className="mb-3">
      <Col
        xl={4}
        md={4}
        sm={12}
        className={`d-flex align-items-center justify-content-start p-0 ${
          startPS && 'ps-3'
        }`}
      >
        <Form.Label className={required ? 'require-data m-0' : 'm-0'}>
          {Translate(label)}
        </Form.Label>
      </Col>
      <Col xl={8} md={8} sm={12}>
        <Row>
          <Col xl={11} md={11} sm={11}>
            <SelectAccountDescriptions
              name={name}
              handleFieldChange={handleFieldChange}
              value={value}
              error={formError[name]}
            />
          </Col>
          <Col
            xl={1}
            md={1}
            sm={1}
            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
          >
            {error ? (
              <FormErrorPopover
                id={name}
                totalErrorCount={Object.keys(formError).length}
                errorMessage={error}
              />
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

AccountInputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.any,
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func,
  required: PropTypes.bool,
  startPS: PropTypes.bool
};

export default AccountInputField;
