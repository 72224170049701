import React from 'react';
import { Button } from 'react-bootstrap';

import { FaArrowCircleUp } from 'react-icons/fa';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

const DownloadCenterBacktoTopButton = prop => {
  const Translate = useAxisproTranslate();

  return (
    <Button
      size="sm"
      className="d-flex flex-row align-items-center justify-content-center w-100"
      {...prop}
    >
      <p className="mb-0 me-1">{Translate('Back to top')}</p>
      <FaArrowCircleUp />
    </Button>
  );
};

export default DownloadCenterBacktoTopButton;
