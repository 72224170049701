import { React, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import PaymentTermPopupForm from './popup-forms/PaymentTermPopupForm';
import ReactSelectAddButton from 'components/add-button/ReactSelectAddButton';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

const SelectPaymentTerms = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  includeThisKeys,
  style,
  placeholder,
  withAddButton,
  onFocus,
  ...rest
}) => {
  const [showFormWindow, setShowFormWindow] = useState(false);
  const {
    fetchData,
    setDefaultResult,
    loading,
    defaultOptions,
    setDefaultOptions
  } = useAutoCompleteApiCallHandler({
    url: 'settings/payment-terms-autocomplete',
    setParams: searchKey => ({ name: searchKey }),
    setOptions: option => setOptionData(option),
    onFocus: onFocus
  });

  const setOptionData = option => ({
    label: option.terms,
    value: keyName ? option[keyName] : option.id,
    ...(Array.isArray(includeThisKeys)
      ? includeThisKeys.reduce((result, key) => {
          if (typeof key === 'string') {
            result[key] = option?.[key];
          }
          return result;
        }, {})
      : null)
  });

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={fetchData}
        isLoading={loading}
        onFocus={setDefaultResult}
        defaultOptions={defaultOptions}
        isClearable
        name={name ?? 'payment_terms'}
        value={value}
        placeholder={placeholder ?? ''}
        onChange={handleFieldChange}
        classNamePrefix="app-react-select"
        className={`${error && 'is-invalid'}`}
        components={
          withAddButton
            ? {
                Menu: props => (
                  <>
                    <ReactSelectAddButton
                      props={props}
                      setShowFormWindow={() => setShowFormWindow(true)}
                      title="Add Payment Term"
                    />
                  </>
                )
              }
            : null
        }
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({
            ...provided,
            zIndex: 9999,
            borderRadius: '0px',
            fontSize: '13px'
          }),
          control: provided => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style
          })
        }}
        {...rest}
      />

      {withAddButton ? (
        <PaymentTermPopupForm
          name={name ?? 'payment_term_id'}
          labelName="terms"
          keyName="id"
          show={showFormWindow}
          onHide={() => setShowFormWindow(false)}
          setData={handleFieldChange}
          callBack={newItem => {
            if (newItem?.id) {
              setDefaultOptions(prev => [setOptionData(newItem), ...prev]);
            }
          }}
        />
      ) : null}
    </>
  );
};

SelectPaymentTerms.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  includeThisKeys: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
  placeholder: PropTypes.string,
  withAddButton: PropTypes.bool,
  onFocus: PropTypes.func
};

export default SelectPaymentTerms;
