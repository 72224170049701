export const getPermissionSlug = type => {
  switch (type) {
    case 'SQ':
      return 'show-sq';
    case 'SO':
      return 'show-so';
    case 'SD':
      return 'show-sd';
    case 'SI':
      return 'show-si';
    case 'CN':
      return 'show-credit-note';
    case 'CR':
      return 'show-cr';
    case 'PI':
      return 'show-pi';
    case 'PD':
      return 'show-pd';
    case 'PR':
      return 'show-pr';
    case 'PO':
      return 'show-po';
    case 'SP':
      return 'show-sp';
    case 'JV':
      return 'show-jv';
    case 'PV':
      return 'show-pv';
    case 'RV':
      return 'show-rv';
    case 'BT':
      return 'show-bt';
    case 'IA':
      return 'show-ia';
    case 'WO':
      return 'show-wo';
    case 'CT':
      return 'show-ct';
    default:
      return '';
  }
};

export const getPrintPermission = type => {
  switch (type) {
    case 'SQ':
      return 'print-sq';
    case 'SO':
      return 'print-so';
    case 'SD':
      return 'print-sd';
    case 'SI':
      return 'print-si';
    case 'CN':
      return 'print-credit-note';
    case 'CR':
      return 'print-customer-receipt';
    case 'PI':
      return 'print-pi';
    case 'PD':
      return 'print-pd';
    case 'PR':
      return 'print-pr';
    case 'PO':
      return 'print-po';
    case 'SP':
      return 'print-sp';
    case 'JV':
      return 'print-jv';
    case 'PV':
      return 'print-pv';
    case 'RV':
      return 'print-rv';
    case 'BT':
      return 'print-bv';
    case 'IA':
      return 'print-ia';
    case 'WO':
      return 'print-wo';
    default:
      return '';
  }
};
