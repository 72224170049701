const authReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...action.payload };

    case 'DELETE':
      return undefined;

    default:
      return state;
  }
};

export default authReducer;
