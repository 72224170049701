import { React, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import CostCenterPopupForm from './popup-forms/CostCenterPopupForm';
import ReactSelectAddButton from 'components/add-button/ReactSelectAddButton';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

const SelectCostCenter = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
  withAddButton,
  onFocus,
  ...rest
}) => {
  const [showFormWindow, setShowFormWindow] = useState(false);
  const {
    fetchData,
    setDefaultResult,
    loading,
    defaultOptions,
    setDefaultOptions
  } = useAutoCompleteApiCallHandler({
    url: 'finance/cost-centers/autocomplete',
    setParams: searchKey => ({ name: searchKey }),
    setOptions: option => setOptionData(option),
    onFocus: onFocus
  });

  const setOptionData = option => ({
    label: option.name,
    value: keyName ? option[keyName] : option.id
  });

  return (
    <>
      <AsyncSelect
        loadOptions={fetchData}
        cacheOptions
        placeholder={placeholder ?? ''}
        isLoading={loading}
        isClearable
        defaultOptions={defaultOptions}
        onFocus={setDefaultResult}
        value={value}
        onChange={handleFieldChange}
        name={name ?? 'cost_center'}
        classNamePrefix="app-react-select"
        className={error && 'form-control ps-0 py-0 is-invalid'}
        components={
          withAddButton
            ? {
                Menu: props => (
                  <>
                    <ReactSelectAddButton
                      props={props}
                      setShowFormWindow={() => setShowFormWindow(true)}
                      title="Add Cost Center"
                    />
                  </>
                )
              }
            : null
        }
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 }),
          control: provided => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style
          })
        }}
        {...rest}
        menuPortalTarget={document.body}
      />

      {withAddButton ? (
        <CostCenterPopupForm
          name={name ?? 'cost_center_id'}
          labelName="name"
          keyName="id"
          show={showFormWindow}
          onHide={() => setShowFormWindow(false)}
          setData={handleFieldChange}
          callBack={newItem => {
            if (newItem?.id) {
              setDefaultOptions(prev => [setOptionData(newItem), ...prev]);
            }
          }}
        />
      ) : null}
    </>
  );
};

SelectCostCenter.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  withAddButton: PropTypes.bool,
  onFocus: PropTypes.func
};

export default SelectCostCenter;
