import React from 'react';

import { BiPurchaseTag } from 'react-icons/bi';
import { FaCoins, FaRegMoneyBillAlt } from 'react-icons/fa';
import { MdOutlineInventory2, MdSell } from 'react-icons/md';
import { FcSettings } from 'react-icons/fc';
import { RiFolderSettingsFill } from 'react-icons/ri';
import { HiOutlineReceiptTax } from 'react-icons/hi';

import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { FaBell } from 'react-icons/fa6';

function menuArray() {
  const permissions = {
    permission: getAcodaxPermissionSlug('CSS', 'show')
  };

  const menuItemsArray = [
    {
      name: 'Company Settings',
      icon: <FcSettings className="me-2" size={15} />,
      route: '/system-settings/company-preference',
      slug: permissions?.permission
    },
    {
      name: 'Finance Settings',
      icon: <FaRegMoneyBillAlt className="me-2" size={15} />,
      route: '/system-settings/finance',
      slug: permissions?.permission
    },
    {
      name: 'Sales Settings',
      icon: <MdSell className="me-2" size={15} />,
      route: '/system-settings/sales-settings',
      slug: permissions?.permission
    },
    {
      name: 'Purchase Settings',
      icon: <BiPurchaseTag className="me-2" size={15} />,
      route: '/system-settings/purchase-settings',
      slug: permissions?.permission
    },
    {
      name: 'Inventory Settings',
      icon: <MdOutlineInventory2 className="me-2" size={15} />,
      route: '/system-settings/inventory-settings',
      slug: permissions?.permission
    },
    {
      name: 'Tax Settings',
      icon: <HiOutlineReceiptTax className="me-2" size={15} />,
      route: '/system-settings/tax-settings',
      slug: permissions?.permission
    },
    {
      name: 'System Settings',
      icon: <RiFolderSettingsFill className="me-2" size={15} />,
      route: '/system-settings/system-settings',
      slug: permissions?.permission
    },
    {
      name: 'Opening Balance',
      icon: <FaCoins className="me-2" size={15} />,
      route: '/system-settings/opening-balance',
      slug: permissions?.permission
    },
    {
      name: 'Notification Settings',
      icon: <FaBell className="me-2" size={15} />,
      route: '/system-settings/notification-settings',
      slug: permissions?.permission
    }
    // {
    //   name: 'Print Settings',
    //   icon: <BiPrinter className="me-2" size={15} />,
    //   route: '/system-settings/print-settings',
    //   slug: getAcodaxPermissionSlug('CSS', 'show')
    // }
  ];

  return menuItemsArray;
}

export default menuArray;
