import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaFile } from 'react-icons/fa';

export default function LaunchpadQuickLinkItem({ linkData, onPageChange }) {
  return (
    <Link
      to={linkData.url ? linkData.url : linkData.to ? `/${linkData.to}` : '/'}
      className="text-decoration-none"
      onClick={onPageChange}
    >
      <div className="d-flex flex-row align-items-center app-launchpad-quick-link-item">
        <FaFile size={15} />
        <p className="text-start">{linkData.label ?? linkData.name}</p>
      </div>
    </Link>
  );
}

LaunchpadQuickLinkItem.propTypes = {
  linkData: PropTypes.object,
  onPageChange: PropTypes.func
};
