import { React, useState } from 'react';
import {
  Badge,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { BsReceipt } from 'react-icons/bs';
import { TiTick } from 'react-icons/ti';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import InventoryAdjustmentView from './InventoryAdjustmentView';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import RecurringRules from '../Common/RecurringRules';
import JournalTable from '../Common/Tables/JournalTable';
import PDFView from 'components/PDFViewerButton/PDFView';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import ActivityLog from 'components/activity-log/ActivityLog';

function InventoryAdjustmentTabView({
  inventoryAdjustmentVoucherData,
  loading,
  voidScreen,
  infoPage,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport
}) {
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [upload, setUpload] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const permission = {
    show: getAcodaxPermissionSlug('IA', 'show'),
    update: getAcodaxPermissionSlug('IA', 'update')
  };

  const getEmail = () => {
    switch (inventoryAdjustmentVoucherData.person_type_id) {
      case 'CUSTOMER':
        return inventoryAdjustmentVoucherData.person
          ? inventoryAdjustmentVoucherData.person.email
          : '';
      case 'SUPPLIER':
        return inventoryAdjustmentVoucherData.person
          ? inventoryAdjustmentVoucherData.person.email_address
          : '';
      default:
        return '';
    }
  };

  const sendEmail = () => {
    const email = getEmail();
    setEmailAddress(email);
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <BsReceipt size={28} className="me-2 text-dark" />
              <h5 className="mb-0 d-flex text-uppercase view-page-header">
                {Translate('Inventory adjustment')}
              </h5>
            </div>
            {inventoryAdjustmentVoucherData &&
            inventoryAdjustmentVoucherData.is_recurring ? (
              <Badge bg="success" className="ms-2 fs--1">
                {Translate('Recurring')} <TiTick />
              </Badge>
            ) : (
              ''
            )}
            {voidTransactionReport && (
              <Badge bg="danger" className="ms-2 fs--1">
                {Translate('Voided')}
              </Badge>
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  setRecurring={setRecurring}
                  recurring
                  disableRecurring={
                    inventoryAdjustmentVoucherData.is_recurring === 1
                  }
                  emailPermission={permission?.show}
                />
              )}
              {voidScreen || infoPage || offCanvas ? (
                ''
              ) : permission?.update ? (
                <>
                  <EditButton
                    to={`/inventory/inventory-adjustment/edit/${inventoryAdjustmentVoucherData.id}`}
                  />
                </>
              ) : (
                ''
              )}
              {!voidTransactionReport && permission?.show && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`inventory/inventory-adjustment/print/${inventoryAdjustmentVoucherData.id}`}
                  pageTitle="Inventory Adjustment print view"
                />
              )}
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap">
            <div className="w-100 mb-2 view-page-contents">
              <div className="d-flex flex-column gap-1">
                {inventoryAdjustmentVoucherData.trans_date_formatted ? (
                  <MainDetails
                    label={'Transaction Date'}
                    value={inventoryAdjustmentVoucherData.trans_date_formatted}
                  />
                ) : null}
                {inventoryAdjustmentVoucherData.trans_object.length > 0 ? (
                  <MainDetails
                    label={'Warehouse Name'}
                    value={
                      inventoryAdjustmentVoucherData.trans_object[0]
                        .warehouse_name
                    }
                  />
                ) : null}
              </div>
              {inventoryAdjustmentVoucherData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/finance/voucher-inquiry?entry=${inventoryAdjustmentVoucherData?.id}&layout=column`}
                  value={inventoryAdjustmentVoucherData?.reference}
                />
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={inventoryAdjustmentVoucherData.trans_type}
                      transId={inventoryAdjustmentVoucherData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <InventoryAdjustmentView
                    inventoryAdjustmentVoucherData={
                      inventoryAdjustmentVoucherData
                    }
                  />
                </SimpleBar>
              </Tab>
              {inventoryAdjustmentVoucherData &&
                inventoryAdjustmentVoucherData.gl_transactions && (
                  <Tab eventKey="journal" title={Translate('Journal')}>
                    <SimpleBar className="simplebar-view-page-style">
                      <JournalTable
                        journalData={inventoryAdjustmentVoucherData}
                      />
                    </SimpleBar>
                  </Tab>
                )}
              {inventoryAdjustmentVoucherData.media &&
              inventoryAdjustmentVoucherData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={inventoryAdjustmentVoucherData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {inventoryAdjustmentVoucherData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`inventory/inventory-adjustment/print/${inventoryAdjustmentVoucherData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
              {inventoryAdjustmentVoucherData?.id ? (
                <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                  <SimpleBar
                    className={
                      voidScreen || offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    {key === 'activityLog' && (
                      <ActivityLog
                        subjectID={inventoryAdjustmentVoucherData?.id}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {inventoryAdjustmentVoucherData &&
              inventoryAdjustmentVoucherData.created_user_full_name
                ? inventoryAdjustmentVoucherData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {inventoryAdjustmentVoucherData &&
              inventoryAdjustmentVoucherData.created_at
                ? inventoryAdjustmentVoucherData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'IA'}
        id={inventoryAdjustmentVoucherData.id}
        setKey={setKey}
      />
      <SendEmail
        url={
          'inventory/inventory-adjustment/send-email/' +
          inventoryAdjustmentVoucherData.id
        }
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={emailAddress}
      />
      <RecurringRules
        show={recurring}
        type="IA"
        id={inventoryAdjustmentVoucherData.id}
        onHide={() => setRecurring(false)}
        fetchData={fetchData}
      />
    </>
  );
}

InventoryAdjustmentTabView.propTypes = {
  inventoryAdjustmentVoucherData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  infoPage: PropTypes.bool
};

export default InventoryAdjustmentTabView;
