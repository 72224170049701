import React from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import PropTypes from 'prop-types';

export default function AppCalendarRangePicker({
  value,
  onChange,
  name,
  ...rest
}) {
  const handleFieldChange = (date, name) => {
    let formatedDate = Array.isArray(date)
      ? date.reduce((result, dateData) => {
          result.push(
            dateData.getFullYear().toString().padStart(4, '0') +
              '-' +
              ('0' + (dateData.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + dateData.getDate()).slice(-2)
          );
          return result;
        }, [])
      : null;
    if (typeof onChange === 'function')
      onChange({ target: { name: name, value: formatedDate } });
  };
  const setValue = value => {
    return Array.isArray(value) && value.length === 2
      ? [new Date(value[0]), new Date(value[1])]
      : null;
  };
  return (
    <Calendar
      selectRange
      value={setValue(value)}
      onChange={date => handleFieldChange(date, name)}
      {...rest}
    />
  );
}

AppCalendarRangePicker.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string
};
