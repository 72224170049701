import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React from 'react';

export default function EstimateEntryProductInformationTitleSection({
  ...rest
}) {
  const Translate = useAxisproTranslate();
  return (
    <tr {...rest}>
      <th className="fw-bold py-2">{Translate('SL')}</th>
      <th className="fw-bold py-2">{Translate('Item Code')}</th>
      <th className="fw-bold py-2">{Translate('Item Name')}</th>
      <th className="fw-bold py-2">{Translate('Length')}</th>
      <th className="fw-bold py-2">{Translate('Width')}</th>
      <th className="fw-bold py-2">{Translate('Height')}</th>
      <th className="fw-bold py-2">{Translate('Weight')}</th>
      <th className="fw-bold py-2 border-end-0">{Translate('Quantity')}</th>
      <th className="border-start-0"></th>
    </tr>
  );
}
