import axios from 'axios';
import { AuthWizardContext } from 'context/Context';
import { loginFormKeys } from 'helpers/formKeys';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import { showToast } from 'module/Common/Toast/toast';
import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner
} from 'react-bootstrap';
import { FaArrowRight, FaLock, FaUser } from 'react-icons/fa';
import { AiOutlineReload } from 'react-icons/ai';

const LoginForm = () => {
  const { dispatch } = useContext(AuthWizardContext);
  const [authenticatingUser, setAuthenticatingUser] = useState(false);
  const [formData, setFormData] = useState(loginFormKeys);
  const [show, setShow] = useState(false);

  const [errors, setErrors] = useState({});

  const handleFieldChange = e =>
    setFormData(generateFormDataHelper(e, null, formData));

  const handleSubmit = e => {
    e.preventDefault();
    setErrors({});

    if (!formData.username || !formData.password)
      return showToast(
        `Invalid request, ${
          !formData.username && !formData.password
            ? 'username and password'
            : !formData.username
            ? 'username'
            : 'password'
        } is required.`,
        'error'
      );

    setAuthenticatingUser(true);

    axios
      .post('auth/login', formData)
      .then(response => {
        setAuthenticatingUser(false);
        if (response.data.success !== true)
          return showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );

        const userData = {
          ...response.data.data,
          username: formData.username,
          lockScreen: false
        };

        if ('BroadcastChannel' in window) {
          const loginChannel = new BroadcastChannel('loginChannel');
          loginChannel.postMessage({
            type: 'login',
            message: 'User has logged out'
          });
          loginChannel.close();
        }

        // save user data with token to localStorage
        localStorage.setItem('AXIS_PRO_USER', JSON.stringify(userData));
        localStorage.setItem('ACODAX_MANUEL_LOGOUT', false);

        // update auth context
        dispatch({ type: 'UPDATE', payload: { ...userData } });
        showToast(response.data.message, 'success');
      })
      .catch(error => {
        setAuthenticatingUser(false);
        if (!error.response.data || !error.response.data.message)
          return showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );

        const validation_error =
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.errors
            ? error.response.data.data.errors
            : null;

        validation_error && setErrors({ ...validation_error });
      });
  };

  useEffect(() => {
    if ('BroadcastChannel' in window) {
      const loginChannel = new BroadcastChannel('loginChannel');
      loginChannel.onmessage = function (event) {
        if (event.data && event.data.type === 'login') {
          setShow(true);
        }
      };
      return () => {
        loginChannel.close();
      };
    }
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {Object.keys(errors).length > 0 ? (
          <Form.Group className="mb-3">
            <Alert variant="danger">
              {errors?.password
                ? errors?.password
                : errors?.username
                ? errors?.username
                : ''}
            </Alert>
          </Form.Group>
        ) : null}
        <Form.Group className="mb-3" controlId="username">
          <Form.Label>Username</Form.Label>
          <InputGroup
            className={` rounded shadow py-1 ${
              formData.username.length > 0 && !errors.username
                ? 'is-valid'
                : errors.username
                ? 'is-invalid'
                : ''
            }`}
          >
            <InputGroup.Text className="border-0 bg-transparent px-2">
              <FaUser size={10} />
            </InputGroup.Text>
            <Form.Control
              className="border-0"
              placeholder="Enter username"
              name="username"
              onChange={handleFieldChange}
              value={formData.username}
              isValid={formData.username.length > 0 && !errors.username}
              isInvalid={!!errors.username || !!errors.password}
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <InputGroup
            className={` rounded shadow py-1 ${
              formData.password.length > 0 && !errors.password
                ? 'is-valid'
                : errors.password
                ? 'is-invalid'
                : ''
            }`}
          >
            <InputGroup.Text className="border-0 bg-transparent px-2">
              <FaLock size={10} />
            </InputGroup.Text>
            <Form.Control
              type="password"
              placeholder="Enter password"
              name="password"
              onChange={handleFieldChange}
              value={formData.password}
              isValid={formData.password.length > 0 && !errors.password}
              isInvalid={!!errors.password}
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="d-flex flex-row">
          <Button
            variant="success"
            type="submit"
            className="rounded w-100 py-2 d-flex flex-row justify-content-center align-items-center"
            disabled={authenticatingUser}
          >
            {authenticatingUser ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="white"
                  className="me-1"
                  style={{ width: '12px', height: '12px' }}
                />
                <span className="fs--1">Authenticating...</span>
              </>
            ) : (
              <>
                <span>Log in</span>
                <FaArrowRight className="ms-2" size={15} />
              </>
            )}
          </Button>
        </Form.Group>
      </Form>
      <Modal show={show} centered>
        <Modal.Body className="fs--1 d-flex flex-column gap-2 align-items-center justify-content-center">
          <AiOutlineReload size={40} />
          You need to reload this tab to continue!
        </Modal.Body>
        <Button variant="danger" onClick={handleReload}>
          Reload
        </Button>
      </Modal>
    </>
  );
};

export default LoginForm;
