import { React, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { AuthWizardContext } from 'context/Context';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import WorkOrderTabView from 'module/Common/ViewPages/WorkOrder/WorkOrderTabView';

function WorkOrderInfo({
  workOrderId,
  rendering,
  firstId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  onHide
}) {
  const { user } = useContext(AuthWizardContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async isLoading => {
    if (!rendering) {
      if (firstId !== undefined) {
        let url = voidTransactionReport
          ? 'settings/void-transaction-details'
          : `production/work-orders/${firstId}`;
        setTimeout(
          await axios
            .get(url, {
              params: {
                trans_type: voidTransactionReport ? 'WO' : '',
                trans_no: voidTransactionReport ? firstId : ''
              }
            })
            .then(res => {
              if (res.data.success) {
                setData(res.data.data);
                setLoading(false);
              }
            })
            .catch(error => {
              console.log(error);
            }),
          1000
        );
      }
    } else {
      if (workOrderId !== undefined) {
        if (isLoading) {
          setLoading(true);
        }
        let url = voidTransactionReport
          ? 'settings/void-transaction-details'
          : `production/work-orders/${workOrderId}`;
        await axios
          .get(url, {
            params: {
              trans_type: voidTransactionReport ? 'WO' : '',
              trans_no: voidTransactionReport ? workOrderId : ''
            }
          })
          .then(res => {
            if (res.data.success) {
              setData(res.data.data);
              setLoading(false);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      fetchData(loading);
    }, 400);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [workOrderId, user.token, firstId]);

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message={'Looking for work order info'} />
        </LoadingScreenCover>
      ) : (
        <WorkOrderTabView
          workOrderVoucherData={data}
          setWorkOrderVoucherData={setData}
          loading={loading}
          fetchData={fetchData}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          offCanvas={offCanvas}
          voidTransactionReport={voidTransactionReport}
          onHide={onHide}
        />
      )}
    </>
  );
}

WorkOrderInfo.propTypes = {
  workOrderId: PropTypes.string,
  firstId: PropTypes.string,
  rendering: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  onHide: PropTypes.func
};

export default WorkOrderInfo;
