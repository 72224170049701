import { React } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsBank2, BsCoin } from 'react-icons/bs';
import { RiHandCoinLine } from 'react-icons/ri';
import { FaAddressCard, FaRegNewspaper } from 'react-icons/fa';

import JournalTable from '../Common/Tables/JournalTable';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';
function CustomerReceiptView({ customerReceiptData }) {
  return (
    <Container className="mt-3 pe-3 ps-3 voucher-inquiry-view-wrapper">
      <Row className="ms-1">
        <Col lg={6} md={6} sm={12} className="p-0">
          {customerReceiptData?.person_name ? (
            <PersonName value={customerReceiptData.person_name} />
          ) : null}
          {customerReceiptData?.reference && (
            <Badges label={customerReceiptData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {customerReceiptData?.bank_account_name && (
              <Details
                label={'Received To'}
                value={customerReceiptData.bank_account_name}
                icon={<BsBank2 className="me-1 text-danger" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {customerReceiptData?.payment_mode && (
              <Details
                label={'Payment Mode'}
                value={customerReceiptData.payment_mode}
                icon={
                  <RiHandCoinLine className="me-1 text-warning" size={15} />
                }
                className={'mb-2 fs--1 text-capitalize'}
              />
            )}
            {customerReceiptData?.cheque_number && (
              <Details
                label={'Cheque Number'}
                value={customerReceiptData.cheque_number}
                icon={<FaRegNewspaper className="me-1 " size={15} />}
                className={'mb-2 fs--1 text-capitalize'}
              />
            )}
            {customerReceiptData?.trans_ref && (
              <Details
                label={'Transaction Reference'}
                value={customerReceiptData.trans_ref}
                icon={<FaAddressCard className="me-1 text-success" size={15} />}
                className={'mb-2 fs--1 text-capitalize'}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-5">
            <Details
              label={'Amount'}
              value={customerReceiptData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Balance Amount'}
              value={customerReceiptData?.balance_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Round Amount'}
              value={customerReceiptData?.round_off_amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      {customerReceiptData && (
        <JournalTable journalData={customerReceiptData} />
      )}
      {customerReceiptData?.memo && (
        <ShowMemo memo={customerReceiptData.memo} />
      )}
    </Container>
  );
}

CustomerReceiptView.propTypes = {
  customerReceiptData: PropTypes.any
};

export default CustomerReceiptView;
