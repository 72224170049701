import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import handlePrint from './printHelper';
import { PDFDocument } from 'pdf-lib';
import axios from 'axios';

export default function generateProposalPDFHelper({
  setIsProcessing,
  pages,
  fileName
}) {
  return (savePDF = false) => {
    const printContent = document.querySelector('.ql-editor');
    if (!printContent) return;

    html2canvas(printContent).then(async function (canvas) {
      let fileDataResultArray = [];
      const pagesIdListWithoutDeFaultPage = Array.isArray(pages)
        ? pages?.reduce((result, item) => {
            if (item?.type !== 'default') {
              result.push(item?.id);
            }
            return result;
          }, [])
        : [];

      if (pagesIdListWithoutDeFaultPage?.length > 0) {
        try {
          const getFileContentApiRequest = await axios.get(
            'proposals-get-media-files',
            {
              params: {
                id: pagesIdListWithoutDeFaultPage
              }
            }
          );

          const result = getFileContentApiRequest?.data?.data;
          if (Array.isArray) {
            fileDataResultArray = result;
          }
        } catch (error) {
          console.error(
            'There has been a problem with pdf file fetch operation:',
            error
          );
          setIsProcessing(false);
          return;
        }
      }

      const base64Image = canvas?.toDataURL('image/png');
      const pdf = new jsPDF('portrait', 'px', 'a4');

      // Create a new PDF document
      const mergedPdf = await PDFDocument.create();

      // Calculate the dimensions to fit the image within the A4 page width with margins
      const imgProps = pdf.getImageProperties(base64Image);
      const margin = 0; // 0px margin || use dynamic from config will include in feature updates
      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
      const pdfHeight = pdf.internal.pageSize.getHeight() - 2 * margin;
      const imgWidth = imgProps.width;
      const imgHeight = imgProps.height;
      const ratio = pdfWidth / imgWidth;

      // Calculate the scaled image dimensions
      const scaledWidth = imgWidth * ratio;
      const scaledHeight = imgHeight * ratio;

      // Calculate the number of pages needed
      const numberOfPages = Math.ceil(scaledHeight / pdfHeight);

      // Add the image to the PDF, splitting it across multiple pages with margins
      for (let i = 0; i < numberOfPages; i++) {
        const y = margin - i * (pdfHeight + margin);
        pdf.addImage(base64Image, 'PNG', margin, y, scaledWidth, scaledHeight);
        if (i < numberOfPages - 1) {
          pdf.addPage();
        }
      }

      const generatedPdfBytes = pdf.output('arraybuffer');
      const generatedPdfDoc = await PDFDocument.load(generatedPdfBytes);
      const generatedPages = await mergedPdf.copyPages(
        generatedPdfDoc,
        generatedPdfDoc.getPageIndices()
      );

      // Add the uploaded PDFs
      for (const page of pages) {
        if (page?.type === 'default') {
          generatedPages.forEach(page => {
            mergedPdf.addPage(page);
          });
        } else if (page?.id) {
          const fileBase64Content = fileDataResultArray?.find(
            item => item?.id?.toString() === page.id?.toString()
          )?.file_content;

          if (fileBase64Content) {
            const pdfDoc = await PDFDocument.load(fileBase64Content);
            const copiedPages = await mergedPdf.copyPages(
              pdfDoc,
              pdfDoc.getPageIndices()
            );
            copiedPages.forEach(copiedPage => {
              mergedPdf.addPage(copiedPage);
            });
          }
        }
      }

      const mergedPdfBytes = await mergedPdf.save();
      const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

      if (savePDF) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName || 'Proposal' + Date.now();
        link.click();
        link.remove();
      } else {
        handlePrint(null, 'Proposal', blob);
      }

      typeof setIsProcessing === 'function' && setIsProcessing(false);
    });
  };
}
