import { React, useState } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import ReactSelectAddButton from 'components/add-button/ReactSelectAddButton';
import CategoryPopupForm from './popup-forms/CategoryPopupForm';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

const SelectCategory = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
  fixedAsset,
  withAddButton,
  includeDetailsKey,
  product_type,
  onFocus
}) => {
  const [showFormWindow, setShowFormWindow] = useState(false);
  const {
    fetchData,
    setDefaultResult,
    loading,
    defaultOptions,
    setDefaultOptions
  } = useAutoCompleteApiCallHandler({
    url: 'settings/categories-autocomplete',
    setParams: searchKey => ({
      name: searchKey,
      only_fixed_asset: fixedAsset ? 1 : 0,
      include_fixed_asset: fixedAsset ? 1 : 0,
      product_type: product_type ?? ''
    }),
    setOptions: option => setOptionData(option),
    onFocus: onFocus
  });
  const setOptionData = option => ({
    label: option.name,
    value: keyName ? option[keyName] : option.id,
    ...(includeDetailsKey ? { category_details: option } : null)
  });
  return (
    <>
      <AsyncSelect
        key={JSON.stringify(product_type)}
        cacheOptions
        isClearable
        isLoading={loading}
        loadOptions={fetchData}
        defaultOptions={defaultOptions}
        onFocus={setDefaultResult}
        name={name ? name : 'category'}
        value={value}
        placeholder={placeholder ?? ''}
        onChange={handleFieldChange}
        components={
          withAddButton
            ? {
                Menu: props => (
                  <>
                    <ReactSelectAddButton
                      props={props}
                      setShowFormWindow={() => setShowFormWindow(true)}
                      title="Add Category"
                    />
                  </>
                )
              }
            : null
        }
        classNamePrefix="app-react-select"
        className={error && 'is-invalid'}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({
            ...provided,
            zIndex: 9999,
            fontSize: '13px',
            borderRadius: '0px'
          }),
          control: provided => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style
          })
        }}
      ></AsyncSelect>

      {withAddButton ? (
        <CategoryPopupForm
          name={name ?? 'category_id'}
          labelName="name"
          keyName={keyName ?? 'id'}
          show={showFormWindow}
          onHide={() => setShowFormWindow(false)}
          setData={handleFieldChange}
          includeDetailsKey={includeDetailsKey}
          product_type={product_type ?? ''}
          callBack={newItem => {
            if (newItem?.id) {
              setDefaultOptions(prev => [setOptionData(newItem), ...prev]);
            }
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};

SelectCategory.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  children: PropTypes.any,
  fixedAsset: PropTypes.bool,
  withAddButton: PropTypes.bool,
  customAddButton: PropTypes.bool,
  includeDetailsKey: PropTypes.bool,
  product_type: PropTypes.any,
  onFocus: PropTypes.any
};

export default SelectCategory;
