import { ActiveTaskContext } from 'context/Context';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ActiveTaskContextProvider = ({ children }) => {
  const [activeTask, setActiveTask] = useState(useContext(ActiveTaskContext));

  return (
    <ActiveTaskContext.Provider value={{ activeTask, setActiveTask }}>
      {children}
    </ActiveTaskContext.Provider>
  );
};

ActiveTaskContextProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default ActiveTaskContextProvider;
