import { React, useState } from 'react';
import {
  Badge,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { MdOutlineBatchPrediction } from 'react-icons/md';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { CloseButton } from 'module/Common/Buttons/ViewPageButtons';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import BatchView from './BatchView';
import BatchItemView from './BatchItemView';

function BatchTabView({
  batchData,
  loading,
  voidScreen,
  offCanvas,
  setShowVoucherDetailArea
}) {
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'transaction');
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [person] = useState({
    value: batchData.customer_id
  });
  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <MdOutlineBatchPrediction size={28} className="me-2 text-dark" />
              <h5 className="mb-0 d-flex text-uppercase view-page-header">
                {Translate('Batch Number')}
                <span className="ps-2 ">({batchData.batch_number})</span>
              </h5>
            </div>
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap">
            <div className="w-100 mb-2 view-page-contents">
              <div className="d-flex gap-3">
                {batchData?.mfg_date_formatted && (
                  <>
                    <h6 className="mb-2 fw-bold">
                      {Translate('Manufacturing Date')}:&nbsp;
                    </h6>
                    <h6 className="mb-2">
                      {batchData?.mfg_date_formatted ?? ''}
                    </h6>
                  </>
                )}
              </div>
              <div className="d-flex gap-1">
                {batchData?.exp_date_formatted && (
                  <>
                    <h6 className="mb-2 fw-bold">
                      {Translate('Expiry Date')}:&nbsp;
                    </h6>
                    <h6 className="mb-2">
                      {batchData?.exp_date_formatted ?? ''}
                    </h6>
                  </>
                )}
              </div>
            </div>
            <div className="w-50 d-flex gap-2">
              {batchData?.batch_number && (
                <Badge bg="dark" className="p-2 fs--1 w-25">
                  {batchData.batch_number}
                </Badge>
              )}
              {batchData.is_expired ? (
                <Badge bg="danger" className="p-2 fs--1 w-25">
                  {Translate('Expired')}
                </Badge>
              ) : (
                <Badge bg="success" className="p-2 fs--1 w-25">
                  {Translate('Not Expired')}
                </Badge>
              )}
              {batchData.is_out_of_stock ? (
                <Badge
                  bg={batchData.is_expired ? 'info' : 'warning'}
                  className="p-2 fs--1 w-25"
                >
                  {Translate('Out Of Stock')}
                </Badge>
              ) : (
                <Badge
                  bg={batchData.is_expired ? 'warning' : 'info'}
                  className="p-2 fs--1 w-25"
                >
                  {Translate('In Stock')}
                </Badge>
              )}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              <Tab eventKey="transaction" title={Translate('Transaction')}>
                <SimpleBar
                  className={
                    offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <BatchView batchData={batchData} />
                </SimpleBar>
              </Tab>
              <Tab eventKey="item" title={Translate('Item')}>
                <SimpleBar
                  className={
                    offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <BatchItemView batchData={batchData} />
                </SimpleBar>
              </Tab>
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {batchData && batchData.created_user_full_name
                ? batchData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {batchData && batchData.created_at
                ? batchData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={person}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
    </>
  );
}

BatchTabView.propTypes = {
  batchData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  infoPage: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool
};

export default BatchTabView;
