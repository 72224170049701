import AppCalendarRangePicker from 'components/AppCalendarRangePicker/AppCalendarRangePicker';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaArrowLeft } from 'react-icons/fa';

export default function CalendarRangePickerModal({
  show,
  value,
  onChange,
  onBack,
  onReset,
  onApply
}) {
  return (
    <Modal show={show}>
      <Modal.Body className="p-0 border-bottom-0">
        <AppCalendarRangePicker
          value={value}
          onChange={onChange}
          className="w-100 border-0"
        />
      </Modal.Body>
      <Modal.Footer className="mt-4 d-flex flex-row align-items-center">
        {typeof onBack === 'function' ? (
          <Button className="me-auto py-0 px-2" onClick={onBack}>
            <FaArrowLeft size={12} />
          </Button>
        ) : null}

        {typeof onReset === 'function' ? (
          <Button variant="secondary" className="ms-1 py-0" onClick={onReset}>
            <small>Reset</small>
          </Button>
        ) : null}

        {typeof onApply === 'function' ? (
          <Button variant="success" className="ms-1 py-0" onClick={onApply}>
            <small>Apply</small>
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}

CalendarRangePickerModal.propTypes = {
  show: PropTypes.bool,
  value: PropTypes.array,
  onChange: PropTypes.func,
  onBack: PropTypes.func,
  onReset: PropTypes.func,
  onApply: PropTypes.func
};
