import React from 'react';
import { Link } from 'react-router-dom';
import { Card, ListGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AiFillStar } from 'react-icons/ai';

import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { randomBTClass } from 'helpers/utils';
import loadable from '@loadable/component';
import { BsCircleFill } from 'react-icons/bs';

export const TileLinkItem = ({ title, to, icon, UI, data, count }) =>
  React.createElement(
    UI === 'column' ? 'div' : 'li',
    UI === 'column'
      ? {
          className: `tile-link`,
          style: { minHeight: '160px' }
        }
      : {
          className: `small mt-2 report-list-custom-style`
        },

    <Link to={to} className="text-decoration-none">
      {UI === 'column' ? (
        <Card className="h-100">
          <FalconCardHeader
            title={title}
            titleTag="h6"
            className="py-2"
            light
          />
          <Card.Body as={Flex} alignItems="end">
            {icon ? (
              <div className="d-flex align-items-center justify-content-between w-100">
                <Icon
                  nameIcon={icon}
                  propsIcon={{ size: 25 }}
                  className={`text-${randomBTClass()}`}
                />

                {count && Object.values(count).length > 0 ? (
                  data?.contents && Object.values(data?.contents).length > 0 ? (
                    <div className="d-flex align-items-end justify-content-center flex-column gap-2">
                      {Object.values(data?.contents).some(array =>
                        array.includes('count')
                      ) ? (
                        count[Object.keys(data?.contents)]['count'].toString()
                          .length > 6 ? (
                          <marquee
                            className={`m-0 fs-1 fw-bold text-${randomBTClass()}`}
                          >
                            {count[Object.keys(data?.contents)]['count']}
                          </marquee>
                        ) : (
                          <h5
                            className={`m-0 fs-1 fw-bold text-${randomBTClass()}`}
                          >
                            {count[Object.keys(data?.contents)]['count']}
                          </h5>
                        )
                      ) : (
                        ''
                      )}
                      {Object.values(data?.contents).some(array =>
                        array.includes('sum')
                      ) ? (
                        count[Object.keys(data?.contents)]['sum'].toString()
                          .length > 12 ? (
                          <marquee
                            className={`m-0 fs--1 fw-bold text-${randomBTClass()}`}
                          >
                            {count[Object.keys(data?.contents)]['sum']}
                          </marquee>
                        ) : (
                          <h5
                            className={`m-0 fs--1 fw-bold text-${randomBTClass()}`}
                          >
                            {count[Object.keys(data?.contents)]['sum']}
                          </h5>
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </div>
            ) : (
              <AiFillStar size="25" className={`text-${randomBTClass()}`} />
            )}
          </Card.Body>
        </Card>
      ) : (
        <ListGroup className="pt-1">
          <ListGroup.Item as="li" className="border-0 p-0  mt-0  p">
            <div className="d-flex align-items-center p-0 mt-0">
              <BsCircleFill size={7} className="" />
              <Link
                className="fw-bold custom-title-reports mt-0 ms-1 ps-2"
                style={{ textDecoration: 'none' }}
                to={to}
              >
                {title}
              </Link>
            </div>
          </ListGroup.Item>
        </ListGroup>
      )}
    </Link>
  );

TileLinkItem.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.any,
  UI: PropTypes.string,
  data: PropTypes.any,
  count: PropTypes.any
};

export const TileLink = ({ UI, children }) => {
  const childrensWithProps = () =>
    React.Children.map(children, child => {
      if (child.type.name !== 'TileLinkItem') {
        return child;
      }
      return { ...child, props: { ...child.props, UI: UI } };
    });

  return React?.createElement(
    UI === 'column' ? 'div' : 'ul',
    {
      className: `${UI === 'column' ? 'd-flex flex-wrap tile-link-row' : ''}`
    },
    ...childrensWithProps()
  );
};

TileLink.propTypes = {
  UI: PropTypes.string.isRequired,
  children: PropTypes.any
};

TileLink.Link = TileLinkItem;

export default TileLink;

export function Icon({ nameIcon, propsIcon, className }) {
  const lib = nameIcon
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    .split(' ')[0]
    .toLocaleLowerCase();
  const ElementIcon = loadable(() => import(`react-icons/${lib}/index.js`), {
    resolveComponent: el => el[nameIcon]
  });

  return <ElementIcon className={className} {...propsIcon} />;
}

Icon.propTypes = {
  nameIcon: PropTypes.any,
  propsIcon: PropTypes.any,
  className: PropTypes.any
};
