const generatePaginationData = (data, limit, currentPage) => {
  let newData = {};
  if (data && Array.isArray(data)) {
    const total = data.length;
    const to = currentPage * limit;
    const trimStart = (currentPage - 1) * limit;
    const trimEnd = trimStart + limit;
    const lastpage = Math.ceil(total / limit);

    const generateLinks = lastpage => {
      let links = [];
      if (lastpage > 1) {
        for (let index = 0; index < lastpage; index++) {
          let page = index + 1;
          links.push({
            label: page,
            active: currentPage === page ? true : false
          });
        }
      }
      return links;
    };

    newData.meta = {
      current_page: currentPage,
      per_page: limit,
      from: (currentPage - 1) * limit + 1,
      last_page: lastpage,
      total: total,
      to: to > total ? total : to,
      links: generateLinks(lastpage)
    };

    newData.data = data.slice(trimStart, trimEnd);
  } else {
    console.error('invalid data provided, the data must be an array.');
  }

  return newData;
};

export default generatePaginationData;
