export default function handleSubmitOnKeyPress(
  event,
  key,
  closestList,
  handleSubmit
) {
  let activeKey = event?.key?.toLowerCase();
  const targetElement = event?.target;
  const closestItemsList = [];
  closestList?.forEach(className => {
    const closestElement = targetElement?.closest(
      `.${className}__control--menu-is-open`
    );

    if (closestElement) {
      closestItemsList.push(closestElement);
    }
  });

  if (activeKey === key && !closestItemsList?.length) {
    event.preventDefault();
    typeof handleSubmit === 'function' && handleSubmit();
  }
}
