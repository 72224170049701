import { React, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Card, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AuthWizardContext } from 'context/Context';
import ChatWindowHeader from '../ChatWindowHeader';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import ChatWindowFooter from '../ChatWindowFooter';
import ChatDetailOffCanvasBody from './ChatWindowSmallScreenBody';

const ChatDetailOffCanvas = ({
  show,
  setShowChatWindow,
  type,
  item,
  hideDetailOffCanvas
}) => {
  const { user } = useContext(AuthWizardContext);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingPreviousMessage, setLoadingPreviousMessage] = useState(false);
  const [data, setData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [pageNumber, setPageNumber] = useState();
  const [formData, setFormData] = useState({
    message: '',
    receiver_id: '',
    group_id: '',
    files: []
  });
  const individualType = 'INDIVIDUAL';
  const groupType = 'GROUP';

  const [links, setLinks] = useState([]);

  const loadNextPage = async next_page_url => {
    setLoadingPreviousMessage(true);
    await axios
      .get(next_page_url)
      .then(response => {
        if (response.data.data) {
          const newData = response.data.data.data
            .map(details => {
              return {
                ...details,
                sender_id: details.sender_id,
                message: details.message,
                media: details.media,
                success: true
              };
            })
            .reverse();
          setData([...newData, ...data]);
          setLinks(response.data.data);
          setPageNumber(response.data.data.meta.current_page);
          setLoadingPreviousMessage(false);
        }
      })
      .catch(error => {
        console.log(error);
        setLoadingPreviousMessage(false);
      });
  };

  const fetchChat = (type, groupOrUserId) => {
    setData([]);
    setLoading(true);
    if (type != 'undefined' && type != '' && type) {
      axios
        .get('user/read-chat', {
          params: {
            user_id: type == individualType ? item.id : '',
            group_id: type == groupType ? item.id : '',
            type: type
          }
        })
        .then(() => {});
    }
    let params = '';
    type == individualType
      ? (params = {
          user_id: groupOrUserId,
          type: type
        })
      : type == groupType
      ? (params = {
          group_id: groupOrUserId,
          type: type
        })
      : '';

    axios
      .get('user/chats', {
        params: params
      })
      .then(response => {
        const newData = response.data.data.data
          .map(details => {
            return {
              ...details,
              sender_id: details.sender_id,
              message: details.message,
              success: true
            };
          })
          .reverse();
        setData(newData);
        setLinks(response.data.data);
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      })
      .catch(error => {
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (type == 'undefined') {
      navigate('/dashboard');
    }
    if (type && item.id) {
      fetchChat(type, item.id);
      type == individualType
        ? setFormData({
            ...formData,
            type: type,
            receiver_id: item.id,
            group_id: ''
          })
        : type == groupType
        ? setFormData({
            ...formData,
            type: type,
            receiver_id: '',
            group_id: item.id
          })
        : '';
    }
  }, [type, item]);

  useEffect(() => {
    if (item && type && type != 'undefined' && type != '') {
      const currentURL = window.location.href;
      const urlObject = new URL(currentURL);
      const domain = urlObject.hostname;
      window.Echo.channel(`user_chat_${domain}_${user.id}`).listen(
        'ChatBroadcast',
        data => {
          let groupOrUserId =
            type == individualType ? data.from_id : data.group_id;
          if (item.id == groupOrUserId && type == data.type) {
            let newData = {
              sender_id: data.from_id,
              message: data.message,
              media: data.files,
              success: true
            };
            setData(prevState => [...prevState, newData]);
            if (pageNumber == 1) {
              messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }

            axios
              .get('user/read-chat', {
                params: {
                  user_id: type == individualType ? item.id : '',
                  group_id: type == groupType ? item.id : '',
                  type: type
                }
              })
              .then(() => {});
          }
        }
      );
      return () => {
        window.Echo.channel(`user_chat_${domain}_${user.id}`).stopListening(
          'ChatBroadcast'
        );
      };
    }
  }, [item, type, pageNumber]);

  return (
    <>
      <Offcanvas
        show={show}
        className={`view-chat-modal
          ${
            user.language == 'AR'
              ? 'chat-window-small-screen-offcanvas-custom-style-rtl'
              : 'chat-window-small-screen-offcanvas-custom-style'
          }
        `}
        onHide={() => setShowChatWindow(false)}
        placement="end"
      >
        <ChatWindowHeader
          item={item}
          type={type}
          smallScreen={true}
          setShowChatWindow={setShowChatWindow}
          hideDetailOffCanvas={hideDetailOffCanvas}
        />

        {loading ? (
          <Card.Body className="h-100 d-flex align-items-center justify-content-center">
            <LoadingScreen message="Looking for messages" />
          </Card.Body>
        ) : (
          <ChatDetailOffCanvasBody
            data={data}
            loadNextPage={loadNextPage}
            links={links}
            loadingPreviousMessage={loadingPreviousMessage}
            attachments={attachments}
            setAttachments={setAttachments}
            setFormData={setFormData}
            formData={formData}
            messagesEndRef={messagesEndRef}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
        <Card.Footer className=" ps-1 pe-1">
          <ChatWindowFooter
            formData={formData}
            setData={setData}
            setFormData={setFormData}
            data={data}
            setAttachments={setAttachments}
            attachments={attachments}
            item={item}
            messagesEndRef={messagesEndRef}
            pageNumber={pageNumber}
          />
        </Card.Footer>
      </Offcanvas>
    </>
  );
};

ChatDetailOffCanvas.propTypes = {
  show: PropTypes.bool,
  setShowChatWindow: PropTypes.func,
  item: PropTypes.any,
  type: PropTypes.any,
  hideDetailOffCanvas: PropTypes.any
};

export default ChatDetailOffCanvas;
