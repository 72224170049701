import { React, useContext, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Button, ListGroup, Offcanvas } from 'react-bootstrap';

import { FaBars, FaTimes } from 'react-icons/fa';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { Icon } from 'components/tile-link/TileLink';
import Search from 'components/search/Search';

const ReportsContainerNav = () => {
  let location = useLocation();
  const [show, setShow] = useState(false);
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();

  const [routes, setRoutes] = useState(
    user?.menu_links?.flatMap(link =>
      link.name === 'Reports' ? link.children : []
    )
  );

  const filterSettings = event => {
    let route = user?.menu_links?.flatMap(link =>
      link.name === 'Reports' ? link.children : []
    );
    let filteredMenuItems = event.target.value
      ? route.reduce((newRouteArray, routeItem) => {
          let filteredItems = routeItem.children.filter(
            item =>
              item.name
                .toLowerCase()
                .indexOf(event.target.value.toLowerCase()) !== -1
          );

          if (filteredItems.length > 0) {
            newRouteArray.push({
              ...routeItem,
              children: filteredItems
            });
          }

          return newRouteArray;
        }, [])
      : route;
    setRoutes(filteredMenuItems);
  };

  const handleNavArea = () => {
    show && setRoutes(routes);
    setShow(!show);
  };

  return (
    <>
      <Button
        variant="transparent"
        onClick={handleNavArea}
        style={{ boxShadow: 'none', border: 'none' }}
      >
        {show ? <FaTimes /> : <FaBars />}
      </Button>
      <Offcanvas show={show} onHide={handleNavArea}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
          <Search
            className="w-100 me-3 rounded"
            onChange={filterSettings}
            placeholder={Translate('Search reports')}
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="border-top">
          {routes.length < 1 ? (
            <div className="d-flex flex-column align-items-center">
              <div>&#128577;</div>
              <p className="fw-bolder mt-2 mb-1 text-black">
                {Translate('No Data Available')}
              </p>
              <p className="small text-muted">
                {Translate('We could not find what you are looking for!')}
              </p>
            </div>
          ) : (
            routes.map((item, index) => (
              <div key={index}>
                <div className="TileTitle d-flex flex-row align-items-center my-3">
                  <Icon
                    nameIcon={item.icon}
                    propsIcon={{ size: 15 }}
                    className={`me-2`}
                  />
                  <h6 className="text-left report-sidebar-title m-0">
                    {Translate(item.name)}
                  </h6>
                </div>
                {item.children && item.children.length > 0 && (
                  <ListGroup>
                    {item.children.map((data, index) => (
                      <ListGroup.Item
                        key={index}
                        as={Link}
                        to={'/' + data.to}
                        className={`fs--1 border-0 d-flex flex-row align-items-center text-dark text-decoration-none report-nav-item ${
                          location.pathname === data.to
                            ? 'report-nav-active-item'
                            : ''
                        }`}
                      >
                        <Icon
                          nameIcon={data.icon}
                          propsIcon={{ size: 15 }}
                          className={`text-secondary me-2`}
                        />
                        {Translate(data.name)}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </div>
            ))
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ReportsContainerNav;
