import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DeleteContext } from 'context/Context';
import { FaCopy, FaTrash } from 'react-icons/fa6';

export default function EstimationEntryCostTitleSection({
  handleSelectItems,
  setFormData,
  type,
  selectedItemsCount,
  isAllSelected,
  isPreview
}) {
  const Translate = useAxisproTranslate();
  const { dispatch: configConfirm } = useContext(DeleteContext);

  const handleAction = (event, method) => {
    event?.preventDefault();

    switch (method) {
      case 'DELETE':
        configConfirm({
          type: 'CONFIG',
          payload: {
            show: true,
            title: Translate('Delete all selected items'),
            message: Translate(
              'Are you sure you want to delete all selected line items?'
            ),
            submitButtonVariant: 'danger',
            buttonText: Translate('Delete'),
            cancelButtonVariant: 'secondary',
            onSubmit: event => {
              event?.preventDefault();

              const key =
                type === 'AdditionalCost' ? 'additional_costs' : 'costs';

              setFormData(prev => ({
                ...prev,
                [key]: prev?.[key]?.filter(item => !item?.selected_ref)
              }));

              configConfirm({
                type: 'RESET'
              });
            }
          }
        });
        break;

      case 'CLONE':
        configConfirm({
          type: 'CONFIG',
          payload: {
            show: true,
            title: Translate('Clone all selected items'),
            message: Translate(
              'Are you sure you want to clone all selected line items?'
            ),
            submitButtonVariant: 'info',
            buttonText: Translate('Clone'),
            cancelButtonVariant: 'secondary',
            onSubmit: event => {
              event?.preventDefault();

              const key =
                type === 'AdditionalCost' ? 'additional_costs' : 'costs';

              setFormData(prev => ({
                ...prev,
                [key]: Array.isArray(prev?.[key])
                  ? [
                      ...prev[key],
                      ...prev[key].filter(item => item?.selected_ref === true)
                    ]?.map(item => {
                      if (item?.selected_ref) {
                        delete item.selected_ref;
                      }

                      return item;
                    })
                  : []
              }));

              configConfirm({
                type: 'RESET'
              });
            }
          }
        });
        break;
    }
  };
  return (
    <>
      {selectedItemsCount > 0 ? (
        <tr>
          <th colSpan={8}>
            <div className="d-flex flex-wrap gap-1 align-items-center justify-content-end">
              <Button
                variant="outline-danger"
                size="sm"
                className="rounded py-1 px-2 d-flex flex-row align-items-center gap-1"
                type="button"
                onClick={event => handleAction(event, 'DELETE')}
              >
                <FaTrash className="small" />
                <small>{Translate('Delete')}</small>
              </Button>

              <Button
                variant="outline-secondary"
                size="sm"
                className="rounded py-1 px-2 d-flex flex-row align-items-center gap-1"
                type="button"
                onClick={event => handleAction(event, 'CLONE')}
              >
                <FaCopy className="small" />
                <small>{Translate('Clone')}</small>
              </Button>
            </div>
          </th>
        </tr>
      ) : null}

      <tr>
        {handleSelectItems ? (
          <th className="bg-light">
            <Form.Check
              onChange={event => handleSelectItems(event, 'all')}
              checked={isAllSelected}
            />
          </th>
        ) : null}

        <th className="small fw-bold bg-light text-dark">{Translate('SL')}</th>
        <th className="small fw-bold bg-light text-dark">
          {Translate('ITEM CODE')}
        </th>
        <th
          className="small fw-bold bg-light text-dark"
          style={{
            minWidth: 250
          }}
        >
          {Translate('ITEM NAME')}
        </th>
        <th className="small fw-bold bg-light text-dark">
          {Translate('QUANTITY')}
        </th>
        <th className="small fw-bold bg-light text-dark">
          {Translate('UNIT PRICE')}
        </th>
        <th className="small fw-bold bg-light text-dark">
          {Translate('LINE TOTAL')}
        </th>
        {!isPreview ? (
          <th className="small fw-bold bg-light text-dark"></th>
        ) : null}
      </tr>
    </>
  );
}

EstimationEntryCostTitleSection.propTypes = {
  handleSelectItems: PropTypes.func,
  setFormData: PropTypes.func,
  type: PropTypes.string,
  selectedItemsCount: PropTypes.number,
  isAllSelected: PropTypes.bool,
  isPreview: PropTypes.bool
};
