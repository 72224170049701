import { apiCall } from 'helpers/apiCalls';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';
import FormattedData from 'module/Common/TableFilter/FormatedData';
import { Card } from 'react-bootstrap';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import PurchaseInvoiceTableView from 'module/Purchase/Purchase Invoice/Page/PurchaseInvoiceTableView';
import PurchaseInvoiceColumnVIew from 'module/Purchase/Purchase Invoice/Page/PurchaseInvoiceColumnVIew';

const FixedAssetPurchaseInvoiceView = () => {
  const [queryParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [entries, setEntries] = useState({});
  const pageNumber = queryParams.get('page');
  const layoutType = queryParams.get('layout');
  const filterItems = queryParams.get('filters');

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      includeCustomFieldsData: true,
      url: 'purchase/fixed-asset-purchase-invoices',
      params: {
        page: page,
        ...FormattedData(JSON.parse(filterItems))
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });

    setEntries(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
  }, [filterItems, pageNumber]);

  return layoutType === 'column' ? (
    entries?.data?.length && !isLoading ? (
      <PurchaseInvoiceColumnVIew
        data={entries}
        getData={getData}
        isFixedAsset
      />
    ) : (
      <Card className=" d-flex align-item-center justify-content-center flex-fill">
        <LoadingScreen message="Looking for data" />
      </Card>
    )
  ) : (
    <PurchaseInvoiceTableView
      data={entries}
      isLoading={isLoading}
      isFixedAsset
    />
  );
};

export default FixedAssetPurchaseInvoiceView;
