import AcodaxQuillEditor from 'components/acodaxQuillEditor/AcodaxQuillEditor';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ProposalDynamicContents from './ProposalDynamicContents';
import ProposalPdfConfigSection from './ProposalPdfConfigSection';

export default function ProposalBuilder({
  editorValue,
  setEditorValue,
  pages,
  setPages,
  fileName,
  setFileName,
  editorPadding,
  setEditorPadding,
  data
}) {
  const quillClassRef = useRef(null);

  useEffect(() => {
    const editorContainer = document.querySelector('.ql-editor');
    if (!editorContainer) return;
    Object.keys(editorPadding)?.map(key => {
      if (key !== 'target') {
        editorContainer.style['padding' + key] =
          (editorPadding[key] || 0) + 'px';
      }
    });
  }, [editorPadding]);

  return (
    <div className="d-flex flex-row gap-2 justify-content-center">
      <div className="flex-fill page-width">
        <AcodaxQuillEditor
          value={editorValue}
          onChange={setEditorValue}
          quillClassRef={quillClassRef}
        />
      </div>
      <div className="flex-fill config-width position-relative">
        <div className="config-section">
          <ProposalPdfConfigSection
            pages={pages}
            setPages={setPages}
            fileName={fileName}
            setFileName={setFileName}
            editorPadding={editorPadding}
            setEditorPadding={setEditorPadding}
          />
          <ProposalDynamicContents quillClassRef={quillClassRef} data={data} />
        </div>
      </div>
    </div>
  );
}

ProposalBuilder.propTypes = {
  editorValue: PropTypes.string,
  setEditorValue: PropTypes.func,
  pages: PropTypes.array,
  setPages: PropTypes.func,
  fileName: PropTypes.string,
  setFileName: PropTypes.func,
  editorPadding: PropTypes.object,
  setEditorPadding: PropTypes.func,
  data: PropTypes.object
};
