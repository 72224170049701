import React, { useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import {
  FaArrowRight,
  FaBuilding,
  FaDollarSign,
  FaLock,
  FaSave,
  FaUser
} from 'react-icons/fa';
import './asset/style/InstallationScreenStyle.scss';
import logo from 'assets/img/ACCODAX1.png';
import CompanySection from './CompanySection';
import UserSection from './UserSection';
import TaxSetupSection from './TaxSetupSection';
import { installationFormKeys } from 'helpers/formKeys';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import { showToast } from 'module/Common/Toast/toast';
import AccountSection from './AccountSection';
import { Link, Outlet } from 'react-router-dom';
import axios from 'axios';
import setNewFormData from 'helpers/setNewFormData';
import SuccessScreen from './SuccessScreen';
import removeRefData from 'helpers/removeRefData';
const InstallationScreen = () => {
  const allSections = ['company', 'user', 'account', 'tax'];
  const [isSaving, setIsSaving] = useState(false);
  const [complete, setComplete] = useState(false);
  const [activeSection, setActiveSection] = useState('company');
  const [formData, setFormData] = useState(installationFormKeys);
  const [formErrors, setFormErrors] = useState({});
  const validateSection = section => {
    const requiredKeys = {
      company: [
        'company_name',
        'business_type',
        'base_currency',
        'no_of_employees',
        'company_email',
        'company_phone',
        'place',
        'address'
      ],
      user: ['first_name', 'last_name', 'user_email', 'user_phone'],
      tax: ['trn_no', 'tax_group'],
      account: ['username', 'password', 'c_password']
    };
    let errors = {};
    let currentSectionRequiredKeys = requiredKeys[section];
    currentSectionRequiredKeys.map(key => {
      if (formData[key] === null || formData[key].length < 1) {
        let keyName = key.replace(/_/g, ' ');
        keyName = keyName.charAt(0).toUpperCase() + keyName.slice(1);
        errors[key] = `${keyName} is required.`;
      } else if (
        key === 'c_password' &&
        formData.c_password !== formData.password
      ) {
        errors.c_password = 'Password and Confirm password does not match!';
      }
    });
    return errors;
  };
  const handleSubmit = e => {
    e.preventDefault();

    setFormErrors({});

    if (!['company', 'user', 'tax', 'account'].includes(activeSection)) {
      showToast('Invalid request', 'error');
      return;
    }

    // check required keys
    let errors = validateSection(activeSection);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setActiveSection(
      activeSection === 'company'
        ? 'user'
        : activeSection === 'user'
        ? 'account'
        : 'tax'
    );

    if (activeSection !== 'tax') return;
    setIsSaving(true);
    axios({
      method: 'post',
      url: 'install',
      data: setNewFormData(
        removeRefData(formData, 'object', {
          removeValue: [null, 'null']
        })
      ),
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if (response.data.success === true) {
          setComplete(true);
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
        setIsSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;

          if (validation_error) {
            const ValidationErrorKeys = Object.keys(validation_error);
            const checkGivenKeyExist = arrayOfKeys => {
              // let keyExist = false;
              let keyExist = arrayOfKeys.reduce((keyExist, key) => {
                return ValidationErrorKeys.includes(key) && !keyExist
                  ? true
                  : keyExist;
              }, false);
              return keyExist;
            };

            setFormErrors(validation_error);

            setActiveSection(
              checkGivenKeyExist([
                'company_name',
                'business_type',
                'no_of_employees',
                'company_email',
                'company_phone',
                'place',
                'address',
                'base_currency',
                'company_logo'
              ])
                ? 'company'
                : checkGivenKeyExist([
                    'first_name',
                    'last_name',
                    'user_email',
                    'user_phone'
                  ])
                ? 'user'
                : checkGivenKeyExist(['username', 'password', 'c_password'])
                ? 'account'
                : activeSection
            );
          }
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
        setIsSaving(false);
      });
  };

  const handleTab = tab => {
    let CurrentSectionErrors = validateSection(activeSection);
    let NextSectionErrors = validateSection(tab);
    setFormErrors(CurrentSectionErrors);
    if (
      allSections.indexOf(tab) > allSections.indexOf(activeSection) &&
      (Object.keys(CurrentSectionErrors).length > 0 ||
        Object.keys(NextSectionErrors).length > 0)
    )
      return;

    setActiveSection(tab);
  };

  const handleFieldChange = (e, action) => {
    if (e && !action && e.target.type && e.target.type === 'file') {
      let file = e.target.files.length > 0 ? e.target.files[0] : null;
      if (file && formErrors[e.target.name]) {
        delete formErrors[e.target.name];
      }
      e = {
        target: {
          name: 'company_logo',
          value: file
        }
      };
    }
    let newFormData = generateFormDataHelper(e, action, formData);

    setFormData(newFormData);
  };

  return (
    <div className="installation-screen-wrapper d-flex flex-column justify-content-center align-items-center">
      <Outlet context={[formData, handleTab]} />
      <div className="brand-logo">
        <img
          src={logo}
          alt="logo"
          className="mb-3"
          style={{ width: '15rem' }}
        />
      </div>
      <Card
        className={`shadow p-3 form-container rounded px-0 ${
          complete
            ? 'success-container'
            : ['company'].includes(activeSection)
            ? 'form-container-md'
            : ''
        }`}
      >
        {complete ? (
          <SuccessScreen />
        ) : (
          <>
            <Card.Header className="d-flex flex-row border-bottom justify-content-between tab-area">
              <Button
                className={`d-flex flex-row align-items-center rounded py-1 ${
                  activeSection !== 'company' ? 'border-0' : ''
                }`}
                size="sm"
                variant={
                  activeSection === 'company' ? 'dark' : 'outline-success'
                }
                style={{ boxShadow: 'none' }}
                onClick={() => handleTab('company')}
              >
                <FaBuilding size={13} />
                <span className="ms-1 small">Company</span>
              </Button>

              <Button
                className="d-flex flex-row align-items-center rounded py-1 border-0"
                size="sm"
                variant={
                  activeSection === 'user'
                    ? 'dark'
                    : ['tax', 'account'].includes(activeSection)
                    ? 'outline-success'
                    : 'outline-secondary'
                }
                style={{ boxShadow: 'none' }}
                onClick={() => handleTab('user')}
              >
                <FaUser size={13} />
                <span className="ms-1 small">User</span>
              </Button>

              <Button
                className="d-flex flex-row align-items-center rounded py-1 border-0"
                size="sm"
                variant={
                  activeSection === 'account'
                    ? 'dark'
                    : activeSection === 'tax'
                    ? 'outline-success'
                    : 'outline-secondary'
                }
                style={{ boxShadow: 'none' }}
                onClick={() => handleTab('account')}
              >
                <FaLock size={13} />
                <span className="ms-1 small">Account</span>
              </Button>

              <Button
                className="d-flex flex-row align-items-center rounded py-1 border-0"
                size="sm"
                variant={activeSection === 'tax' ? 'dark' : 'outline-secondary'}
                style={{ boxShadow: 'none' }}
                onClick={() => handleTab('tax')}
              >
                <FaDollarSign size={13} />
                <span className="ms-1 small">Tax</span>
              </Button>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                {activeSection === 'company' ? (
                  <CompanySection
                    formData={formData}
                    errors={formErrors}
                    onChange={handleFieldChange}
                  />
                ) : activeSection === 'user' ? (
                  <UserSection
                    formData={formData}
                    errors={formErrors}
                    onChange={handleFieldChange}
                  />
                ) : activeSection === 'account' ? (
                  <AccountSection
                    formData={formData}
                    errors={formErrors}
                    onChange={handleFieldChange}
                  />
                ) : activeSection === 'tax' ? (
                  <TaxSetupSection
                    formData={formData}
                    errors={formErrors}
                    onChange={handleFieldChange}
                  />
                ) : null}

                <Form.Group className="d-flex flex-row">
                  <Button
                    variant="success"
                    type="submit"
                    className="rounded w-100 py-2 d-flex flex-row justify-content-center align-items-center"
                    disabled={isSaving}
                  >
                    {isSaving ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="white"
                          className="me-1"
                        />
                        <span className="fs--1">Saving...</span>
                      </>
                    ) : activeSection === 'tax' ? (
                      <>
                        <FaSave className="me-2" size={15} />
                        <span>Create</span>
                      </>
                    ) : (
                      <>
                        <span>Continue</span>
                        <FaArrowRight className="ms-2" size={15} />
                      </>
                    )}
                  </Button>
                </Form.Group>
                <Form.Group className="text-center mt-3">
                  <p className="m-0 text-muted fw-bold">
                    Review your&nbsp;
                    <Link to="/install/preview" className="text-danger">
                      Application
                    </Link>
                  </p>
                </Form.Group>
              </Form>
            </Card.Body>
          </>
        )}
      </Card>
    </div>
  );
};

export default InstallationScreen;
