import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { HiOutlineReceiptTax } from 'react-icons/hi';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from '../Toast/toast';
import { CancelButton, SaveButton } from '../Buttons/CommonButton';
import SelectAccountDescriptions from 'components/form/SelectAccountDescriptions';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import ErrorAlert from '../Error/ErrorAlert';

function AddTax({ show, onHide, setFormData, data }) {
  const Translate = useAxisproTranslate();
  const [taxData, setTaxData] = useState({
    name: '',
    purchase_gl_code: '',
    purchase_gl_code_ref: '',
    sales_gl_code: '',
    sales_gl_code_ref: ''
  });
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});

  useEffect(() => {
    setTaxData({
      ...taxData,
      name: data
    });
  }, [data]);

  const handleFieldChange = (e, action) => {
    let newFormData;
    if (action) {
      newFormData = {
        ...taxData,
        [action.name]: e ? e.value : null,
        [action.name + '_ref']: e
      };
    } else {
      newFormData = {
        ...taxData,
        [e.target.name]: e.target.value
      };
    }
    setTaxData(newFormData);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    axios({
      method: 'post',
      url: 'settings/tax',
      data: taxData
    })
      .then(response => {
        if (response.data.success === true) {
          setTaxData({
            name: '',
            purchase_gl_code: '',
            purchase_gl_code_ref: '',
            sales_gl_code: '',
            sales_gl_code_ref: ''
          });
          onHide();
          showToast(response.data.message, 'success');
          setFormData(prev => ({
            ...prev,
            tax_id: response.data.data.id,
            tax_id_ref: response.data.data.name
          }));
        }
        setOnSave(false);
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        }
        setOnSave(false);
      });
  };

  const handleCancel = () => {
    onHide();
    setFormError({});
  };
  const handleClearForm = () => {
    setTaxData({
      name: '',
      purchase_gl_code: '',
      purchase_gl_code_ref: '',
      sales_gl_code: '',
      sales_gl_code_ref: ''
    });
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      className="modal-with-overlay"
      onHide={handleCancel}
    >
      <Card className="p-1">
        <Modal.Header
          className="d-flex flex-row align-items-center border-bottom"
          closeButton
        >
          <HiOutlineReceiptTax size={20} className="text-dark me-1 m-0" />
          <h5 className="modal-form-heading" style={{ fontSize: '15px' }}>
            {Translate('Add Tax')}
          </h5>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Card.Body className="pb-0">
            {!Object.keys(formError).length == 0 ? (
              <ErrorAlert setFormError={setFormError} formError={formError} />
            ) : (
              ''
            )}
            <Row>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate('Name')}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="text"
                          name="name"
                          onChange={handleFieldChange}
                          value={taxData.name}
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.name ? (
                          <FormErrorPopover
                            id="name"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.name}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate('Sales gl code')}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <SelectAccountDescriptions
                          name="sales_gl_code"
                          value={taxData.sales_gl_code_ref}
                          handleFieldChange={handleFieldChange}
                          keyName="code"
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.sales_gl_code ? (
                          <FormErrorPopover
                            id="sales_gl_code"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.sales_gl_code}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate('Purchase gl code')}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <SelectAccountDescriptions
                          name="purchase_gl_code"
                          value={taxData.purchase_gl_code_ref}
                          handleFieldChange={handleFieldChange}
                          keyName="code"
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.purchase_gl_code ? (
                          <FormErrorPopover
                            id="purchase_gl_code"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.purchase_gl_code}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex flex-row gap-2 justify-content-start border-top">
            <Form.Group className="d-flex flex-row gap-2 justify-content-start">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                createPermission={true}
                editPermission={true}
              />
              <CancelButton
                handleCancel={handleClearForm}
                onSave={onSave}
                clear={true}
              />
            </Form.Group>
          </Card.Footer>
        </Form>
      </Card>
    </Modal>
  );
}

AddTax.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setFormData: PropTypes.func,
  data: PropTypes.any
};

export default AddTax;
