import ShowReference from 'components/show-reference/ShowReference';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React from 'react';
import {
  FaCalendarAlt,
  FaEnvelope,
  FaInfoCircle,
  FaMobile,
  FaSignal,
  FaStickyNote,
  FaUser,
  FaUserCog
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import EstimationEntryStatus from 'module/Production/Estimation/components/EstimationEntryStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import QuillPreviewContainer from 'components/QuillPreviewContainer/QuillPreviewContainer';

export default function EnquiryOverview({
  enquiryDetails,
  offCanvas,
  setshowDetailsArea,
  permission
}) {
  const Translate = useAxisproTranslate();

  return (
    <div className="p-3">
      <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
        <h6 className="mb-2 fw-bold me-auto d-flex gap-2">
          <FaInfoCircle />
          <span>{Translate('Enquiry Reference')}:</span>
        </h6>
        <p className="mb-2 d-flex flex-row align-items-center gap-1">
          <ShowReference
            data={enquiryDetails}
            to={`/sales/enquiry/?entry=${enquiryDetails?.id}&layout=column`}
            offCanvas={offCanvas}
          />
        </p>
      </div>

      <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
        <h6 className="mb-2 fw-bold me-auto d-flex gap-2">
          <FaUser />
          <span>{Translate('Customer Name')}:</span>
        </h6>
        {enquiryDetails?.customer_name?.length > 0 ? (
          <div className="d-flex flex-row align-items-center gap-1 mb-2">
            <p className="m-0">{enquiryDetails?.customer_name}</p>
            {permission?.showCustomerDetails ? (
              <Button
                className="p-0 m-0 text-primary outline-none"
                variant="transparent"
                onClick={() => setshowDetailsArea('CUSTOMER')}
                size="sm"
              >
                <FontAwesomeIcon
                  className="p-0 m-0"
                  icon="external-link-alt"
                  size="xs"
                  cursor={'pointer'}
                />
              </Button>
            ) : null}
          </div>
        ) : (
          '-'
        )}
      </div>

      <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
        <h6 className="mb-2 fw-bold me-auto d-flex gap-2">
          <FaCalendarAlt />
          <span>{Translate('Enquiry Date')}:</span>
        </h6>
        <p className="mb-2">{enquiryDetails?.formatted_date}</p>
      </div>

      <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
        <h6 className="mb-2 fw-bold me-auto d-flex gap-2">
          <FaUser />
          <span>{Translate('Enquiry From')}:</span>
        </h6>
        <p className="mb-2">{enquiryDetails?.name}</p>
      </div>

      <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
        <h6 className="mb-2 fw-bold me-auto d-flex gap-2">
          <FaMobile />
          <span>{Translate('Phone')}:</span>
        </h6>
        <p className="mb-2">
          <a href={`tel:+${enquiryDetails?.phone}`}>{enquiryDetails?.phone}</a>
        </p>
      </div>

      <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
        <h6 className="mb-2 fw-bold me-auto d-flex gap-2">
          <FaEnvelope />
          <span>{Translate('Email')}:</span>
        </h6>
        <p className="mb-2">
          <a href={`mailto:${enquiryDetails?.email}`}>
            {enquiryDetails?.email}
          </a>
        </p>
      </div>

      <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
        <h6 className="mb-2 fw-bold me-auto d-flex gap-2">
          <FaUserCog />
          <span>{Translate('Salesman Name')}:</span>
        </h6>
        {enquiryDetails?.salesman_name?.length > 0 ? (
          <div className="d-flex flex-row align-items-center gap-1 mb-2">
            <p className="m-0">{enquiryDetails?.salesman_name}</p>
            {permission?.showSalesmandDetails ? (
              <Button
                className="p-0 m-0 text-primary outline-none"
                variant="transparent"
                onClick={() => setshowDetailsArea('SALESMAN')}
                size="sm"
              >
                <FontAwesomeIcon
                  className="p-0 m-0"
                  icon="external-link-alt"
                  size="xs"
                  cursor={'pointer'}
                />
              </Button>
            ) : null}
          </div>
        ) : (
          '-'
        )}
      </div>

      <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
        <h6 className="mb-2 fw-bold me-auto d-flex gap-2">
          <FaStickyNote />
          <span>{Translate('Description')}:</span>
        </h6>
        <QuillPreviewContainer value={enquiryDetails?.message} dashedBorder />
      </div>

      {enquiryDetails?.estimation?.id ? (
        <>
          <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
            <h6 className="mb-2 fw-bold me-auto d-flex gap-2">
              <FaInfoCircle />
              <span>{Translate('Estimation Reference')}:</span>
            </h6>
            <p className="mb-2 d-flex flex-row align-items-center gap-1">
              <ShowReference
                data={enquiryDetails.estimation}
                to={`/production/estimation?entry=${enquiryDetails.estimation.id}&layout=column`}
                offCanvas
              />
            </p>
          </div>

          <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
            <h6 className="mb-2 fw-bold me-auto d-flex gap-2">
              <FaSignal />
              <span>{Translate('Estimation Status')}:</span>
            </h6>
            <p className="mb-2">
              <EstimationEntryStatus
                status={enquiryDetails.estimation?.status}
              />
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
}

EnquiryOverview.propTypes = {
  enquiryDetails: PropTypes.object,
  offCanvas: PropTypes.bool,
  setshowDetailsArea: PropTypes.func.isRequired,
  permission: PropTypes.object
};
