import { React, useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaCalendarAlt, FaFileAlt } from 'react-icons/fa';

import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { StoreContext } from 'context/Context';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import VoidConfirmBox from 'module/Common/Warnings/VoidConfirmBox';

function VoidTransactionTab({ transType, transId }) {
  const { store } = useContext(StoreContext);
  const Translate = useAxisproTranslate();
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    trans_no: transId,
    trans_type: transType,
    voided_date: store.currentDate
  });
  const [queryParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const transRef = queryParams.get('reference');
  const transfrom = queryParams.get('from_date');
  const transEnd = queryParams.get('to_date');
  const [formError, setFormError] = useState({});

  const handleShow = () => setShow(!show);

  const handleFieldChange = (e, action) => {
    if (action) {
      setFormData({ ...formData, [action.name]: e.type });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleDelete = e => {
    e.preventDefault();
    setFormError({});
    axios({
      method: 'post',
      url: `settings/void-transaction`,
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');

          let strFilter = '';
          if (transType) {
            strFilter += 'transaction_type=' + transType;
          }
          if (transRef) {
            strFilter += `${transType ? '&' : ''}${'reference=' + transRef}`;
          }
          if (transfrom) {
            strFilter += `${transType || transRef ? '&' : ''}${
              'from_date=' + transfrom
            }`;
          }
          if (transEnd) {
            strFilter += `${transType || transRef || transfrom ? '&' : ''}${
              'to_date=' + transEnd
            }`;
          }
          setFormData({});
          navigate(`/system-admin/void-transactions?${strFilter}`, {
            state: { reload: true },
            replace: true
          });
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .catch(error => {
        if (
          error.response.data.data &&
          error.response.data.data.errors &&
          error.response.data.data.errors[0] &&
          error.response.data.data.errors[0][0]
        ) {
          showToast(error.response.data.data.errors[0][0], 'error');
        } else {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          if (validation_error && Object.keys(validation_error).length > 0) {
            showToast(error.response.data.message, 'error');
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              'error'
            );
          }
        }
      });
    setShow(false);
  };

  return (
    <>
      <Form>
        <Row>
          <Col md={4}>
            <div className="mt-3 fs--1 mb-0">
              <Form.Group className="mb-3" controlId="voidDateControl">
                <Form.Label>
                  {Translate('Void Date')}
                  <FaCalendarAlt className="ms-2" />
                </Form.Label>
                <AppDatePicker
                  name="voided_date"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={handleFieldChange}
                  value={formData ? formData.voided_date : ''}
                  isInvalid={!!formError.voided_date}
                />
                <Form.Control.Feedback type="invalid">
                  {formError.voided_date}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="mt-2 fs--1 mb-0">
              <Form.Group className="mb-3" controlId="formNameControl">
                <Form.Label>
                  {Translate('Notes')}
                  <FaFileAlt className="ms-2" />
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="memo"
                  rows={5}
                  onChange={handleFieldChange}
                  value={formData ? formData.memo : ''}
                  isInvalid={!!formError.memo}
                />
                <Form.Control.Feedback type="invalid">
                  {formError.memo}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Col>
          <Col md={2} className="p-0">
            <Button
              disabled={
                formData &&
                formData.voided_date &&
                formData.voided_date !== null &&
                formData.memo &&
                formData.memo !== ''
                  ? false
                  : true
              }
              variant="danger"
              className="mt-7"
              onClick={handleShow}
              size="sm"
            >
              {Translate('Void')}
            </Button>
          </Col>
        </Row>
      </Form>
      <VoidConfirmBox
        show={show}
        onHide={handleShow}
        handleDelete={handleDelete}
        content="Are you sure you want to void this transaction?"
      />
    </>
  );
}

VoidTransactionTab.propTypes = {
  transId: PropTypes.string,
  transType: PropTypes.string
};

export default VoidTransactionTab;
