import { React } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import SelectCostCenter from 'components/form/SelectCostCenter';

function CostCenterInputField({ formData, formError, handleCostCenter, size }) {
  const Translate = useAxisproTranslate();

  return (
    <Col sm={size?.col1 ?? 12}>
      <Form.Group as={Row} className="mb-3" controlId="payFromConrol">
        <Form.Label column md={size?.col2 ?? 2}>
          {Translate('Cost Center')}
        </Form.Label>
        <Col sm={size?.col3 ?? 3}>
          <SelectCostCenter
            value={formData.cost_center_ref}
            name="cost_center"
            handleFieldChange={handleCostCenter}
            placeholder="Search..."
            style={{ borderRadius: 'none' }}
          />
        </Col>
        <Col
          xl={1}
          md={1}
          sm={1}
          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
        >
          <FormErrorPopover
            id="cost_center"
            totalErrorCount={Object.keys(formError).length}
            errorMessage={formError.cost_center}
          />
        </Col>
      </Form.Group>
    </Col>
  );
}

CostCenterInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleCostCenter: PropTypes.func,
  size: PropTypes.any
};

export default CostCenterInputField;
