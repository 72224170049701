import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

function FooterDetails({ created_at, created_by }) {
  const Translate = useAxisproTranslate();

  return (
    <Card.Footer className="d-flex align-items-center justify-content-between">
      {created_by && (
        <div className="d-flex align-items-center gap-3">
          <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
          <h6 className="m-0">{created_by}</h6>
        </div>
      )}
      {created_at && (
        <div className="d-flex align-items-center gap-3">
          <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
          <h6 className="m-0">{created_at}</h6>
        </div>
      )}
    </Card.Footer>
  );
}

FooterDetails.propTypes = {
  created_at: PropTypes.any,
  created_by: PropTypes.any
};

export default FooterDetails;
