import axios from 'axios';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import PopupFormButton from 'components/popup-form-button/PopupFormButton';
import { warehousePopupFormKeys } from 'helpers/formKeys';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { FaBuilding } from 'react-icons/fa';
import PhoneNumberInput from '../PhoneNumberInput';
import PropTypes from 'prop-types';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const WarehousePopupForm = ({
  callBack,
  setData,
  labelName,
  keyName,
  name,
  showAddButton,
  onHide,
  show
}) => {
  const Translate = useAxisproTranslate();
  const [showWindow, setShowWindow] = useState(show ?? false);
  const [formData, setFormData] = useState(warehousePopupFormKeys);
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const handleShow = () => {
    setShowWindow(!showWindow);
    if (showWindow) {
      setFormData(warehousePopupFormKeys);
      setFormError({});
      setIsSaving(false);
      if (onHide && typeof onHide === 'function') onHide();
    }
  };
  const handleFieldChange = (e, action) =>
    setFormData(generateFormDataHelper(e, action, formData));
  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setIsSaving(true);

    axios({
      method: 'post',
      url: 'settings/warehouses',
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');

          if (callBack) {
            callBack(response.data.data);
          }

          if (response.data.data && setData) {
            setData(
              {
                label: response.data.data[labelName ?? 'location_name'],
                value: response.data.data[keyName ?? 'id']
              },
              {
                action: 'select-option',
                option: undefined,
                name: name ?? 'warehouse_id'
              }
            );
          }

          handleShow();
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setIsSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setIsSaving(false);
      });
  };
  useEffect(() => {
    setShowWindow(show ?? false);
    return () => {
      setFormData(warehousePopupFormKeys);
      setFormError({});
      setShowWindow(false);
      setIsSaving(false);
    };
  }, [show]);

  return (
    <>
      {showAddButton ? (
        <PopupFormButton onClick={handleShow}>
          {Translate('Add Warehouse')}
        </PopupFormButton>
      ) : null}

      {showWindow ? (
        <Modal
          show={showWindow}
          onHide={handleShow}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="d-flex flex-row align-items-center">
                <FaBuilding className="text-success" />
                <span className="ms-1">{Translate('ADD WAREHOUSE')}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(formError).length > 0 && (
                <ErrorAlert formError={formError} setFormError={setFormError} />
              )}
              <Row>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="warehouseLocationName"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Location Name')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <Form.Control
                        type="text"
                        name="location_name"
                        onChange={handleFieldChange}
                        value={formData.location_name}
                      />
                      <FormErrorPopover
                        id="location_name"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.location_name}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="warehouseFax"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Fax')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <Form.Control
                        type="text"
                        name="fax"
                        onChange={handleFieldChange}
                        value={formData.fax}
                      />
                      <FormErrorPopover
                        id="fax"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.fax}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="warehouseContact"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Contact')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <PhoneNumberInput
                        name="phone"
                        onChange={handleFieldChange}
                        value={formData.phone}
                      />
                      <FormErrorPopover
                        id="phone"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.phone}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="warehouseContactPerson"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Contact Person')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <Form.Control
                        type="text"
                        name="contact"
                        onChange={handleFieldChange}
                        value={formData.contact}
                      />
                      <FormErrorPopover
                        id="contact"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.contact}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="warehouseAlternateContact"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Alternate Contact')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <PhoneNumberInput
                        name="phone2"
                        onChange={handleFieldChange}
                        value={formData.phone2}
                      />
                      <FormErrorPopover
                        id="phone2"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.phone2}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="warehouseEmail"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Email')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <Form.Control
                        type="email"
                        name="email"
                        onChange={handleFieldChange}
                        value={formData.email}
                      />
                      <FormErrorPopover
                        id="email"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.email}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="supplierDeliveryAddress"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="m-0 d-flex align-items-center justify-content-start">
                        {Translate('Delivery Address')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="delivery_address"
                        onChange={handleFieldChange}
                        value={formData.delivery_address}
                      />
                      <FormErrorPopover
                        id="delivery_address"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.delivery_address}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-row justify-content-start">
              <Button
                variant="outline-success"
                size="sm"
                className="d-flex align-items-center justify-content-center"
                onClick={handleSubmit}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="success"
                      className="me-1"
                    />
                    <span className="fs--1">{Translate('Saving...')}</span>
                  </>
                ) : (
                  <span>{Translate('Add')}</span>
                )}
              </Button>
              <Button
                variant="outline-danger"
                onClick={handleShow}
                size="sm"
                disabled={isSaving}
              >
                {Translate('Cancel')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

WarehousePopupForm.propTypes = {
  callBack: PropTypes.func,
  setData: PropTypes.func,
  name: PropTypes.string,
  labelName: PropTypes.string,
  keyName: PropTypes.string,
  show: PropTypes.bool,
  showAddButton: PropTypes.bool,
  onHide: PropTypes.func
};

export default WarehousePopupForm;
