import { React } from 'react';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
const MenuItems = ({ selectedItem, searchValue, handleAutocompleteSubmit }) => {
  const Translate = useAxisproTranslate();
  const toggleRef = useRef();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const searchBoxData = async () => {
    setLoading(true);
    let response = await axios.get(`${selectedItem?.url}`, {
      params: {
        [selectedItem.params_name]: searchValue,
        ...(selectedItem.trans && { t: selectedItem.type })
      }
    });
    let data = response && response.data ? response.data.data : [];
    let dataArray = data.map(item => ({
      label: item[selectedItem.label],
      value: item[selectedItem.value]
    }));
    setLoading(false);
    setData(() => [...dataArray]);
  };
  useEffect(() => {
    searchBoxData();
  }, [selectedItem, searchValue]);
  return (
    <>
      {loading ? (
        <Dropdown.Menu className="search-data-menu">
          <Dropdown.Item className="text-center" disabled>
            {Translate('Loading options')}...
          </Dropdown.Item>
        </Dropdown.Menu>
      ) : data[0] ? (
        <Dropdown.Menu
          style={{
            maxHeight: '20rem',
            overflow: 'hidden',
            overflowY: 'scroll'
          }}
          ref={toggleRef}
        >
          {data.map((items, key) => (
            <Dropdown.Item
              style={{ fontWeight: '400', fontSize: '13px' }}
              onClick={() => handleAutocompleteSubmit(items)}
              key={key}
            >
              <Flex alignItems="center">
                <div className="p-1 text-dark">{items.label}</div>
              </Flex>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      ) : (
        <Dropdown.Menu className="search-data-menu">
          <Dropdown.Item className="text-center" disabled>
            {Translate('No Records')}
          </Dropdown.Item>
        </Dropdown.Menu>
      )}
    </>
  );
};
MenuItems.propTypes = {
  selectedItem: PropTypes.any,
  searchValue: PropTypes.string,
  handleAutocompleteSubmit: PropTypes.func
};
export default MenuItems;
