import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';
import { Link, createSearchParams } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';

export default function UserPermissionUserLineItem({ item, active }) {
  return (
    <ListGroup.Item
      as={Link}
      to={`/system-admin/users-permissions?${createSearchParams({
        user_id: item.id,
        user_name: item?.first_name + ' ' + item?.last_name
      })}`}
      className="d-flex active-list-item text-decoration-none"
      style={{ backgroundColor: active ? '#edf2f9' : '#fff' }}
    >
      {(item?.first_name || item?.last_name) && (
        <div className="d-flex flex-row align-items-center py-1">
          <FaUser />
          <div className="ms-2">
            <h6 className="mb-0 fw-bold">
              {item?.first_name + ' ' + item?.last_name}
            </h6>
            <p
              className="mb-0 text-muted"
              style={{
                fontSize: '0.7rem'
              }}
            >
              {item?.email}
            </p>
          </div>
        </div>
      )}
    </ListGroup.Item>
  );
}

UserPermissionUserLineItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool
};
