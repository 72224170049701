import { React } from 'react';
import { Card, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { CancelButton, SaveButton } from '../Buttons/CommonButton';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

function ConfirmWarning({
  show,
  onHide,
  handleSubmit,
  content,
  onSave,
  buttonTitle,
  modalTitle,
  saveButtonVariant,
  cancelButtonVariant
}) {
  const Translate = useAxisproTranslate();

  return (
    <Modal
      show={show}
      size="md"
      backdrop="static"
      className="modal-with-overlay"
    >
      <Card>
        <Card.Header className="text-center pb-0 pt-4">
          <h5 className="fs--1 text-uppercase text-dark fw-bold">
            {Translate(modalTitle ? modalTitle : 'Confirm')}
          </h5>
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center text-center pb-0 pt-0">
          <p className="m-0 fs--1">{Translate(content)}</p>
        </Card.Body>
        <Card.Footer className="d-flex gap-2 align-items-center justify-content-end">
          <SaveButton
            handleSubmit={handleSubmit}
            onSave={onSave}
            createPermission={true}
            editPermission={true}
            buttonTitle={buttonTitle}
            variant={saveButtonVariant}
          />
          <CancelButton
            handleCancel={onHide}
            onSave={onSave}
            variant={cancelButtonVariant}
          />
        </Card.Footer>
      </Card>
    </Modal>
  );
}

ConfirmWarning.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  content: PropTypes.string,
  handleSubmit: PropTypes.func,
  onSave: PropTypes.bool,
  buttonTitle: PropTypes.string,
  modalTitle: PropTypes.string,
  saveButtonVariant: PropTypes.string,
  cancelButtonVariant: PropTypes.string
};

export default ConfirmWarning;
