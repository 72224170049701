import handlePrint from 'helpers/printHelper';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { BsPrinterFill } from 'react-icons/bs';

const PrintButton = ({ url, pageTitle, title, iconSize, ...rest }) => {
  return (
    <Button {...rest} onClick={() => handlePrint(url, pageTitle ?? '')}>
      <BsPrinterFill size={iconSize ?? ''} /> {title ?? ''}
    </Button>
  );
};

PrintButton.propTypes = {
  url: PropTypes.string,
  pageTitle: PropTypes.string,
  title: PropTypes.string,
  iconSize: PropTypes.number
};

export default PrintButton;
