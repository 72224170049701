import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { Form, Spinner } from 'react-bootstrap';

export default function FormSettingsOffcanvasConfigs({ data, handleToggle }) {
  const Translate = useAxisproTranslate();
  return data?.sections?.map((section, sectionIndex) => (
    <Fragment key={'section-' + sectionIndex}>
      <p
        className={classNames('fw-bold text-uppercase text-dark', {
          'mb-3': sectionIndex <= 0,
          'mt-3': sectionIndex > 0
        })}
      >
        {section?.title}
      </p>
      <div className="d-flex flex-column gap-2">
        {section?.data?.map((item, itemIndex) => (
          <Form.Group
            key={`section-${sectionIndex}-item-${itemIndex}`}
            className="d-flex flex-row align-items-center justify-content-between gap-2"
            controlId={`form-settings-switch-input-section-${sectionIndex}-item-${itemIndex}`}
          >
            <Form.Label className="text-secondary">
              {Translate(item?.title)}
            </Form.Label>
            <div
              style={{
                minWidth: '2em'
              }}
              className="d-flex flex-column align-items-center"
            >
              {item?.showIsLoading ? (
                <Spinner
                  animation="border"
                  size="sm"
                  variant="primary"
                  style={{
                    width: 12,
                    height: 12
                  }}
                />
              ) : (
                <Form.Check
                  type="switch"
                  name={`input-${sectionIndex}-${itemIndex}`}
                  checked={item?.show}
                  onChange={handleToggle}
                />
              )}
            </div>
          </Form.Group>
        ))}
      </div>
    </Fragment>
  ));
}

FormSettingsOffcanvasConfigs.propTypes = {
  data: PropTypes.object,
  handleToggle: PropTypes.func
};
