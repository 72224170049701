export const calculateTotalVisibleColumns = (
  defaultColumns,
  tableHeaders,
  settings
) => {
  let visibleColumnsCount = 0;

  if (settings && Object.keys(settings).length > 0) {
    Object.keys(settings).forEach(key => {
      if (tableHeaders[key] === true && settings[key] === true) {
        visibleColumnsCount++;
      }
    });
  }

  const totalVisibleColumns =
    visibleColumnsCount + defaultColumns - Object.keys(tableHeaders)?.length;
  return totalVisibleColumns;
};
