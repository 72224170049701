import { useContext, useEffect } from 'react';
import driverStepsData from '../helpers/driverStepsData';
import { AuthWizardContext } from 'context/Context';
import axios from 'axios';

export default function useDriverHook({ stepKey }) {
  const { user: userData, dispatch } = useContext(AuthWizardContext);
  useEffect(() => {
    function updateUserEnableUserGuide() {
      this.updateUserEnableUserGuide = () => {
        axios
          .post('user/disable-user-guide')
          .then(response => {
            if (response?.data?.success) {
              let updatedUserData = { ...userData, enable_user_guide: false };
              // update localStorage user data
              localStorage.setItem(
                'AXIS_PRO_USER',
                JSON.stringify(updatedUserData)
              );

              // update auth context
              dispatch({
                type: 'UPDATE',
                payload: updatedUserData
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
      };
    }
    const enable = userData?.enable_user_guide;
    if (enable && driverStepsData?.[stepKey]) {
      updateUserEnableUserGuide.apply(driverStepsData);
      driverStepsData[stepKey]();
    }

    return () => {};
  }, [stepKey]);
}
