import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AfterSaveNewButton({ title, onClick, ...rest }) {
  return (
    <Button {...rest} size="sm" title={title} onClick={onClick}>
      <FontAwesomeIcon icon="file" size="sm" /> Create New
    </Button>
  );
}

AfterSaveNewButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func
};

export default AfterSaveNewButton;
