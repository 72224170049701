import { React, useContext, useEffect, useState } from 'react';
import {
  createSearchParams,
  useSearchParams,
  Link,
  useNavigate
} from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { BsCoin } from 'react-icons/bs';
import { AiOutlineFileText } from 'react-icons/ai';
import { TiTick } from 'react-icons/ti';
import { useBreakpoints } from 'hooks/useBreakpoints';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { isDateBeforeToday } from 'helpers/utils';
import { StoreContext } from 'context/Context';
import SalesInvoiceView from './SalesInvoiceView';
import TransactionsData from 'module/Common/Timeline/TransactionsData';
import PaymentScreenPopup from '../Common/PaymentScreenPopup';
import UnUsedCreditsPopup from '../Common/UnUSedCredits/UnUsedCreditsPopup';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import RecurringRules from '../Common/RecurringRules';
import ReferenceCopyToClipBoard from '../Common/ReferenceCopyToClipBoard';
import JournalTable from '../Common/Tables/JournalTable';
import AllocationsTable from '../Common/Tables/AllocationsTable';
import PaymentChequeScreen from '../Common/PaymentChequeScreen';
import FollowUp from '../Common/Tables/FollowUp';
import PDFView from 'components/PDFViewerButton/PDFView';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import Badges from '../Common/ViewPageDetails/Badges';
import PersonName from '../Common/ViewPageDetails/PersonName';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';
import ActivityLog from 'components/activity-log/ActivityLog';
import useTabViewContentReloadHandler from 'hooks/useTabViewContentReloadHandler';
import ApproveButton from 'components/approval-component/button/ApproveButton';
import RejectButton from 'components/approval-component/button/RejectButton';
import BranchToBranch from 'components/inter-branch-transaction/components/BranchToBranch';
import WhatsAppBotButton from 'components/whatsapp-bot/WhatsAppBotButton';

function SalesInvoiceTabView({
  salesInvoiceData,
  loading,
  infoPage,
  onHide,
  fetchData,
  voidScreen,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData,
  interBranch,
  handlePaymentSuccess
}) {
  const { store } = useContext(StoreContext);
  const tabList = ['SI-ACTIVITY-LOG-VIEW-TAB'];
  const TabViewReloadHandler = useTabViewContentReloadHandler({
    activeTabList: tabList
  });
  let navigateTo = useNavigate();
  const Translate = useAxisproTranslate();
  let isEnableWhatsAppBot =
    store?.globalConfiguration?.enable_whatsapp_bot ?? false;
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [unUsedCredits, setUnUsedCredits] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [payment, setPayment] = useState(false);
  const [cheque, setCheque] = useState(false);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [showCreditNote, setShowCreditNoteArea] = useState(false);
  const [creditNoteID, setCreditNoteId] = useState();
  const [person, setPerson] = useState(() => ({
    personType: 'CUSTOMER',
    personId: '',
    name: '',
    value: ''
  }));
  const [upload, setUpload] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [disableCreditNote, setDisableCreditNote] = useState(false);
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    journal: false,
    allocations: false
  });
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const status = queryParams.get('status');
  const permission = {
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    print_si: getAcodaxPermissionSlug('SI', 'print'),
    create_cr: getAcodaxPermissionSlug('CR', 'create'),
    create_si: getAcodaxPermissionSlug('SI', 'create'),
    update_si: getAcodaxPermissionSlug('SI', 'update'),
    edit_si: getAcodaxPermissionSlug('CSI', 'update-created'),
    send_mail_si: getAcodaxPermissionSlug('SI', 'send-mail'),
    create_cn: getAcodaxPermissionSlug('CN', 'create'),
    create_sd: getAcodaxPermissionSlug('SD', 'create'),
    void_si: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };
  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };
  const handleCreditNoteArea = () => {
    setCreditNoteId('');
    setShowCreditNoteArea(false);
  };
  const handelClickOnCreditNote = id => {
    setCreditNoteId(id);
    setShowCreditNoteArea(true);
  };
  useEffect(() => {
    if (!loading) {
      if (
        salesInvoiceData &&
        salesInvoiceData.connected_order &&
        salesInvoiceData.connected_deliveries
      ) {
        let connected = [
          ...salesInvoiceData.connected_order,
          ...salesInvoiceData.connected_deliveries
        ];
        let sorted = connected.sort((a, b) =>
          a.timestamp.localeCompare(b.timestamp)
        );
        setTransactions(sorted);
      }
      setPerson(() => ({
        personType: 'CUSTOMER',
        personId: salesInvoiceData.customer_id,
        name: salesInvoiceData.customer_name,
        value: salesInvoiceData.customer_id
      }));
      setDisableCreditNote(false);
      if (salesInvoiceData.total_qty_credited > 0) {
        setDisableCreditNote(true);
      }
      if (salesInvoiceData?.allocations?.length > 0) {
        if (
          salesInvoiceData.allocations.filter(
            item => item?.type_to_object?.trans_type === 'CN'
          ).length > 0
        ) {
          setDisableCreditNote(true);
        } else {
          setDisableCreditNote(false);
        }
      }
    }
  }, [salesInvoiceData]);

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper form-layer-container">
        {salesInvoiceData.id ? (
          <>
            <Card.Header
              className={
                breakpoints.up('sm')
                  ? 'd-flex m-0 align-items-center border-bottom'
                  : 'border-bottom'
              }
            >
              <>
                <div className="d-flex m-0 align-items-center">
                  <AiOutlineFileText size={28} className="me-2 text-dark" />
                  <h5
                    className="mb-0 d-flex text-uppercase view-page-header"
                    style={{ fontSize: '25px' }}
                  >
                    {Translate(
                      interBranch
                        ? 'Inter Branch Sales Invoice'
                        : 'Sales invoice'
                    )}
                  </h5>
                </div>
                {Math.round(salesInvoiceData.alloc) === 0 ? (
                  <Badges label={`UnPaid`} className="ms-2 fs--1" bg={'dark'} />
                ) : Math.round(salesInvoiceData.alloc) > 0 &&
                  Math.round(salesInvoiceData.alloc) <
                    Math.round(salesInvoiceData.amount) ? (
                  <Badges
                    label={'Partially Paid'}
                    className="ms-2 fs--1"
                    bg={'info'}
                    icon={<TiTick className="ms-1" />}
                  />
                ) : Math.round(salesInvoiceData.amount) ===
                  Math.round(salesInvoiceData.alloc) ? (
                  <Badges
                    label={'Fully Paid'}
                    className="ms-2 fs--1"
                    bg={'success'}
                    icon={<TiTick className="ms-1" />}
                  />
                ) : (
                  ''
                )}
                {Math.round(salesInvoiceData.alloc) === 0 &&
                  isDateBeforeToday(salesInvoiceData?.due_date) && (
                    <Badges
                      label={Translate(`Overdue`)}
                      className="ms-2 fs--1"
                      bg={'danger'}
                    />
                  )}
                {salesInvoiceData.total_qty_sent > 0 &&
                !(
                  salesInvoiceData.total_qty_sent ===
                  salesInvoiceData.total_qty_invoiced
                ) ? (
                  <Badges
                    label={`Partially Delivered`}
                    className="ms-2 fs--1"
                    bg={'dark'}
                  />
                ) : salesInvoiceData.total_qty_sent ===
                  salesInvoiceData.total_qty_invoiced ? (
                  <Badges
                    label={'Fully Delivered'}
                    className="ms-2 fs--1"
                    bg={'warning'}
                    icon={<TiTick className="ms-1" />}
                  />
                ) : (
                  ''
                )}
                {voidTransactionReport && (
                  <Badges
                    label={'Voided'}
                    className="ms-2 fs--1"
                    bg={'danger'}
                  />
                )}
                {!interBranch &&
                salesInvoiceData?.is_inter_branch_transaction ? (
                  <BranchToBranch
                    className={'ms-2'}
                    badgeColor="info"
                    size={15}
                  />
                ) : null}
                {salesInvoiceData &&
                salesInvoiceData.total_qty_credited > 0 &&
                salesInvoiceData.total_qty_credited <
                  salesInvoiceData.total_qty_invoiced ? (
                  <Badges
                    label={'Partially Credited'}
                    className="ms-2 fs--1 text-dark"
                    bg={'light'}
                    icon={<TiTick className="ms-1" />}
                  />
                ) : salesInvoiceData && salesInvoiceData.is_fully_credited ? (
                  <Badges
                    label={'Fully Credited'}
                    className="ms-2 fs--1"
                    bg={'dark'}
                    icon={<TiTick className="ms-1" />}
                  />
                ) : (
                  ''
                )}
                {salesInvoiceData && salesInvoiceData.is_recurring ? (
                  <Badges
                    label={'Recurring'}
                    className="ms-2 fs--1"
                    bg={'success'}
                    icon={<TiTick className="ms-1" />}
                  />
                ) : (
                  ''
                )}
                <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center dropdown-menu-style">
                  {(status &&
                    status !== 'approved-request' &&
                    status !== 'active') ||
                  voidScreen ||
                  offCanvas ? (
                    ''
                  ) : (
                    <MoreButton
                      sendEmail={sendEmail}
                      setUpload={setUpload}
                      setRecurring={setRecurring}
                      recurring={
                        interBranch ||
                        salesInvoiceData?.is_inter_branch_transaction
                          ? false
                          : true
                      }
                      disableRecurring={
                        salesInvoiceData.is_recurring === 1 ||
                        salesInvoiceData.total_qty_credited > 0
                      }
                      setPayment={setPayment}
                      paymentValidations={permission?.create_cr}
                      disablePaymentButton={
                        Math.round(salesInvoiceData.amount) ===
                        Math.round(salesInvoiceData.alloc)
                      }
                      type={'SI'}
                      firstConversionValidation={
                        permission?.create_sd &&
                        !interBranch &&
                        !salesInvoiceData?.is_inter_branch_transaction
                      }
                      disableFirstConversion={
                        salesInvoiceData.is_fully_sent === true
                      }
                      firstConversionTo={
                        '/sales/sales-deliveries/add/si/' + salesInvoiceData.id
                      }
                      afterFirstConversion={Translate('Delivered!')}
                      beforeFirstConversion={Translate(
                        'Convert to Sales Delivery'
                      )}
                      creditNoteValidations={
                        permission?.create_cn &&
                        !interBranch &&
                        !salesInvoiceData?.is_inter_branch_transaction
                      }
                      disableCreditNote={
                        salesInvoiceData.is_fully_credited ||
                        (salesInvoiceData?.is_fully_credited == 0 &&
                          disableCreditNote)
                      }
                      creditNoteTo={
                        '/sales/credit-note/add/' + salesInvoiceData.id
                      }
                      creditNoteButtonName={
                        salesInvoiceData.is_fully_credited
                          ? 'Fully Credited'
                          : salesInvoiceData?.is_fully_credited == 0 &&
                            disableCreditNote
                          ? 'Can`t make Credit Note'
                          : 'Create Credit Note'
                      }
                      emailPermission={permission?.send_mail_si}
                      voidTransactionData={
                        permission?.void_si
                          ? {
                              id: salesInvoiceData?.id,
                              trans_type: 'SI',
                              callBack: () => {
                                navigateTo(
                                  `/sales/sales-invoice/info?${createSearchParams(
                                    {
                                      ...allQueryParams
                                    }
                                  )}`
                                );
                                typeof getData === 'function' && getData(1);
                              }
                            }
                          : undefined
                      }
                    />
                  )}
                  {isEnableWhatsAppBot ? (
                    <WhatsAppBotButton
                      pdfUrl={`sales/invoice/print/${salesInvoiceData.id}`}
                      pdfName={'Sales Invoice Print'}
                      whatsAppNumberToPopulate={
                        salesInvoiceData?.customer?.customer_whatsapp_number ??
                        ''
                      }
                    />
                  ) : null}
                  {(status &&
                    status !== 'approved-request' &&
                    status !== 'active') ||
                  voidScreen ||
                  infoPage ||
                  offCanvas ? (
                    ''
                  ) : permission?.update_si || permission?.edit_si ? (
                    <>
                      <EditButton
                        to={
                          interBranch
                            ? `/sales/inter-branch-transactions/sales-invoice/edit/${
                                salesInvoiceData.id
                              }?${createSearchParams({
                                ...allQueryParams
                              })}`
                            : `/sales/sales-invoice/edit/${
                                salesInvoiceData.id
                              }?${createSearchParams({
                                ...allQueryParams
                              })}`
                        }
                        disable={parseFloat(salesInvoiceData.alloc) > 0}
                      />
                    </>
                  ) : (
                    ''
                  )}
                  {(status &&
                    status !== 'approved-request' &&
                    status !== 'active') ||
                    (!voidTransactionReport && permission?.print_si && (
                      <ViewPagePrintButton
                        variant="danger"
                        className="text-white"
                        style={{ boxShadow: 'none' }}
                        url={`sales/invoice/print/${salesInvoiceData.id}`}
                        pageTitle="Sales invoice print view"
                      />
                    ))}
                  {/* <FormSettingsOffcanvas type="SI" /> */}
                  {infoPage || offCanvas ? (
                    <CloseButton
                      setShowVoucherDetailArea={setShowVoucherDetailArea}
                      onHide={onHide}
                    />
                  ) : (
                    ''
                  )}
                  {salesInvoiceData?.user_actions?.Approve && (
                    <ApproveButton
                      name={salesInvoiceData?.user_actions?.Approve}
                      Id={salesInvoiceData?.approval_event_id}
                      data={salesInvoiceData}
                      getData={getData}
                      info={true}
                      approveRequestTab={interBranch ? false : true}
                    />
                  )}
                  {salesInvoiceData?.user_actions?.Reject && (
                    <RejectButton
                      name={salesInvoiceData?.user_actions?.Reject}
                      Id={salesInvoiceData?.approval_event_id}
                      data={salesInvoiceData}
                      getData={getData}
                      info={true}
                    />
                  )}
                </ButtonGroup>
              </>
            </Card.Header>
            <Card.Body className={loading && 'd-flex'}>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div>
                  {salesInvoiceData?.customer_name ? (
                    <PersonName
                      label={interBranch ? 'Branch Name' : 'Customer Name'}
                      value={salesInvoiceData?.customer_name}
                      view
                      permission={permission?.show_cs}
                      setCustomerDetailsArea={setCustomerDetailsArea}
                    />
                  ) : null}
                  {salesInvoiceData?.trans_date_formatted ? (
                    <MainDetails
                      label={'Transaction Date'}
                      value={salesInvoiceData?.trans_date_formatted}
                    />
                  ) : null}
                </div>
                <div className="view-page-contents d-flex flex-column">
                  {salesInvoiceData?.reference ? (
                    <Reference
                      offCanvas={offCanvas}
                      voidTransactionReport={voidTransactionReport}
                      to={`/sales/sales-invoice/info/${salesInvoiceData?.id}`}
                      value={salesInvoiceData?.reference}
                    />
                  ) : null}
                  {salesInvoiceData?.contract_id ? (
                    <Reference
                      label={'Contract'}
                      offCanvas={offCanvas}
                      voidTransactionReport={voidTransactionReport}
                      to={`/sales/contract/info/${salesInvoiceData?.contract_id}`}
                      value={salesInvoiceData?.contract_reference}
                    />
                  ) : null}
                  <div className="d-flex flex-column gap-1">
                    {salesInvoiceData?.credit_note_allocations?.length > 0 &&
                      !disableCreditNote && (
                        <div className="d-flex gap-1">
                          <h6 className="mb-2 fw-bold">
                            {Translate('Credit Note')}:&nbsp;
                          </h6>
                          <h6
                            className={'mb-1 reference-style'}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              handelClickOnCreditNote(
                                salesInvoiceData?.credit_note_allocations[0].id
                              )
                            }
                          >
                            {salesInvoiceData &&
                            salesInvoiceData?.credit_note_allocations[0]
                              .reference
                              ? salesInvoiceData?.credit_note_allocations[0]
                                  .reference
                              : 'null'}
                          </h6>
                          <ReferenceCopyToClipBoard
                            data={salesInvoiceData?.credit_note_allocations[0]}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <Container className="p-0 view-page-tab-style-wrapper">
                {Math.round(salesInvoiceData.amount) ===
                  Math.round(salesInvoiceData.alloc) ||
                voidScreen ||
                offCanvas ? (
                  ''
                ) : (
                  <div className="view-page-payment-message-wrapper">
                    <Card className="p-3">
                      {salesInvoiceData &&
                      salesInvoiceData.customer &&
                      salesInvoiceData.customer.unused_credits <= 0 ? (
                        ''
                      ) : (
                        <>
                          <div className="d-flex align-items-center gap-2">
                            <BsCoin />
                            <p className="m-0 text-center">
                              {Translate('Unused Credits')}: &nbsp;
                              <span className="fw-bold text-dark">
                                {salesInvoiceData &&
                                salesInvoiceData.customer &&
                                salesInvoiceData.customer
                                  .unused_credits_formatted
                                  ? salesInvoiceData.customer
                                      .unused_credits_formatted
                                  : '0.00'}
                              </span>
                            </p>
                            <Button
                              variant="transparent"
                              style={{ boxShadow: 'none', cursor: 'pointer' }}
                              className="fs--2 p-0 text-primary"
                              onClick={() => setUnUsedCredits(true)}
                            >
                              {Translate('Apply now')}
                            </Button>
                          </div>
                          <hr className="mt-2 mb-2" />
                        </>
                      )}
                      {permission?.create_cr ? (
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex gap-2 align-items-center">
                              <BsCoin size={22} className="text-dark" />
                              <h4 className="m-0 tab-view-heading">
                                {Translate('Receive payment for this invoice!')}
                              </h4>
                            </div>
                            <div>
                              <Button
                                variant="warning"
                                style={{
                                  boxShadow: 'none',
                                  backgroundColor: '#5ec2ff'
                                }}
                                className="fs--2 text-uppercase me-2 border-0"
                                onClick={() => setCheque(true)}
                              >
                                {Translate('Receive Cheque')}
                              </Button>
                              <Button
                                variant="warning"
                                style={{ boxShadow: 'none' }}
                                className="fs--2 text-uppercase"
                                onClick={() => setPayment(true)}
                              >
                                {Translate('Receive payment')}
                              </Button>
                            </div>
                          </div>
                          <div>
                            <p className="m-0">
                              {Translate(
                                'Receive payments for this invoice if advance allocation available or you can directly pay into this invoice!'
                              )}
                            </p>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </Card>
                  </div>
                )}
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={k => setKey(k)}
                  color="red"
                  className="custom-offcanvas-header-tabs"
                >
                  {voidScreen ? (
                    <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                      <SimpleBar className="simplebar-view-page-style">
                        <VoidTransactionTab
                          transType={salesInvoiceData.trans_type}
                          transId={salesInvoiceData.id}
                        />
                      </SimpleBar>
                    </Tab>
                  ) : (
                    ''
                  )}
                  <Tab eventKey="overview" title={Translate('Overview')}>
                    <SimpleBar
                      className={
                        Math.round(salesInvoiceData.amount) ===
                          Math.round(salesInvoiceData.alloc) ||
                        voidScreen ||
                        offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      <SalesInvoiceView salesInvoiceData={salesInvoiceData} />
                    </SimpleBar>
                  </Tab>
                  <Tab eventKey="journal" title={Translate('Journal')}>
                    <SimpleBar
                      className={
                        Math.round(salesInvoiceData.amount) ===
                          Math.round(salesInvoiceData.alloc) ||
                        voidScreen ||
                        offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      <JournalTable
                        id={salesInvoiceData?.id}
                        transType={'SI'}
                        activeTabKey={key}
                        tabApiCallCompleted={tabApiCallCompleted?.journal}
                        setTabApiCallCompleted={setTabApiCallCompleted}
                      />
                    </SimpleBar>
                  </Tab>
                  <Tab eventKey="allocations" title={Translate('Allocations')}>
                    <SimpleBar
                      className={
                        Math.round(salesInvoiceData.amount) ===
                          Math.round(salesInvoiceData.alloc) ||
                        voidScreen ||
                        offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      <AllocationsTable
                        id={salesInvoiceData?.id}
                        t_type={'SI'}
                        activeTabKey={key}
                        tabApiCallCompleted={tabApiCallCompleted?.allocations}
                        setTabApiCallCompleted={setTabApiCallCompleted}
                      />
                    </SimpleBar>
                  </Tab>
                  {transactions && transactions.length > 0 && (
                    <Tab
                      eventKey="transaction"
                      title={Translate('Transactions')}
                    >
                      <SimpleBar
                        className={
                          Math.round(salesInvoiceData.amount) ===
                            Math.round(salesInvoiceData.alloc) ||
                          voidScreen ||
                          offCanvas
                            ? 'simplebar-view-page-style'
                            : 'simplebar-view-page-style-with-container'
                        }
                      >
                        <TransactionsData
                          transaction={transactions}
                          interBranch={interBranch}
                        />
                      </SimpleBar>
                    </Tab>
                  )}
                  <Tab eventKey="followUp" title={Translate('Follow up')}>
                    <SimpleBar
                      className={
                        Math.round(salesInvoiceData.amount) ===
                          Math.round(salesInvoiceData.alloc) ||
                        voidScreen ||
                        offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      {key === 'followUp' && (
                        <FollowUp
                          person={person}
                          invoiceData={salesInvoiceData}
                        />
                      )}
                    </SimpleBar>
                  </Tab>
                  {salesInvoiceData.media &&
                  salesInvoiceData.media.length > 0 ? (
                    <Tab
                      eventKey="attachments"
                      title={Translate('Attachments')}
                    >
                      <SimpleBar
                        className={
                          Math.round(salesInvoiceData.amount) ===
                            Math.round(salesInvoiceData.alloc) ||
                          voidScreen ||
                          offCanvas
                            ? 'simplebar-view-page-style'
                            : 'simplebar-view-page-style-with-container'
                        }
                      >
                        <AttachementsView
                          data={salesInvoiceData}
                          fetchData={fetchData}
                          setKey={setKey}
                        />
                      </SimpleBar>
                    </Tab>
                  ) : (
                    ''
                  )}
                  {salesInvoiceData?.id ? (
                    <Tab eventKey="pdfView" title={Translate('PDF View')}>
                      <SimpleBar
                        className={
                          Math.round(salesInvoiceData.amount) ===
                            Math.round(salesInvoiceData.alloc) ||
                          voidScreen ||
                          offCanvas
                            ? 'simplebar-view-page-style'
                            : 'simplebar-view-page-style-with-container'
                        }
                      >
                        {key === 'pdfView' && (
                          <PDFView
                            viewType="tab"
                            url={`sales/invoice/print/${salesInvoiceData.id}`}
                          />
                        )}
                      </SimpleBar>
                    </Tab>
                  ) : null}
                  <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                    <SimpleBar
                      className={
                        Math.round(salesInvoiceData.amount) ===
                          Math.round(salesInvoiceData.alloc) ||
                        voidScreen ||
                        offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      {key === 'activityLog' && (
                        <ActivityLog subjectID={salesInvoiceData.id} />
                      )}
                    </SimpleBar>
                  </Tab>
                </Tabs>
              </Container>
            </Card.Body>
            <FooterDetails
              created_at={salesInvoiceData?.created_at}
              created_by={salesInvoiceData?.created_user_full_name}
            />
          </>
        ) : (
          <div className="d-flex h-100 align-items-center justify-content-center">
            {permission.create_si && !interBranch && (
              <Button
                className="m-1"
                size="sm"
                as={Link}
                to="/sales/sales-invoice/add"
              >
                {Translate('Add Sales Invoice')}
              </Button>
            )}
          </div>
        )}
      </Card>
      <VoucherDetail
        show={showCreditNote}
        onHide={handleCreditNoteArea}
        setShowVoucherDetailArea={setShowCreditNoteArea}
        type={'CN'}
        voucherId={creditNoteID}
        infoPage={true}
        offCanvas={true}
      />
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={person}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      <UnUsedCreditsPopup
        show={unUsedCredits}
        onHide={() => setUnUsedCredits(false)}
        person={person}
        transType={salesInvoiceData.trans_type}
        transId={salesInvoiceData.voucher_id}
        reference={salesInvoiceData.reference}
        dueAmount={parseFloat(salesInvoiceData.balance)}
        reLoad={() => {
          fetchData();
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
      />
      <PaymentScreenPopup
        show={payment}
        person={person}
        invoiceData={salesInvoiceData}
        onHide={() => setPayment(false)}
        dueAmount={salesInvoiceData.balance}
        fetchData={enableLoading => {
          fetchData(enableLoading);
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
        onPaymentSuccess={handlePaymentSuccess}
      />
      <PaymentChequeScreen
        show={cheque}
        person={person}
        invoiceData={salesInvoiceData}
        onHide={() => setCheque(false)}
        dueAmount={salesInvoiceData.balance}
        fetchData={enableLoading => {
          fetchData(enableLoading);
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
          TabViewReloadHandler.addTab(tabList);
        }}
        onPaymentSuccess={handlePaymentSuccess}
      />
      {salesInvoiceData.id && (
        <FileUpload
          show={upload}
          onHide={() => setUpload(false)}
          fetchData={fetchData}
          type={'SI'}
          id={salesInvoiceData.id}
          setKey={setKey}
        />
      )}
      <SendEmail
        url={'sales/sales-invoice-email/' + salesInvoiceData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={salesInvoiceData.email}
      />
      <RecurringRules
        show={recurring}
        type="SI"
        id={salesInvoiceData.id}
        fetchData={fetchData}
        onHide={() => setRecurring(false)}
      />
    </>
  );
}

SalesInvoiceTabView.propTypes = {
  salesInvoiceData: PropTypes.any,
  loading: PropTypes.bool,
  infoPage: PropTypes.bool,
  onHide: PropTypes.func,
  fetchData: PropTypes.func,
  voidScreen: PropTypes.string,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  interBranch: PropTypes.bool,
  getData: PropTypes.func,
  handlePaymentSuccess: PropTypes.any
};

export default SalesInvoiceTabView;
