import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';
import { Link, createSearchParams } from 'react-router-dom';
import { FaBook } from 'react-icons/fa';

export default function LedgerInquiryLineItem({ item, active }) {
  return (
    <ListGroup.Item
      as={Link}
      to={`/finance/ledger-inquiry?${createSearchParams({
        account_code: item.code,
        account_name: item.name
      })}`}
      className={`d-flex active-list-item text-decoration-none ${
        active ? 'active-three-column-tab-item' : ''
      }`}
      style={{ backgroundColor: active ? '#edf2f9' : '#fff' }}
    >
      {item.name && (
        <div className="d-flex flex-row align-items-center py-1">
          <FaBook />
          <div className="ms-2">
            <h6 className="mb-0 fw-bold">{item.name}</h6>
            <p
              className="mb-0 text-muted"
              style={{
                fontSize: '0.7rem'
              }}
            >
              {item.code}
            </p>
          </div>
        </div>
      )}
    </ListGroup.Item>
  );
}

LedgerInquiryLineItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool
};
