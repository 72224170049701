import React from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import AppPagination from 'components/common/app-pagination/AppPagination';
import PurchaseInvoiceEntryItem from '../Components/PurchaseInvoiceEntryItem';
import AddButton from 'components/add-button/AddButton';
import PurchaseInvoiceInfo from './PurchaseInvoiceInfo';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useKeyDownNavigatorHook from 'hooks/useKeyDownNavigatorHook';
import ClassicThreeColumnLayoutFilter from 'module/Purchase/common/components/ClassicFilter/ClassicThreeColumnLayoutFilter';
import { paymentStatus } from 'module/Common/Enum/enum';

const PurchaseInvoiceColumnVIew = ({
  data,
  setEntriesData,
  getData,
  isFixedAsset,
  interBranch
}) => {
  const Translate = useAxisproTranslate();
  const [permissions] = useOutletContext();
  const [queryParams] = useSearchParams();
  const { entry, ...allQueryParamsWithoutEntryItem } = Object.fromEntries([
    ...queryParams
  ]);
  const pathPreFix = isFixedAsset ? 'fixed-assets' : 'purchase';
  const titlePreFix = isFixedAsset ? 'Fixed asset p' : 'P';
  const filterModuleNamePreFix = isFixedAsset ? 'fixed_asset_' : '';

  const entryId = entry ?? data.data[0].id;
  const { containerRef } = useKeyDownNavigatorHook({
    focusElementClassName: 'active-three-column-tab-item',
    activeKeyName: 'id',
    activeItemRef: entryId,
    dataResult: data?.data,
    lastPageNumber: data?.meta?.last_page,
    to: `/${pathPreFix}/${
      interBranch ? 'inter-branch-transactions/' : ''
    }purchase-invoice`,
    params: [
      {
        paramKey: 'entry',
        dataKey: 'id'
      }
    ]
  });

  return (
    <Row className="flex-fill mb-3">
      <Col sm={12} md={3} className="d-flex pe-md-2 ps-3" ref={containerRef}>
        <Card className="p-0 flex-fill mb-0">
          <Card.Header
            className="p-3 d-flex align-items-center"
            style={{ backgroundColor: 'whitesmoke' }}
          >
            <span className="fs--1 fw-bold text-dark text-uppercase me-auto">
              {interBranch
                ? 'Inter Branch PI'
                : isFixedAsset
                ? Translate('Fixed Asset Purchase List')
                : Translate(titlePreFix + 'urchase Invoice ')}
            </span>

            {permissions?.create && !interBranch ? (
              <AddButton to={`/${pathPreFix}/purchase-invoice/add`} />
            ) : (
              ''
            )}
            <div className="ms-2">
              <ClassicThreeColumnLayoutFilter
                enableDropDown
                dropDownData={paymentStatus}
                moduleName={
                  interBranch
                    ? 'purchase_invoice_inter_branch'
                    : filterModuleNamePreFix + 'purchase_invoice'
                }
              />
            </div>
          </Card.Header>
          <>
            <SimpleBar
              className={
                data &&
                data.meta &&
                data.meta.last_page &&
                data.meta.last_page === 1
                  ? 'simplebar-style-list-page-without-pagination'
                  : 'simplebar-style-list-page'
              }
            >
              {data.data.map((item, index) => (
                <PurchaseInvoiceEntryItem
                  key={index}
                  item={item}
                  active={item.id === entryId ? true : false}
                  isFixedAsset={isFixedAsset}
                  interBranch={interBranch}
                />
              ))}
            </SimpleBar>
            <Card.Footer className="border-top mt-3">
              {data.links && (
                <AppPagination
                  data={data}
                  url={`/${pathPreFix}/${
                    interBranch ? 'inter-branch-transactions/' : ''
                  }purchase-invoice`}
                  search={allQueryParamsWithoutEntryItem}
                />
              )}
            </Card.Footer>
          </>
        </Card>
      </Col>
      <Col sm={12} md={9} className="d-md-flex flex-column ps-sm-3 ps-md-0">
        <PurchaseInvoiceInfo
          purchaseInvoiceId={entryId}
          getData={getData}
          setEntriesData={setEntriesData}
          interBranch={interBranch}
        />
      </Col>
    </Row>
  );
};

PurchaseInvoiceColumnVIew.propTypes = {
  data: PropTypes.any,
  setEntriesData: PropTypes.func,
  isLoading: PropTypes.bool,
  getData: PropTypes.func,
  isFixedAsset: PropTypes.bool,
  interBranch: PropTypes.bool
};

export default PurchaseInvoiceColumnVIew;
