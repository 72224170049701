import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { BsCoin, BsReceipt } from 'react-icons/bs';
import { TiTick } from 'react-icons/ti';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import ReceiptVoucherView from './ReceiptVoucherView';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import ReFundPopup from '../Common/ReFundPopup';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import SupplierDetails from 'components/supplier-details/SupplierDetails';
import UnUsedCreditsPopup from '../Common/UnUSedCredits/UnUsedCreditsPopup';
import PaymentScreenPopup from '../Common/PaymentScreenPopup';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import RecurringRules from '../Common/RecurringRules';
import AllocationsTable from '../Common/Tables/AllocationsTable';
import PDFView from 'components/PDFViewerButton/PDFView';
import PersonName from '../Common/ViewPageDetails/PersonName';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import ActivityLog from 'components/activity-log/ActivityLog';

function ReceiptVoucherTabView({
  receiptVoucherData,
  loading,
  voidScreen,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport
}) {
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [unUsedCredits, setUnUsedCredits] = useState(false);
  const [reFund, setReFund] = useState(false);
  const [payment, setPayment] = useState(false);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [showSupplierDetailsArea, setSupplierDetailsArea] = useState(false);
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    allocations: false
  });
  const permission = {
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    show_sp: getAcodaxPermissionSlug('supplier', 'show'),
    send_email_rv: getAcodaxPermissionSlug('RV', 'send-mail'),
    update_rv: getAcodaxPermissionSlug('RV', 'update'),
    print_rv: getAcodaxPermissionSlug('RV', 'show'),
    create_sp: getAcodaxPermissionSlug('SP', 'create'),
    create_alloc: getAcodaxPermissionSlug('CRA', 'create')
  };
  const [popupData] = useState({
    personId: receiptVoucherData.person_id,
    personType: receiptVoucherData.person_type_id,
    personName: receiptVoucherData.person_name,
    refundAmount: receiptVoucherData.balance,
    reference: receiptVoucherData.reference,
    refundAmountFormatted: receiptVoucherData.balance_formatted,
    transType: receiptVoucherData.trans_type,
    transNo: receiptVoucherData.id,
    value: receiptVoucherData.person_id,
    name: receiptVoucherData.person_name
  });
  const [upload, setUpload] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  const getEmail = () => {
    switch (receiptVoucherData.person_type_id) {
      case 'CUSTOMER':
        return receiptVoucherData.person ? receiptVoucherData.person.email : '';
      case 'SUPPLIER':
        return receiptVoucherData.person
          ? receiptVoucherData.person.email_address
          : '';
      default:
        return '';
    }
  };

  const sendEmail = () => {
    const email = getEmail();
    setEmailAddress(email);
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const handleSupplierDetailsArea = () => {
    setSupplierDetailsArea(!showSupplierDetailsArea);
  };
  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <BsReceipt size={28} className="me-2 text-dark" />
              <h5 className="mb-0 d-flex text-uppercase view-page-header">
                {Translate('Receipt Voucher')}
              </h5>
            </div>
            {receiptVoucherData &&
              receiptVoucherData.custom_voucher &&
              receiptVoucherData.custom_voucher.title && (
                <Badge className="ms-2 fs--1" bg="warning">
                  {receiptVoucherData.custom_voucher.title}
                </Badge>
              )}
            {receiptVoucherData && receiptVoucherData.is_recurring ? (
              <Badge bg="success" className="ms-2 fs--1">
                {Translate('Recurring')} <TiTick />
              </Badge>
            ) : (
              ''
            )}
            {voidTransactionReport && (
              <Badge bg="danger" className="ms-2 fs--1">
                {Translate('Voided')}
              </Badge>
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  setReFund={setReFund}
                  setRecurring={setRecurring}
                  recurring
                  disableRecurring={receiptVoucherData.is_recurring === 1}
                  setPayment={setPayment}
                  refundValiadtions={
                    receiptVoucherData &&
                    receiptVoucherData.person_id &&
                    receiptVoucherData.person_type_id === 'CUSTOMER'
                  }
                  disableRefundButton={
                    Math.round(receiptVoucherData.balance) === 0
                  }
                  paymentValidations={
                    receiptVoucherData &&
                    receiptVoucherData.person_id &&
                    receiptVoucherData.person_type_id === 'SUPPLIER'
                  }
                  disablePaymentButton={
                    Math.abs(receiptVoucherData.alloc) ===
                    Math.abs(receiptVoucherData.amount)
                  }
                  emailPermission={permission.send_email_rv}
                />
              )}
              {voidScreen ||
              (receiptVoucherData && receiptVoucherData.custom_voucher) ||
              offCanvas ? (
                ''
              ) : permission.update_rv ? (
                <EditButton
                  to={`/finance/receipt-voucher/edit/${receiptVoucherData.id}`}
                />
              ) : (
                ''
              )}
              {!voidTransactionReport && permission.print_rv && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`/finance/receipt-voucher/print/${receiptVoucherData.id}`}
                  pageTitle="Receipt voucher print view"
                />
              )}
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div>
              {receiptVoucherData && receiptVoucherData.person_name ? (
                <PersonName
                  label={
                    receiptVoucherData &&
                    receiptVoucherData.person_type_id === 'CUSTOMER'
                      ? Translate('Customer Name')
                      : receiptVoucherData &&
                        receiptVoucherData.person_type_id === 'SUPPLIER'
                      ? Translate('Supplier Name')
                      : Translate('Name')
                  }
                  value={receiptVoucherData.person_name}
                  view={true}
                  permission={
                    receiptVoucherData.person_type_id === 'CUSTOMER'
                      ? permission.show_cs
                      : receiptVoucherData.person_type_id === 'SUPPLIER'
                      ? permission.show_sp
                      : false
                  }
                  setCustomerDetailsArea={
                    receiptVoucherData.person_type_id === 'CUSTOMER'
                      ? setCustomerDetailsArea
                      : receiptVoucherData.person_type_id === 'SUPPLIER'
                      ? setSupplierDetailsArea
                      : null
                  }
                />
              ) : null}
              {receiptVoucherData.trans_date_formatted ? (
                <MainDetails
                  label={'Transaction Date'}
                  value={receiptVoucherData.trans_date_formatted}
                />
              ) : null}
            </div>
            <div className=" view-page-contents d-flex flex-column">
              {receiptVoucherData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/finance/voucher-inquiry?entry=${receiptVoucherData?.id}&layout=column`}
                  value={receiptVoucherData?.reference}
                />
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            {voidScreen || offCanvas ? (
              ''
            ) : receiptVoucherData.person_type_id === 'SUPPLIER' &&
              receiptVoucherData.amount !== receiptVoucherData.alloc ? (
              <div className="view-page-payment-message-wrapper">
                <Card className="p-3">
                  {receiptVoucherData &&
                  receiptVoucherData.person &&
                  receiptVoucherData.person.unused_credits &&
                  receiptVoucherData.person.unused_credits > 0 ? (
                    <>
                      <div className="d-flex align-items-center gap-2">
                        <BsCoin />
                        <p className="m-0 text-center">
                          {Translate('Unused credits')}: &nbsp;
                          <span className="fw-bold text-dark">
                            {receiptVoucherData.person &&
                            receiptVoucherData.person.unused_credits_formatted
                              ? receiptVoucherData.person
                                  .unused_credits_formatted
                              : '0.00'}
                          </span>
                        </p>
                        <Button
                          variant="transparent"
                          style={{ boxShadow: 'none', cursor: 'pointer' }}
                          className="fs--2 p-0 text-primary"
                          onClick={() => setUnUsedCredits(true)}
                        >
                          {Translate('Apply now')}
                        </Button>
                      </div>
                      <hr className="mt-2 mb-2" />
                    </>
                  ) : (
                    ''
                  )}
                  {permission.create_sp ? (
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex gap-2 align-items-center">
                          <BsCoin size={22} className="text-dark" />
                          <h4 className="m-0 tab-view-heading">
                            {Translate('Make payment for this transaction!')}
                          </h4>
                        </div>
                        <div>
                          <Button
                            variant="warning"
                            style={{ boxShadow: 'none' }}
                            className="fs--2 text-uppercase"
                            onClick={() => setPayment(true)}
                          >
                            {Translate('Make payment')}
                          </Button>
                        </div>
                      </div>
                      <div>
                        <p className="m-0">
                          {Translate(
                            'Make payment for this transaction if outstanding transaction available!'
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </Card>
              </div>
            ) : receiptVoucherData.person_type_id === 'CUSTOMER' &&
              receiptVoucherData.amount !== receiptVoucherData.alloc &&
              receiptVoucherData.person &&
              receiptVoucherData.person.total_outstanding &&
              receiptVoucherData.person.total_outstanding !== 0 ? (
              <div className="view-page-payment-message-wrapper">
                <Card className="p-3">
                  <>
                    <div className="d-flex align-items-center gap-2">
                      <BsCoin />
                      <p className="m-0 text-center">
                        {Translate('Un Allocated Amount')}: &nbsp;
                        <span className="fw-bold text-dark">
                          {receiptVoucherData.balance_formatted
                            ? receiptVoucherData.balance_formatted
                            : '0.00'}
                        </span>
                      </p>
                    </div>
                    <hr className="mt-2 mb-2" />
                  </>
                  {permission.create_alloc ? (
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex gap-2 align-items-center">
                          <BsCoin size={22} className="text-dark" />
                          <h4 className="m-0 tab-view-heading">
                            {Translate(
                              'Make allocations for this transaction!'
                            )}
                          </h4>
                        </div>
                        <div>
                          <Button
                            variant="warning"
                            style={{ boxShadow: 'none' }}
                            className="fs--2 text-uppercase"
                            as={Link}
                            to={`/sales/customer-allocation/allocate/${receiptVoucherData.id}`}
                          >
                            {Translate('Make allocation')}
                          </Button>
                        </div>
                      </div>
                      <div>
                        <p className="m-0">
                          {Translate(
                            'Make allocations for this transaction if outstanding transaction available!'
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </Card>
              </div>
            ) : (
              ''
            )}
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={receiptVoucherData.trans_type}
                      transId={receiptVoucherData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar
                  className={
                    (receiptVoucherData.person_type_id === 'SUPPLIER' &&
                      receiptVoucherData.amount !== receiptVoucherData.alloc) ||
                    voidScreen ||
                    offCanvas ||
                    (receiptVoucherData.person_type_id === 'CUSTOMER' &&
                      receiptVoucherData.amount !== receiptVoucherData.alloc &&
                      receiptVoucherData.person &&
                      receiptVoucherData.person.total_outstanding &&
                      receiptVoucherData.person.total_outstanding !== 0)
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <ReceiptVoucherView receiptVoucherData={receiptVoucherData} />
                </SimpleBar>
              </Tab>
              <Tab eventKey="allocations" title={Translate('Allocations')}>
                <SimpleBar
                  className={
                    (receiptVoucherData.person_type_id === 'SUPPLIER' &&
                      receiptVoucherData.amount !== receiptVoucherData.alloc) ||
                    voidScreen ||
                    offCanvas ||
                    (receiptVoucherData.person_type_id === 'CUSTOMER' &&
                      receiptVoucherData.amount !== receiptVoucherData.alloc &&
                      receiptVoucherData.person &&
                      receiptVoucherData.person.total_outstanding &&
                      receiptVoucherData.person.total_outstanding !== 0)
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <AllocationsTable
                    id={receiptVoucherData?.id}
                    t_type={'RV'}
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.allocations}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                  />
                </SimpleBar>
              </Tab>
              {receiptVoucherData.media &&
              receiptVoucherData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar
                    className={
                      (receiptVoucherData.person_type_id === 'SUPPLIER' &&
                        receiptVoucherData.amount !==
                          receiptVoucherData.alloc) ||
                      voidScreen ||
                      offCanvas ||
                      (receiptVoucherData.person_type_id === 'CUSTOMER' &&
                        receiptVoucherData.amount !==
                          receiptVoucherData.alloc &&
                        receiptVoucherData.person &&
                        receiptVoucherData.person.total_outstanding &&
                        receiptVoucherData.person.total_outstanding !== 0)
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    <AttachementsView
                      data={receiptVoucherData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {receiptVoucherData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`/finance/receipt-voucher/print/${receiptVoucherData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
              {receiptVoucherData?.id ? (
                <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                  <SimpleBar
                    className={
                      voidScreen || offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    {key === 'activityLog' && (
                      <ActivityLog subjectID={receiptVoucherData?.id} />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {receiptVoucherData && receiptVoucherData.created_user_full_name
                ? receiptVoucherData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {receiptVoucherData && receiptVoucherData.created_at
                ? receiptVoucherData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={popupData}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      <SupplierDetails
        show={showSupplierDetailsArea}
        onHide={handleSupplierDetailsArea}
        value={popupData}
        showSupplierDetailsArea={showSupplierDetailsArea}
      />
      <ReFundPopup
        show={reFund}
        popupData={popupData}
        fetchData={() => {
          fetchData();
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
        onHide={() => setReFund(false)}
      />
      <UnUsedCreditsPopup
        show={unUsedCredits}
        onHide={() => setUnUsedCredits(false)}
        person={popupData}
        transType={receiptVoucherData.trans_type}
        transId={receiptVoucherData.id}
        reference={receiptVoucherData.reference}
        dueAmount={receiptVoucherData.amount - receiptVoucherData.alloc}
        reLoad={() => {
          fetchData();
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
      />
      <PaymentScreenPopup
        show={payment}
        person={popupData}
        invoiceData={receiptVoucherData}
        onHide={() => setPayment(false)}
        fetchData={() => {
          fetchData();
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
        dueAmount={receiptVoucherData.amount - receiptVoucherData.alloc}
      />
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'RV'}
        id={receiptVoucherData.id}
        setKey={setKey}
      />
      <SendEmail
        url={'finance/receipt-voucher/send-email/' + receiptVoucherData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={emailAddress}
      />
      <RecurringRules
        show={recurring}
        type="RV"
        id={receiptVoucherData.id}
        onHide={() => setRecurring(false)}
        fetchData={fetchData}
      />
    </>
  );
}

ReceiptVoucherTabView.propTypes = {
  receiptVoucherData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool
};

export default ReceiptVoucherTabView;
