import { showToast } from 'module/Common/Toast/toast';

export const bankTransferValidators = formData => {
  if (!formData.from_bank_account_id) {
    showToast('Payment from bank account must be selected!', 'error');
    return false;
  } else if (!formData.to_bank_account_id) {
    showToast('Payment to bank account must be selected!', 'error');
    return false;
  } else if (!formData.amount) {
    showToast('Transferring amount must be entered!', 'error');
    return false;
  } else if (!formData.trans_date) {
    showToast('Transaction date must be selected!', 'error');
    return false;
  } else {
    return true;
  }
};
