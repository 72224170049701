import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { FaBuilding, FaDollarSign, FaLock, FaUser } from 'react-icons/fa';
import { useNavigate, useOutletContext } from 'react-router-dom';
import DetailsSectionQuickEditButton from './DetailsSectionQuickEditButton';
import LogoPreview from './LogoPreview';

const InstallationDetailsPreview = () => {
  let [formData, handleTab] = useOutletContext();
  let navigate = useNavigate();

  formData = formData ?? {};
  const hashPassword = length =>
    Array.from(Array(length).keys()).map(() => '*');
  const handleClose = () => {
    navigate('/install');
  };

  const handleEdit = tab => {
    handleTab(tab);
    handleClose();
  };
  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="p-0">
        <Table responsive>
          <tbody>
            <tr>
              <th colSpan={2} className="border-0">
                <div className="d-flex flex-row align-items-center">
                  <FaBuilding className="me-1 text-success" size={15} />
                  <p
                    className="mb-0 fw-bold text-dark"
                    style={{ fontSize: '14px' }}
                  >
                    Company Details
                  </p>
                </div>
              </th>
              <td className="border-0 text-end">
                <DetailsSectionQuickEditButton
                  title="Edit Company Details"
                  onClick={() => handleEdit('company')}
                />
              </td>
            </tr>
            <tr>
              <th colSpan={2}>Company Logo</th>
              <td>
                {formData.company_logo ? (
                  <div style={{ width: '100%', maxWidth: '350px' }}>
                    <LogoPreview logo={formData.company_logo} imageOnly />
                  </div>
                ) : null}
              </td>
            </tr>
            <tr>
              <th colSpan={2}>Company Name</th>
              <td>{formData.company_name}</td>
            </tr>
            <tr>
              <th colSpan={2}>Type of business</th>
              <td>{formData.business_type}</td>
            </tr>

            <tr>
              <th colSpan={2}>Base Currency</th>
              <td>
                {formData.base_currency_ref
                  ? formData.base_currency_ref.label
                  : null}
              </td>
            </tr>

            <tr>
              <th colSpan={2}>No. of Employees</th>
              <td>{formData.no_of_employees}</td>
            </tr>

            <tr>
              <th colSpan={2}>Place</th>
              <td>{formData.place}</td>
            </tr>

            <tr>
              <th colSpan={2}>Address</th>
              <td style={{ whiteSpace: 'pre-line' }}>{formData.address}</td>
            </tr>

            <tr>
              <th colSpan={2}>Company Email</th>
              <td>{formData.company_email}</td>
            </tr>

            <tr>
              <th colSpan={2}>Company Phone</th>
              <td>{formData.company_phone}</td>
            </tr>

            <tr>
              <th colSpan={2} className="border-0">
                <div className="d-flex flex-row align-items-center ">
                  <FaUser className="me-1 text-success" size={15} />
                  <p
                    className="mb-0 fw-bold text-dark"
                    style={{ fontSize: '14px' }}
                  >
                    User Details
                  </p>
                </div>
              </th>
              <td className="border-0 text-end">
                <DetailsSectionQuickEditButton
                  title="Edit User Details"
                  onClick={() => handleEdit('user')}
                />
              </td>
            </tr>

            <tr>
              <th colSpan={2}>First Name</th>
              <td>{formData.first_name}</td>
            </tr>
            <tr>
              <th colSpan={2}>Last Name</th>
              <td>{formData.last_name}</td>
            </tr>
            <tr>
              <th colSpan={2}>Email</th>
              <td>{formData.user_email}</td>
            </tr>
            <tr>
              <th colSpan={2}>Mobile</th>
              <td>{formData.user_phone}</td>
            </tr>

            <tr>
              <th colSpan={2} className="border-0">
                <div className="d-flex flex-row align-items-center">
                  <FaLock className="me-1 text-success" size={15} />
                  <p
                    className="mb-0 fw-bold text-dark"
                    style={{ fontSize: '14px' }}
                  >
                    Account Details
                  </p>
                </div>
              </th>
              <td className="border-0 text-end">
                <DetailsSectionQuickEditButton
                  title="Edit Account Details"
                  onClick={() => handleEdit('account')}
                />
              </td>
            </tr>
            <tr>
              <th colSpan={2}>Username</th>
              <td>{formData.username}</td>
            </tr>
            <tr>
              <th colSpan={2}>Password</th>
              <td>
                {hashPassword(formData.password ? formData.password.length : 0)}
              </td>
            </tr>
            <tr>
              <th colSpan={2}>Confirm Password</th>
              <td>
                {hashPassword(
                  formData.c_password ? formData.c_password.length : 0
                )}
              </td>
            </tr>

            <tr>
              <th colSpan={2} className="border-0">
                <div className="d-flex flex-row align-items-center">
                  <FaDollarSign className="me-1 text-success" size={15} />
                  <p
                    className="mb-0 fw-bold text-dark"
                    style={{ fontSize: '14px' }}
                  >
                    Tax Details
                  </p>
                </div>
              </th>
              <td className="border-0 text-end">
                <DetailsSectionQuickEditButton
                  title="Edit Tax Details"
                  onClick={() => handleEdit('tax')}
                />
              </td>
            </tr>
            <tr>
              <th colSpan={2}>TRN No.</th>
              <td>{formData.trn_no}</td>
            </tr>
            <tr>
              <th colSpan={2} className="border-0">
                Tax Group
              </th>
              <td className="border-0">
                {formData.tax_group_ref ? formData.tax_group_ref.label : null}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default InstallationDetailsPreview;
