import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';

import { FcNoIdea } from 'react-icons/fc';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SoftBadge from 'components/common/SoftBadge';
import Loader from '../Common/Loader';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { chequeSummary } from '../Common/LoaderData';

function ChequeSummary({ draggable }) {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get('finance/cheques-summary')
      .then(res => {
        if (res?.data?.success) {
          setData(res.data.data);
        }
        setIsLoading(false);
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card className={`h-100 ${draggable && 'dashboard_shake'} `}>
      <FalconCardHeader
        title={Translate('Cheque Summary')}
        light={false}
        titleTag="h6"
        className="pb-0 tile-heading"
      />
      {!isLoading ? (
        <>
          {data && data !== '' ? (
            <Card.Body className="p-0 cheque-summary">
              <div className="fw-semi-bold fs--2">
                <div className="d-flex align-items-center justify-content-center gap-3 mt-2">
                  <SoftBadge bg="warning" className="w-25 p-2 rounded-3 ">
                    <p className="m-0 fs--1 mb-1 count">
                      {data?.cheque_pending_count}
                    </p>
                    <p className="m-0 name">{Translate('Total Pending')}</p>
                  </SoftBadge>
                  <SoftBadge bg="success" className="w-25 p-2 rounded-3">
                    <p className="m-0 fs--1 mb-1 count">
                      {data?.cheque_cleared_count}
                    </p>
                    <p className="m-0 name">{Translate('Total Cleared')}</p>
                  </SoftBadge>
                  <SoftBadge bg="danger" className="w-25 p-2 rounded-3">
                    <p className="m-0 fs--1 mb-1 count">
                      {data?.cheque_bounced_count}
                    </p>
                    <p className="m-0 name">{Translate('Total Bounced')}</p>
                  </SoftBadge>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-3 mt-2">
                  <SoftBadge bg="dark" className="w-25 p-2 rounded-3">
                    <p className="m-0 fs--1 mb-1 count">
                      {data?.cheque_due_today_count}
                    </p>
                    <p className="m-0 name">{Translate('Due Today')}</p>
                  </SoftBadge>
                  <SoftBadge bg="primary" className="w-25 p-2 rounded-3">
                    <p className="m-0 fs--1 mb-1 count">
                      {data?.cheque_due_this_week_count}
                    </p>
                    <p className="m-0 name">{Translate('Due This Week')}</p>
                  </SoftBadge>
                </div>
              </div>
            </Card.Body>
          ) : (
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <FcNoIdea size={30} />
              {Translate('No Data Available')}
            </Card.Body>
          )}
        </>
      ) : (
        <Loader loader={chequeSummary} className={'ps-4'} />
      )}
    </Card>
  );
}
ChequeSummary.propTypes = {
  draggable: PropTypes.any
};
export default ChequeSummary;
