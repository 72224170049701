export const handleDeliveryDate = (
  initialDate,
  setFromData,
  dataKey = 'delivery_date'
) => {
  const getFormattedDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  let deliveryDate = new Date(initialDate);
  deliveryDate.setDate(deliveryDate.getDate() + 14);
  const formattedDate = getFormattedDate(deliveryDate);
  setFromData(prev => ({
    ...prev,
    [dataKey]: formattedDate ?? ''
  }));
};
