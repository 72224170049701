import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import axios from 'axios';
// Need to remove this file. its using chat module
const SelectMultipleUser = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  groupDetail,
  selectRef
}) => {
  const [loading, setloading] = useState(false);
  const [optionsArray, setoptionsArray] = useState([]);

  useEffect(() => {
    async function initFunc() {
      setloading(true);
      let response = await axios.get('user/users-autocomplete', {
        params: {
          exclude_logged_user: true
        }
      });
      let data = response && response.data ? response.data.data : [];

      if (groupDetail && groupDetail.users && groupDetail.users.length > 0) {
        data = data.filter(
          obj1 => !groupDetail.users.some(obj2 => obj2.id === obj1.id)
        );
      }

      setoptionsArray(
        data.map(item => ({
          label: item.first_name,
          value: item.id
        }))
      );
      setloading(false);
    }

    initFunc();
  }, [groupDetail]);

  return (
    <Select
      isClearable
      isMulti
      isLoading={loading}
      placeholder={placeholder ?? ''}
      options={optionsArray}
      value={value}
      onChange={handleFieldChange}
      ref={selectRef}
      name={name ? name : 'user'}
      className={error ? 'ps-0 py-0 ' : 'app-react-select'}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({
          ...provided,
          height: 'auto',
          borderRadius: '0px',
          minHeight: 32,
          border: '1px solid #b5b5b5',
          ...style
        })
      }}
    />
  );
};

SelectMultipleUser.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  groupDetail: PropTypes.any,
  selectRef: PropTypes.any
};

export default SelectMultipleUser;
