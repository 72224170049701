import { React, useRef } from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';

const TextEditor = ({
  name,
  handleFieldChange,
  value,
  isInvalid,
  placeholder,
  HideMedia,
  SI,
  Height,
  onFocus,
  notesEditor,
  className = ''
}) => {
  const refEditor = useRef();
  const focusTextEditor = () => {
    refEditor.current.focus();
  };
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // Toggle buttons for text styles
      [{ header: 1 }, { header: 2 }], // Custom buttons for different headers
      [{ list: 'ordered' }, { list: 'bullet' }], // Ordered and unordered lists
      [{ script: 'sub' }, { script: 'super' }], // Superscript and subscript
      [{ indent: '-1' }, { indent: '+1' }], // Outdent and indent
      [{ direction: 'rtl' }], // Text direction
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // Text and background color
      [{ font: [] }], // Font selection
      [{ align: [] }], // Text alignment
      ['clean'], // Remove formatting button
      ...(HideMedia
        ? [] // If HideMedia is true, include an empty array (no media options)
        : [['link', 'image', 'video']]) // If HideMedia is false, include media options
    ]
  };

  return (
    <div onClick={focusTextEditor} className="h-100">
      <ReactQuill
        ref={refEditor}
        theme="snow"
        placeholder={placeholder ? placeholder : ''}
        name={name ?? 'text-editor'}
        value={value}
        onFocus={onFocus}
        onChange={value => {
          handleFieldChange(null, {
            action: 'text-editor',
            name: name,
            value: value
          });
        }}
        modules={modules}
        className={`custom-text-editor d-flex flex-column ${
          isInvalid ? 'is-invalid' : ''
        } ${
          SI
            ? `${
                notesEditor
                  ? 'si-custom-notes-editor'
                  : 'si-custom-terms-editor'
              }`
            : ''
        } ${className}`}
        style={{
          ...(!Height ? { minHeight: SI ? '243px' : '200px' } : {}),
          borderRadius: '0px',
          ...(isInvalid ? { border: '1px solid #dc3545' } : {}),
          ...(Height ? { height: `${Height}` } : {})
        }}
      />
    </div>
  );
};

TextEditor.propTypes = {
  name: PropTypes.string,
  handleFieldChange: PropTypes.any,
  value: PropTypes.any,
  isInvalid: PropTypes.any,
  placeholder: PropTypes.string,
  HideMedia: PropTypes.bool,
  SI: PropTypes.bool,
  Height: PropTypes.string,
  onFocus: PropTypes.func,
  notesEditor: PropTypes.bool,
  className: PropTypes.string
};

export default TextEditor;
