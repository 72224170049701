import { AuthWizardContext } from 'context/Context';
import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const RequireAuth = ({ children }) => {
  const location = useLocation();
  let { user } = useContext(AuthWizardContext);
  return !user ? (
    <Navigate
      to={`/authentication/login?redirectTo=${encodeURI(
        location.pathname + location.search
      )}`}
    />
  ) : user?.password_expired ? (
    <Navigate to={'/password-expiry'} />
  ) : (
    children
  );
};

RequireAuth.propTypes = {
  children: PropTypes.element.isRequired
};

export default RequireAuth;
