import generateProposalTableHelper from 'helpers/generateProposalTableHelper';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { FaCalendar, FaIdCard, FaTable } from 'react-icons/fa6';

export default function ProposalDynamicContents({ ...rest }) {
  const { quillClassRef, data } = rest;
  const Translate = useAxisproTranslate();

  // Function to paste HTML content
  function pasteHtmlContent(event) {
    const button = event?.currentTarget;
    const method = button?.getAttribute('btn-method');
    const availableMethods = [
      'CustomerDetails',
      'TransactionDate',
      'TableData'
    ];
    if (!availableMethods.includes(method)) return;
    let htmlContent;

    switch (method) {
      case 'TableData':
        htmlContent = generateProposalTableHelper({
          type: 'sq',
          data: data
        });
        break;

      case 'TransactionDate':
        htmlContent = `<p class="ql-direction-dmContentTable"><strong>${
          data?.trans_date_formatted || '-'
        }</strong></p>`;
        break;

      case 'CustomerDetails':
        htmlContent = `<blockquote class="ql-direction-dmContentUserName"><strong class="ql-size-large">${
          data?.customer_name
        }</strong></blockquote>
                         ${
                           data?.email
                             ? `<blockquote class="ql-direction-dmContentUserMail"><strong style="color: rgb(136, 136, 136);">${data?.email}</strong></blockquote>`
                             : ''
                         }
                         ${
                           data?.mobile
                             ? `<blockquote class="ql-direction-dmContentUserPhone"><strong style="color: rgb(136, 136, 136);">${data?.mobile}</strong></blockquote>`
                             : ''
                         }`;
        break;

      default:
        htmlContent = '';
        break;
    }

    const range = quillClassRef.current.getSelection();

    if (range) {
      // Use dangerouslyPasteHTML to insert HTML content
      quillClassRef.current.clipboard.dangerouslyPasteHTML(
        range.index,
        htmlContent
      );
    } else {
      alert('Please select an area in the editor to paste the content.');
    }
  }

  return (
    <div className="border shadow-sm">
      <div className="bg-dark text-light p-2 text-uppercase fw-bold">
        {Translate('PDF Dynamic Contents')}
      </div>
      <div className="d-flex flex-column gap-2 px-2 py-3">
        <Button
          type="button"
          variant="transparent"
          size="sm"
          className="dm-pr-dynamic-btn-item bg-light border p-2 rounded d-flex flex-row align-items-center gap-2 shadow-none"
          btn-method="CustomerDetails"
          onClick={pasteHtmlContent}
        >
          <FaIdCard size={11} />
          <span className="fw-bold">{Translate('Customer Details')}</span>
          <FaPlusCircle size={11} className="ms-auto" />
        </Button>
        <Button
          type="button"
          variant="transparent"
          size="sm"
          className="dm-pr-dynamic-btn-item bg-light border p-2 rounded d-flex flex-row align-items-center gap-2 shadow-none"
          btn-method="TransactionDate"
          onClick={pasteHtmlContent}
        >
          <FaCalendar size={11} />
          <span className="fw-bold">{Translate('Transaction Date')}</span>
          <FaPlusCircle size={11} className="ms-auto" />
        </Button>
        <Button
          type="button"
          variant="transparent"
          size="sm"
          className="dm-pr-dynamic-btn-item bg-light border p-2 rounded d-flex flex-row align-items-center gap-2 shadow-none"
          btn-method="TableData"
          onClick={pasteHtmlContent}
        >
          <FaTable size={11} />
          <span className="fw-bold">{Translate('Table Data')}</span>
          <FaPlusCircle size={11} className="ms-auto" />
        </Button>
      </div>
    </div>
  );
}
