import { React } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
const CommonSelect = ({
  name,
  value,
  onChange,
  Options,
  style,
  placeholder,
  error,
  onFocus,
  onBlur,
  option,
  HideCloseButton
}) => {
  const optionsArray =
    Options?.length > 0
      ? Options.map(item => ({ value: item, label: item }))
      : [];
  return (
    <Select
      isClearable={HideCloseButton ? false : true}
      value={value}
      placeholder={placeholder ?? ''}
      name={name}
      classNamePrefix="app-react-select"
      className={`w-100  ${error && 'is-invalid'}`}
      onFocus={onFocus}
      onBlur={onBlur}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({
          ...provided,
          zIndex: 9999,
          fontSize: '13px',
          borderRadius: '0px'
        }),
        control: provided => ({
          ...provided,
          ...style
        })
      }}
      onChange={onChange}
      options={option ? option : optionsArray}
    />
  );
};
CommonSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  style: PropTypes.any,
  placeholder: PropTypes.string,
  error: PropTypes.any,
  Options: PropTypes.array,
  option: PropTypes.array,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  HideCloseButton: PropTypes.bool
};
export default CommonSelect;
