import { React, useEffect, useState, useContext } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import axios from 'axios';
import { Alert, Container, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { StoreContext } from 'context/Context';
import { showToast } from '../../../Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import LedgerReportOffcanvas from 'module/Common/LedgerOffcanvas/LedgerReportOffcanvas';
import ShowMemo from '../ViewPageDetails/ShowMemo';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';

function JournalTable({
  journalData,
  voucherData,
  bt,
  jv,
  id,
  transType,
  tabApiCallCompleted,
  setTabApiCallCompleted,
  activeTabKey
}) {
  const Translate = useAxisproTranslate();
  const navigate = useNavigate();
  const { store } = useContext(StoreContext);
  const [debitSum, setDebitSum] = useState([]);
  const [creditSum, setCreditSum] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const allQueryParams = Object.fromEntries([...queryParams]);
  let toDate = store.currentDate;
  const thirtyDaysAgo = new Date(toDate);
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const showFixedAssets =
    store?.globalConfiguration?.enable_fixed_assets === '1' ? true : false;
  useEffect(() => {
    if (data && data.gl_transactions && data.gl_transactions.length > 0) {
      let debit = data.gl_transactions.filter(item => item.amount > 0);
      let credit = data.gl_transactions.filter(item => item.amount < 0);
      let debitAmount = debit.map(item => item.amount);
      let creditAmount = credit.map(item => item.amount);
      if (debitAmount.length !== 0) {
        let totalDebit = debitAmount.reduce((a, b) => a + b);
        setDebitSum(totalDebit);
      }
      if (creditAmount.length !== 0) {
        let totalCredit = creditAmount.reduce((a, b) => a + b);
        setCreditSum(totalCredit);
      }
    }
  }, [data]);

  const fetchJornalData = () => {
    setLoading(true);
    axios
      .get(`finance/gl-transactions`, {
        params: { trans_no: id, trans_type: transType }
      })
      .then(res => {
        if (res?.data?.success) {
          setData({
            gl_transactions: res?.data?.data
          });
        } else {
          showToast('Something went wrong! Please contact admin', 'error');
        }
      })
      .catch(error => {
        if (error) {
          showToast('Something went wrong! Please contact admin', 'error');
        }
      })
      .finally(() => {
        setLoading(false);
        setTabApiCallCompleted(prev => ({
          ...prev,
          journal: true
        }));
      });
  };

  useEffect(() => {
    if (id) {
      if (activeTabKey !== 'journal' || tabApiCallCompleted) return;
      fetchJornalData();
    } else {
      if (journalData) {
        setData(journalData);
        setLoading(false);
      }
    }
  }, [activeTabKey, tabApiCallCompleted, journalData]);

  return (
    <Container className="mt-1 pe-0 ps-0 voucher-inquiry-view-wrapper">
      {data && (
        <Table
          striped
          bordered
          hover
          responsive
          className="simple-table-animation"
        >
          {!loading ? (
            <>
              <thead className="bg-dark text-light text-uppercase">
                <tr>
                  <th className="fs--2 p-2 text-center">#</th>
                  <th className="fs--2 p-2 pe-0">
                    {Translate('Account Code')}
                  </th>
                  {voucherData ? (
                    <th className="fs--2 p-2 pe-0">
                      {Translate('Sub Ledger')}
                    </th>
                  ) : (
                    ''
                  )}
                  {showFixedAssets ? (
                    <th className="fs--2 p-2 pe-0">
                      {Translate('Fixed Assets')}
                    </th>
                  ) : (
                    ''
                  )}
                  {voucherData || bt ? (
                    <th className="fs--2 p-2 pe-0">
                      {Translate('Cost Center')}
                    </th>
                  ) : (
                    ''
                  )}
                  <th className="fs--2 p-2 pe-0">{Translate('Debit')}</th>
                  <th className="fs--2 p-2 pe-0">{Translate('Credit')}</th>
                  {bt ? (
                    ''
                  ) : (
                    <th className="fs--2 p-2 pe-0">
                      {Translate('Description')}
                    </th>
                  )}
                </tr>
              </thead>
              {data && data.gl_transactions?.length > 0 ? (
                <>
                  {data.gl_transactions.map((item, index) => (
                    <tbody key={index} className="text-dark">
                      <tr>
                        <td className="p-2 text-center">{index + 1}</td>
                        <td className="p-2">
                          <a
                            className="text-primary over-underline cursor-pointer"
                            onClick={() => {
                              navigate(
                                `?${createSearchParams({
                                  ...allQueryParams,
                                  account_code: item.account_code,
                                  date_from: '2000-01-01',
                                  date_to: toDate,
                                  ledger_offcanvas: true,
                                  account_code_ref: JSON.stringify({
                                    label:
                                      item.account_code +
                                      ' - ' +
                                      item.account_name,
                                    value: item.account_code
                                  })
                                })}`
                              );
                            }}
                          >
                            {' '}
                            {item.account_code + ' - ' + item.account_name}
                          </a>
                        </td>
                        {voucherData ? (
                          <td className="p-2">
                            {item.subledger_code
                              ? item.subledger_code +
                                ' - ' +
                                item.subledger_name
                              : '-'}
                          </td>
                        ) : (
                          ''
                        )}
                        {showFixedAssets ? (
                          <td className="p-2">
                            {item.fixed_asset_name
                              ? item.fixed_asset_name
                              : '-'}
                          </td>
                        ) : (
                          ''
                        )}
                        {voucherData || bt ? (
                          <td className="p-2">{item.cost_center_name}</td>
                        ) : (
                          ''
                        )}
                        <td className="p-2 text-end">
                          {item.amount > 0
                            ? acodaxNumberFormat(item.amount)
                            : '-'}
                        </td>
                        <td className="p-2 text-end">
                          {item.amount < 0
                            ? acodaxNumberFormat(Math.abs(item.amount))
                            : '-'}
                        </td>
                        {bt ? '' : <td className="p-2">{item.description}</td>}
                      </tr>
                    </tbody>
                  ))}
                  <tfoot>
                    <tr className="text-dark">
                      <td></td>
                      {bt ? <td></td> : ''}
                      {voucherData ? (
                        <>
                          <td></td>
                          <td></td>
                        </>
                      ) : (
                        ''
                      )}
                      {showFixedAssets && <td></td>}
                      <td className="p-2 fw-bold text-end">
                        {Translate('Total')}
                      </td>
                      <td className="p-2 fw-bold text-end">
                        {acodaxNumberFormat(debitSum)}
                      </td>
                      <td className="p-2 fw-bold text-end">
                        {acodaxNumberFormat(Math.abs(creditSum))}
                      </td>
                      {bt ? '' : <td></td>}
                    </tr>
                  </tfoot>
                </>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={8} className="p-2">
                      <Alert className="text-center m-0" variant="danger">
                        {Translate('Nothing to show!')}
                      </Alert>
                    </td>
                  </tr>
                </tbody>
              )}
            </>
          ) : (
            <div
              style={{ height: '50vh' }}
              className="d-flex align-items-center justiyf-content-center w-100"
            >
              <LoadingScreen message={Translate('Getting journal details!')} />
            </div>
          )}
        </Table>
      )}
      {jv ? (
        <div className="p-3">
          {data?.memo && <ShowMemo memo={data?.memo} />}
        </div>
      ) : null}
      <LedgerReportOffcanvas />
    </Container>
  );
}

JournalTable.propTypes = {
  journalData: PropTypes.any,
  voucherData: PropTypes.bool,
  bt: PropTypes.bool,
  jv: PropTypes.bool,
  id: PropTypes.any,
  transType: PropTypes.any,
  activeTabKey: PropTypes.string,
  tabApiCallCompleted: PropTypes.bool,
  setTabApiCallCompleted: PropTypes.func
};

export default JournalTable;
