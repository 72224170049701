import React, { useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import EnquiryInfo from 'module/Sales/Enquiry/page/EnquiryInfo';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

export default function EnquiryReferenceView({ targetId, reference, variant }) {
  const [show, setShow] = useState(false);
  const Translate = useAxisproTranslate();
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip>{Translate('Enquiry reference')}</Tooltip>}
      >
        <div className="d-flex flex-row align-items-center gap-1 mb-2">
          <Button
            size="sm"
            variant={variant ?? 'danger'}
            className="rounded p-1 py-0 "
            type="button"
            onClick={() => setShow(!show)}
          >
            <small>{reference?.length > 0 ? reference : '-'}</small>
          </Button>
        </div>
      </OverlayTrigger>

      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Body className="p-0">
          <EnquiryInfo
            enquiryId={targetId}
            onHide={() => setShow(false)}
            infoPage
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

EnquiryReferenceView.propTypes = {
  targetId: PropTypes.string.isRequired,
  reference: PropTypes.string,
  variant: PropTypes.string
};
