import React from 'react';
import PropTypes from 'prop-types';

const DisplayLineItemErrors = ({ errors, colSpan }) => {
  return (
    <tr>
      <td colSpan={colSpan ?? 10}>
        <ul className="mb-0 text-danger small">
          {Object.keys(errors).map(keyName =>
            errors[keyName].map((error, index) => <li key={index}>{error}</li>)
          )}
        </ul>
      </td>
    </tr>
  );
};

DisplayLineItemErrors.propTypes = {
  errors: PropTypes.object.isRequired,
  colSpan: PropTypes.number
};

export default DisplayLineItemErrors;
