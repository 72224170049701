import React from 'react';
import PropTypes from 'prop-types';
import { FaBook, FaInfo, FaList, FaQuestion } from 'react-icons/fa';

export default function ChatInitialHelpers({ handleSubmit }) {
  return (
    <>
      <p className="small-message">
        Here are some things AcoBot can help you do.
      </p>
      <div className="chat-initial-helpers">
        <a
          href="#"
          onClick={event =>
            handleSubmit(event, 'What is my total sales amount ?')
          }
        >
          <div className="helper-item d-flex flex-row align-items-center">
            <FaBook />
            <p>What is my total sales amount ?</p>
          </div>
        </a>

        <a
          href="#"
          onClick={event => handleSubmit(event, 'Top 5 customers by sale')}
        >
          <div className="helper-item d-flex flex-row align-items-center">
            <FaList />
            <p>Top 5 customers by sale</p>
          </div>
        </a>

        <a
          href="#"
          onClick={event => handleSubmit(event, 'Customer balance ?')}
        >
          <div className="helper-item d-flex flex-row align-items-center">
            <FaQuestion />
            <p>Customer balance ?</p>
          </div>
        </a>

        <a
          href="#"
          onClick={event =>
            handleSubmit(event, 'How to set user profile image ?')
          }
        >
          <div className="helper-item d-flex flex-row align-items-center">
            <FaInfo />
            <p>How to set user profile image ?</p>
          </div>
        </a>
      </div>
    </>
  );
}

ChatInitialHelpers.propTypes = {
  handleSubmit: PropTypes.any
};
