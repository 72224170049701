export const linkItems = [
  {
    name: '/item:STK-001', // +
    display_input_value: '/item:', // +
    params: true, // +
    url: 'inventory/items-auto', // +
    navigate: '/inventory/items', // +
    params_name: 'item_name', // +
    label: 'item_name', // +
    type: 'IT', // +
    value: 'stock_id', // +
    trans: false,
    unique_id: 'stock_id',
    unique_name: 'item_name',
    filter_name: 'stock_id', // +
    searchFilter: [
      {
        column: 'item_name',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/customer:CS-007',
    display_input_value: '/customer:',
    params: false,
    url: 'sales/customerAutocomplete',
    navigate: '/sales/customer/info',
    params_name: 'name',
    label: 'name',
    value: 'id',
    type: 'CS',
    trans: false,
    unique_id: 'customer_id',
    unique_name: 'name',
    filter_name: 'customer_id',
    searchFilter: [
      {
        column: 'name',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/supplier:SP-001',
    display_input_value: '/supplier:',
    params: true,
    url: 'purchase/suppliers-autocomplete',
    navigate: '/purchase/supplier',
    params_name: 'name',
    label: 'name',
    type: 'supplier',
    value: 'id',
    trans: false,
    unique_id: 'supplier_code',
    unique_name: 'name',
    filter_name: 'supplier_id',
    searchFilter: [
      {
        column: 'name',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/salesman:SM-001',
    display_input_value: '/salesman:',
    params: false,
    url: 'sales/salesmanAutoComplete',
    navigate: '/sales/salesman/info',
    params_name: 'name',
    label: 'salesman_name',
    value: 'id',
    type: 'SM',
    trans: false,
    unique_id: 'salesman_code',
    unique_name: 'salesman_name',
    filter_name: 'salesman_id',
    searchFilter: [
      {
        column: 'name',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/sales-invoice',
    display_input_value: '/sales-invoice:',
    params: false,
    type: 'SI',
    url: 'finance/transaction-autocomplete',
    navigate: '/sales/sales-invoice/info',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/sales-quotation',
    display_input_value: '/sales-quotation:',
    params: false,
    type: 'SQ',
    url: 'finance/transaction-autocomplete',
    navigate: '/sales/sales-quotation/info',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/sales-order',
    display_input_value: '/sales-order:',
    params: false,
    type: 'SO',
    url: 'finance/transaction-autocomplete',
    navigate: '/sales/sales-order/info',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/sales-delivery',
    display_input_value: '/sales-delivery:',
    params: false,
    type: 'SD',
    url: 'finance/transaction-autocomplete',
    navigate: '/sales/sales-deliveries/info',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/credit-note',
    display_input_value: '/credit-note:',
    params: false,
    type: 'CN',
    url: 'finance/transaction-autocomplete',
    navigate: '/sales/credit-note/info',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/contract',
    display_input_value: '/contract:',
    params: false,
    type: 'CT',
    url: 'finance/transaction-autocomplete',
    navigate: '/sales/contract/info',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/purchase-request',
    display_input_value: '/purchase-request:',
    params: true,
    type: 'PR',
    url: 'finance/transaction-autocomplete',
    navigate: '/purchase/purchase-request',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/purchase-order',
    display_input_value: '/purchase-order:',
    params: true,
    type: 'PO',
    url: 'finance/transaction-autocomplete',
    navigate: '/purchase/purchase-order',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/purchase-delivery',
    display_input_value: '/purchase-delivery:',
    params: true,
    type: 'PD',
    url: 'finance/transaction-autocomplete',
    navigate: '/purchase/purchase-delivery',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/purchase-invoice',
    display_input_value: '/purchase-invoice:',
    params: true,
    type: 'PI',
    url: 'finance/transaction-autocomplete',
    navigate: '/purchase/purchase-invoice',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/journal-voucher',
    display_input_value: '/journal-voucher:',
    params: true,
    type: 'JV',
    url: 'finance/transaction-autocomplete',
    navigate: '/finance/voucher-inquiry',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/payment-voucher',
    display_input_value: '/payment-voucher:',
    params: true,
    type: 'PV',
    url: 'finance/transaction-autocomplete',
    navigate: '/finance/voucher-inquiry',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/receipt-voucher',
    display_input_value: '/receipt-voucher:',
    params: true,
    type: 'RV',
    url: 'finance/transaction-autocomplete',
    navigate: '/finance/voucher-inquiry',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  },
  {
    name: '/bank-transfer',
    display_input_value: '/bank-transfer:',
    params: true,
    type: 'BT',
    url: 'finance/transaction-autocomplete',
    navigate: '/finance/voucher-inquiry',
    params_name: 'q',
    label: 'value',
    value: 'key',
    trans: true,
    filter_name: 'reference',
    searchFilter: [
      {
        column: 'reference',
        operator: 'LIKE',
        type: 'string',
        value: ''
      }
    ]
  }
];
