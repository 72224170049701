import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Badge, Card, Col, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Background from 'components/common/Background';
import recieve from 'assets/img/money.png';
import corner1 from 'assets/img/icons/spot-illustrations/corner-4.png';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import CalendarRangePickerModal from 'components/CalendarRangePickerModal/CalendarRangePickerModal';
import { FaCalendar } from 'react-icons/fa';
import Loader from '../Common/Loader';
import { receivablesAndPayables } from '../Common/LoaderData';
import { useNavigate } from 'react-router-dom';

function TotalPayables({ finance, draggable, data, setData, loading }) {
  const Translate = useAxisproTranslate();
  const navigate = useNavigate();
  const [showDateRangePicker, setshowDateRangePicker] = useState(false);
  const [payablesDateRange, setPayablesDateRange] = useState(null);
  const [isLoading, setIsLoading] = useState(loading);
  function handleSetDateRange(event) {
    setPayablesDateRange(event?.target?.value ?? null);
  }

  function handleShowModal() {
    setshowDateRangePicker(!showDateRangePicker);
  }

  const fetchData = (reset = false) => {
    if (payablesDateRange) {
      if (reset) {
        setPayablesDateRange(null);
      }
      setIsLoading(true);
      setshowDateRangePicker(false);
      axios
        .get('finance/total-receivables-and-payables', {
          params:
            !reset &&
            Array.isArray(payablesDateRange) &&
            payablesDateRange.length > 0
              ? {
                  from_date: payablesDateRange[0],
                  to_date: payablesDateRange[1]
                }
              : null
        })
        .then(res => {
          if (res.data.success === true) {
            setData(prev => ({
              ...prev,
              total_payables: res?.data?.data?.total_payables,
              total_payables_formatted:
                res?.data?.data?.total_payables_formatted
            }));
            setIsLoading(false);
          }
        })
        .catch(error => {
          if (error) {
            setIsLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {showDateRangePicker ? (
        <CalendarRangePickerModal
          show={showDateRangePicker}
          value={payablesDateRange}
          onChange={handleSetDateRange}
          onBack={() => handleShowModal(undefined)}
          onReset={() => fetchData(true)}
          onApply={() => fetchData(false)}
        />
      ) : null}
      <Col className="h-100 total-payable" md={finance ? 6 : 12}>
        <Card
          className={`h-100 ${
            draggable ? 'dashboard_shake' : 'cursor-pointer'
          }`}
          onClick={() =>
            !draggable && navigate('/reports/supplier-balance-report')
          }
        >
          <Background image={corner1} />
          <FalconCardHeader
            title={Translate('Total Payables')}
            light={false}
            titleTag="h6"
            className="pb-0 pt-2"
            endEl={
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item onClick={() => handleShowModal()}>
                    {Translate('Change Date')}
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            }
          />
          {isLoading || loading ? (
            <Card.Body className="p-0">
              <Loader loader={receivablesAndPayables} className="ps-4" />
            </Card.Body>
          ) : (
            <Card.Body className="p-0">
              <div className="h-100 d-flex flex-column gap-2 align-items-center justify-content-center">
                <h2 className="fw-bold fs-1 m-0">
                  {data?.total_payables_formatted}
                </h2>
                <img
                  src={recieve}
                  alt=""
                  style={{ width: finance ? '3rem' : '4rem' }}
                />
              </div>
            </Card.Body>
          )}
          {Array.isArray(payablesDateRange) && payablesDateRange.length > 1 ? (
            <div className="d-flex flex-row  dashboard-date-range-receivable-paybles-div  align-items-center">
              <FaCalendar size={10} />{' '}
              <Badge bg="" className="ms-1 text-dark">
                {payablesDateRange[0]} - {payablesDateRange[1]}
              </Badge>
            </div>
          ) : (
            <Card.Footer className="p-2"></Card.Footer>
          )}
        </Card>
      </Col>
    </>
  );
}
TotalPayables.propTypes = {
  finance: PropTypes.bool,
  draggable: PropTypes.bool,
  data: PropTypes.any,
  setData: PropTypes.any,
  loading: PropTypes.bool,
  setLoading: PropTypes.any
};

export default TotalPayables;
