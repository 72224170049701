export const handleEnterButton = (e, submitTableData, tableData, next) => {
  let result = Object.values(tableData).filter(
    value =>
      value !== null &&
      value !== undefined &&
      value !== '' &&
      value !== 0 &&
      value !== true
  );
  if (result.length > 0) {
    if (e.key === 'Enter') {
      submitTableData(e);
    }
    if (e.key === 'Tab' && next === 'submitButton') {
      submitTableData(e);
    }
  }
};
