import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import welcomeImage from './asset/images/welcome.jpg';
import './asset/style/SuccessScreenStyle.scss';

const SuccessScreen = () => {
  return (
    <Card.Body className="d-flex flex-row py-1 success-message-wrapper">
      <div className="text-center">
        <img src={welcomeImage} className="img-fluid" />
        <p
          style={{
            fontSize: '1.60rem',
            color: '#252526',
            fontWeight: 700,
            fontFamily: 'system-ui'
          }}
        >
          Welcome
        </p>

        <p>
          Your account has been successfully created, use the link below to
          login to your account. check your inbox for login information!
        </p>
        <Button
          as={Link}
          to="/authentication/login"
          className="w-100 rounded border shadow-sm fw-bold"
          variant="light"
        >
          Login
        </Button>
      </div>
    </Card.Body>
  );
};

export default SuccessScreen;
