import { useContext } from 'react';
import { AuthWizardContext } from 'context/Context';

const useAcodaxNumberFormat = () => {
  const { user } = useContext(AuthWizardContext);
  const format = user?.branch?.system_settings?.number_format_system ?? 'en-GB'; //'en-IN' for Indian
  const decimalPoints = 2;
  const acodaxNumberFormat = number => {
    if (isNaN(parseFloat(number))) return '';
    return parseFloat(number).toLocaleString(format, {
      minimumFractionDigits: decimalPoints,
      maximumFractionDigits: decimalPoints
    });
  };
  return acodaxNumberFormat;
};

export default useAcodaxNumberFormat;
