import React, { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { showToast } from 'module/Common/Toast/toast';
import { GrAttachment } from 'react-icons/gr';

const ChatFileUpload = ({
  error,
  onChange,
  className,
  limit,
  limitFileSizeInMB = 0,
  limitFileType = [],
  setAttachments
}) => {
  const [filesArray, setFilesArray] = useState([]);
  const fileInputRef = useRef(null);
  const triggerFileInput = e => {
    e.preventDefault();
    fileInputRef && fileInputRef.current.click();
  };

  const handleFileUpload = e => {
    if (e.target.files.length > 0) {
      let refFilesArray = [];
      Object.keys(e.target.files).map(index => {
        if (
          !limit ||
          (limit > 0 &&
            refFilesArray.filter(item => item.isValid).length +
              filesArray.filter(item => item.isValid).length <
              limit)
        ) {
          let fileItem = e.target.files[index];
          let fileExtension = fileItem.name.split('.').pop();
          let fileSizeInMB = (fileItem.size / Math.pow(1024, 2)).toFixed(2);
          let fileIsValid = true;
          let fileErrors = [];
          if (limitFileSizeInMB > 0 && fileSizeInMB > limitFileSizeInMB) {
            fileIsValid = false;
            fileErrors.push('File size is too large');
          }

          if (
            limitFileType &&
            Array.isArray(limitFileType) &&
            limitFileType.length > 0 &&
            !limitFileType.includes(fileExtension)
          ) {
            fileIsValid = false;
            fileErrors.push(
              `Invalid type, this are the allowed file types ${limitFileType.join(
                ','
              )}`
            );
          }

          refFilesArray.push({
            file: fileItem,
            sizeInMB: fileSizeInMB,
            isValid: fileIsValid,
            errors: fileErrors
          });

          const reader = new FileReader();
          reader.onload = event => {
            const src = event.target.result;
            setAttachments(prevAttachments => [
              ...prevAttachments,
              {
                file_name: fileItem.name,
                name: fileItem.name,
                size: fileItem.size,
                original_url: src
              }
            ]);
          };
          reader.readAsDataURL(fileItem);
        }
      });

      if (Object.keys(e.target.files).length + filesArray.length > limit) {
        showToast(
          `You have exceeded the file upload limit, the maximum limit allowed is ${limit}.`,
          'error'
        );
      }

      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      let newArrayResult = refFilesArray.concat(filesArray);
      setFilesArray(newArrayResult);

      onChange(
        newArrayResult.reduce((initalArray, item) => {
          if (item.isValid) {
            initalArray.push(item.file);
          }
          return initalArray;
        }, [])
      );
    }
  };

  return (
    <div className={`file-upload-cover ${className}`}>
      <div className="d-flex flex-row">
        <Button
          style={{ backgroundColor: '#E6E6E6' }}
          className="rounded-1 text-dark border-0"
          size={'sm'}
          onClick={triggerFileInput}
        >
          <GrAttachment color="dark" size={15} />
        </Button>
      </div>
      <Form.Control
        multiple
        type="file"
        name="file"
        onChange={handleFileUpload}
        isInvalid={!!error}
        ref={fileInputRef}
        hidden
      />
    </div>
  );
};

ChatFileUpload.propTypes = {
  error: PropTypes.any,
  note: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  limit: PropTypes.number,
  limitFileSizeInMB: PropTypes.number,
  limitFileType: PropTypes.array,
  setAttachments: PropTypes.func
};

export default ChatFileUpload;
