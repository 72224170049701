export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      to: 'dashboard',
      active: true,
      icon: 'chart-pie',
      slug: 'dashboard'
    }
  ]
};

export const financeRoutes = {
  label: 'Finance',
  labelDisable: true,
  children: [
    {
      name: 'Finance',
      to: 'finance',
      icon: 'book',
      active: true,
      slug: 'finance',
      children: [
        {
          name: 'Bank Account',
          to: 'finance/bank-accounts',
          active: true,
          parent_slug: 'finance',
          slug: 'bank-account'
        },
        {
          name: 'Cost Center',
          to: 'finance/cost-center',
          active: true,
          parent_slug: 'finance',
          slug: 'cost-center'
        },
        {
          name: 'Cost Center Group',
          to: 'finance/cost-center-group',
          active: true,
          parent_slug: 'finance',
          slug: 'cost-center-group'
        },
        {
          name: 'Chart of accounts',
          to: 'finance/chart-of-accounts',
          active: true,
          parent_slug: 'finance',
          slug: 'charts-of-accounts'
        },
        {
          name: 'Reconcile',
          to: 'finance/reconcile',
          active: true,
          parent_slug: 'finance',
          slug: 'bank-reconciliations'
        },
        {
          name: 'Vouchers',
          active: true,
          subIcon: true,
          parent_slug: 'finance',
          slug: 'list-vouchers',
          subName1: '/finance/voucher-inquiry',
          subName2: '/finance/journal-voucher/add',
          subName3: '/finance/payment-voucher/add',
          subName4: '/finance/receipt-voucher/add',
          subName5: '/finance/bank-transfer/add',
          children: [
            {
              name: 'Voucher Inquiry',
              to: '/finance/voucher-inquiry',
              active: true,
              parent_slug: 'finance',
              slug: 'list-vouchers'
            },
            {
              name: 'Journal Voucher',
              to: '/finance/journal-voucher/add',
              active: true,
              parent_slug: 'finance',
              slug: 'create-jv'
            },
            {
              name: 'Payment Voucher',
              to: '/finance/payment-voucher/add',
              active: true,
              parent_slug: 'finance',
              slug: 'create-pv'
            },
            {
              name: 'Receipt Voucher',
              to: '/finance/receipt-voucher/add',
              active: true,
              parent_slug: 'finance',
              slug: 'create-rv'
            },
            {
              name: 'Bank Transfer',
              to: '/finance/bank-transfer/add',
              active: true,
              parent_slug: 'finance',
              slug: 'create-bt'
            }
          ]
        },
        {
          name: 'Transaction Locking',
          to: 'finance/transaction-locking',
          active: true,
          parent_slug: 'finance',
          slug: 'transaction-lock'
        },
        {
          name: 'Cheques',
          to: 'finance/cheques',
          active: true,
          parent_slug: 'finance',
          slug: 'cheque'
        },
        {
          name: 'Recurrent Profiles',
          to: 'finance/recurrent-profiles',
          active: true,
          parent_slug: 'finance',
          slug: 'recurrent-profile'
        },
        {
          name: 'Accrual Transactions',
          to: 'finance/accrual-transactions/add',
          active: true,
          parent_slug: 'finance',
          slug: 'accural-transaction'
        }
      ]
    }
  ]
};

export const salesRoutes = {
  label: 'Sales',
  labelDisable: true,
  children: [
    {
      name: 'Sales',
      to: 'sales',
      icon: 'tags',
      active: true,
      slug: 'sales',
      children: [
        {
          name: 'Sales Quotation',
          to: 'sales/sales-quotation',
          active: true,
          parent_slug: 'sales',
          slug: 'sales-quotation'
        },
        {
          name: 'Sales Order',
          to: 'sales/sales-order',
          active: true,
          parent_slug: 'sales',
          slug: 'sales-order'
        },
        {
          name: 'Sales Delivery',
          to: 'sales/sales-deliveries',
          active: true,
          parent_slug: 'sales',
          slug: 'sales-delivery'
        },
        {
          name: 'Sales Invoice',
          to: 'sales/sales-invoice',
          active: true,
          parent_slug: 'sales',
          slug: 'sales-invoice'
        },
        {
          name: 'Credit Note',
          to: 'sales/credit-note',
          active: true,
          parent_slug: 'sales',
          slug: 'credit-note'
        },
        {
          name: 'Customer Receipt',
          to: 'sales/customer-receipt',
          active: true,
          parent_slug: 'sales',
          slug: 'customer-receipt'
        },
        {
          name: 'Customer Allocation',
          to: 'sales/customer-allocation',
          active: true,
          parent_slug: 'sales',
          slug: 'customer-payments'
        },
        {
          name: 'Salesman',
          to: 'sales/salesman',
          active: true,
          parent_slug: 'sales',
          slug: 'salesman'
        },
        {
          name: 'Customer',
          to: 'sales/customer',
          active: true,
          parent_slug: 'sales',
          slug: 'customer'
        },
        {
          name: 'Gov - Transactions',
          active: true,
          subIcon: true,
          parent_slug: 'sales',
          slug: 'government-transactions',
          subName1: '/sales/gov-transactions/pre-invoice',
          subName2: '/sales/gov-transactions/gov-invoice',
          children: [
            {
              name: 'Pre - Invoice',
              to: 'sales/gov-transactions/pre-invoice',
              active: true,
              parent_slug: 'government-transactions',
              slug: 'government-transactions'
            },
            {
              name: 'Gov - Invoice',
              to: 'sales/gov-transactions/gov-invoice',
              active: true,
              parent_slug: 'government-transactions',
              slug: 'government-transactions'
            }
          ]
        }
      ]
    }
  ]
};

export const productionRoutes = {
  label: 'Production',
  labelDisable: true,
  children: [
    {
      name: 'Production',
      to: 'production',
      active: true,
      icon: 'cog',
      slug: 'settings',
      children: [
        {
          name: 'Work Orders',
          to: 'production/work-orders',
          active: true,
          parent_slug: 'settings',
          slug: 'unit'
        }
      ]
    }
  ]
};
export const settingsRoutes = {
  label: 'Settings',
  labelDisable: true,
  children: [
    {
      name: 'Settings',
      to: 'settings',
      active: true,
      icon: 'cog',
      slug: 'settings',
      children: [
        {
          name: 'Units',
          to: 'settings/units',
          active: true,
          parent_slug: 'settings',
          slug: 'unit'
        },
        {
          name: 'Brands',
          to: 'settings/brands',
          active: true,
          parent_slug: 'settings',
          slug: 'brand'
        },
        {
          name: 'Manufacturer',
          to: 'settings/manufacturer',
          active: true,
          parent_slug: 'settings',
          slug: 'manufacturer'
        },
        {
          name: 'Exchange Rate',
          to: 'settings/exchange-rate',
          active: true,
          parent_slug: 'settings',
          slug: 'exchange-rate'
        },
        {
          name: 'Financial Year Setup',
          to: 'settings/fiscal-year',
          active: true,
          parent_slug: 'settings',
          slug: 'fiscal-year'
        },
        {
          name: 'Modules',
          to: 'settings/modules-tree',
          active: true,
          parent_slug: 'settings',
          slug: 'modules'
        },
        {
          name: 'Payment Terms',
          to: 'settings/payment-terms',
          active: true,
          parent_slug: 'settings',
          slug: 'payment-term'
        },
        {
          name: 'Shipping Company',
          to: 'settings/shipping-company',
          active: true,
          parent_slug: 'settings',
          slug: 'shipping-company'
        },
        {
          name: 'Terms And Conditions',
          to: 'settings/terms-and-conditions',
          active: true,
          parent_slug: 'settings',
          slug: 'terms-and-conditions'
        },
        {
          name: 'Tax',
          active: true,
          subIcon: true,
          parent_slug: 'settings',
          slug: 'tax',
          subName1: '/settings/tax',
          subName2: '/settings/tax-group',
          subName3: '/settings/tax-item',
          subName4: '/settings/tax/add',
          subName5: '/settings/tax-group/add',
          subName6: '/settings/tax-item/add',
          children: [
            {
              name: 'Tax',
              to: 'settings/tax',
              active: true,
              parent_slug: 'settings',
              slug: 'tax'
            },
            {
              name: 'Tax Group',
              to: 'settings/tax-group',
              active: true,
              parent_slug: 'settings',
              slug: 'tax-group'
            },
            {
              name: 'Tax Group Item',
              to: 'settings/tax-item',
              active: true,
              parent_slug: 'settings',
              slug: 'tax-group-items'
            }
          ]
        },
        {
          name: 'Sales',
          active: true,
          subIcon: true,
          subName1: '/settings/sales-area',
          subName2: '/settings/sales-type',
          subName3: '/settings/sales-area/add',
          subName4: '/settings/sales-type/add',
          parent_slug: 'settings',
          slug: 'sales-area',
          children: [
            {
              name: 'Sales Area',
              to: 'settings/sales-area',
              active: true,
              parent_slug: 'settings',
              slug: 'sales-area'
            },
            {
              name: 'Sales Type',
              to: 'settings/sales-type',
              active: true,
              parent_slug: 'settings',
              slug: 'sales-type'
            }
          ]
        },
        {
          name: 'Transaction Reference',
          active: true,
          to: 'settings/transaction-reference',
          parent_slug: 'settings',
          slug: 'transaction-type'
        },
        {
          name: 'Roles',
          active: true,
          to: 'settings/roles',
          parent_slug: 'settings',
          slug: 'role'
        },
        {
          name: 'Warehouse',
          active: true,
          to: 'settings/warehouse',
          parent_slug: 'settings',
          slug: 'warehouse'
        }
      ]
    }
  ]
};

export const purchaseRoutes = {
  label: 'Purchase',
  labelDisable: true,
  children: [
    {
      name: 'Purchase',
      to: 'purchase',
      icon: 'shopping-cart',
      active: true,
      slug: 'purchase',
      children: [
        {
          name: 'Purchase Request',
          to: 'purchase/purchase-request',
          active: true,
          parent_slug: 'purchase',
          slug: 'purchase-request'
        },
        {
          name: 'Purchase Orders',
          to: 'purchase/purchase-order',
          active: true,
          parent_slug: 'purchase',
          slug: 'purchase-order'
        },
        {
          name: 'Purchase Delivery',
          to: 'purchase/purchase-delivery',
          active: true,
          parent_slug: 'purchase',
          slug: 'purchase-delivery'
        },
        {
          name: 'Purchase Invoice',
          to: 'purchase/purchase-invoice',
          active: true,
          parent_slug: 'purchase',
          slug: 'purchase-invoice'
        },
        {
          name: 'Supplier Payment',
          to: 'purchase/supplier-payment',
          active: true,
          parent_slug: 'purchase',
          slug: 'create-supplier-payment'
        },
        {
          name: 'Supplier Allocation',
          to: 'purchase/supplier-allocation',
          active: true,
          parent_slug: 'purchase',
          slug: 'supplier-allocation'
        },
        {
          name: 'Supplier',
          active: true,
          to: 'purchase/supplier',
          parent_slug: 'purchase',
          slug: 'supplier'
        }
      ]
    }
  ]
};

export const inventoryRoutes = {
  label: 'Inventory',
  labelDisable: true,
  children: [
    {
      name: 'Inventory',
      to: 'inventory',
      icon: 'building',
      active: true,
      slug: 'inventory',
      children: [
        {
          name: 'Items',
          to: 'inventory/items',
          active: true,
          parent_slug: 'inventory',
          slug: 'item'
        },
        {
          name: 'Category',
          to: 'inventory/categories',
          active: true,
          parent_slug: 'inventory',
          slug: 'category'
        },
        {
          name: 'Stock Kit',
          to: 'inventory/stock-kit',
          active: true,
          parent_slug: 'inventory',
          slug: 'stock-kit'
        },
        {
          name: 'Price',
          to: 'inventory/price',
          active: true,
          parent_slug: 'inventory',
          slug: 'price-settings'
        },
        {
          name: 'Inventory Adjustment',
          to: 'inventory/inventory-adjustment',
          active: true,
          parent_slug: 'inventory',
          slug: 'inventory-adjustment'
        },
        {
          name: 'Issue Items',
          to: 'inventory/issue-items',
          active: true,
          parent_slug: 'inventory',
          slug: 'issue-items'
        },
        {
          name: 'Gov - Inventory',
          active: true,
          subIcon: true,
          parent_slug: 'inventory',
          slug: 'government-transactions',
          subName1: '/inventory/gov-transactions/item',
          subName2: '/inventory/gov-transactions/category',
          children: [
            {
              name: 'Item',
              to: 'inventory/gov-transactions/item',
              active: true,
              parent_slug: 'inventory',
              slug: 'government-transactions'
            },
            {
              name: 'Category',
              to: 'inventory/gov-transactions/categories',
              active: true,
              parent_slug: 'inventory',
              slug: 'government-transactions'
            }
          ]
        }
      ]
    }
  ]
};

export const systemAdminRoutes = {
  label: 'System Admin',
  labelDisable: true,
  children: [
    {
      name: 'System Admin',
      validatingName: 'System-Admin',
      to: 'system-admin',
      icon: 'user',
      active: true,
      slug: 'system-admin',
      children: [
        {
          name: 'Users',
          to: 'system-admin/users',
          active: true,
          parent_slug: 'system-admin',
          slug: 'user'
        },
        {
          name: 'Users Permissions',
          to: 'system-admin/users-permissions',
          active: true,
          parent_slug: 'system-admin',
          slug: 'create-user-permission'
        },
        {
          name: 'Roles Permissions',
          to: 'system-admin/roles-permissions',
          active: true,
          parent_slug: 'system-admin',
          slug: 'list-role-permission'
        },
        {
          name: 'Activity Log',
          to: 'system-admin/activity-log',
          active: true,
          // parent_slug: '',
          slug: 'log-history'
        },
        {
          name: 'Custom Fields',
          to: 'system-admin/custom-fields',
          active: true
          // parent_slug: 'system-admin'
        },
        {
          name: 'Void Transactions',
          to: 'system-admin/void-transactions',
          active: true,
          parent_slug: 'system-admin',
          slug: 'void-transaction'
        }
      ]
    }
  ]
};

export const fixedAssetRoutes = {
  label: 'Fixed Assets',
  labelDisable: true,
  children: [
    {
      name: 'Fixed Assets',
      validatingName: 'Fixed-Assets',
      to: 'fixed-assets',
      icon: 'building',
      active: true,
      slug: 'fixed-assets',
      children: [
        {
          name: 'Manage Assets',
          to: 'fixed-assets/assets',
          active: true,
          parent_slug: 'fixed-assets'
        }
      ]
    }
  ]
};

export const reportRoutes = {
  label: 'Reports',
  labelDisable: true,
  children: [
    {
      name: 'Reports',
      validatingName: 'Reports',
      to: 'reports',
      icon: 'chart-line',
      active: true,
      slug: 'reports'
    }
  ]
};

export default [
  dashboardRoutes,
  financeRoutes,
  salesRoutes,
  productionRoutes,
  purchaseRoutes,
  inventoryRoutes,
  settingsRoutes,
  systemAdminRoutes,
  fixedAssetRoutes,
  reportRoutes
];
