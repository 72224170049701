import { React, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Badge, Spinner } from 'react-bootstrap';

function DataFetching({ nextLink, loadNextPage }) {
  const componentRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadNextPage();
      }
    }, options);
    observer.observe(componentRef.current);
    return () => {
      observer.disconnect();
    };
  }, [nextLink]);

  return (
    <div
      ref={componentRef}
      className="d-flex align-items-center justify-content-center mb-2 mt-2"
    >
      <Badge
        className="p-1 m-0 pe-3 ps-3 d-flex align-items-center justify-content-center gap-1"
        bg="info"
      >
        Fetching Data... !
        <Spinner animation="grow" variant="light" size="sm" />
        <Spinner animation="grow" variant="light" size="sm" />
        <Spinner animation="grow" variant="light" size="sm" />
      </Badge>
    </div>
  );
}

DataFetching.propTypes = {
  loadNextPage: PropTypes.func,
  nextLink: PropTypes.any
};

export default DataFetching;
