import React from 'react';
import PropTypes from 'prop-types';
import { FaExclamationCircle, FaSearch } from 'react-icons/fa';
import LaunchpadQuickLinksSection from './LaunchpadQuickLinksSection';

export default function LaunchpadSearchResultArea({
  searchKey,
  searchResult,
  isSearching,
  onPageChange
}) {
  return (
    <>
      <div className="app-launchpad-search-result-section text-center">
        {isSearching ? (
          <>
            <FaSearch size={30} className="orbit-animation" />
            <p className="orbit-animation-text">Searching for "{searchKey}"</p>
          </>
        ) : searchResult.length < 1 || searchKey.length < 3 ? (
          <>
            <FaExclamationCircle size={30} />
            <p className="mt-3 message">
              {searchKey.length < 3
                ? 'Please enter at least 3 characters to search...'
                : `No results for "${searchKey}"`}
            </p>
          </>
        ) : (
          <LaunchpadQuickLinksSection
            title="Search Result"
            quickLinks={searchResult}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}

LaunchpadSearchResultArea.propTypes = {
  searchKey: PropTypes.string,
  searchResult: PropTypes.array,
  isSearching: PropTypes.bool,
  onPageChange: PropTypes.func
};
