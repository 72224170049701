import React from 'react';
import { Placeholder, Table } from 'react-bootstrap';

function AllocatingTableLoading() {
  const number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <Table size="sm" striped bordered responsive>
      <thead>
        {number.map(key => (
          <tr key={key}>
            <td style={{ width: '40px' }}>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </td>
            <td style={{ width: '150px' }}>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </td>
            <td style={{ width: '150px' }}>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </td>
            <td style={{ width: '150px' }}>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </td>
            <td style={{ width: '150px' }}>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </td>
            <td style={{ width: '150px' }}>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </td>
            <td style={{ width: '150px' }}>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </td>
            <td style={{ width: '150px' }}>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </td>
          </tr>
        ))}
      </thead>
    </Table>
  );
}

export default AllocatingTableLoading;
