import React from 'react';
import { FaSearch } from 'react-icons/fa';
import PropTypes from 'prop-types';

export default function LaunchpadSearchArea({ inputProps }) {
  return (
    <div className="launchpad-searcharea-cover app-launchpad-section-padding">
      <div className="search-area d-flex flex-row align-items-center ">
        <div className="icon-cover">
          <FaSearch />
        </div>
        <div className="search-cover">
          {!inputProps?.value?.length ? (
            <span>Search for modules, and settings</span>
          ) : null}
          <input type="search" {...inputProps} />
        </div>
      </div>
    </div>
  );
}

LaunchpadSearchArea.propTypes = {
  inputProps: PropTypes.object
};
