import { React, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaLink } from 'react-icons/fa';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { CancelButton, SaveButton } from '../Buttons/CommonButton';
import { showToast } from '../Toast/toast';
import ErrorAlert from '../Error/ErrorAlert';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function QuickLinksForm({ show, onHide, handleToggle }) {
  const { dispatch } = useContext(AuthWizardContext);
  const { pathname } = useLocation();
  const Translate = useAxisproTranslate();
  const [data, setData] = useState({
    url: pathname,
    label: '',
    type: 'erp'
  });
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});

  const handleFieldChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    setData(prev => ({
      ...prev,
      url: pathname
    }));
  }, [pathname]);

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);
    axios({
      method: 'post',
      url: 'quick_link',
      data: data
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          axios
            .get('user/info')
            .then(res => {
              if (res.data.success) {
                dispatch({
                  type: 'UPDATE',
                  payload: { ...res.data.data }
                });
                handleToggle();
              }
            })
            .catch(error => {
              if (error) {
                showToast(
                  'Something went wrong! Please contact admin',
                  'error'
                );
              }
            });
          setData({
            url: pathname,
            label: '',
            type: 'erp'
          });
          setOnSave(false);
          onHide();
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast('Something went wrong! Please contact admin', 'error');
        }
        setOnSave(false);
      });
  };

  const handleCancel = () => {
    onHide();
    setData(prev => ({
      ...prev,
      label: ''
    }));
    setFormError({});
  };
  const handleClearForm = () => {
    setData({
      url: pathname,
      label: '',
      type: 'erp'
    });
  };
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      className="modal-with-overlay"
      onHide={handleCancel}
    >
      <Card className="p-0">
        <Modal.Header
          className="d-flex flex-row align-items-center border-bottom"
          closeButton
        >
          <FaLink size={25} className="text-dark me-1 m-0" />
          <h5 className="modal-form-heading">{Translate('Add Quick Link')}</h5>
        </Modal.Header>
        <Form>
          <Card.Body className="pb-0">
            {!Object.keys(formError).length == 0 ? (
              <ErrorAlert setFormError={setFormError} formError={formError} />
            ) : (
              ''
            )}
            <Row>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate('Label')}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="text"
                          name="label"
                          className="input"
                          onChange={handleFieldChange}
                          value={data.label}
                          autoFocus
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.label ? (
                          <FormErrorPopover
                            id="name"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.label}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex flex-row gap-2 justify-content-start border-top">
            <SaveButton
              handleSubmit={handleSubmit}
              onSave={onSave}
              createPermission={true}
              editPermission={true}
            />
            <CancelButton
              handleCancel={handleClearForm}
              onSave={onSave}
              clear={true}
            />
          </Card.Footer>
        </Form>
      </Card>
    </Modal>
  );
}

QuickLinksForm.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  handleToggle: PropTypes.func
};

export default QuickLinksForm;
