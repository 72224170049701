import React, { useContext } from 'react';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaChevronDown } from 'react-icons/fa';
import {
  Accordion,
  AccordionContext,
  Button,
  useAccordionButton
} from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function EntriesPopUpLineItemAccordionViewItemDescriptionSection({
  title,
  eventKey,
  description,
  colSpan
}) {
  const Translate = useAxisproTranslate();
  const { activeEventKey } = useContext(AccordionContext);
  const handleClick = useAccordionButton(eventKey);
  const descriptionLength = description?.length;
  const descriptionLengthLimitExceded = !!(descriptionLength > 100);

  return descriptionLength > 0 ? (
    <>
      <tr>
        <th className="fw-bold" colSpan={colSpan}>
          <div className="d-flex flex-row justify-content-between gap-2 align-items-center text-dark">
            <div>
              <span>{Translate(title || 'ITEM DESCRIPTION')}</span>
              <p
                className="m-0"
                style={{
                  fontSize: '.65rem',
                  color: '#838282'
                }}
              >
                {descriptionLengthLimitExceded
                  ? description?.substring(0, 100) + '...'
                  : description}
              </p>
            </div>
            {descriptionLengthLimitExceded && (
              <Button
                variant="transparent"
                size="sm"
                className="outline-none shadow-none"
                onClick={handleClick}
              >
                <FaChevronDown
                  style={{
                    transition: 'all .4s linear 0s',
                    transform: `rotate(${
                      activeEventKey === eventKey ? '0' : '-90'
                    }deg)`
                  }}
                />
              </Button>
            )}
          </div>
        </th>
      </tr>
      {descriptionLengthLimitExceded && (
        <tr>
          <th colSpan={colSpan} className="bg-white p-0 m-0 border-0">
            <Accordion.Collapse eventKey={eventKey}>
              <p
                className="p-3 m-0 border-start border-end"
                style={{
                  whiteSpace: 'pre-wrap'
                }}
              >
                {description}
              </p>
            </Accordion.Collapse>
          </th>
        </tr>
      )}
    </>
  ) : null;
}

EntriesPopUpLineItemAccordionViewItemDescriptionSection.propTypes = {
  title: PropTypes.string,
  eventKey: PropTypes.string,
  description: PropTypes.string,
  colSpan: PropTypes.number
};
