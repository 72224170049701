import React from 'react';
import PropTypes from 'prop-types';
import './_switch_controller.scss';

export default function SwitchController({ checked, onSwitch, disabled }) {
  return (
    <label className="switch-controller m-0">
      <input
        type="checkbox"
        checked={!!checked}
        onChange={onSwitch}
        disabled={disabled}
      />
      <span className="slider"></span>
    </label>
  );
}

SwitchController.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onSwitch: PropTypes.func
};
