import React from 'react';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import EllipsisOverlayText from 'components/ellipsis-overlay-text/EllipsisOverlayText';

export default function TabViewGeneralDetailsSectionCover({
  children,
  mainTitle
}) {
  return (
    <div
      className="border p-3 mb-2"
      style={{
        backgroundColor: '#edf2f5'
      }}
    >
      {mainTitle ? (
        <TabViewGeneralDetailsSectionCover.SectionTitle title={mainTitle} />
      ) : null}
      <div className="d-flex flex-wrap gap-3">{children}</div>
    </div>
  );
}

TabViewGeneralDetailsSectionCover.Section = ({
  children,
  position,
  className
}) => (
  <div
    className={`${position === 'start' ? 'me-auto' : ''} ${className ?? ''}`}
  >
    {children}
  </div>
);

TabViewGeneralDetailsSectionCover.Item = ({
  title,
  value,
  children,
  className,
  titleClassName,
  contentSectionClassName,
  contentClassName,
  longText,
  longTextLimit,
  EndElement,
  showIfValueExist
}) => {
  const Translate = useAxisproTranslate();
  const ellipsisOverlayTextLimit = longTextLimit > 0 ? longTextLimit : 30;
  const enableLongText = !!(
    longText && value?.length > ellipsisOverlayTextLimit
  );
  return !showIfValueExist || value?.length > 0 ? (
    <div className={`d-flex flex-wrap align-items-center gap-2 ${className}`}>
      <h6 className={`mb-2 fw-bold ${titleClassName ?? ''}`}>
        {Translate(title)}:
      </h6>
      <div
        className={`d-flex flex-row align-items-center gap-1 ${
          contentSectionClassName ?? ''
        }`}
      >
        {children ?? (
          <>
            <h6
              className={`mb-2 text-uppercase ${contentClassName ?? ''}`}
              {...(enableLongText
                ? {
                    style: {
                      cursor: 'help'
                    }
                  }
                : null)}
            >
              {enableLongText ? (
                <EllipsisOverlayText
                  text={value}
                  limit={ellipsisOverlayTextLimit}
                />
              ) : value?.length > 0 ? (
                value
              ) : (
                ''
              )}
            </h6>
          </>
        )}
        {EndElement ?? null}
      </div>
    </div>
  ) : null;
};

TabViewGeneralDetailsSectionCover.CustomColumnResultSection = ({
  customColumnItems
}) => {
  const groupedResult = [];
  if (Array.isArray(customColumnItems)) {
    const customColumnItemsLength = customColumnItems?.length;
    if (customColumnItemsLength > 1) {
      const sliceIndex = Math.ceil(customColumnItemsLength / 2);
      groupedResult.push(customColumnItems.slice(0, sliceIndex));
      groupedResult.push(
        customColumnItems.slice(sliceIndex, customColumnItemsLength)
      );
    } else if (customColumnItemsLength > 0) {
      groupedResult.push(customColumnItems);
    }
  }

  return groupedResult?.length > 0 ? (
    <>
      <div className="w-100 _additional-details-section">
        <TabViewGeneralDetailsSectionCover.SectionTitle title="Additional Details" />

        <div className="d-flex flex-wrap gap-3">
          {groupedResult.map((groupedResultItems, key) => (
            <TabViewGeneralDetailsSectionCover.Section
              key={key}
              position={key === 0 ? 'start' : 'end'}
            >
              {Array.isArray(groupedResultItems) &&
                groupedResultItems.map((item, key) => (
                  <TabViewGeneralDetailsSectionCover.Item
                    key={item?.id || key}
                    title={item?.custom_field?.label}
                    value={item?.value}
                    longText={
                      !!(item?.custom_field?.column_type === 'longText')
                    }
                    longTextLimit={25}
                  />
                ))}
            </TabViewGeneralDetailsSectionCover.Section>
          ))}
        </div>
      </div>
    </>
  ) : null;
};

TabViewGeneralDetailsSectionCover.SectionTitle = ({ title }) => {
  const Translate = useAxisproTranslate();
  return (
    <div
      className="mb-2 text-uppercase small fw-bold w-100"
      style={{
        color: '#4f74ff'
      }}
    >
      {Translate(title)}
    </div>
  );
};

TabViewGeneralDetailsSectionCover.propTypes = {
  children: PropTypes.any,
  mainTitle: PropTypes.string
};

TabViewGeneralDetailsSectionCover.Section.propTypes = {
  children: PropTypes.any,
  position: PropTypes.oneOf(['start', 'end']),
  className: PropTypes.string
};

TabViewGeneralDetailsSectionCover.Item.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  contentSectionClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  longText: PropTypes.bool,
  longTextLimit: PropTypes.number,
  EndElement: PropTypes.any,
  showIfValueExist: PropTypes.bool
};

TabViewGeneralDetailsSectionCover.CustomColumnResultSection.propTypes = {
  customColumnItems: PropTypes.array
};

TabViewGeneralDetailsSectionCover.SectionTitle.propTypes = {
  title: PropTypes.string
};
