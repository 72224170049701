import React from 'react';
import AsyncSelect from 'react-select/async';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';
import PropTypes from 'prop-types';
import { FaCircle } from 'react-icons/fa6';

export default function SelectTaskStatus({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  onFocus,
  ...rest
}) {
  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: 'crm/task-status',
      setParams: searchKey => ({
        name: searchKey
      }),
      setOptions: option => ({
        label: option,
        value: option
      }),
      onFocus: onFocus
    });

  const iconColorList = {
    to_do: '#9c8aff',
    done: '#07bc0cb8',
    in_review: '#fc859d',
    hold: '#ffa636',
    in_progress: '#75cdff',
    need_clarification: '#808080',
    bug: '#db3232',
    deployed: '#29c3be',
    cancelled: '#eb7936',
    reopened: '#b3ce68'
  };

  const formatOptionLabel = option => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <span
          style={{
            minWidth: 10
          }}
        >
          <FaCircle
            size={10}
            style={{
              color: iconColorList?.[option?.value] ?? '#000'
            }}
          />
        </span>
        <span>{option?.label?.replaceAll('_', ' ')}</span>
      </div>
    );
  };

  return (
    <AsyncSelect
      cacheOptions
      isLoading={loading}
      loadOptions={fetchData}
      onFocus={setDefaultResult}
      defaultOptions={defaultOptions}
      formatOptionLabel={formatOptionLabel}
      isClearable
      name={name ?? 'project_status'}
      value={value}
      placeholder={placeholder ?? ''}
      onChange={handleFieldChange}
      className={error && 'form-control ps-0 py-0 is-invalid'}
      style={{
        color: 'red'
      }}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({
          ...provided,
          zIndex: 9999,
          textTransform: 'uppercase',
          fontSize: '.7rem'
        }),
        control: provided => ({
          ...provided,
          height: 32,
          minHeight: 32,
          ...style
        })
      }}
      {...rest}
    />
  );
}

SelectTaskStatus.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func
};
