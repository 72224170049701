import { React } from 'react';
import { Modal, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';

import VoucherDetails from 'module/Finance/voucherInquiry/VoucherDetails';
import SalesOrderInfo from 'module/Sales/SalesOrder/SalesOrderPage/SalesOrderInfo';
import SalesQuotationInfo from 'module/Sales/SalesQuotation/SalesQuotationPage/SalesQuotationInfo';
import PurchaseOrderInfo from 'module/Purchase/Purchase Order/Page/PurchaseOrderInfo';
import PurchaseRequestInfo from 'module/Purchase/Purchase Request/Page/PurchaseRequestInfo';
import PurchaseDeliveryInfo from 'module/Purchase/Purchase Delivery/Page/PurchaseDeliveryInfo';
import CreditNoteInfo from 'module/Sales/CreditNote/CreditNotePage/CreditNoteInfo';
import SalesDeliveryInfo from 'module/Sales/SalesDelivery/SalesDeliveryPage/SalesDeliveryInfo';
import SalesInvoiceInfo from 'module/Sales/SalesInvoice/SalesInvoicePage/SalesInvoiceInfo';
import PurchaseInvoiceInfo from 'module/Purchase/Purchase Invoice/Page/PurchaseInvoiceInfo';
import WorkOrderInfo from 'module/Production/WorkOrder/WorkOrderPage/WorkOrderInfo';
import BatchNumberInfo from 'module/Inventory/BatchNumber/BatchNumberPage/BatchNumberInfo';
import ContractInfo from 'module/Sales/Contract/ContractPage/ContractInfo';
import DebitNoteInfo from 'module/Purchase/DebitNote/Page/DebitNoteInfo';
import EstimationEntryInfo from 'module/Production/Estimation/Page/EstimationEntryInfo';
import EnquiryInfoOffcanvas from 'components/EnquiryInfoOffcanvas/EnquiryInfoOffcanvas';
import MaterialRequestInfo from 'module/Production/MaterialRequest/Page/MaterialRequestInfo';
import RentalContractInfo from 'module/FixedAssets/RentalContract/components/RentalContractInfo';

const VoucherDetail = ({
  show,
  onHide,
  voucherId,
  infoPage,
  setShowVoucherDetailArea,
  offCanvas,
  type,
  voidTransactionReport,
  modalView,
  interBranch,
  className
}) => {
  return modalView ? (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Body className="p-0">
        {type === 'ESTIMATION' ? (
          <EstimationEntryInfo estimationEntryId={voucherId} onHide={onHide} />
        ) : null}
      </Modal.Body>
    </Modal>
  ) : type === 'ENQUIRY' ? (
    <EnquiryInfoOffcanvas show={show} onHide={onHide} targetId={voucherId} />
  ) : (
    <>
      <Offcanvas
        show={show}
        placement="end"
        onHide={onHide}
        className={className ? className : 'w-50'}
      >
        <>
          {type == 'SO' ? (
            <SalesOrderInfo
              offCanvas={offCanvas}
              salesOrderId={voucherId}
              firstId={voucherId}
              rendering={true}
              onHide={onHide}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              voidTransactionReport={voidTransactionReport}
              interBranch={interBranch}
            />
          ) : type == 'SI' ? (
            <SalesInvoiceInfo
              salesInvoiceId={voucherId}
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              voidTransactionReport={voidTransactionReport}
              interBranch={interBranch}
            />
          ) : type == 'CT' ? (
            <ContractInfo
              contractId={voucherId}
              rendering={true}
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              voidTransactionReport={voidTransactionReport}
            />
          ) : type == 'SD' ? (
            <SalesDeliveryInfo
              salesDeliveryId={voucherId}
              rendering={true}
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              voidTransactionReport={voidTransactionReport}
              interBranch={interBranch}
            />
          ) : type == 'SQ' ? (
            <SalesQuotationInfo
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              salesQuotationId={voucherId}
              rendering={true}
              voidTransactionReport={voidTransactionReport}
              onHide={onHide}
            />
          ) : type == 'PO' ? (
            <PurchaseOrderInfo
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              purchaseOrderId={voucherId}
              voidTransactionReport={voidTransactionReport}
              interBranch={interBranch}
            />
          ) : type == 'PR' ? (
            <PurchaseRequestInfo
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              purchaseRequestId={voucherId}
              voidTransactionReport={voidTransactionReport}
            />
          ) : type == 'PI' ? (
            <PurchaseInvoiceInfo
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              purchaseInvoiceId={voucherId}
              voidTransactionReport={voidTransactionReport}
              interBranch={interBranch}
            />
          ) : type == 'PD' ? (
            <PurchaseDeliveryInfo
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              purchaseDeliveryId={voucherId}
              voidTransactionReport={voidTransactionReport}
              interBranch={interBranch}
            />
          ) : type == 'CN' ? (
            <CreditNoteInfo
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              creditNoteId={voucherId}
              rendering={true}
              voidTransactionReport={voidTransactionReport}
            />
          ) : type == 'DN' ? (
            <DebitNoteInfo
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              debitNoteId={voucherId}
              rendering={true}
              voidTransactionReport={voidTransactionReport}
            />
          ) : type == 'WO' ? (
            <WorkOrderInfo
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              workOrderId={voucherId}
              rendering={true}
              voidTransactionReport={voidTransactionReport}
              onHide={onHide}
            />
          ) : type == 'BCH' ? (
            <BatchNumberInfo
              batchId={voucherId}
              offCanvas
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              rendering
            />
          ) : type === 'MR' ? (
            <MaterialRequestInfo
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              materialRequestId={voucherId}
              rendering
              voidTransactionReport={voidTransactionReport}
              onHide={onHide}
            />
          ) : type === 'RCT' ? (
            <RentalContractInfo
              offCanvas={offCanvas}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              rentalId={voucherId}
              rendering
              voidTransactionReport={voidTransactionReport}
              onHide={onHide}
            />
          ) : type === 'ES' ? (
            <EstimationEntryInfo
              estimationEntryId={voucherId}
              onHide={onHide}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              voidTransactionReport={voidTransactionReport}
              offCanvas={offCanvas}
            />
          ) : voucherId ? (
            <VoucherDetails
              infoPage={infoPage}
              setShowVoucherDetailArea={setShowVoucherDetailArea}
              itemId={voucherId}
              offCanvas={offCanvas}
              voidTransactionReport={voidTransactionReport}
              type={type}
            />
          ) : null}
        </>
      </Offcanvas>
    </>
  );
};

VoucherDetail.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  voucherId: PropTypes.string,
  infoPage: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  offCanvas: PropTypes.bool,
  type: PropTypes.string,
  voidTransactionReport: PropTypes.bool,
  modalView: PropTypes.bool,
  interBranch: PropTypes.bool,
  className: PropTypes.string
};

export default VoucherDetail;
