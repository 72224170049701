import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LaunchpadQuickLinkItem from './LaunchpadQuickLinkItem';

export default function LaunchpadQuickLinksSection({
  quickLinks,
  onPageChange,
  title
}) {
  return Array.isArray(quickLinks) && quickLinks.length > 0 ? (
    <>
      <div className="content-area-head d-flex flex-wrap">
        <p className="app-launchpad-section-title mb-3">
          {title ?? 'Quick Links'}
        </p>
      </div>

      <Row md={3} sm={2} xs={1} className="app-launchpad-quick-link-cover">
        {quickLinks.map((link, index) => (
          <Col key={index}>
            <LaunchpadQuickLinkItem
              linkData={link}
              onPageChange={onPageChange}
            />
          </Col>
        ))}
      </Row>
    </>
  ) : null;
}

LaunchpadQuickLinksSection.propTypes = {
  quickLinks: PropTypes.array,
  onPageChange: PropTypes.func,
  title: PropTypes.string
};
