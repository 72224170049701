import React from 'react';
import { Placeholder } from 'react-bootstrap';

function ActivityLoading() {
  const number = [1, 2, 3, 4, 5, 6, 7];

  return number.map(key => (
    <div className="mt-2" key={key}>
      <Placeholder
        as="p"
        animation="glow"
        className="m-0"
        style={{ height: '20px' }}
      >
        <Placeholder
          className="w-75"
          size="sm"
          bg="secondary"
          style={{ opacity: '0.01', borderRadius: '10px' }}
        />
      </Placeholder>
      <Placeholder
        as="p"
        animation="glow"
        className="m-0"
        style={{ height: '20px' }}
      >
        <Placeholder
          className="w-25"
          size="sm"
          bg="secondary"
          style={{ opacity: '0.01', borderRadius: '10px' }}
        />
      </Placeholder>
      <Placeholder
        as="p"
        animation="glow"
        className="m-0"
        style={{ height: '20px' }}
      >
        <Placeholder
          className="w-50"
          size="sm"
          bg="secondary"
          style={{ opacity: '0.01', borderRadius: '10px' }}
        />
      </Placeholder>
      <Placeholder
        as="p"
        animation="glow"
        className="m-0"
        style={{ height: '20px' }}
      >
        <Placeholder
          className="w-100"
          size="sm"
          bg="secondary"
          style={{ opacity: '0.01', borderRadius: '10px' }}
        />
      </Placeholder>
    </div>
  ));
}

export default ActivityLoading;
