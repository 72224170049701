import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import LogoPreview from './LogoPreview';
import PropTypes from 'prop-types';
import PhoneNumberInput from 'components/form/PhoneNumberInput';
import SelectCurrencyCode from 'components/form/SelectCurrencyCode';

const CompanySection = ({ formData, errors, onChange }) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="companyName">
        <Form.Label>Company Name</Form.Label>
        <Form.Control
          placeholder="Enter company name"
          name="company_name"
          onChange={onChange}
          value={formData.company_name}
          isValid={formData.company_name.length > 0 && !errors.company_name}
          isInvalid={!!errors.company_name}
        />
        {errors.company_name ? (
          <Form.Control.Feedback type="invalid">
            {errors.company_name}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>

      <Row>
        <Form.Group className="mb-3" controlId="companyEmail" as={Col} md={6}>
          <Form.Label>Company Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter company email address"
            name="company_email"
            onChange={onChange}
            value={formData.company_email}
            isValid={formData.company_email.length > 0 && !errors.company_email}
            isInvalid={!!errors.company_email}
          />
          {errors.company_email ? (
            <Form.Control.Feedback type="invalid">
              {errors.company_email}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group className="mb-3" controlId="companyPhone" as={Col} md={6}>
          <Form.Label>Company Phone</Form.Label>
          <PhoneNumberInput
            placeholder="Enter contact number"
            name="company_phone"
            onChange={onChange}
            value={formData.company_phone}
            isValid={formData.company_phone.length > 0 && !errors.company_phone}
            isInvalid={!!errors.company_phone}
          />
          {errors.company_phone ? (
            <Form.Control.Feedback type="invalid">
              {errors.company_phone}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="typeOfBussiness"
          as={Col}
          md={6}
        >
          <Form.Label>Type of Business</Form.Label>
          <Form.Select
            name="business_type"
            onChange={onChange}
            value={formData.business_type}
            isValid={formData.business_type.length > 0 && !errors.business_type}
            isInvalid={!!errors.business_type}
          >
            <option value="">Choose bussiness type</option>
            <option value="Manufacturer">Manufacturer</option>
            <option value="Financial Institution">Financial Institution</option>
            <option value="Government Agency">Government Agency</option>
          </Form.Select>
          {errors.business_type ? (
            <Form.Control.Feedback type="invalid">
              {errors.business_type}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group className="mb-3" controlId="baseCurrency" as={Col} md={6}>
          <Form.Label>Base Currency</Form.Label>
          <SelectCurrencyCode
            placeholder="Choose base currency"
            name="base_currency"
            value={formData.base_currency_ref}
            keyName="code"
            handleFieldChange={onChange}
            style={{ borderRadius: 'none' }}
            error={!!errors.base_currency}
          />

          {errors.base_currency ? (
            <Form.Control.Feedback type="invalid">
              {errors.base_currency}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group className="mb-3" controlId="employeesCount" as={Col} md={6}>
          <Form.Label>No. of Employees</Form.Label>
          <Form.Control
            type="number"
            min={1}
            placeholder="Enter company name"
            name="no_of_employees"
            onChange={onChange}
            value={formData.no_of_employees}
            isValid={
              formData.no_of_employees.length > 0 && !errors.no_of_employees
            }
            isInvalid={!!errors.no_of_employees}
          />
          {errors.no_of_employees ? (
            <Form.Control.Feedback type="invalid">
              {errors.no_of_employees}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group className="mb-3" controlId="place" as={Col} md={6}>
          <Form.Label>Place</Form.Label>
          <Form.Control
            placeholder="Enter place"
            name="place"
            onChange={onChange}
            value={formData.place}
            isValid={formData.place.length > 0 && !errors.place}
            isInvalid={!!errors.place}
          />
          {errors.place ? (
            <Form.Control.Feedback type="invalid">
              {errors.place}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="address">
        <Form.Label>Address</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          placeholder="Enter address"
          name="address"
          onChange={onChange}
          value={formData.address}
          isValid={formData.address.length > 0 && !errors.address}
          isInvalid={!!errors.address}
        />
        {errors.address ? (
          <Form.Control.Feedback type="invalid">
            {errors.address}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3" controlId="companyLogo">
        {formData.company_logo && !errors.company_logo ? (
          <LogoPreview
            logo={formData.company_logo}
            onRemove={() =>
              onChange({
                target: {
                  name: 'company_logo',
                  value: null
                }
              })
            }
          />
        ) : (
          <>
            <Form.Label>Choose Logo</Form.Label>
            <Form.Control
              type="file"
              onChange={onChange}
              accept=".jpg, .png, .jpeg, .JPEG, .PNG, .JPG"
              name="company_logo"
              isInvalid={!!errors.company_logo}
            />
            {errors.company_logo ? (
              <Form.Control.Feedback type="invalid">
                {errors.company_logo}
              </Form.Control.Feedback>
            ) : null}
          </>
        )}
      </Form.Group>
    </>
  );
};

CompanySection.propTypes = {
  formData: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func
};

export default CompanySection;
