const tabViewContentReloadHandlerReducer = (state, action) => {
  if (!Array.isArray(action?.payload)) {
    throw new Error('Invalid data type');
  }

  switch (action.type) {
    case 'ADD-TAB-TO-RELOAD-LIST':
      return {
        ...state,
        reloadPendingTabList: [
          ...state.reloadPendingTabList,
          ...action.payload.filter(
            item => !state.reloadPendingTabList.includes(item)
          )
        ]
      };

    case 'REMOVE-TAB-FROM-RELOAD-LIST':
      return {
        ...state,
        reloadPendingTabList: state.reloadPendingTabList?.filter(
          item => !action.payload?.includes(item)
        )
      };

    default:
      return state;
  }
};
export default tabViewContentReloadHandlerReducer;
