import { React } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function AmountInputField({ formData, formError, handleFieldChange, size }) {
  const Translate = useAxisproTranslate();

  return (
    <Col sm={size?.col1 ?? 12}>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column md={size?.col2 ?? 2} className="require-data">
          {Translate('Amount')}
        </Form.Label>
        <Col sm={size?.col3 ?? 3}>
          <Form.Control
            type="number"
            name="amount"
            step="0.0000000000001"
            onChange={handleFieldChange}
            value={formData.amount}
          />
        </Col>
        <Col
          xl={1}
          md={1}
          sm={1}
          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
        >
          <FormErrorPopover
            id="amount"
            totalErrorCount={Object.keys(formError).length}
            errorMessage={formError.amount}
          />
        </Col>
      </Form.Group>
    </Col>
  );
}

AmountInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func,
  size: PropTypes.any
};

export default AmountInputField;
