import React, { useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import CarouselImage from 'components/carousel/CarouselImage';
import { Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
function FixedItemTabViewDetails({ fixedAssetType, data }) {
  const [imageCarousel, setImageCarousel] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const handleImageCarousel = index => {
    setActiveImageIndex(index);
    setImageCarousel(true);
  };
  const onHideImageCarousel = () => {
    setActiveImageIndex();
    setImageCarousel(false);
  };
  const Translate = useAxisproTranslate();
  return (
    <div className="fiexed-assets-item-info ">
      <div className="image-slider">
        <Row
          className="p-1"
          style={{ backgroundColor: 'aliceblue', flexDirection: 'row-reverse' }}
        >
          <Col md={4} className="pe-0 me-0">
            {data?.images?.length > 0 && (
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false
                }}
                pagination={{
                  clickable: true
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="app-swiper cursor-pointer"
              >
                {data?.images.map((image, key) => (
                  <SwiperSlide key={key}>
                    <Image
                      onClick={() => handleImageCarousel(key)}
                      src={image?.file_url}
                      width={100}
                      height={100}
                      layout="fill"
                      objectFit="cover"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Col>
          {fixedAssetType === 'vehicle' && (
            <Col md={8}>
              <div>
                <h1 className={`text-start  mt-3 mb-3`}>
                  {Translate('Vehicle Information')}
                </h1>
              </div>

              <Row className="m-0 p-0">
                <Col
                  md={4}
                  className="d-flex flex-column align-items-start justify-content-start p-2"
                >
                  <div className="heading ">{Translate('Name')}</div>
                  <div className=" value">
                    {data?.vehicle_details?.name !== ''
                      ? data?.vehicle_details?.name
                      : '-'}
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex flex-column align-items-start justify-content-start p-2"
                >
                  <div className="heading ">{Translate('Registration No')}</div>
                  <div className=" value">
                    {data?.vehicle_details?.registration_no !== ''
                      ? data?.vehicle_details?.registration_no
                      : '-'}
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex flex-column align-items-start justify-content-start p-2"
                >
                  <div className="heading ">{Translate('Manufacturer')}</div>
                  <div className=" value">
                    {data?.vehicle_details?.manufacturer !== ''
                      ? data?.vehicle_details?.manufacturer
                      : '-'}
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex flex-column align-items-start justify-content-start p-2 mt-3"
                >
                  <div className="heading">{Translate('Model')}</div>
                  <div className=" value">
                    {data?.vehicle_details?.model !== ''
                      ? data?.vehicle_details?.model
                      : '-'}
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex flex-column align-items-start justify-content-start p-2 mt-3"
                >
                  <div className="heading">{Translate('Color')}</div>
                  <div className=" value">
                    {data?.vehicle_details?.color !== ''
                      ? data?.vehicle_details?.color
                      : '-'}
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex flex-column align-items-start justify-content-start p-2 mt-3"
                >
                  <div className="heading">{Translate('Engine Number')}</div>
                  <div className=" value">
                    {data?.vehicle_details?.engine_no !== ''
                      ? data?.vehicle_details?.engine_no
                      : '-'}
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex flex-column align-items-start justify-content-start p-2 mt-3"
                >
                  <div className="heading">{Translate('Chassis Number')}</div>
                  <div className=" value">
                    {data?.vehicle_details?.chassis_no !== ''
                      ? data?.vehicle_details?.chassis_no
                      : '-'}
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex flex-column align-items-start justify-content-start p-2 mt-3"
                >
                  <div className="heading">{Translate('Fuel')}</div>
                  <div className="value">
                    {data?.vehicle_details?.fuel_type !== ''
                      ? data?.vehicle_details?.fuel_type
                      : '-'}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          <div>
            <CarouselImage
              imageArray={
                data?.images.length
                  ? data?.images.map(item => item?.file_url)
                  : []
              }
              activeItemIndex={activeImageIndex}
              show={imageCarousel}
              onHide={onHideImageCarousel}
            />
          </div>
        </Row>
      </div>
    </div>
  );
}

FixedItemTabViewDetails.propTypes = {
  fixedAssetType: PropTypes.string,
  flexColumn: PropTypes.bool,
  columnClassName: PropTypes.string,
  data: PropTypes.any
};

export default FixedItemTabViewDetails;
