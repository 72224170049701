import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { AiFillFileAdd } from 'react-icons/ai';
import PropTypes from 'prop-types';
import axios from 'axios';
import setNewFormData from 'helpers/setNewFormData';
import { showToast } from 'module/Common/Toast/toast';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import './WorkOrderDeliveryModal.scss';
import removeRefData from 'helpers/removeRefData';

export default function WorkOrderDeliveryModal({
  onHide,
  workOrderDetailId,
  setTabApiCallCompleted,
  setWorkOrderVoucherData,
  deliverylimit
}) {
  const Translate = useAxisproTranslate();
  const formKeys = {
    delivered_date: '',
    delivered_quantity: deliverylimit
  };

  const handleFieldChange = (e, action) => {
    let newFormData = generateFormDataHelper(e, action, formData);
    setFormData(newFormData);
  };

  const [formData, setFormData] = useState(formKeys);

  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    setFormError({});

    let error = undefined;

    if (
      isNaN(parseFloat(formData?.delivered_quantity)) ||
      parseFloat(formData.delivered_quantity) < 1
    ) {
      error = Translate('Invalid quantity, value must be greater than 0,');
    } else if (parseFloat(formData.delivered_quantity) > deliverylimit) {
      error = Translate(
        'Invalid quantity, the quantity must be less than or equal to,' +
          deliverylimit
      );
    }

    if (error) {
      showToast(error, 'error');
      return;
    }

    setIsSaving(true);

    let newFormData = setNewFormData({
      work_order_detail_id: workOrderDetailId,
      ...removeRefData(formData, 'object', {
        removeValue: [null, 'null']
      })
    });

    axios
      .postForm('production/deliver-finished-goods', newFormData)
      .then(response => {
        if (response.data.success !== true) {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
          return;
        }
        showToast(response?.data?.message, 'success');
        setFormData(formKeys);
        if (
          !isNaN(response?.data?.data?.delivered_quantity) &&
          typeof setWorkOrderVoucherData === 'function'
        ) {
          let deliveryApiData = response.data.data;
          setWorkOrderVoucherData(prev => ({
            ...prev,
            details: Array.isArray(prev?.details)
              ? prev.details.reduce((newResult, item) => {
                  if (item?.id === workOrderDetailId) {
                    item.delivered_quantity =
                      deliveryApiData.delivered_quantity;
                  }
                  newResult.push(item);
                  return newResult;
                }, [])
              : []
          }));
        }

        typeof onHide === 'function' && onHide();
        typeof setTabApiCallCompleted === 'function' &&
          setTabApiCallCompleted(prev => ({
            ...prev,
            delivery: false
          }));
      })
      .catch(error => {
        setFormError(error?.response?.data?.data?.errors ?? {});
        showToast(
          error?.response?.data?.message ??
            'Something went wrong, please refresh the page and try again.',
          'error'
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  return (
    <Modal show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="text-uppercase d-flex flex-row align-items-center gap-1 fw-bold">
          <AiFillFileAdd size={18} className="text-success" />
          {Translate('Deliver finished goods')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof formError === 'object' && Object.keys(formError).length > 0 && (
          <ErrorAlert formError={formError} setFormError={setFormError} />
        )}
        <Form onSubmit={handleSubmit} className="wo-deliver-form">
          <Form.Group
            className="mb-4 d-flex flex-row gap-4"
            controlId="form.delivered_date"
          >
            <Form.Label className="require-data">
              {Translate('Date')}
            </Form.Label>
            <div className="flex-fill d-flex flex-row align-items-center">
              <div className="flex-fill">
                <AppDatePicker
                  name="delivered_date"
                  value={formData.delivered_date}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  onChange={handleFieldChange}
                  showDefaultDate
                />
              </div>
              <FormErrorPopover
                id="delivered_date"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={formError?.delivered_date}
                addSpace
              />
            </div>
          </Form.Group>

          <Form.Group
            className="mb-4 d-flex flex-row gap-4"
            controlId="form.delivered_quantity"
          >
            <Form.Label className="require-data">
              {Translate('Quantity')}
            </Form.Label>
            <div className="flex-fill d-flex flex-row align-items-center">
              <div className="flex-fill">
                <Form.Control
                  type="number"
                  name="delivered_quantity"
                  onChange={handleFieldChange}
                  min={1}
                  max={deliverylimit}
                  value={formData.delivered_quantity}
                />
              </div>
              <FormErrorPopover
                id="delivered_quantity"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={formError?.delivered_quantity}
                addSpace
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {!isSaving ? (
          <Button variant="danger" size="sm" onClick={onHide}>
            {Translate('Cancel')}
          </Button>
        ) : null}

        <Button
          variant="success"
          size="sm"
          onClick={handleSubmit}
          disabled={isSaving}
          className="d-flex flex-row gap-1 align-items-center justify-content-center"
        >
          {isSaving ? (
            <>
              <Spinner
                style={{
                  width: 15,
                  height: 15
                }}
              />{' '}
              {Translate('Saving')}
            </>
          ) : (
            Translate('Save')
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

WorkOrderDeliveryModal.propTypes = {
  onHide: PropTypes.func,
  workOrderDetailId: PropTypes.string,
  setTabApiCallCompleted: PropTypes.func,
  setWorkOrderVoucherData: PropTypes.func,
  deliverylimit: PropTypes.number
};
