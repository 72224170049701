import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';

const tableHeader = ({
  title,
  handleNew,
  handleFilter,
  handleExport,
  selectedRowIds,
  handleClose
}) => {
  return (
    <Row className="flex-between-center w-100">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap text-dark py-sm-2 py-xl-0 text-uppercase">
          {title}
        </h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {selectedRowIds && Object.keys(selectedRowIds)?.length > 0 ? (
          // <div className="d-flex">
          //   <Form.Select size="sm" aria-label="Bulk actions">
          //     <option>Bulk Actions</option>
          //     <option value="delete">Delete</option>
          //   </Form.Select>
          //   <Button
          //     type="button"
          //     variant="falcon-default"
          //     size="sm"
          //     className="ms-2"
          //   >
          //     Apply
          //   </Button>
          // </div>
          ''
        ) : (
          <div id="orders-actions">
            {handleClose ? (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="times"
                transform="shrink-3"
                onClick={handleClose}
              >
                <span className="d-none d-sm-inline-block ms-1">Close</span>
              </IconButton>
            ) : (
              <>
                {handleNew && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    onClick={handleNew}
                  >
                    <span className="d-none d-sm-inline-block ms-1">New</span>
                  </IconButton>
                )}
                {handleFilter && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="filter"
                    transform="shrink-3"
                    className="mx-2"
                    onClick={handleFilter}
                  >
                    <span className="d-none d-sm-inline-block ms-1">
                      Filter
                    </span>
                  </IconButton>
                )}
                {handleExport && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="external-link-alt"
                    transform="shrink-3"
                    onClick={handleExport}
                  >
                    <span className="d-none d-sm-inline-block ms-1">
                      Export
                    </span>
                  </IconButton>
                )}
              </>
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};

tableHeader.prototype = {
  title: PropTypes.string,
  selectedRowIds: PropTypes.object
};

export default tableHeader;
