import { React } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';

function TransactionDateInputField({
  formData,
  formError,
  handleFieldChange,
  size,
  showDefaultDate
}) {
  const Translate = useAxisproTranslate();

  return (
    <Col md={size?.col1 ?? 12}>
      <Form.Group as={Row} className="mb-3" controlId="transdateConrol">
        <Form.Label column md={size?.col2 ?? 2} className="require-data">
          {Translate('Transaction Date')}
        </Form.Label>
        <Col sm={size?.col3 ?? 3}>
          <AppDatePicker
            name="trans_date"
            value={formData.trans_date}
            yearPlaceholder="yyyy"
            monthPlaceholder="mm"
            dayPlaceholder="dd"
            onChange={handleFieldChange}
            showDefaultDate={showDefaultDate}
          />
        </Col>
        <Col
          xl={1}
          md={1}
          sm={1}
          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
        >
          <FormErrorPopover
            id="trans_date"
            totalErrorCount={Object.keys(formError).length}
            errorMessage={formError.trans_date}
          />
        </Col>
      </Form.Group>
    </Col>
  );
}

TransactionDateInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func,
  size: PropTypes.any,
  showDefaultDate: PropTypes.any
};

export default TransactionDateInputField;
