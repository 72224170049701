export const totalOrder = [20, 40, 100, 120];

export const marketShare = [
  { id: 1, value: 53000000, name: 'Samsung', color: 'primary' },
  { id: 2, value: 19000000, name: 'Huawei', color: 'info' },
  { id: 3, value: 20000000, name: 'Apple', color: 'orange' }
];

export const weather = {
  city: 'New York City',
  condition: 'Sunny',
  precipitation: '50%',
  temperature: 31,
  highestTemperature: 32,
  lowestTemperature: 25
};
