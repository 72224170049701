import { React, useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

function ResizeWindow({
  children,
  colSize,
  setColSize,
  holdSize,
  setHoldSize
}) {
  const [startX, setStartX] = useState(0);
  const [hovering, setHovering] = useState(false);
  const [resizing, setResizing] = useState(false);
  const colRef = useRef(null);

  const handleMouseDown = e => {
    if (e.button === 0) {
      setResizing(true);
      setStartX(e.clientX);
      document.body.style.cursor = 'ew-resize';
    }
  };

  const handleMouseMove = e => {
    if (resizing && colSize >= 400) {
      const delta = e.clientX - startX;
      const newWidth =
        Math.max(100, colSize + delta) <= 400
          ? 400
          : Math.max(100, colSize + delta) >= 1000
          ? 1000
          : Math.max(100, colSize + delta);
      setColSize(newWidth);
      setStartX(e.clientX);
    } else if (hovering) {
      document.body.style.cursor = 'ew-resize';
    }
    const bodyComputedStyle = window.getComputedStyle(document.body);
    const bodyCursorPropertyValue =
      bodyComputedStyle.getPropertyValue('cursor');
    if (bodyCursorPropertyValue === 'auto') {
      setTimeout(() => {
        setResizing(false);
      }, 2000);
    }
  };

  const handleMouseUp = () => {
    setResizing(false);
    document.body.style.cursor = 'auto';
  };

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
    document.body.style.cursor = 'auto';
  };

  useEffect(() => {
    if (colSize == 400 && !resizing) {
      setHoldSize(false);
    } else if (colSize > 400) {
      setHoldSize(true);
    }
  }, [colSize]);

  useEffect(() => {
    if (resizing) {
      document.body.style.cursor = 'ew-resize !important';
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [resizing]);

  const containerStyle = {
    width: holdSize ? `${colSize}px` : '',
    minWidth: holdSize ? '400px' : '',
    maxWidth: holdSize ? '1000px' : '',
    cursor: resizing ? 'ew-resize' : 'default',
    userSelect: 'none',
    transition:
      'flex-basis 0.3s ease-in-out, max-width 0.3s ease-in-out, flex-grow 0.3s ease-in-out'
  };

  return (
    <Col
      sm={12}
      ref={colRef}
      md={3}
      style={containerStyle}
      className="pe-0 position-relative d-flex "
    >
      <div className="w-100">{children}</div>
      <div
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        // onMouseUp={handleMouseUp}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ width: '10px', cursor: 'ew-resize' }}
      ></div>
    </Col>
  );
}

ResizeWindow.propTypes = {
  children: PropTypes.any,
  resizing: PropTypes.bool,
  setResizing: PropTypes.func,
  colSize: PropTypes.any,
  setColSize: PropTypes.func,
  holdSize: PropTypes.bool,
  setHoldSize: PropTypes.func
};

export default ResizeWindow;
