import { React } from 'react';
import { Form, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import TableLoading from 'module/Report/Common/TableLoading';

const IssueItemPopUpTable = ({ entries, setFormData, formData, isLoading }) => {
  const Translate = useAxisproTranslate();
  const handleItemChange = (e, action, key) => {
    if (action.action === 'select-option') {
      const item = entries[key];
      const newItems = [...entries];
      newItems[key] = {
        ...item,
        [action.name]: e.code
      };
      const newFormData = {
        ...formData,
        items: newItems,
        [action.name]: e.code,
        [action.name + '_ref']: e
      };
      setFormData(newFormData);
    } else {
      const rowIndex = e.target.getAttribute('data-row');
      const item = entries[rowIndex];
      const issuableQuantity = parseFloat(item?.max_issuable_quantity) || 0;
      if (
        e.target.value > issuableQuantity ||
        e.target.value > parseInt(item.qoh)
      ) {
        showToast(
          'You cannot issue items more than requested quantity or quantity in hand',
          'error'
        );
      } else {
        const newItems = [...entries];
        newItems[rowIndex] = {
          ...item,
          quantity: e.target.value
        };

        const newFormData = {
          ...formData,
          items: newItems
        };

        setFormData(newFormData);
      }
    }
  };

  return (
    <Table bordered responsive className="form-table-layout mb-4">
      <thead
        className="text-dark"
        style={{ backgroundColor: 'rgb(237,243,248)' }}
      >
        <tr>
          <th
            className="table-header-size text-center"
            style={{ width: '40px' }}
          >
            #
          </th>
          <th className="table-header-size text-center">
            {Translate('Item Code')}
          </th>
          <th className="table-header-size text-center">
            {Translate('Item Name')}
          </th>
          <th className="table-header-size text-center">{Translate('QOH')}</th>
          <th className="table-header-size text-center">
            {Translate('Requested Quantity')}
          </th>
          <th className="table-header-size text-center">
            {Translate('Issued Quantity')}
          </th>
          <th
            style={{ minWidth: '200px' }}
            className="table-header-size text-center"
          >
            {Translate('Account')}
          </th>
          <th
            className="table-header-size text-center"
            style={{ width: '150px' }}
          >
            {Translate('Issuing Quantity')}
          </th>
        </tr>
      </thead>
      <tbody className="text-dark ">
        {isLoading ? (
          <TableLoading row={1} column={8} />
        ) : (
          entries.map((entry, key) => (
            <tr key={key}>
              <td className="fs--1 text-dark p-1 text-center">{key + 1}</td>
              <td className="text-center">{entry.stock_id}</td>
              <td className="text-center">{entry.item_display_name}</td>
              <td className="text-center">{entry.qoh}</td>
              <td className="text-center">{entry.requested_quantity}</td>
              <td className="text-center">{entry.issued_quantity}</td>
              <td className="text-center">{entry?.account_name_ref}</td>
              <td className="p-1">
                <Form.Control
                  name="quantity"
                  placeholder="Enter Quantity"
                  disabled={
                    entry.requested_quantity == entry.issued_quantity ||
                    entry.qoh <= 0
                  }
                  value={entry.quantity}
                  onChange={e => handleItemChange(e, key)}
                  data-row={key}
                />
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

IssueItemPopUpTable.propTypes = {
  entries: PropTypes.array,
  formError: PropTypes.object,
  setFormData: PropTypes.func,
  setFormError: PropTypes.func,
  formData: PropTypes.any,
  isLoading: PropTypes.bool
};

export default IssueItemPopUpTable;
