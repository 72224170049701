import AppContext from 'context/Context';
import React, { useContext, useState } from 'react';
import { FaMoon } from 'react-icons/fa';
import { IoMdSunny } from 'react-icons/io';

function ToggleSwitch() {
  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);
  const [isChecked, setIsChecked] = useState(false);
  const handleToggle = () => {
    setIsChecked(!isChecked);
    localStorage.setItem('isDarkTheme', !isChecked);
    setConfig('isDark', !isChecked);
  };

  return (
    <div className="d-flex align-item-center justify-content-center mt-2 me-2 ms-2 toggle-main-layout">
      <input
        type="checkbox"
        id="toggle"
        checked={isDark}
        onChange={handleToggle}
      />
      <label htmlFor="toggle">
        <FaMoon className="moon-icon" size={10} />
        <IoMdSunny className="sun-icon" size={14} />
      </label>
    </div>
  );
}

export default ToggleSwitch;
