import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

function AppLayer() {
  return (
    <Modal
      show={true}
      size="lg"
      className="d-flex align-items-center justify-content-center"
    >
      <div className="d-flex">
        {['secondary'].map(varient => (
          <Spinner
            key={varient}
            animation="border"
            role="status"
            variant={varient}
            className="m-1"
            size="sm"
            style={{
              height: '1rem',
              width: '1rem'
            }}
          />
        ))}
      </div>
    </Modal>
  );
}

export default AppLayer;
