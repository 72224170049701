import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Button, Form, Image, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsFillSendFill } from 'react-icons/bs';

import { showToast } from 'module/Common/Toast/toast';
import { checkWhatsAppNumber, convertBlobUrlToBase64 } from './functions';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import PhoneNumberInput from 'components/form/PhoneNumberInput';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import ModalHeader from './ModalHeader';

function MessagePopup({
  show,
  onHide,
  qrCode,
  authenticated,
  modalClosingNotification,
  pdfUrl,
  pdfName,
  whatsAppSessionId,
  whatsAppNumberToPopulate,
  restartSession
}) {
  const Translate = useAxisproTranslate();
  const [cells, setCells] = useState([]);
  const [formData, setFormData] = useState({});
  const [notAValidNumber, setNotAValidNumber] = useState(false);
  const [send, setSend] = useState(false);
  const [base64, setBase64] = useState({
    contentType: 'MessageMedia',
    chatId: '@c.us',
    content: {
      mimetype: 'application/pdf',
      filename: pdfName
    }
  });

  const addArrayItems = count => {
    let array = [];
    for (let index = 0; index < count; index++) {
      array.push(index);
    }
    return array;
  };

  const fetchPdfBase64Data = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: pdfUrl,
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/octet-stream'
        }
      });

      const pdfFile = new Blob([response.data], { type: 'application/pdf' });
      if (pdfFile instanceof Blob) {
        const blobUrl = window.URL.createObjectURL(pdfFile);
        const base64 = await convertBlobUrlToBase64(blobUrl);

        return new Promise(resolve => {
          setBase64(prevBase64 => {
            const updatedBase64 = {
              ...prevBase64,
              content: {
                ...prevBase64.content,
                data: base64
              }
            };
            resolve(updatedBase64);
            return updatedBase64;
          });
        });
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  useEffect(() => {
    setCells(addArrayItems(250));
    return () => {
      setCells([]);
    };
  }, [show]);

  const handleFieldChange = (e, action) => {
    e.target.value = e.target.value.replace(/\s+/g, '');
    let inputValue = e.target.value;
    let pattern = /^[+{1}]?\d*$/;
    if (pattern.test(inputValue)) {
      setFormData(generateFormDataHelper(e, action, base64));
    }
  };

  const sendWhatsAppMessage = async () => {
    setSend(true);
    setNotAValidNumber(false);
    const isWhatsAppNumberValid = await checkWhatsAppNumber(
      whatsAppSessionId,
      formData?.number
    );
    if (isWhatsAppNumberValid) {
      onHide();
      setSend(false);
      try {
        let base64Payload = base64;
        if (pdfUrl) {
          base64Payload = await fetchPdfBase64Data();
        }
        const session = await fetch(
          `${process.env.REACT_APP_WHATSAPP_BOT_URL}/client/sendMessage/${whatsAppSessionId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(base64Payload)
          }
        );
        const data = await session.json();
        if (!data?.success) {
          showToast(
            'Message is not send, please refresh the page and try again.',
            'error'
          );
        }
      } catch (error) {
        showToast(
          'Something went wrong, please refresh the page and try again.',
          'error'
        );
      }
    } else {
      setNotAValidNumber(true);
      setSend(false);
    }
  };

  useEffect(() => {
    if (formData?.number) {
      setBase64(prev => ({
        ...prev,
        chatId: formData?.number + '@c.us'
      }));
    }
  }, [formData?.number]);

  useEffect(() => {
    if (whatsAppNumberToPopulate) {
      setFormData(prev => ({
        ...prev,
        number: whatsAppNumberToPopulate
      }));
    }
  }, [whatsAppNumberToPopulate]);

  console.log(authenticated);

  return (
    <Modal
      show={show}
      backdrop="static"
      size="md"
      scrollable
      className="whatsapp-modal"
    >
      {qrCode && !authenticated ? (
        <>
          <Modal.Header className="m-0 modal-header">
            <ModalHeader title={Translate('Scan QR code')} onHide={onHide} />
          </Modal.Header>
          <Modal.Body className="d-flex align-items-center justify-content-center flex-column qr-code-modal-body pt-0">
            <Image src={qrCode} alt="qr-code" />
            {modalClosingNotification && (
              <>
                <Alert variant="info" className="w-100 text-center fs--2 p-1">
                  {Translate('Close the popup after scanning!')}
                </Alert>
                <h1
                  className="fs--2 text-primary m-0"
                  onClick={restartSession}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  {Translate('Click here to regenerate QR code again')}
                </h1>
              </>
            )}
          </Modal.Body>
        </>
      ) : authenticated ? (
        <>
          <Modal.Header className="m-0 modal-header">
            <ModalHeader
              title={Translate('Recipient Number')}
              onHide={onHide}
            />
          </Modal.Header>
          <Modal.Body className="p-3 pt-2 whatsapp-modal-body">
            {notAValidNumber ? (
              <Alert variant="danger">
                {Translate(
                  'The entered phone number is not registered in whatsapp!'
                )}
              </Alert>
            ) : null}
            <Form>
              <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100 text-dark">
                {Translate('Whatsapp Number(Country Code Required)')}
              </Form.Label>
              <div className="w-75">
                <PhoneNumberInput
                  name="number"
                  onChange={handleFieldChange}
                  value={formData.number}
                  autoFocus={true}
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer className="whatsapp-modal-footer">
            <Button
              variant="success"
              size="sm"
              onClick={sendWhatsAppMessage}
              disabled={send}
            >
              {send ? (
                <>{Translate('Sending...')}</>
              ) : (
                <>
                  <BsFillSendFill className="me-1" size={13} />
                  {Translate('Send')}
                </>
              )}
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Header className="m-0 modal-header">
            <ModalHeader
              title={Translate('Generate QR code')}
              onHide={onHide}
            />
          </Modal.Header>
          <Modal.Body className="p-3 pt-2">
            <div className="qr-container p-2">
              <div className="qr-code">
                {cells?.length > 0 &&
                  cells.map(index => (
                    <div key={index} className="qr-cell"></div>
                  ))}
              </div>
            </div>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}

MessagePopup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  qrCode: PropTypes.any,
  authenticated: PropTypes.bool,
  modalClosingNotification: PropTypes.bool,
  pdfUrl: PropTypes.string,
  pdfName: PropTypes.string,
  whatsAppSessionId: PropTypes.string,
  whatsAppNumberToPopulate: PropTypes.string,
  restartSession: PropTypes.func
};

export default MessagePopup;
