import { React } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectSalesType from '../Search/SelectSalesType';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function SalesTypeInputField({
  formData,
  uiDesign,
  validations,
  handleSalesType,
  formError,
  setFormData,
  setSalesType,
  editDisable,
  title
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group as={Col} xxl={4} xl={6} md={6} sm={12} className="mb-2">
      <Row>
        <Col xl={4} md={4} sm={12} className="pe-0">
          <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
            {Translate(title)}
          </Form.Label>
        </Col>
        <Col xl={8} md={8} sm={12} className="ps-0">
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
              {(uiDesign && uiDesign.convertingScreen) ||
              (validations && validations.fromSd) ||
              (validations && validations.fromSi) ||
              (validations && validations.fromSo) ||
              (formData.details && formData.details.length > 0) ||
              editDisable ? (
                <Form.Control
                  value={
                    formData?.sales_type_id_ref?.label ??
                    formData?.sales_type_id_ref
                  }
                  disabled
                />
              ) : (
                <SelectSalesType
                  value={formData.sales_type_id_ref}
                  name={formData.sales_type_id_ref}
                  handleFieldChange={handleSalesType}
                  setFormData={setFormData}
                  setSalesType={setSalesType}
                />
              )}
            </Col>
            {(formData.details && formData.details.length > 0) ||
            editDisable ? (
              ''
            ) : (
              <Col
                xl={1}
                md={1}
                sm={1}
                className="text-danger d-flex align-items-center m-0 p-sm-0 flex-1 ms-1"
              >
                {formError.sales_type_id ? (
                  <FormErrorPopover
                    id="sales_type_id"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.sales_type_id}
                  />
                ) : (
                  ''
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

SalesTypeInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  uiDesign: PropTypes.any,
  validations: PropTypes.any,
  setFormData: PropTypes.func,
  setSalesType: PropTypes.func,
  editDisable: PropTypes.bool,
  handleSalesType: PropTypes.func,
  title: PropTypes.string
};

export default SalesTypeInputField;
