import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import ReactSelectAddButton from 'components/add-button/ReactSelectAddButton';
import FormSettingsTemplatePopupForm from './popup-forms/FormSettingsTemplatePopupForm';
import useFormSettingsHook from 'hooks/useFormSettingsHook';
import { formSettingsPropTypesAllowdTypeItems } from 'data/form-settings-data/formSettingsData';

export default function SelectFormSettingsTemplate({
  type,
  name,
  onFocus,
  placeholder,
  value,
  handleFieldChange,
  error,
  style,
  withAddButton,
  ...rest
}) {
  const [showFormWindow, setShowFormWindow] = useState(false);
  const { templates } = useFormSettingsHook(type);
  const loading = !templates ? true : false;
  const options = templates || [];

  return (
    <>
      <Select
        options={options}
        cacheOptions
        placeholder={placeholder ?? ''}
        isLoading={loading}
        isClearable={false}
        onFocus={onFocus}
        value={value}
        onChange={handleFieldChange}
        name={name ?? 'cost_center'}
        classNamePrefix="app-react-select"
        className={`w-100  ${
          error ? ' form-control ps-0 py-0 is-invalid' : ''
        }`}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 }),
          control: provided => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style
          })
        }}
        components={
          withAddButton
            ? {
                Menu: props => (
                  <ReactSelectAddButton
                    props={props}
                    setShowFormWindow={() => setShowFormWindow(true)}
                    title="Add New Template"
                  />
                )
              }
            : null
        }
        menuPortalTarget={document.body}
        {...rest}
      />

      {withAddButton ? (
        <FormSettingsTemplatePopupForm
          type={type}
          show={showFormWindow}
          method="ADD"
          onHide={() => setShowFormWindow(false)}
        />
      ) : null}
    </>
  );
}

SelectFormSettingsTemplate.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  withAddButton: PropTypes.bool,
  onFocus: PropTypes.func,
  type: PropTypes.oneOf(formSettingsPropTypesAllowdTypeItems).isRequired
};
