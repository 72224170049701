import { React, useEffect, useState } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';

function TablePlaceholder({ row, column }) {
  const [rowRef, setRowRef] = useState([]);
  const [colRef, setColRef] = useState([]);

  const addArrayItems = count => {
    let array = [];
    for (let index = 0; index < count; index++) {
      array.push(index);
    }
    return array;
  };

  useEffect(() => {
    if (Array.isArray(row)) {
      setRowRef(row);
    } else if (Number.isInteger(row) && row > 0) {
      setRowRef(addArrayItems(row));
    }

    if (Array.isArray(column)) {
      setRowRef(column);
    } else if (Number.isInteger(column) && column > 0) {
      setColRef(addArrayItems(column));
    }

    return () => {
      setRowRef([]);
      setColRef([]);
    };
  }, [column, row]);

  return rowRef.map(rowIndex => (
    <tr key={rowIndex}>
      {colRef.map(colIndex => (
        <td className="table-loading-wrapper p-1" key={colIndex}>
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="sm"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
      ))}
    </tr>
  ));
}

TablePlaceholder.propTypes = {
  column: PropTypes.any,
  row: PropTypes.any
};

export default TablePlaceholder;
