import { React, useEffect, useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

function SelectRole({ handleFieldChange, name, error, value, addForm }) {
  // const [optionsArray, setOptionsArray] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  // const defaultSelect = data => {
  //   handleFieldChange(
  //     { label: data[0]?.name, value: data[0]?.id },
  //     { action: 'select-option' }
  //   );
  // };
  const setItems = async name => {
    setLoading(true);
    try {
      let response = await axios.get('roles-autocomplete', {
        params: { name: name }
      });
      let data =
        response && response.data && response.data.data && response.data.data
          ? response.data.data
          : [];

      // setOptionsArray(
      //   data.map(item => ({
      //     label: item.name,
      //     value: item.id
      //   }))
      // );
      // if (!value) defaultSelect(data);
      return data.map(item => ({
        label: item.name,
        value: item.id
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleFocus = async () => {
    if (defaultOptions.length === 0) {
      const defaultOptionsData = await setItems('');
      setDefaultOptions(defaultOptionsData);
    }
  };

  useEffect(() => {
    if (addForm) {
      handleFocus();
    }
  }, [addForm]);

  return (
    <>
      <AsyncSelect
        cacheOptions
        key={JSON.stringify(defaultOptions)}
        loadOptions={setItems}
        defaultOptions={defaultOptions}
        onFocus={handleFocus}
        isSelected={true}
        isLoading={loading}
        name={name ? name : 'roles'}
        value={value ?? ''}
        placeholder=""
        onChange={handleFieldChange}
        classNamePrefix="app-react-select"
        className={`${error && 'is-invalid'}`}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({
            ...provided,
            zIndex: 9999,
            fontFamily: 'sans-serif',
            fontSize: '12px',
            borderRadius: '0px',
            textTransform: 'capitalize',
            overflow: 'hidden'
          }),
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: '0px',
            border: '1px solid #b5b5b5',
            height: '10px'
          })
        }}
        menuPortalTarget={document.body}
      ></AsyncSelect>
    </>
  );
}

SelectRole.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.any,
  error: PropTypes.any,
  value: PropTypes.any,
  addForm: PropTypes.bool
};

export default SelectRole;
