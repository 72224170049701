import { React, useContext, useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import axios from 'axios';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';

import { FaUserPlus } from 'react-icons/fa';

import { supplierPopupFormKeys } from 'helpers/formKeys';
import { StoreContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import PopupFormButton from 'components/popup-form-button/PopupFormButton';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import PhoneNumberInput from '../PhoneNumberInput';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useRemoveParams from 'module/Common/Hooks/useRemoveParams';

const SupplierPopupForm = ({
  callBack,
  setData,
  labelName,
  keyName,
  name,
  showAddButton,
  onHide,
  show,
  includeDetailsKey
}) => {
  const Translate = useAxisproTranslate();
  const { store } = useContext(StoreContext);
  const [showWindow, setShowWindow] = useState(show ?? false);
  const [formData, setFormData] = useState(supplierPopupFormKeys);
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const allQueryParams = Object.fromEntries([...queryParams]);

  const handleShow = () => {
    setShowWindow(!showWindow);
    if (showWindow) {
      setFormData(supplierPopupFormKeys);
      setFormError({});
      setIsSaving(false);
      navigate(
        `?${createSearchParams(
          useRemoveParams(allQueryParams, ['add_new_supplier'])
        )}`
      );
      if (onHide && typeof onHide === 'function') onHide();
    }
  };
  const handleFieldChange = (e, action) =>
    setFormData(generateFormDataHelper(e, action, formData));
  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setIsSaving(true);

    axios({
      method: 'post',
      url: 'purchase/supplier',
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');

          if (callBack) {
            callBack(response.data.data);
          }

          if (response.data.data && setData) {
            setData(
              {
                label: response.data.data[labelName ?? 'name'],
                value: response.data.data[keyName ?? 'id'],
                ...(includeDetailsKey
                  ? {
                      supplier_details: response.data.data
                    }
                  : null)
              },
              {
                action: 'select-option',
                option: undefined,
                name: name ?? 'supplier_id'
              }
            );
          }

          handleShow();
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setIsSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setIsSaving(false);
      });
  };

  useEffect(() => {
    setShowWindow(show ?? false);
    return () => {
      setFormData(supplierPopupFormKeys);
      setFormError({});
      setShowWindow(false);
      setIsSaving(false);
    };
  }, [show]);

  return (
    <>
      {showAddButton ? (
        <PopupFormButton onClick={handleShow}>
          {Translate('Add Supplier')}
        </PopupFormButton>
      ) : null}

      {showWindow ? (
        <Modal
          show={showWindow}
          onHide={handleShow}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="d-flex flex-row align-items-center">
                <FaUserPlus className="text-success" />
                <span className="ms-1">{Translate('ADD SUPPLIER')}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(formError).length > 0 && (
                <ErrorAlert formError={formError} setFormError={setFormError} />
              )}
              <Row>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="supplierName"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Name')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <Form.Control
                        type="text"
                        name="name"
                        onChange={handleFieldChange}
                        value={formData.name}
                      />
                      <FormErrorPopover
                        id="name"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.name}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="supplierEmail"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className=" m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Email Address')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <Form.Control
                        type="email"
                        name="email_address"
                        onChange={handleFieldChange}
                        value={formData.email_address}
                      />
                      <FormErrorPopover
                        id="email"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.email}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="supplierContactNumber"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className=" m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Contact Number')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <PhoneNumberInput
                        name="contact_number"
                        onChange={handleFieldChange}
                        value={formData.contact_number}
                      />
                      <FormErrorPopover
                        id="contact_number"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.contact_number}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="supplierSecondaryContactNumber"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Secondary Contact Number')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <PhoneNumberInput
                        name="secondary_contact_number"
                        onChange={handleFieldChange}
                        value={formData.secondary_contact_number}
                      />
                      <FormErrorPopover
                        id="secondary_contact_number"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.secondary_contact_number}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="supplierAddress"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="m-0 d-flex align-items-center justify-content-start">
                        {Translate('Address')}
                      </Form.Label>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="address"
                        onChange={handleFieldChange}
                        value={formData.address}
                      />
                      <FormErrorPopover
                        id="address"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.address}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3"
                  controlId="supplierTrn"
                >
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className=" m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate(
                          `${store?.globalConfiguration?.tax_label} Number`
                        )}
                      </Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Form.Control
                        type="text"
                        name="supplier_trn"
                        onChange={handleFieldChange}
                        value={formData.supplier_trn}
                      />
                      <FormErrorPopover
                        id="supplier_trn"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.supplier_trn}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-row justify-content-start">
              <Button
                variant="outline-success"
                size="sm"
                className="d-flex align-items-center justify-content-center"
                onClick={handleSubmit}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="success"
                      className="me-1"
                    />
                    <span className="fs--1">{Translate('Saving...')}</span>
                  </>
                ) : (
                  <span>{Translate('Add')}</span>
                )}
              </Button>
              <Button
                variant="outline-danger"
                onClick={handleShow}
                size="sm"
                disabled={isSaving}
              >
                {Translate('Cancel')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

SupplierPopupForm.propTypes = {
  callBack: PropTypes.func,
  setData: PropTypes.func,
  name: PropTypes.string,
  labelName: PropTypes.string,
  keyName: PropTypes.string,
  show: PropTypes.bool,
  showAddButton: PropTypes.bool,
  onHide: PropTypes.func,
  includeDetailsKey: PropTypes.bool
};

export default SupplierPopupForm;
