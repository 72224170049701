import React from 'react';
import { toast } from 'react-toastify';

import { MdDoneAll } from 'react-icons/md';
import { BiErrorAlt } from 'react-icons/bi';

let currentToastId = null;

export const showToast = (message, type) => {
  if (currentToastId) {
    toast.dismiss(currentToastId);
  }

  const options = {
    theme: 'light',
    position: 'top-center',
    type: 'default',
    autoClose: 2000,
    className: type === 'success' ? 'success' : 'warnings',
    icon:
      type === 'success' ? (
        <MdDoneAll size={20} className="text-success" />
      ) : (
        <BiErrorAlt size={20} className="text-danger" />
      )
  };

  currentToastId = toast(message, options);
};
