import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Offcanvas, Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsFillChatQuoteFill } from 'react-icons/bs';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ChatTab from './messengerTabs/ChatTab';
import GroupTab from './messengerTabs/GroupTab';
import GroupFormModal from './MessengerGroups/GroupFormModal';
import Search from 'components/search/Search';
import { MdOutlineGroupAdd } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';

const ChatWindowOffCanvas = ({ show, setShowChatWindow }) => {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const [chatUsersData, setChatUsersData] = useState([]);
  const [preChatUserData, setPreChatUserData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [preGroupData, setPreGroupData] = useState([]);
  const [activeTab, setActiveTab] = useState('chat');
  const [showGroupForm, setShowGroupForm] = useState(false);
  const [groupLinks, setGroupLinks] = useState([]);
  const [loading, setLoading] = useState(false);

  const readUnreadMessage = (type, groupOrUserId) => {
    if (
      type != 'undefined' &&
      type != '' &&
      type &&
      groupOrUserId != 'undefined' &&
      groupOrUserId != '' &&
      groupOrUserId
    ) {
      axios
        .get('user/read-chat', {
          params: {
            user_id: type == 'INDIVIDUAL' ? groupOrUserId : '',
            group_id: type == 'GROUP' ? groupOrUserId : '',
            type: type
          }
        })
        .then(() => {
          fetchUsers();
          fetchGroups();
        });
    }
  };

  const hideOffCanvas = () => {
    setShowChatWindow(false);
  };

  const fetchUsers = async () => {
    setLoading(true);
    let response = await axios.get('user/chat-users');
    if (response.data.success) {
      if (response.data.data) {
        setChatUsersData(response.data.data);
        setPreChatUserData(response.data.data);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const handleFilter = e => {
    let filterValue = e.target.value;
    if (activeTab === 'chat') {
      let filteredItems = preChatUserData?.filter(
        item =>
          item.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
      );
      if (filteredItems.length > 0) {
        setChatUsersData(filteredItems);
      }
    }
    if (activeTab === 'group') {
      let filteredGroups = preGroupData?.data?.filter(
        item =>
          item.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
      );
      if (filteredGroups?.length > 0) {
        setGroupData(() => ({ ...preGroupData, data: filteredGroups }));
      }
    }
  };

  const fetchGroups = async () => {
    setLoading(true);
    let response = await axios.get('user/chat-groups');
    if (response.data.success) {
      if (response.data.data) {
        setGroupData(response.data.data.data);
        setPreGroupData(response.data.data);
        setGroupLinks(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      fetchUsers();
      fetchGroups();
    }
  }, [show]);

  useEffect(() => {
    const currentURL = window.location.href;
    const urlObject = new URL(currentURL);
    const domain = urlObject.hostname;
    window.Echo.channel(`user_chat_${domain}_${user.id}`).listen(
      'ChatBroadcast',
      () => {
        fetchUsers();
      }
    );
    return () => {
      window.Echo.channel(`user_chat_${domain}_${user.id}`).stopListening(
        'ChatBroadcast'
      );
    };
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        placement="end"
        className="messager-offcanvas-custom-style"
      >
        <Offcanvas.Header className="border-bottom bg-dark text-light">
          <Offcanvas.Title className="fw-bold mb-0 d-flex flex-row align-items-center pt-2 ">
            <BsFillChatQuoteFill color="white" size={20} className="me-1" />
            <h6 className="form-heading text-start gap-1 text-light">
              <span>{Translate('Messenger')}</span>
            </h6>
          </Offcanvas.Title>
          <IoMdClose
            className="text-light cursor-pointer"
            size={18}
            onClick={hideOffCanvas}
          />
        </Offcanvas.Header>

        <Offcanvas.Body className="p-0 d-flex flex-column position-relative">
          {activeTab == 'group' && (
            <Button
              size="sm"
              variant="transparent"
              className="add-group-button"
              onClick={() => setShowGroupForm(true)}
            >
              <MdOutlineGroupAdd className="me-2" size={15} /> Crate Group{' '}
            </Button>
          )}
          <Tabs
            activeKey={activeTab}
            onSelect={setActiveTab}
            className="w-100 ms-2 d-flex flex-row gap-3 messager-offcanvas-tabs-style"
          >
            <Tab
              eventKey="chat"
              title={<div className="tab-title">{Translate('Chat')}</div>}
            />
            <Tab eventKey="group" title={Translate('Groups')} />
          </Tabs>
          <div className="p-2">
            <div className="">
              <Search
                className="w-100 me-3 rounded"
                onChange={e => handleFilter(e, activeTab)}
                placeholder={Translate('Search users')}
              />
            </div>
            {activeTab === 'chat' && (
              <ChatTab
                chatUsersData={chatUsersData}
                loading={loading}
                hideOffCanvas={hideOffCanvas}
                readUnreadMessage={readUnreadMessage}
              />
            )}
            {activeTab === 'group' && (
              <GroupTab
                groupData={groupData}
                setgroupData={setGroupData}
                setGroupLinks={setGroupLinks}
                groupLinks={groupLinks}
                loading={loading}
                setShowGroupForm={setShowGroupForm}
                fetchGroups={fetchGroups}
                hideOffCanvas={hideOffCanvas}
                readUnreadMessage={readUnreadMessage}
              />
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <GroupFormModal
        showGroupForm={showGroupForm}
        setShowGroupForm={setShowGroupForm}
        fetchGroups={fetchGroups}
      />
    </>
  );
};

ChatWindowOffCanvas.propTypes = {
  show: PropTypes.bool,
  setShowChatWindow: PropTypes.func
};

export default ChatWindowOffCanvas;
