import { React } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
const SelectVoucherTransType = ({
  options,
  name,
  value,
  onChange,
  style,
  placeholder,
  isInvalid
}) => {
  const Translate = useAxisproTranslate();
  let thisOptions = [];

  if (typeof options === 'string') {
    thisOptions = options.split(',').reduce((result, item) => {
      result.push({ label: Translate(item), value: item });
      return result;
    }, []);
  }

  return (
    <Select
      isClearable
      value={value}
      placeholder={placeholder ?? ''}
      name={name ? name : 'owner'}
      className={`app-react-select${
        isInvalid ? ' form-control ps-0 py-0 is-invalid' : ''
      }`}
      classNamePrefix="app-react-select"
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({
          ...provided,
          ...style
        })
      }}
      onChange={onChange}
      options={thisOptions}
    />
  );
};
SelectVoucherTransType.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  style: PropTypes.any,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  options: PropTypes.string
};
export default SelectVoucherTransType;
