import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaEdit } from 'react-icons/fa';
import { DeleteContext } from 'context/Context';
import { showWarningToast } from 'module/Common/Toast/showWarningToast';
import useAxisproPermission from 'hooks/useAxisproPermission';

export default function WorkOrderLineItemTaskItemDescriptionArea({
  task,
  isDropDownPreview,
  itemTargetId,
  setWorkOrderVoucherData,
  setShowLoading,
  isCompleted
}) {
  const { dispatch: confirmDispatch } = useContext(DeleteContext);
  const [showEditDescription, setShowEditDescription] = useState(false);
  const axisProPermission = useAxisproPermission();
  const [formData, setFormData] = useState({
    title: ''
  });

  const permissions = {
    updateWorkOrder: axisProPermission('update-wo')
  };

  const Translate = useAxisproTranslate();

  const handleShowEditDescription = event => {
    event.preventDefault();
    setFormData({
      title: task?.title ?? ''
    });
    setShowEditDescription(!showEditDescription);
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    if (formData?.title === task?.title) {
      showWarningToast('No changes were found to continue !', {
        position: 'top-center'
      });
      return;
    }
    const config = {
      title: Translate('Update Task Description'),
      message: Translate(
        'Are you sure you want to update this task discription?'
      ),
      buttonText: 'Update',
      submitButtonVariant: 'success',
      cancelButtonVariant: 'danger',
      reuestMethod: 'put',
      url:
        '/production/work-order-task-update?task_id=' +
        task?.id +
        '&' +
        'title=' +
        formData.title,
      onDelete: () => setShowLoading(true),
      onSuccess: taskData => {
        if (taskData?.id && itemTargetId) {
          setShowEditDescription(false);
          setWorkOrderVoucherData(prev => ({
            ...prev,
            details: prev?.details?.map(item =>
              item?.id === itemTargetId ? { ...item, task: taskData } : item
            )
          }));

          setShowLoading(false);
        }
      },
      onError: setShowLoading(false)
    };

    confirmDispatch({
      type: 'CONFIG',
      payload: config
    });
  };

  return (
    <>
      <div className="d-flex flex-row gap-1 align-items-center">
        <p className="fw-bold text-dark mb-0">
          {Translate('Task Description')}
        </p>
        {permissions?.updateWorkOrder &&
        !isDropDownPreview &&
        !showEditDescription ? (
          <Button
            variant="transparent"
            className="p-0 m-0 text-warning outline-none shadow-none"
            type="button"
            onClick={handleShowEditDescription}
            title={Translate('Edit task description')}
            disabled={task?.running_status || isCompleted}
          >
            <FaEdit size={10} />
          </Button>
        ) : null}
      </div>

      {!showEditDescription ? (
        <p
          className="text-muted"
          style={{
            fontSize: '.75rem'
          }}
        >
          {task?.title}
        </p>
      ) : (
        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mt-2 mb-3">
            <Form.Control
              as="textarea"
              rows={5}
              value={formData.title}
              onChange={event =>
                setFormData({
                  title: event?.target?.value
                })
              }
            />
          </Form.Group>
          <Form.Group className="d-flex gap-1 justify-content-end">
            <Button
              variant="success"
              size="sm"
              className="rounded d-flex flex-row align-items-center gap-1"
              type="submit"
            >
              <small>{Translate('Update')}</small>
            </Button>
            <Button
              variant="danger"
              size="sm"
              className="rounded"
              type="button"
              onClick={handleShowEditDescription}
            >
              <small>{Translate('Cancel')}</small>
            </Button>
          </Form.Group>
        </Form>
      )}
    </>
  );
}

WorkOrderLineItemTaskItemDescriptionArea.propTypes = {
  task: PropTypes.object,
  isDropDownPreview: PropTypes.bool,
  itemTargetId: PropTypes.string,
  setWorkOrderVoucherData: PropTypes.func,
  setShowLoading: PropTypes.func,
  isCompleted: PropTypes.bool
};
