import { React, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import PurchaseRequestTabView from 'module/Common/ViewPages/PurchaseRequest/PurchaseRequestTabView';
import { useLocation } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

function PurchaseRequestInfo({
  purchaseRequestId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [issuedItem, setIssuedItem] = useState(false);
  const location = useLocation();
  const Translate = useAxisproTranslate();
  const apiCallDelayTime = location?.state?.apiCallDelay ?? 0;

  const fetchData = async () => {
    let url = voidTransactionReport
      ? 'settings/void-transaction-details'
      : `purchase/purchase-request/${purchaseRequestId}`;
    await axios
      .get(url, {
        params: {
          trans_type: voidTransactionReport ? 'PR' : '',
          trans_no: voidTransactionReport ? purchaseRequestId : ''
        }
      })
      .then(res => {
        if (res.data.success) {
          setData(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
    setIssuedItem(false);
  };

  useEffect(() => {
    setLoading(true);
    const apiCallTime = setTimeout(() => {
      fetchData();
    }, apiCallDelayTime);
    return () => clearTimeout(apiCallTime);
  }, [purchaseRequestId, apiCallDelayTime]);

  useEffect(() => {
    if (issuedItem) {
      fetchData();
    }
  }, [issuedItem]);
  return (
    <>
      {loading ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : data?.id ? (
        <PurchaseRequestTabView
          purchaseRequestData={data}
          loading={loading}
          fetchData={fetchData}
          getData={getData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          setIssuedItem={setIssuedItem}
        />
      ) : (
        <Card className="flex-fill view-page-content-wrapper d-flex align-items-center justify-content-center h-100 ">
          <div>{Translate('No Records')}</div>
        </Card>
      )}
    </>
  );
}

PurchaseRequestInfo.propTypes = {
  purchaseRequestId: PropTypes.string,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func
};

export default PurchaseRequestInfo;
