import { React } from 'react';
import { Tab, Tabs, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

const ItemDetailsTab = ({ data }) => {
  const Translate = useAxisproTranslate();

  return (
    <Tabs
      defaultActiveKey={1}
      className="border-top mt-3"
      style={{ margin: '0 -1rem' }}
    >
      <Tab eventKey={1} title={Translate('OTHER DETAILS')}>
        <Row className="mt-3">
          <Col>
            <h5>{Translate('MANUFACTURER')}</h5>
          </Col>
          <Col>{data.manufacturer_name}</Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <h5>{Translate('SUPPLIER')}</h5>
          </Col>
          <Col>{data.supplier_name}</Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <h5>{Translate('STOCK ON HAND')}</h5>
          </Col>
          <Col>{data.qoh}</Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <h5>{Translate('REORDER LEVEL')}</h5>
          </Col>
          <Col>{data.reorder_level}</Col>
        </Row>
      </Tab>
      <Tab eventKey={2} title={Translate('DIMENSIONAL DETAILS')}>
        <Row className="mt-3">
          <Col>
            <h5>{Translate('WEIGHT')}</h5>
          </Col>
          <Col>
            {data.weight}
            {data.weight_unit ? (data.weight_unit == 'g' ? 'g' : 'kg') : ''}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <h5>{Translate('HEIGHT')}</h5>
          </Col>
          <Col>{data.dim_height}</Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <h5>{Translate('LENGTH')}</h5>
          </Col>
          <Col>{data.dim_length}</Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <h5>{Translate('WIDTH')}</h5>
          </Col>
          <Col>{data.dim_width}</Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <h5>{Translate('DIMENSION UNIT')}</h5>
          </Col>
          <Col>
            {data.dimension_unit
              ? data.dimension_unit == 'in'
                ? 'inch'
                : 'cm'
              : ''}
          </Col>
        </Row>
      </Tab>
    </Tabs>
  );
};

ItemDetailsTab.propTypes = {
  data: PropTypes.any
};

export default ItemDetailsTab;
