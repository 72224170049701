import axios from 'axios';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import PopupFormButton from 'components/popup-form-button/PopupFormButton';
import { batchNumberFormKeys } from 'helpers/formKeys';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { MdBatchPrediction } from 'react-icons/md';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import { HiOutlineRefresh } from 'react-icons/hi';

const BatchNumberPopupForm = ({
  callBack,
  setData,
  labelName,
  keyName,
  name,
  showAddButton,
  onHide,
  show
}) => {
  const Translate = useAxisproTranslate();
  const [showWindow, setShowWindow] = useState(show ?? false);
  const [batchNumber, setBatchNumber] = useState(undefined);
  const [isGeneratingBatchNumber, setIsGeneratingBatchNumber] = useState(false);
  const [formData, setFormData] = useState(batchNumberFormKeys);
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const handleShow = () => {
    setShowWindow(!showWindow);
    if (show) {
      setFormData(batchNumberFormKeys);
      setFormError({});
      setIsSaving(false);
      if (onHide && typeof onHide === 'function') onHide();
    }
  };
  const handleFieldChange = (e, action) => {
    if (e?.target?.name === 'exp_date' && e.target?.value) {
      if (!formData.mfg_date) {
        return showToast(
          Translate('Date of manufacture is blank, please enter a valid date'),
          'error'
        );
      } else if (formData.mfg_date > e.target.value) {
        return showToast(
          Translate(
            'Invalid data, The expiration date should not be less than the date of manufacture.'
          ),
          'error'
        );
      }
    }
    setFormData(generateFormDataHelper(e, action, formData));
  };
  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setIsSaving(true);

    axios({
      method: 'post',
      url: 'inventory/batch',
      data: { ...formData, batch_number: batchNumber }
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          if (callBack) {
            callBack(response.data.data);
          }

          if (response.data.data && setData) {
            setData(
              {
                label: response.data.data[labelName ?? 'batch_number'],
                value: response.data.data[keyName ?? 'id']
              },
              {
                action: 'select-option',
                option: undefined,
                name: name ?? 'batch_id'
              }
            );
          }

          handleShow();
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setIsSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setIsSaving(false);
      });
  };

  const getNewBatchNumber = () => {
    setIsGeneratingBatchNumber(true);
    axios
      .get('inventory/generate-batch-number')
      .then(response => {
        setIsGeneratingBatchNumber(false);
        if (response.status === 200 && response?.data?.data?.batch_number) {
          return setBatchNumber(response.data.data.batch_number);
        }
        showToast(
          Translate(
            'Something went wrong, please refresh the page and try again.'
          ),
          'error'
        );
      })
      .catch(error => {
        setIsGeneratingBatchNumber(false);
        showToast(
          Translate(
            error?.response?.data?.message ??
              'Something went wrong, please refresh the page and try again.'
          ),
          'error'
        );
      });
  };

  useEffect(() => {
    setShowWindow(show ?? false);
    if (show) {
      getNewBatchNumber();
    }
    return () => {
      setFormData(batchNumberFormKeys);
      setFormError({});
      setShowWindow(false);
      setIsSaving(false);
      setBatchNumber(undefined);
    };
  }, [show]);

  return (
    <>
      {showAddButton ? (
        <PopupFormButton onClick={handleShow}>
          {Translate('Add Batch Number')}
        </PopupFormButton>
      ) : null}

      {showWindow ? (
        <Modal
          show={showWindow}
          onHide={handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="d-flex flex-row align-items-center">
                <MdBatchPrediction size={20} className="text-success" />
                <span className="ms-1">{Translate('ADD BATCH NUMBER')}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(formError).length > 0 && (
                <ErrorAlert formError={formError} setFormError={setFormError} />
              )}
              <Form.Group className="mb-3" controlId="form.batch_number">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Batch Number')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Control readOnly value={batchNumber ?? ''} />
                    <Button
                      variant="secondary"
                      size="sm"
                      className="px-2 py-0 align-self-stretch"
                      title="Regenerate batch number"
                      type="button"
                      onClick={getNewBatchNumber}
                    >
                      {isGeneratingBatchNumber ? (
                        <Spinner
                          animation="border"
                          style={{ width: '10px', height: '10px' }}
                        />
                      ) : (
                        <HiOutlineRefresh size={10} />
                      )}
                    </Button>
                    <FormErrorPopover
                      id="batch_number"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.batch_number}
                    />
                  </Col>
                </Row>
              </Form.Group>

              {!batchNumber ? (
                isGeneratingBatchNumber ? (
                  <Alert variant="warning">
                    {Translate('Generating batch number, please wait!')}
                  </Alert>
                ) : null
              ) : (
                <>
                  <Form.Group className="mb-3" controlId="costCenterName">
                    <Row>
                      <Col md={5} sm={12}>
                        <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                          {Translate('Manufacturing Date')}
                        </Form.Label>
                      </Col>
                      <Col
                        md={7}
                        sm={12}
                        className="d-flex flex-row align-items-center"
                      >
                        <AppDatePicker
                          name="mfg_date"
                          value={formData.mfg_date}
                          yearPlaceholder="yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          onChange={handleFieldChange}
                        />
                        <FormErrorPopover
                          id="batch_number"
                          totalErrorCount={Object.keys(formError).length}
                          errorMessage={formError.mfg_date}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="costCenterName">
                    <Row>
                      <Col md={5} sm={12}>
                        <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                          {Translate('Expiry Date')}
                        </Form.Label>
                      </Col>
                      <Col
                        md={7}
                        sm={12}
                        className="d-flex flex-row align-items-center"
                      >
                        <AppDatePicker
                          name="exp_date"
                          value={formData.exp_date}
                          yearPlaceholder="yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          onChange={handleFieldChange}
                        />
                        <FormErrorPopover
                          id="batch_number"
                          totalErrorCount={Object.keys(formError).length}
                          errorMessage={formError.exp_date}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="form.description">
                    <Row>
                      <Col md={5} sm={12}>
                        <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                          {Translate('Description')}
                        </Form.Label>
                      </Col>
                      <Col
                        md={7}
                        sm={12}
                        className="d-flex flex-row align-items-center"
                      >
                        <Form.Control
                          as="textarea"
                          rows={5}
                          name="description"
                          onChange={handleFieldChange}
                          value={formData.description}
                        />
                        <FormErrorPopover
                          id="description"
                          totalErrorCount={Object.keys(formError).length}
                          errorMessage={formError.description}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className="d-flex flex-row justify-content-start">
              <Button
                variant="outline-success"
                size="sm"
                className="d-flex align-items-center justify-content-center"
                onClick={handleSubmit}
                disabled={!batchNumber || isSaving}
              >
                {isSaving ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="success"
                      className="me-1"
                    />
                    <span className="fs--1">{Translate('Saving...')}</span>
                  </>
                ) : (
                  <span>{Translate('Add')}</span>
                )}
              </Button>
              <Button
                variant="outline-danger"
                onClick={handleShow}
                size="sm"
                disabled={isSaving}
              >
                {Translate('Cancel')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

BatchNumberPopupForm.propTypes = {
  callBack: PropTypes.func,
  setData: PropTypes.func,
  name: PropTypes.string,
  labelName: PropTypes.string,
  keyName: PropTypes.string,
  show: PropTypes.bool,
  showAddButton: PropTypes.bool,
  onHide: PropTypes.func
};

export default BatchNumberPopupForm;
