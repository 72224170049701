import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BiDotsVertical } from 'react-icons/bi';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ConfirmWarning from 'module/Common/Warnings/ConfirmWarning';

const GropActionDropDown = ({
  item,
  loggedGroupUser,
  groupDetail,
  setNewMember,
  setShowGroupDetails,
  user
}) => {
  const adminAction = 'AdminAction';
  const removeAction = 'RemoveAction';
  const exitAction = 'ExitAction';
  const Translate = useAxisproTranslate();
  let navigate = useNavigate();
  const [actionType, setActionType] = useState('');
  const [userId, setUserId] = useState('');
  const [confirmShow, setConfirmShow] = useState(false);

  const handleShow = () => setConfirmShow(!confirmShow);

  const markAsAdmin = () => {
    axios
      .post('user/chat-groups/add-admin', {
        group_id: groupDetail.id,
        user_id: userId
      })
      .then(() => {
        setNewMember(true);
        setConfirmShow(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const privateChatAction = item => {
    setShowGroupDetails(false);
    navigate('/chat', {
      state: { item: item, type: 'INDIVIDUAL' }
    });
  };

  const removeFromGroup = () => {
    axios
      .post('user/chat-groups/remove-user', {
        group_id: groupDetail.id,
        user_id: userId
      })
      .then(() => {
        setNewMember(true);
        setConfirmShow(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const exitGroup = () => {
    axios
      .post('user/chat-groups/exit-group', {
        group_id: groupDetail.id,
        user_id: user.id
      })
      .then(() => {
        setNewMember(true);
        setConfirmShow(false);
        navigate('/dashboard');
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const ConfirmAction = (user, adminAction) => {
    setActionType(adminAction);
    setUserId(user.id);
    setConfirmShow(true);
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          bsPrefix="toggle"
          variant="transparent"
          style={{ boxShadow: 'none', border: 'none' }}
          className="px-0 d-flex align-items-center justify-content-center"
        >
          <BiDotsVertical size={20} className="me-1" title="Actions" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 p-0">
            {item.is_admin == 0 &&
            loggedGroupUser &&
            loggedGroupUser.is_admin ? (
              <>
                <Dropdown.Item
                  className="d-flex gap-1 align-items-center p-0 ps-1"
                  onClick={() => ConfirmAction(item, adminAction)}
                >
                  {Translate('Make as admin')}
                </Dropdown.Item>
              </>
            ) : (
              ''
            )}
            {item.id == user.id ? (
              <>
                <Dropdown.Item
                  className="d-flex gap-1 align-items-center p-0 ps-1"
                  onClick={() => ConfirmAction(item, exitAction)}
                >
                  {Translate('Exit from group')}
                </Dropdown.Item>
              </>
            ) : loggedGroupUser && loggedGroupUser.is_admin ? (
              <>
                <Dropdown.Item
                  className="d-flex gap-1 align-items-center p-0 ps-1"
                  onClick={() => ConfirmAction(item, removeAction)}
                >
                  {Translate('Remove from group')}
                </Dropdown.Item>
              </>
            ) : (
              ''
            )}
            {item.id != user.id ? (
              <>
                <Dropdown.Item
                  className="d-flex gap-1 align-items-center p-0 ps-1"
                  onClick={() => privateChatAction(item)}
                >
                  {Translate('Private chat')}
                </Dropdown.Item>
              </>
            ) : (
              ''
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <ConfirmWarning
        show={confirmShow}
        onHide={handleShow}
        handleSubmit={
          actionType == adminAction
            ? markAsAdmin
            : actionType == removeAction
            ? removeFromGroup
            : actionType == exitAction
            ? exitGroup
            : ''
        }
        content={
          actionType == adminAction
            ? 'Are you sure you want to make this person as admin?'
            : actionType == removeAction
            ? 'Are you sure you want to remove this person?'
            : actionType == exitAction
            ? 'Are you sure you want exit from this group?'
            : ''
        }
      />
    </>
  );
};

GropActionDropDown.propTypes = {
  item: PropTypes.any,
  loggedGroupUser: PropTypes.any,
  groupDetail: PropTypes.any,
  setNewMember: PropTypes.func,
  setShowGroupDetails: PropTypes.func,
  user: PropTypes.any
};

export default GropActionDropDown;
