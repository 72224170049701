import { React, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import axios from 'axios';
import BatchTable from '../Common/Tables/BatchTable';
import { showToast } from 'module/Common/Toast/toast';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';

function BatchView({ batchData }) {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`inventory/get-batch-transactions/${batchData.batch_number}`)
      .then(res => {
        if (res.data.success) {
          setData(res.data.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        if (error) {
          showToast('Something went wrong! Please contact admin', 'error');
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (batchData?.batch_number) {
      fetchData();
    }
  }, [batchData.batch_number]);

  return (
    <Container className="mt-3 pe-3 ps-3 voucher-inquiry-view-wrapper">
      {!loading ? (
        <>
          {data && data.length > 0 ? (
            <>
              <BatchTable data={data} />
              <Row className="pt-2 pb-2 pe-1 ps-1">
                <Col lg={2} md={3} className="p-0">
                  <h6 className="mb-2 fw-bold">
                    {Translate('Total Quantity In Batch')} :
                  </h6>
                </Col>
                <Col xs={6} className="">
                  <h6 className="mb-2">{batchData.quantity_in_batch}</h6>
                </Col>
              </Row>
            </>
          ) : (
            <div
              style={{ height: '20vh' }}
              className="d-flex align-items-center justify-content-center"
            >
              <h5 className="m-0 text-muted fw-bold">
                {Translate('Nothing to Display')}!
              </h5>
            </div>
          )}
          {batchData && batchData.description ? (
            <Row
              style={{ border: '1px dashed grey' }}
              className="pt-2 pb-2 pe-1 ps-1"
            >
              <Col xs={12} className="p-0">
                <h6 className="mb-2 fw-bold">{Translate('Description')} :</h6>
              </Col>
              <Col xs={12} className="ms-3">
                <h6 className="mb-2">{batchData.description}</h6>
              </Col>
            </Row>
          ) : (
            ''
          )}
        </>
      ) : (
        <div
          style={{ height: '70vh' }}
          className="d-flex align-items-center justify-content-center"
        >
          <LoadingScreen />
        </div>
      )}
    </Container>
  );
}

BatchView.propTypes = {
  batchData: PropTypes.any
};

export default BatchView;
