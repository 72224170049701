import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PhoneNumberInput from 'components/form/PhoneNumberInput';

const UserSection = ({ formData, errors, onChange }) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="firstName">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          placeholder="Enter first name"
          name="first_name"
          onChange={onChange}
          value={formData.first_name}
          isValid={formData.first_name.length > 0 && !errors.first_name}
          isInvalid={!!errors.first_name}
        />
        {errors.first_name ? (
          <Form.Control.Feedback type="invalid">
            {errors.first_name}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3" controlId="lastName">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          placeholder="Enter last name"
          name="last_name"
          onChange={onChange}
          value={formData.last_name}
          isValid={formData.last_name.length > 0 && !errors.last_name}
          isInvalid={!!errors.last_name}
        />
        {errors.last_name ? (
          <Form.Control.Feedback type="invalid">
            {errors.last_name}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3" controlId="userEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email address"
          name="user_email"
          onChange={onChange}
          value={formData.user_email}
          isValid={formData.user_email.length > 0 && !errors.user_email}
          isInvalid={!!errors.user_email}
        />
        {errors.user_email ? (
          <Form.Control.Feedback type="invalid">
            {errors.user_email}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3" controlId="userMobile">
        <Form.Label>Mobile</Form.Label>
        <PhoneNumberInput
          placeholder="Enter contact number"
          name="user_phone"
          onChange={onChange}
          value={formData.user_phone}
          isValid={formData.user_phone.length > 0 && !errors.user_phone}
          isInvalid={!!errors.user_phone}
        />
        {errors.user_phone ? (
          <Form.Control.Feedback type="invalid">
            {errors.user_phone}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
    </>
  );
};

UserSection.propTypes = {
  formData: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func
};

export default UserSection;
