const generateFormDataHelper = (e, action, oldData, isMultiKeys) => {
  let newOldData;
  if (action) {
    if (action?.action === 'text-editor') {
      e = action;
    }
    newOldData = {
      ...oldData,
      [action.name]: e
        ? Array.isArray(isMultiKeys) &&
          isMultiKeys.includes(action?.name) &&
          Array.isArray(e)
          ? e.reduce((newResult, item) => {
              if (item?.value) {
                newResult.push(item.value);
              }

              return newResult;
            }, [])
          : e.value
        : null,
      [action.name + '_ref']: e
    };
  } else {
    newOldData = {
      ...oldData,
      [e.target.name]:
        e.target.type === 'checkbox'
          ? !e.target.checked
            ? 0
            : 1
          : e.target.value
    };
  }
  return newOldData;
};

export default generateFormDataHelper;
