export default function setItemPopupFormCategoryRelatedDetails(
  formData,
  categoryDetails
) {
  formData.unit_id = categoryDetails?.unit?.id ?? undefined;
  formData.unit_id_ref = categoryDetails?.unit?.id
    ? {
        label: categoryDetails?.unit?.name,
        value: categoryDetails.unit.id
      }
    : null;
  formData.tax_group_id = categoryDetails?.tax_group_id ?? undefined;
  formData.tax_group_id_ref = categoryDetails?.tax_group_id
    ? {
        label: categoryDetails?.tax_group_name,
        value: categoryDetails.tax_group_id
      }
    : null;
  formData.sales_account_code =
    categoryDetails?.sales_account_code ?? undefined;
  formData.sales_account_code_ref = categoryDetails?.sales_account_code
    ? {
        code: categoryDetails.sales_account_code,
        label:
          categoryDetails.sales_account_code +
          ' - ' +
          categoryDetails?.sales_account_name,

        value: categoryDetails?.sales_account_id
      }
    : null;
  formData.purchase_account_code =
    categoryDetails?.purchase_account_code ?? undefined;
  formData.purchase_account_code_ref = categoryDetails?.purchase_account_code
    ? {
        code: categoryDetails.purchase_account_code,
        label:
          categoryDetails.purchase_account_code +
          ' - ' +
          categoryDetails?.purchase_account_name,

        value: categoryDetails?.purchase_account_id
      }
    : null;
  formData.inventory_account_code =
    categoryDetails?.inventory_account_code ?? undefined;
  formData.inventory_account_code_ref = categoryDetails?.inventory_account_code
    ? {
        code: categoryDetails.inventory_account_code,
        label:
          categoryDetails.inventory_account_code +
          ' - ' +
          categoryDetails?.inventory_account_name,

        value: categoryDetails?.inventory_account_id
      }
    : null;

  formData.wip_crediting_account_code =
    categoryDetails?.wip_crediting_account_code ?? undefined;
  formData.wip_crediting_account_code_ref =
    categoryDetails?.wip_crediting_account_code
      ? {
          code: categoryDetails.wip_crediting_account_code,
          label:
            categoryDetails.wip_crediting_account_code +
            ' - ' +
            categoryDetails?.wip_crediting_account_code_name,

          value: categoryDetails?.wip_crediting_account_code_id
        }
      : null;

  formData.wip_account_code = categoryDetails?.wip_account_code ?? undefined;
  formData.wip_account_code_ref = categoryDetails?.wip_account_code
    ? {
        code: categoryDetails.wip_account_code,
        label:
          categoryDetails.wip_account_code +
          ' - ' +
          categoryDetails?.wip_account_name,

        value: categoryDetails?.wip_account_code_id
      }
    : null;

  return formData;
}
