import { React, useContext } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AuthWizardContext } from 'context/Context';

const RequireGuest = ({ children }) => {
  let { user } = useContext(AuthWizardContext);
  let logoutType = localStorage.getItem('ACODAX_MANUEL_LOGOUT');
  const [searchParams] = useSearchParams();
  const lastVisitedPagePath = searchParams.get('redirectTo');
  return user ? (
    <Navigate
      to={logoutType ? lastVisitedPagePath ?? '/dashboard' : '/dashboard'}
    />
  ) : (
    children
  );
};

RequireGuest.propTypes = {
  children: PropTypes.element.isRequired
};

export default RequireGuest;
