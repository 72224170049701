import React from 'react';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AiOutlinePercentage } from 'react-icons/ai';
import { BsCashCoin } from 'react-icons/bs';

function TypeSwitchingInputField({
  name,
  onChange,
  value,
  onKeyDown,
  type,
  changeToAmount,
  changeToPercentage
}) {
  return (
    <InputGroup>
      <Form.Control
        type="number"
        name={name}
        className="form-tab-switch-control"
        onChange={onChange}
        value={value || ''}
        onKeyDown={onKeyDown}
      />
      <Dropdown size="sm">
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-basic"
          className="discount-conversion-button"
        >
          {type === 'AMOUNT' ? (
            <BsCashCoin color="black" />
          ) : (
            <AiOutlinePercentage color="black" />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ minWidth: '10px', padding: '5px' }}>
          <Dropdown.Item onClick={changeToAmount}>
            <BsCashCoin color="black" />
          </Dropdown.Item>
          <Dropdown.Item onClick={changeToPercentage}>
            <AiOutlinePercentage color="black" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </InputGroup>
  );
}

TypeSwitchingInputField.propTypes = {
  name: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any,
  onKeyDown: PropTypes.func,
  type: PropTypes.any,
  changeToAmount: PropTypes.func,
  changeToPercentage: PropTypes.func
};

export default TypeSwitchingInputField;
