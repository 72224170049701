import { React, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { apiCall } from 'helpers/apiCalls';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { FaUserGroup } from 'react-icons/fa6';

const CustomerGroupView = () => {
  const Translate = useAxisproTranslate();
  let navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function initFunc() {
      if (id) {
        setLoading(true);
        let details = await apiCall({
          url: 'sales/customer-groups/' + id
        });
        setData(details.data);
        console.log(data);
        setLoading(false);
      }
    }
    initFunc();
  }, [id]);

  return (
    <Offcanvas show onHide={() => navigate(-1)} placement="end">
      <Offcanvas.Header
        closeButton
        className="border-bottom offcanvas-heading-style"
      >
        <div>
          <FaUserGroup size={20} className="me-1 text-dark" />
          {Translate('Customer Group View')}
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>{!loading ? '' : <LoadingScreen />}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default CustomerGroupView;
