import { useContext, useEffect } from 'react';
import { TabViewContentReloadHandlerContext } from 'context/Context';

const useTabViewContentReloadHandler = configs => {
  const {
    TabViewContentReloadHandlerContextData,
    tabViewContentReloadHandlerContextDispatch
  } = useContext(TabViewContentReloadHandlerContext);

  const addTab = tabNameArray =>
    tabViewContentReloadHandlerContextDispatch({
      type: 'ADD-TAB-TO-RELOAD-LIST',
      payload: tabNameArray
    });

  const removeTab = tabNameArray =>
    tabViewContentReloadHandlerContextDispatch({
      type: 'REMOVE-TAB-FROM-RELOAD-LIST',
      payload: tabNameArray
    });

  const isTabIncludes = tabName =>
    TabViewContentReloadHandlerContextData.reloadPendingTabList?.includes(
      tabName
    );

  useEffect(() => {
    if (Array.isArray(configs?.activeTabList)) {
      addTab(configs?.activeTabList);
    }

    return () => {
      if (Array.isArray(configs?.activeTabList)) {
        removeTab(configs?.activeTabList);
      }
    };
  }, []);

  return {
    reloadPendingTabList:
      TabViewContentReloadHandlerContextData?.reloadPendingTabList,
    addTab,
    removeTab,
    isTabIncludes
  };
};

export default useTabViewContentReloadHandler;
