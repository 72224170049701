import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FilterModal from '../../../../Common/TableFilter/FilterModal';
import React, { useState } from 'react';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaFilter, FaTimes } from 'react-icons/fa';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';

export default function ClassicThreeColumnLayoutFilter({
  moduleName,
  enableDropDown,
  dropDownData
}) {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const Translate = useAxisproTranslate();
  const filterItems = queryParams.get('filters');
  const allQueryParams = Object.fromEntries([...queryParams]);

  const clearFilter = () => {
    if (allQueryParams?.filters) {
      delete allQueryParams.filters;
    }

    if (allQueryParams?.page > 0) {
      allQueryParams.page = 1;
    }

    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(allQueryParams)}`
    });
  };

  const [show, setShow] = useState(false);

  return (
    <>
      <ButtonGroup
        aria-label="filter-button-group"
        className="three-column-layout-list-button"
      >
        <Button
          size="sm"
          variant="transparent"
          className={`d-flex flex-row align-items-center px-2`}
          onClick={() => setShow(!show)}
        >
          <>
            <FaFilter size={10} />
          </>
        </Button>
        {filterItems && (
          <OverlayTrigger
            overlay={
              <Tooltip id="clear-filter-toolptip" className="small">
                {Translate('Clear all filters')}
              </Tooltip>
            }
          >
            <Button
              size="sm"
              variant={'danger'}
              className={`d-flex flex-row align-items-center px-1 position-absolute rounded-circle`}
              style={{
                top: '-10px',
                right: '-5px',
                width: '17px',
                height: '17px',
                zIndex: '1'
              }}
              onClick={clearFilter}
            >
              <>
                <FaTimes size={10} />
              </>
            </Button>
          </OverlayTrigger>
        )}
      </ButtonGroup>

      <FilterModal
        show={show}
        handleModal={() => setShow(!show)}
        setShow={setShow}
        enableDropDown={enableDropDown}
        dropDownData={dropDownData}
        moduleName={moduleName}
      />
    </>
  );
}

ClassicThreeColumnLayoutFilter.propTypes = {
  moduleName: PropTypes.string,
  enableDropDown: PropTypes.any,
  dropDownData: PropTypes.array
};
