import React from 'react';
import { Button } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import PropTypes from 'prop-types';

const DetailsSectionQuickEditButton = ({ variant, size, ...rest }) => {
  return (
    <Button variant={variant ?? 'trabsparent'} size={size ?? 'sm'} {...rest}>
      <FaEdit className="text-info" />
    </Button>
  );
};

DetailsSectionQuickEditButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string
};

export default DetailsSectionQuickEditButton;
