import { React } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
const SelectProductType = ({
  name,
  value,
  onChange,
  style,
  placeholder,
  error
}) => {
  const Translate = useAxisproTranslate();
  const thisOptions = [
    {
      label: Translate('Goods'),
      value: 'goods'
    },
    {
      label: Translate('Service'),
      value: 'service'
    },
    {
      label: Translate('Manufactured'),
      value: 'manufactured'
    }
  ];
  return (
    <Select
      isClearable
      value={value}
      placeholder={placeholder ?? ''}
      name={name ? name : 'product_type'}
      classNamePrefix="app-react-select"
      className={`w-100  ${error && 'is-invalid'}`}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({
          ...provided,
          ...style
        })
      }}
      onChange={onChange}
      options={thisOptions}
    />
  );
};
SelectProductType.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  style: PropTypes.any,
  placeholder: PropTypes.string,
  error: PropTypes.any
};
export default SelectProductType;
