import React from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { FaPaperPlane } from 'react-icons/fa';
import TextEditor from 'module/Common/InputFields/TextEditor/TextEditor';
import PropTypes from 'prop-types';
const CommentInputFeild = ({
  handleFieldChange,
  formData,
  handleSubmit,
  onComment,
  formError,
  onEdit,
  handleCommentDistroy
}) => {
  return (
    <Card as={Form} className="shadow-none mb-3">
      <Card.Body className="p-0">
        <div className="border border-0 border-200">
          {Object.keys(formError).length > 0 && (
            <Alert variant="danger" show={formError.comment ? true : false}>
              <Alert.Heading> {formError.comment}</Alert.Heading>
            </Alert>
          )}

          <Form.Group>
            <TextEditor
              HideMedia={true}
              name="comment"
              placeholder="Leave a comment..."
              handleFieldChange={handleFieldChange}
              value={formData?.comment}
            />
          </Form.Group>
          <div className="d-flex align-items-center justify-content-between w-100">
            <div></div>
            <div className="d-flex">
              {onEdit && (
                <IconButton
                  className="me-2  mb-0 rounded-3"
                  variant="light"
                  size="sm"
                  icon="xmark"
                  onClick={handleCommentDistroy}
                >
                  {' '}
                  <span className="d-none d-md-inline-block ms-1">Clear</span>
                </IconButton>
              )}
              <Button
                size="sm"
                variant="dark"
                className="p-0 custom-transperent-common-button border-0 d-flex align-items-center justify-content-center p-1 rounded-1 mt-1 pe-1 cursor-pointer"
                onClick={handleSubmit}
                disabled={formData && formData.comment ? false : true}
              >
                {onComment ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  <>
                    <FaPaperPlane size={16} className="text-light" />
                  </>
                )}
                <div className="text-muted small ps-1 text-uppercase">
                  Post Comment
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
CommentInputFeild.propTypes = {
  handleFieldChange: PropTypes.bool,
  formData: PropTypes.any,
  handleSubmit: PropTypes.func,
  onComment: PropTypes.any,
  formError: PropTypes.any,
  handleCommentDistroy: PropTypes.any,
  onEdit: PropTypes.any
};

export default CommentInputFeild;
