import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectUnit from 'components/form/SelectUnit';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function UnitInputField({
  formData,
  formError,
  handleFieldChange,
  required,
  labelClassname
}) {
  const Translate = useAxisproTranslate();

  return (
    <Row className="mb-3">
      <Col
        xl={4}
        md={4}
        sm={12}
        className={`d-flex align-items-center justify-content-start ${labelClassname}`}
      >
        <Form.Label className={required ? 'require-data m-0' : 'm-0'}>
          {Translate('Unit')}
        </Form.Label>
      </Col>
      <Col xl={8} md={8} sm={12}>
        <Row>
          <Col xl={11} md={11} sm={11}>
            <SelectUnit
              sm={1}
              name="unit_id"
              value={formData.unit_id_ref}
              handleFieldChange={handleFieldChange}
              error={formError?.unit_id}
            />
          </Col>
          <Col
            xl={1}
            md={1}
            sm={1}
            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
          >
            {formError.unit_id ? (
              <FormErrorPopover
                id="unit_id"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={formError.unit_id}
              />
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

UnitInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  labelClassname: PropTypes.any,
  handleFieldChange: PropTypes.func,
  required: PropTypes.bool
};

export default UnitInputField;
