import { React } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
const SelectPriority = ({
  name,
  value,
  onChange,
  style,
  placeholder,
  isInvalid,
  options
}) => {
  const thisOptions = [
    {
      label: 'High',
      value: 'high'
    },
    {
      label: 'Medium',
      value: 'medium'
    },
    {
      label: 'Low',
      value: 'low'
    }
  ];
  return (
    <Select
      isClearable
      value={value}
      placeholder={placeholder ?? ''}
      name={name ? name : 'priority'}
      className={`${isInvalid && 'is-invalid'}`}
      classNamePrefix="app-react-select"
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({
          ...provided,
          ...style
        })
      }}
      onChange={onChange}
      options={options ? options : thisOptions}
    />
  );
};
SelectPriority.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  style: PropTypes.any,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  options: PropTypes.array
};
export default SelectPriority;
