import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import React from 'react';
import { Outlet } from 'react-router-dom';

function FixedAssetPurchaseInvoiceOutlet() {
  const permissions = {
    sendMail: getAcodaxPermissionSlug('PI', 'send-mail'),
    print: getAcodaxPermissionSlug('PI', 'print'),
    create: getAcodaxPermissionSlug('PI', 'create'),
    edit: getAcodaxPermissionSlug('PI', 'update'),
    show_sp: getAcodaxPermissionSlug('supplier', 'show'),
    voidTransaction: getAcodaxPermissionSlug('VOID', 'TRANSACTION'),
    createDebitNote: getAcodaxPermissionSlug('DN', 'create'),
    additionalCosts: getAcodaxPermissionSlug('PI_ADDITIONAL_COST', 'save')
  };
  return <Outlet context={[permissions]} />;
}

export default FixedAssetPurchaseInvoiceOutlet;
