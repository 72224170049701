import React from 'react';
import { Card, Col, Placeholder, Row, Container } from 'react-bootstrap';

function ConvertingScreenInfoLoading() {
  return (
    <Card>
      <Card.Body>
        <Container
          style={{
            height: 'auto'
          }}
        >
          <Row>
            <Col xl={6} lg={6} md={6} sm={12}>
              <Row className="p-2 mb-2">
                <Col xl={10}>
                  <>
                    <Placeholder
                      as="p"
                      animation="glow"
                      className="m-0"
                      style={{ height: '20px' }}
                    >
                      <Placeholder
                        className="w-50"
                        size="sm"
                        bg="secondary"
                        style={{ opacity: '0.01', borderRadius: '10px' }}
                      />
                    </Placeholder>
                  </>
                </Col>
              </Row>
              <Row className="p-2">
                <Col xl={12}>
                  <>
                    <Placeholder
                      as="p"
                      animation="glow"
                      className="m-0"
                      style={{ height: '20px' }}
                    >
                      <Placeholder
                        className="w-50"
                        size="sm"
                        bg="secondary"
                        style={{ opacity: '0.01', borderRadius: '10px' }}
                      />
                    </Placeholder>
                  </>
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12}>
              <Row className="p-2 mb-2">
                <Col xl={3} lg={6} md={6} sm={12}></Col>
                <Col xl={9} lg={6} md={6} sm={12}>
                  <>
                    <Placeholder
                      as="p"
                      animation="glow"
                      className="m-0"
                      style={{ height: '20px', width: '200%' }}
                    >
                      <Placeholder
                        className="w-50"
                        size="sm"
                        bg="secondary"
                        style={{ opacity: '0.01', borderRadius: '10px' }}
                      />
                    </Placeholder>
                  </>
                </Col>
              </Row>
              <Row className="p-2 mb-2">
                <Col xl={7} lg={6} md={6} sm={12}></Col>
                <Col xl={5} lg={6} md={6} sm={12}>
                  <>
                    <Placeholder
                      as="p"
                      animation="glow"
                      className="m-0"
                      style={{ height: '20px', width: '200%' }}
                    >
                      <Placeholder
                        className="w-50"
                        size="sm"
                        bg="secondary"
                        style={{ opacity: '0.01', borderRadius: '10px' }}
                      />
                    </Placeholder>
                  </>
                </Col>
              </Row>
              <Row className="p-2 mb-2">
                <Col xl={6} lg={6} md={6} sm={12}>
                  <>
                    <Placeholder
                      as="p"
                      animation="glow"
                      className="m-0"
                      style={{ height: '20px', width: '200%' }}
                    >
                      <Placeholder
                        className="w-50"
                        size="sm"
                        bg="secondary"
                        style={{ opacity: '0.01', borderRadius: '10px' }}
                      />
                    </Placeholder>
                  </>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <>
                    <Placeholder
                      as="p"
                      animation="glow"
                      className="m-0"
                      style={{ height: '20px', width: '200%' }}
                    >
                      <Placeholder
                        className="w-50"
                        size="sm"
                        bg="secondary"
                        style={{ opacity: '0.01', borderRadius: '10px' }}
                      />
                    </Placeholder>
                  </>
                </Col>
              </Row>
              <Row className="p-2 mb-2">
                <Col xl={6} lg={6} md={6} sm={12}>
                  <>
                    <Placeholder
                      as="p"
                      animation="glow"
                      className="m-0"
                      style={{ height: '20px', width: '200%' }}
                    >
                      <Placeholder
                        className="w-50"
                        size="sm"
                        bg="secondary"
                        style={{ opacity: '0.01', borderRadius: '10px' }}
                      />
                    </Placeholder>
                  </>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <>
                    <Placeholder
                      as="p"
                      animation="glow"
                      className="m-0"
                      style={{ height: '20px', width: '200%' }}
                    >
                      <Placeholder
                        className="w-50"
                        size="sm"
                        bg="secondary"
                        style={{ opacity: '0.01', borderRadius: '10px' }}
                      />
                    </Placeholder>
                  </>
                </Col>
              </Row>
              <Row className="p-2 mb-2">
                <Col xl={6} lg={6} md={6} sm={12}>
                  <>
                    <Placeholder
                      as="p"
                      animation="glow"
                      className="m-0"
                      style={{ height: '20px', width: '200%' }}
                    >
                      <Placeholder
                        className="w-50"
                        size="sm"
                        bg="secondary"
                        style={{ opacity: '0.01', borderRadius: '10px' }}
                      />
                    </Placeholder>
                  </>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <>
                    <Placeholder
                      as="p"
                      animation="glow"
                      className="m-0"
                      style={{ height: '20px', width: '200%' }}
                    >
                      <Placeholder
                        className="w-50"
                        size="sm"
                        bg="secondary"
                        style={{ opacity: '0.01', borderRadius: '10px' }}
                      />
                    </Placeholder>
                  </>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="p-2 mt-2">
            <Col xl={2} lg={2} md={2} sm={2}>
              <>
                <Placeholder
                  as="p"
                  animation="glow"
                  className="m-0"
                  style={{ height: '20px', width: '200%' }}
                >
                  <Placeholder
                    className="w-50"
                    size="sm"
                    bg="secondary"
                    style={{ opacity: '0.01', borderRadius: '10px' }}
                  />
                </Placeholder>
              </>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2}>
              <>
                <Placeholder
                  as="p"
                  animation="glow"
                  className="m-0"
                  style={{ height: '20px', width: '200%' }}
                >
                  <Placeholder
                    className="w-50"
                    size="sm"
                    bg="secondary"
                    style={{ opacity: '0.01', borderRadius: '10px' }}
                  />
                </Placeholder>
              </>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4}>
              <>
                <Placeholder
                  as="p"
                  animation="glow"
                  className="m-0"
                  style={{ height: '20px', width: '200%' }}
                >
                  <Placeholder
                    className="w-50"
                    size="sm"
                    bg="secondary"
                    style={{ opacity: '0.01', borderRadius: '10px' }}
                  />
                </Placeholder>
              </>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2}>
              <>
                <Placeholder
                  as="p"
                  animation="glow"
                  className="m-0"
                  style={{ height: '20px', width: '200%' }}
                >
                  <Placeholder
                    className="w-50"
                    size="sm"
                    bg="secondary"
                    style={{ opacity: '0.01', borderRadius: '10px' }}
                  />
                </Placeholder>
              </>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2}>
              <>
                <Placeholder
                  as="p"
                  animation="glow"
                  className="m-0"
                  style={{ height: '20px', width: '200%' }}
                >
                  <Placeholder
                    className="w-50"
                    size="sm"
                    bg="secondary"
                    style={{ opacity: '0.01', borderRadius: '10px' }}
                  />
                </Placeholder>
              </>
            </Col>
          </Row>

          <Row className="p-2 mt-2">
            <Col xl={2} lg={2} md={2} sm={2}>
              <>
                <Placeholder
                  as="p"
                  animation="glow"
                  className="m-0"
                  style={{ height: '20px', width: '200%' }}
                >
                  <Placeholder
                    className="w-50"
                    size="sm"
                    bg="secondary"
                    style={{ opacity: '0.01', borderRadius: '10px' }}
                  />
                </Placeholder>
              </>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2}>
              <>
                <Placeholder
                  as="p"
                  animation="glow"
                  className="m-0"
                  style={{ height: '20px', width: '200%' }}
                >
                  <Placeholder
                    className="w-50"
                    size="sm"
                    bg="secondary"
                    style={{ opacity: '0.01', borderRadius: '10px' }}
                  />
                </Placeholder>
              </>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4}>
              <>
                <Placeholder
                  as="p"
                  animation="glow"
                  className="m-0"
                  style={{ height: '20px', width: '200%' }}
                >
                  <Placeholder
                    className="w-50"
                    size="sm"
                    bg="secondary"
                    style={{ opacity: '0.01', borderRadius: '10px' }}
                  />
                </Placeholder>
              </>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2}>
              <>
                <Placeholder
                  as="p"
                  animation="glow"
                  className="m-0"
                  style={{ height: '20px', width: '200%' }}
                >
                  <Placeholder
                    className="w-50"
                    size="sm"
                    bg="secondary"
                    style={{ opacity: '0.01', borderRadius: '10px' }}
                  />
                </Placeholder>
              </>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2}>
              <>
                <Placeholder
                  as="p"
                  animation="glow"
                  className="m-0"
                  style={{ height: '20px', width: '200%' }}
                >
                  <Placeholder
                    className="w-50"
                    size="sm"
                    bg="secondary"
                    style={{ opacity: '0.01', borderRadius: '10px' }}
                  />
                </Placeholder>
              </>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default ConvertingScreenInfoLoading;
