import React from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

function VoidConfirmBox({ show, onHide, content, handleDelete }) {
  const Translate = useAxisproTranslate();

  return (
    <Modal show={show} onHide={onHide} size="md">
      <Card>
        <Card.Header className="text-center">
          <h5 className="fs--1 text-uppercase text-dark fw-bold">
            {Translate('Void transaction')}
          </h5>
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center p-0">
          <p className="m-0 fs--1">
            {content
              ? Translate(content)
              : Translate('Are you sure you want to delete this item?')}
          </p>
        </Card.Body>
        <Card.Footer className="d-flex gap-2 align-items-center justify-content-end">
          <Button
            variant="danger"
            style={{ boxShadow: 'none' }}
            onClick={handleDelete}
            size="sm"
          >
            {Translate('Confirm')}
          </Button>
          <Button
            variant="secondary"
            style={{ boxShadow: 'none' }}
            onClick={onHide}
            size="sm"
          >
            {Translate('Cancel')}
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
}

VoidConfirmBox.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  handleDelete: PropTypes.func,
  content: PropTypes.string
};

export default VoidConfirmBox;
