import axios from 'axios';
import { ActiveTaskContext, AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import WorkOrderLineItemTaskItem from 'module/Common/ViewPages/WorkOrder/components/WorkOrderLineItemTaskItem';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Table } from 'react-bootstrap';
import { FaClock } from 'react-icons/fa6';

export default function ActiveTaskDetailsDropdown() {
  const { user } = useContext(AuthWizardContext);
  const { activeTask, setActiveTask } = useContext(ActiveTaskContext);
  const [isOpen, setIsOpen] = useState(false);
  const Translate = useAxisproTranslate();

  const handleToggle = async () => {
    setIsOpen(!isOpen);
    if (
      user?.running_task_id &&
      !activeTask?.assignee &&
      !activeTask?.isFetching
    ) {
      setActiveTask({
        id: user.running_task_id,
        showLoadingUI: true,
        isFetching: true
      });

      try {
        const apiResult = await axios.get('crm/running-tasks-info');
        const taskDetails = apiResult?.data?.data?.tasks?.[0];
        const currentTimeData =
          taskDetails?.current_time_tracker?.[0]?.total_worked_so_far_detailed;
        if (!activeTask?.assignee) {
          setActiveTask(
            taskDetails
              ? {
                  ...taskDetails,
                  ...(!taskDetails?.timeData
                    ? {
                        timeData: {
                          hour:
                            parseInt(
                              currentTimeData?.total_hours_including_days_as_hours
                            ) || 0,
                          minute: parseInt(currentTimeData?.minutes) || 0,
                          second: parseInt(currentTimeData?.seconds) || 0
                        }
                      }
                    : null)
                }
              : undefined
          );
        }
      } catch (error) {
        if (!activeTask?.assignee) setActiveTask(undefined);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (activeTask?.showQuickActionCenter) {
      setIsOpen(true);
    }
  }, [activeTask?.showQuickActionCenter]);

  return user?.running_task_id ? (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        variant="transparent"
        style={{ boxShadow: 'none', border: 'none' }}
        className="px-1 py-0"
        title={Translate('Manage Running Task')}
      >
        <FaClock />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret-bg">
        <Card className="dropdown-menu-end shadow-none">
          <Card.Body className="p-2">
            <Table className="m-0" borderless>
              <tbody>
                <WorkOrderLineItemTaskItem
                  isDropDownPreview
                  task={activeTask}
                  itemTargetId={activeTask?.itemTargetId}
                  setWorkOrderVoucherData={activeTask?.setWorkOrderVoucherData}
                  colSpan={1}
                />
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
}
