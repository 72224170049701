import { React, useEffect, useState, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Col, Dropdown, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { Bar } from 'react-chartjs-2';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { getColor, rgbaColor } from 'helpers/utils';
import { AuthWizardContext } from 'context/Context';
import { useBreakpoints } from '../../../../../hooks/useBreakpoints';
import { apiCall } from 'helpers/apiCalls';
import ListLoading from 'module/Common/Loading/ListLoading';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

function SalesChart({ title, customer_id, salesman_id }) {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const { breakpoints } = useBreakpoints();
  const [queryParams] = useSearchParams();
  const { customerId, salesmanId } = useParams();
  const supplierId = queryParams.get('entry');
  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: []
  });
  const [loading, setLoading] = useState(false);
  const baseCurrency = user?.branch?.base_currency ?? '';

  const fetchData = async () => {
    setLoading(true);
    setBarChartData({
      labels: [],
      datasets: []
    });
    const data = await apiCall({
      url:
        customerId || customer_id
          ? customerId
            ? 'sales/customers-graph/' + customerId
            : 'sales/customers-graph/' + customer_id
          : salesmanId || salesman_id
          ? salesmanId
            ? 'sales/salesman-graph/' + salesmanId
            : 'sales/salesman-graph/' + salesman_id
          : 'purchase/supplier-graph/' + supplierId
    });
    formatBarChartData(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [customerId, salesmanId, supplierId]);

  const formatBarChartData = data => {
    let netTotal = 0;
    if (data && data.length > 0) {
      let chartData = data.reduce(
        (initalValue, item) => {
          netTotal = netTotal + parseFloat(item.inv_amount);
          initalValue.labels = [...initalValue.labels, item.year_and_month];
          initalValue.datasets[0].label =
            Translate('Total Income') +
            '(Last 6 months)' +
            `-  ${baseCurrency}` +
            Math.abs(netTotal).toFixed(2);
          initalValue.datasets[0].data = [
            ...(initalValue.datasets[0].data ?? []),
            item.inv_amount
          ];
          return initalValue;
        },
        {
          labels: [],
          datasets: [
            {
              label: '',
              data: [],
              backgroundColor: [
                rgbaColor(getColor('secondary'), 0.2),
                rgbaColor(getColor('warning'), 0.2),
                rgbaColor(getColor('danger'), 0.2),
                rgbaColor(getColor('success'), 0.2),
                rgbaColor(getColor('info'), 0.2),
                rgbaColor(getColor('primary'), 0.2)
              ],
              borderColor: [
                getColor('secondary'),
                getColor('warning'),
                getColor('danger'),
                getColor('success'),
                getColor('info'),
                getColor('primary')
              ],
              borderWidth: 1
            }
          ]
        }
      );
      setBarChartData(chartData);
    }
  };

  const options = {
    plugins: {
      tooltip: chartJsDefaultTooltip()
    },
    scales: {
      x: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1)
        }
      },
      y: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1),
          drawBorder: true
        }
      }
    }
  };

  return (
    <>
      {loading ? (
        <ListLoading />
      ) : (
        <Row className="d-flex justify-content-between flex-row">
          <Col
            lg={4}
            className="d-flex align-items-center flex-1 mb-3"
            style={{ justifyContent: breakpoints.down('sm') ? 'start' : '' }}
          >
            <h5 className="fs--1 m-0">{Translate(title)}</h5>
          </Col>
          <Col
            lg={8}
            md={8}
            sm={10}
            className="d-flex flex-row justify-content-end flex-1 mb-3"
            style={{
              paddingInlineEnd: breakpoints.down('sm') ? '0' : '',
              paddingInlineStart: breakpoints.down('sm') ? '0' : ''
            }}
          >
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                size="sm"
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  border: 'none'
                }}
              >
                <span className="fs--1 text-primary">
                  {Translate('Last 6 months')}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2">
                <Dropdown.Item>{Translate('Action')}</Dropdown.Item>
                <Dropdown.Item>{Translate('Action')}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                size="sm"
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  border: 'none'
                }}
              >
                <span className="fs--1 text-primary">
                  {Translate('Accrual')}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2">
                <Dropdown.Item>{Translate('Action')}</Dropdown.Item>
                <Dropdown.Item>{Translate('Action')}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={12}>
            <Bar
              data={barChartData}
              options={options}
              height={700}
              width={1618}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

SalesChart.propTypes = {
  title: PropTypes.string,
  customer_id: PropTypes.any,
  salesman_id: PropTypes.any
};

export default SalesChart;
