import React from 'react';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
const SubTotalLineItem = ({
  textAreaColSpan,
  amountAreaColSpan,
  totalAmount
}) => {
  const Translate = useAxisproTranslate();
  return (
    <tr>
      <td colSpan={textAreaColSpan}>
        <div
          className="d-flex justify-content-end text-dark"
          style={{
            fontSize: '0.83rem',
            fontWeight: 'bold'
          }}
        >
          {Translate('Sub-Total')}
        </div>
      </td>
      <td
        className="text-dark"
        style={{
          fontSize: '0.83rem',
          fontWeight: 'bold'
        }}
        colSpan={amountAreaColSpan}
      >
        {totalAmount
          ? totalAmount.toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          : '0.00'}
      </td>
    </tr>
  );
};

SubTotalLineItem.propTypes = {
  textAreaColSpan: PropTypes.number.isRequired,
  amountAreaColSpan: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired
};

export default SubTotalLineItem;
