export const CustomFieldFormValidation = (customFields, data, setFormError) => {
  let errorData = {};
  const isRequiredObject =
    customFields.length > 0 &&
    customFields.reduce((result, obj) => {
      if (obj.is_required == 1) {
        result[obj.id] = obj.label;
      }
      return result;
    }, {});

  const missingKeys =
    customFields.length > 0 &&
    Object.keys(isRequiredObject).filter(key => !(key in data));

  if (customFields.length > 0) {
    if (isRequiredObject != {}) {
      if (missingKeys.length === 0) {
        return true;
      } else {
        missingKeys.forEach(key => {
          errorData = {
            ...errorData,
            [key]: [`THE ${isRequiredObject[key]} FIELD IS REQUIRED.`]
          };
        });
        setFormError(errorData);
        return false;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
};
