import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { AuthWizardContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import SelectBranch from 'components/form/SelectBranch';

function ChangeBranch() {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [branchData, setBranchData] = useState({});

  useEffect(() => {
    if (user?.branch_id) {
      setBranchData({
        branch_id: user?.branch_id,
        branch_id_ref: {
          label: user?.branch?.branch_name,
          value: user?.branch_id
        }
      });
    }
  }, [user]);

  const branchChange = data => {
    axios({
      method: 'put',
      url: `user/${user?.id}`,
      data: data
    })
      .then(response => {
        if (response.data.success) {
          showToast(response.data.message, 'success');
          window.location.reload();
        } else {
          showToast(
            Translate(
              `Something went wrong, please refresh the page and try again.`
            ),
            'error'
          );
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(Translate(error.response.data.message), 'error');
        } else {
          showToast(
            Translate(
              `Something went wrong, please refresh the page and try again.`
            ),
            'error'
          );
        }
      });
  };

  const handleFieldChange = (e, action) => {
    if (action?.action === 'clear') {
      setBranchData({
        branch_id: user?.branch_id,
        branch_id_ref: {
          label: user?.branch?.branch_name,
          value: user?.branch_id
        }
      });
    } else {
      let data = {
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        username: user?.username,
        branch_id: e?.value,
        company_id: user?.branch?.company_id,
        time_zone: user?.time_zone
      };
      branchChange(data);
      setBranchData({
        branch_id: e?.value,
        branch_id_ref: e
      });
    }
  };

  return (
    <div className="flex-fill me-2" style={{ width: '13rem' }}>
      <SelectBranch
        name={'branch_id'}
        value={branchData?.branch_id_ref}
        handleFieldChange={handleFieldChange}
        navbar
      />
    </div>
  );
}

export default ChangeBranch;
