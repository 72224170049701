import React, { createContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

export const AppDropdownMenuContextInitialValue = {
  show: false,
  parent: undefined,
  MenuList: null
};

export const AppDropdownMenuContext = createContext(
  AppDropdownMenuContextInitialValue
);

const AppDropdownMenuProvider = ({ children }) => {
  const menuCover = useRef(null);
  const [appMenuConfigs, setAppMenuConfigs] = useState(
    AppDropdownMenuContextInitialValue
  );

  useEffect(() => {
    const removeMenu = () => {
      if (appMenuConfigs.show)
        return setTimeout(() => {
          setAppMenuConfigs(AppDropdownMenuContextInitialValue);
        }, 150);
    };

    document.addEventListener('mousedown', removeMenu);

    return () => {
      document.removeEventListener('mousedown', removeMenu);
    };
  });

  useEffect(() => {
    const handleMenuCoverPosition = () => {
      if (
        appMenuConfigs.show &&
        appMenuConfigs.parent &&
        appMenuConfigs.parent.current &&
        menuCover &&
        menuCover.current
      ) {
        let parentCordinates =
          appMenuConfigs.parent.current.getBoundingClientRect();
        let menuCoverCordinates = menuCover.current.getBoundingClientRect();

        menuCover.current.style.transform = `translate(${
          window.innerWidth - parentCordinates.right > 0
            ? parentCordinates.left - menuCoverCordinates.width
            : window.innerWidth - menuCoverCordinates.width - 15
        }px, ${parentCordinates.bottom}px)`;
        setTimeout(() => {
          menuCover.current.style.opacity = 1;
        }, 150);
      }
    };
    handleMenuCoverPosition();
    window.addEventListener('resize', handleMenuCoverPosition);
    return () => {
      window.removeEventListener('mousedown', handleMenuCoverPosition);
    };
  }, [appMenuConfigs.parent, appMenuConfigs.show]);

  return (
    <AppDropdownMenuContext.Provider
      value={{ appMenuConfigs, setAppMenuConfigs }}
    >
      {children}
      {appMenuConfigs.show && appMenuConfigs.MenuList ? (
        <div className="app-dropdown-menu-cover" ref={menuCover}>
          <appMenuConfigs.MenuList />
        </div>
      ) : null}
    </AppDropdownMenuContext.Provider>
  );
};

AppDropdownMenuProvider.propTypes = {
  children: PropTypes.any
};

export default AppDropdownMenuProvider;
