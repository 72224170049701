import paymentStatusChecking from 'helpers/paymentStatusChecking';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { TiTick } from 'react-icons/ti';
import PropTypes from 'prop-types';

export default function PaymentStatus({ paymentStatus, ...rest }) {
  const Translate = useAxisproTranslate();
  const paymentStatusDetails = paymentStatusChecking(paymentStatus);
  return paymentStatusDetails ? (
    <Badge bg={paymentStatusDetails?.bg} {...rest}>
      <span>{Translate(paymentStatusDetails?.status)}</span>
      {paymentStatusDetails?.statusInLowerCase !== 'un paid' ? (
        <TiTick />
      ) : null}
    </Badge>
  ) : null;
}

PaymentStatus.propTypes = {
  paymentStatus: PropTypes.string
};
