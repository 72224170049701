import ReferenceCopyToClipBoard from 'module/Common/ViewPages/Common/ReferenceCopyToClipBoard';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function ShowReference({ data, to, offCanvas, readOnly }) {
  const isAuto = !!(data?.reference?.toLowerCase() === 'auto');
  return isAuto ? (
    <p className="m-0 text-uppercase">{data?.reference}</p>
  ) : (
    <>
      {readOnly ? (
        <p className="m-0 text-muted">{data?.reference}</p>
      ) : (
        <Link
          to={to}
          className={`d-flex flex-row align-items-center gap-1 text-dark ${
            !offCanvas ? 'text-decoration-none' : ''
          }`}
        >
          <p className="m-0">{data?.reference}</p>
        </Link>
      )}

      <ReferenceCopyToClipBoard data={data} />
    </>
  );
}

ShowReference.propTypes = {
  data: PropTypes.object,
  to: PropTypes.string,
  offCanvas: PropTypes.bool,
  readOnly: PropTypes.bool
};
