import { React } from 'react';
import { Modal } from 'react-bootstrap';
import { FcOk } from 'react-icons/fc';
import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';
const PasswordSuccessModal = ({ show }) => {
  // const navigate = useNavigate();

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered
        className="modal-with-overlay"
      >
        <Modal.Body>
          <div className="password-reset-success-modal d-flex flex-column align-items-center justify-content-center">
            <h1 className="fs-1 fw-bold mt-4">Password Updated</h1>
            <div className=" d-flex flex-column align-items-center justify-content-center mt-4 mb-4">
              <FcOk size={60} />
              <p className="pb-0 mb-0">Password updated!</p>
              <div className="text-dark mt-0 pt-1">
                Congratulations, your password has been updated
              </div>
            </div>
            {/* <Button
              onClick={() => {
                navigate('/authentication/login');
              }}
              variant="success"
              type="submit"
              size="sm"
              className="rounded w-100 py-2  d-flex flex-row justify-content-center align-items-center mb-3"
            >
              <span>Log In</span>
              <FaArrowRight className="ms-2 pt-1" size={15} />
            </Button> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
PasswordSuccessModal.propTypes = {
  show: PropTypes.bool
};
export default PasswordSuccessModal;
