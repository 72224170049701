import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function LaunchpadModuleItem({ moduleData, onPageChange }) {
  return (
    <Link
      to={moduleData.to}
      className="text-decoration-none"
      onClick={onPageChange}
    >
      <div className="app-launchpad-module-grid-item">
        {moduleData?.icon ? (
          <FontAwesomeIcon
            icon={moduleData.icon}
            color={moduleData?.['launch-pad-icon-color'] ?? '#fff'}
          />
        ) : null}

        <p>{moduleData.name}</p>
      </div>
    </Link>
  );
}

LaunchpadModuleItem.propTypes = {
  onPageChange: PropTypes.func,
  moduleData: PropTypes.object
};
