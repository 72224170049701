import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { components } from 'react-select';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const ReactSelectAddButton = ({ setShowFormWindow, title, props }) => {
  const Translate = useAxisproTranslate();
  return (
    <components.Menu {...props}>
      <Button
        onClick={setShowFormWindow}
        size="sm"
        variant="secondary"
        className="d-flex flex-row justify-content-center align-items-center w-100 border-bottom text-dark py-2 text-white"
      >
        <BsPlus size={18} />
        <small>{Translate(title ?? 'Add')}</small>
      </Button>
      {props.children}
    </components.Menu>
  );
};

ReactSelectAddButton.propTypes = {
  title: PropTypes.string,
  setShowFormWindow: PropTypes.func.isRequired,
  props: PropTypes.any,
  children: PropTypes.any
};

export default ReactSelectAddButton;
