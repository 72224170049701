const FormattedData = formData => {
  if (formData === undefined || formData === null) {
    return {};
  }
  let newObject = {};
  Object.keys(formData)
    .filter(
      key =>
        !key.endsWith('_ref') &&
        key !== 'date_range' &&
        key !== 'month' &&
        key !== 'undefined'
    )
    .forEach(item => {
      if (
        formData[item] !== undefined &&
        formData[item] !== null &&
        formData[item] !== ''
      ) {
        newObject[item] = formData[item];
      }
    });
  return newObject;
};

export default FormattedData;
