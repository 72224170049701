import { React, useContext, useState } from 'react';
import { Badge, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import ChatWindowSmallScreen from '../chatWindow/smallScreen/ChatWindowSmallScreen';

const ChatTab = ({
  chatUsersData,
  loading,
  hideOffCanvas,
  readUnreadMessage
}) => {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [item, setItem] = useState({});
  const chatUser = chatUsersData.filter(items => items.last_message_time) ?? [];
  const unChatUser =
    chatUsersData.filter(items => !items.last_message_time) ?? [];
  const loadChat = item => {
    if (item.un_read_message_count > 0) {
      readUnreadMessage('INDIVIDUAL', item.id);
    }

    setItem(item);
    setShowChatWindow(true);
  };

  const hideDetailOffCanvas = () => {
    hideOffCanvas();
    setShowChatWindow(false);
  };

  return (
    <>
      {!loading ? (
        <>
          {chatUser &&
            chatUser.length > 0 &&
            chatUser.map((item, index) => (
              <div
                className={`chat-tab-custom-style d-flex ${
                  chatUser.length - 1 !== index && 'border-bottom'
                }  pb-1`}
                onClick={() => loadChat(item)}
                key={index}
              >
                <div className="position-relative me-2">
                  <img
                    src={item.image}
                    width={50}
                    height={50}
                    className="rounded-circle"
                  />
                  {item.is_user_active && (
                    <div
                      className={
                        user.language == 'AR'
                          ? 'translate-middle badge rounded-circle bg-success text-success active-green-symbol-style-rtl'
                          : 'translate-middle badge rounded-circle bg-success text-success active-green-symbol-style'
                      }
                    >
                      .
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column align-items-start justify-content-center me-2 ">
                  <h3 className="m-0 text-capitalize user-name">{item.name}</h3>
                  <h4 className="text-muted pt-1 role-name">
                    {item.roles && item.roles.length > 0 && item.roles[0].name}
                  </h4>
                </div>

                <div className="d-flex flex-column align-items-end me-2 ms-auto justify-content-center">
                  <h3
                    className={`m-0 last-message ${
                      item.un_read_message_count > 0 && 'text-success'
                    } `}
                  >
                    {item.last_message_time}
                  </h3>
                  <h4 className="text-muted last-message">
                    {item.un_read_message_count > 0 ? (
                      <Badge bg="success  ms-1" className="rounded-3">
                        {item.un_read_message_count}
                      </Badge>
                    ) : (
                      item.last_message_at
                    )}
                  </h4>
                </div>
              </div>
            ))}
          <div className="d-flex align-items-center justify-content-between text-muted pt-0 mt-0">
            <div className="border-bottom w-25"></div>
            {Translate('New Chat')}
            <div className="border-bottom w-25"></div>
          </div>
          {unChatUser &&
            unChatUser.length > 0 &&
            unChatUser.map((item, index) => (
              <div
                className={`chat-tab-custom-style d-flex ${
                  chatUser.length - 1 !== index && 'border-bottom'
                }  pb-1`}
                onClick={() => loadChat(item)}
                key={index}
              >
                <div className="position-relative me-2">
                  <img
                    src={item.image}
                    width={50}
                    height={50}
                    className="rounded-circle"
                  />
                  {item.is_user_active && (
                    <div
                      className={
                        user.language == 'AR'
                          ? 'translate-middle badge rounded-circle bg-success text-success active-green-symbol-style-rtl'
                          : 'translate-middle badge rounded-circle bg-success text-success active-green-symbol-style'
                      }
                    >
                      .
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column align-items-start justify-content-center me-2 ">
                  <h3 className="m-0 text-capitalize user-name">{item.name}</h3>
                  <h4 className="text-muted pt-1 role-name">
                    {item.roles && item.roles.length > 0 && item.roles[0].name}
                  </h4>
                </div>

                <div className="d-flex flex-column align-items-end me-2 ms-auto justify-content-center">
                  <h3
                    className={`m-0 last-message ${
                      item.un_read_message_count > 0 && 'text-success'
                    } `}
                  >
                    {item.last_message_time}
                  </h3>
                  <h4 className="text-muted last-message">
                    {item.un_read_message_count > 0 ? (
                      <Badge bg="success  ms-1" className="rounded-3">
                        {item.un_read_message_count}
                      </Badge>
                    ) : (
                      item.last_message_at
                    )}
                  </h4>
                </div>
              </div>
            ))}
        </>
      ) : (
        <Card.Body
          className="d-flex justify-content-center align-items-center"
          style={{ height: '45rem' }}
        >
          <LoadingScreen />
        </Card.Body>
      )}
      <ChatWindowSmallScreen
        show={showChatWindow}
        hideDetailOffCanvas={hideDetailOffCanvas}
        type={'INDIVIDUAL'}
        item={item}
        setShowChatWindow={setShowChatWindow}
      />
    </>
  );
};

ChatTab.propTypes = {
  chatUsersData: PropTypes.any,
  showChat: PropTypes.func,
  loading: PropTypes.bool,
  hideOffCanvas: PropTypes.func,
  readUnreadMessage: PropTypes.func
};

export default ChatTab;
