import { React, useState } from 'react';
import PropTypes from 'prop-types';

import { BsClipboard } from 'react-icons/bs';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const ReferenceCopyToClipBoard = ({ data, reference }) => {
  const Translate = useAxisproTranslate();
  const [copyReference, setCopyReference] = useState(false);
  const [copied, setCopied] = useState(false);

  const copyToClipboard = str => {
    const el = document.createElement('input');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setCopyReference(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
    setTimeout(() => {
      setCopyReference(false);
    }, 2000);
  };
  return (
    <>
      {!copyReference ? (
        <BsClipboard
          style={{ cursor: 'pointer' }}
          onClick={() => copyToClipboard(reference ?? data?.reference)}
          className="ms-1 text-dark"
          size={14}
          title={Translate('Copy to clipboard')}
        />
      ) : (
        <>
          {copied ? (
            <IoIosCheckmarkCircleOutline size={18} className="text-success" />
          ) : (
            ''
          )}
          {/* {copied ? (
            <span className="text-success">{Translate('Copied')}</span>
          ) : (
            ''
          )} */}
        </>
      )}
    </>
  );
};

ReferenceCopyToClipBoard.propTypes = {
  data: PropTypes.any,
  reference: PropTypes.any
};

export default ReferenceCopyToClipBoard;
