import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

export default function EstimationEntryStatus({ status }) {
  return status === 'closed' ? (
    <Badge bg="success" className="text-capitalize">
      {status}
    </Badge>
  ) : status === 'open' ? (
    <Badge bg="warning" className="text-capitalize">
      {status}
    </Badge>
  ) : (
    <Badge bg="danger" className="text-capitalize">
      {status}
    </Badge>
  );
}

EstimationEntryStatus.propTypes = {
  status: PropTypes.string
};
