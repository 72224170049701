import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FaEye, FaTrash } from 'react-icons/fa6';
export default function ProposalPrintLayoutsItem({
  as,
  layout,
  handleView,
  handleTrash,
  ...rest
}) {
  const child = (
    <>
      <div
        className="proposal-layout-item img-thumbnail border p-3 bg-white border-light border-5 shadow-sm rounded-0"
        onClick={event => handleView(event, layout)}
      >
        <div
          className="content-area"
          dangerouslySetInnerHTML={{ __html: layout?.body }}
        ></div>
      </div>
      <div className="py-2">
        <p className="m-0 mt-2 me-auto text-dark fw-bold">{layout?.name}</p>
        <div className="d-flex flex-wrap align-items-center gap-2">
          <p className="small text-muted m-0">
            Created at {layout?.created_at}
          </p>
          <div className="actions d-flex flex-wrap align-items-center gap-2 justify-content-end ms-auto">
            <Button
              size="sm"
              variant="light"
              className="rounded d-flex flex-row align-items-center gap-1"
              onClick={event => handleView(event, layout)}
            >
              <FaEye size={13} />
              <small>Preview</small>
            </Button>
            <Button
              size="sm"
              variant="light"
              className="rounded d-flex flex-row align-items-center gap-1"
              onClick={event => handleTrash(event, layout)}
            >
              <FaTrash size={13} />
              <small>Trash</small>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
  return React.createElement(
    as || 'div',
    {
      className: 'proposal-layout-item-cover d-flex flex-column',
      ...rest
    },
    child
  );
}

ProposalPrintLayoutsItem.propTypes = {
  as: PropTypes.any,
  layout: PropTypes.object,
  handleView: PropTypes.func,
  handleTrash: PropTypes.func
};
