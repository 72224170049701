import { React, useEffect, useState } from 'react';
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams
} from 'react-router-dom';
import axios from 'axios';
import { Offcanvas, Form } from 'react-bootstrap';

import { FaStickyNote } from 'react-icons/fa';

import { apiCall } from 'helpers/apiCalls';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import { SaveButton } from 'module/Common/Buttons/CommonButton';
import { TermsAndConditionsFormKeys } from 'helpers/formKeys';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import removeRefData from 'helpers/removeRefData';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import BackButton from 'components/common/BackButton';
import TextEditor from 'module/Common/InputFields/TextEditor/TextEditor';

const TermsAndConditionsForm = () => {
  const Translate = useAxisproTranslate();
  const [getData] = useOutletContext();
  let navigate = useNavigate();
  const { itemId } = useParams();
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(TermsAndConditionsFormKeys);
  const [formError, setFormError] = useState({});
  const pageNumber = queryParams.get('page');
  const permission = {
    create: getAcodaxPermissionSlug('TC', 'create'),
    update: getAcodaxPermissionSlug('TC', 'update')
  };

  const handleFieldChange = (e, action) => {
    if (action && action?.action === 'text-editor') {
      setFormData({
        ...formData,
        [action.name]: action.value
      });
    } else {
      setFormData(generateFormDataHelper(e, action, formData));
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);

    axios({
      method: itemId ? 'put' : 'post',
      url: `settings/terms-and-conditions${itemId ? '/' + itemId : ''}`,
      data: formDataWithoutRefkeys
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          getData(pageNumber ?? 1);
          navigate(itemId ? -1 : '/settings/terms-and-conditions');
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setOnSave(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    async function initFunc() {
      if (itemId) {
        setLoading(true);
        let details = await apiCall({
          url: 'settings/terms-and-conditions/' + itemId
        });
        setFormData({
          name: details.name,
          description: details.description
          // closed: details.closed,
          // closed_date: details.closed_date
        });
        setLoading(false);
      }
    }

    initFunc();
  }, [itemId]);

  return (
    <Offcanvas
      show
      onHide={() => navigate(-1)}
      placement="end"
      style={{ width: '600px' }}
    >
      <Offcanvas.Header
        closeButton
        className="border-bottom offcanvas-heading-style"
      >
        <div>
          <FaStickyNote size={20} className="me-1 text-dark" />
          {itemId
            ? Translate('Edit Terms And Conditions')
            : Translate('Add  Terms And Conditions')}
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!loading ? (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label className="require-data">
                {Translate('Name')}
              </Form.Label>
              <Form.Control
                name="name"
                value={formData.name}
                placeholder="Enter Name"
                onChange={handleFieldChange}
                isInvalid={!!formError.name}
              />
              <Form.Control.Feedback type="invalid">
                {formError.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label className="require-data">
                {Translate('Description')}
              </Form.Label>
              <TextEditor
                name="description"
                handleFieldChange={handleFieldChange}
                placeholder="Enter Description"
                value={formData.description}
                isInvalid={!!formError.description}
                HideMedia={true}
              />
              <Form.Control.Feedback type="invalid">
                {formError.description}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="d-flex gap-1">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={itemId}
                createPermission={permission?.create}
                editPermission={permission?.update}
              />
              {!onSave && (
                <BackButton variant={'danger'} size={'sm'}>
                  {Translate('Cancel')}
                </BackButton>
              )}
            </Form.Group>
          </Form>
        ) : (
          <LoadingScreen />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TermsAndConditionsForm;
