import { React, useState } from 'react';
import { Button, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaFile, FaTimes } from 'react-icons/fa';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

const FileUploadItem = ({
  fileItem,
  index,
  setFilesArray,
  onChange,
  limitFeature,
  name,
  supportHandleFieldChange
}) => {
  const Translate = useAxisproTranslate();
  const [uplaodCompleted] = useState(false);
  const [uploadPercentage] = useState(Math.floor(Math.random() * 100));

  const handleRemove = (e, index) => {
    e.preventDefault();
    setFilesArray(prev => {
      let newArrayResult = prev.filter(
        (item, Itemindex) => item && index !== Itemindex
      );

      const onChangeResult = newArrayResult.reduce((initalArray, item) => {
        if (item.isValid) {
          initalArray.push(item.file);
        }
        return initalArray;
      }, []);

      onChange(
        supportHandleFieldChange
          ? { target: { name: name || 'files', value: onChangeResult } }
          : onChangeResult
      );
      return newArrayResult;
    });
  };
  return (
    <div
      className={`mb-2 d-flex flex-row align-items-center p-2 ${
        !fileItem.isValid ? 'border border-danger' : 'border'
      }`}
      style={!fileItem.isValid ? { backgroundColor: '#fff8f8' } : null}
    >
      <div className="file-item-icon me-2">
        <FaFile />
      </div>
      <div className="file-item-details flex-fill">
        <p className="file-item-name mb-0 text-muted">
          {fileItem.file.name}
          {!limitFeature && fileItem.isValid ? (
            <span className="ms-1 small text-warning fw-bold">
              ({uploadPercentage}%)
            </span>
          ) : null}
          <br />
          <span className="small fw-bold text-primary">
            {fileItem?.fileSize + ' ' + fileItem?.fileSizeUnit}
          </span>
        </p>
        {!fileItem.isValid && fileItem.errors
          ? fileItem.errors.map((error, key) => (
              <p className="small text-danger mb-0" key={key}>
                {error}
              </p>
            ))
          : null}
        {!limitFeature && fileItem.isValid ? (
          <ProgressBar
            animated
            variant={uplaodCompleted ? 'success' : 'primary'}
            now={uploadPercentage}
            style={{ height: '7px' }}
          />
        ) : null}
      </div>
      <div className="file-item-actions ms-2">
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip style={{ fontSize: '10px' }}>
              {Translate('Remove Item')}
            </Tooltip>
          }
        >
          <Button
            variant="danger"
            size="sm"
            className="py-0 px-1 text-center"
            onClick={e => handleRemove(e, index)}
          >
            <FaTimes size={7} />
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

FileUploadItem.propTypes = {
  fileItem: PropTypes.object,
  index: PropTypes.number,
  setFilesArray: PropTypes.func,
  onChange: PropTypes.func,
  limitFeature: PropTypes.bool,
  name: PropTypes.string,
  supportHandleFieldChange: PropTypes.bool
};
export default FileUploadItem;
