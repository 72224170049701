import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { Badge, Card, Table } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { AiOutlineFileText } from 'react-icons/ai';
import { useContext } from 'react';
import { AuthWizardContext } from 'context/Context';
import LedgerInquiryTableLineItem from './LedgerInquiryTableLineItem';
import DataFetching from 'module/Report/LedgerReport/DataFetching';
import TableLoading from 'module/Report/Common/TableLoading';
import LedgerReportForm from 'module/Report/LedgerReport/LedgerReportForm';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FiChevronsRight } from 'react-icons/fi';
import ExportButton from 'components/export-button/ExportButton';
import removeRefData from 'helpers/removeRefData';

export default function LedgerInquiryDetailsView({ accountCode, accountName }) {
  const { user } = useContext(AuthWizardContext);
  const scrollableSection = useRef();
  const navigate = useNavigate();
  const Translate = useAxisproTranslate();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState({});
  const [data, setData] = useState({});
  const [initialOpeningBalance, setInitialOpeningBalance] = useState(0);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const affectedFilters = searchParams.get('filters');
  const location = useLocation();
  const apiCallDelayTime = location?.state?.apiCallDelay ?? 0;

  const getFormattedDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const lastDate = new Date(year, month + 1, 0).getDate();
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month, lastDate);
  const formattedStartOfMonth = getFormattedDate(startDate);
  const formattedEndOfMonth = getFormattedDate(endDate);

  const fetchData = async (pageNumber = 1, apiFilters, intial) => {
    if (!intial && loading) return;
    setPage(pageNumber);
    setLoading(true);
    await axios
      .get(
        'finance/reports/ledger-report?account_code=' +
          accountCode +
          '&page=' +
          pageNumber,
        {
          params: removeRefData(apiFilters)
        }
      )
      .then(res => {
        if (res.data.success && res.data?.data) {
          let openingBalanceAmount = openingBalance;
          if (pageNumber === 1) {
            openingBalanceAmount = parseFloat(res?.data?.opening_balance) ?? 0;
            setInitialOpeningBalance(openingBalanceAmount);
          }

          if (!Array.isArray(res.data.data?.data)) {
            res.data.data.data = [];
          }

          res.data.data.data = res.data.data.data.reduce(
            (newResult, itemData) => {
              openingBalanceAmount += parseFloat(itemData.amount ?? 0);
              itemData.opening_blance_amount = openingBalanceAmount;
              itemData.opening_blance_amount_formatted =
                openingBalanceAmount.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                });

              newResult.push(itemData);
              return newResult;
            },
            []
          );

          if (pageNumber === 1 && res.data.data.data[0]?.account_name)
            setOpeningBalance(openingBalanceAmount);
          setData(
            pageNumber === 1
              ? res.data.data
              : prev => ({
                  ...res.data.data,
                  data: [...(prev?.data ?? []), ...res.data.data.data]
                })
          );
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  function onFilter() {
    let urlFriendlyFilters = encodeURI(JSON.stringify(filters));
    navigate(
      '/finance/ledger-inquiry?account_code=' +
        accountCode +
        '&filters=' +
        urlFriendlyFilters
    );
  }

  useEffect(() => {
    setLoading(true);
    const apiCallTime = setTimeout(() => {
      const filtersList = affectedFilters
        ? JSON.parse(decodeURI(affectedFilters))
        : {};
      let affectedKeys = Object.keys(
        JSON.parse(decodeURI(affectedFilters)) ?? {}
      );
      if (
        !affectedKeys?.includes('date_from') ||
        !affectedKeys?.includes('date_to')
      ) {
        filtersList.date_from = formattedStartOfMonth;
        filtersList.date_to = formattedEndOfMonth;
      }

      fetchData(1, filtersList, true);
      setFilters(prev => ({
        ...prev,
        ...filtersList,
        account_code: accountCode
      }));
    }, apiCallDelayTime);

    return () => {
      clearTimeout(apiCallTime);
      setData({});
      setFilters({});
    };
  }, [accountCode, affectedFilters, apiCallDelayTime]);

  useEffect(() => {
    if (scrollableSection?.current && scrollableSection.current.children[0]) {
      const contentSection = scrollableSection.current.children[0];
      contentSection.className = 'simplebar-content d-flex flex-column';
      contentSection.style = 'height: 100%';
    }
  });

  return (
    <Card className="flex-fill view-page-content-wrapper">
      <Card.Header className="d-flex m-0 align-items-center border-bottom">
        <div className="d-flex flex-row align-items-center me-2">
          <AiOutlineFileText size={28} className="me-2 text-dark" />
          <div>
            <p
              className="mb-0 d-flex text-uppercase"
              style={{
                fontSize: '.95rem'
              }}
            >
              <span className="text-dark">{Translate('Ledger Report')}</span>
              {!loading && accountName ? (
                <>
                  <FiChevronsRight color="red" className="mx-2" />
                  {accountName}
                </>
              ) : null}
            </p>
          </div>
        </div>
        {!loading && data?.data?.length > 0 ? (
          <div className="ms-auto">
            <ExportButton
              className="me-1"
              url="finance/reports/ledger-report/export"
              filters={removeRefData(filters)}
              initialMessage={Translate('Ledger Report export started')}
              successMessage={Translate('Ledger Report export completed')}
            />
          </div>
        ) : null}
      </Card.Header>
      <Card.Body>
        <div className="flex-fill d-flex flex-column position-relative">
          {!loading || page > 1 ? (
            <div
              className="shadow-sm pb-3 bg-white position-sticky"
              style={{
                top: 0,
                zIndex: 1
              }}
            >
              <LedgerReportForm
                fullWidth
                hideHeader
                md={2}
                xl={4}
                ledgerInquiryFilter
                formData={filters}
                setFormData={setFilters}
                accountCode={accountCode}
                onSubmit={onFilter}
              />
            </div>
          ) : null}
          <SimpleBar
            className="simplebar-style-list-page-without-pagination"
            scrollableNodeProps={{ ref: scrollableSection }}
          >
            {(!loading || page > 1) && data?.data?.length > 0 ? (
              <Table
                bordered
                hover
                responsive
                className="simple-table-animation"
              >
                <thead className="text-uppercase ">
                  <tr className="bg-dark text-light top-0 position-sticky">
                    <th className="fs--2 p-2 text-center">#</th>

                    <th className="fs--2 p-2 pe-0">{Translate('DATE')}</th>
                    <th className="fs--2 p-2 pe-0">{Translate('TYPE')}</th>
                    <th className="fs--2 p-2 pe-0">{Translate('REFERENCE')}</th>
                    <th className="fs--2 p-2 pe-0">{Translate('PERSON')}</th>
                    <th className="fs--2 p-2 pe-0">
                      {Translate('COST CENTER')}
                    </th>
                    <th className="fs--2 p-2 pe-0">{Translate('MEMO')}</th>
                    <th className="fs--2 p-2 pe-0">{Translate('DEBIT')}</th>
                    <th className="fs--2 p-2 pe-0">{Translate('CREDIT')}</th>
                    <th className="fs--2 p-2 pe-0">{Translate('BALANCE')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      colSpan={10}
                      style={{
                        fontSize: '0.72rem'
                      }}
                    >
                      **Amount is displayed in your base currency{' '}
                      <Badge bg="success">
                        {user?.branch?.base_currency ?? ''}
                      </Badge>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={9} className="fw-bold text-dark bg-light">
                      {Translate('Opening Balance')}
                    </th>
                    <td className="bg-light">
                      {initialOpeningBalance.toLocaleString(
                        navigator.language,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      )}
                    </td>
                  </tr>
                  {data.data.map((ledgerData, index) => (
                    <LedgerInquiryTableLineItem
                      key={index}
                      lineNumber={index + 1}
                      ledgerData={ledgerData}
                    />
                  ))}
                  {!loading && typeof data?.links?.next === 'string' ? (
                    <tr>
                      <td colSpan={10}>
                        <DataFetching
                          nextLink={page + 1}
                          loadNextPage={() => fetchData(page + 1, filters)}
                        />
                      </td>
                    </tr>
                  ) : loading ? (
                    <TableLoading row={15} column={10} />
                  ) : null}
                </tbody>
              </Table>
            ) : (
              <LoadingScreenCover className="mb-3 bg-transparent shadow-none">
                {loading ? (
                  <LoadingScreen
                    message={Translate('Looking for ledger item details')}
                  />
                ) : (
                  <h5 className=" text-center text-secondary">
                    {Translate(
                      'There were no results found for this ledger item!'
                    )}{' '}
                    &#128578;
                  </h5>
                )}
              </LoadingScreenCover>
            )}
          </SimpleBar>
        </div>
      </Card.Body>
    </Card>
  );
}

LedgerInquiryDetailsView.propTypes = {
  accountCode: PropTypes.string,
  accountName: PropTypes.string
};
