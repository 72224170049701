import { React, useState } from 'react';
import { Button, Col, Collapse, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const OtherDetailsCollapse = ({ data }) => {
  const Translate = useAxisproTranslate();
  const [open, setOpen] = useState(true);

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="customer-more-info-collapse"
        aria-expanded={open}
        variant="white"
        size="small"
        className="px-0 text-primary d-flex flex-row align-items-center"
        style={{ border: 'none', outLine: 'none', boxShadow: 'none' }}
      >
        <small>{Translate('Other Details')}</small>
        <FontAwesomeIcon
          icon={open ? 'caret-down' : 'caret-right'}
          className="ms-1"
        />
      </Button>
      <Collapse in={open}>
        <Row
          id="customer-more-info-collapse"
          className="mt-2"
          style={{ fontSize: '.8rem', fontWeight: '600' }}
        >
          {data.address ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate('Address')}{' '}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data.address}
              </Col>
            </>
          ) : (
            ''
          )}
          {data.secondary_contact_number ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate('Secondary Number')}{' '}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data.secondary_contact_number}
              </Col>
            </>
          ) : (
            ''
          )}
        </Row>
      </Collapse>
    </>
  );
};

OtherDetailsCollapse.propTypes = {
  data: PropTypes.object
};

export default OtherDetailsCollapse;
