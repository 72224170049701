import axios from 'axios';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import PopupFormButton from 'components/popup-form-button/PopupFormButton';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaLayerGroup } from 'react-icons/fa';
import SelectUnit from '../SelectUnit';
import SelectTaxGroup from '../SelectTaxGroup';
import SelectProductType from '../SelectProductType';
import SelectAccountDescriptions from '../SelectAccountDescriptions';
import { categoryFormKeys } from 'helpers/formKeys';
import { AuthWizardContext } from 'context/Context';
import setCategoryFormDefaultData from 'helpers/setCategoryFormDefaultData';
import removeRefData from 'helpers/removeRefData';

const CategoryPopupForm = ({
  callBack,
  setData,
  labelName,
  keyName,
  name,
  showAddButton,
  onHide,
  show,
  includeDetailsKey,
  product_type
}) => {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [showWindow, setShowWindow] = useState(show ?? false);
  const [formData, setFormData] = useState(categoryFormKeys);
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const handleShow = () => {
    setShowWindow(!showWindow);
    if (show) {
      setFormData({ ...categoryFormKeys, ...setCategoryFormDefaultData(user) });
      setFormError({});
      setIsSaving(false);
      if (onHide && typeof onHide === 'function') onHide();
    }
  };
  const handleFieldChange = (e, action) => {
    if (
      action?.name !== 'product_type' &&
      (!formData?.product_type || formData.product_type?.length < 1)
    )
      return showToast(
        Translate('Product type is required, please choose a product type'),
        'error'
      );
    setFormData(generateFormDataHelper(e, action, formData));
  };
  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setIsSaving(true);

    let dataWithoutRefKeys = removeRefData(formData, 'object');
    if (['goods', 'service'].includes(dataWithoutRefKeys?.product_type)) {
      delete dataWithoutRefKeys.wip_account_code;
    }
    if (['goods'].includes(dataWithoutRefKeys?.product_type)) {
      delete dataWithoutRefKeys.wip_crediting_account_code;
    }
    if (['service'].includes(dataWithoutRefKeys?.product_type)) {
      delete dataWithoutRefKeys.purchase_account_code;
    }

    axios({
      method: 'post',
      url: 'settings/categories',
      data: dataWithoutRefKeys
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          if (callBack) {
            callBack(response.data.data);
          }

          if (response.data.data && setData) {
            setData(
              {
                label: response.data.data[labelName ?? 'name'],
                value: response.data.data[keyName ?? 'id'],
                ...(includeDetailsKey
                  ? { category_details: response.data.data }
                  : null)
              },
              {
                action: 'select-option',
                option: undefined,
                name: name ?? 'category_id'
              }
            );
          }

          handleShow();
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setIsSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setIsSaving(false);
      });
  };

  useEffect(() => {
    setShowWindow(show ?? false);
    if (show) {
      const defaultData = {
        ...categoryFormKeys,
        ...setCategoryFormDefaultData(user)
      };
      if (product_type) {
        defaultData.product_type = product_type;
      }
      setFormData(defaultData);
    }
    return () => {
      setFormData(categoryFormKeys);
      setFormError({});
      setShowWindow(false);
      setIsSaving(false);
    };
  }, [show, user, product_type]);

  return (
    <>
      {showAddButton ? (
        <PopupFormButton onClick={handleShow}>
          {Translate('Add Category')}
        </PopupFormButton>
      ) : null}

      {showWindow ? (
        <Modal
          show={showWindow}
          onHide={handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="d-flex flex-row align-items-center">
                <FaLayerGroup size={20} className="text-success" />
                <span className="ms-1">{Translate('ADD CATEGORY')}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(formError).length > 0 && (
                <ErrorAlert formError={formError} setFormError={setFormError} />
              )}
              {product_type && formData?.product_type ? null : (
                <Form.Group className="mb-3" controlId="form.product_type">
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Product type')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <SelectProductType
                        onChange={handleFieldChange}
                        name="product_type"
                        value={formData?.product_type_ref}
                        error={formError?.product_type}
                      />
                      <FormErrorPopover
                        id="product_type"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.product_type}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              )}

              <Form.Group className="mb-3" controlId="form.name">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Name')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleFieldChange}
                      value={formData.name}
                      isInvalid={!!formError?.name}
                    />
                    <FormErrorPopover
                      id="name"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.name}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3" controlId="form.unit_id">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Unit')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <div className="w-100">
                      <SelectUnit
                        name="unit_id"
                        value={formData.unit_id_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError.unit_id}
                      />
                    </div>
                    <FormErrorPopover
                      id="unit_id"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.unit_id}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3" controlId="form.tax_group_id">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Tax group')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <div className="w-100">
                      <SelectTaxGroup
                        name="tax_group_id"
                        value={formData.tax_group_id_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError?.tax_group_id}
                      />
                    </div>
                    <FormErrorPopover
                      id="tax_group_id"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.tax_group_id}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3" controlId="form.sales_account_code">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Sales account')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <div className="w-100">
                      <SelectAccountDescriptions
                        name="sales_account_code"
                        keyName="code"
                        value={formData.sales_account_code_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError?.sales_account_code}
                      />
                    </div>
                    <FormErrorPopover
                      id="sales_account_code"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.sales_account_code}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="form.inventory_account_code"
              >
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Inventory account')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <div className="w-100">
                      <SelectAccountDescriptions
                        name="inventory_account_code"
                        keyName="code"
                        value={formData.inventory_account_code_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError?.inventory_account_code}
                      />
                    </div>
                    <FormErrorPopover
                      id="inventory_account_code"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.inventory_account_code}
                    />
                  </Col>
                </Row>
              </Form.Group>

              {formData?.product_type !== 'service' && (
                <Form.Group
                  className="mb-3"
                  controlId="form.purchase_account_code"
                >
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('Cost account')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <div className="w-100">
                        <SelectAccountDescriptions
                          name="purchase_account_code"
                          keyName="code"
                          value={formData.purchase_account_code_ref}
                          handleFieldChange={handleFieldChange}
                          error={formError?.purchase_account_code}
                        />
                      </div>
                      <FormErrorPopover
                        id="purchase_account_code_ref"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.purchase_account_code}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              )}

              {formData.product_type === 'manufactured' ? (
                <Form.Group className="mb-3" controlId="form.wip_account_code">
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('WIP account')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <div className="w-100">
                        <SelectAccountDescriptions
                          name="wip_account_code"
                          keyName="code"
                          value={formData.wip_account_code_ref}
                          handleFieldChange={handleFieldChange}
                          error={formError?.wip_account_code}
                        />
                      </div>
                      <FormErrorPopover
                        id="wip_account_code"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.wip_account_code}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              ) : null}

              {formData.product_type === 'service' ||
              formData?.product_type === 'manufactured' ? (
                <Form.Group
                  className="mb-3"
                  controlId="form.wip_crediting_account_code"
                >
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('WIP Crediting account')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <div className="w-100">
                        <SelectAccountDescriptions
                          name="wip_crediting_account_code"
                          keyName="code"
                          value={formData.wip_crediting_account_code_ref}
                          handleFieldChange={handleFieldChange}
                          error={formError?.wip_crediting_account_code}
                        />
                      </div>
                      <FormErrorPopover
                        id="wip_crediting_account_code"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.wip_crediting_account_code}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              ) : null}

              <Form.Group className="mb-3" controlId="form.description">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Description')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="description"
                      onChange={handleFieldChange}
                      value={formData.description}
                      isInvalid={!!formError?.description}
                    />
                    <FormErrorPopover
                      id="description"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.description}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-row justify-content-start">
              <Button
                variant="outline-success"
                size="sm"
                className="d-flex align-items-center justify-content-center"
                onClick={handleSubmit}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="success"
                      className="me-1"
                    />
                    <span className="fs--1">{Translate('Saving...')}</span>
                  </>
                ) : (
                  <span>{Translate('Add')}</span>
                )}
              </Button>
              <Button
                variant="outline-danger"
                onClick={handleShow}
                size="sm"
                disabled={isSaving}
              >
                {Translate('Cancel')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

CategoryPopupForm.propTypes = {
  callBack: PropTypes.func,
  setData: PropTypes.func,
  name: PropTypes.string,
  labelName: PropTypes.string,
  keyName: PropTypes.string,
  show: PropTypes.bool,
  showAddButton: PropTypes.bool,
  onHide: PropTypes.func,
  includeDetailsKey: PropTypes.bool,
  product_type: PropTypes.any
};

export default CategoryPopupForm;
