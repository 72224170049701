import React from 'react';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

function MainDetails({ label, value }) {
  const Translate = useAxisproTranslate();

  return (
    <div className="d-flex gap-3">
      <h6 className="mb-2 fw-bold">{Translate(label)}:&nbsp;</h6>
      <h6 className="mb-2">{value}</h6>
    </div>
  );
}

MainDetails.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any
};

export default MainDetails;
