import { useContext, useEffect } from 'react';

import { AuthWizardContext, StoreContext } from 'context/Context';
import { toast } from 'react-toastify';

function VersionNotification() {
  const { user } = useContext(AuthWizardContext);
  const { store } = useContext(StoreContext);

  useEffect(() => {
    window.Echo.channel(`app-broadcast-${user?.branch_id}`).listen(
      'AcodaxAppBroadCast',
      versionData => {
        if (
          versionData?.type == 'app-version.updated' &&
          versionData?.platform == 'react'
        ) {
          if (versionData?.version != store?.latestVersion?.version) {
            toast.info(
              `A new version of the app is available. Press Ctrl + F5 to get updated!`,
              {
                position: 'top-center',
                autoClose: 10000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'colored'
              }
            );
          }
        }
      }
    );

    return () => {
      window.Echo.channel(`app-broadcast-${user?.branch_id}`).stopListening(
        'AcodaxAppBroadCast'
      );
    };
  }, [user?.branch_id, store?.latestVersion]);

  return null;
}

export default VersionNotification;
