import { React, useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

function SelectFilterItem({
  handleFieldChange,
  name,
  value,
  stockItemList,
  type,
  includeFixedAsset
}) {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const setItems = async name => {
    setLoading(true);
    try {
      let response = await axios.get('inventory/items-auto', {
        params: {
          item_name: name,
          exclude_kits: stockItemList ? 1 : type ? 1 : 0,
          product_type: type
            ? 'govt_service'
            : includeFixedAsset
            ? 'fixed_asset'
            : '',
          include_fixed_asset: includeFixedAsset ? '1' : '0'
        }
      });
      let data = response && response.data.data ? response.data.data : [];
      return data.map(item => ({
        label: item.item_name,
        value: item.stock_id
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleFocus = async () => {
    if (defaultOptions?.length === 0) {
      const defaultOptionsData = await setItems('');
      setDefaultOptions(defaultOptionsData);
    }
  };

  const { MenuList } = components;

  const CustomMenuList = props => (
    <MenuList {...props}>
      {loading ? (
        <div className="w-100 d-flex align-items-center justify-content-center p-3">
          Loading...
        </div>
      ) : (
        <SimpleBar
          style={{ maxHeight: '18rem' }}
          forceVisible="y"
          autoHide={false}
        >
          {props.children}
        </SimpleBar>
      )}
    </MenuList>
  );

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={name ? setItems : setItems}
        defaultOptions={defaultOptions}
        isClearable
        isSelected={true}
        onFocus={handleFocus}
        name={name ? name : 'item'}
        value={value ?? ''}
        onChange={handleFieldChange}
        components={{ MenuList: CustomMenuList }}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({
            ...provided,
            zIndex: 9999,
            fontFamily: 'sans-serif',
            fontSize: '12px',
            borderRadius: '0px',
            textTransform: 'capitalize'
          }),
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: '0px',
            border: '1px solid #b5b5b5',
            height: '10px'
          })
        }}
        menuPortalTarget={document.body}
      ></AsyncSelect>
    </>
  );
}

SelectFilterItem.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.any,
  value: PropTypes.any,
  companyId: PropTypes.any,
  stockItemList: PropTypes.bool,
  type: PropTypes.bool,
  includeFixedAsset: PropTypes.bool,
  children: PropTypes.any
};

export default SelectFilterItem;
