import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { useState } from 'react';
import { apiCall } from 'helpers/apiCalls';
import { useEffect } from 'react';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import LedgerInquiryLineItem from './components/LedgerInquiryLineItem';
import LedgerInquiryDetailsView from './components/LedgerInquiryDetailsView';
import Search from 'components/search/Search';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import SupplierDetails from 'components/supplier-details/SupplierDetails';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import useKeyDownNavigatorHook from 'hooks/useKeyDownNavigatorHook';

export default function LedgerInquiry() {
  const navigate = useNavigate();
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const {
    account_code,
    account_name,
    person_view_id,
    person_view_type,
    reference_view_id,
    reference_view_type
  } = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get('page');
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [AllEntries, setAllEntries] = useState([]);
  const [entries, setEntries] = useState([]);
  const [accountCode, setAccountCode] = useState(undefined);
  const [accountName, setAccountName] = useState(undefined);
  const [timer, setTimer] = useState(null);

  function handleOffcanvasOnHide() {
    let paramsList = Object.fromEntries([...queryParams]);
    delete paramsList.person_view_id;
    delete paramsList.person_view_type;
    delete paramsList.reference_view_id;
    delete paramsList.reference_view_type;
    navigate(location.pathname + '?' + createSearchParams(paramsList));
  }

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: 'finance/chart-of-accounts?type=ledger',
      params: {
        page: page
      }
    });

    setAllEntries(data);
    setEntries(data);
    if (data?.[0]?.code) {
      setAccountCode(data?.[0]?.code);
      setAccountName(data?.[0]?.name);
    }
    setIsLoading(false);
  };

  function filterLedgers(event) {
    let newResult = AllEntries;

    setIsSearching(false);
    const searchKey = event.target.value;
    if (searchKey.length > 0) {
      setIsSearching(true);
      newResult = newResult.filter(
        item =>
          item.name.toLowerCase().includes(searchKey.toLowerCase()) ||
          item.code.toLowerCase().includes(searchKey.toLowerCase())
      );
    }
    setEntries(newResult);

    // Set account code with delay
    if (!account_code && newResult?.[0]?.code) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        setAccountCode(newResult?.[0]?.code);
        setAccountName(newResult?.[0]?.name);
      }, 1000);
      setTimer(newTimer);
    }
  }

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    setAccountCode(account_code ?? accountCode);
    setAccountName(account_name ?? accountName);
    return () => {
      setAccountCode(undefined);
      setAccountName(undefined);
    };
  }, [account_code, account_name]);

  const { containerRef } = useKeyDownNavigatorHook({
    focusElementClassName: 'active-three-column-tab-item',
    activeKeyName: 'code',
    activeItemRef: accountCode,
    dataResult: entries,
    lastPageNumber: 1,
    to: '/finance/ledger-inquiry',
    params: [
      {
        paramKey: 'account_code',
        dataKey: 'code'
      },
      {
        paramKey: 'account_name',
        dataKey: 'name'
      }
    ]
  });

  return !isLoading && (isSearching || entries.length > 0) ? (
    <>
      <Row className="flex-fill mb-3">
        <Col sm={12} md={3} className="d-flex pe-md-2 ps-3" ref={containerRef}>
          <Card className="p-0 flex-fill mb-0">
            <Card.Header
              className="p-3 d-flex"
              style={{ backgroundColor: 'whitesmoke' }}
            >
              <span className="fs--1 fw-bold text-dark text-uppercase">
                {Translate('Ledgers')}
              </span>
              {/* <FilterButton
                className="ms-auto me-1"
                filters={filters}
                url="purchase/purchase-delivery"
                search={{ layout: 'column' }}
              />
              {permissions?.create ? (
                <AddButton to="/purchase/purchase-delivery/add" />
              ) : (
                ''
              )} */}
            </Card.Header>
            <>
              <Search
                placeholder={Translate('Search Ledgers by name | code')}
                onChange={filterLedgers}
              />
              <SimpleBar className="simplebar-style-list-page-without-pagination">
                {entries.length > 0 ? (
                  entries.map((item, index) => (
                    <LedgerInquiryLineItem
                      key={index}
                      item={item}
                      active={item.code === accountCode ? true : false}
                    />
                  ))
                ) : (
                  <h5 className="text-center text-secondary mt-3">
                    {Translate('Ledger item not found!')} &#128578;
                  </h5>
                )}
              </SimpleBar>
            </>
          </Card>
        </Col>
        <Col sm={12} md={9} className="d-md-flex flex-column ps-sm-3 ps-md-0">
          <LedgerInquiryDetailsView
            accountCode={accountCode}
            accountName={accountName}
          />
        </Col>
      </Row>
      {person_view_id ? (
        person_view_type === 'CUSTOMER' ? (
          <CustomerDetails
            show={true}
            onHide={handleOffcanvasOnHide}
            value={{
              value: person_view_id
            }}
            showCustomerDetailsArea={true}
          />
        ) : person_view_type === 'SUPPLIER' ? (
          <SupplierDetails
            show={true}
            onHide={handleOffcanvasOnHide}
            value={{
              value: person_view_id
            }}
            showSupplierDetailsArea={true}
          />
        ) : null
      ) : reference_view_id ? (
        <VoucherDetail
          show={true}
          onHide={handleOffcanvasOnHide}
          setShowVoucherDetailArea={handleOffcanvasOnHide}
          type={reference_view_type}
          voucherId={reference_view_id}
          offCanvas={true}
        />
      ) : null}
    </>
  ) : (
    <LoadingScreenCover className="mb-3">
      {isLoading ? (
        <LoadingScreen message={Translate('Looking for ledgers')} />
      ) : (
        <h5 className="text-center text-secondary">
          {Translate('Ledgers not found!')} &#128578;
        </h5>
      )}
    </LoadingScreenCover>
  );
}
