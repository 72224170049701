import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import BasicDetails from '../Common/PersonBasedComponents/BasicDetails';
import SalesChart from '../Common/PersonBasedComponents/SalesChart';

function SalesmanView({ salesmanData }) {
  return (
    <Container className="mt-3 ps-3 pe-3">
      <Row>
        <Col
          md={4}
          className="p-0"
          style={{ height: '64vh', backgroundColor: 'whitesmoke' }}
        >
          <BasicDetails data={salesmanData} type="salesman" />
        </Col>
        <Col md={8}>
          <Row>
            <SalesChart title="Income" salesman_id={salesmanData.id} />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

SalesmanView.propTypes = {
  salesmanData: PropTypes.any
};

export default SalesmanView;
