import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

const LoadingScreenCover = ({ children, className }) => {
  return (
    <Card
      className={`d-flex flex-fill align-items-center justify-content-center ${className}`}
    >
      <Card.Body className="d-flex my-auto">
        <div className="my-auto">{children}</div>
      </Card.Body>
    </Card>
  );
};

LoadingScreenCover.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default LoadingScreenCover;
