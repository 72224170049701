import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LaunchpadModuleItem from './LaunchpadModuleItem';

export default function LaunchpadModulesSection({ onPageChange, modules }) {
  return Array.isArray(modules) && modules.length > 0 ? (
    <div>
      <div className="content-area-head d-flex flex-wrap">
        <p className="app-launchpad-section-title mb-3">Modules</p>
      </div>

      <Row className="app-launchpad-module-grid-cover" md={5} sm={4} xs={2}>
        {modules.map((module, index) => (
          <Col key={index} className="mb-1">
            <LaunchpadModuleItem
              moduleData={module}
              onPageChange={onPageChange}
            />
          </Col>
        ))}
      </Row>
    </div>
  ) : null;
}

LaunchpadModulesSection.propTypes = {
  onPageChange: PropTypes.func,
  modules: PropTypes.array
};
