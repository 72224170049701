import { React } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
const SortReport = ({ formData, sortOptions, setFormData }) => {
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  return (
    <div className="d-flex">
      <Form.Select
        name="sortValue"
        value={formData.sortValue}
        aria-label="Default select example"
        style={{ width: '13rem' }}
        onChange={handleFieldChange}
      >
        <option>SORT BY</option>
        {sortOptions.map((items, key) => (
          <option key={key} value={items.value}>
            {items.label}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};
SortReport.propTypes = {
  formData: PropTypes.any,
  sortOptions: PropTypes.array,
  setFormData: PropTypes.bool
};
export default SortReport;
