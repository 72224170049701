import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import SwitchController from 'components/switch/SwitchController';
import Search from 'components/search/Search';
import { useState } from 'react';
import PermissionsTableLineItem from './PermissionsTableLineItem';

export default function PermissionsTableListView({
  updatingPermissions,
  Translate,
  onSwitch,
  permissions,
  activeModule
}) {
  const [searchKey, setSearchKey] = useState(undefined);
  const permissionsArray = Array.isArray(permissions)
    ? permissions.filter(item => item.module_id === activeModule)
    : undefined;
  const allSelected =
    Array.isArray(permissionsArray) &&
    permissionsArray.length ===
      permissionsArray.filter(
        item =>
          (item?.hasPermission === true && item?.hasPermissionRef !== false) ||
          item?.hasPermissionRef === true
      ).length
      ? true
      : false;

  const searchResult = !searchKey
    ? permissionsArray
    : (Array.isArray(permissionsArray) ? permissionsArray : []).filter(item =>
        (item?.name ?? '').toLowerCase().includes(searchKey)
      );

  function handleSearch(event) {
    let searchKey = event.target.value;
    setSearchKey(searchKey?.length > 0 ? searchKey : undefined);
  }

  return (
    <>
      <Search
        className="border-0 border-bottom shadow-sm"
        placeholder="Search permission"
        onChange={handleSearch}
      />
      <Table bordered hover responsive className="simple-table-animation">
        <thead className="text-uppercase">
          <tr className="bg-dark text-light">
            <th
              className="text-center"
              style={{
                width: '60px'
              }}
            >
              #
            </th>
            <th className="fs--2 p-2 pe-0 border-end-0">
              {Translate('PERMISSION')}
            </th>
            <th
              className="fs--2 p-2 text-center"
              style={{
                width: '40px'
              }}
            >
              {activeModule && permissionsArray?.length > 0 ? (
                <SwitchController
                  disabled={updatingPermissions}
                  checked={allSelected}
                  onSwitch={event => onSwitch(event, activeModule, true)}
                />
              ) : null}
            </th>
          </tr>
        </thead>
        <tbody>
          {!activeModule ? (
            <tr>
              <th colSpan={3}>{Translate('Please select a module')}</th>
            </tr>
          ) : permissionsArray && searchResult.length > 0 ? (
            searchResult.map((permission, index) => (
              <PermissionsTableLineItem
                key={index}
                lineNumber={index + 1}
                updatingPermissions={updatingPermissions}
                permission={permission}
                onSwitch={onSwitch}
              />
            ))
          ) : (
            <tr>
              <th colSpan={3}>{Translate('No results found')}</th>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

PermissionsTableListView.propTypes = {
  updatingPermissions: PropTypes.bool,
  Translate: PropTypes.func,
  onSwitch: PropTypes.func,
  permissions: PropTypes.array,
  activeModule: PropTypes.string
};
