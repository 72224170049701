import React from 'react';
import PropTypes from 'prop-types';
import { Button, Offcanvas } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SalesmanInfo from 'module/Sales/Salesman/SalesmanPage/SalesmanInfo';
import { FaUserCog, FaTimes } from 'react-icons/fa';

export default function SalesmanOffcanvasDetails({ show, onHide, salesmanId }) {
  const Translate = useAxisproTranslate();
  return (
    <Offcanvas
      show={show}
      placement="end"
      className="w-100 w-lg-50"
      onHide={onHide}
    >
      <Offcanvas.Header className="border-bottom">
        <Offcanvas.Title className="d-flex flex-row gap-1 fw-bold">
          <FaUserCog />
          <span>{Translate('Salesman Details')}</span>
        </Offcanvas.Title>
        <Button
          className="border"
          variant="transparent"
          size="sm"
          onClick={onHide}
        >
          <FaTimes />
        </Button>
      </Offcanvas.Header>
      <SalesmanInfo salesmanId={salesmanId} rendering={true} offcanvas />
    </Offcanvas>
  );
}

SalesmanOffcanvasDetails.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  salesmanId: PropTypes.string
};
