import React from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

function Badges({ label, bg, className, icon }) {
  const Translate = useAxisproTranslate();

  return (
    <Badge bg={bg} className={className}>
      {Translate(label)}
      {icon}
    </Badge>
  );
}

Badges.propTypes = {
  label: PropTypes.any,
  bg: PropTypes.any,
  className: PropTypes.any,
  icon: PropTypes.any
};

export default Badges;
