import React, { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { AuthWizardContext } from 'context/Context';

const ReportBasicTableFooter = ({ colSpan }) => {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);

  return (
    <tr>
      <td className="small border-0 px-0 pt-4" colSpan={colSpan ?? 3}>
        {Translate('**Amount is displayed in your base currency')}
        <Badge bg="success" className="ms-1">
          {user?.branch?.base_currency}
        </Badge>
      </td>
    </tr>
  );
};

ReportBasicTableFooter.propTypes = {
  colSpan: PropTypes.number
};

export default ReportBasicTableFooter;
