import { React } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdOutlineWarning } from 'react-icons/md';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

function LineItemLimitWarning({ show, onHide, content }) {
  const Translate = useAxisproTranslate();

  return (
    <Modal
      show={show}
      size="md"
      backdrop="static"
      className="modal-with-overlay"
      centered
    >
      <Card>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center text-center px-5">
          <MdOutlineWarning size={60} className="text-warning" />
          <p className="m-0 fs--1">{Translate(content)}</p>
        </Card.Body>
        <Card.Footer className="d-flex align-itmes-center justify-content-end pt-0">
          <Button
            className="fs--1"
            size="sm"
            onClick={onHide}
            style={{ boxShadow: 'none' }}
            variant="info"
          >
            {Translate('Continue')}
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
}

LineItemLimitWarning.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  content: PropTypes.string
};

export default LineItemLimitWarning;
