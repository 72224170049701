import { DeleteContext } from 'context/Context';
import React, { useContext, useReducer } from 'react';
import deleteReducer from 'reducers/deleteReducer';
import PropTypes from 'prop-types';

const DeleteContextProvider = ({ children }) => {
  const [config, dispatch] = useReducer(
    deleteReducer,
    useContext(DeleteContext)
  );
  return (
    <DeleteContext.Provider value={{ config, dispatch }}>
      {children}
    </DeleteContext.Provider>
  );
};

DeleteContextProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default DeleteContextProvider;
