import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Table } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useAcodaxNumberRoundFormat from 'hooks/useAcodaxNumberRoundFormat';

export default function AdditionalCostPopUpDropdownLineItemSection({
  orderDetailsArray,
  formData,
  setFormData
}) {
  const Translate = useAxisproTranslate();
  const [detailsValueChange, setDetailsValueChange] = useState(false);
  const acodaxNumberRoundFormat = useAcodaxNumberRoundFormat();

  function handleEditLineItem(event, index) {
    setDetailsValueChange(true);
    const inputName = event.target.name;
    if (formData?.gl_items?.[index]) {
      setFormData(prev => {
        prev.gl_items[index][inputName] = event.target.value;
        const qty = parseFloat(prev.gl_items[index].quantity);
        const unitPrice = parseFloat(prev.gl_items[index].amount);
        prev.gl_items[index].total_ref =
          (isNaN(unitPrice) ? 0 : unitPrice) * (isNaN(qty) ? 0 : qty);
        const TotalPrice = prev?.gl_items.reduce(
          (total, gl) => total + parseFloat(gl.amount || 0),
          0
        );
        return {
          ...prev,
          unit_price: TotalPrice
        };
      });
    }
  }

  useEffect(() => {
    const singleItemCostAmount = acodaxNumberRoundFormat(
      (parseFloat(formData?.unit_price) || 0) / (orderDetailsArray?.length || 0)
    );
    const createGLItemsList = orderDetailsArray?.reduce((result, item) => {
      if (item?.id) {
        result.push({
          purchase_order_detail_id: item?.order_detail_id,
          item_name_ref: item?.item_display_name ?? '-',
          amount: singleItemCostAmount,
          account_code: formData?.account_code,
          tax_group_id: formData?.tax_group_id
        });
      }

      console.log(result);
      return result;
    }, []);
    if (!detailsValueChange) {
      if (Array.isArray(createGLItemsList)) {
        setFormData(prev => ({
          ...prev,
          gl_items: createGLItemsList
        }));
      }
    } else {
      setDetailsValueChange(false);
    }
  }, [
    orderDetailsArray,
    formData?.unit_price,
    formData?.account_code,
    formData?.tax_group_id
  ]);

  return (
    <Table
      striped
      bordered
      hover
      responsive
      className="simple-table-animation fs--2"
    >
      <thead className="bg-dark text-light text-uppercase">
        <tr>
          <th>{Translate('SL.No')}</th>
          <th
            style={{
              minWidth: 200
            }}
          >
            {Translate('Item Name')}
          </th>
          <th>{Translate('Amount')}</th>
        </tr>
      </thead>
      <tbody>
        {formData?.gl_items?.map((item, index) => (
          <tr key={index}>
            <th className="fw-bold">{index + 1}</th>
            <td>{item?.item_name_ref ?? '-'}</td>
            <td>
              <Form.Control
                type="number"
                step="0.1"
                min={0}
                name="amount"
                value={item?.amount}
                onChange={event => handleEditLineItem(event, index)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

AdditionalCostPopUpDropdownLineItemSection.propTypes = {
  orderDetailsArray: PropTypes.array,
  formData: PropTypes.func,
  setFormData: PropTypes.func
};
