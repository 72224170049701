import ShowReference from 'components/show-reference/ShowReference';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React from 'react';
import {
  FaEnvelope,
  FaInfoCircle,
  FaMobile,
  FaSignal,
  FaUser,
  FaUserCog
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import EstimationEntryStatus from 'module/Production/Estimation/components/EstimationEntryStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import QuillPreviewContainer from 'components/QuillPreviewContainer/QuillPreviewContainer';

export default function EnquiryOverview({
  enquiryDetails,
  setshowDetailsArea,
  permission
}) {
  const Translate = useAxisproTranslate();

  return (
    <div className="py-3 d-flex flex-wrap gap-3 justify-content-between">
      <div>
        {enquiryDetails?.customer_name?.length ? (
          <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
            <h6 className="mb-2 fw-bold d-flex gap-2">
              <FaUser />
              <span>{Translate('Customer Name')}:</span>
            </h6>
            <div className="d-flex flex-row align-items-center gap-1 mb-2">
              <p className="m-0">
                {enquiryDetails?.customer_code
                  ? enquiryDetails.customer_code + ' - '
                  : ''}
                {enquiryDetails?.customer_name}
              </p>
              {permission?.showCustomerDetails ? (
                <Button
                  className="p-0 m-0 text-primary outline-none"
                  variant="transparent"
                  onClick={() => setshowDetailsArea('CUSTOMER')}
                  size="sm"
                >
                  <FontAwesomeIcon
                    className="p-0 m-0"
                    icon="external-link-alt"
                    size="xs"
                    cursor={'pointer'}
                  />
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}

        {enquiryDetails?.salesman_name?.length ? (
          <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
            <h6 className="mb-2 fw-bold d-flex gap-2">
              <FaUserCog />
              <span>{Translate('Salesman Name')}:</span>
            </h6>
            <div className="d-flex flex-row align-items-center gap-1 mb-2">
              <p className="m-0">
                {enquiryDetails?.salesman_code
                  ? enquiryDetails.salesman_code + ' - '
                  : ''}
                {enquiryDetails?.salesman_name}
              </p>
              {permission?.showSalesmandDetails ? (
                <Button
                  className="p-0 m-0 text-primary outline-none"
                  variant="transparent"
                  onClick={() => setshowDetailsArea('SALESMAN')}
                  size="sm"
                >
                  <FontAwesomeIcon
                    className="p-0 m-0"
                    icon="external-link-alt"
                    size="xs"
                    cursor={'pointer'}
                  />
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}

        {enquiryDetails?.name?.length ? (
          <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
            <h6 className="mb-2 fw-bold d-flex gap-2">
              <FaUser />
              <span>{Translate('Enquiry From')}:</span>
            </h6>
            <p className="mb-2">{enquiryDetails?.name}</p>
          </div>
        ) : null}
      </div>

      {enquiryDetails?.phone?.length || enquiryDetails?.email?.length ? (
        <div>
          {enquiryDetails?.phone?.length ? (
            <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
              <h6 className="mb-2 fw-bold d-flex gap-2">
                <FaMobile />
                <span>{Translate('Phone')}:</span>
              </h6>
              <p className="mb-2">
                <a href={`tel:+${enquiryDetails?.phone}`}>
                  {enquiryDetails?.phone}
                </a>
              </p>
            </div>
          ) : null}

          {enquiryDetails?.email?.length ? (
            <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
              <h6 className="mb-2 fw-bold d-flex gap-2">
                <FaEnvelope />
                <span>{Translate('Email')}:</span>
              </h6>
              <p className="mb-2">
                <a href={`mailto:${enquiryDetails?.email}`}>
                  {enquiryDetails?.email}
                </a>
              </p>
            </div>
          ) : null}
        </div>
      ) : null}

      <div>
        {enquiryDetails?.estimation?.id ? (
          <>
            <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
              <h6 className="mb-2 fw-bold d-flex gap-2">
                <FaInfoCircle />
                <span>{Translate('Estimation Reference')}:</span>
              </h6>
              <p className="mb-2 d-flex flex-row align-items-center gap-1">
                <ShowReference
                  data={enquiryDetails.estimation}
                  to={`/production/estimation?entry=${enquiryDetails.estimation.id}&layout=column`}
                  offCanvas
                />
              </p>
            </div>

            <div className="d-flex flex-wrap align-items-center gap-2 mb-1">
              <h6 className="mb-2 fw-bold d-flex gap-2">
                <FaSignal />
                <span>{Translate('Estimation Status')}:</span>
              </h6>
              <p className="mb-2">
                <EstimationEntryStatus
                  status={enquiryDetails.estimation?.status}
                />
              </p>
            </div>
          </>
        ) : null}
      </div>

      {enquiryDetails?.message?.length > 0 ? (
        <div className="mt-2 w-100">
          <QuillPreviewContainer
            title="Description :"
            value={enquiryDetails.message}
            dashedBorder
          />
        </div>
      ) : null}
    </div>
  );
}

EnquiryOverview.propTypes = {
  enquiryDetails: PropTypes.object,
  setshowDetailsArea: PropTypes.func.isRequired,
  permission: PropTypes.object
};
