import { React, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useOutletContext,
  useSearchParams
} from 'react-router-dom';
import { ButtonGroup, Card, Container, Tab, Tabs } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { AiOutlineFileText } from 'react-icons/ai';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import Badges from '../Common/ViewPageDetails/Badges';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';
import TabViewGeneralDetailsSectionCover from 'components/tab-view-general-details-section-cover/TabViewGeneralDetailsSectionCover';
import ShowReference from 'components/show-reference/ShowReference';
import EnquiryOverview from './EnquiryOverview';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import SalesmanOffcanvasDetails from 'components/salesman-offcanvas-details/SalesmanOffcanvasDetails';

export default function EnquiryTabView({
  enquiryData,
  loading,
  infoPage,
  voidScreen,
  fetchData,
  getData,
  offCanvas,
  setShowVoucherDetailArea,
  onHide,
  voidTransactionReport
}) {
  const Translate = useAxisproTranslate();
  const navigateTo = useNavigate();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  let [permissions] = useOutletContext() ?? [];
  if (!permissions) {
    permissions = {
      update: getAcodaxPermissionSlug('ENQUIRY', 'update'),
      sendMail: getAcodaxPermissionSlug('ENQUIRY', 'send-mail'),
      createEstimation: getAcodaxPermissionSlug('ESTIMATION', 'store'),
      createSalesQuotation: getAcodaxPermissionSlug('SQ', 'create'),
      showCustomerDetails: getAcodaxPermissionSlug('CS', 'show'),
      showSalesmandDetails: getAcodaxPermissionSlug('SM', 'show'),
      voidTransaction: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
    };
  }
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [showDetailsArea, setshowDetailsArea] = useState(undefined);
  const [activeOption, setActiveOption] = useState(undefined);

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <AiOutlineFileText size={28} className="me-2 text-dark" />
              <h5
                className="mb-0 d-flex text-uppercase view-page-header"
                style={{ fontSize: '25px' }}
              >
                {Translate('Enquiry Details')}
              </h5>
            </div>
            {voidTransactionReport && (
              <Badges label={'Voided'} className="ms-2 fs--1" bg={'danger'} />
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {!voidScreen && !infoPage && !offCanvas ? (
                <>
                  <MoreButton
                    setUpload={() => setActiveOption('fileUpload')}
                    firstConversionValidation={!!permissions?.createEstimation}
                    disableFirstConversion={enquiryData?.is_fully_estimated}
                    firstConversionTo={`/production/estimation/add?enquiry_id=${enquiryData?.id}`}
                    beforeFirstConversion={Translate('Convert to Estimation')}
                    afterFirstConversion={Translate('Fully Estimated!')}
                    secondConversionValidation={
                      !!permissions?.createSalesQuotation
                    }
                    disableSecondConversion={enquiryData?.is_fully_quoted}
                    secondConversionTo={`/sales/sales-quotation/add?enquiry_id=${enquiryData?.id}`}
                    beforeSecondConversion={Translate(
                      'Convert to Sales Quotation'
                    )}
                    afterSecondConversion={Translate('Fully Quoted!')}
                    sendEmail={() => setActiveOption('sendMail')}
                    emailPermission={permissions?.sendMail}
                    voidTransactionData={
                      permissions?.voidTransaction &&
                      !enquiryData?.is_fully_estimated &&
                      !enquiryData?.is_fully_quoted
                        ? {
                            id: enquiryData?.id,
                            trans_type: 'EQ',
                            callBack: () => {
                              navigateTo(
                                `/sales/enquiry?layout=column&${createSearchParams(
                                  {
                                    ...allQueryParams
                                  }
                                )}`
                              );
                              typeof getData === 'function' && getData(1);
                            }
                          }
                        : undefined
                    }
                  />

                  {permissions?.update &&
                  !enquiryData?.is_fully_estimated &&
                  !enquiryData?.is_fully_quoted ? (
                    <EditButton to={`/sales/enquiry/edit/${enquiryData?.id}`} />
                  ) : null}
                </>
              ) : null}

              {offCanvas || onHide ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                  onHide={onHide}
                />
              ) : null}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <TabViewGeneralDetailsSectionCover mainTitle="General Details">
            <TabViewGeneralDetailsSectionCover.Section position="start">
              <TabViewGeneralDetailsSectionCover.Item
                title="Enquiry Date"
                value={enquiryData?.formatted_date}
              />
            </TabViewGeneralDetailsSectionCover.Section>

            <TabViewGeneralDetailsSectionCover.Section position="end">
              <TabViewGeneralDetailsSectionCover.Item
                title="Reference"
                contentSectionClassName="mb-2"
              >
                <ShowReference
                  data={enquiryData}
                  to={`/sales/enquiry?entry=${enquiryData?.id}&layout=column`}
                  offCanvas={offCanvas}
                  readOnly={voidTransactionReport}
                />
              </TabViewGeneralDetailsSectionCover.Item>
            </TabViewGeneralDetailsSectionCover.Section>

            <TabViewGeneralDetailsSectionCover.CustomColumnResultSection
              customColumnItems={enquiryData?.custom_field_values}
            />
          </TabViewGeneralDetailsSectionCover>

          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={enquiryData.trans_type}
                      transId={enquiryData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}

              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <EnquiryOverview
                    enquiryDetails={enquiryData}
                    setshowDetailsArea={() => console.log('')}
                    permission={{}}
                  />
                </SimpleBar>
              </Tab>

              {enquiryData?.files?.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={{
                        ...enquiryData,
                        media: enquiryData?.files
                      }}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <FooterDetails
          created_at={enquiryData?.created_at}
          created_by={enquiryData?.created_user_full_name}
        />
      </Card>

      {showDetailsArea === 'CUSTOMER' ? (
        <CustomerDetails
          show={true}
          onHide={() => setshowDetailsArea(undefined)}
          value={{
            value: enquiryData?.customer_id
          }}
          showCustomerDetailsArea={true}
        />
      ) : showDetailsArea === 'SALESMAN' ? (
        <SalesmanOffcanvasDetails
          show
          onHide={() => setshowDetailsArea(undefined)}
          salesmanId={enquiryData?.salesman_id}
        />
      ) : null}

      {activeOption === 'fileUpload' ? (
        <FileUpload
          show
          onHide={() => setActiveOption(undefined)}
          fetchData={fetchData}
          type={'EQ'}
          id={enquiryData.id}
          setKey={setKey}
        />
      ) : activeOption === 'sendMail' ? (
        <SendEmail
          url={'crm/enquiries-mail/' + enquiryData?.id}
          showEmailModal={() => setActiveOption('sendMail')}
          handleCancel={() => setActiveOption(undefined)}
          data={enquiryData?.email}
        />
      ) : null}
    </>
  );
}

EnquiryTabView.propTypes = {
  enquiryData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  getData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  onHide: PropTypes.bool,
  voidTransactionReport: PropTypes.bool,
  infoPage: PropTypes.bool
};
