import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaHourglassStart
} from 'react-icons/fa';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

export default function WorkOrderStatusBadge({
  quantity,
  quantityIssued,
  isFullyIssued
}) {
  const Translate = useAxisproTranslate();
  const _quantity = parseFloat(quantity) || 0;
  const _quantityIssued = parseFloat(quantityIssued) || 0;

  const status =
    isFullyIssued || _quantityIssued >= _quantity
      ? 'done'
      : _quantityIssued > 0
      ? 'started'
      : 'pending';

  return (
    <Badge
      bg={
        status === 'done'
          ? 'success'
          : status === 'started'
          ? 'warning'
          : 'danger'
      }
      className="rounded"
    >
      <div className="d-flex flex-row align-items-center gap-1">
        {status === 'done' ? (
          <>
            <FaCheckCircle />
            {Translate('Fully Issued')}
          </>
        ) : status === 'started' ? (
          <>
            <FaHourglassStart />
            {Translate('Partially Issued')}
          </>
        ) : (
          <>
            <FaExclamationCircle />
            {Translate('Pending')}
          </>
        )}
      </div>
    </Badge>
  );
}

WorkOrderStatusBadge.propTypes = {
  quantity: PropTypes.string,
  quantityIssued: PropTypes.string,
  isFullyIssued: PropTypes.bool
};
