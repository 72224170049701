import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useNavigate, useSearchParams } from 'react-router-dom';

const RowsPerPage = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const rows_per_page = queryParams.get('rows_per_page');
  const Translate = useAxisproTranslate();
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(
    rows_per_page ?? 10
  );

  const handleChange = value => {
    setSelectedRowsPerPage(value);
    const Params = {
      ...allQueryParams,
      rows_per_page: value + '' ?? 10
    };
    const queryString = Object.keys(Params)
      .map(
        key =>
          `${key}=${encodeURIComponent(
            typeof queryParams[key] === 'object'
              ? JSON.stringify(queryParams[key])
              : Params[key]
          )}`
      )
      .join('&');
    navigate({ pathname: '', search: queryString ? queryString : '' });
  };

  return (
    <div className="d-flex align-items-center">
      <div style={{ width: '100px' }}>{Translate('Rows Per Page')}</div>
      <div>
        <Dropdown>
          <Dropdown.Toggle
            className="border"
            size="sm"
            variant="transparent "
            id="dropdown-basic"
            style={{ boxShadow: 'none' }}
          >
            {selectedRowsPerPage}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              active={selectedRowsPerPage === 10}
              onClick={() => handleChange(10)}
            >
              10
            </Dropdown.Item>
            <Dropdown.Item
              active={selectedRowsPerPage === 20}
              onClick={() => handleChange(20)}
            >
              20
            </Dropdown.Item>
            <Dropdown.Item
              active={selectedRowsPerPage === 30}
              onClick={() => handleChange(30)}
            >
              30
            </Dropdown.Item>
            <Dropdown.Item
              active={selectedRowsPerPage === 40}
              onClick={() => handleChange(40)}
            >
              40
            </Dropdown.Item>
            <Dropdown.Item
              active={selectedRowsPerPage === 50}
              onClick={() => handleChange(50)}
            >
              50
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default RowsPerPage;
