import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import {
  ActiveTaskContext,
  AuthWizardContext,
  DeleteContext
} from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import { FaCheckCircle, FaClock, FaStopCircle } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import TaskTimer from 'components/task-timer/TaskTimer';

export default function TaskTimerHandler({
  task,
  itemTargetId,
  setWorkOrderVoucherData,
  setShowLoading,
  isCompleted
}) {
  const Translate = useAxisproTranslate();
  const { user, dispatch: userDispatch } = useContext(AuthWizardContext);
  const { activeTask, setActiveTask } = useContext(ActiveTaskContext);
  const { dispatch: confirmDispatch } = useContext(DeleteContext);
  const isMyTask = task?.assigned_to && user?.id === task.assigned_to;
  const isMyTaskAndTaskIsRunning = isMyTask && activeTask?.id === task?.id;
  const taskIsInProgress = task?.status === 'in_progress';

  const handleTaskTimer = (event, method) => {
    event.preventDefault();
    if (!['START', 'STOP'].includes(method)) {
      throw new Error('Invalid method, please provide a valid method');
    }

    if (!isMyTask) return;

    let errorMessage;

    if (!task?.assigned_to) {
      errorMessage = 'The task has not been assigned to anyone yet.';
    }

    if (!taskIsInProgress) {
      errorMessage =
        'To start the timer, the status of the task must be in progress.';
    }

    if (errorMessage) {
      showToast(Translate(errorMessage), 'error');
      return;
    }
    const config = {
      title: Translate('Start Task Timer'),
      message: Translate(
        'Are you sure you want to start the timer for this task?'
      ),
      buttonText: 'Start',
      submitButtonVariant: 'success',
      cancelButtonVariant: 'danger',
      reuestMethod: 'post',
      url: `/crm/start-timer/${task?.id}`,
      onDelete: () => {
        typeof setShowLoading === 'function' && setShowLoading(true);
        activeTask && setActiveTask(prev => ({ ...prev, showLoadingUI: true }));
      },
      onSuccess: taskData => {
        if (taskData?.task) {
          if (itemTargetId) {
            setWorkOrderVoucherData(prev => ({
              ...prev,
              details: prev?.details?.map(item =>
                item?.id === itemTargetId
                  ? { ...item, task: taskData.task }
                  : item
              )
            }));
          }

          if (method === 'START') {
            userDispatch({
              type: 'UPDATE',
              payload: {
                ...user,
                running_task_id: taskData?.task?.id,
                running_tasks_count:
                  (parseInt(user?.running_tasks_count) || 0) + 1
              }
            });

            setActiveTask({
              ...taskData?.task,
              itemTargetId: itemTargetId,
              setWorkOrderVoucherData: setWorkOrderVoucherData,
              setShowLoading: setShowLoading,
              showQuickActionCenter: true
            });
          } else {
            userDispatch({
              type: 'UPDATE',
              payload: {
                ...user,
                running_task_id: null,
                running_tasks_count:
                  (parseInt(user?.running_tasks_count) || 1) - 1
              }
            });

            setActiveTask(undefined);
          }
        }
        setShowLoading(false);
      },
      onError: setShowLoading(false)
    };

    if (method === 'STOP') {
      config.title = Translate('Stop Task Timer');
      config.message = Translate(
        'Are you sure you want to stop the timer for this task?'
      );
      config.buttonText = 'Stop';
      config.submitButtonVariant = 'danger';
      config.cancelButtonVariant = 'warning';
      config.url = `/crm/stop-timer/${task?.id}`;
    }

    confirmDispatch({
      type: 'CONFIG',
      payload: config
    });
  };

  useEffect(() => {
    if (
      user?.running_task_id &&
      task?.id === user.running_task_id &&
      !activeTask?.assignee
    ) {
      const currentTimeData =
        task?.current_time_tracker?.[0]?.total_worked_so_far_detailed;
      setActiveTask({
        ...task,
        ...(!task?.timeData
          ? {
              timeData: {
                hour:
                  parseInt(
                    currentTimeData?.total_hours_including_days_as_hours
                  ) || 0,
                minute: parseInt(currentTimeData?.minutes) || 0,
                second: parseInt(currentTimeData?.seconds) || 0
              }
            }
          : null)
      });
    }
  }, [task, activeTask, user]);

  return (
    <div className="d-flex flex-row">
      {isMyTaskAndTaskIsRunning ? (
        <TaskTimer
          start
          itemTargetId={itemTargetId}
          setWorkOrderVoucherData={setWorkOrderVoucherData}
        />
      ) : null}
      <Button
        variant={
          isMyTaskAndTaskIsRunning
            ? 'danger'
            : isCompleted
            ? 'success'
            : 'primary'
        }
        size="sm"
        className={classNames(
          'text-uppercase d-flex gap-1 align-items-center justify-content-center px-2 py-1 flex-fill rounded-end',
          {
            rounded: !isMyTaskAndTaskIsRunning
          }
        )}
        title={isMyTaskAndTaskIsRunning ? Translate('Stop Timer') : ''}
        type="button"
        onClick={event =>
          handleTaskTimer(event, isMyTaskAndTaskIsRunning ? 'STOP' : 'START')
        }
        disabled={
          !isMyTask ||
          (activeTask?.id && !isMyTaskAndTaskIsRunning) ||
          (!isMyTaskAndTaskIsRunning && isCompleted)
        }
      >
        {isMyTaskAndTaskIsRunning ? (
          <FaStopCircle />
        ) : isCompleted ? (
          <>
            <FaCheckCircle size={13} />
            <small>{Translate('Completed')}</small>
          </>
        ) : (
          <>
            <FaClock size={13} />
            <small>
              {Translate(`${task?.running_status ? 'Running' : 'Start Timer'}`)}
            </small>
          </>
        )}
      </Button>
    </div>
  );
}

TaskTimerHandler.propTypes = {
  task: PropTypes.object,
  itemTargetId: PropTypes.string,
  setWorkOrderVoucherData: PropTypes.func,
  setShowLoading: PropTypes.func,
  isCompleted: PropTypes.bool
};
