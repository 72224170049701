import React from 'react';

export default function LaunchPadFooter() {
  return (
    <div className="app-launchpad-footer app-launchpad-section-padding d-flex flex-wrap">
      <div className="d-flex flex-row align-items-center app-launchpad-control-item ms-auto">
        <div>
          <span className="app-launchpad-control-key">Esc</span>
        </div>
        <div>
          <span className="ms-1">to close</span>
        </div>
      </div>
    </div>
  );
}
