import removeRefData from 'helpers/removeRefData';
import SetLineItemsWithItemsIncludedInTheKit from './SetLineItemsWithItemsIncludedInTheKit';

export default function handleSetPurchaseFormDetailsData({
  detailsArray = [],
  glItemsArray = []
}) {
  return new Promise((resolve, reject) => {
    let result = [...(Array.isArray(detailsArray) ? detailsArray : [])].reduce(
      (newResult, item) => {
        if (typeof item?.errors === 'object') {
          newResult.invalidItems.push(item);
        } else {
          newResult.validItems.push(item);
        }
        return newResult;
      },
      {
        validItems: [],
        invalidItems: []
      }
    );

    result.allItems = detailsArray;

    const invalidItemsLength = result?.invalidItems?.length;
    const glItemsLength = Array.isArray(glItemsArray) ? glItemsArray.length : 0;

    if (invalidItemsLength > 0) {
      reject({
        message: `${invalidItemsLength} item${
          invalidItemsLength > 1 ? 's' : ''
        } found with errors, please fix or remove ${
          invalidItemsLength > 1 ? 'them' : 'it'
        }`
      });
    } else if (result?.validItems?.length < 1 && glItemsLength < 1) {
      reject({
        message: 'Entries are required!'
      });
    } else {
      result.validItems = removeRefData(
        SetLineItemsWithItemsIncludedInTheKit(result?.validItems),
        'array',
        {
          removeValue: [null, 'null']
        }
      );

      if (Array.isArray(glItemsArray)) {
        result.glItems = removeRefData(glItemsArray, 'array', {
          removeValue: [null, 'null']
        });
      }
      resolve(result);
    }
  });
}
