const headers = {
  sq: {
    columnsTitleArray: [
      '#',
      'Item Code',
      'Item Name',
      'Quantity',
      'Unit',
      'Price',
      'Tax',
      'Discount',
      'Total'
    ],
    columnsDataKeyArray: [
      '#',
      'stock_id',
      item => {
        return `<p class="ql-direction-dmContentTable"><strong>${
          item?.item_display_name
        }</strong></p>
                <br/>
                <p class="ql-direction-dmContentTable"><span class="ql-size-small">${
                  item?.item_description || '-'
                }</span></p>`;
      },
      'quantity',
      'unit_name',
      'unit_price_formatted',
      'unit_tax_formatted',
      'discount_amount_formatted',
      'line_total_formatted'
    ]
  }
};
export default function generateProposalTableHelper({
  type,
  data,
  resultType
}) {
  const columnsTitleArray = headers?.[type]?.columnsTitleArray;
  const columnsDataKeyArray = headers?.[type]?.columnsDataKeyArray;
  if (!Array.isArray(columnsTitleArray) || !Array.isArray(columnsDataKeyArray))
    return;
  const Table = document.createElement('table');
  Table.classList.add('ql-table-better');

  // set table title row
  const TR = Table.insertRow();
  const columnsTitleArraylastIndex = columnsTitleArray?.length - 1;
  columnsTitleArray?.forEach((title, index) => {
    const cell = TR.insertCell();
    cell.innerHTML = `<p class="ql-direction-dmContentTable"><strong style="color: rgb(255, 255, 255);">${title}</strong></p>`;
    cell.setAttribute(
      'style',
      `background-color: rgb(0, 0, 0); ${
        index !== columnsTitleArraylastIndex
          ? 'border-right-color: rgb(255, 255, 255)'
          : ''
      }; height: 50px;`
    );
  });

  // set table result row
  data?.details?.forEach((itemData, index) => {
    const TR = Table.insertRow();
    for (const key of columnsDataKeyArray) {
      const cell = TR.insertCell();
      cell.classList.add('ql-direction-dmContentTable');
      cell.style.borderColor = '#edf2f9';
      if (key === '#') {
        cell.innerText = index + 1;
      } else if (typeof key === 'function') {
        cell.innerHTML = key(itemData);
      } else {
        cell.innerHTML = `<p class="ql-direction-dmContentTable">${itemData?.[key]}</p>`;
      }
    }
  });

  // include total summery section

  [
    {
      title: 'Sub Total',
      key: 'amount_formatted'
    },
    {
      title: 'Total Tax',
      key: 'total_tax_formatted'
    },
    {
      title: 'Total Discount',
      key: 'total_discount_amount_formatted'
    },
    {
      title: 'Grand Total',
      key: 'sub_total_formatted'
    }
  ].map(item => {
    const TotalSummeryTR = Table.insertRow();
    const cell = TotalSummeryTR.insertCell();
    cell.colSpan = 7;
    cell.style.borderStyle = 'none';
    cell.style.paddingTop = '10px';
    cell.innerHTML = `<p class="ql-direction-dmContentTable ql-align-right"><span style="color: rgb(136, 136, 136);">${item?.title}:</span></p>`;

    const ValueCell = TotalSummeryTR.insertCell();
    ValueCell.colSpan = 2;
    ValueCell.style.borderStyle = 'none';
    ValueCell.style.paddingTop = '10px';
    ValueCell.innerHTML = `<p class="ql-direction-dmContentTable ql-align-right"><strong>${
      data?.[item?.key]
    }</strong></p>`;
  });

  if (resultType === 'HTML') {
    return Table;
  }

  // get table html as string
  const div = document.createElement('div');
  div.appendChild(Table);

  return div?.innerHTML;
}
