import { React, useEffect, useRef, useState } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';

function ReconcileLoading({ loadNextPage, links }) {
  const [nextPage, setNextPage] = useState();

  const componentRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadNextPage(nextPage);
      }
    }, options);
    observer.observe(componentRef.current);
    return () => {
      observer.disconnect();
    };
  }, [nextPage]);

  useEffect(() => {
    if (links !== '' && links.links) {
      setNextPage(links.links.next);
    }
  }, [links]);

  return (
    <>
      <tr ref={componentRef}>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
      </tr>
      <tr>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
      </tr>
      <tr>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
        <td className="p-3">
          <Placeholder as="p" animation="glow" className="m-0">
            <Placeholder
              className="w-100"
              size="md"
              bg="secondary"
              style={{ opacity: '0.01' }}
            />
          </Placeholder>
        </td>
      </tr>
    </>
  );
}

ReconcileLoading.propTypes = {
  links: PropTypes.any,
  loadNextPage: PropTypes.func
};

export default ReconcileLoading;
