import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';
import { Link, createSearchParams } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';

export default function RolePermissionRoleLineItem({ item, active }) {
  return (
    <ListGroup.Item
      as={Link}
      to={`/system-admin/roles-permissions?${createSearchParams({
        role_id: item.id,
        role_name: item?.name
      })}`}
      className="d-flex active-list-item text-decoration-none"
      style={{ backgroundColor: active ? '#edf2f9' : '#fff' }}
    >
      {item?.name && (
        <div className="d-flex flex-row align-items-center py-1">
          <FaUser />
          <div className="ms-2">
            <h6 className="mb-0 fw-bold">{item?.name}</h6>
          </div>
        </div>
      )}
    </ListGroup.Item>
  );
}

RolePermissionRoleLineItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool
};
