import axios from 'axios';
import setNewFormData from 'helpers/setNewFormData';
import { showToast } from 'module/Common/Toast/toast';
import moment from 'moment';

export default async function setEstimationToSalesQuotationData({
  estimationId,
  setLoading,
  setFormData,
  user
}) {
  let index = 0;

  try {
    //get estimation details
    const estimationApiResult = await axios.get(
      'crm/estimation/' + estimationId
    );

    if (estimationApiResult?.data?.data?.id) {
      const estimationData = estimationApiResult.data.data;

      // set add to cart bulk data result
      const trans_date = moment().format('YYYY-MM-DD');
      const sales_type_id = estimationData?.customer?.sales_type_id
        ? estimationData.customer.sales_type_id
        : user?.branch?.sales_settings?.sales_type_id
        ? user.branch.sales_settings.sales_type_id
        : '';
      const estimateDetailsItems = Array.isArray(estimationData?.details)
        ? estimationData.details.reduce((newResult, item) => {
            index += 1;
            // const itemUnitPrice = parseFloat(item?.amount);
            // const itemQuantity = parseFloat(item?.quantity);
            const unitPrice = parseFloat(item?.amount); // bipin bro told to remove division
            // (itemUnitPrice > 0 ?  : 0) /
            // (itemQuantity > 0 ? itemQuantity : 0);
            newResult.push({
              id_ref: index,
              remove: false,
              trans_type: 'SQ',
              item_display_name: item?.title ?? '',
              quantity: item?.quantity ?? '',
              unit_name: item?.unit_name ?? '',
              unit_price: unitPrice,
              initial_unit_price: unitPrice,
              unit_margin: 0,
              stock_id: item?.stock_id ?? '',
              stock_id_ref: item?.stock_id ?? '',
              item_description:
                item?.description?.length > 0
                  ? item.description
                  : item?.item?.item_description?.length > 0
                  ? item.item.item_description
                  : '-',
              sales_type_id: sales_type_id,
              trans_date: trans_date,
              is_kit: item?.is_kit,
              estimation_detail_id: item?.id,
              margin_type: 'PERCENT'
            });
            return newResult;
          }, [])
        : [];

      if (estimateDetailsItems?.length > 0) {
        const addToCartApiResult = await axios.post(
          '/sales/add-to-cart-bulk',
          setNewFormData({
            items: estimateDetailsItems
          })
        );

        estimationData.cartResult = Array.isArray(
          addToCartApiResult?.data?.data?.items
        )
          ? addToCartApiResult.data.data.items
          : [];
      }

      setFormData(prev => ({
        ...prev,
        estimation_id: estimationId,
        customer_reference: estimationData?.enquiry_reference ?? '',
        enquiry_reference_ref: estimationData?.enquiry_reference ?? '',
        enquiry_id_ref: estimationData?.enquiry_id,
        estimate_reference_ref: estimationData?.reference,
        trans_date: trans_date,
        customer_id: estimationData?.customer_id ?? '',
        customer_id_ref: estimationData?.customer_id
          ? {
              value: estimationData.customer_id,
              label: estimationData?.customer_name
            }
          : null,
        salesman_id: estimationData?.salesman_id ?? '',
        salesman_id_ref: estimationData?.salesman_id
          ? {
              value: estimationData.salesman_id,
              label: estimationData?.salesman_name
            }
          : null,
        details: estimationData?.cartResult ?? []
      }));
    }
  } catch (error) {
    showToast(
      error?.response?.data?.message ??
        'Something went wrong, please refresh the page and try again.',
      'error'
    );
  } finally {
    if (typeof setLoading === 'function') setLoading(false);
  }
}
