import LoadingItem from 'components/loading-screen/LoadingItem';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import PropTypes from 'prop-types';

export default function PermissionLineItemLoading({ rows }) {
  let totalRowsArray = [0];
  if (rows > 0) {
    totalRowsArray = [];
    for (let index = 0; index < rows; index++) {
      totalRowsArray.push(index);
    }
  }

  return totalRowsArray.map(rowIndex => (
    <ListGroup.Item
      key={rowIndex}
      className="d-flex active-list-item text-decoration-none"
    >
      <div className="py-1 d-flex flex-row align-items-center w-100">
        <FaUser />
        <div className="ms-2 w-100">
          <LoadingItem />
        </div>
      </div>
    </ListGroup.Item>
  ));
}

PermissionLineItemLoading.propTypes = {
  row: PropTypes.number
};
