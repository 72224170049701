import { React, useState } from 'react';
import PropTypes from 'prop-types';

import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import ItemDetails from './ItemDetails';

const ItemName = ({ name, id }) => {
  const [value, setValue] = useState({ value: 0 });
  const [showItemDetailsArea, setShowItemDetailsArea] = useState(false);
  const permissions = {
    item_show: getAcodaxPermissionSlug('IT', 'show')
  };
  const handleItemDetailsArea = () => {
    setShowItemDetailsArea(!showItemDetailsArea);
  };

  const clickOnItem = id => {
    setValue({ value: id });
    handleItemDetailsArea();
  };
  return (
    <>
      <div>
        {permissions.item_show ? (
          <a
            className="report-column-content-custom-style"
            href="#"
            onClick={() => clickOnItem(id)}
          >
            {name}
          </a>
        ) : (
          <span>{name}</span>
        )}
      </div>
      <ItemDetails
        show={showItemDetailsArea}
        onHide={handleItemDetailsArea}
        value={value}
        showItemDetailsArea={showItemDetailsArea}
      />
    </>
  );
};
ItemName.propTypes = {
  name: PropTypes.any,
  id: PropTypes.any
};

export default ItemName;
