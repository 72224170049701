import React from 'react';
import PropTypes from 'prop-types';

function ClickPerson({
  name,
  clickOnFunction,
  className,
  person_type,
  permission
}) {
  return (
    <>
      {person_type && person_type !== 'MISCELLANEOUS' ? (
        permission ? (
          <a href="#" className={className} onClick={clickOnFunction}>
            {name}
          </a>
        ) : (
          <a className={`${className} text-dark text-decoration-none`}>
            {name}
          </a>
        )
      ) : (
        <a className={`${className} text-dark text-decoration-none`}>{name}</a>
      )}
    </>
  );
}

ClickPerson.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  clickOnFunction: PropTypes.func,
  className: PropTypes.string,
  person_type: PropTypes.string,
  permission: PropTypes.bool
};

export default ClickPerson;
