import { React, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import PurchaseDeliveryTabView from 'module/Common/ViewPages/PurchaseDelivery/PurchaseDeliveryTabView';
import { useLocation } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

function PurchaseDeliveryInfo({
  purchaseDeliveryId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData,
  interBranch
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const Translate = useAxisproTranslate();
  const apiCallDelayTime = location?.state?.apiCallDelay ?? 0;

  const fetchData = async () => {
    let url = voidTransactionReport
      ? 'settings/void-transaction-details'
      : `purchase/purchase-delivery/${purchaseDeliveryId}`;
    await axios
      .get(url, {
        params: {
          trans_type: voidTransactionReport ? 'PD' : '',
          trans_no: voidTransactionReport ? purchaseDeliveryId : ''
        }
      })
      .then(res => {
        if (res.data.success) {
          setData(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    const apiCallTime = setTimeout(() => {
      fetchData();
    }, apiCallDelayTime);
    return () => clearTimeout(apiCallTime);
  }, [purchaseDeliveryId, apiCallDelayTime]);

  return (
    <>
      {loading ? (
        <LoadingScreenCover>
          <LoadingScreen
            message={`Looking for ${
              interBranch ? 'inter branch PD' : 'purchase delivery'
            }  info`}
          />
        </LoadingScreenCover>
      ) : data?.id ? (
        <PurchaseDeliveryTabView
          purchaseDeliveryData={data}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
          interBranch={interBranch}
        />
      ) : (
        <Card className="flex-fill view-page-content-wrapper d-flex align-items-center justify-content-center h-100 ">
          <div>{Translate('No Records')}</div>
        </Card>
      )}
    </>
  );
}

PurchaseDeliveryInfo.propTypes = {
  purchaseDeliveryId: PropTypes.string,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func,
  interBranch: PropTypes.bool
};

export default PurchaseDeliveryInfo;
