import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export default function WorkOrderDetailItemSelect({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  optionsArray
}) {
  optionsArray = Array.isArray(optionsArray)
    ? optionsArray.reduce((newResult, item) => {
        newResult.push({
          label: item?.item?.stock_id + ' - ' + item?.item?.item_name,
          value: item?.id,
          item: item
        });
        return newResult;
      }, [])
    : [];
  return (
    <Select
      isClearable
      placeholder={placeholder ?? ''}
      options={optionsArray}
      value={value}
      onChange={handleFieldChange}
      name={name ? name : 'credit_status'}
      className={error && 'form-control ps-0 py-0 is-invalid auto-select'}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({
          ...provided,
          height: 32,
          minHeight: 32,
          ...style
        })
      }}
    />
  );
}

WorkOrderDetailItemSelect.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  optionsArray: PropTypes.array
};
