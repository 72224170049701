import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { ConfirmationBox } from './ConfirmationBox';
import { useState, React, useEffect } from 'react';
import PropTypes from 'prop-types';

const ShortCutKeyController = ({ children }) => {
  const navigate = useNavigate();
  const [selectButton, setSelectButton] = useState(true);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [show, setShow] = useState(false);
  const [key, setKey] = useState('');

  const handleSubmit = () => {
    if (key === 'F1') {
      onHide();
      navigate(
        `?${createSearchParams({ ...allQueryParams, add_new_customer: true })}`
      );
    }
    if (key === 'F6') {
      onHide();
      navigate('/inventory/items/add');
    }
    if (key === 'F3') {
      onHide();
      navigate('/sales/customer-receipt');
    }
    if (key === 'F2') {
      onHide();
      navigate(
        `?${createSearchParams({ ...allQueryParams, add_new_supplier: true })}`
      );
    }
    setKey('');
  };

  const onHide = () => {
    setShow(false);
    setSelectButton(true);
    setKey('');
  };

  const handleKeyDown = event => {
    if (
      event.ctrlKey &&
      (event.key === 'F1' ||
        event.key === 'F2' ||
        event.key === 'F3' ||
        event.key === 'F6')
    ) {
      setShow(true);
      setKey(event.key);
    }

    if (event.key === 'Enter' && key) {
      if (selectButton) {
        handleSubmit();
      } else {
        onHide();
      }
    }

    if (event.key === 'ArrowLeft') {
      setSelectButton(false);
    }

    if (event.key === 'ArrowRight') {
      setSelectButton(true);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate, key, selectButton]);

  return (
    <>
      {children}
      <ConfirmationBox
        show={show}
        handleSubmit={handleSubmit}
        onHide={onHide}
        selectButton={selectButton}
      />
    </>
  );
};

ShortCutKeyController.propTypes = {
  children: PropTypes.any
};

export default ShortCutKeyController;
