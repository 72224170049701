const useAxisproPermission = () => {
  let assignedPermissions = JSON.parse(
    localStorage.getItem('ACODAX_PERMISSION')
  );
  const axisProPermission = slug => {
    if (assignedPermissions && assignedPermissions?.includes(slug)) return true;
    else return false;
  };

  return axisProPermission;
};

export default useAxisproPermission;
