import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Container, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { StoreContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';
// import Snippet from 'module/Common/Snippet/Snippet';
import MinimalReportDataFetching from 'module/Common/Loading/MinimalReportDataFetching';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';

function PersonBasedTransactions({
  person_id,
  person_name,
  url,
  tabApiCallCompleted,
  setTabApiCallCompleted,
  activeTabKey
}) {
  const Translate = useAxisproTranslate();
  const { store } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const branch_id = store?.globalConfiguration?.branch_id;
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const [trans_type, setTransType] = useState('');
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);
  const [voucherId, setVoucherId] = useState();
  const [data, setData] = useState([]);
  const [nextLink, setNextLink] = useState(null);
  const [total] = useState({
    totalSum: '',
    totalCreditAmount: '',
    totalDebitAmount: ''
  });

  const handleVoucherDetailsArea = () => {
    setShowVoucherDetailArea(!showVoucherDetailArea);
  };

  const clickOnVoucher = (voucher_id, trans_type) => {
    setVoucherId(voucher_id);
    setTransType(trans_type);
    setShowVoucherDetailArea(true);
  };

  const getData = () => {
    if (person_id) {
      setIsLoading(true);
      axios
        .get(url, {
          params: {
            branch_id: branch_id,
            [person_name]: person_id
          }
        })
        .then(response => {
          if (response.data.success) {
            setNextLink(response.data.data.links.next);
            let currentSum = 0;
            const dataArray = response.data.data.data;
            const newArray = dataArray.map(item => {
              currentSum += item.debit_amount;
              currentSum -= item.credit_amount;
              total.totalSum = currentSum;
              return { ...item, balanceAmount: currentSum };
            });
            setData(newArray);
            let totalCreditAmount = newArray.map(item =>
              parseFloat(item.credit_amount)
            );
            let totalDebitAmount = newArray.map(item =>
              parseFloat(item.debit_amount)
            );
            if (totalCreditAmount.length > 0) {
              let totalCAmount = totalCreditAmount.reduce((a, b) => a + b);
              total.totalCreditAmount = totalCAmount;
            }
            if (totalDebitAmount.length > 0) {
              let totalDAmount = totalDebitAmount.reduce((a, b) => a + b);
              total.totalDebitAmount = totalDAmount;
            }
          }
        })
        .catch(error => {
          if (error) {
            showToast('Something went wrong! Please contact admin', 'error');
          }
        })
        .finally(() => {
          setIsLoading(false);
          setTabApiCallCompleted(prev => ({
            ...prev,
            transaction: true
          }));
        });
    }
  };

  const loadNextPage = async () => {
    if (nextLink && nextLink !== '') {
      await axios
        .get(nextLink)
        .then(res => {
          let currentSum = 0;
          const dataArray = res.data.data.data;
          const newArray = dataArray.map(item => {
            currentSum += item.debit_amount;
            currentSum -= item.credit_amount;
            total.totalSum = currentSum;
            return { ...item, balanceAmount: currentSum };
          });
          setData([...data, ...newArray]);
          let totalCreditAmount = newArray.map(item =>
            parseFloat(item.credit_amount)
          );
          let totalDebitAmount = newArray.map(item =>
            parseFloat(item.debit_amount)
          );
          if (totalCreditAmount.length > 0) {
            let totalCAmount = totalCreditAmount.reduce((a, b) => a + b);
            total.totalCreditAmount = totalCAmount;
          }
          if (totalDebitAmount.length > 0) {
            let totalDAmount = totalDebitAmount.reduce((a, b) => a + b);
            total.totalDebitAmount = totalDAmount;
          }
          if (
            res?.data?.data?.links?.next !== null &&
            typeof res.data.data.links.next !== 'undefined'
          ) {
            setNextLink(res.data.data.links.next);
          } else {
            setNextLink(null);
          }
        })
        .catch(error => {
          if (error) {
            showToast('Something went wrong please contact admin', 'error');
          }
        });
    }
  };

  useEffect(() => {
    if (person_id) {
      if (activeTabKey !== 'transaction' || tabApiCallCompleted) return;
      getData();
    }
  }, [activeTabKey, tabApiCallCompleted]);

  return (
    <Container fluid className="p-0 reports-container-wrapper">
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <LoadingScreen message="Looking for transaction" />
        </div>
      ) : data && data.length > 0 ? (
        <Table striped bordered hover responsive className="mt-2">
          <thead className="bg-dark text-light text-uppercase">
            <tr>
              <th className="ledger-report-table-heading">#</th>
              <th className="ledger-report-table-heading">
                {Translate('DATE')}
              </th>
              <th className="ledger-report-table-heading">
                {Translate('REFERENCE')}
              </th>
              <th className="ledger-report-table-heading">
                {Translate('TRANSACTION TYPE')}
              </th>
              <th className="ledger-report-table-heading">
                {Translate('DEBIT')}
              </th>
              <th className="ledger-report-table-heading">
                {Translate('CREDIT')}
              </th>
              <th className="ledger-report-table-heading">
                {Translate('BALANCE')}
              </th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, key) => (
              <tr className="report-column-custom-style" key={key}>
                <td className="p-2 text-center">{key + 1}</td>
                <td>{item.trans_date}</td>
                <td className="p-2">
                  {getAcodaxPermissionSlug(item?.trans_type, 'show') ? (
                    <a
                      href="#"
                      onClick={() =>
                        clickOnVoucher(item.reference_id, item?.trans_type)
                      }
                    >
                      {item.reference}
                    </a>
                  ) : (
                    <a>{item.reference}</a>
                  )}
                </td>
                <td className="p-2">
                  {' '}
                  <span className="report-column-content-custom-style">
                    {item.trans_type_name}
                  </span>
                </td>
                <td className="p-2">
                  {' '}
                  <span className="report-column-content-custom-style">
                    {acodaxNumberFormat(item.debit_amount ?? 0)}
                  </span>
                </td>
                <td className="p-2">
                  {' '}
                  <span className="report-column-content-custom-style">
                    {acodaxNumberFormat(item.credit_amount ?? 0)}
                  </span>
                </td>
                <td className="p-2">
                  {' '}
                  <span className="report-column-content-custom-style">
                    {acodaxNumberFormat(item.balanceAmount ?? 0)}
                  </span>
                </td>
                {/* <td className="p-2">
                  <Snippet
                    maxLength={15}
                    description={item?.memo}
                    more={true}
                    className="report-column-content-custom-style"
                  />
                </td> */}
              </tr>
            ))}
          </tbody>
          {nextLink && nextLink !== null && (
            <MinimalReportDataFetching
              row={3}
              column={8}
              nextLink={nextLink}
              loadNextPage={loadNextPage}
            />
          )}
          <tr className="total-wrapper report-column-custom-style">
            <td></td>
            <td></td>
            <td></td>
            <td className="text-field">
              <span className="report-column-content-custom-style">
                {Translate('Total')}
              </span>
            </td>
            <td>
              <span className="report-column-content-custom-style">
                {acodaxNumberFormat(total?.totalDebitAmount ?? 0)}
              </span>
            </td>
            <td>
              <span className="report-column-content-custom-style">
                {acodaxNumberFormat(total?.totalCreditAmount ?? 0)}
              </span>
            </td>
            <td>
              <span className="report-column-content-custom-style">
                {acodaxNumberFormat(total?.totalSum ?? 0)}
              </span>
            </td>
          </tr>
        </Table>
      ) : (
        <Alert className="my-auto alert-danger  mt-2">
          <h5 className="text-center text-secondary">
            {Translate('No Transaction Found')} &#128578;
          </h5>
        </Alert>
      )}
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={handleVoucherDetailsArea}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={trans_type}
        voucherId={voucherId}
        infoPage={true}
        offCanvas={true}
      />
    </Container>
  );
}

PersonBasedTransactions.propTypes = {
  person_id: PropTypes.string,
  person_name: PropTypes.string,
  url: PropTypes.string,
  activeTabKey: PropTypes.string,
  tabApiCallCompleted: PropTypes.bool,
  setTabApiCallCompleted: PropTypes.func
};

export default PersonBasedTransactions;
