import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';

const LoadingItem = () => {
  return (
    <Placeholder as={Card.Text} animation="glow">
      <Placeholder xs={7} /> <Placeholder xs={4} />
      <Placeholder xs={4} /> <Placeholder xs={6} />
      <Placeholder xs={8} />
    </Placeholder>
  );
};

export default LoadingItem;
