import moment from 'moment';

export default function handlePurchaseModuleFormDynamicValues(
  event,
  action,
  formData,
  user,
  methods,
  module
) {
  return new Promise(resolve => {
    if (!Array.isArray(methods)) {
      throw new Error(
        'handlePurchaseModuleFormDynamicValues failed to execute, Please provide a valid methods'
      );
    }

    let keyName = !action ? event.target.name : action?.name;

    if (
      methods.includes(
        'setSuppliersDefaultPaymentTermAsDefaultPaymentTermValue'
      )
    ) {
      if (
        keyName === 'supplier_id' ||
        (module === 'DebitNote' && keyName === 'person_id')
      ) {
        const paymentTermDetails = event?.supplier_details?.payment_term_id
          ? event.supplier_details
          : user?.branch?.purchase_settings?.payment_term_id
          ? user.branch.purchase_settings
          : undefined;
        if (paymentTermDetails?.payment_term_id) {
          formData.payment_term_id = paymentTermDetails.payment_term_id;
          formData.payment_term_id_ref = {
            label:
              paymentTermDetails?.payment_term_terms ||
              paymentTermDetails?.payment_term_name,
            value: paymentTermDetails.payment_term_id,
            days: paymentTermDetails?.payment_term_days
          };

          if (event) {
            event.days = paymentTermDetails?.payment_term_days;
          }
        } else {
          formData.payment_term_id = null;
          formData.payment_term_id_ref = null;

          if (methods.includes('setDueDateRelatedToPaymentTermDays')) {
            formData.due_date = '';
          }
        }
      }
    }

    if (methods.includes('applyTransdateAndTaxIncludedChanges')) {
      if (['tax_included', 'trans_date'].includes(keyName)) {
        formData.details = formData.details.map(item => {
          return {
            ...item,
            trans_date: formData.trans_date,
            tax_included: formData.tax_included,
            add_to_cart_ref: true,
            edit: true
          };
        });
      }
    }

    if (methods.includes('setDueDateRelatedToPaymentTermDays')) {
      if (
        (['payment_term_id', 'supplier_id'].includes(keyName) ||
          (module === 'DebitNote' && keyName === 'person_id')) &&
        typeof event?.days === 'number' &&
        formData?.trans_date?.length > 0
      ) {
        let format = 'YYYY-MM-DD';
        formData.due_date = moment(formData?.trans_date, format)
          .clone()
          .add(event.days, 'days')
          .format(format);
      }
    }

    resolve(formData);
  });
}
