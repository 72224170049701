import { React, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  Badge,
  Button,
  Form,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
  Offcanvas
} from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import PropTypes from 'prop-types';

import { BsPlus } from 'react-icons/bs';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { AuthWizardContext } from 'context/Context';
import { UsersPermissionsFilterFormKeys } from 'helpers/formKeys';
import { showToast } from 'module/Common/Toast/toast';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import AvatarImage from 'assets/img/response.png';
import GropActionDropDown from './GropActionDropDown';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import SelectMultipleUser from 'components/form/SelectMultipleUser';

const GroupInfoModal = ({
  groupDetail,
  showGroupDetails,
  setShowGroupDetails,
  setNewMember,
  GroupAvatarImage
}) => {
  const { user } = useContext(AuthWizardContext);
  const selectRef = useRef(null);
  const Translate = useAxisproTranslate();
  const [filter, setfilter] = useState(UsersPermissionsFilterFormKeys);
  const [loggedGroupUser, setLoggedGroupUser] = useState({});
  const [editGroupDetail, setEditGroupDetail] = useState(false);
  const [editForm, setEditForm] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [formError, setFormError] = useState([]);
  const [formData, setFormData] = useState({
    group_id: '',
    users: []
  });

  const handleFieldsChange = (e, action) => {
    if (action) {
      let newData = generateFormDataHelper(e, action, filter);
      setfilter(newData);
      setFormData({
        ...formData,
        users: newData.user_ref.map(item => item.value)
      });
    }
  };

  const handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;
    setEditForm({ ...editForm, [name]: value });
  };

  const addToGroup = () => {
    if (formData && formData.users && formData.users.length <= 0) {
      showToast(`Please select atleast one user`, 'error');
    } else {
      axios
        .post('user/chat-groups/add-new-user', formData)
        .then(response => {
          if (response.data.success) {
            showToast(response.data.message, 'success');
            setNewMember(true);
            selectRef.current.clearValue();
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              'error'
            );
          }
        })
        .catch(() => {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        });
    }
  };

  const handleEditSumbit = () => {
    setIsEditing(true);
    axios
      .put(`user/chat-groups/${groupDetail.id}`, editForm)
      .then(response => {
        if (response.data.success) {
          setNewMember(true);
          showToast(response.data.message, 'success');
          setEditGroupDetail(false);
          setIsEditing(false);
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
          setIsEditing(false);
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setIsEditing(false);
      });
  };

  useEffect(() => {
    if (groupDetail && groupDetail.users && groupDetail.users.length > 0) {
      const userData = groupDetail.users.find(item => item.id == user.id);
      setLoggedGroupUser(userData);
    }
  }, [groupDetail, user]);

  useEffect(() => {
    if (groupDetail) {
      setFormData({ ...formData, group_id: groupDetail.id });
      setEditForm({
        ...editForm,
        name: groupDetail.name,
        description: groupDetail.description
      });
    }
  }, [groupDetail]);

  return (
    <>
      <Offcanvas
        show={showGroupDetails ? true : false}
        style={{ width: '400px' }}
        onHide={() => setShowGroupDetails(false)}
        placement="end"
      >
        <Modal.Header>
          <div className="position-relative me-2">
            <img
              src={GroupAvatarImage}
              width={50}
              height={50}
              className="rounded-circle"
            />
          </div>

          <div
            className="d-flex flex-column align-items-start me-2"
            style={{ width: '100%' }}
          >
            <div
              className="d-flex flex-row justify-content-between align-items-start"
              style={{ width: '100%' }}
            >
              {!editGroupDetail ? (
                <span className="m-0 text-dark" style={{ fontSize: '18px' }}>
                  {groupDetail.name}
                </span>
              ) : (
                <>
                  <Form.Control
                    type="text"
                    name="name"
                    className="w-75 mb-2"
                    onChange={handleChange}
                    value={editForm.name}
                  />
                  <FormErrorPopover
                    id="name"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.name}
                  />
                </>
              )}
              {!editGroupDetail ? (
                <Button
                  size="sm"
                  className="p-2 m-0 d-flex justify-content-center align-items-center"
                  style={{ height: '25px' }}
                  variant="primary"
                  onClick={() => setEditGroupDetail(true)}
                >
                  {Translate('Edit')}
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="p-2 m-0 d-flex justify-content-center align-items-center"
                  style={{ height: '25px' }}
                  variant="success"
                  onClick={handleEditSumbit}
                >
                  {!isEditing ? Translate('Save') : Translate('Saving') + '...'}
                </Button>
              )}
            </div>
            {editGroupDetail ? (
              <Form.Control
                as="textarea"
                className="w-75"
                rows={4}
                name="description"
                value={editForm.description}
                onChange={handleChange}
              />
            ) : (
              <>
                <span className="m-0 text-dark">
                  {Translate('Created By')} :
                  {groupDetail.created_user_full_name}
                </span>
                <span className="m-0 text-dark">
                  {Translate('Created At')} :
                  {groupDetail && groupDetail.created_at.split(' ')[0]}
                </span>
              </>
            )}
          </div>
        </Modal.Header>

        <Modal.Body>
          <Card.Body className="p-0 pb-2 d-flex ">
            <h2 className="m-0">
              {groupDetail && groupDetail?.description?.length > 180 ? (
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>{groupDetail.description}</Tooltip>}
                >
                  <span>
                    {groupDetail.description.substring(0, 180) + '...'}
                  </span>
                </OverlayTrigger>
              ) : (
                groupDetail.description
              )}
            </h2>
          </Card.Body>
          {!editGroupDetail && loggedGroupUser && loggedGroupUser.is_admin ? (
            <Card.Body className="p-0 pb-2 d-flex ">
              <div className="col-10">
                <SelectMultipleUser
                  value={filter && filter.user_ref}
                  handleFieldChange={handleFieldsChange}
                  groupDetail={groupDetail}
                  selectRef={selectRef}
                />
              </div>
              <Button
                variant="danger"
                className="ms-3"
                size="sm"
                onClick={() => addToGroup()}
              >
                <BsPlus />
              </Button>
            </Card.Body>
          ) : (
            ''
          )}
          <SimpleBarReact
            style={{
              height: '45rem',
              overflowX: 'hidden'
            }}
          >
            {groupDetail && groupDetail.users.length > 0 ? (
              groupDetail.users.map(item => (
                <>
                  <Card.Body className="d-flex align-items-center m-0 mt-2 p-0">
                    <div className="position-relative me-2">
                      <img
                        src={item.image}
                        width={50}
                        height={50}
                        className="rounded-circle"
                        alt="Alternative text for the image"
                        onError={e => {
                          e.target.src = AvatarImage;
                        }}
                      />
                      {item.is_user_active && (
                        <div className="translate-middle badge rounded-circle bg-success text-success group-info-users-active-style">
                          .
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-column align-items-start me-2">
                      <h3 className="m-0">
                        {item.id == user.id ? Translate('You') : item.name}
                      </h3>
                      {item.is_admin ? (
                        <Badge bg="danger">{Translate('Group Admin')}</Badge>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="d-flex flex-column align-items-start me-3 ms-auto">
                      <GropActionDropDown
                        item={item}
                        loggedGroupUser={loggedGroupUser}
                        groupDetail={groupDetail}
                        setNewMember={setNewMember}
                        setShowGroupDetails={setShowGroupDetails}
                        user={user}
                      />
                    </div>
                  </Card.Body>
                  <hr />
                </>
              ))
            ) : (
              <Card.Body
                className="d-flex align-items-center justify-content-center m-0 p-0"
                style={{ height: '40rem' }}
              >
                <LoadingScreen message="Looking for details" />
              </Card.Body>
            )}
          </SimpleBarReact>
        </Modal.Body>
      </Offcanvas>
    </>
  );
};

GroupInfoModal.propTypes = {
  groupDetail: PropTypes.any,
  showGroupDetails: PropTypes.bool,
  setShowGroupDetails: PropTypes.func,
  setNewMember: PropTypes.any,
  GroupAvatarImage: PropTypes.any
};

export default GroupInfoModal;
