import { React } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
const SelectVoucherTransType = ({
  name,
  value,
  onChange,
  style,
  placeholder,
  isInvalid
}) => {
  const Translate = useAxisproTranslate();
  const thisOptions = [
    {
      label: Translate('Receipt Voucher'),
      value: 'RV'
    },
    {
      label: Translate('Journal Voucher'),
      value: 'JV'
    },
    {
      label: Translate('Payment Voucher'),
      value: 'PV'
    },
    {
      label: Translate('Bank Transfer'),
      value: 'BT'
    },
    {
      label: Translate('Customer Receipt'),
      value: 'CR'
    },
    {
      label: Translate('Supplier Payment'),
      value: 'SP'
    },
    {
      label: Translate('Inventory Adjustment'),
      value: 'IA'
    },
    {
      label: Translate('Sales Invoice'),
      value: 'SI'
    },
    {
      label: Translate('Sales Delivery'),
      value: 'SD'
    },
    {
      label: Translate('Credit Note'),
      value: 'CN'
    },
    {
      label: Translate('Debit Note'),
      value: 'DN'
    },
    {
      label: Translate('Purchase Invoice'),
      value: 'PI'
    },
    {
      label: Translate('Purchase Delivery'),
      value: 'PD'
    }
  ];
  return (
    <Select
      isClearable
      value={value}
      placeholder={placeholder ?? ''}
      name={name ? name : 'owner'}
      className={`app-react-select${
        isInvalid ? ' form-control ps-0 py-0 is-invalid' : ''
      }`}
      classNamePrefix="app-react-select"
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({
          ...provided,
          ...style
        })
      }}
      onChange={onChange}
      options={thisOptions}
    />
  );
};
SelectVoucherTransType.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  style: PropTypes.any,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool
};
export default SelectVoucherTransType;
