import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { BsBank2 } from 'react-icons/bs';
import { TiTick } from 'react-icons/ti';

import { useBreakpoints } from 'hooks/useBreakpoints';
import { CloseButton, MoreButton } from 'module/Common/Buttons/ViewPageButtons';
import useAxisProPermission from 'hooks/useAxisproPermission';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import BankTransferView from './BankTransferView';
import FileUpload from '../Common/FileUpload';
import RecurringRules from '../Common/RecurringRules';
import ReferenceCopyToClipBoard from '../Common/ReferenceCopyToClipBoard';
import PDFView from 'components/PDFViewerButton/PDFView';
import { FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ActivityLog from 'components/activity-log/ActivityLog';

function BankTransferTabView({
  BankTransferVoucherData,
  loading,
  voidScreen,
  fetchData,
  setShowVoucherDetailArea,
  offCanvas,
  voidTransactionReport
}) {
  const { breakpoints } = useBreakpoints();
  const navigate = useNavigate();
  const Translate = useAxisproTranslate();
  const axisProPermission = useAxisProPermission();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [upload, setUpload] = useState(false);
  const [recurring, setRecurring] = useState(false);

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <BsBank2 size={26} className="me-2 text-dark" />
              <h5 className="mb-0 d-flex text-uppercase view-page-header">
                {Translate('Bank Transfer')}
              </h5>
            </div>
            {BankTransferVoucherData && BankTransferVoucherData.is_recurring ? (
              <Badge bg="success" className="ms-2 fs--1">
                {Translate('Recurring')} <TiTick />
              </Badge>
            ) : (
              ''
            )}
            {voidTransactionReport && (
              <Badge bg="danger" className="ms-2 fs--1">
                {Translate('Voided')}
              </Badge>
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <>
                  <MoreButton
                    setUpload={setUpload}
                    type={'BT'}
                    setRecurring={setRecurring}
                    recurring
                    disableRecurring={
                      BankTransferVoucherData.is_recurring === 1
                    }
                  />
                  {axisProPermission('update-bt') ? (
                    <Link
                      className="btn btn-sm btn-info px-2"
                      to={
                        `/finance/bank-transfer/edit/` +
                        BankTransferVoucherData?.id
                      }
                    >
                      <FaEdit />
                    </Link>
                  ) : null}
                </>
              )}
              {!voidTransactionReport && axisProPermission('print-bt') && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`/finance/bank-transfer/print/${BankTransferVoucherData.id}`}
                  pageTitle="Bank Transfer print view"
                />
              )}
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap">
            <div className="w-100 mb-2 view-page-contents">
              <div className="d-flex gap-1">
                <h6 className="mb-2 fw-bold">
                  {Translate('Transaction Date')}:&nbsp;
                </h6>
                <h6 className="mb-2">
                  {BankTransferVoucherData &&
                  BankTransferVoucherData.trans_date_formatted
                    ? BankTransferVoucherData.trans_date_formatted
                    : 'update'}
                </h6>
              </div>
              <div className="d-flex gap-1">
                <h6 className="mb-2 fw-bold">
                  {Translate('Reference')}:&nbsp;
                </h6>
                <h6
                  className={
                    offCanvas && !voidTransactionReport
                      ? 'mb-1 reference-style'
                      : 'mb-2'
                  }
                  style={{
                    cursor: offCanvas && !voidTransactionReport ? 'pointer' : ''
                  }}
                  onClick={() =>
                    offCanvas && !voidTransactionReport
                      ? navigate(
                          `/finance/voucher-inquiry?entry=${BankTransferVoucherData.id}&layout=column`
                        )
                      : ''
                  }
                >
                  {BankTransferVoucherData && BankTransferVoucherData.reference
                    ? BankTransferVoucherData.reference
                    : 'null'}
                </h6>
                <ReferenceCopyToClipBoard data={BankTransferVoucherData} />
              </div>
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={BankTransferVoucherData.trans_type}
                      transId={BankTransferVoucherData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <BankTransferView
                    BankTransferVoucherData={BankTransferVoucherData}
                  />
                </SimpleBar>
              </Tab>
              {BankTransferVoucherData.media &&
              BankTransferVoucherData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={BankTransferVoucherData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {BankTransferVoucherData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`/finance/bank-transfer/print/${BankTransferVoucherData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
              {BankTransferVoucherData?.id ? (
                <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                  <SimpleBar
                    className={
                      voidScreen || offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    {key === 'activityLog' && (
                      <ActivityLog subjectID={BankTransferVoucherData?.id} />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {BankTransferVoucherData &&
              BankTransferVoucherData.created_user_full_name
                ? BankTransferVoucherData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {BankTransferVoucherData && BankTransferVoucherData.created_at
                ? BankTransferVoucherData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'BT'}
        id={BankTransferVoucherData.id}
        setKey={setKey}
      />
      <RecurringRules
        show={recurring}
        type="BT"
        id={BankTransferVoucherData.id}
        fetchData={fetchData}
        onHide={() => setRecurring(false)}
      />
    </>
  );
}

BankTransferTabView.propTypes = {
  BankTransferVoucherData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  setShowVoucherDetailArea: PropTypes.func,
  offCanvas: PropTypes.bool,
  voidTransactionReport: PropTypes.bool
};

export default BankTransferTabView;
