import React from 'react';
import PropTypes from 'prop-types';
import {
  createSearchParams,
  useOutletContext,
  useSearchParams
} from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsFillTrashFill } from 'react-icons/bs';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AppPagination from 'components/common/app-pagination/AppPagination';
import TableHeader from 'helpers/tableHeader';
import AddButton from 'components/add-button/AddButton';
import PrintButton from 'components/print-button/PrintButton';
import ExportButton from 'components/export-button/ExportButton';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Filter from 'module/Common/TableFilter/Filter';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { setCustomFieldItemsTableHeaders } from 'helpers/setCustomFields';
import CommonSearch from 'components/common-search/CommonSearch';

const DebitNoteTableView = ({ data, handleDelete, isLoading }) => {
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const pageNumber = queryParams.get('page');
  const filterQueryParam = queryParams.get('filters');
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [permissions] = useOutletContext();

  const columns = [
    {
      accessor: 'date',
      Header: Translate('Date'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        return (
          <h5 className="mb-0 fs--1">
            {rowData.row.original.trans_date_formatted}
          </h5>
        );
      }
    },
    {
      accessor: 'reference',
      Header: Translate('Reference'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        return <h5 className="mb-0 fs--1">{rowData.row.original.reference}</h5>;
      }
    },
    {
      accessor: 'supplier_name',
      Header: Translate('Supplier name'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        return (
          <h5 className="mb-0 fs--1">{rowData.row.original.person_name}</h5>
        );
      }
    },
    {
      accessor: 'amount',
      Header: Translate('Amount'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        return (
          <h5 className="mb-0 fs--1">
            {rowData.row.original.amount_formatted}
          </h5>
        );
      }
    },
    ...setCustomFieldItemsTableHeaders({
      customFieldItems: data?.customFields,
      Translate: Translate
    }),
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'py-0 text-end'
      },
      Cell: rowData => {
        return (
          <div className="d-flex flex-row justify-content-end align-items-center">
            <Link
              to={`/purchase/debit-note?${createSearchParams({
                ...(filterQueryParam ? { filters: filterQueryParam } : null),
                entry: rowData.row.original.id,
                layout: 'column',
                ...(pageNumber ? { page: pageNumber } : null)
              })}`}
              className={'p-1 me-1 mx-1 text-primary'}
            >
              <FaEye />
            </Link>

            <PrintButton
              variant="transparent"
              className="p-1 me-1 mx-1 text-black"
              style={{ boxShadow: 'none' }}
              url={`finance/debit-note/print/${rowData.row.original.id}`}
              pageTitle="Debit note print view"
            />

            {handleDelete && (
              <Button
                variant="transparent"
                className="p-1 me-1 mx-1 text-danger"
                style={{ boxShadow: 'none' }}
                onClick={() => handleDelete(rowData.row.original)}
              >
                <BsFillTrashFill />
              </Button>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <Card className="mb-3 flex-fill">
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <TableHeader title={Translate('Debit note')} table />
        <CommonSearch />
        <ExportButton
          className="me-1"
          url="finance/debit-note/export"
          initialMessage={Translate('Debit note export started')}
          successMessage={Translate('Debit note export completed')}
          file_type={'pdf'}
        />
        {permissions.create_dn && <AddButton to="/purchase/debit-note/add" />}
      </Card.Header>
      <Card.Body className="p-0">
        <Filter moduleName="purchase_delivery" />
        {!isLoading && data && data.data ? (
          <AdvanceTableWrapper
            columns={columns}
            data={data?.data ?? []}
            sortable
            pagination
            perPage={20}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              view
              entry_params
              to="/purchase/debit-note/"
              allQueryParams={allQueryParams}
            />
          </AdvanceTableWrapper>
        ) : (
          <div
            className=" d-flex align-item-center justify-content-center"
            style={{ height: '60vh' }}
          >
            <LoadingScreen message="Looking for data" />
          </div>
        )}
      </Card.Body>
      <Card.Footer>
        {!isLoading && data && data.links && (
          <AppPagination
            data={data}
            url="/purchase/debit-note"
            search={allQueryParams}
          />
        )}
      </Card.Footer>
    </Card>
  );
};

DebitNoteTableView.propTypes = {
  data: PropTypes.any,
  handleDelete: PropTypes.func,
  isLoading: PropTypes.bool
};

export default DebitNoteTableView;
