import { React, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Alert, Container, Table } from 'react-bootstrap';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { showToast } from 'module/Common/Toast/toast';
import { ChequeReportHead } from '../Common/data';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ReportsContainer from 'components/report-components/reports-container/ReportsContainer';
import TableHeader from '../Common/TableHeader';
import MinimalReportDataFetching from 'module/Common/Loading/MinimalReportDataFetching';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import SupplierDetails from 'components/supplier-details/SupplierDetails';
import useReportApiCalls from '../Common/useReportApiCalls';
import ClickPerson from 'module/Common/ClickableData/ClickPerson';
import { chequeStatus } from 'module/Common/Enum/enum';
import ClickReference from 'module/Common/ClickableData/ClickReference';
import VoucherDetail from 'components/voucher-details/VoucherDetail';

function ChequeReport() {
  const Translate = useAxisproTranslate();
  const reportApiCall = useReportApiCalls();
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get('filters');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({ value: 0 });
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [showSupplierDetailsArea, setSupplierDetailsArea] = useState(false);
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);
  const [transType, setTransType] = useState();
  const [voucherId, setVoucherId] = useState();
  const [nextLink, setNextLink] = useState(null);
  const pageNumber = queryParams.get('page');
  const permissions = {
    customer_show: getAcodaxPermissionSlug('CS', 'show'),
    supplier_show: getAcodaxPermissionSlug('supplier', 'show')
  };

  const fetchData = async (page = 1) => {
    setLoading(true);
    const data = await reportApiCall('finance/cheques', {
      page: page
    });
    if (data?.links?.next !== null) {
      setNextLink(data.links.next);
    } else {
      setNextLink(null);
    }
    setData(data.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(pageNumber);
  }, [pageNumber, filterItems]);

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
    setValue({ value: 0 });
  };

  const handleSupplierDetailsArea = () => {
    setSupplierDetailsArea(!showSupplierDetailsArea);
    setValue({ value: 0 });
  };

  const clickOnPerson = (person_id, person_type) => {
    setValue({ value: person_id });
    if (person_type === 'CUSTOMER') {
      setCustomerDetailsArea(true);
    } else {
      setSupplierDetailsArea(true);
    }
  };

  const clickOnVoucher = (voucher_id, trans_type) => {
    setVoucherId(voucher_id);
    setTransType(trans_type);
    setShowVoucherDetailArea(!showVoucherDetailArea);
  };

  const loadNextPage = async () => {
    if (nextLink && nextLink !== null) {
      await axios
        .get(nextLink)
        .then(res => {
          if (res.data.success) {
            setData([...data, ...res.data.data.data]);
            if (res?.data?.data?.links?.next !== null) {
              setNextLink(res.data.data.links.next);
            } else {
              setNextLink(null);
            }
          }
        })
        .catch(error => {
          if (error) {
            showToast('Something went wrong please contact admin', 'error');
          }
        });
    }
  };

  return (
    <>
      <ReportsContainer
        title={'Cheque Report'}
        url="finance/cheques-report/export"
        initialMessage={'Cheque report export started'}
        successMessage={'Cheque report export completed'}
        data={data}
        loading={loading}
        moduleName="cheque_list"
        dropDownData={chequeStatus}
        enableDropDown
      >
        <Container fluid className="p-0 reports-container-wrapper">
          <Table striped hover bordered={data?.length > 0 ? true : false}>
            <TableHeader headers={ChequeReportHead} />
            {data ? (
              data.length > 0 ? (
                <>
                  {data.map((item, index) => (
                    <tbody key={index}>
                      <tr className="report-column-custom-style">
                        <td>
                          <span className="report-column-content-custom-style">
                            {index + 1}
                          </span>
                        </td>

                        <td>{item?.cheque_number}</td>
                        <td>
                          <span className="report-column-content-custom-style">
                            {item.cheque_date ?? '-'}
                          </span>
                        </td>
                        <td style={{ minWidth: '11rem' }}>
                          <span className="report-column-content-custom-style">
                            {item.trans_date ?? '-'}
                          </span>
                        </td>
                        <td style={{ minWidth: '13rem' }}>
                          <span className="report-column-content-custom-style">
                            {item.transaction_reference ? (
                              <ClickReference
                                permission={getAcodaxPermissionSlug(
                                  item.trans_type,
                                  'show'
                                )}
                                id={item.voucher_id}
                                trans_type={item.trans_type}
                                reference={item.transaction_reference}
                                clickOnFunction={clickOnVoucher}
                              />
                            ) : (
                              '-'
                            )}
                          </span>
                        </td>
                        <td>
                          {' '}
                          <span className="report-column-content-custom-style">
                            {item.bank_name ?? '-'}
                          </span>
                        </td>
                        <td>
                          {' '}
                          <span className="report-column-content-custom-style">
                            {item.type}
                          </span>
                        </td>
                        <td>
                          {item.supplier_name ? (
                            <ClickPerson
                              name={item.supplier_name}
                              person_type={'SUPPLIER'}
                              className="fs--1"
                              permission={permissions?.supplier_show}
                              clickOnFunction={() =>
                                clickOnPerson(item.person_id, 'SUPPLIER')
                              }
                            />
                          ) : (
                            '-'
                          )}
                        </td>
                        <td>
                          {item?.customer_name ? (
                            <ClickPerson
                              name={item.customer_name}
                              person_type={'CUSTOMER'}
                              className="fs--1"
                              permission={permissions?.customer_show}
                              clickOnFunction={() =>
                                clickOnPerson(item.customer_id, 'CUSTOMER')
                              }
                            />
                          ) : (
                            '-'
                          )}
                        </td>
                        <td>{item?.amount}</td>
                        <td>
                          {' '}
                          <span
                            className={`report-column-content-custom-style fs--2 ${
                              item?.status === 'cleared'
                                ? 'text-success'
                                : item?.status === 'bounced'
                                ? 'text-danger'
                                : 'text-info'
                            }`}
                          >
                            {item?.status}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                  {nextLink && nextLink !== null && nextLink !== '' && (
                    <MinimalReportDataFetching
                      row={3}
                      column={11}
                      nextLink={nextLink}
                      loadNextPage={loadNextPage}
                    />
                  )}
                </>
              ) : (
                <tr className="alert-styles">
                  <td colSpan={12}>
                    <Alert variant="warning">
                      {Translate('Currently there is no report generated.')}
                    </Alert>
                  </td>
                </tr>
              )
            ) : (
              ''
            )}
          </Table>
        </Container>
      </ReportsContainer>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={value}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      <SupplierDetails
        show={showSupplierDetailsArea}
        onHide={handleSupplierDetailsArea}
        value={value}
        showSupplierDetailsArea={showSupplierDetailsArea}
      />
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={() => setShowVoucherDetailArea(false)}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={transType}
        voucherId={voucherId}
        offCanvas={true}
      />
    </>
  );
}

export default ChequeReport;
