const initPermissionData = {
  apiDataBackup: undefined,
  allPermissionsData: [],
  permissionsData: [],
  permissionsTreeData: [],
  defaultSelectedModules: [],
  activeModule: undefined
};
function setPermissionWithAssignedPermissionData(data) {
  let permissionArray = [];
  let permissionData = [];
  const getChildrenPermissionSlug = subData => {
    Object.keys(subData).forEach(items => {
      if (subData[items]?.sub_modules) {
        getChildrenPermissionSlug(subData[items]);
      }
      if (subData[items]?.permissions?.length > 0) {
        subData[items]?.permissions.forEach(permission => {
          let newPermissionsObject = {
            hasPermission: permission[2],
            hasPermissionRef: permission[2],
            id: permission[0],
            module_id: items,
            module_name: items,
            name: permission[1],
            slug: permission[0]
          };
          permissionData.push(newPermissionsObject);
          permissionArray.push(permission[0]);
        });
      }
    });
  };

  if (data) {
    Object.keys(data).forEach(modules => {
      Object.keys(data[modules]).forEach(subModuleName => {
        if (data[modules][subModuleName]) {
          getChildrenPermissionSlug(data[modules][subModuleName]);
        }
        if (data[modules][subModuleName]?.permissions?.length > 0) {
          data[modules][subModuleName]?.permissions.forEach(permission => {
            let newPermissionsObject = {
              hasPermission: permission[2],
              hasPermissionRef: permission[2],
              id: permission[0],
              module_id: subModuleName,
              module_name: subModuleName,
              name: permission[1],
              slug: permission[0]
            };
            permissionData.push(newPermissionsObject);
            permissionArray.push(permission[0]);
          });
        }
      });
    });
  }

  return { permissionArray, permissionData };
}
function setSubModule(data, setSelectedModules, parentName) {
  let newData = [];
  if (data && Object.keys(data).length > 0) {
    Object.keys(data).forEach(subModuleName => {
      let treeData = {
        id: subModuleName,
        name: data[subModuleName].description,
        icon:
          typeof data[subModuleName]?.sub_modules === 'object' &&
          data[subModuleName]?.sub_modules !== null
            ? 'folder'
            : 'file',
        iconClass: 'text-danger',
        children: [],
        hide: false
      };
      if (
        typeof data[subModuleName]?.sub_modules === 'object' &&
        data[subModuleName]?.sub_modules !== null
      ) {
        treeData.children = setSubModule(
          data[subModuleName]?.sub_modules,
          setSelectedModules,
          parentName
        );
      }
      if (data[subModuleName]?.permissions?.length > 0) {
        const hasTrueAtIndex2 = data[subModuleName]?.permissions.some(
          item => item[2] === true
        );
        if (hasTrueAtIndex2) {
          setSelectedModules(subModuleName);
          setSelectedModules(parentName);
        }
        treeData.children = data[subModuleName]?.permissions.map(
          singlePermission => {
            if (singlePermission[2]) {
              setSelectedModules(singlePermission[0]);
            }
            return {
              id: singlePermission[0],
              name: singlePermission[1],
              icon: 'file',
              iconClass: 'text-danger',
              hide: true,
              children: []
            };
          }
        );
      }
      newData.push(treeData);
    });
  }
  return newData;
}

function setTreeResult(permissionsWithHasPermission) {
  let selectedModules = [];

  const setSelectedModules = id => {
    selectedModules = [...selectedModules, id];
  };

  const treeData = [];

  Object.keys(permissionsWithHasPermission).forEach(moduleName => {
    let module = permissionsWithHasPermission[moduleName];
    let result = {
      id: moduleName,
      name: module.description,
      icon:
        typeof module?.sub_modules === 'object' && module?.sub_modules !== null
          ? 'folder'
          : 'file',
      iconClass: 'text-primary',
      permission: module?.permissions ?? [],
      children: []
    };

    if (
      typeof module?.sub_modules === 'object' &&
      module?.sub_modules !== null
    ) {
      result.children = setSubModule(
        module.sub_modules,
        setSelectedModules,
        moduleName
      );
    }

    treeData.push(result);
  });

  return {
    treeData: [
      {
        id: 'rootItemId',
        name: 'Modules',
        icon: 'folder',
        iconClass: 'text-primary',
        children: treeData.sort((a, b) =>
          a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        )
      }
    ],
    selectedModules: selectedModules
  };
}
export default function permissionDataReducer(state, action) {
  switch (action?.type) {
    case 'SET_DATA': {
      const permissionsWithHasPermission =
        setPermissionWithAssignedPermissionData(action.data);
      let newResult = { ...state, apiDataBackup: action.data };
      if (action.data) {
        const { treeData, selectedModules } = setTreeResult(action.data);
        newResult.allPermissionsData =
          permissionsWithHasPermission?.permissionData;
        newResult.permissionsData =
          permissionsWithHasPermission?.permissionData;
        newResult.permissionsTreeData = treeData;
        newResult.defaultSelectedModules = selectedModules;
      }
      return newResult;
    }
    case 'RESET_DATA': {
      const permissionsWithHasPermission =
        setPermissionWithAssignedPermissionData(state.apiDataBackup);
      let newResult = { ...state, apiDataBackup: state.apiDataBackup };
      if (state.apiDataBackup) {
        const { treeData, selectedModules } = setTreeResult(
          state.apiDataBackup
        );
        newResult.allPermissionsData =
          permissionsWithHasPermission?.permissionData;
        newResult.permissionsData =
          permissionsWithHasPermission?.permissionData;
        newResult.permissionsTreeData = treeData;
        newResult.defaultSelectedModules = selectedModules;
      }
      return newResult;
    }
    case 'SET_ACTIVE_MODULE_DATA': {
      return {
        ...state,
        activeModule: action?.module
      };
    }
    case 'CHECK_MODULE': {
      if (!Array.isArray(state?.permissionsData))
        throw Error('Invalid permission data');
      const { slectedModulesArray } = action;
      return {
        ...state,
        permissionsData: state.permissionsData.reduce((result, permission) => {
          if (
            slectedModulesArray.includes(permission.module_id) ||
            slectedModulesArray.includes(permission.id)
          ) {
            permission.hasPermissionRef = true;
          } else {
            permission.hasPermissionRef = false;
          }
          result.push(permission);
          return result;
        }, [])
      };
    }
    case 'SWITCH_PERMISSION': {
      const data = {
        ...state,
        permissions: state?.permissionsData
      };
      if (Array.isArray(data?.permissions)) {
        const { module, selectedModulesArray, checked, id } = action;
        let NewPermissionData = data.permissions.reduce(
          (result, permissionItem) => {
            if (
              permissionItem.id === id ||
              (module &&
                (permissionItem.module_id === id ||
                  selectedModulesArray.includes(permissionItem.module_id)))
            ) {
              permissionItem.hasPermissionRef = checked;
            }
            result.push(permissionItem);
            return result;
          },
          []
        );
        data.permissionsData = NewPermissionData;
        data.allPermissionsData = NewPermissionData;
        const updatedData = NewPermissionData.filter(
          items => items.hasPermissionRef
        ).map(items => items?.id);
        data.defaultSelectedModules = updatedData;
      }
      return data;
    }
    case 'SET_DEFAULT_DATA': {
      return initPermissionData;
    }

    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export { initPermissionData };
