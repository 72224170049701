import { React } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const DownKeyController = ({
  url,
  data,
  currentData,
  pageNumber,
  lastPage,
  entry
}) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const filterQueryParam = queryParams.get('filters');
  const handleKeyDown = event => {
    const foundObject = data.find(item => item.id === currentData);
    const index = data.indexOf(foundObject);
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const nextItem = data[index + 1];
      if (nextItem) {
        if (parseInt(pageNumber)) {
          if (entry) {
            navigate(
              `${url}?entry=${nextItem.id}&page=${pageNumber}&layout=column${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          } else {
            navigate(
              `${url + nextItem.id}?page=${pageNumber}${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          }
        } else {
          if (entry) {
            navigate(
              `${url}?entry=${nextItem.id}&layout=column${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          } else {
            navigate(
              `${url + nextItem.id}${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          }
        }
      }
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      const nextItem = data[index - 1];
      if (nextItem) {
        if (parseInt(pageNumber)) {
          if (entry) {
            navigate(
              `${url}?entry=${nextItem.id}&page=${pageNumber}&layout=column${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          } else {
            navigate(
              `${url + nextItem.id}?page=${pageNumber}${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          }
        } else {
          if (entry) {
            navigate(
              `${url}?entry=${nextItem.id}&layout=column${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          } else {
            navigate(
              `${url + nextItem.id}${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          }
        }
      }
    }
    if (event.key === 'ArrowRight') {
      event.preventDefault();
      if (
        parseInt(pageNumber) + 1 <= parseInt(lastPage) &&
        parseInt(lastPage) !== 1
      ) {
        if (parseInt(pageNumber) >= 2) {
          if (entry) {
            navigate(
              `${url}?page=${parseInt(pageNumber) + 1}&layout=column${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          } else {
            navigate(
              `${url}?page=${parseInt(pageNumber) + 1}${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          }
        } else {
          if (entry) {
            navigate(
              `${url}?page=2&layout=column${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          } else {
            navigate(
              `${url}?page=2${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          }
        }
      }
    }
    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      if (parseInt(pageNumber) > 2) {
        if (entry) {
          navigate(
            `${url}?page=${parseInt(pageNumber) - 1}&layout=column${
              filterQueryParam
                ? '&filters=' + encodeURIComponent(filterQueryParam)
                : ''
            }`
          );
        } else {
          navigate(
            `${url}?page=${parseInt(pageNumber) - 1}${
              filterQueryParam
                ? '&filters=' + encodeURIComponent(filterQueryParam)
                : ''
            }`
          );
        }
      } else {
        if (parseInt(pageNumber) !== 0) {
          if (entry) {
            navigate(
              `${url}?&layout=column&page=1${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          } else {
            navigate(
              `${url}?&layout=column&page=1${
                filterQueryParam
                  ? '&filters=' + encodeURIComponent(filterQueryParam)
                  : ''
              }`
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentData, data]);

  return <div></div>;
};
DownKeyController.propTypes = {
  url: PropTypes.string,
  data: PropTypes.any,
  currentData: PropTypes.string,
  pageNumber: PropTypes.any,
  lastPage: PropTypes.any,
  entry: PropTypes.bool
};
export default DownKeyController;
