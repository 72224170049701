const exportReducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return [
        {
          ...action.payload,
          id: state.length + 1,
          status: false,
          request_status: false
        },
        ...state
      ];

    case 'DELETE':
      return state.map(item =>
        item.id === action.payload.id ? { ...item, deleted: true } : item
      );

    case 'UPDATE':
      return state.map(item =>
        item.id === action.payload.id ? action.payload : item
      );

    case 'UPDATE-STATUS-BY-FILE-URL':
      return state.map(item =>
        item.file_url === action.payload.file_url ||
        item.file_name === action.payload.file_url
          ? { ...item, ...action.payload }
          : item
      );

    default:
      return state;
  }
};
export default exportReducer;
