import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import AppContext, { AuthWizardContext } from 'context/Context';
import axios from 'axios';
import getFormSettingsData, {
  contextSettingsTemplatesTargetKey
} from 'data/form-settings-data/formSettingsData';
import objectDeepClone from './objectDeepClone';

export default function RequireFormSettings({ children }) {
  let { user } = useContext(AuthWizardContext);
  const { config, setConfig } = useContext(AppContext);
  const branchId = user?.branch_id;
  const apiRoute = `settings/system/system-settings/form-settings-templates/${branchId}`;
  const formSettingsData = config?.[contextSettingsTemplatesTargetKey];

  useEffect(() => {
    const generateThisTypeDefaultTemplate = (
      type,
      defaultFormSettigsDeepClone
    ) => [
      {
        label: 'Default',
        value: objectDeepClone(defaultFormSettigsDeepClone?.[type]),
        active: true
      }
    ];

    async function init() {
      try {
        const defaultFormSettigs = getFormSettingsData('ALL');
        if (!defaultFormSettigs) return;

        const apiRequest = await axios.get(apiRoute);
        if (apiRequest?.status !== 200) return;
        const storedTemplates = apiRequest?.data?.data?.form_settings;

        let formSettingsTemplateResult;
        const defaultFormSettigsDeepClone = objectDeepClone(defaultFormSettigs);
        const defaultFormSettigsDeepCloneKeysList = Object.keys(
          defaultFormSettigsDeepClone
        );

        if (!Array.isArray(defaultFormSettigsDeepCloneKeysList)) return;

        if (
          typeof storedTemplates === 'string' &&
          storedTemplates?.length > 0
        ) {
          const storedTemplatesObjectResult = JSON.parse(storedTemplates);
          defaultFormSettigsDeepCloneKeysList.forEach(key => {
            if (!storedTemplatesObjectResult?.[key]) {
              storedTemplatesObjectResult[key] =
                generateThisTypeDefaultTemplate(
                  key,
                  defaultFormSettigsDeepClone
                );
            }
          });
          formSettingsTemplateResult = storedTemplatesObjectResult;
        } else {
          const newResult = Object.keys(defaultFormSettigsDeepClone)?.reduce(
            (result, key) => {
              result[key] = generateThisTypeDefaultTemplate(
                key,
                defaultFormSettigsDeepClone
              );

              return result;
            },
            {}
          );
          formSettingsTemplateResult = newResult;
        }

        setConfig(
          contextSettingsTemplatesTargetKey,
          formSettingsTemplateResult
        );
      } catch (error) {
        console.error('error', error);
      }
    }

    if (branchId) init();
  }, [contextSettingsTemplatesTargetKey, apiRoute, branchId]);

  return formSettingsData ? (
    children
  ) : (
    <div className="d-flex align-items-center justify-content-center w-100 p-3 h-100 position-fixed">
      <LoadingScreen />
    </div>
  );
}

RequireFormSettings.propTypes = {
  children: PropTypes.node
};
