import { React, useRef, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FileUploadItem from './FileUploadItem';
import { showToast } from 'module/Common/Toast/toast';

const FileController = ({
  error,
  note,
  onChange,
  className,
  limit,
  limitFeature = false,
  limitFileSizeInMB = 0,
  limitFileType = [],
  required,
  name,
  supportHandleFieldChange,
  labelName,
  buttonClassName
}) => {
  const Translate = useAxisproTranslate();
  const [filesArray, setFilesArray] = useState([]);
  const fileInputRef = useRef(null);
  const triggerFileInput = e => {
    e.preventDefault();
    fileInputRef && fileInputRef.current.click();
  };

  const getFileSizeAndUnit = file => {
    if (file) {
      let fileSizeInBytes = file.size;
      let fileSize = fileSizeInBytes;
      let unit = 'bytes';

      if (fileSizeInBytes >= 1024) {
        // convert to KB
        fileSize = (fileSize / 1024)?.toFixed(2);
        unit = 'KB';

        if (fileSize >= 1024) {
          // convert to MB
          fileSize = (fileSize / 1024)?.toFixed(2);
          unit = 'MB';
        }
      }

      return {
        fileSize: fileSize,
        fileSizeUnit: unit
      };
    }
    return null;
  };

  const handleFileUpload = e => {
    if (e.target.files.length > 0) {
      let refFilesArray = [];
      Object.keys(e.target.files).map(index => {
        if (
          !limit ||
          (limit > 0 &&
            refFilesArray.filter(item => item.isValid).length +
              filesArray.filter(item => item.isValid).length <
              limit)
        ) {
          let fileItem = e.target.files[index];
          let fileExtension = fileItem.name?.split('.')?.pop()?.toLowerCase();
          let fileSizeInMB = (fileItem.size / Math.pow(1024, 2)).toFixed(2);
          let fileIsValid = true;
          let fileErrors = [];
          if (limitFileSizeInMB > 0 && fileSizeInMB > limitFileSizeInMB) {
            fileIsValid = false;
            let errorMessage = Translate('File size is too large');
            fileErrors.push(errorMessage);
          }

          if (
            limitFileType &&
            Array.isArray(limitFileType) &&
            limitFileType.length > 0 &&
            !limitFileType.includes(fileExtension)
          ) {
            fileIsValid = false;
            let errorMessage = Translate(
              'Invalid type, this are the allowed file types'
            );
            fileErrors.push(`${errorMessage} ${limitFileType.join(',')}`);
          }

          const fileSizeDetails = getFileSizeAndUnit(fileItem);

          refFilesArray.push({
            file: fileItem,
            extension: fileExtension,
            sizeInMB: fileSizeInMB,
            ...fileSizeDetails,
            isValid: fileIsValid,
            errors: fileErrors
          });
        }
      });

      if (Object.keys(e.target.files).length + filesArray.length > limit) {
        let errorMessage = Translate(
          'You have exceeded the file upload limit, the maximum limit allowed is'
        );
        showToast(`${errorMessage} ${limit}.`, 'error');
      }

      // reset file input
      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      let newArrayResult = refFilesArray.concat(filesArray);
      setFilesArray(newArrayResult);

      const onChangeResult = newArrayResult.reduce((initalArray, item) => {
        if (item.isValid) {
          initalArray.push(item.file);
        }
        return initalArray;
      }, []);

      onChange(
        supportHandleFieldChange
          ? { target: { name: name || 'files', value: onChangeResult } }
          : onChangeResult
      );
    }
  };

  return (
    <div className={`file-upload-cover ${className}`}>
      <Form.Group controlId="cashierForm.ControlInput7">
        <Form.Label>
          {Translate(labelName ? labelName : 'Attach Files')}
          <span className={required ? 'require-data' : ''}></span>
        </Form.Label>
        <div className="d-flex flex-row">
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={triggerFileInput}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              border: '1px dashed'
            }}
            className={`attachment-handler-btn-focus-style ${
              buttonClassName || ''
            }`}
          >
            <FontAwesomeIcon icon="upload" size="xs" />
          </Button>
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-basic"
              size="sm"
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                border: '1px dashed'
              }}
              className={`attachment-handler-btn-focus-style ${
                buttonClassName || ''
              }`}
            >
              {Translate('Upload File')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={triggerFileInput}>
                {Translate('Attach from Desktop')}
              </Dropdown.Item>
              {!limitFeature && (
                <>
                  <Dropdown.Item href="#/action-2">
                    {Translate('Attach from Cloud')}
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    {Translate('Attach from Documents')}
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Form.Control
          multiple
          type="file"
          rows={5}
          name="file"
          onChange={handleFileUpload}
          isInvalid={!!error}
          ref={fileInputRef}
          hidden
        />
        <small className="text-muted">{Translate(note)}</small>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>

      {filesArray.length > 0 && (
        <Form.Group
          className="mt-3"
          style={{ width: '100%', maxWidth: '600px' }}
        >
          {filesArray.map((fileItem, key) => (
            <FileUploadItem
              fileItem={fileItem}
              key={key}
              index={key}
              setFilesArray={setFilesArray}
              onChange={onChange}
              limitFeature={limitFeature}
              name={name}
              supportHandleFieldChange={supportHandleFieldChange}
            />
          ))}
        </Form.Group>
      )}
    </div>
  );
};

FileController.propTypes = {
  error: PropTypes.any,
  note: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  limit: PropTypes.number,
  limitFeature: PropTypes.bool,
  limitFileSizeInMB: PropTypes.number,
  limitFileType: PropTypes.array,
  required: PropTypes.any,
  name: PropTypes.string,
  supportHandleFieldChange: PropTypes.bool,
  labelName: PropTypes.string,
  buttonClassName: PropTypes.string
};

export default FileController;
