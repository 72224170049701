import { React, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { showToast } from 'module/Common/Toast/toast';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import BatchTabView from 'module/Common/ViewPages/Batch/BatchTabView';

function BatchNumberInfo({
  batchId,
  rendering,
  firstId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async isLoading => {
    if (!rendering) {
      if (firstId !== undefined) {
        let url = `inventory/batch/${firstId}`;
        setTimeout(
          await axios
            .get(url)
            .then(res => {
              if (res.data.success) {
                setData(res.data.data);
                setLoading(false);
              }
            })
            .catch(error => {
              if (error) {
                showToast(
                  'Something went wrong! Please contact admin',
                  'error'
                );
              }
            }),
          1000
        );
      }
    } else {
      if (batchId !== undefined) {
        if (isLoading) {
          setLoading(true);
        }
        let url = `inventory/batch/${batchId}`;
        await axios
          .get(url)
          .then(res => {
            if (res.data.success) {
              setData(res.data.data);
              setLoading(false);
            }
          })
          .catch(error => {
            if (error) {
              showToast('Something went wrong! Please contact admin', 'error');
            }
          });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      fetchData(loading);
    }, 400);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [batchId, firstId]);

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : (
        <BatchTabView
          batchData={data}
          loading={loading}
          fetchData={fetchData}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          offCanvas={offCanvas}
          voidTransactionReport={voidTransactionReport}
        />
      )}
    </>
  );
}

BatchNumberInfo.propTypes = {
  batchId: PropTypes.string,
  firstId: PropTypes.string,
  rendering: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool
};

export default BatchNumberInfo;
