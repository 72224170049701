import React from 'react';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import DataFetching from 'module/Report/LedgerReport/DataFetching';

export default function WorkOrderTabDataInfiniteScrollHandler({
  isLoading,
  current_page,
  last_page,
  loadNextPage,
  nextLink
}) {
  const Translate = useAxisproTranslate();
  return (
    <>
      {isNaN(current_page) || isNaN(last_page) ? null : isLoading ? (
        <p className="m-0 text-center">{Translate('Loading...')}</p>
      ) : current_page === last_page ? (
        <p className="m-0 text-center">{Translate('You have seen it all.')}</p>
      ) : (
        <DataFetching nextLink={nextLink} loadNextPage={loadNextPage} />
      )}
    </>
  );
}

WorkOrderTabDataInfiniteScrollHandler.propTypes = {
  isLoading: PropTypes.bool,
  current_page: PropTypes.number,
  last_page: PropTypes.number,
  nextLink: PropTypes.string,
  loadNextPage: PropTypes.func
};
