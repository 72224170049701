import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoMdAddCircle } from 'react-icons/io';
import PropTypes from 'prop-types';

const PopupFormButton = ({ children, style, ...rest }) => {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="bottom"
      overlay={<Tooltip>{children}</Tooltip>}
    >
      <Button
        variant="transparent"
        className="text-primary p-0 m-0"
        {...rest}
        style={{ outline: 'none', boxShadow: 'none', ...style }}
      >
        <IoMdAddCircle size={20} />
      </Button>
    </OverlayTrigger>
  );
};

PopupFormButton.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object
};

export default PopupFormButton;
