import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import tabViewContentReloadHandlerReducer from '../reducers/tabViewContentReloadHandlerReducer';
import { TabViewContentReloadHandlerContext } from 'context/Context';

export default function TabViewContentReloadHandlerContextProvider({
  children
}) {
  const [
    TabViewContentReloadHandlerContextData,
    tabViewContentReloadHandlerContextDispatch
  ] = useReducer(
    tabViewContentReloadHandlerReducer,
    useContext(TabViewContentReloadHandlerContext)
  );

  return (
    <TabViewContentReloadHandlerContext.Provider
      value={{
        TabViewContentReloadHandlerContextData,
        tabViewContentReloadHandlerContextDispatch
      }}
    >
      {children}
    </TabViewContentReloadHandlerContext.Provider>
  );
}

TabViewContentReloadHandlerContextProvider.propTypes = {
  children: PropTypes.any.isRequired
};
