import React from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsHandbagFill } from 'react-icons/bs';
import { IoMdCheckmarkCircle } from 'react-icons/io';

import SelectBatch from 'module/Common/Search/SelectBatch';

function KitLineItemForm({
  entry,
  index,
  quantity,
  addToCart,
  kit,
  setModifiedKitFullDetails,
  quantityChange = false,
  batch
}) {
  const handleFocus = e => e.target.select();
  const handleQuantityChange = (e, data, index) => {
    const prevData = kit.kit_items[index];
    if (prevData) {
      data.base_quantity_ref = e.target.value;
      data.quantity = e.target.value * quantity;
      kit[index] = data;
      setModifiedKitFullDetails(prev => ({
        ...prev,
        ...kit
      }));
    }
  };

  const handleBatch = (valueObject, entry, index) => {
    if (valueObject.value !== entry.batch_number) {
      let batchNumber =
        valueObject && valueObject.value ? valueObject.value : null;
      const prevData = kit.kit_items[index];
      if (prevData) {
        entry.batch_number = batchNumber;
        entry.batch_number_ref = valueObject;
        kit[index] = entry;
        setModifiedKitFullDetails(prev => ({
          ...prev,
          ...kit
        }));
      }
    }
  };
  return (
    <tr>
      <td className="fs--1 text-dark p-1 text-center">{index + 1}</td>
      <td className="fs--1 text-dark p-1 text-center">{entry.stock_id}</td>
      <td className="fs--1 text-dark p-1 text-center">
        <div className="d-flex flex-row justify-content-between align-items-center">
          {entry.item_display_name}
          {parseInt(entry.is_kit) && !entry.kit_id ? (
            <BsHandbagFill style={{ fill: 'red' }} size={11} className="mx-2" />
          ) : null}
        </div>
      </td>
      {batch ? (
        ''
      ) : (
        <td className="p-1 fs--1 text-dark text-center">
          {(entry.stock_id && entry.trans_date && entry?.has_batch_inventory) ||
          entry.batch_number ? (
            <SelectBatch
              value={entry.batch_number_ref}
              handleFieldChange={e => handleBatch(e, entry, index)}
              name={entry.batch_number_ref}
              itemId={entry.stock_id}
              trans_date={entry.trans_date}
            />
          ) : (
            '-'
          )}
        </td>
      )}
      <td className="fs--1 text-dark p-1 text-center">
        {entry.unit_ref ?? entry.unit_name}
      </td>
      <td className="fs--1 text-dark p-1">
        {quantityChange ? (
          <Form.Control
            type="number"
            min={1}
            value={entry.base_quantity_ref}
            onChange={e => handleQuantityChange(e, entry, index)}
            onFocus={handleFocus}
          />
        ) : (
          entry.base_quantity_ref
        )}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {entry.base_quantity_ref * quantity}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {entry.unit_price_formatted ?? '-'}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {entry.unit_tax_formatted ?? '-'}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {entry.discount_amount < 1 ? (
          '-'
        ) : entry.discount_unit_ref === '%' ? (
          <>{entry.discount_percent}%</>
        ) : (
          <>{entry.discount_amount_formatted ?? '-'}</>
        )}
      </td>
      <td className="fs--1 text-dark p-1 fw-bolder text-center">
        {entry.quantity && entry.unit_tax
          ? (
              parseFloat(entry.quantity) * parseFloat(entry.unit_price) +
              parseFloat(entry.quantity) * parseFloat(entry.unit_tax) -
              parseFloat(entry.quantity) * parseFloat(entry.discount_amount)
            ).toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          : '-'}
      </td>
      <td className="p-1">
        <div className="d-flex flex-row align-items-center justify-content-center p-1">
          <Button
            size="sm"
            variant="transparent"
            className={`p-0 text-success`}
            style={{ boxShadow: 'none' }}
            onClick={() => addToCart(entry, index)}
          >
            <IoMdCheckmarkCircle size={20} />
          </Button>
        </div>
      </td>
    </tr>
  );
}

KitLineItemForm.propTypes = {
  index: PropTypes.number,
  entry: PropTypes.object,
  quantity: PropTypes.any,
  addToCart: PropTypes.any,
  handleQuantityChange: PropTypes.any,
  kit: PropTypes.any,
  setModifiedKitFullDetails: PropTypes.any,
  quantityChange: PropTypes.bool,
  batch: PropTypes.bool
};

export default KitLineItemForm;
