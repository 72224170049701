import axios from 'axios';
import React from 'react';

export default function setCustomFields({ formSlug, setCustomFieldItems }) {
  return new Promise((resolve, reject) => {
    if (typeof formSlug !== 'string' || formSlug.length < 1) {
      reject('Invalid form slug, please provide a valid form slug');
    }

    axios
      .get('/settings/custom-fields/find-by-form-slug/' + formSlug)
      .then(repsonse => {
        if (typeof setCustomFieldItems === 'function')
          setCustomFieldItems(repsonse?.data?.data);
        resolve(repsonse?.data?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function setCustomFieldsForApiResult(customFieldsData) {
  let result = {};
  if (Array.isArray(customFieldsData)) {
    customFieldsData.forEach(customFieldItem => {
      if (customFieldItem?.custom_field?.id) {
        const customFieldName = `custom_fields[${customFieldItem.custom_field.id}]`;
        result[customFieldName] = customFieldItem?.value;
        if (customFieldItem.custom_field?.column_type === 'select') {
          result[customFieldName + '_ref'] = {
            label: customFieldItem?.value,
            value: customFieldItem?.value
          };
        }
      }
    });
  }

  return result;
}

export function setCustomFieldItemsTableHeaders({
  customFieldItems,
  Translate
}) {
  let result = [];
  if (Array.isArray(customFieldItems)) {
    customFieldItems.forEach(item => {
      if ([true, 1, '1'].includes(item?.show_in_table)) {
        result.push({
          accessor: 'customField.' + item?.id,
          Header: Translate(item?.label),
          headerProps: { className: 'pe-1' },
          cellProps: {
            className: 'py-0'
          },
          Cell: rowData => {
            const customFieldDetails =
              rowData.row.original?.custom_field_values?.find(
                customFieldData => customFieldData?.custom_field_id === item?.id
              );
            return <h5 className="mb-0 fs--1">{customFieldDetails?.value}</h5>;
          }
        });
      }
    });
  }
  return result;
}
