import { React } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

function RevisionTable({ transaction }) {
  const Translate = useAxisproTranslate();

  return (
    <Table
      striped
      bordered
      hover
      responsive
      className="mt-2 simple-table-animation"
    >
      <thead className="bg-dark text-light text-uppercase">
        <tr>
          <th className="fs--2 p-2 text-center">{Translate('Revision')}</th>
          <th className="fs--2 p-2 text-center">{Translate('Date')}</th>
          <th className="fs--2 p-2 text-center">{Translate('Reference')}</th>
          <th className="fs--2 p-2 text-center">{Translate('Created At')}</th>
          <th className="fs--2 p-2 text-center">{Translate('Created By')}</th>
        </tr>
      </thead>
      {transaction?.length > 0 &&
        transaction.map((item, key) => (
          <tbody key={key} className="text-dark">
            <tr>
              <td className="p-2 text-center">{item?.revision ?? ' - '}</td>
              <th className="p-2 text-center">
                {item?.data?.trans_date_formatted ?? ' - '}
              </th>
              <th className="p-2 text-center">
                {item?.data?.reference ?? ' - '}
              </th>
              <th className="p-2 text-center text-uppercase">
                {item?.created_at ?? ' - '}
              </th>
              <th className="p-2 text-center">{item?.created_by ?? ' - '}</th>
            </tr>
          </tbody>
        ))}
    </Table>
  );
}

RevisionTable.propTypes = {
  transaction: PropTypes.object
};

export default RevisionTable;
