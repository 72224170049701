import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiCall } from 'helpers/apiCalls';
import { Card } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

import TableHeader from 'helpers/tableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AppPagination from 'components/common/app-pagination/AppPagination';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { HiDownload } from 'react-icons/hi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Filter from 'module/Common/TableFilter/Filter';

function NotificationList() {
  const { pageNumber } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState();

  const fetchData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: '/notifications',
      params: {
        page: page
      }
    });

    setData(data);
    if (data && data.meta && data.meta.per_page) {
      setRowsPerPage(data.meta.per_page);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(pageNumber);
  }, [pageNumber]);

  const handleDownload = (url, name) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = name;
    link.click();
  };

  const columns = [
    {
      Header: '#',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        // const index = rowData.row.index + 1;
        const index =
          parseInt(pageNumber ?? 1) * rowsPerPage -
          rowsPerPage +
          rowData.row.index +
          1;
        return <h5 className="mb-0 fs--1">{index}</h5>;
      }
    },
    {
      accessor: 'description',
      Header: 'Description',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { data } = rowData.row.original;
        if (data.download_link) {
          const startIndex = data.download_link.lastIndexOf('/') + 1;
          const endIndex = Math.max(
            data.download_link.lastIndexOf('.pdf'),
            data.download_link.lastIndexOf('.xlsx')
          );
          let name = data.download_link.substring(startIndex, endIndex);
          const modifiedString = name
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
          return (
            <h5 className="mb-0 fs--1">
              {data.body} {'  '}
              <HiDownload
                size={18}
                color={'#2C7BE5'}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  handleDownload(data.download_link, modifiedString)
                }
              />
            </h5>
          );
        }
        return <h5 className="mb-0 fs--1">{data.body}</h5>;
      }
    },
    {
      accessor: 'time',
      Header: 'Time',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { time } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{time}</h5>;
      }
    }
  ];

  return (
    <>
      {!isLoading && data.data && data.data.length > 0 ? (
        <AdvanceTableWrapper
          columns={columns}
          data={data.data}
          sortable
          pagination
          perPage={30}
        >
          <Card className="flex-fill mb-3">
            <Card.Header className="d-flex">
              <FontAwesomeIcon
                icon="bell"
                // transform="shrink-6"
                className="fs-4"
              />
              <TableHeader title="Notifications" table />
            </Card.Header>
            <Filter moduleName="notification_filter" />
            <SimpleBar className="simplebar-style-with-pagination">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
            </SimpleBar>
            <Card.Footer>
              {data && data.links && (
                <AppPagination data={data} url="/notifications/" />
              )}
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <Card className="d-flex flex-fill mb-3 align-items-center justify-content-center">
          <Card.Body className="d-flex my-auto">
            <div className="my-auto">
              {isLoading ? (
                <LoadingScreen message="Looking for notifications" />
              ) : (
                <h5 className="text-center text-secondary">
                  No Notifications Found &#128578;
                </h5>
              )}
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default NotificationList;
