import { React } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import JournalTable from '../Common/Tables/JournalTable';

function BankTransferView({ BankTransferVoucherData }) {
  const Translate = useAxisproTranslate();

  return (
    <Container className="mt-3 pe-0 ps-0 voucher-inquiry-view-wrapper">
      <Row className="ms-1">
        <Col lg={6} md={6} sm={12} className="p-0">
          <Badge bg="danger" className="p-1">
            {BankTransferVoucherData &&
              BankTransferVoucherData.reference &&
              BankTransferVoucherData.reference}
          </Badge>
          <div className="mt-2">
            <Row>
              <Col xs={4}>
                <h6 className="mb-2">{Translate('Payment From')}</h6>
              </Col>
              <Col xs={8}>
                <h6 className="mb-2 text-uppercase">
                  {BankTransferVoucherData &&
                  BankTransferVoucherData.from_bank_account_name
                    ? BankTransferVoucherData.from_bank_account_name
                    : 'Update Bank'}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <h6 className="mb-2 mt-2">{Translate('Received To')}</h6>
              </Col>
              <Col xs={8}>
                <h6 className="mb-2 mt-2 text-uppercase">
                  {BankTransferVoucherData &&
                  BankTransferVoucherData.to_bank_account_name
                    ? BankTransferVoucherData.to_bank_account_name
                    : 'Update Bank'}
                </h6>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {BankTransferVoucherData && (
        <JournalTable journalData={BankTransferVoucherData} bt />
      )}
      {BankTransferVoucherData && BankTransferVoucherData.memo ? (
        <Row
          style={{ border: '1px dashed grey' }}
          className="pt-2 pb-2 pe-1 ps-1 ms-1 me-1"
        >
          <Col xs={12} className="p-0">
            <h6 className="mb-2 fw-bold">{Translate('Notes')} :</h6>
          </Col>
          <Col xs={12} className="ms-3">
            <h6 className="mb-2">{BankTransferVoucherData.memo}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </Container>
  );
}

BankTransferView.propTypes = {
  BankTransferVoucherData: PropTypes.any
};

export default BankTransferView;
