import { React, useState } from 'react';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ItemDetails from 'components/item-details/ItemDetails';
import useAxisproPermission from 'hooks/useAxisproPermission';

const BatchItemView = ({ batchData }) => {
  const Translate = useAxisproTranslate();
  const [value, setValue] = useState({ value: 0 });
  const axisProPermission = useAxisproPermission();
  const [showItemDetailsArea, setShowItemDetailsArea] = useState(false);
  const clickOnItem = id => {
    setValue({ value: id });
    setShowItemDetailsArea(true);
  };

  const handleItemDetailsArea = () => {
    setShowItemDetailsArea(!showItemDetailsArea);
    setValue({ value: 0 });
  };
  return (
    <>
      {batchData?.items?.length ? (
        <Table
          striped
          bordered
          hover
          responsive
          className="p-0 simple-table-animation m-3"
        >
          <thead className="bg-dark text-light text-uppercase">
            <tr>
              <th className="fs--2 p-2 text-center">#</th>
              <th className="fs--2 p-2 text-center">
                {Translate('ITEM CODE')}
              </th>
              <th className="fs--2 p-2 text-center">
                {Translate('ITEM NAME')}
              </th>
              <th className="fs--2 p-2 text-center">
                {Translate('PRODUCT TYPE')}
              </th>
            </tr>
          </thead>

          <tbody className="text-dark">
            {batchData.items.map((items, index) => (
              <tr key={index}>
                <th className="p-2 text-center">{index + 1}</th>
                <th className="p-2 text-center  cursor-pointer">
                  {axisProPermission('show-item') ? (
                    <a
                      href="#"
                      onClick={() => {
                        clickOnItem(items.id);
                      }}
                    >
                      {items.stock_id}
                    </a>
                  ) : (
                    items.stock_id
                  )}
                </th>
                <th className="p-2 text-center">{items.item_name}</th>
                <th className="p-2 text-center">{items.product_type}</th>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div
          style={{ height: '40vh' }}
          className="d-flex align-items-center justify-content-center"
        >
          <h5 className="m-0 text-muted fw-bold">
            {Translate('Nothing to Display!')}
          </h5>
        </div>
      )}
      <ItemDetails
        show={showItemDetailsArea}
        onHide={handleItemDetailsArea}
        value={value}
        showItemDetailsArea={showItemDetailsArea}
      />
    </>
  );
};
BatchItemView.propTypes = {
  batchData: PropTypes.any
};
export default BatchItemView;
