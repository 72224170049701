import axios from 'axios';
import { ContractTenure } from 'module/Common/Enum/enum';
import { showToast } from 'module/Common/Toast/toast';
const getRenatalContractTenureValue = value => {
  return ContractTenure.find(item => item?.value === value);
};
const fetchCustomer = async (setFormData, customer_id, setLoading) => {
  try {
    setLoading(true);
    const { data } = await axios.get(`sales/customers/${customer_id}`);
    let customerDetails = data?.data;
    setFormData(prev => ({
      ...prev,
      ...(customerDetails?.sales_type_id
        ? {
            sales_type_id: customerDetails.sales_type_id,
            sales_type_id_ref: {
              label: customerDetails.sales_type_name,
              value: customerDetails.sales_type_id
            }
          }
        : {
            sales_type_id: '',
            sales_type_id_ref: ''
          })
    }));

    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
const fetchRentalDetails = async (
  rentalId,
  setFormData,
  setIsLoading,
  renewed
) => {
  try {
    setIsLoading(true);
    const { data } = await axios.get(`sales/rental-contracts/${rentalId}`);
    let rentalDetails = data?.data;
    setFormData(prev => ({
      ...prev,
      // _method: 'PUT',
      title: rentalDetails?.title,
      customer_id: rentalDetails?.customer_id,
      description: rentalDetails?.description,
      customer_id_ref: {
        label: rentalDetails?.customer_name,
        value: rentalDetails?.customer_id
      },
      sales_type_id: rentalDetails?.sales_type_id,
      sales_type_id_ref: {
        label: rentalDetails?.sales_type,
        value: rentalDetails?.sales_type_id
      },
      contract_tenure: rentalDetails?.contract_tenure,
      contract_tenure_ref: getRenatalContractTenureValue(
        rentalDetails?.contract_tenure ?? ''
      ),
      // customer_documents:rentalDetails?.customer_document,

      ...(!renewed
        ? {
            contract_date: rentalDetails?.contract_date,
            contract_from_date: rentalDetails?.contract_from_date,
            contract_to_date: rentalDetails?.contract_to_date
          }
        : {
            previous_contract_id: rentalId,
            status: 'renewed'
          }),
      item: rentalDetails.details[0]?.item_id,
      item_ref: {
        label: rentalDetails.details[0]?.item_display_name,
        value: rentalDetails.details[0]?.item_id
      },
      unit_price: rentalDetails.details[0]?.unit_price,
      fixed_assets_type: 'vehicle',
      fixed_assets_type_ref: {
        label: 'VEHICLE',
        value: 'vehicle'
      },
      details: rentalDetails?.details.map(items => ({
        description: items.description,
        stock_id: items?.stock_id,
        // id: items?.item_id,
        item_display_name: items?.item_display_name,
        unit_price: items?.unit_price
        // item: items?.item_id,
        // item_ref: {
        //   label: items?.item_display_name,
        //   value: items?.item_id
        // }
      }))
    }));
    setIsLoading(false);
    return rentalDetails;
  } catch (error) {
    setIsLoading(false);
    showToast('Something Went Wrong', 'error');
    console.log(error);
  }
};
const handleContractToDate = (fromDate, tenure, setFromData) => {
  const getFormattedDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  let toDate = new Date(fromDate);
  if (tenure === 'daily') {
    toDate.setDate(toDate.getDate() + 1);
  } else if (tenure === 'weekly') {
    toDate.setDate(toDate.getDate() + 7);
  } else if (tenure === 'monthly') {
    toDate.setMonth(toDate.getMonth() + 1);
  } else if (tenure === 'yearly') {
    toDate.setFullYear(toDate.getFullYear() + 1);
  }
  const formattedDate = getFormattedDate(toDate);
  setFromData(prev => ({
    ...prev,
    contract_to_date: formattedDate ?? ''
  }));
};
const fetchData = async (
  voidTransactionReport,
  rentalId,
  setLoading,
  setData,
  signal
) => {
  let url = voidTransactionReport
    ? 'settings/void-transaction-details'
    : `sales/rental-contracts/${rentalId}`;
  await axios
    .get(url, {
      params: {
        ...(voidTransactionReport && {
          trans_type: 'RCT',
          trans_no: rentalId
        })
      },
      signal: signal
    })
    .then(res => {
      if (res.data.success) {
        setData(res.data.data);
        setLoading(false);
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export default {
  fetchCustomer,
  fetchRentalDetails,
  handleContractToDate,
  fetchData
};
