import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AiOutlineSend } from 'react-icons/ai';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';

function SendEmail({ url, showEmailModal, handleCancel, data }) {
  const Translate = useAxisproTranslate();
  const [onSendEmail, setOnSendEmail] = useState(false);
  const [formData, setFormData] = useState();
  const [validationError, setValidationError] = useState(false);
  const [validationErrorMsg, setValidationErrorMsg] = useState(false);

  const sendEmail = () => {
    if (formData?.email) {
      setOnSendEmail(true);
      axios
        .get(url, {
          params: { ...formData }
        })
        .then(response => {
          showToast(response.data.message, 'success');
          setFormData({ email: data });
          handleCancel();
          setOnSendEmail(false);
        })
        .catch(error => {
          setOnSendEmail(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors &&
            error.response.data.data.errors.email
          ) {
            setValidationError(true);
            setValidationErrorMsg(error.response.data.data.errors.email);
            setOnSendEmail(false);
          }
        });
    } else {
      setValidationError(true);
      setValidationErrorMsg('Email is required');
    }
  };
  const handleFieldChange = e => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };
  const closeModel = () => {
    setFormData({ email: data });
    setValidationError(false);
    handleCancel();
  };

  useEffect(() => {
    setFormData({ email: data });
  }, [data]);

  return (
    <Modal
      show={showEmailModal ? true : false}
      className="modal-with-overlay"
      backdrop="static"
    >
      <Card>
        <Card.Header className="fs-1 text-uppercase text-dark d-flex align-items-center border-bottom">
          <AiOutlineSend className="me-1" />
          {Translate('send email')}
        </Card.Header>
        <Card.Body>
          {data && data !== '' ? (
            ''
          ) : (
            <p className="text-dark mb-2">
              {Translate(
                'The user does not have an email address. Please update the email address'
              )}
            </p>
          )}
          <Form.Group md={12} sm={12} className="mb-1">
            <div>{Translate('Email')}</div>
            <Form.Control
              type="email"
              name="email"
              required="true"
              value={formData?.email}
              onChange={handleFieldChange}
            />
            {validationError ? (
              <p className="text-danger">{validationErrorMsg}</p>
            ) : (
              ''
            )}
          </Form.Group>
          <Form.Group md={12} sm={12} className="mb-1 mt-3">
            <div>{Translate('Notes')}</div>
            <Form.Control
              as="textarea"
              rows={3}
              name="note"
              className="w-100"
              value={formData?.note}
              onChange={handleFieldChange}
            />
          </Form.Group>
        </Card.Body>
        <Card.Footer className="d-flex gap-1">
          <Button
            size="sm"
            className="ms-auto"
            variant="success"
            disabled={onSendEmail}
            onClick={sendEmail}
          >
            {onSendEmail ? (
              <span className="fs--1">{Translate('Sending')}...</span>
            ) : (
              <span>{Translate('Send Email')}</span>
            )}
          </Button>
          <Button variant="danger" size="sm" onClick={closeModel}>
            {Translate('Cancel')}
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
}

SendEmail.propTypes = {
  url: PropTypes.string,
  showEmailModal: PropTypes.bool,
  handleCancel: PropTypes.func,
  data: PropTypes.any
};

export default SendEmail;
