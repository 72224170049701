import React from 'react';
import SelectSupplier from 'components/form/SelectSupplier';

const commonFilterOperators = [
  { name: 'EQUAL', value: '=' },
  { name: 'LIKE', value: 'LIKE' }
];

const purchaseCommonFilters = {
  supplier_id: {
    label: 'Supplier Name',
    operators: commonFilterOperators,
    active: true,
    Element: item =>
      item && item.operator === '=' ? (
        <div className="w-100">
          <SelectSupplier
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        'STRING'
      )
  },
  trans_date: {
    label: 'Date',
    type: 'date',
    active: true,
    operators: [
      { name: 'EQUAL', value: '=' },
      { name: 'GREATER THAN', value: '>' },
      { name: 'LESS THAN', value: '<' },
      { name: 'BETWEEN', value: 'BETWEEN' }
    ],
    Element: 'DATE'
  },
  reference: {
    label: 'Reference',
    active: true,
    operators: commonFilterOperators
  }
};

export const supplierCommonFilters = {
  id: {
    label: 'Supplier Name',
    operators: commonFilterOperators,
    active: true,
    Element: item =>
      item && item.operator === '=' ? (
        <div className="w-100">
          <SelectSupplier
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        'STRING'
      )
  },
  supplier_code: {
    label: 'Supplier Code',
    operators: commonFilterOperators,
    active: true
  },
  name: {
    label: 'Supplier Name',
    active: false,
    operators: commonFilterOperators
  },
  email_address: {
    label: 'Email',
    active: true,
    operators: commonFilterOperators
  },
  contact_number: {
    label: 'Contact Number',
    active: true,
    operators: commonFilterOperators
  }
};

export default purchaseCommonFilters;
