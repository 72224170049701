import { StoreContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import { useContext } from 'react';

const useAcodaxRoundOff = () => {
  const { store } = useContext(StoreContext);
  const roundOffPrecision =
    store?.globalConfiguration?.round_off_precision ?? 0;
  const roundOffMaxLimit = store?.globalConfiguration?.round_off_max_limit ?? 0;
  const roundOffAlgorithm =
    store?.globalConfiguration?.round_off_algorithm ?? 'auto';

  const acodaxRoundOffAmount = amount => {
    const sanitizedAmount = amount
      ? parseFloat(amount.toString().replace(/,/g, ''))
      : 0;
    if (isNaN(sanitizedAmount)) {
      showToast('Amount must be a valid number', 'error');
    }
    let roundedAmount;
    if (roundOffMaxLimit != 0) {
      if (roundOffPrecision != 0) {
        roundedAmount =
          roundOffAlgorithm === 'up'
            ? Math.ceil(sanitizedAmount / roundOffPrecision) * roundOffPrecision
            : roundOffAlgorithm === 'down'
            ? Math.floor(sanitizedAmount / roundOffPrecision) *
              roundOffPrecision
            : Math.round(sanitizedAmount);
        // : Math.round(amount / roundOffPrecision) * roundOffPrecision;
        let roundedAmountDifference = roundedAmount - sanitizedAmount;
        if (
          Math.abs(roundedAmountDifference) > roundOffMaxLimit &&
          roundOffMaxLimit != 0
        ) {
          showToast(
            `Rounding amount is greater than system rounding limit ${roundOffMaxLimit}`,
            'error'
          );
          roundedAmount = sanitizedAmount;
        }
      } else {
        roundedAmount = sanitizedAmount;
      }
    } else {
      roundedAmount = sanitizedAmount;
    }
    return roundedAmount;
  };

  return acodaxRoundOffAmount;
};

export default useAcodaxRoundOff;
