import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { IoMdArrowBack } from 'react-icons/io';

function Error403Popup({ show, onHide }) {
  const navigate = useNavigate();

  const handleClick = () => {
    onHide();
    navigate('/dashboard');
  };
  const handleBack = () => {
    navigate(-1);
    onHide();
  };

  return (
    <Modal
      show={show}
      size="lg"
      className="modal-with-overlay"
      centered
      backdrop="static"
      animation
    >
      <Card>
        <Card.Body className="p-5 d-flex flex-column align-items-center justify-content-center">
          <div className="my-auto d-flex flex-column align-items-center justify-content-center">
            <div className="display-1 text-300 fs-error">403</div>
            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
              Permission denied!
            </p>
          </div>
          <div className="mt-auto d-flex flex-column align-items-center justify-content-center">
            <hr />
            <p className="m-0 text-center">
              You do not have permission to access this page, contact your
              system administrator.
            </p>
            <div className="d-flex">
              <Button
                className="btn btn-primary btn-sm mt-3"
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faHome} className="me-2" />
                Take me home
              </Button>
              <Button
                className="btn btn-danger btn-sm mt-3 ms-2"
                onClick={handleBack}
              >
                <IoMdArrowBack size={18} className="me-2" />
                Back
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  );
}

Error403Popup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func
};

export default Error403Popup;
