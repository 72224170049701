import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import React from 'react';
import { Outlet } from 'react-router-dom';

export default function DebitNoteOutlet() {
  const permissions = {
    show_dn: getAcodaxPermissionSlug('DN', 'show'),
    create_dn: getAcodaxPermissionSlug('DN', 'create'),
    update_dn: getAcodaxPermissionSlug('DN', 'update'),
    show_sp: getAcodaxPermissionSlug('supplier', 'show')
  };
  return <Outlet context={[permissions]} />;
}
