import axios from 'axios';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import PopupFormButton from 'components/popup-form-button/PopupFormButton';
import { itemPopupFormKeys } from 'helpers/formKeys';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { BsBox } from 'react-icons/bs';
import SelectUnit from '../SelectUnit';
import SelectCategory from '../SelectCategory';
import SelectTaxGroup from '../SelectTaxGroup';
import SelectAccountDescriptions from '../SelectAccountDescriptions';
import { FaBarcode } from 'react-icons/fa';
import { apiCall } from 'helpers/apiCalls';
import { AuthWizardContext } from 'context/Context';
import removeRefData from 'helpers/removeRefData';
import setItemPopupFormCategoryRelatedDetails from 'helpers/setItemPopupFormCategoryRelatedDetails';

const ItemPopupForm = ({
  callBack,
  setData,
  labelName,
  keyName,
  name,
  showAddButton,
  onHide,
  show,
  formDefaultProductType
}) => {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const systemSettings = user?.branch?.system_settings;
  const [showWindow, setShowWindow] = useState(show ?? false);
  const [stockIDIsSetting, setStockIDIsSetting] = useState(false);
  const [formData, setFormData] = useState(itemPopupFormKeys);
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const handleShow = () => {
    setShowWindow(!showWindow);
    if (show) {
      setFormData(itemPopupFormKeys);
      setFormError({});
      setIsSaving(false);
      if (onHide && typeof onHide === 'function') onHide();
    }
  };
  const handleFieldChange = (e, action) => {
    let keyName = !action ? e.target.name : action?.name;
    if (keyName === 'product_type' && formDefaultProductType) {
      return;
    }
    let generatedData = generateFormDataHelper(e, action, formData);

    if (
      e &&
      action &&
      [
        'sales_account_code',
        'purchase_account_code',
        'inventory_account_code',
        'wip_account_code',
        'wip_crediting_account_code'
      ].includes(keyName)
    ) {
      generatedData[keyName] = e?.code ?? '';
    }

    if (keyName === 'category_id') {
      generatedData = setItemPopupFormCategoryRelatedDetails(
        generatedData,
        e?.category_details
      );
    }
    setFormData(generatedData);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setIsSaving(true);

    let dataWithoutRefKeys = removeRefData(formData, 'object');

    if (['goods', 'service'].includes(dataWithoutRefKeys?.product_type)) {
      delete dataWithoutRefKeys.wip_account_code;
    }

    if (['goods'].includes(dataWithoutRefKeys?.product_type)) {
      delete dataWithoutRefKeys.wip_crediting_account_code;
    }

    if (['service'].includes(dataWithoutRefKeys?.product_type)) {
      delete dataWithoutRefKeys.purchase_account_code;
    }

    axios({
      method: 'post',
      url: 'inventory/items',
      data: dataWithoutRefKeys
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          if (callBack) {
            callBack(response.data.data);
          }

          if (response?.data?.data && setData) {
            let itemData = response.data.data;
            setData(
              {
                label: itemData?.[labelName ?? 'name'],
                value: itemData?.[keyName ?? 'id'],
                stock_id: itemData?.stock_id,
                type: 'item',
                item: {
                  has_batch_inventory: itemData?.has_batch_inventory,
                  id: itemData?.id,
                  item_name: itemData?.item_name,
                  purchase_rate: itemData?.purchase_rate,
                  purchase_rate_formatted: itemData?.purchase_rate_formatted,
                  sale_rate: itemData?.sale_rate,
                  sale_rate_formatted: itemData?.sale_rate_formatted,
                  stock_id: itemData?.stock_id,
                  type: 'item',
                  unit_name: itemData?.unit_name,
                  tax_group_id: itemData?.tax_group_id,
                  tax_group_name: itemData?.tax_group_name
                }
              },
              {
                action: 'select-option',
                option: undefined,
                name: name ?? 'item'
              }
            );
          }

          handleShow();
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setIsSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setIsSaving(false);
      });
  };

  useEffect(() => {
    const formDataWithDefaultProductType = {
      ...itemPopupFormKeys,
      product_type: formDefaultProductType ?? itemPopupFormKeys.product_type,
      ...(systemSettings?.default_unit_id
        ? {
            unit_id: systemSettings?.default_unit_id,
            unit_id_ref: {
              label: systemSettings?.default_unit_name,
              value: systemSettings?.default_unit_id
            }
          }
        : {
            unit_id_ref: '',
            unit_id: ''
          })
    };
    async function setDefaultStockId() {
      setStockIDIsSetting(true);
      const stockIDData = await apiCall({
        url: '/inventory/generate-stock-id'
      });
      if (stockIDData?.stock_id) {
        setFormData({
          ...formDataWithDefaultProductType,
          stock_id: stockIDData.stock_id
        });
      }
      setStockIDIsSetting(false);
    }
    if (show) {
      setDefaultStockId();
    }
    setShowWindow(show ?? false);
    return () => {
      setFormData(formDataWithDefaultProductType);
      setFormError({});
      setShowWindow(false);
      setIsSaving(false);
    };
  }, [show, formDefaultProductType]);

  return (
    <>
      {showAddButton ? (
        <PopupFormButton onClick={handleShow}>
          {Translate('Add Item')}
        </PopupFormButton>
      ) : null}

      {showWindow ? (
        <Modal
          show={showWindow}
          onHide={handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="d-flex flex-row align-items-center">
                <BsBox size={20} className="text-success" />
                <span className="ms-1">{Translate('ADD ITEM')}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(formError).length > 0 && (
                <ErrorAlert formError={formError} setFormError={setFormError} />
              )}
              <Form.Group className="mb-3" controlId="itemType">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Type')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-wrap align-items-center"
                  >
                    {formDefaultProductType ? (
                      <span className="text-capitalize">
                        {formDefaultProductType}
                      </span>
                    ) : (
                      <>
                        <Form.Check
                          inline
                          label={Translate('Goods')}
                          name="product_type"
                          type="radio"
                          id="inline-radio-1"
                          checked={
                            formData.product_type === 'goods' ? true : false
                          }
                          value="goods"
                          onChange={handleFieldChange}
                        />
                        <Form.Check
                          inline
                          label={Translate('Service')}
                          name="product_type"
                          type="radio"
                          id="inline-radio-2"
                          value="service"
                          checked={
                            formData.product_type === 'service' ? true : false
                          }
                          onChange={handleFieldChange}
                        />
                        <Form.Check
                          inline
                          label={Translate('Manufactured')}
                          name="product_type"
                          type="radio"
                          id="inline-radio-3"
                          value="manufactured"
                          checked={
                            formData.product_type === 'manufactured'
                              ? true
                              : false
                          }
                          onChange={handleFieldChange}
                        />
                      </>
                    )}

                    <div className="mb-1">
                      <FormErrorPopover
                        id="product_type"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.product_type}
                      />
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="stockIdInput">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Stock ID')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <div className="w-100">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="stock_id"
                          onChange={handleFieldChange}
                          value={formData.stock_id}
                        />
                        {stockIDIsSetting ? (
                          <InputGroup.Text
                            style={{
                              borderRadius: '0px',
                              backgroundColor: 'whitesmoke'
                            }}
                          >
                            <Spinner
                              animation="border"
                              style={{
                                width: 15,
                                height: 15
                              }}
                            />
                          </InputGroup.Text>
                        ) : null}
                      </InputGroup>
                      {stockIDIsSetting ? (
                        <Form.Text className="text-info">
                          Setting default stock id
                        </Form.Text>
                      ) : null}
                    </div>
                    <FormErrorPopover
                      id="stock_id"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.stock_id}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="itemBarcodeInput">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Barcode')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="barcode"
                        onChange={handleFieldChange}
                        value={formData?.barcode}
                      />
                      <InputGroup.Text
                        style={{
                          borderRadius: '0px',
                          backgroundColor: 'whitesmoke'
                        }}
                      >
                        <FaBarcode />
                      </InputGroup.Text>
                    </InputGroup>
                    <FormErrorPopover
                      id="barcode"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.barcode}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="itemNameInput">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Name')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <Form.Control
                      type="text"
                      name="item_name"
                      onChange={handleFieldChange}
                      value={formData.item_name}
                    />
                    <FormErrorPopover
                      id="item_name"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.item_name}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="itemPurchaseRateInput">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Purchase Price')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="purchase_rate"
                        onChange={handleFieldChange}
                        value={formData?.purchase_rate}
                      />
                      <InputGroup.Text
                        style={{
                          borderRadius: '0px',
                          backgroundColor: 'whitesmoke'
                        }}
                      >
                        <h5 className="fs--2 m-0">
                          {user?.branch?.base_currency}
                        </h5>
                      </InputGroup.Text>
                    </InputGroup>
                    <FormErrorPopover
                      id="purchase_rate"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.purchase_rate}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="itemSaleRateInput">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Sales Price')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="sale_rate"
                        onChange={handleFieldChange}
                        value={formData?.sale_rate}
                      />
                      <InputGroup.Text
                        style={{
                          borderRadius: '0px',
                          backgroundColor: 'whitesmoke'
                        }}
                      >
                        <h5 className="fs--2 m-0">
                          {user?.branch?.base_currency}
                        </h5>
                      </InputGroup.Text>
                    </InputGroup>
                    <FormErrorPopover
                      id="sale_rate"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.sale_rate}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="itemCategoryInput">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Category')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <div className="w-100">
                      <SelectCategory
                        name="category_id"
                        value={formData.category_id_ref}
                        handleFieldChange={handleFieldChange}
                        withAddButton
                        includeDetailsKey
                        product_type={formData?.product_type ?? ''}
                      />
                    </div>
                    <FormErrorPopover
                      id="category_id"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.category_id}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="itemUnitInput">
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Unit')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <div className="w-100">
                      <SelectUnit
                        name="unit_id"
                        value={formData.unit_id_ref}
                        handleFieldChange={handleFieldChange}
                      />
                    </div>
                    <FormErrorPopover
                      id="unit_id"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.unit_id}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="itemTaxGroupInput"
                style={{ display: 'none' }}
              >
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Tax Group')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <div className="w-100">
                      <SelectTaxGroup
                        name="tax_group_id"
                        value={formData.tax_group_id_ref}
                        handleFieldChange={handleFieldChange}
                      />
                    </div>
                    <FormErrorPopover
                      id="tax_group_id"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.tax_group_id}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="itemSalesAccountCodeInput"
                style={{ display: 'none' }}
              >
                <Row>
                  <Col md={5} sm={12}>
                    <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                      {Translate('Sales Account')}
                    </Form.Label>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    className="d-flex flex-row align-items-center"
                  >
                    <div className="w-100">
                      <SelectAccountDescriptions
                        name="sales_account_code"
                        value={formData.sales_account_code_ref}
                        handleFieldChange={handleFieldChange}
                      />
                    </div>
                    <FormErrorPopover
                      id="sales_account_code"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError.sales_account_code}
                    />
                  </Col>
                </Row>
              </Form.Group>
              {formData?.product_type === 'goods' ? (
                <>
                  <Form.Group
                    className="mb-3"
                    controlId="itemPurchaseAccountCodeInput"
                    style={{ display: 'none' }}
                  >
                    <Row>
                      <Col md={5} sm={12}>
                        <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                          {Translate('Purchase/Cost Account')}
                        </Form.Label>
                      </Col>
                      <Col
                        md={7}
                        sm={12}
                        className="d-flex flex-row align-items-center"
                      >
                        <div className="w-100">
                          <SelectAccountDescriptions
                            name="purchase_account_code"
                            value={formData.purchase_account_code_ref}
                            handleFieldChange={handleFieldChange}
                          />
                        </div>
                        <FormErrorPopover
                          id="purchase_account_code"
                          totalErrorCount={Object.keys(formError).length}
                          errorMessage={formError.purchase_account_code}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="itemInventoryAccountCodeInput"
                    style={{ display: 'none' }}
                  >
                    <Row>
                      <Col md={5} sm={12}>
                        <Form.Label className="require-data m-0 d-flex align-items-center justify-content-start h-100">
                          {Translate('Inventory/Expense Account')}
                        </Form.Label>
                      </Col>
                      <Col
                        md={7}
                        sm={12}
                        className="d-flex flex-row align-items-center"
                      >
                        <div className="w-100">
                          <SelectAccountDescriptions
                            name="inventory_account_code"
                            value={formData.inventory_account_code_ref}
                            handleFieldChange={handleFieldChange}
                          />
                        </div>
                        <FormErrorPopover
                          id="inventory_account_code"
                          totalErrorCount={Object.keys(formError).length}
                          errorMessage={formError.inventory_account_code}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              ) : formData?.product_type === 'manufactured' ? (
                <Form.Group
                  className="mb-3"
                  controlId="itemWIPAccountCodeInput"
                  style={{ display: 'none' }}
                >
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('WIP Account')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <div className="w-100">
                        <SelectAccountDescriptions
                          name="wip_account_code"
                          value={formData.wip_account_code_ref}
                          handleFieldChange={handleFieldChange}
                        />
                      </div>
                      <FormErrorPopover
                        id="wip_account_code"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.wip_account_code}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              ) : formData?.product_type === 'service' ? (
                <Form.Group
                  className="mb-3"
                  controlId="itemWIPCreditAccountCodeInput"
                  style={{ display: 'none' }}
                >
                  <Row>
                    <Col md={5} sm={12}>
                      <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                        {Translate('WIP Credit Account')}
                      </Form.Label>
                    </Col>
                    <Col
                      md={7}
                      sm={12}
                      className="d-flex flex-row align-items-center"
                    >
                      <div className="w-100">
                        <SelectAccountDescriptions
                          name="wip_crediting_account_code"
                          value={formData.wip_crediting_account_code_ref}
                          handleFieldChange={handleFieldChange}
                        />
                      </div>
                      <FormErrorPopover
                        id="wip_crediting_account_code"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.wip_crediting_account_code}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              ) : null}
            </Modal.Body>
            <Modal.Footer className="d-flex flex-row justify-content-end">
              <Button
                variant="outline-success"
                size="sm"
                className="d-flex align-items-center justify-content-center"
                onClick={handleSubmit}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="success"
                      className="me-1"
                    />
                    <span className="fs--1">{Translate('Saving...')}</span>
                  </>
                ) : (
                  <span>{Translate('Add')}</span>
                )}
              </Button>
              <Button
                variant="outline-danger"
                onClick={handleShow}
                size="sm"
                disabled={isSaving}
              >
                {Translate('Cancel')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

ItemPopupForm.propTypes = {
  callBack: PropTypes.func,
  setData: PropTypes.func,
  name: PropTypes.string,
  labelName: PropTypes.string,
  keyName: PropTypes.string,
  show: PropTypes.bool,
  showAddButton: PropTypes.bool,
  onHide: PropTypes.func,
  formDefaultProductType: PropTypes.string
};

export default ItemPopupForm;
