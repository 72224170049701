import React from 'react';
import { Alert, Card, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

function Contacts({ data }) {
  const Translate = useAxisproTranslate();
  return (
    <Container
      className={
        (data && data.contacts && data.contacts.length > 0) ||
        (data && data.supplier_contacts && data.supplier_contacts.length > 0)
          ? 'd-flex gap-3 flex-wrap mt-1 mb-1 '
          : 'd-flex gap-3 flex-wrap mt-1 mb-1 align-items-center justify-content-center'
      }
      // customer-contact-styles (removed this class in above container for responsive )
    >
      {data && data.contacts && data.contacts.length > 0 ? (
        data.contacts.map((item, key) => (
          <Card key={key} className="bg-soft-info customer-contact-card-styles">
            <Card.Header className="pb-2 pt-2">
              <h5 className="fs--1 m-0 fw-bold text-uppercase">
                {item.contact_name}
              </h5>
            </Card.Header>
            <Card.Body className="pt-0 pb-0">
              <div className="d-flex">
                <h5 className="flex-1 m-0 p-0">
                  {item.contact_email.length > 20
                    ? item.contact_email.substring(0, 20) + '...'
                    : item.contact_email}
                </h5>
              </div>
              <div className="d-flex mt-1">
                <h5 className="flex-1 m-0 p-0">{item.contact_mobile}</h5>
              </div>
              <div className="d-flex mt-1">
                <h5 className="flex-1 m-0 p-0" title={item.contact_address}>
                  {item.contact_address.length > 80
                    ? item.contact_address.substring(0, 80) + '...'
                    : item.contact_address}
                </h5>
              </div>
            </Card.Body>
          </Card>
        ))
      ) : data &&
        data.supplier_contacts &&
        data.supplier_contacts.length > 0 ? (
        data.supplier_contacts.map((item, key) => (
          <Card
            // style={{ height: '10vh', width: '30vw' }} responsive time style break
            key={key}
            className="bg-soft-info"
          >
            <Card.Header className="pb-2 pt-2">
              <h5 className="fs--1 m-0 fw-bold text-uppercase">{item.name}</h5>
            </Card.Header>
            <Card.Body className="pt-0 pb-0">
              <div className="d-flex">
                <h5 className="flex-1">{Translate('Email Address')}:</h5>
                <h5 className="flex-1">
                  {item.email_address.length > 20
                    ? item.email_address.substring(0, 20) + '...'
                    : item.email_address}
                </h5>
              </div>
              <div className="d-flex">
                <h5 className="flex-1">{Translate('Phone number')}:</h5>
                <h5 className="flex-1">{item.contact_number}</h5>
              </div>
              <div className="d-flex">
                <h5 className="flex-1">{Translate('Address')}:</h5>
                <h5 className="flex-1" title={item.address}>
                  {item.address.length > 20
                    ? item.address.substring(0, 20) + '...'
                    : item.address}
                </h5>
              </div>
            </Card.Body>
          </Card>
        ))
      ) : (
        <Alert variant="danger">
          {Translate('Currently there is no contacts to display!')}
        </Alert>
      )}
    </Container>
  );
}

Contacts.propTypes = {
  data: PropTypes.any
};

export default Contacts;
