import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

function BackButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };
  return (
    <FaRegArrowAltCircleLeft
      size={20}
      onClick={handleClick}
      className="me-2 cursor-pointer"
      title="Go Back"
    />
  );
}

export default BackButton;
