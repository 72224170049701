import { React, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import SalesmanTabView from 'module/Common/ViewPages/Salesman/SalesmanTabView';

function SalesmanInfo({ salesmanId, rendering, firstId, offcanvas }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async isLoading => {
    if (!rendering) {
      if (firstId !== undefined) {
        const fetchData = async () => {
          try {
            const response = await axios.get(`sales/salesman/${firstId}`);
            if (response?.data?.success) {
              setData(response?.data?.data);
            }
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        };
        fetchData();
      }
    } else {
      if (salesmanId !== undefined) {
        if (isLoading) {
          setLoading(true);
        }
        await axios
          .get(`sales/salesman/${salesmanId}`)
          .then(res => {
            if (res.data.success) {
              setData(res.data.data);
              setLoading(false);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };
  useEffect(() => {
    setLoading(true);
    // Set a timeout to call fetchData after 200 milliseconds
    const timeoutId = setTimeout(() => {
      fetchData(loading);
    }, 200);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [salesmanId, firstId]);
  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : (
        <SalesmanTabView
          salesmanData={data}
          loading={loading}
          fetchData={fetchData}
          offcanvas={offcanvas}
        />
      )}
    </>
  );
}

SalesmanInfo.propTypes = {
  salesmanId: PropTypes.string,
  firstId: PropTypes.string,
  rendering: PropTypes.bool,
  offcanvas: PropTypes.bool
};

export default SalesmanInfo;
