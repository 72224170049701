import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { AuthWizardContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import AppLayer from '../../../module/Common/Layer/AppLayer';

import uk from 'assets/img/country/uk.png';
import uae from 'assets/img/country/uae.png';

function LanguageDropdown() {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = () => {
    if (formData?.language == user?.language) {
      return null;
    } else {
      setLoading(true);
      axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json'
        },
        url: `user/${user.id}`,
        data: formData
      })
        .then(response => {
          if (response.data.success === true) {
            setLoading(false);

            localStorage.removeItem('ACODAX_TRANSLATION');
            window.location.reload();
          } else {
            setLoading(false);
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              'error'
            );
          }
        })
        .catch(() => {
          setLoading(false);
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        });
    }
  };

  const onClick = language => {
    setFormData(prevFormData => ({
      ...prevFormData,
      language: language
    }));
  };

  useEffect(() => {
    if (formData.language) {
      handleSubmit();
    }
  }, [formData.language]);

  useEffect(() => {
    setFormData({
      ...formData,
      _method: 'PUT',
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
      },
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      time_zone: user.time_zone
    });
  }, [user]);

  return (
    <>
      {loading ? <AppLayer /> : ''}
      <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
        <Dropdown.Toggle
          bsPrefix="toggle"
          variant="transparent"
          style={{ boxShadow: 'none', border: 'none' }}
          className="px-0 d-flex align-items-center justify-content-center"
          title={Translate('Language')}
        >
          {user.language == 'AR' ? (
            <img
              className="me-2"
              style={{ borderRadius: '50%' }}
              src={uae}
              width="19"
              height="18"
            />
          ) : (
            <img
              className="me-2"
              style={{ borderRadius: '50%' }}
              src={uk}
              width="19"
              height="18"
            />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
          <Dropdown.Item
            className="d-flex gap-1 align-items-center"
            onClick={() => onClick('EN')}
          >
            <img className="me-2" src={uk} width="20" /> ENGLISH
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex gap-1 align-items-center"
            onClick={() => onClick('AR')}
          >
            <img className="me-2" src={uae} width="20" /> {Translate('ARABIC')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default LanguageDropdown;
