import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';

function Loader({ loader, className }) {
  return (
    <Placeholder
      as={Card.Text}
      animation="glow"
      className={`${className} p-3 pb-2`}
    >
      {loader &&
        loader.length > 0 &&
        loader.map((item, index) => (
          <React.Fragment key={index}>
            <Placeholder xs={item} />{' '}
          </React.Fragment>
        ))}
    </Placeholder>
  );
}

Loader.propTypes = {
  loader: PropTypes.any,
  className: PropTypes.any
};

export default Loader;
